import { Form, Format, FormBuilder, TreeView } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import { Button, Grid } from "semantic-ui-react";
import { CustomerCountryAccessTypeDropdown } from "../lookups";
import { getSegment, saveSegment } from "./actions";

class ViewCustomerSegment extends React.Component {
  static propTypes = {
    customerId: PropTypes.string.isRequired
  };

  state = {
    errors: {}
  };

  flattenCountryData = countryAccessTypes => {
    // turn a tree of countries (with region parents) to an array of
    // {country-id: access-type} so we can still use TreeView with
    // our custom node rendering
    return !!countryAccessTypes
      ? countryAccessTypes.children
          .map(r => {
            return r.children.map(c => {
              const key = `country-${c.value}`; // this matches with CustomerCountryAccessTypeDropdown in `this.renderNode`
              return { [key]: c.accessType };
            });
          })
          .reduce((acc, val) => acc.concat(val), [])
          .reduce((prev, next) => ({ ...prev, ...next }), {})
      : {};
  };

  renderNode = (node, state, events) => {
    if (node.accessType == null || node.accessType == undefined) {
      return node.text;
    }

    return (
      <div className="flex">
        <span style={{ width: "20rem" }}>{node.text}</span>
        <CustomerCountryAccessTypeDropdown
          field={`country-${node.value}`}
          width="6rem"
        />
      </div>
    );
  };

  render() {
    const { customerId } = this.props;
    const { errors } = this.state;

    return (
      <FormBuilder
        initialData={{ segmentLimit: 1000 }}
        loadAction={getSegment}
        loadParams={customerId}
        loadTransform={data => ({
          ...data,
          ...this.flattenCountryData(data.countryAccessTypes)
        })}
        submitAction={saveSegment}
        submitParams={customerId}
        renderForm={(formProps, state, events) => (
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={14}
            className="m-b-1"
            stretched
          >
            <Grid className="user-page" style={{ margin: "0" }}>
              <Grid.Row>
                <Grid.Column>
                  <div className="i4b-list">
                    <Form {...formProps}>
                      <div className="flex space-between">
                        <h2>Segment</h2>

                        <Button
                          primary
                          onClick={() => events.onSubmit({ reload: true })}
                          disabled={
                            state.loading ||
                            (!!errors["segmentLimit"] &&
                              errors["segmentLimit"].length > 0)
                          }
                          style={{ alignSelf: "flex-end" }}
                        >
                          Save
                        </Button>
                      </div>

                      <Form.Group
                        style={{
                          marginLeft: "0.75rem",
                          width: "45%",
                          justifyContent: "space-between"
                        }}
                      >
                        <Form.Group>
                          <Form.Numeric
                            field="segmentCredits"
                            label="Credits"
                            type="number"
                            minValue={0}
                            width="7.5rem"
                            required
                          />
                          <div
                            className="centred-readonly-input"
                            style={{
                              marginLeft: "0.5rem",
                              marginRight: "0.5rem",
                              width: "4rem"
                            }}
                          >
                            <Form.Numeric
                              readOnly
                              field="currentSegmentUsed"
                              label="Used"
                              renderReadOnly={({ value }, _) => (
                                <Format.Number value={value || 0} />
                              )}
                            />
                          </div>
                          <Form.Numeric
                            field="segmentLimit"
                            label="Segment Limit"
                            type="number"
                            minValue={0}
                            required
                            width="7.5rem"
                            className="i4b-input-numeric-fontsize"
                          />
                        </Form.Group>
                        <Form.Group style={{ marginLeft: "2.5rem" }}>
                          <Form.Numeric
                            field="refreshCreditCount"
                            label="Refresh Credits"
                            width="7.5rem"
                            minValue={0}
                            decimalPlaces={0}
                            showErrors={false}
                          />
                          <Form.Date
                            instant
                            field="refreshDateUtc"
                            label="Refresh Date"
                            width="7.5rem"
                          />
                        </Form.Group>
                      </Form.Group>

                      {!!formProps.value.countryAccessTypes && (
                        <TreeView
                          nodes={[formProps.value.countryAccessTypes || {}]}
                          nodeKey="value"
                          renderNode={this.renderNode}
                          defaultExpanded
                          className="i4b-segment-tree"
                        />
                      )}
                    </Form>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        )}
      />
    );
  }
}

export default ViewCustomerSegment;
