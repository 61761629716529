import Switchboard from "assets/icons/headset.svg";
import LinkedIn from "assets/icons/linkedin_circle.svg";
import Manager from "assets/icons/manager.svg";
import Contacts from "assets/icons/people_circle.svg";
import Telephone from "assets/icons/telephone.svg";
import ViewCompany from "assets/icons/view_company.svg";
import { PermissionCheck, UserTypeCheck } from "components/auth";
import { Actions, Targets, UserTypes } from "constants/permissions";
import { CompanyRoutes } from "constants/routes";
import { GoogleMapsLink } from "constants/search";
import React from "react";
import { Link } from "react-router-dom";
import { Icon, Image, Loader, Popup, Transition } from "semantic-ui-react";
import { addContactTag } from "./actions";
import AddTagModal from "./AddTagModal";
import DeleteTagModal from "./DeleteTagModal";
import RequestResearchModal from "./RequestResearchModal";

class ExpandedRowContent extends React.Component {
  componentDidMount = () => {
    const { details, getContactDetails } = this.props;
    if (!details) {
      getContactDetails();
    }
  };

  componentDidUpdate(prevProps) {
    const { details, getContactDetails, contactsLoading } = this.props;
    if (!details && !contactsLoading) {
      getContactDetails();
    }
  }

  renderLeft() {
    return (
      <div className="error-banner">
        <span>This contact has left this company</span>
      </div>
    );
  }

  renderContent(data) {
    return (
      <div>
        {data.permissioned &&
          !data.optedOut &&
          (data.email || data.telephone || data.switchboard) && (
            <div className="flex flex-wrap m-b-05">
              {data.email && (
                <React.Fragment>
                  {!data.suppressed ? (
                    <span className="m-r-05 border-right">
                      <a
                        href={`mailto: ${data.email}`}
                        className="p-r-05 grey-link"
                      >
                        {data.email}
                      </a>
                    </span>
                  ) : (
                    <span className="m-r-05 border-right p-r-05">
                      {data.email}
                    </span>
                  )}
                </React.Fragment>
              )}
              {data.ddi && (
                <span className="m-r-05 flex align-center border-right">
                  <Popup
                    trigger={<Image src={Telephone} />}
                    content="Direct Dial In"
                    position="top center"
                    className="table-popup"
                  />
                  <p className="p-r-05 m-l-05">
                    {data.ddi}
                    {data.ddI_TPS == 1 && (
                      <span className="color-red"> (TPS)</span>
                    )}
                  </p>
                </span>
              )}
              {data.switchboard && (
                <span className="m-r-05 flex align-center border-right">
                  <Popup
                    trigger={<Image src={Switchboard} />}
                    content="Switchboard"
                    position="top center"
                    className="table-popup"
                  />
                  <p className="p-r-05 m-l-05">
                    {data.switchboard}
                    {data.switchboard_TPS == 1 && (
                      <span className="color-red"> (TPS)</span>
                    )}
                  </p>
                </span>
              )}
            </div>
          )}
        {data.optedOut && (
          <div className="error-banner">
            <span>Opted out</span>
          </div>
        )}
        {!data.permissioned && !data.optedOut && (
          <div className="error-banner" style={{ justifyContent: "start" }}>
            <span>Contacts email permission process is incomplete</span>
          </div>
        )}
        {(data.greeting || data.language) && (
          <div className="flex flex-wrap m-b-05">
            {data.greeting && (
              <span className="m-r-05 flex border-right">
                <p className="p-r-05">{data.greeting}</p>
              </span>
            )}
            {data.language && (
              <span className="m-r-05 flex">
                <p>{data.language}</p>
              </span>
            )}
          </div>
        )}
        {(data.techRole || data.address) && (
          <div className="flex flex-wrap m-b-05">
            {data.techRole && (
              <span className="m-r-05 flex border-right">
                <p className="p-r-05">{data.techRole}</p>
              </span>
            )}
            {data.address && (
              <span className="flex">
                <Icon name="map marker alternate" />
                <a
                  target="_blank"
                  href={GoogleMapsLink(data.address)}
                  className="grey-link p-r-05"
                >
                  {data.address}
                </a>
              </span>
            )}
          </div>
        )}
        {data.personalAssistant && (
          <div className="flex flex-wrap m-b-05">
            <span className="m-r-05 flex align-center border-right">
              <Popup
                trigger={<Image src={Manager} />}
                content="Personal Assistant"
                position="top center"
                className="table-popup"
              />
              <p className="p-r-05 m-l-05">{data.personalAssistant}</p>
            </span>
          </div>
        )}
        {data.description && (
          <div>
            <small>
              <i>{data.description}</i>
            </small>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      contactId,
      details,
      updateSearchFilter,
      onSubmitted,
      listViewingUniverse,
      isCompanyContactsPage,
      deleteTag,
      allowTagAdditions,
      leftCompany
    } = this.props;

    const { data, loading } = details || {};

    if (listViewingUniverse)
      return <p style={{ marginBottom: "0.5rem" }}>No data available.</p>;

    return (
      <Transition transitionOnMount={true} animation="fade" duration={200}>
        <section className="expanded-content">
          {!details || loading ? (
            <Loader active inline size="small" />
          ) : !data ? (
            <p style={{ marginBottom: "0.5rem" }}>No data available.</p>
          ) : (
            <React.Fragment>
              {leftCompany ? this.renderLeft() : this.renderContent(data)}

              <div className="m-t-1 flex space-between">
                <div className="flex m-r-2 flex-wrap">
                  {data.tags && data.tags.length > 0 && (
                    <React.Fragment>
                      {data.tags.map(tag => (
                        <div className="tag" key={tag.id}>
                          <label>{tag.name}</label>
                          <DeleteTagModal
                            submitAction={deleteTag}
                            contactId={contactId}
                            contactOriginalId={data.originalIntId}
                            tagId={tag.id}
                            onSubmitted={onSubmitted}
                          />
                        </div>
                      ))}
                    </React.Fragment>
                  )}
                  <UserTypeCheck restrictTo={UserTypes.External}>
                    <AddTagModal
                      selection={{
                        selected: [contactId],
                        deselected: [],
                        allSelected: false
                      }}
                      submitAction={addContactTag}
                      onSubmitted={onSubmitted}
                      isContactsPage
                      allowAdditions={allowTagAdditions}
                    />
                  </UserTypeCheck>
                </div>
              </div>
              <div className="m-t-1 flex space-between">
                <div className="flex m-r-2 flex-wrap space-icons">
                  <RequestResearchModal
                    contactId={contactId}
                    contactOriginalId={data.originalIntId}
                    onSubmitted={onSubmitted}
                  />
                  {data && data.linkedIn && (
                    <Popup
                      trigger={
                        <Image
                          src={LinkedIn}
                          href={data.linkedIn}
                          target="_blank"
                          className="row-action-icon m-r-05"
                        />
                      }
                      mouseEnterDelay={75}
                      content="LinkedIn"
                      position="bottom right"
                      className="table-popup"
                    />
                  )}
                  {!isCompanyContactsPage && (
                    <Popup
                      trigger={
                        <Image
                          src={Contacts}
                          className="row-action-icon"
                          onClick={() => updateSearchFilter(data.name)}
                        />
                      }
                      mouseEnterDelay={75}
                      content="Find contacts with a similar name"
                      position="bottom right"
                      className="table-popup"
                    />
                  )}
                  {data && !data.suppressed && !isCompanyContactsPage && (
                    <PermissionCheck
                      target={Targets.Company}
                      action={Actions.View}
                    >
                      <Link to={CompanyRoutes.Overview(data.companyId)}>
                        <Popup
                          trigger={
                            <Image
                              src={ViewCompany}
                              className="row-action-icon"
                            />
                          }
                          content="Company Summary"
                          position="bottom right"
                          className="table-popup"
                          mouseEnterDelay={75}
                        />
                      </Link>
                    </PermissionCheck>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </section>
      </Transition>
    );
  }
}

export default ExpandedRowContent;
