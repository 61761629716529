import { apiPut } from "@redriver/cinnamon";
import { authenticate, getAuthState } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/ACTIVE_SESSIONS";

export const ActionTypes = {
  DeleteSessions: `${NAMESPACE}/DELETE_OTHER_SESSIONS`
};

export const deleteOtherSessions = id => async (dispatch, getState) => {
  const { accessToken } = getAuthState(getState());
  const { success, response } = await dispatch(
    apiPut(
      ActionTypes.DeleteSessions,
      `auth/${id}/remove-sessions`,
      {},
      { auth: false }
    )
  );
  if (success) {
    dispatch(authenticate(accessToken, response, true));
    return true;
  }
  return false;
};
