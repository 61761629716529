import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";

const JobTitleCriteria = ({ active, handleClick, criteria }) => {
  const { jobTitles, jobTitlesMatch } = criteria.jobTitlesFilter;

  if (!jobTitles.length) return null;

  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Job Titles"
        text={`${jobTitlesMatch} ${jobTitles.map(t => t.title).join(", ")}`}
      />
      <Accordion.Content active={active}>
        <ul className="p-t-0 p-b-05 p-r-0 p-l-1 m-y-0">
          {jobTitles.map(title => (
            <li key={title.id}>{title.title}</li>
          ))}
        </ul>
      </Accordion.Content>
    </React.Fragment>
  );
};

export default JobTitleCriteria;
