import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { PageLoader, deauthenticate } from "@redriver/cinnamon";

class UserResetPrivateRedirect extends Component {
  componentDidMount() {
    const { history, location } = this.props;

    // history.push(SystemRoutes.Logout, {
    //   nextPath: location.pathname,
    //   customText: "Click the button below to reset your password."
    // });
    this.props.deauthenticate();
    this.props.history.push(location.pathname);
  }

  render() {
    return <PageLoader />;
  }
}

const actions = {
  deauthenticate
};

const mapStateToProps = state => {};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(UserResetPrivateRedirect)
);
