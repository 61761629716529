import { VerifiedRoute, VerifiedSwitch } from "components/auth";
import { AppRoutes, SystemRoutes } from "constants/routes";
import React from "react";
import { Route } from "react-router-dom";
import CompaniesRoute from "./companies";
import ContactsRoute from "./contacts";
import OptOutPage from "./contacts/OptOutDownloadPage";
import CustomersRoute from "./customers";
import DashboardRoute from "./dashboard";
import KnowledgeBaseRoute from "./knowledgeBase";
import ListRoute from "./lists";
import NotFoundPage from "./NotFoundPage";
import NotificationsRoute from "./notifications";
import OptOutReportRoute from "./optOutReport";
import RolesRoute from "./roles";
import SecureFileAreaRoute from "./secureFileArea";
import SegmentRemovalRoute from "./segmentRemoval";
import ExportTemplatesRoute from "./settings";
import UserResetPrivateRedirect from "./system/UserResetPrivateRedirect";
import UploadRoute from "./uploads";
import UserRoute from "./users";
import UserStatsRoute from "./userStats";
import ReportRoute from "./reports";

const I4BAppRoutes = () => {
  return (
    <VerifiedSwitch>
      <VerifiedRoute
        exact
        path={AppRoutes.Root}
        component={DashboardRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.Companies}
        component={CompaniesRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.Contacts}
        component={ContactsRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.Customers}
        component={CustomersRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.SegmentRemoval}
        component={SegmentRemovalRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.Roles}
        component={RolesRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.Users}
        component={UserRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.Lists}
        component={ListRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.Notifications}
        component={NotificationsRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.KnowledgeBase}
        component={KnowledgeBaseRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.Uploads}
        component={UploadRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.OptOutDownload}
        component={OptOutPage}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.OptOutReport}
        component={OptOutReportRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.AdvancedOptOutReport}
        component={OptOutReportRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={`/${AppRoutes.OptOutReport}`}
        component={OptOutReportRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={`/${AppRoutes.AdvancedOptOutReport}`}
        component={OptOutReportRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.UserStats}
        component={UserStatsRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.SecureFileArea}
        component={SecureFileAreaRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.Settings}
        component={ExportTemplatesRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        path={AppRoutes.Reports}
        component={ReportRoute}
        verifyRedirect={SystemRoutes.Reverify}
        sessionsRedirect={SystemRoutes.ActiveSessions}
      />
      <VerifiedRoute
        exact
        path={SystemRoutes.ResetUser}
        component={UserResetPrivateRedirect}
      />

      <Route component={NotFoundPage} />
    </VerifiedSwitch>
  );
};

export default I4BAppRoutes;
