import { Modal, ModalBuilder } from "@redriver/cinnamon";
import Close from "assets/icons/close.svg";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Image } from "semantic-ui-react";
import { BigPlayButton, ControlBar, LoadingSpinner, Player } from "video-react";
import { getKnowledgeVideo } from "./actions";

class PreviewKnowledgeItem extends Component {
  state = {
    videoUrl: ""
  };

  componentDidMount() {
    if (!this.props.download) {
      this.props
        .getKnowledgeVideo(this.props.itemId)
        .then(({ success, response }) => response.body)
        .then(res => new Response(res))
        .then(res => res.blob())
        .then(blob => {
          this.setState({
            videoUrl: URL.createObjectURL(blob)
          });
        })
        .catch(err => console.error("couldn't create video blob:", err));
    }
  }

  render() {
    const {
      renderTrigger,
      title,
      link,
      description,
      download,
      itemId
    } = this.props;
    const { videoUrl } = this.state;

    return (
      <ModalBuilder
        renderTrigger={showModal =>
          download ? (
            <a href={link} target="_blank">
              {renderTrigger()}
            </a>
          ) : (
            renderTrigger(showModal)
          )
        }
        renderModal={(modalProps, data, state, events) => (
          <Modal.Information
            {...modalProps}
            onSubmit={null}
            size="large"
            header={
              <div className="header">
                {title}
                <Image
                  src={Close}
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={events.onSubmit}
                />
              </div>
            }
            className="preview-modal"
          >
            <section className="video-player">
              <Player playsInline src={videoUrl}>
                <BigPlayButton position="center" />
                <LoadingSpinner />
                <ControlBar
                  autoHide={true}
                  autoHideTime={1000}
                  className="tutorial-video-control"
                />
              </Player>
            </section>
            <p>{description}</p>
          </Modal.Information>
        )}
      />
    );
  }
}

const actions = {
  getKnowledgeVideo
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  actions
)(PreviewKnowledgeItem);
