import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { editCustomFieldValue } from "./actions";

const EditCustomFieldValue = ({ item, renderTrigger, onSubmitted }) => (
  <ModalBuilder
    withForm
    submitAction={editCustomFieldValue}
    submitParams={item.id}
    onSubmitted={onSubmitted}
    renderTrigger={renderTrigger}
    initialData={{ value: item.value }}
    renderModal={(modalProps, formProps) => {
      return (
        <Modal.Edit
          submitLabel="Confirm"
          cancelLabel="Cancel"
          {...modalProps}
          header={
            <div>
              <Icon name="edit" /> Edit Custom Value
            </div>
          }
          size="tiny"
        >
          <Form {...formProps}>
            <Form.Input fluid field="value" label="Value" required />
          </Form>
        </Modal.Edit>
      );
    }}
  />
);

export default EditCustomFieldValue;
