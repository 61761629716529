import React from "react";
import { PageHeader } from "@redriver/cinnamon";
import { UserSetup } from "features/System";
import { SystemPageContent } from "components/pages";

const UserSetupPage = ({ match }) => (
  <SystemPageContent>
    <PageHeader>User Setup</PageHeader>
    <UserSetup token={match.params.token || ""} />
  </SystemPageContent>
);

export default UserSetupPage;
