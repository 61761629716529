import {
  apiGet,
  Breadcrumb,
  Form,
  Lookup,
  PageLoader,
  registerLookup
} from "@redriver/cinnamon";
import React from "react";
import { CountriesLookup } from "components/search/lookups";

const NAMESPACE = "USERS/LOOKUPS";

const ActionTypes = {
  ViewUser: `${NAMESPACE}/VIEW_USER`,
  ViewUsers: `${NAMESPACE}/VIEW_USERS`,
  CustomerNamesDropdown: `${NAMESPACE}/CUSTOMER_NAMES_DROPDOWN`
};

registerLookup(
  "usersLookup",
  ({ search }) =>
    apiGet(ActionTypes.ViewUsers, `users`, { search, pageSize: 10 }),
  {
    transform: res => {
      return res.results.map(s => ({
        value: s.id,
        text: `${s.firstName} ${s.lastName}`
      }));
    }
  }
);

export const UsersTypeahead = props => (
  <Form.TypeAhead lookup="usersLookup" {...props} />
);

registerLookup("userLookup", id => apiGet(ActionTypes.ViewUser, `users/${id}`));

registerLookup("customerNamesDropdownLookup", ({ search }) => {
  return apiGet(ActionTypes.CustomerNamesDropdown, "lookups/customers/names", {
    search
  });
});

export const UserLookup = ({ userId, ...props }) => (
  <Lookup lookup="userLookup" lookupParams={userId} {...props} />
);

export const UserPreLoader = ({ userId, children }) => (
  <UserLookup
    userId={userId}
    render={({ loading }) => (loading ? <PageLoader /> : children)}
    resetOnMount
  />
);

export const UserBreadcrumbItem = ({ userId }) => (
  <UserLookup
    userId={userId}
    render={({ response }) =>
      response && (
        <Breadcrumb.Item
          content={response.firstName + " " + response.lastName}
        />
      )
    }
  />
);

export const UserCountriesCheckboxTree = ({ userId, isEditable }) => (
  <CountriesLookup
    userId={userId}
    viewFullTree={true}
    render={({ response }, refresh) => {
      return (
        response && (
          <Form.CheckboxTree
            className={`checkbox-tree ${
              isEditable ? "checkbox-root-editable" : "checkbox-read-only"
            }`}
            field="countries"
            nodes={[
              {
                value: `${response.value}`,
                text: `${response.text}`,
                defaultExpanded: true,
                className: isEditable
                  ? "checkbox-rootnode-editable-padded"
                  : undefined,
                children: response.children || []
              }
            ]}
          />
        )
      );
    }}
  />
);
