import { ErrorMessage } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import {
  Loader,
  Table as STable,
  Dimmer,
  Container,
  DimmerDimmable
} from "semantic-ui-react";
import { ExpandRowsType } from "./constants";
import { ExpandedRow, HeaderRow, TableRow } from "./rows";

/**
 * A table that renders an array of data
 */
class I4bTable extends React.Component {
  static propTypes = {
    /**
     * Array of table row data
     * @property {string} `className` Optional classes for styling individual rows
     */
    data: PropTypes.arrayOf(PropTypes.object),
    /**
     * Property name for unique key in data, defaults to "key"
     */
    dataKey: PropTypes.string,
    /**
     * Indicates the table is busy loading data
     */
    loading: PropTypes.bool,
    /**
     * Indicates the table is taking a long time to load (a loading indicator will appear)
     */
    slowLoading: PropTypes.bool,
    /**
     * Error message to display within the table when something has gone wrong
     */
    error: PropTypes.any,
    /**
     * Message to display if the data contains no items
     */
    emptyMessage: PropTypes.string,
    /**
     * Hide the table header row
     */
    noHeader: PropTypes.bool,
    /**
     * Callback when a row is clicked
     * @param {Object} `item` The row item that was clicked on
     */
    onRowClick: PropTypes.func,
    /**
     * Default behaviour for expanding rows. Can be one of the following options:
     * @typedef ExpandRowsType
     * @property {string} `none` Rows are initially collapsed, can be toggled using onExpand/onCollapse row callbacks
     * @property {string} `onClick` Rows are initially collapsed, will automatically toggle when a row is clicked
     * @property {string} `always` Rows are permanently expanded and cannot be collapsed
     * @property {string} `never` Rows are permanently collapsed and cannot be expanded
     */
    expandRows: PropTypes.oneOf(Object.values(ExpandRowsType)),
    /**
     * Render function for displaying an expanded row
     * @param {Object} `item` The current row item being rendered
     * @param {Object} `state` An object representing the current row state
     * @param {*} `state.itemKey` Unique key value for row item
     * @param {number} `state.itemIndex` Row index for the item
     * @param {Object} `events` An object containing useful callbacks that can be triggered
     * @param {function} `events.onCollapse` Collapses the row
     */
    renderExpandedRow: PropTypes.func,
    /**
     * Optional, the maximum number of rows that can be expanded at any one time.
     * If exceeded the oldest expanded row will be automatically collapsed.
     */
    maxExpandedRows: PropTypes.number,
    /**
     * Time in milliseconds for the expanded row animation transitions, or false to disable
     */
    animateExpandedRows: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool
    ]),
    /**
     * The total number of columns that the table contains.
     * If children contains Table.Column components this will be calculated automatically.
     * If children is a render function this should be specified manually.
     */
    columns: PropTypes.number,
    /**
     * Content that each table row should contain, or a render function that will be passed the following parameters:
     * @param {Object} `item` The current item row being rendered
     * @param {Object} `state` An object representing the current row state
     * @param {*} `state.itemKey` Unique key value for row item
     * @param {number} `state.itemIndex` Row index for the item
     * @param {boolean} `state.expanded` Whether the row is currently expanded
     * @param {Object} `events` An object containing useful callbacks that can be triggered
     * @param {function} `events.onExpand` Expands the row
     * @param {function} `events.onCollapse` Collapses the row
     */
    children: PropTypes.any,
    /**
     * Additional classes for styling
     */
    className: PropTypes.string,
    setExpandedRows: PropTypes.func,
    expandedRows: PropTypes.array,
    /**
     * A method that is called when a row is expanded. This method is passed the 'itemKey'
     */
    onRowExpand: PropTypes.func,
    /**
     * A method that is called when a row is collapsed. This method is passed the 'itemKey'
     */
    onRowCollapse: PropTypes.func
  };

  static defaultProps = {
    dataKey: "key",
    expandRows: "none",
    animateExpandedRows: 400,
    expandedRows: []
  };

  _keyWarning = false;
  _columnsWarning = false;

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.data !== this.props.data ||
      nextProps.dataKey !== this.props.dataKey
    ) {
      this._keyWarning = false;
    }
  };

  getItemKey = item => {
    const { dataKey } = this.props;
    const key = item[dataKey];
    if (!key && !this._keyWarning) {
      console.warn("Table dataKey is invalid");
      this._keyWarning = true;
    }
    return key;
  };

  getTotalColumns = (required = true) => {
    const { columns } = this.props;
    if (!columns && !this._columnsWarning && required) {
      console.warn("Table columns has not been defined");
      this._columnsWarning = true;
    }
    return columns;
  };

  isRowExpanded = itemKey => {
    const { expandRows } = this.props;
    return (
      expandRows !== ExpandRowsType.Never &&
      (expandRows === ExpandRowsType.Always ||
        this.props.expandedRows.some(x => x === itemKey))
    );
  };

  onRowClick = (item, itemKey) => {
    const { onRowClick, expandRows } = this.props;
    if (expandRows === ExpandRowsType.OnClick) {
      const rowExpanded = this.isRowExpanded(itemKey);
      if (rowExpanded) {
        this.onRowCollapse(itemKey);
      } else {
        this.onRowExpand(itemKey);
      }
    }
    if (onRowClick) onRowClick(item);
  };

  onRowExpand = itemKey => {
    const { maxExpandedRows } = this.props;
    const expandedRows = this.props.expandedRows.filter(x => x !== itemKey);
    if (maxExpandedRows && expandedRows.length >= maxExpandedRows) {
      const [removeKey, ...remainingRows] = expandedRows;
      this.props.setExpandedRows([...remainingRows, itemKey]);
    } else {
      this.props.setExpandedRows([...expandedRows, itemKey]);
    }

    if (this.props.onRowExpand) {
      this.props.onRowExpand(itemKey);
    }
  };

  onRowCollapse = itemKey => {
    const { expandedRows } = this.props;
    this.props.setExpandedRows(expandedRows.filter(x => x !== itemKey));

    if (this.props.onRowCollapse) {
      this.props.onRowCollapse(itemKey);
    }
  };

  renderHeaderRow() {
    const { data, dataKey, noHeader, children } = this.props;
    if (noHeader) return null;
    return (
      <HeaderRow data={data} dataKey={dataKey}>
        {children}
      </HeaderRow>
    );
  }

  renderErrorRow(columnSpan) {
    const { error } = this.props;
    if (!error) return null;

    const errorStyle = {
      padding: 0
    };
    return (
      <STable.Row>
        <STable.Cell colSpan={columnSpan} style={errorStyle}>
          <ErrorMessage error={error} />
        </STable.Cell>
      </STable.Row>
    );
  }

  renderBodyRows() {
    const {
      data,
      dataKey,
      loading,
      dimmerLoading,
      slowLoading,
      emptyMessage,
      onRowClick,
      expandRows,
      renderExpandedRow,
      animateExpandedRows,
      children
    } = this.props;

    const columnSpan =
      typeof children === "function"
        ? this.getTotalColumns()
        : this.getTotalColumns(false) || React.Children.count(children);

    if (!data) return this.renderErrorRow(columnSpan);
    if (loading) {
      const loadingStyle = {
        minHeight: "80px",
        textAlign: "center"
      };
      const loaderStyle =
        slowLoading || slowLoading === undefined ? {} : { opacity: 0 };
      return (
        <STable.Row>
          <STable.Cell colSpan={columnSpan} style={loadingStyle}>
            <Loader size="large" active inline="centered" style={loaderStyle} />
          </STable.Cell>
        </STable.Row>
      );
    }

    if (data.length === 0) {
      const emptyStyle = { textAlign: "center" };
      return (
        <React.Fragment>
          <STable.Row>
            <STable.Cell colSpan={columnSpan} style={emptyStyle}>
              {emptyMessage || "No Results"}
            </STable.Cell>
          </STable.Row>
          {this.renderErrorRow(columnSpan)}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {data.map((item, index) => {
          const itemKey = this.getItemKey(item) || index;
          const expanded = this.isRowExpanded(itemKey);

          return (
            <React.Fragment key={itemKey}>
              <TableRow
                data={data}
                dataKey={dataKey}
                item={item}
                itemKey={itemKey}
                itemIndex={index}
                onClick={
                  !!onRowClick || expandRows === ExpandRowsType.OnClick
                    ? this.onRowClick
                    : undefined
                }
                onExpand={this.onRowExpand}
                onCollapse={this.onRowCollapse}
                expanded={expanded}
              >
                {children}
              </TableRow>
              {renderExpandedRow && (
                <ExpandedRow
                  item={item}
                  itemKey={itemKey}
                  itemIndex={index}
                  render={renderExpandedRow}
                  columnSpan={columnSpan}
                  onCollapse={this.onRowCollapse}
                  visible={expanded}
                  animate={
                    // for v0.9.0 compability
                    animateExpandedRows === true
                      ? Table.defaultProps.animateExpandedRows
                      : animateExpandedRows
                  }
                />
              )}
            </React.Fragment>
          );
        })}
        {this.renderErrorRow(columnSpan)}
      </React.Fragment>
    );
  }

  render() {
    const {
      data,
      dataKey,
      loading,
      dimmerLoading,
      slowLoading,
      error,
      emptyMessage,
      noHeader,
      onRowClick,
      expandRows,
      renderExpandedRow,
      maxExpandedRows,
      animateExpandedRows,
      columns,
      children,
      ...otherProps
    } = this.props;
    return (
      <Dimmer.Dimmable
        dimmed={dimmerLoading}
        className="i4table-dimmable-container"
      >
        <STable>
          <Dimmer
            active={dimmerLoading}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
            className="i4btable-dimmer"
          >
            <Loader size="big" active inline="centered" />
          </Dimmer>
          <STable.Header>{this.renderHeaderRow()}</STable.Header>

          <STable.Body>{this.renderBodyRows()}</STable.Body>
        </STable>
      </Dimmer.Dimmable>
    );
  }
}

export default I4bTable;
