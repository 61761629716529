import { UserTypes } from "constants/permissions";
import React from "react";
import CustomResearchWidget from "./CustomResearchWidget";
import MyFeedWidget from "./MyFeedWidget";
import RecentlyViewedWidget from "./RecentlyViewedWidget";
import SegmentWidget from "./SegmentWidget";

const widgets = {
  research: {
    type: "research",
    label: "Custom Research",
    alwaysShow: true,
    userType: UserTypes.External,
    content: props => (
      <CustomResearchWidget
        colour="#4eb888"
        key="research"
        label="Research"
        {...props}
      />
    )
  },
  myFeed: {
    type: "feed",
    label: "My Feed",
    alwaysShow: false,
    userType: UserTypes.External,
    content: props => (
      <MyFeedWidget
        colour="#4eb888"
        key="myFeed"
        label="My Feed"
        className="my-feed"
        info="Select the Star icon against any record to add them to your updates Feed"
        {...props}
      />
    )
  },
  segmentDetails: {
    type: "segment",
    label: "My Segment",
    alwaysShow: false,
    userType: UserTypes.External,
    forSegmentedCustomers: true,
    content: props => (
      <SegmentWidget
        colour="#4eb888"
        key="segmentDetails"
        label="My Segment"
        {...props}
      />
    )
  },
  recentlyViewed: {
    type: "recent",
    label: "Recently Viewed",
    alwaysShow: false,
    content: props => (
      <RecentlyViewedWidget
        colour="#4eb888"
        key="recent"
        label="Recently Viewed"
        className="recently-viewed"
        {...props}
      />
    )
  }
};
export default widgets;
