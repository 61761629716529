import { Content, PageLoader } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import { UserLookup } from "../lookups";
import { updateUserIpWhitelist } from "./actions";
import IPForm from "./IPForm";

const UserWhitelist = ({ userId }) => (
  <Grid.Column
    mobile={16}
    tablet={16}
    computer={14}
    className="p-l-2"
    style={{ padding: "0.8rem" }}
  >
    <UserLookup
      userId={userId}
      render={({ response }, refresh) => {
        return response ? (
          <Segment className="i4b-user-page">
            <h2>IP Whitelist</h2>
            <IPForm
              data={response}
              submitAction={updateUserIpWhitelist}
              onSubmitted={refresh}
            />
          </Segment>
        ) : (
          <PageLoader />
        );
      }}
    />
  </Grid.Column>
);

UserWhitelist.propTypes = {
  userId: PropTypes.string.isRequired
};

export default UserWhitelist;
