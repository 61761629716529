import React from "react";
import { Route, Switch } from "react-router-dom";

import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes, CustomerRoutes } from "constants/routes";
import PermissionErrorPage from "../PermissionErrorPage";
import ListCustomersPage from "./ListCustomersPage";
import CustomerRoute from "./CustomerRoute";

export default () => (
  <React.Fragment>
    <PermissionCheck target={Targets.Customers} action={Actions.View}>
      <Switch>
        <Route exact path={AppRoutes.Customers} component={ListCustomersPage} />
        <Route path={CustomerRoutes.Customer()} component={CustomerRoute} />
      </Switch>
    </PermissionCheck>
    <PermissionCheck
      target={Targets.Customers}
      action={Actions.View}
      not
      component={PermissionErrorPage}
    />
  </React.Fragment>
);
