import { ContentBuilder, Format } from "@redriver/cinnamon";
import User from "assets/icons/green_user.svg";
import Building from "assets/icons/purple_company.svg";
import { CompanyRoutes } from "constants/routes";
import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Icon, Image } from "semantic-ui-react";
import { getMyFeedData } from "./actions";
import Widget from "./Widget";

class MyFeedWidget extends React.Component {
  renderNotification = notification => {
    const { history } = this.props;
    var isContactUpdate =
      notification.notificationType === "ContactWatchlistAlert";

    return (
      <Grid
        className={`dashboard-notification ${
          isContactUpdate ? "contact-update" : "company-update"
        }`}
        onClick={() =>
          isContactUpdate
            ? history.push(CompanyRoutes.Contacts(notification.companyId), {
                search: notification.contactName
              })
            : history.push(CompanyRoutes.Overview(notification.companyId))
        }
      >
        <Grid.Row columns={2}>
          <Grid.Column computer={14}>
            <Grid.Row
              columns={2}
              style={{ display: "flex", maxHeight: "5rem" }}
            >
              <Grid.Column
                computer={12}
                style={{ width: "100%", display: "flex" }}
                verticalAlign="middle"
              >
                {isContactUpdate ? (
                  //NOTE: these icons are different sizes hence different margins
                  <Image src={User} style={{ marginRight: "10px" }} />
                ) : (
                  //NOTE: these icons are different sizes hence different margins
                  <Image
                    src={Building}
                    style={{ marginLeft: "-3px", marginRight: "8px" }}
                  />
                )}
                <p className="update-title">{`${
                  isContactUpdate ? "Contact " : "Company "
                } Updated`}</p>
              </Grid.Column>
              <Grid.Column computer={4}>
                <Format.DateTime value={notification.createdUtc} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column computer={16}>
                <p style={{ marginTop: "0.3em" }}>{notification.content}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column computer={2} verticalAlign="middle">
            <Icon name="caret right" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderContent() {
    return (
      <ContentBuilder
        loadAction={getMyFeedData}
        renderContent={(data, state, events) =>
          data && data.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              <Icon name="star outline" size="large" />
              <p style={{ paddingTop: "0.5rem" }}>
                Follow companies or contacts and you'll see updates here
              </p>
            </div>
          ) : (
            data.length > 0 && (
              <Grid
                columns={1}
                style={{
                  borderTop: "1px solid #f7f6fa",
                  marginTop: "0rem",
                  paddingTop: "0rem"
                }}
              >
                {data.map(r => (
                  <Grid.Column>{this.renderNotification(r)}</Grid.Column>
                ))}
              </Grid>
            )
          )
        }
      />
    );
  }

  render() {
    const { label, colour, loading, className, info } = this.props;

    return (
      <Widget
        loading={loading}
        header={label}
        color={colour}
        className={className}
        info={info}
      >
        {this.renderContent()}
      </Widget>
    );
  }
}

export default withRouter(MyFeedWidget);
