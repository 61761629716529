import { ActionBar, Form, FormBuilder, PageSize } from "@redriver/cinnamon";
import React from "react";

const Filters = ({
  filters,
  onChangeFilters,
  advanced,
  pageSize,
  onPageSizeChange,
  onChangeSelection
}) => (
  <ActionBar>
    <ActionBar.Item fluid className="p-t-05">
      <span>
        If you require access to older opt outs please contact your Account
        Manager.
      </span>
    </ActionBar.Item>
    {advanced && (
      <ActionBar.Item fluid>
        <FormBuilder
          initialData={filters}
          onChange={onChangeFilters}
          renderForm={formProps => (
            <Form {...formProps}>
              <Form.Group inline compact>
                <Form.Date
                  field="from"
                  local
                  placeholder="From"
                  width="7.5rem"
                />
                <Form.Date field="to" local placeholder="To" width="7.5rem" />
                <Form.RadioGroup
                  field="isLeftContact"
                  inline
                  options={[
                    { value: "false", text: "Opt Out" },
                    { value: "true", text: "Left Contacts" }
                  ]}
                />
                <Form.Trigger
                  field="isLeftContact"
                  event="onChange"
                  action={() => {
                    onChangeSelection();
                  }}
                />
              </Form.Group>
            </Form>
          )}
        />
      </ActionBar.Item>
    )}
    {pageSize && (
      <ActionBar.Item className="i4b-filter-pagesize">
        <PageSize
          value={pageSize}
          onChange={x => onPageSizeChange({ pageSize: x, pageNumber: 1 })}
          options={[10, 25, 50, 100]}
        />
      </ActionBar.Item>
    )}
  </ActionBar>
);

export default Filters;
