import React from "react";
import { Message } from "semantic-ui-react";

const ErrorMessageNotification = ({ data, state, events }) => (
  <Message
    header={data.title}
    content={
      <React.Fragment>
        {data.message}
        <span
          style={{
            float: "right",
            clear: "right",
            marginLeft: 10
          }}
        >
          {state.displayTime}
        </span>
      </React.Fragment>
    }
    onDismiss={events.onDismiss}
    error
    className="custom-notification"
  />
);

export default ErrorMessageNotification;
