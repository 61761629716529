import React from "react";
import {
  apiGet,
  Form,
  registerLookup,
  mockSuccess,
  Lookup,
  PageLoader,
  Breadcrumb
} from "@redriver/cinnamon";
import { CountriesLookup } from "components/search/lookups";

const NAMESPACE = "CUSTOMERS/LOOKUPS";

const ActionTypes = {
  CustomerSubTypeTypeahead: `${NAMESPACE}/SUBTYPE_TYPEAHEAD`,
  CustomerCountryAccessTypeTypeahead: `${NAMESPACE}/CCACCESSTYPE_TYPEAHEAD`,
  ViewCustomer: `${NAMESPACE}/VIEW_CUSTOMER`,
  CustomerUsersActiveDropdown: `${NAMESPACE}/USERS_ACTIVE_DROPDOWN`
};

registerLookup("subTypeLookup", () =>
  apiGet(
    ActionTypes.CustomerSubTypeTypeahead,
    "lookups/customers/subscription-types"
  )
);

export const CustomerSubTypeDropdown = props => (
  <Form.Dropdown
    placeholder="Customer Subscription Type"
    {...props}
    lookup="subTypeLookup"
  />
);

registerLookup("countryAccessTypeLookup", () =>
  mockSuccess(ActionTypes.CustomerCountryAccessTypeTypeahead, {
    response: [
      {
        text: "No Access",
        value: "NoAccess"
      },
      {
        text: "Full Access",
        value: "FullAccess"
      },
      {
        text: "Custom Segment",
        value: "SegmentAccess"
      }
    ]
  })
);

export const CustomerCountryAccessTypeDropdown = props => (
  <Form.Dropdown
    placeholder="Country Access Type"
    {...props}
    lookup="countryAccessTypeLookup"
  />
);

registerLookup("customerLookup", id =>
  apiGet(ActionTypes.ViewCustomer, `customers/${id}`)
);

export const CustomerLookup = ({ customerId, ...props }) => (
  <Lookup lookup="customerLookup" lookupParams={customerId} {...props} />
);

export const CustomerPreLoader = ({ customerId, children }) => (
  <CustomerLookup
    customerId={customerId}
    render={({ loading }) => (loading ? <PageLoader /> : children)}
    resetOnMount
  />
);

export const CustomerBreadcrumbItem = ({ customerId }) => (
  <CustomerLookup
    customerId={customerId}
    render={({ response }) =>
      response && <Breadcrumb.Item content={response.fullName} />
    }
  />
);

export const CustomerCountriesCheckboxTree = ({ isEditable }) => (
  <CountriesLookup
    viewFullTree={true}
    render={({ response }, refresh) => {
      return (
        response && (
          <Form.CheckboxTree
            className={`checkbox-tree ${
              isEditable ? "checkbox-root-editable" : "checkbox-read-only"
            }`}
            field="countries"
            nodes={[
              {
                value: `${response.value}`,
                text: `${response.text}`,
                defaultExpanded: true,
                className: isEditable
                  ? "checkbox-rootnode-editable-padded"
                  : undefined,
                children: response.children || []
              }
            ]}
          />
        )
      );
    }}
  />
);

registerLookup("usersActiveFilterDropdown", () =>
  apiGet(
    ActionTypes.CustomerUsersActiveDropdown,
    "lookups/users/active-filter-options"
  )
);

export const UsersActiveFilterDropdown = props => (
  <Form.Dropdown {...props} lookup="usersActiveFilterDropdown" />
);
