import {
  ActionBar,
  getAuthClaims,
  Lookup,
  lookupReset
} from "@redriver/cinnamon";
import { UserTypeCheck } from "components/auth";
import { AddToListModal, SaveListModal } from "components/search";
import { Actions, Targets, UserTypes } from "constants/permissions";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";
import AddCustomFieldModal from "../../CompaniesSearch/AddCustomFieldModal";
import { getCompanyStructureState } from "../selectors";
import { addCompanyTag, addToList, clearForm, saveList } from "./actions";
import CompanyStructureTreeView from "./CompanyStructureTreeView";

class CompanyStructure extends React.Component {
  state = {
    treeViewKey: 0,
    dropdownOpen: false
  };
  expandAllDescendants = parent => {
    parent.defaultExpanded = true;
    for (const child of parent.children) {
      this.expandAllDescendants(child);
    }
  };

  expandIfMatch = (parent, companyId) => {
    if (parent.value == companyId) {
      parent.defaultExpanded = true;
    }

    for (const child of parent.children) {
      const isExpanded = this.expandIfMatch(child, companyId);
      if (isExpanded) {
        parent.defaultExpanded = true;
      }
    }
    if (parent.defaultExpanded) {
      return true;
    } else {
      return false;
    }
  };

  onExpandAllNodes = nodes => {
    const { treeViewKey } = this.state;
    const ultimateParent = nodes[0];
    this.expandAllDescendants(ultimateParent);
    this.setState({
      treeViewKey: treeViewKey + 1
    });
  };

  init = lookupData => {
    const { companyId } = this.props;
    const { treeViewKey } = this.state;
    lookupData.response[0].defaultExpanded = true;
    const ultimateParent = lookupData.response[0];
    this.expandIfMatch(ultimateParent, companyId);
    this.setState({
      treeViewKey: treeViewKey + 1
    });
  };

  toggleDropdownOpen = () =>
    this.setState({ dropdownOpen: !this.state.dropdownOpen });

  componentWillUnmount() {
    const { lookupReset, companyId, clearForm } = this.props;
    lookupReset("companyStructureLookup", companyId);
    clearForm();
  }

  render() {
    const {
      companyId,
      selected,
      selectedItems,
      allowTagAdditions
    } = this.props;
    const { treeViewKey, dropdownOpen } = this.state;
    const selection = {
      allSelected: false,
      selected: selected,
      deselected: []
    };

    return (
      <div className="i4b-list colour-expanded-table">
        <h1 className="flex space-between">Org Structure</h1>
        <Lookup
          lookup="companyStructureLookup"
          lookupParams={companyId}
          onLoaded={lookupData => {
            this.init(lookupData);
          }}
          render={({ loading, response }) => (
            <React.Fragment>
              {loading ? (
                <Loader active size="large" style={{ display: "block" }} />
              ) : (
                <React.Fragment>
                  <ActionBar className="space-between">
                    <ActionBar.Item>
                      <a
                        href="#current-company"
                        onClick={() => this.onExpandAllNodes(response)}
                      >
                        <Button primary size="small">
                          Expand group structure
                        </Button>
                      </a>
                    </ActionBar.Item>
                    <ActionBar.Item>
                      <div className="i4b-search-actions-wrapper">
                        <div>
                          <span
                            className={
                              !selectedItems || selectedItems < 1
                                ? `none-selected`
                                : undefined
                            }
                            onClick={
                              !!selectedItems && selectedItems > 0
                                ? this.toggleDropdownOpen
                                : null
                            }
                          >
                            Actions
                          </span>
                          <Button
                            className={`actions-dropdown-button ${
                              !selectedItems || selectedItems < 1
                                ? `none-selected`
                                : selectedItems && !dropdownOpen
                                ? `some-selected`
                                : `close`
                            }`}
                            onClick={
                              !!selectedItems && selectedItems > 0
                                ? this.toggleDropdownOpen
                                : null
                            }
                          />
                          <section
                            style={
                              dropdownOpen
                                ? {
                                    visibility: "visible",
                                    opacity: "1"
                                  }
                                : {
                                    visibility: "hidden",
                                    opacity: "0"
                                  }
                            }
                            className="actions-dropdown-section"
                          >
                            <header>
                              <h3>Actions</h3>
                              <p>
                                {selectedItems} selected{" "}
                                {selectedItems > 1 ? "results" : "result"}
                              </p>
                            </header>
                            <ul className="list-unstyled">
                              <React.Fragment>
                                <SaveListModal
                                  isCompaniesPage
                                  submitAction={saveList}
                                  selection={selection}
                                />
                                <AddToListModal
                                  isCompaniesPage
                                  submitAction={addToList}
                                  submitParams={{
                                    selection
                                  }}
                                />
                              </React.Fragment>

                              <UserTypeCheck restrictTo={UserTypes.External}>
                                <li>
                                  <AddCustomFieldModal
                                    selection={selection}
                                    isActionList
                                    submitAction={addCompanyTag}
                                    viewingUniverse={false}
                                    allowAdditions={allowTagAdditions}
                                  />
                                </li>
                              </UserTypeCheck>
                            </ul>
                          </section>
                        </div>
                      </div>
                    </ActionBar.Item>
                  </ActionBar>
                  <CompanyStructureTreeView
                    className="org-structure-tree-view"
                    companyId={companyId}
                    nodes={response || []}
                    expandedIcon={<Icon name="chevron down" size="large" />}
                    collapsedIcon={<Icon name="chevron right" size="large" />}
                    key={treeViewKey}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { formData } = getCompanyStructureState(state);
  const { permissions } = getAuthClaims(state);
  return {
    selectedItems: formData.selectedCompanies
      ? formData.selectedCompanies.length
      : null,
    selected: formData.selectedCompanies,
    allowTagAdditions:
      permissions &&
      permissions.hasOwnProperty(Targets.ItemTags) &&
      (Array.isArray(permissions[Targets.ItemTags])
        ? permissions[Targets.ItemTags].some(p => p == Actions.Create)
        : permissions[Targets.ItemTags] == Actions.Create)
  };
};

const actions = {
  lookupReset,
  clearForm
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(CompanyStructure)
);
