import React from "react";
import { Image } from "semantic-ui-react";
import { Breadcrumb } from "@redriver/cinnamon";

import { ListRoles } from "features/Roles";
import { AppRoutes } from "constants/routes";
import Home from "assets/icons/home_breadcrumb.svg";
const ListRolesPage = () => (
  <React.Fragment>
    <Breadcrumb className="i4b-breadcrumb-container">
      <Breadcrumb.Item
        link={AppRoutes.Root}
        icon={<Image src={Home} title="Home" />}
      />
      <Breadcrumb.Item content="Roles" />
    </Breadcrumb>
    <ListRoles />
  </React.Fragment>
);

export default ListRolesPage;
