import { connect } from "react-redux";
import {
  changeFollowing,
  changeSelection,
  getContacts,
  getContactDetails,
  addContactTag,
  deleteTag
} from "./actions";
import ContactsTable from "./ContactsTable";
import { getContactsSearchState } from "./selectors";

const actions = {
  changeSelection,
  changeFollowing,
  getContacts,
  getContactDetails,
  addContactTag,
  deleteTag,
  onSubmitted: () => getContacts(true, true, true)
};
const mapStateToProps = state => {
  const {
    contacts,
    contactsLoading,
    dimmerLoading,
    pageNumber,
    pageSize,
    selection
  } = getContactsSearchState(state);

  return {
    contacts: contacts.results,
    totalResults: contacts.totalResults,
    contactsLoading,
    dimmerLoading,
    pageNumber,
    pageSize,
    selection
  };
};

// Add all the props needed from redux
// ContactsTable is reused when viewing under a company
export default connect(
  mapStateToProps,
  actions
)(ContactsTable);
