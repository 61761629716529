import {
  apiDelete,
  apiGet,
  apiPut,
  apiRequest,
  mockError
} from "@redriver/cinnamon";

const NAMESPACE = "UPLOADS/LIST_UPLOADS";

export const ActionTypes = {
  UploadFile: `${NAMESPACE}/UPLOAD_FILE`,
  GetUploads: `${NAMESPACE}/UPLOADS`,
  RenameUpload: `${NAMESPACE}/RENAME_UPLOAD`,
  LoadUploadModal: `${NAMESPACE}/LOAD_UPLOAD_MODAL`,
  DeleteUpload: `${NAMESPACE}/DELETE_UPLOAD`
};

export const uploadFileForProcessing = upload => async dispatch => {
  if (!upload.file || !upload.file.size)
    return mockError(ActionTypes.UploadFile, {
      code: 0,
      message: "Couldn't retrieve uploaded file. Please try again",
      preventNotification: true
    });

  const regex = /(?:\.([^.]+))?$/;
  const ext = regex.exec(upload.file.name)[1];
  if (ext === undefined || !["csv", "xlsx"].includes(ext))
    return mockError(ActionTypes.UploadFile, {
      code: 0,
      message: `${
        upload.file.name
      }: Uploaded files must be in the CSV or Excel XLSX format`
    });

  return await dispatch(
    apiRequest({
      type: ActionTypes.UploadFile,
      path: "uploads",
      method: "POST",
      body: upload.file,
      params: { name: upload.file.name },
      contentType: upload.file.type
    })
  );
};

export const getUploads = ({ filters, pagination }) => {
  const params = {
    ...filters,
    ...pagination
  };

  return apiGet(ActionTypes.GetUploads, "uploads", params);
};

export const loadUploadModal = id =>
  apiGet(ActionTypes.LoadUploadModal, `uploads/${id}`);

export const renameUpload = (FormData, submitParams) =>
  apiPut(
    ActionTypes.RenameUpload,
    `uploads/${submitParams.uploadId}/rename`,
    FormData
  );

export const deleteUpload = (data, submitParams) =>
  apiDelete(ActionTypes.DeleteUpload, `uploads/${submitParams.uploadId}`);
