import React from "react";
import { Icon } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteRole } from "./actions";

const DeleteRole = ({ id, renderTrigger, onSubmitted }) => (
  <ModalBuilder
    submitAction={deleteRole}
    submitParams={id}
    onSubmitted={onSubmitted}
    renderTrigger={renderTrigger}
    renderModal={modalProps => (
      <Modal.Delete
        {...modalProps}
        header={
          <div>
            <Icon name="warning sign" /> Delete Role
          </div>
        }
        size="tiny"
      >
        <p>Are you sure you want to delete this role?</p>
      </Modal.Delete>
    )}
  />
);

export default DeleteRole;
