import { Format } from "@redriver/cinnamon";
import Revenue from "assets/icons/pounds_bag.svg";
import ViewCompany from "assets/icons/view_company.svg";
import People from "assets/icons/people.svg";
import Switchboard from "assets/icons/headset.svg";
import { PermissionCheck, UserTypeCheck } from "components/auth";
import { ExternalLink } from "components/buttons";
import { Actions, Targets, UserTypes } from "constants/permissions";
import { CompanyRoutes } from "constants/routes";
import { GoogleMapsLink } from "constants/search";
import { formatRevenue, isPhoneValid } from "features/Utils";
import React from "react";
import { Link } from "react-router-dom";
import { Icon, Image, Popup, Transition } from "semantic-ui-react";
import { addCompanyTag, getCompanies } from "./actions";
import AddCustomFieldModal from "./AddCustomFieldModal";
import DeleteFieldModal from "./DeleteFieldModal";
import RequestResearchModal from "./RequestResearchModal";

class ExpandedRowContent extends React.Component {
  isEmpty = details => {
    return Object.keys(details).length == 0;
  };

  shouldDisplay = value => {
    return value !== undefined && value !== null && value !== 0;
  };

  renderNoData() {
    return <p className="m-b-05">No data available.</p>;
  }

  renderContent(details, isDefunct) {
    return (
      <div>
        {isDefunct && (
          <div className="error-banner">
            <span>Company status: Defunct - This company no longer exists</span>
          </div>
        )}
        {(details.website || details.contacts || details.crn) && (
          <div className="flex flex-wrap m-b-05">
            {details.website && (
              <span className="m-r-05 border-right">
                <ExternalLink
                  link={details.website}
                  className="grey-link p-r-05"
                />
              </span>
            )}
            {details.contacts && (
              <span className="m-r-05 flex border-right">
                <Icon name="address card" />
                <p className="purple-bold p-r-05">
                  {details.contacts} Contacts
                </p>
              </span>
            )}
            {details.crn && (
              <Popup
                trigger={<p>{details.crn}</p>}
                content="Reg. No."
                position="top center"
                className="table-popup"
              />
            )}
          </div>
        )}
        {details.address && (
          <div className="flex flex-wrap m-b-05">
            <Popup
              trigger={<Icon name="map marker alternate" />}
              content="Address"
              position="top center"
              className="table-popup"
            />
            <Popup
              trigger={
                <a
                  target="_blank"
                  href={GoogleMapsLink(details.address)}
                  className="grey-link company-address-link"
                >
                  {details.address}
                </a>
              }
              content={details.address}
              position="top center"
              className="table-popup"
            />
          </div>
        )}
        {this.shouldDisplay(details.telPublic) &&
          isPhoneValid(details.telPublic) && (
            <div className="flex flex-wrap m-b-05">
              <Popup
                trigger={
                  <Image
                    src={Switchboard}
                    alt="HQ Switchboard"
                    className="switchboard-icon"
                  />
                }
                content="HQ Switchboard"
                position="top center"
                className="table-popup"
              />
              <p className="p-r-05"> {details.telPublic}</p>
            </div>
          )}
        <div className="flex flex-wrap m-b-05">
          {this.shouldDisplay(details.revenue) && (
            <span className="m-r-05 flex-wrap flex border-right">
              <Popup
                trigger={<Image src={Revenue} className="revenue-icon" />}
                content="Revenue"
                position="top center"
                className="table-popup"
              />
              <p className="p-r-05">
                {details.currencyCode} {formatRevenue(details.revenue)}
              </p>
            </span>
          )}
          {this.shouldDisplay(details.employees) && (
            <span className="flex m-r-05 border-right">
              <Popup
                trigger={<Icon name="users" />}
                content="Employees"
                position="top center"
                className="table-popup"
              />
              <p className="p-r-05">
                <Format.Number value={details.employees} />
              </p>
            </span>
          )}
          {this.shouldDisplay(details.numberActiveContacts) && (
            <span className="flex m-r-05 border-right">
              <Popup
                trigger={
                  <Image
                    src={People}
                    alt="Number of Active Contacts"
                    className="contacts-icon"
                  />
                }
                content="Active Contacts at this Company"
                position="top center"
                className="table-popup flowing"
              />
              <p className="p-r-05">
                <Format.Number value={details.numberActiveContacts} />
              </p>
            </span>
          )}
          {(this.shouldDisplay(details.locations) ||
            this.shouldDisplay(details.totalLocations)) && (
            <span className="flex m-r-05 border-right">
              <Popup
                trigger={<Icon name="world" />}
                content="Locations"
                position="top center"
                className="table-popup"
              />
              {details.locations && (
                <p className="m-r-05">{details.locations}</p>
              )}{" "}
              {details.totalLocations ? (
                <p className="p-r-05">({details.totalLocations} Worldwide)</p>
              ) : (
                ""
              )}
            </span>
          )}
          {details.yearEnd && (
            <span>
              <p>
                Year end <Format.Date value={details.yearEnd} format="DD/MM" />
              </p>
            </span>
          )}
        </div>
        {details.description && (
          <div>
            <small>
              <i>{details.description}</i>
            </small>
          </div>
        )}
      </div>
    );
  }

  renderSuppressed() {
    return (
      <div className="error-banner">
        <span>Company is being suppressed</span>
      </div>
    );
  }

  render() {
    const { item, onSubmitted, viewUniverse, allowTagAdditions } = this.props;
    const { details, isDefunct } = item;

    return (
      <Transition transitionOnMount={true} animation="fade" duration={200}>
        <section className="expanded-content">
          {!item.details || this.isEmpty(details)
            ? this.renderNoData()
            : item.details.suppressed
            ? this.renderSuppressed()
            : this.renderContent(details, isDefunct)}

          <div className="m-t-1 flex flex-wrap space-between">
            <div className="flex m-r-2 flex-wrap">
              {item.customFields && item.customFields.length > 0 && (
                <React.Fragment>
                  {item.customFields.map(({ id, name }) => (
                    <div className="tag" key={id}>
                      <label>{name}</label>
                      {!viewUniverse && (
                        <DeleteFieldModal
                          companyId={item.id}
                          companyOriginalId={item.companyId}
                          fieldId={id}
                          onSubmitted={onSubmitted}
                        />
                      )}
                    </div>
                  ))}
                </React.Fragment>
              )}
              {!viewUniverse && (
                <UserTypeCheck restrictTo={UserTypes.External}>
                  <AddCustomFieldModal
                    selection={{
                      allSelected: false,
                      selected: [item.id],
                      deselected: []
                    }}
                    onSubmitted={onSubmitted}
                    submitAction={addCompanyTag}
                    allowAdditions={allowTagAdditions}
                  />
                </UserTypeCheck>
              )}
            </div>
          </div>
          <div className="m-t-1 flex flex-wrap space-between">
            <div className="flex space-icons">
              {!viewUniverse && (
                <RequestResearchModal
                  companyId={item.id}
                  companyOriginalId={item.companyId}
                  onSubmitted={getCompanies}
                />
              )}
              {details && !viewUniverse && (
                <PermissionCheck target={Targets.Company} action={Actions.View}>
                  <Link to={CompanyRoutes.Overview(item.id)}>
                    <Popup
                      trigger={
                        <Image src={ViewCompany} className="row-action-icon" />
                      }
                      content="Full Summary"
                      position="bottom right"
                      className="table-popup"
                      mouseEnterDelay={75}
                    />
                  </Link>
                </PermissionCheck>
              )}
            </div>
          </div>
        </section>
      </Transition>
    );
  }
}

export default ExpandedRowContent;
