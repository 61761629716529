import { apiDelete, apiGet, apiPost, apiPut } from "@redriver/cinnamon";
import { addToListBase } from "features/Contacts/ContactsSearch/actions";
import { getViewCompanyState } from "./selectors";

const NAMESPACE = "COMPANIES/OVERVIEW";

export const ActionTypes = {
  GetContacts: `${NAMESPACE}/GET_CONTACTS`,
  GetContactDetails: `${NAMESPACE}/GET_CONTACT_DETAILS`,
  StoreContactFilters: `${NAMESPACE}/STORE_CONTACT_FILTERS`,
  GetLists: `${NAMESPACE}/GET_LISTS`,
  SuppressCompany: `${NAMESPACE}/SUPPRESS_COMPANY`,
  SaveList: `${NAMESPACE}/SAVE_LIST`,
  GetCustomerId: `${NAMESPACE}/GET_CUSTOMER_ID`,
  ChangeFollowing: `${NAMESPACE}/CHANGE_FOLLOWING`,
  AddContactTag: `${NAMESPACE}/ADD_CONTACT_TAG`,
  ClearTags: `${NAMESPACE}/CLEAR_TAGS`,
  DeleteTag: `${NAMESPACE}/DELETE_TAG`
};

export const companyContactCriteria = (filters, companyId) => {
  const {
    textFilter: { filter: search },
    reduxFilter: { lobRoles, techRoles, left },
    excludeCompanyName
  } = filters;
  const searchCriteria = [
    {
      id: "company-criteria",
      type: "companyFilter",
      companyId
    },
    {
      id: "contact-activity",
      left,
      type: "contactActivityFilter"
    },
    {
      id: "search-criteria",
      searchFilter: { search: search || "", excludeCompanyName, left },
      type: "searchFilter",
      universe: false
    }
  ];

  if ((lobRoles && lobRoles.length) || (techRoles && techRoles.length)) {
    var selectedRoles = [...(lobRoles || []), ...(techRoles || [])];
    searchCriteria.push({
      id: "top-level-role-criteria",
      selectedRoles,
      type: "topLevelRoleFilter"
    });
  }

  return searchCriteria;
};

export const getContacts = ({ filters, pagination, selection }, loadParams) => {
  const params = {
    searchCriteria: companyContactCriteria(filters, loadParams.companyId),
    ...pagination
  };
  return apiPost(
    ActionTypes.GetContacts,
    `companies/${loadParams.companyId}/contacts`,
    params
  );
};

export const addToList = (modalData, submitParams) => (dispatch, getState) => {
  const filters = getViewCompanyState(getState()).contactFilters;
  return dispatch(
    addToListBase(
      companyContactCriteria(filters, submitParams.companyId),
      modalData,
      submitParams.selection
    )
  );
};

export const storeContactFilters = filters => ({
  type: ActionTypes.StoreContactFilters,
  filters
});

export const getContactDetails = id =>
  apiGet(
    ActionTypes.GetContactDetails,
    `contacts/${id}/details`,
    {},
    { actionCustomData: { contactId: id } }
  );

export const saveListBase = (
  searchCriteria,
  modalData,
  selection,
  forceDumbList,
  leftCompanyFlag
) => {
  const request = {
    ...modalData,
    selection,
    searchCriteria,
    forceDumbList,
    leftCompanyFlag
  };
  return apiPost(ActionTypes.SaveList, "lists/contact", request);
};

export const saveListCompanyContacts = (modalData, submitParams) => (
  dispatch,
  getState
) => {
  const filters = getViewCompanyState(getState()).contactFilters;
  return dispatch(
    saveListBase(
      companyContactCriteria(filters, submitParams.companyId),
      modalData,
      submitParams.selection,
      true,
      filters.reduxFilter.left
    )
  );
};

export const addToListCompanyContacts = (filters, companyId) => (
  modalData,
  submitParams
) =>
  addToListBase(
    companyContactCriteria(filters, companyId),
    modalData,
    submitParams.selection
  );

export const addContactTag = ({ tag }, { searchCriteria, selection }) =>
  apiPut(ActionTypes.AddContactTag, `contacts/contact-tags`, {
    tag: tag,
    searchCriteria: searchCriteria,
    selection
  });

export const deleteTag = ({ contactId, tagId }) =>
  apiDelete(
    ActionTypes.DeleteTag,
    `contacts/${contactId}/tags`,
    { id: tagId },
    { preventErrorNotification: true }
  );

export const clearTags = ({ selection, formData, customerId }) =>
  apiDelete(ActionTypes.ClearTags, `customers/${customerId}/contact-tags`, {
    selection,
    searchCriteria: formData.searchCriteria
  });

export const getLists = ({ filters, pagination, selection }, companyId) =>
  apiGet(ActionTypes.GetLists, "lists", {
    ...filters,
    ...pagination,
    companyId
  });

export const changeFollowing = (contacts, currentlyFollowing) => {
  const path = `customers/contact-watch-list-items`;
  const ids = contacts.map(c => c.originalIntId);
  const actionType = ActionTypes.ChangeFollowing;
  const args = { contactIds: ids, customerId: null };
  const opts = {
    actionCustomData: { contacts, following: !currentlyFollowing }
  };

  const action = currentlyFollowing
    ? apiDelete(actionType, path, args, opts)
    : apiPost(actionType, path, args, opts);

  return action;
};

export const suppressCompany = companyId =>
  apiPut(ActionTypes.SuppressCompany, `companies/suppress/${companyId}`);

export const getCustomerId = () =>
  apiGet(ActionTypes.GetCustomerId, `users/user-customer-id`);
