import {
  requestError,
  requestIsBusy,
  requestIsSuccess,
  requestResponse
} from "@redriver/cinnamon";
import { ActionTypes } from "./actions";
import Widgets from "./Widgets";

const initialState = {
  widgets: [],
  isSegmented: false,
  loading: false,
  updating: true,
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_WIDGETS:
      const response = requestResponse(action);
      const widgets =
        response && response.widgets
          ? requestResponse(action).widgets
          : Object.keys(Widgets).map(key => {
              return { id: key };
            });
      return {
        ...state,
        widgets: requestIsSuccess(action) ? widgets : state.widgets,
        isSegmented: requestIsSuccess(action)
          ? requestResponse(action).isSegmented
          : state.isSegmented,
        loading: requestIsBusy(action),
        error: requestError(action) || state.error
      };

    case ActionTypes.UPDATE_WIDGETS:
      return {
        ...state,
        updated: requestIsSuccess(action),
        updating: requestIsBusy(action),
        error: requestError(action) || state.error
      };

    case ActionTypes.ADD_WIDGET:
      return { ...state, widgets: [...state.widgets, { id: action.widget }] };

    case ActionTypes.REMOVE_WIDGET:
      return {
        ...state,
        widgets: [...state.widgets.filter(w => w.id !== action.widget)]
      };

    default:
      return state;
  }
};
