import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { CustomFieldNamesDropdown } from "features/Companies/lookups";
import { createCustomFieldValue } from "./actions";

const CreateCustomFieldValue = ({ customerId, onSubmitted }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={createCustomFieldValue}
      submitParams={customerId}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <Button primary content="Add Custom Field Value" onClick={showModal} />
      )}
      renderModal={(modalProps, formProps) => (
        <Modal.Add
          {...modalProps}
          header={
            <div>
              <Icon name="plus" /> Add Custom Field Value
            </div>
          }
          submitLabel="Add Field Value"
          submitDisabled={!formProps.value.name || !formProps.value.value}
          size="tiny"
        >
          <Form {...formProps}>
            <CustomFieldNamesDropdown
              required={true}
              value={formProps.value.name}
              allowAdditions
            />
            <Form.Input field="value" label="Name" required fluid />
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default CreateCustomFieldValue;
