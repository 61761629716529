import React from "react";
import { Table } from "@redriver/cinnamon";
import EditField from "./EditField";
import DeleteField from "./DeleteField";

const FieldActions = ({ customerId, field, onRefresh }) => (
  <Table.Menu direction="left" className="actions-column">
    <EditField
      as={Table.MenuItem}
      customerId={customerId}
      fieldId={field.id}
      fieldName={field.name}
      onUpdate={onRefresh}
    >
      Edit
    </EditField>
    <DeleteField
      as={Table.MenuItem}
      customerId={customerId}
      fieldId={field.id}
      fieldName={field.name}
      fieldUsages={field.usageCount}
      onDelete={onRefresh}
    >
      Delete
    </DeleteField>
  </Table.Menu>
);

export default FieldActions;
