import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes, UserRoutes } from "constants/routes";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PermissionErrorPage from "../PermissionErrorPage";
import ListUserPage from "./ListUserPage";
import UserRoute from "./UserRoute";

export default () => (
  <React.Fragment>
    <PermissionCheck target={Targets.User} action={Actions.View}>
      <Switch>
        <Route
          path={AppRoutes.Users}
          exact
          render={() => <Redirect to={UserRoutes.ExternalUsers} />}
        />
        <Route
          path={UserRoutes.InternalUsers}
          render={() => <ListUserPage userType="Internal" />}
        />
        <Route
          path={UserRoutes.ExternalUsers}
          render={() => <ListUserPage userType="External" requireCustomer />}
        />
        <Route path={UserRoutes.User()} component={UserRoute} />
      </Switch>
    </PermissionCheck>
    <PermissionCheck
      target={Targets.User}
      action={Actions.View}
      not
      component={PermissionErrorPage}
    />
  </React.Fragment>
);
