import React from "react";
import { Row } from "react-data-grid";
import CellRenderer from "./CellRenderer";

class RowRenderer extends React.Component {
  render() {
    return <Row cellRenderer={CellRenderer} ref="row" {...this.props} />;
  }
}

export default RowRenderer;
