import { Breadcrumb, lookupRequest } from "@redriver/cinnamon";
import Home from "assets/icons/home_breadcrumb.svg";
import { AppRoutes, CompanyRoutes } from "constants/routes";
import {
  CompanyBreadcrumbItem,
  CompanyContacts,
  CompanyLists,
  CompanyMenu,
  CompanyOverview,
  CompanyStructure
} from "features/Companies";
import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Grid, Image } from "semantic-ui-react";

class CompanyRoute extends React.Component {
  constructor(props) {
    super(props);

    // Set the history stack location when hitting this page so we can route back.
    // Be aware: If the page is reloaded the history length in react router is maintained.
    // The minimum history length is 1
    // If opened in a new tab length is 1
    const { history } = this.props;
    this.state = {
      historyLen: history.length
    };
  }

  componentDidMount() {
    const { lookupRequest, match } = this.props;
    lookupRequest("companyLookup", match.params.id);
  }

  routeBack = () => {
    const { historyLen } = this.state;
    const { history } = this.props;
    const amountBack = historyLen - history.length - 1;

    // Route to companies if at start of stack or invalid navigation amount
    if (amountBack * -1 >= history.length || amountBack >= 0) {
      history.push(AppRoutes.Companies);
    } else {
      history.go(amountBack);
    }
  };

  render() {
    const { match } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb className="i4b-breadcrumb-container">
          <Breadcrumb.Item
            link={AppRoutes.Root}
            icon={<Image src={Home} title="Home" />}
          />
          <Breadcrumb.Item onClick={this.routeBack} content="Search" />
          <CompanyBreadcrumbItem companyId={match.params.id} />
        </Breadcrumb>
        <Grid className="customer-page p-t-1">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <CompanyMenu companyId={match.params.id} />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={14}
              className="m-b-1"
              stretched
            >
              <Switch>
                <Route
                  exact
                  path={CompanyRoutes.Overview()}
                  render={props => (
                    <CompanyOverview companyId={match.params.id} />
                  )}
                />
                <Route
                  exact
                  path={CompanyRoutes.Contacts()}
                  render={props => (
                    <CompanyContacts companyId={match.params.id} />
                  )}
                />
                />
                <Route
                  exact
                  path={CompanyRoutes.Lists()}
                  render={props => <CompanyLists companyId={match.params.id} />}
                />
                <Route
                  exact
                  path={CompanyRoutes.OrgStructure()}
                  render={props => (
                    <CompanyStructure companyId={match.params.id} />
                  )}
                />
              </Switch>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const actions = {
  lookupRequest
};

export default connect(
  mapStateToProps,
  actions
)(CompanyRoute);
