import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";
import TreeViewCriteria from "./TreeViewCriteria";

const TechRolesCriteria = ({ active, handleClick, criteria }) => {
  const { selectedTechRoles } = criteria;
  if (!selectedTechRoles.length) return null;

  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Technology Roles"
        text={selectedTechRoles.join(", ")}
      />
      <Accordion.Content active={active}>
        <TreeViewCriteria
          criteria={criteria}
          lookup="techRolesCriteriaLookup"
        />
      </Accordion.Content>
    </React.Fragment>
  );
};

export default TechRolesCriteria;
