import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { Targets, Actions, UserTypes } from "constants/permissions";
import { PermissionCheck, UserTypeCheck } from "components/auth";
import { updateStatus } from "../actions";
import { ListStatusesDropdown } from "../../lookups";

const UpdateStatusModal = ({ onSubmitted, isContactList, listId }) => (
  <ModalBuilder
    withForm
    submitAction={updateStatus}
    onSubmitted={onSubmitted}
    renderTrigger={showModal => (
      <UserTypeCheck restrictTo={UserTypes.External}>
        <PermissionCheck target={Targets.ItemStatus} action={Actions.Edit}>
          <div className="dropdown-action-container" onClick={showModal}>
            Update List Status
          </div>
        </PermissionCheck>
      </UserTypeCheck>
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Update List Status" size="tiny">
        <Form {...formProps}>
          <ListStatusesDropdown
            value={formProps.value.status}
            required={true}
            allowAdditions={true}
            listId={listId}
            isContactList={isContactList}
          />
          <Form.Input
            field="comment"
            label="Comment"
            placeholder="Comment"
            fluid
          />
        </Form>
      </Modal.Edit>
    )}
  />
);

export default UpdateStatusModal;
