import { Lookup } from "@redriver/cinnamon";
import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";

const UploadsCriteria = ({ active, handleClick, criteria }) => {
  const { included, excluded } = criteria.uploadsSearch.uploadsSelection;

  if (!included.length && !excluded.length) return null;

  return (
    <Lookup
      lookup={"uploadsCriteriaLookup"}
      lookupParams={criteria}
      render={({ response }, refresh) => {
        if (!response) return null;
        const includedItems = response.included.join(", ");
        const excludedItems = response.excluded.join(", ");
        return (
          <React.Fragment>
            <ReadOnlyHeader
              active={active}
              handleClick={handleClick}
              criteria={criteria}
              name="Uploads"
              text={
                <p style={{ fontSize: "1em" }}>
                  Included: {included.length} Excluded: {excluded.length}
                </p>
              }
            />
            <Accordion.Content active={active}>
              <div>
                {includedItems && (
                  <p style={{ fontSize: "1em" }}>Included: {includedItems}</p>
                )}
                {excludedItems && (
                  <p style={{ fontSize: "1em" }}>Excluded: {excludedItems}</p>
                )}
              </div>
            </Accordion.Content>
          </React.Fragment>
        );
      }}
    />
  );
};

export default UploadsCriteria;
