import { PageLoader } from "@redriver/cinnamon";
import { MultiSelectTreeDropdown } from "components/form";
import React from "react";
import { TechRolesLookup } from "../lookups";

class TechRoleFilter extends React.Component {
  shouldComponentUpdate(nextProps) {
    return false;
  }

  render() {
    return (
      <TechRolesLookup
        render={({ response }, refresh) => (
          <React.Fragment>
            {response ? (
              <MultiSelectTreeDropdown
                field="selectedTechRoles"
                nodes={response.children || []}
                flavor={{
                  included: {
                    [0]: {
                      label: "IT Roles",
                      placeholder: "Select Tech Roles to include"
                    }
                  }
                }}
              />
            ) : (
              <PageLoader
                active
                inline="centered"
                size="small"
                className="p-y-05"
              />
            )}
          </React.Fragment>
        )}
      />
    );
  }
}

export default TechRoleFilter;
