import { getAuthState } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Shell from "./Shell";

class InactivityMonitor extends React.Component {
  _intervalId = false;
  componentDidMount() {
    this._intervalId = setInterval(async () => {
      const lastAction = localStorage.getItem("lastAction");
      if (!lastAction) return;
      if (
        moment().diff(moment(lastAction), "seconds") >
          process.env.INACTIVITY_TIMEOUT_SECONDS &&
        this.props.loggedIn
      ) {
        this.props.history.push(SystemRoutes.Logout);
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._intervalId && clearInterval(this._intervalId);
  }

  render() {
    return <Shell />;
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: getAuthState(state).loggedIn
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(InactivityMonitor)
);
