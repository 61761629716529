import React from "react";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";

import { FilterBase } from "components/search";
import { getListsOverviewState } from "../selectors";
import EditNotesModal from "./EditNotes";
import { loadOverviewModal, saveNotes } from "../actions";

class ListNotes extends React.Component {
  state = {
    visible: false
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  render() {
    const { visible } = this.state;
    const { listId, notes } = this.props;

    return (
      <Segment className="m-t-1" style={{ padding: 0, width: "100%" }}>
        <section className={`search-filter`}>
          <FilterBase.Header
            title="Notes"
            collapsible
            collapsed={!visible}
            onCollapseToggle={this.toggleVisibility}
            removable={false}
          />
          <FilterBase.Body collapsed={!visible}>
            <div className="list-notes-container">
              <div className="list-notes">{notes}</div>
              <EditNotesModal
                loadAction={loadOverviewModal}
                loadParams={listId}
                loadTransform={data => {
                  return { notes: data.notes };
                }}
                submitParams={{ listId }}
                submitAction={saveNotes}
                hasNotes={!!(notes && notes.length)}
              />
            </div>
          </FilterBase.Body>
        </section>
      </Segment>
    );
  }
}

const actions = {};

const mapStateToProps = state => {
  const { listId, notes } = getListsOverviewState(state);
  return {
    listId,
    notes
  };
};

export default connect(
  mapStateToProps,
  actions
)(ListNotes);
