import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Portal, Button } from "semantic-ui-react";

import { updateSidePanelState } from "./actions";

class SlidePanel extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      ...this.setInnerOuterProps(props)
    };
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.show === nextProps.show) return;
    this.setState(this.setInnerOuterProps(nextProps));
    this.props.updateSidePanelState(nextProps.show);
  };

  componentWillUnmount() {
    this.props.updateSidePanelState(false);
  }

  setInnerOuterProps = props => {
    clearTimeout(this.timeout);

    if (props.show) {
      this.timeout = setTimeout(
        () =>
          this.setState(
            {
              innerOpen: true
            },
            this.focus
          ),
        200
      );
      return { outerOpen: true };
    }
    if (!props.show) {
      this.timeout = setTimeout(
        () =>
          this.setState({
            outerOpen: false
          }),
        300
      );
      return { innerOpen: false };
    }
  };

  focus = () => {
    this.closeBtn.focus();
  };

  render() {
    const { children, className, onClose } = this.props;
    const { outerOpen, innerOpen } = this.state;
    return (
      <Portal open onClose={onClose} closeOnDocumentClick={false}>
        <div className={`i4b-slide-panel ${innerOpen ? "active" : ""}`}>
          {outerOpen && (
            <div className={className}>
              <Button
                ref={c => (this.closeBtn = c)}
                basic
                circular
                icon="close"
                onClick={() => onClose()}
              />
              <div className="i4b-slide-panel-content">{children}</div>
            </div>
          )}
        </div>
      </Portal>
    );
  }
}

export default connect(
  null,
  {
    updateSidePanelState
  }
)(SlidePanel);
