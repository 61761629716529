import { Format } from "@redriver/cinnamon";
import React from "react";
import { Icon, Popup, Segment } from "semantic-ui-react";

class SearchSegmentToggle extends React.Component {
  selectUniverse = () => this.props.updateSegmentSelection("universe");
  selectSegment = () => this.props.updateSegmentSelection("segment");

  render() {
    const {
      segmentSelected,
      totalSegmentResults,
      totalUniverseResults,
      disableUniverse
    } = this.props;

    return (
      <React.Fragment>
        {!disableUniverse && (
          <Segment className="m-t-0 p-a-0" style={{ display: "flex" }}>
            <div
              className={`segment-toggle${
                segmentSelected == null || segmentSelected ? " checked" : ""
              }`}
              onClick={this.selectSegment}
            >
              <Popup
                content="The data you have access to within your segment"
                trigger={<Icon name="info circle" size="small" />}
              />
              <div className="segment-toggle-title">
                <p>My Segment Data</p>
                <Icon name="check circle" size="small" />
              </div>
              {totalSegmentResults === null ? (
                ""
              ) : totalSegmentResults > 0 ? (
                <p style={{ fontSize: "0.75rem" }}>
                  <strong>
                    <Format.Number value={totalSegmentResults} />
                  </strong>{" "}
                  results available
                </p>
              ) : (
                <p style={{ fontSize: "0.75rem" }}>No results available</p>
              )}
            </div>
            <div
              className={`segment-toggle${
                segmentSelected !== null && !segmentSelected ? " checked" : ""
              }`}
              onClick={this.selectUniverse}
            >
              <Popup
                content="All available data outside my segment"
                trigger={<Icon name="info circle" size="small" />}
              />
              <div className="segment-toggle-title">
                <p>Outside My Segment</p>
                <Icon name="check circle" size="small" />
              </div>
              {totalUniverseResults === null ? (
                ""
              ) : totalUniverseResults > 0 ? (
                <p style={{ fontSize: "0.75rem" }}>
                  <strong>
                    <Format.Number value={totalUniverseResults} />
                  </strong>{" "}
                  results available
                </p>
              ) : (
                <p style={{ fontSize: "0.75rem" }}>No results available</p>
              )}
            </div>
          </Segment>
        )}
      </React.Fragment>
    );
  }
}

export default SearchSegmentToggle;
