import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";

const RemoveItemsModal = ({
  submitAction,
  onSubmitted,
  isContactList,
  numSelected
}) => (
  <ModalBuilder
    submitAction={submitAction}
    onSubmitted={onSubmitted}
    renderTrigger={showModal => (
      <PermissionCheck target={Targets.Items} action={Actions.Remove}>
        {isContactList ? (
          <div className="dropdown-action-container" onClick={showModal}>
            Remove Contacts
          </div>
        ) : (
          <div className="dropdown-action-container" onClick={showModal}>
            Remove Companies
          </div>
        )}
      </PermissionCheck>
    )}
    renderModal={modalProps => (
      <Modal.Confirmation
        {...modalProps}
        header={isContactList ? "Remove Contacts" : "Remove Companies"}
      >
        <p>{`Are you sure you would like to remove ${numSelected} ${
          isContactList
            ? numSelected > 1
              ? "contacts"
              : "contact"
            : numSelected > 1
            ? "companies"
            : "company"
        } from this list?`}</p>
      </Modal.Confirmation>
    )}
  />
);

export default RemoveItemsModal;
