import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Icon } from "semantic-ui-react";
import { removeSuppression } from "./actions";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/permissions";
import { deleteContactSuppression } from "./actions";

const DeleteContactSuppressionModal = ({
  suppression,
  customerId,
  onSubmitted
}) => (
  <ModalBuilder
    submitAction={deleteContactSuppression}
    submitParams={{ customerId, suppression }}
    onSubmitted={onSubmitted}
    renderTrigger={showModal => <p onClick={showModal}>Remove</p>}
    renderModal={modalProps => (
      <Modal.Confirmation
        {...modalProps}
        header={
          <div>
            <Icon name="warning sign" /> Remove Contact Suppression
          </div>
        }
        size="tiny"
      >
        <p>
          Are you sure you want to remove the suppression for{" "}
          <strong>{suppression.fullName}</strong> at {suppression.companyName}?
        </p>
      </Modal.Confirmation>
    )}
  />
);

export default DeleteContactSuppressionModal;
