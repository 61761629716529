import {
  requestIsBusy,
  requestIsSuccess,
  requestResponse
} from "@redriver/cinnamon";
import { mergeSearchCriteria } from "features/Utils";
import { difference, find, uniq } from "lodash";
import {
  addSearchFilter,
  clearJobsFilter,
  clearJobTitlesFilter,
  clearSearchFilter,
  clearSelected,
  clearSizeFilter,
  removeFilter
} from "modules/reducers";
import { ActionTypes } from "./actions";

const initialState = {
  contactsLoading: false,
  dimmerLoading: false,
  contacts: {
    results: [],
    totalResults: 0,
    totalWithEmail: 0,
    totalWithoutEmail: 0,
    criteriaResultCount: []
  },
  contactsUniverseCount: null,
  contactsSegmentCount: null,
  contactDetails: {},
  criteriaIsSystemGenerated: true,
  criteriaId: null,
  customerId: null,
  customerIdLoading: true,
  formData: {
    searchCriteriaUserId: null,
    searchCriteria: [
      {
        id: "0",
        searchFilter: {
          search: ""
        },
        type: "searchFilter"
      },
      {
        id: "1",
        countries: [
          {
            region: "",
            options: []
          }
        ],
        type: "countriesFilter",
        selectedCountries: []
      }
    ],
    searchTotal: 0
  },
  pageNumber: 1,
  pageSize: 200,
  selection: { allSelected: false, selected: [], deselected: [] },
  selectedItems: null,
  pageChanged: false,
  tableKey: 0,
  expandedRows: [],
  isSegmented: false,
  isUniverse: false,
  unfilteredSelectedCountries: {
    universe: [],
    segment: []
  }
};

const updateListsFilter = formData => {
  const listsFilter = find(formData.searchCriteria, { type: "listsFilter" });
  if (listsFilter) {
    listsFilter.listsSearch.isContact = true;
  }
};

const updateuploadsFilter = formData => {
  const uploadsFilter = find(formData.searchCriteria, {
    type: "contactUploadsFilter"
  });
  if (uploadsFilter) {
    uploadsFilter.uploadsSearch.isContact = true;
  }

  const companyUploadsFilter = find(formData.searchCriteria, {
    type: "companyUploadsFilter"
  });
  if (companyUploadsFilter) {
    companyUploadsFilter.uploadsSearch.isContact = false;
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GetContacts:
      return {
        ...state,
        //use requestResponse(action) and requestIsBusy(action)
        contactsLoading:
          requestIsBusy(action) &&
          !(action.customData && action.customData.dimmerLoading),
        dimmerLoading:
          requestIsBusy(action) &&
          (action.customData && action.customData.dimmerLoading),
        contacts: requestResponse(action) || state.contacts,
        pageChanged: requestIsSuccess(action) ? false : state.pageChanged,
        contactDetails: requestResponse(action) ? {} : state.contactDetails,
        isSegmented: requestIsSuccess(action)
          ? requestResponse(action).isSegmented
          : false
      };
    case ActionTypes.UpdateForm:
      const newFormData = action.applyChanges(state.formData);
      updateListsFilter(newFormData);
      updateuploadsFilter(newFormData);

      return {
        ...state,
        formData: newFormData,
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null,
        pageNumber: 1
      };
    case ActionTypes.GetFormData: {
      const res = requestResponse(action);

      if (res == null) return state;
      const formData = {
        ...state.formData,
        searchCriteriaUserId: res.userId,
        searchCriteria:
          res.criteria.length > 0
            ? [...res.criteria]
            : initialState.formData.searchCriteria
      };
      let selectedCountryCache = [];
      const searchCriteria = formData.searchCriteria.map(s => {
        if (s.type !== "countriesFilter") return s;

        if (s.selectedCountries.length) {
          selectedCountryCache = selectedCountryCache.concat(
            s.selectedCountries
          );
        }

        return {
          ...s,
          selectedCountries: []
        };
      });
      return {
        ...state,
        criteriaIsSystemGenerated: res.isSystemGenerated,
        criteriaId: res.id,
        isUniverse: res.isUniverse,
        formData: formData,
        unfilteredSelectedCountries: {
          universe: selectedCountryCache,
          segment: selectedCountryCache
        }
      };
    }

    case ActionTypes.GetListFormData: {
      const res = requestResponse(action);

      if (res == null) return state;

      return {
        ...state,
        criteriaIsSystemGenerated: false,
        criteriaId: res.criteriaId,
        formData: {
          ...state.formData,
          searchCriteria: [...res.criteria]
        }
      };
    }
    case ActionTypes.UpdatePageNumber:
      const { pageNumber } = action.value;

      return {
        ...state,
        pageNumber: pageNumber,
        pageChanged: true
      };
    case ActionTypes.UpdateSearchFilter:
      const { searchText } = action.value;
      const newCriteria = [
        {
          id: "0",
          type: "searchFilter",
          searchFilter: {
            search: searchText
          }
        }
      ];

      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: [
            ...mergeSearchCriteria(state.formData.searchCriteria, newCriteria)
          ]
        }
      };
    case ActionTypes.ResetSearch:
      return {
        ...initialState
      };
    case ActionTypes.ChangeSelection:
      const { selection } = action.value;
      return {
        ...state,
        selection: selection,
        selectedItems:
          selection.selected.length > 0
            ? selection.selected.length
            : selection.allSelected
            ? state.contacts.totalResults - selection.deselected.length
            : null
      };
    case ActionTypes.ChangeFollowing:
      const loading = requestIsBusy(action);
      const response = requestResponse(action);
      const success = requestIsSuccess(action);

      const newResults = [...state.contacts.results];

      if (action.customData && action.customData.contacts) {
        for (var i = 0; i < action.customData.contacts.length; i++) {
          const contactId = action.customData.contacts[i].originalIntId;
          const contact = find(newResults, c => c.originalIntId === contactId);
          if (contact) {
            if (success === false) {
              contact.followingUpdating = false;
            } else if (response) {
              contact.following = action.customData.following;
              contact.followingUpdating = false;
            } else {
              contact.followingUpdating = true;
            }
          }
        }
      }

      return {
        ...state,
        contacts: {
          ...state.contacts,
          results: newResults
        }
      };

    case ActionTypes.ResetResults:
      return {
        ...state,
        contacts: { ...initialState.contacts },
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null
      };
    case ActionTypes.ClearSearchFilter:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: clearSearchFilter(state)
        },
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null
      };
    case ActionTypes.ClearSelected:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: clearSelected(state, action.value)
        },
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null
      };
    case ActionTypes.AddSearchFilter:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: addSearchFilter(state, action.value)
        }
      };
    case ActionTypes.RemoveFilter:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: removeFilter(state, action.value)
        },
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null
      };
    case ActionTypes.ClearSizeFilter:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: clearSizeFilter(state)
        },
        selection: {},
        selectedItems: null
      };
    case ActionTypes.ClearJobTitlesFilter:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: clearJobTitlesFilter(state)
        },
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null
      };
    case ActionTypes.ClearJobsFilter:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: clearJobsFilter(state)
        },
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null
      };

    case ActionTypes.LoadSearchById:
      var res = requestResponse(action);
      return requestIsBusy(action)
        ? state
        : {
            ...state,
            formData: {
              ...state.formData,
              searchCriteria: res.criteria
            },
            isUniverse: res.isUniverse
          };
    case ActionTypes.GetCustomerId:
      var res = requestResponse(action);
      return requestIsBusy(action)
        ? state
        : {
            ...state,
            customerId: res.customerId,
            customerIdLoading: false
          };

    case ActionTypes.GetContactDetails:
      const res = requestResponse(action);
      const detailsLoading = requestIsBusy(action);
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          [action.customData.contactId]: { data: res, loading: detailsLoading }
        }
      };

    case ActionTypes.ClearTags: {
      return {
        ...state,
        contactDetails: {},
        tableKey: (state.tableKey += 1)
      };
    }

    case ActionTypes.GetUniverseContactsCount:
      return {
        ...state,
        contactsUniverseCount: requestResponse(action)
      };

    case ActionTypes.GetSegmentContactsCount:
      return {
        ...state,
        contactsSegmentCount: requestResponse(action)
      };

    case ActionTypes.UpdateSegmentSelection: {
      const { unfilteredSelectedCountries } = state;
      const { selected } = action.value;
      const isUniverse = selected === "universe";
      let selectedCountryCache = [];
      const searchCriteria = state.formData.searchCriteria.map(s => {
        if (s.type !== "countriesFilter") return s;

        if (s.selectedCountries.length) {
          selectedCountryCache = selectedCountryCache.concat(
            s.selectedCountries
          );
        }

        return {
          ...s,
          selectedCountries: []
        };
      });

      let currentCache =
        unfilteredSelectedCountries[isUniverse ? "segment" : "universe"];
      let nextCache =
        unfilteredSelectedCountries[isUniverse ? "universe" : "segment"];

      const cachedRemoved = difference(currentCache, selectedCountryCache);

      //removing countries
      currentCache = selectedCountryCache.filter(
        c => !cachedRemoved.includes(c)
      );
      nextCache = nextCache.filter(c => !cachedRemoved.includes(c));

      //adding countries
      nextCache = uniq(currentCache.concat(nextCache));
      unfilteredSelectedCountries[
        isUniverse ? "segment" : "universe"
      ] = currentCache;
      unfilteredSelectedCountries[
        isUniverse ? "universe" : "segment"
      ] = nextCache;

      return {
        ...state,
        formData: {
          ...state.formData,
          contactDetails: {},
          searchCriteria
        },
        isUniverse: isUniverse,
        unfilteredSelectedCountries: unfilteredSelectedCountries,
        selection: initialState.selection,
        selectedItems: initialState.selectedItems
      };
    }

    case ActionTypes.UpdateSelectedCountries: {
      const { selected, viewingUniverse } = action.value;
      const { unfilteredSelectedCountries } = state;
      const searchCriteria = state.formData.searchCriteria.map(s => {
        if (s.type !== "countriesFilter") return s;

        return {
          ...s,
          selectedCountries: selected
        };
      });

      unfilteredSelectedCountries[
        viewingUniverse ? "universe" : "segment"
      ] = selected;

      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria
        },
        unfilteredSelectedCountries
      };
    }

    case ActionTypes.SetExpandedRows:
      const { rows } = action;
      return {
        ...state,
        expandedRows: rows
      };

    default:
      return state;
  }
};
