import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Icon } from "semantic-ui-react";
import { deleteField } from "./actions";
import { Targets, Actions, UserTypes } from "constants/permissions";
import { PermissionCheck, UserTypeCheck } from "components/auth";

const DeleteFieldModal = ({ companyOriginalId, fieldId, onSubmitted }) => {
  return (
    <ModalBuilder
      submitAction={deleteField}
      submitParams={{ companyId: companyOriginalId, fieldId }}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <UserTypeCheck restrictTo={UserTypes.External}>
          <PermissionCheck target={Targets.ItemTags} action={Actions.Edit}>
            <Icon name="delete" onClick={showModal} />
          </PermissionCheck>
        </UserTypeCheck>
      )}
      renderModal={(modalProps, data) => (
        <Modal.Delete
          {...modalProps}
          header={
            <div>
              <Icon name="trash" /> Remove Company Custom Field
            </div>
          }
          submitLabel="Remove Field"
          size="tiny"
        >
          <p>
            Are you sure you want to remove the custom field from this company?
          </p>
        </Modal.Delete>
      )}
    />
  );
};

export default DeleteFieldModal;
