import { apiGet, apiPost, apiPut } from "@redriver/cinnamon";
import { getDashboardState } from "./selectors";
const NAMESPACE = "DASHBOARD";
export const ActionTypes = {
  GET_COMPANIES: `${NAMESPACE}/GET_COMPANIES`,
  CREATE_RESEARCH_REQUEST: `${NAMESPACE}/CREATE_RESEARCH_REQUEST`,
  RESET_VIEW: `${NAMESPACE}/RESET_VIEW`,
  GET_WIDGETS: `${NAMESPACE}/GET_WIDGETS`,
  UPDATE_WIDGETS: `${NAMESPACE}/UPDATE_WIDGETS`,
  ADD_WIDGET: `${NAMESPACE}/ADD_WIDGET`,
  REMOVE_WIDGET: `${NAMESPACE}/REMOVE_WIDGET`,
  GET_MY_FEED: `${NAMESPACE}/GET_MY_FEED`,
  GetSegmentData: `${NAMESPACE}/GET_SEGMENT_DATA`,
  GET_RECENTLY_VIEWED: `${NAMESPACE}/GET_RECENTLY_VIEWED`
};

export const addWidget = widget => (dispatch, getState) => {
  dispatch({ type: ActionTypes.ADD_WIDGET, widget });
  const s = getDashboardState(getState());
  dispatch(updateWidgets(s.widgets));
};

export const removeWidget = widget => (dispatch, getState) => {
  dispatch({ type: ActionTypes.REMOVE_WIDGET, widget });
  const s = getDashboardState(getState());
  dispatch(updateWidgets(s.widgets));
};

export const getWidgets = () =>
  apiGet(ActionTypes.GET_WIDGETS, `dashboard/widgets`);

export const updateWidgets = widgets =>
  apiPut(ActionTypes.UPDATE_WIDGETS, `dashboard/widgets`, {
    widgets
  });

export const resetView = () => dispatch => {
  dispatch({ type: ActionTypes.RESET_VIEW });
  dispatch(getWidgets());
};

export const submitResearchRequest = ({ requestText }) =>
  apiPost(ActionTypes.CREATE_RESEARCH_REQUEST, `research`, {
    requestText: requestText.trim()
  });

export const getMyFeedData = () =>
  apiGet(ActionTypes.GET_MY_FEED, "dashboard/my-feed");

export const getSegmentDeta = () =>
  apiGet(ActionTypes.GetSegmentData, "dashboard/segment");

export const getRecentlyViewedData = () =>
  apiGet(ActionTypes.GET_RECENTLY_VIEWED, "dashboard/recently-viewed");
