import {
  connectForm,
  Field,
  FieldError,
  formUtils,
  formValidators
} from "@redriver/cinnamon";
import { uniq } from "lodash";
import PropTypes from "prop-types";
import { default as React } from "react";
import { Dropdown, Icon } from "semantic-ui-react";

class IncExcDropdown extends React.Component {
  static propTypes = {
    //Modified proptype of value to accept an object value
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    showErrors: PropTypes.bool,
    allErrors: PropTypes.bool,
    disabled: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fluid: PropTypes.bool,
    required: PropTypes.bool,
    passThruProps: PropTypes.object,
    /**
     * The current state of the form relative to this field (supplied by the form connection)
     * @property {Object} `fields` Form field data, either for the entire form or the current level when Object/Array fields are used
     * @property {Object} `parentFields` Form field data for the parent level when Object/Array fields are used
     * @property {Object} `formFields` Form field data for the entire form
     * @property {number} `arrayIndex` Index of the current row when used inside an Array field
     */
    formState: PropTypes.object,
    /**
     * Array of tree nodes objects in format [{ value: "", text: "", children: [] }]
     * @property {any} `value` Unique key for the node (note that only leaf nodes are actual selectable values in the tree)
     * @property {string} `text` Label for the node checkbox
     * @property {Array} `children` Optional child node objects
     */
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.object),
        defaultExpanded: PropTypes.bool,
        className: PropTypes.string,
        disabled: PropTypes.bool
      })
    ).isRequired,
    /**
     * The depth of the tree (number of required dropdowns)
     */
    layers: PropTypes.number.isRequired,
    flavor: PropTypes.shape({
      included: PropTypes.object,
      excluded: PropTypes.object
    })
  };

  static defaultProps = {
    value: {
      included: [],
      excluded: []
    },
    onChange: () => {},
    layers: 1,
    flavor: {
      included: {
        [0]: {
          label: "Included",
          placeholder: "Select to include"
        }
      },
      excluded: {
        [0]: {
          label: "Excluded",
          placeholder: "Select to exclude"
        }
      }
    }
  };

  state = {
    included: [],
    excluded: []
  };

  onInclude = (dd, ev) => {
    const {
      value: { included, excluded }
    } = this.props;

    const selected = ev.options.find(o => o.value == ev.value);

    this.props.onChange({
      included: uniq([...included, selected.value]),
      excluded: excluded.filter(e => e != ev.value)
    });
  };

  onExclude = (dd, ev) => {
    const { value } = this.props;
    const { included, excluded } = value;

    const selected = ev.options.find(o => o.value == ev.value);

    this.props.onChange({
      included: included.filter(e => e != ev.value),
      excluded: uniq([...excluded, selected.value])
    });
  };

  removeItem = id => {
    const { value } = this.props;
    const { included, excluded } = value;

    this.props.onChange({
      included: included.filter(e => e != id),
      excluded: excluded.filter(e => e != id)
    });
  };

  renderSelection(selection, className) {
    const { nodes } = this.props;
    const extractTags = (node, parents) => {
      if (node.children && node.children.length)
        return node.children.reduce(
          (acc, n) => [...acc, ...extractTags(n, [...parents, node])],
          []
        );

      if (selection.includes(node.value))
        return [
          <div className={"selection " + className} key={node.value}>
            <label>
              {parents
                .concat(node)
                .map(n => n.text)
                .join(" - ")}
            </label>
            <Icon name="delete" onClick={() => this.removeItem(node.value)} />
          </div>
        ];

      return [];
    };

    const result = nodes.reduce(
      (acc, n) => [...acc, ...extractTags(n, [])],
      []
    );

    return <div className="selections">{result}</div>;
  }

  renderDropdown(onChange, key) {
    const { layers, nodes: topLevelNodes, disabled, flavor } = this.props;

    const dropdowns = [];
    const stateArray = this.state[key];
    const selections = this.props.value[key];

    const allLabels = flavor && flavor[key];

    for (let i = 0; i < layers; i += 1) {
      let options = (i == 0 && topLevelNodes) || [];
      let val = null;

      const labels = allLabels && allLabels[i];

      // If this was previously filled in
      if (stateArray.length > i) {
        val = stateArray[i];
      }

      // If parent dropdown filled in
      if (stateArray.length >= i) {
        let prev = stateArray[i - 1];

        if (prev) {
          options = prev.next;
        }
      }
      const isLast = i + 1 == layers;
      const onDropChange = isLast
        ? onChange
        : (dd, ev) => {
            const selected = ev.options.find(s => s.value == ev.value);
            const newSelected = [...stateArray.slice(0, i), selected];
            this.setState({
              [key]: newSelected
            });
          };

      const mappedOptions = options
        .filter(o => !selections.includes(o.value))
        .map(a => ({
          text: a.text,
          value: a.value,
          next: a.children || []
        }));

      dropdowns.push(
        <div key={key + i} className="drop-labels">
          {labels &&
            labels.label &&
            (typeof labels.label === "string" ? (
              <label>{labels.label}</label>
            ) : (
              labels.label
            ))}
          <Dropdown
            value={val && val.value}
            onChange={onDropChange}
            selectOnBlur={false}
            selection
            options={mappedOptions}
            disabled={disabled || !options.length}
            search
            placeholder={labels && labels.placeholder}
            closeOnChange={!(isLast && mappedOptions.length)}
          />
        </div>
      );
    }
    return <div className="inc-exc-dropdowns">{dropdowns}</div>;
  }

  render() {
    const {
      errors,
      showErrors,
      allErrors,
      disabled,
      width,
      fluid,
      required
    } = this.props;

    const {
      value: { included, excluded }
    } = this.props;

    const hasErrors = showErrors && !!errors && errors.length > 0;

    return (
      <Field
        required={required}
        error={hasErrors}
        disabled={disabled}
        width={width}
        fluid={fluid}
        className="inc-exc"
      >
        <div>
          {this.renderDropdown(this.onInclude, "included")}
          {this.renderSelection(included, "included")}
        </div>

        <div>
          {this.renderDropdown(this.onExclude, "excluded")}
          {this.renderSelection(excluded, "excluded")}
        </div>

        {showErrors && <FieldError errors={errors} showAll={allErrors} />}
      </Field>
    );
  }
}

export default connectForm({
  displayName: props =>
    props.label && typeof props.label === "string"
      ? props.label
      : formUtils.prettifyField(props.field),
  validators: [formValidators.requiredField(false)]
})(IncExcDropdown);
