import React from "react";
import CompanyFollowedCriteria from "./CompanyFollowedCriteria";
import CompanySizeCriteria from "./CompanySizeCriteria";
import ContactFollowedCriteria from "./ContactFollowedCriteria";
import ContactsInCompanyListsCriteria from "./ContactsInCompanyListsCriteria";
import ContactTagsCriteria from "./ContactTagsCriteria";
import CountriesCriteria from "./CountriesCriteria";
import CustomFieldsCriteria from "./CustomFieldsCriteria";
import EmailDdiCriteria from "./EmailDdiCriteria";
import IndustriesCriteria from "./IndustriesCriteria";
import JobsCriteria from "./JobsCriteria";
import JobTitleCriteria from "./JobTitleCriteria";
import LanguagesCriteria from "./LanguagesCriteria";
import ListsCriteria from "./ListsCriteria";
import LOBRolesCriteria from "./LOBRolesCriteria";
// Pull all lookup registrations into the bundle
import "./lookups";
import PostalAreasCriteria from "./PostalAreasCriteria";
import StockExchangeCriteria from "./StockExchangeCriteria";
import TechRolesCriteria from "./TechRolesCriteria";
import TextCriteria from "./TextCriteria";
import UploadsCriteria from "./UploadsCriteria";

const BlackHole = ({ criteria }) => (
  <div>{console.warn("Unknown search criteria", criteria)}</div>
);

export const mapCriteriaToComponent = criteria => {
  switch (criteria.type) {
    case "countriesFilter":
      return CountriesCriteria;

    case "postalAreasFilter":
      return PostalAreasCriteria;
    case "languagesFilter":
      return LanguagesCriteria;

    case "searchFilter":
      return TextCriteria;
    case "jobTitlesFilter":
      return JobTitleCriteria;
    case "techRolesFilter":
      return TechRolesCriteria;
    case "industriesFilter":
      return IndustriesCriteria;
    case "stockExchangeIndexesFilter":
      return StockExchangeCriteria;

    case "LOBRolesFilter":
      return LOBRolesCriteria;
    case "sizeFilter":
      return CompanySizeCriteria;
    case "contactTagsFilter":
      return ContactTagsCriteria;

    case "listsFilter":
      return ListsCriteria;

    case "contactUploadsFilter":
    case "companyUploadsFilter":
      return UploadsCriteria;

    case "customFieldsFilter":
      return CustomFieldsCriteria;

    case "jobsFilter":
      return JobsCriteria;

    case "contactFollowedFilter":
      return ContactFollowedCriteria;

    case "companyFollowedFilter":
      return CompanyFollowedCriteria;

    case "contactsInCompanyListsFilter":
      return ContactsInCompanyListsCriteria;

    case "emailDdiFilter":
      return EmailDdiCriteria;

    default:
      return BlackHole;
  }
};
