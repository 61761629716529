import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import DeleteRole from "./DeleteRole";
import EditRole from "./EditRole";

const ActionsColumn = ({ item, onChange }) => (
  <Table.Menu icon="ellipsis horizontal" className="actions-column">
    <EditRole
      role={item}
      onSubmitted={onChange}
      renderTrigger={showModal => (
        <PermissionCheck
          target={Targets.Role}
          action={Actions.Edit}
          renderFailure={() => null}
        >
          <Table.MenuItem onClick={showModal} content="Edit" />
        </PermissionCheck>
      )}
    />
    <DeleteRole
      id={item.id}
      onSubmitted={onChange}
      renderTrigger={showModal => (
        <PermissionCheck
          target={Targets.Role}
          action={Actions.Delete}
          renderFailure={() => null}
        >
          <Table.MenuItem onClick={showModal} content="Delete" />
        </PermissionCheck>
      )}
    />
  </Table.Menu>
);

export default ActionsColumn;
