import { Form, PageLoader } from "@redriver/cinnamon";
import { IncExcDropdown } from "components/form";
import PropTypes from "prop-types";
import React from "react";
import { ContactTagsLookup } from "../lookups";
import FilterBase from "./FilterBase";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";
import FilterClearer from "./FilterClearer";

class ContactTagsFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    results: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    clearSelected: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired
  };
  state = {
    visible: true,
    total: null,
    included: [],
    excluded: []
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  componentDidMount = () => {
    const { id, formData } = this.props;
    this.getSelected(formData, id);
  };

  componentDidUpdate = prevProps => {
    const { id, formData } = this.props;
    if (prevProps.formData !== formData) {
      this.getSelected(formData, id);
    }
  };

  formatTags = tags => {
    const formattedTags = tags.map(element => {
      return element.split("<>")[1];
    });
    return formattedTags.join(", ");
  };

  getSelected = (formData, id) => {
    const { searchCriteria } = formData;

    const tagsObj = searchCriteria.find(el => el.id == id);
    const { included, excluded } = tagsObj.tagsSelection.tags;

    this.setState({
      included: included.length > 0 ? this.formatTags(included) : null,
      excluded: excluded.length > 0 ? this.formatTags(excluded) : null
    });
  };

  render() {
    const { visible, included, excluded, total } = this.state;
    const {
      id,
      loading,
      pageChanged,
      removeFilter,
      clearSelected
    } = this.props;

    return (
      <ContactTagsLookup
        render={({ response }, refresh) => (
          <FilterBase
            total={total}
            loading={loading && !pageChanged}
            className="tags-filter"
          >
            {response ? (
              <React.Fragment>
                <FilterBase.Header
                  title="Contact Custom Fields"
                  collapsible
                  collapsed={!visible}
                  onCollapseToggle={this.toggleVisibility}
                  removable
                  onRemove={event => removeFilter(id, event)}
                  showClearer={!!included || !!excluded}
                  onClear={clearSelected}
                >
                  {!visible && (
                    <div className="ellipsis-paragraph">
                      {included && <span>Include: {included}</span>}
                      {excluded && <span>Exclude: {excluded}</span>}
                      {!included && !excluded && "No custom fields selected"}
                    </div>
                  )}
                </FilterBase.Header>
                <FilterBase.Body collapsed={!visible}>
                  {!!response.children.length ? (
                    <Form.Object field="tagsSelection">
                      <div className="tags-container">
                        <div className="radio-field">
                          <label>
                            Match results to ALL of your criteria or to at least
                            one of your criteria?
                          </label>
                          <Form.RadioGroup
                            field="match"
                            inline
                            options={[
                              {
                                value: "all",
                                text: "All"
                              },
                              {
                                value: "one",
                                text: "At least one"
                              }
                            ]}
                          />
                        </div>
                        <IncExcDropdown
                          field="tags"
                          nodes={response.children || []}
                          layers={1}
                          flavor={{
                            included: {
                              [0]: {
                                label: "Included Custom Fields",
                                placeholder: "Select custom fields to include"
                              }
                            },
                            excluded: {
                              [0]: {
                                label: "Excluded Custom Fields",
                                placeholder: "Select custom fields to exclude"
                              }
                            }
                          }}
                        />
                      </div>
                    </Form.Object>
                  ) : (
                    <div className="i4b-filter-container">
                      <p>No contact custom fields available</p>
                    </div>
                  )}
                </FilterBase.Body>
              </React.Fragment>
            ) : (
              <PageLoader
                active
                inline="centered"
                size="small"
                className="p-y-05"
              />
            )}
          </FilterBase>
        )}
      />
    );
  }
}

export default ContactTagsFilter;
