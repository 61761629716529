import { SideMenu } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { CustomerRoutes } from "constants/routes";
import React from "react";
import { withRouter } from "react-router-dom";

const CustomerMenu = ({ customerId }) => (
  <SideMenu pointing vertical>
    <SideMenu.Item link={CustomerRoutes.Overview(customerId)} exact>
      <span>Overview</span>
    </SideMenu.Item>
    <SideMenu.Item link={CustomerRoutes.Users(customerId)}>
      <span>Users</span>
    </SideMenu.Item>
    <SideMenu.Item link={CustomerRoutes.Contacts(customerId)}>
      <span>Contacts</span>
    </SideMenu.Item>
    <PermissionCheck target={Targets.ItemTags} action={Actions.View}>
      <SideMenu.Item link={CustomerRoutes.CustomFields(customerId)}>
        <span>Company Custom Fields</span>
      </SideMenu.Item>
      <SideMenu.Item link={CustomerRoutes.CustomFieldValues(customerId)}>
        <span>Company Custom Values</span>
      </SideMenu.Item>
      <SideMenu.Item
        link={CustomerRoutes.ContactCustomFields(customerId)}
        exact
      >
        <span>Contact Custom Fields</span>
      </SideMenu.Item>
    </PermissionCheck>
    <SideMenu.Item link={CustomerRoutes.Segment(customerId)} exact>
      <span>Segment</span>
    </SideMenu.Item>
    <PermissionCheck
      target={Targets.CustomerSuppressionLists}
      action={Actions.View}
    >
      <SideMenu.Item link={CustomerRoutes.Suppressions(customerId)} exact>
        <span>Suppressions</span>
      </SideMenu.Item>
    </PermissionCheck>
    <PermissionCheck target={Targets.CustomerUserStats} action={Actions.View}>
      <SideMenu.Item link={CustomerRoutes.UserStats(customerId)} exact>
        <span>User Stats</span>
      </SideMenu.Item>
    </PermissionCheck>
  </SideMenu>
);

export default withRouter(CustomerMenu);
