import React from "react";
import {
  ListBuilder,
  Table,
  Form,
  FormBuilder,
  Format,
  PageNumber
} from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";

import DeleteLoadedSearchModal from "./modals/DeleteLoadedSearchModal";

const LoadSearchSidePanelContent = ({
  loadAction,
  loadParams,
  onSearchSelected,
  searchDeleteAction
}) => (
  <ListBuilder
    withTable
    loadAction={loadAction}
    loadParams={loadParams}
    dataTransform={data => data.results}
    totalTransform={data => data.totalResults}
    initialFilters={{ search: "" }}
    renderList={(tableProps, state, events) => (
      <React.Fragment>
        <FormBuilder
          initialData={state.filters}
          onChange={events.onChangeFilters}
          renderForm={formProps => (
            <Form {...formProps}>
              <Form.Input
                field="search"
                placeholder="Search saved searches..."
                width={25}
              />
            </Form>
          )}
        />
        <Table
          {...tableProps}
          dataKey="id"
          basic="very"
          onRowClick={item => !state.loading && onSearchSelected(item)}
        >
          <Table.Column title="Name" field="name" width="65%" />
          <Table.Column
            title="Last Ran"
            render={item => (
              <Format.DateTime
                value={item.lastRunUtc}
                format={({ isToday }) =>
                  isToday ? "[Today, ] HH:mm" : "DD/MM/YYYY"
                }
              />
            )}
            width="15%"
          />
          <Table.Column
            render={item => (
              <div
                onClick={(e, d) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Button
                  content="Load Search"
                  primary
                  className="green-small-button"
                  style={{ minWidth: "6rem" }}
                  disabled={state.loading}
                  onClick={(e, d) => onSearchSelected(item)}
                />
                <DeleteLoadedSearchModal
                  deleteAction={searchDeleteAction}
                  deleteParams={{ id: item.id }}
                  loading={state.loading}
                />
              </div>
            )}
          />
        </Table>
        <div className="i4b-list-footer">
          <PageNumber
            totalItems={state.total}
            onChange={x =>
              events.onChangePagination({
                pageSize: state.pagination.pageSize,
                pageNumber: x
              })
            }
            pageSize={state.pagination.pageSize}
            value={state.pagination.pageNumber}
          />
        </div>
      </React.Fragment>
    )}
  />
);

export default LoadSearchSidePanelContent;
