import { SearchCriteria } from "constants/search";
import React from "react";
import { Dropdown } from "semantic-ui-react";
import SearchCriteriaSection from "./SearchCriteriaSection";

class CompaniesSearchCriteriaDropdown extends React.Component {
  shouldComponentUpdate = nextProps => {
    return this.props.disabled.length != nextProps.disabled.length;
  };

  render() {
    const contactsSearchCriteria = SearchCriteria.filter(el =>
      el.types.includes("companies")
    );
    const onClick = this.props.onClick;
    const disabled = this.props.disabled;
    return (
      <Dropdown text="Add Search Criteria">
        <Dropdown.Menu className="criteria-menu">
          <SearchCriteriaSection
            criteria={contactsSearchCriteria.filter(
              el => el.category == "geography"
            )}
            disabled={disabled}
            heading="Geography"
            key="Geography"
            onClick={onClick}
            section="geography"
          />

          <SearchCriteriaSection
            criteria={contactsSearchCriteria.filter(
              el => el.category == "company"
            )}
            disabled={disabled}
            heading="Company"
            key="Company"
            onClick={onClick}
            section="company"
          />

          <SearchCriteriaSection
            criteria={contactsSearchCriteria.filter(
              el => el.category == "contact"
            )}
            disabled={disabled}
            heading="Contacts"
            key="Contacts"
            onClick={onClick}
            section="contact"
          />

          <SearchCriteriaSection
            criteria={contactsSearchCriteria.filter(
              el => el.category == "lists"
            )}
            disabled={disabled}
            heading="Lists"
            key="Lists"
            onClick={onClick}
            section="lists"
          />

          <SearchCriteriaSection
            criteria={contactsSearchCriteria.filter(
              el => el.category == "uploads"
            )}
            disabled={disabled}
            heading="Uploads"
            key="Uploads"
            onClick={onClick}
            section="uploads"
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default CompaniesSearchCriteriaDropdown;
