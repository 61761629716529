import { ActionTypes } from "./actions";

const initialState = {
  formData: {
    selectedItems: []
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UpdateForm:
      return {
        ...state,
        formData: action.applyChanges(state.formData)
      };

    case ActionTypes.ClearForm:
      return {
        ...state,
        formData: initialState.formData
      };

    default:
      return state;
  }
};
