import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";

const EditUploadNameModal = ({
  uploadName,
  submitParams,
  submitAction,
  toggleVisibility,
  onSubmitted
}) => {
  return (
    <ModalBuilder
      withForm
      initialData={{ uploadName }}
      submitAction={submitAction}
      submitParams={submitParams}
      onSubmitted={onSubmitted}
      onCancel={toggleVisibility}
      renderModal={(modalProps, formProps) => (
        <Modal.Edit {...modalProps} header="Change Upload name">
          <Form {...formProps}>
            <Form.Input field="uploadName" label="New Upload Name" />
          </Form>
        </Modal.Edit>
      )}
    />
  );
};

export default EditUploadNameModal;
