import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import * as fileSaver from "file-saver";
import React from "react";
import { connect } from "react-redux";
import { downloadFile } from "./actions";
import DeleteFile from "./DeleteFile";

class FileActions extends React.Component {
  render() {
    const { file, onRefresh, downloadFile } = this.props;
    return (
      <PermissionCheck
        target={Targets.SecureFile}
        any
        action={[Actions.Download, Actions.Delete]}
      >
        <Table.Menu direction="left" className="actions-column">
          <PermissionCheck
            target={Targets.SecureFile}
            action={Actions.Download}
          >
            <Table.MenuItem
              onClick={async () => {
                const data = await downloadFile(file.id);
                fileSaver.saveAs(data.response, file.fileName);
              }}
            >
              Download
            </Table.MenuItem>
          </PermissionCheck>
          <PermissionCheck target={Targets.SecureFile} action={Actions.Delete}>
            <DeleteFile
              as={Table.MenuItem}
              fileId={file.id}
              fileName={file.fileName}
              onDelete={onRefresh}
            >
              Delete
            </DeleteFile>
          </PermissionCheck>
        </Table.Menu>
      </PermissionCheck>
    );
  }
}

const actions = {
  downloadFile
};

export default connect(
  null,
  actions
)(FileActions);
