import React from "react";
import { connect } from "react-redux";
import { ListBuilder, FormBuilder, Form, Table } from "@redriver/cinnamon";
import { Grid, Segment } from "semantic-ui-react";
import { PageNumber } from "components/pagination";
import { SearchResultCounter } from "components/lists";
import CompanySuppressionsTable from "./CompanySuppressionsTable";
import ContactSuppressionsTable from "./ContactSuppressionsTable";
import FilterSuppressions from "./FilterSuppressions";
import { getSuppressions } from "./actions";

const ListSuppressions = props => {
  const { customerId } = props;

  return (
    <ListBuilder
      withTable
      debounceFilters={250}
      initialFilters={{ search: "", isContact: true }}
      totalTransform={data => data.totalResults}
      dataTransform={data => data.results}
      loadAction={getSuppressions}
      loadParams={{ customerId }}
      renderList={(tableProps, state, events) => {
        const { filters, pagination, total } = state;
        const getSuppressionsConnected = () =>
          props.getSuppressions(state, { customerId });

        return (
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={14}
            className="m-b-1"
            stretched
          >
            <div className="i4b-list customer-suppressions-container">
              <h2>Suppressions</h2>

              <FilterSuppressions
                filters={filters}
                onChangeFilters={events.onChangeFilters}
                pageSize={pagination.pageSize}
                onPageSizeChange={events.onChangePagination}
              />
              <Segment>
                {filters.isContact ? (
                  <ContactSuppressionsTable
                    tableProps={tableProps}
                    state={state}
                    events={events}
                    customerId={customerId}
                    onSubmitted={events.onRefresh}
                  />
                ) : (
                  <CompanySuppressionsTable
                    tableProps={tableProps}
                    state={state}
                    events={events}
                    customerId={customerId}
                    onSubmitted={events.onRefresh}
                  />
                )}

                <div className="i4b-list-footer">
                  <SearchResultCounter
                    pageSize={pagination.pageSize}
                    pageNumber={pagination.pageNumber}
                    totalResults={total}
                  />
                  <PageNumber
                    totalItems={total}
                    onChange={x =>
                      events.onChangePagination({
                        pageSize: pagination.pageSize,
                        pageNumber: x
                      })
                    }
                    pageSize={pagination.pageSize}
                    value={pagination.pageNumber}
                  />
                </div>
              </Segment>
            </div>
          </Grid.Column>
        );
      }}
    />
  );
};

const actions = {
  getSuppressions
};

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  actions
)(ListSuppressions);
