import { PageHeader } from "@redriver/cinnamon";
import { SystemPageContent } from "components/pages";
import { ActiveSessions } from "features/System";
import React from "react";

const ActiveSessionsPage = ({}) => {
  return (
    <SystemPageContent>
      <PageHeader>You are already logged in</PageHeader>
      <ActiveSessions />
    </SystemPageContent>
  );
};

export default ActiveSessionsPage;
