import { Format, getAuthState, ListBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { ShowMoreButton } from "components/buttons";
import { Actions, Targets, UserTypes } from "constants/permissions";
import { TemplateAccess, TemplateType } from "constants/settings/templates";
import { find } from "lodash";
import PermissionErrorPage from "pages/PermissionErrorPage";
import React from "react";
import { connect } from "react-redux";
import { Grid, Icon, Popup, Segment } from "semantic-ui-react";
import { getTemplateColumns, getTemplates } from "./actions";
import DeleteTemplate from "./DeleteTemplate";
import EditTemplate from "./EditTemplate";
import FilterTemplates from "./FilterTemplates";
import ViewTemplate from "./ViewTemplate";

class ListTemplates extends React.Component {
  state = {
    detailsModalVisible: false,
    editModalVisible: false,
    deleteModalVisible: false,
    selectedTemplateId: null,
    columns: null
  };

  async componentDidMount() {
    if (this.props.getTemplateColumns) {
      const columns = await this.props.getTemplateColumns();
      this.setState({ columns: columns });
    }
  }

  toggleDetailsModal = id => {
    return this.setState({
      detailsModalVisible: !this.state.detailsModalVisible,
      selectedTemplateId: id
    });
  };

  toggleEditModal = id => {
    return this.setState({
      editModalVisible: !this.state.editModalVisible,
      selectedTemplateId: id
    });
  };

  toggleDeleteModal = id =>
    this.setState({
      deleteModalVisible: !this.state.deleteModalVisible,
      selectedTemplateId: id
    });

  render() {
    const {
      detailsModalVisible,
      editModalVisible,
      deleteModalVisible,
      selectedTemplateId,
      columns
    } = this.state;

    const { isInternalUser } = this.props;

    const templateMenu = template => (
      <Popup
        className="template-card-menu-popup"
        basic
        on={"click"}
        position="bottom left"
        trigger={<Icon name="ellipsis horizontal" />}
        content={
          <div className="template-card-menu">
            <div onClick={() => this.toggleDetailsModal(template.id)}>
              View Details
            </div>
            {(isInternalUser || template.customerId) && (
              <div onClick={() => this.toggleEditModal(template.id)}>
                Update
              </div>
            )}
            {(isInternalUser || template.customerId) && (
              <div onClick={() => this.toggleDeleteModal(template.id)}>
                Delete
              </div>
            )}
          </div>
        }
      />
    );

    return (
      <React.Fragment>
        <PermissionCheck target={Targets.ExportTemplates} action={Actions.View}>
          <ListBuilder
            dataTransform={data => data.results}
            totalTransform={data => data.totalResults}
            loadAction={getTemplates}
            initialFilters={{
              search: "",
              templateTypes: Object.values(TemplateType),
              viewableTemplates: Object.keys(TemplateAccess)
            }}
            renderList={(
              data,
              { filters, pagination, total, loadingMore, hasMore },
              events
            ) => {
              return (
                <React.Fragment>
                  <div className="i4b-list">
                    <Segment style={{ padding: 0 }}>
                      <h1>Download Templates</h1>
                      <FilterTemplates
                        filters={filters}
                        onChangeFilters={events.onChangeFilters}
                        onCreateTemplate={events.onRefresh}
                        columns={columns}
                        isInternal={isInternalUser}
                      />
                    </Segment>
                  </div>

                  <Grid stackable columns={3} className="templates-list">
                    {data.map(x => (
                      <Grid.Column key={x.id}>
                        <div className="i4b-list" style={{ padding: 0 }}>
                          <Segment>
                            <div>
                              <label className="title">{x.name}</label>
                              {templateMenu(x)}
                            </div>
                            <label className="field">
                              {x.columns.length}{" "}
                              {x.columns.length > 1 ? "Fields" : "Field"}
                            </label>
                            <Grid columns={2} style={{ padding: 0 }}>
                              <Grid.Column computer={8} tablet={16} mobile={16}>
                                <label className="field left">
                                  {x.isContact ? "Contacts" : "Company"}{" "}
                                  Template
                                </label>
                              </Grid.Column>
                              <Grid.Column computer={8} tablet={16} mobile={16}>
                                <label className="field right">
                                  Last updated{" "}
                                  <Format.Date
                                    value={x.lastUpdatedUtc}
                                    className="inline"
                                  />
                                </label>
                              </Grid.Column>
                            </Grid>
                          </Segment>
                        </div>
                      </Grid.Column>
                    ))}
                  </Grid>
                  {hasMore && (
                    <ShowMoreButton
                      onClick={events.onLoadMore}
                      loading={loadingMore}
                    />
                  )}

                  {this.state.detailsModalVisible && (
                    <ViewTemplate
                      id={this.state.selectedTemplateId}
                      onClose={this.toggleDetailsModal}
                      columns={columns}
                      isInternal={isInternalUser}
                    />
                  )}

                  {this.state.editModalVisible && (
                    <PermissionCheck
                      target={Targets.ExportTemplates}
                      action={Actions.Edit}
                    >
                      <EditTemplate
                        id={this.state.selectedTemplateId}
                        onSubmitted={() => {
                          this.toggleEditModal();
                          events.onRefresh();
                        }}
                        onCancel={this.toggleEditModal}
                        columns={columns}
                        isInternal={isInternalUser}
                      />
                    </PermissionCheck>
                  )}

                  {this.state.deleteModalVisible && (
                    <PermissionCheck
                      target={Targets.ExportTemplates}
                      action={Actions.Delete}
                    >
                      <DeleteTemplate
                        toggleModal={() => {
                          this.toggleDeleteModal();
                          events.onRefresh();
                        }}
                        template={find(data, {
                          id: this.state.selectedTemplateId
                        })}
                      />
                    </PermissionCheck>
                  )}
                </React.Fragment>
              );
            }}
          />
        </PermissionCheck>
        <PermissionCheck
          target={Targets.ExportTemplates}
          action={Actions.View}
          not
          component={PermissionErrorPage}
        />
      </React.Fragment>
    );
  }
}

const actions = {
  getTemplateColumns
};

const mapStateToProps = state => {
  var authState = getAuthState(state);
  return {
    isInternalUser: authState.claims.userType === UserTypes.Internal
  };
};

export default connect(
  mapStateToProps,
  actions
)(ListTemplates);
