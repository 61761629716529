import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  getAuthState,
  lookupRequest,
  lookupReset
} from "@redriver/cinnamon";
import { SavedSearchType } from "constants/search";
import { extractSearchCriteria, isFormDataEmpty } from "features/Utils";
import { debounce } from "lodash";
import { getCompaniesSearchState } from "./selectors";

const NAMESPACE = "COMPANIES";

export const ActionTypes = {
  GetFormData: `${NAMESPACE}/GET_FORM_DATA`,
  GetListFormData: `${NAMESPACE}/GET_LIST_FORM_DATA`,
  GetCompanies: `${NAMESPACE}/GET_COMPANIES`,
  AddCompanyTag: `${NAMESPACE}/ADD_COMPANY_TAG`,
  UpdateForm: `${NAMESPACE}/UPDATE_FORM`,
  UpdatePageNumber: `${NAMESPACE}/UPDATE_PAGE_NUMBER`,
  ResetSearch: `${NAMESPACE}/RESET_SEARCH`,
  ClearSelected: `${NAMESPACE}/CLEAR_SELECTED`,
  ResetResults: `${NAMESPACE}/RESET_RESULTS`,
  ClearSearchFilter: `${NAMESPACE}/CLEAR_SEARCH_FILTER`,
  ChangeSelection: `${NAMESPACE}/CHANGE_SELECTION`,
  ChangeFollowing: `${NAMESPACE}/CHANGE_FOLLOWING`,
  AddSearchFilter: `${NAMESPACE}/ADD_SEARCH_FILTER`,
  ClearSizeFilter: `${NAMESPACE}/CLEAR_SIZE_FILTER`,
  RemoveFilter: `${NAMESPACE}/REMOVE_FILTER`,
  SaveList: `${NAMESPACE}/SAVE_LIST`,
  LoadSearches: `${NAMESPACE}/LOAD_SAVED_SEARCHES`,
  LoadSearchById: `${NAMESPACE}/LOAD_SAVED_SEARCH`,
  DeleteSearchById: `${NAMESPACE}/DELETE_SAVED_SEARCH`,
  SaveSearch: `${NAMESPACE}/SAVE_SEARCH`,
  GetCustomFields: `${NAMESPACE}/GET_CUSTOM_FIELDS`,
  DeleteCustomField: `${NAMESPACE}/DELETE_CUSTOM_FIELD`,
  AddToList: `${NAMESPACE}/ADD_TO_LIST`,
  UpdateListCriteria: `${NAMESPACE}/UPDATE_LIST_CRITERIA`,
  CreateResearchRequest: `${NAMESPACE}/CREATE_RESEARCH_REQUEST`,
  AddSuppressions: `${NAMESPACE}/ADD_COMPANY_SUPPRESSIONS`,
  ClearCustomFields: `${NAMESPACE}/CLEAR_CUSTOM_FIELDS`,
  ClearForm: `${NAMESPACE}/CLEAR_FORM`,
  UpdateSegmentSelection: `${NAMESPACE}/UPDATE_SEGMENT_SELECTION`,
  GetUniverseCompaniesCount: `${NAMESPACE}/GET_COMPANIES_UNIVERSE_COUNT`,
  GetSegmentCompaniesCount: `${NAMESPACE}/GET_COMPANIES_SEGMENT_COUNT`,
  GetCustomerId: `${NAMESPACE}/GET_CUSTOMER_ID`,
  GetSegmentAdditions: `${NAMESPACE}/GET_SEGMENT_ADDITIONS`,
  GetSegmentRemovals: `${NAMESPACE}/GET_SEGMENT_REMOVALS`,
  AddCompaniesToSegments: `${NAMESPACE}/ADD_COMPANIES_TO_SEGMENTS`,
  RemoveCompaniesFromSegments: `${NAMESPACE}/REMOVE_COMPANIES_FROM_SEGMENTS`,
  DownloadCount: `${NAMESPACE}/DOWNLOAD_COUNT`,
  GetDownload: `${NAMESPACE}/GET_DOWNLOAD`,
  SetExpandedRows: `${NAMESPACE}/SET_EXPANDED_ROWS`,
  UpdateSelectedCountries: `${NAMESPACE}/UPDATE_SELECTED_COUNTRIES`,
  GetCompanyById: `${NAMESPACE}/GET_COMPANY_BY_ID`,
  UpdateViewedCompanies: `${NAMESPACE}/UPDATE_VIEWED_COMPANIES`,
  RemoveFromViewedCompanies: `${NAMESPACE}/REMOVE_FROM_VIEWED_COMPANIES`,
  ResetViewedCompanies: `${NAMESPACE}/RESET_VIEWED_COMPANIES`
};

export const getPreviousFormData = id => async (dispatch, getState) => {
  await dispatch(
    apiGet(ActionTypes.GetFormData, `users/${id}/previous-criteria`, {
      criteriaType: SavedSearchType.CompanySearch
    })
  );

  debouncedGetCompanies(dispatch);
};

export const getListFormData = listId => async (dispatch, getState) => {
  await dispatch(
    apiGet(ActionTypes.GetListFormData, `lists/${listId}/criteria`)
  );

  debouncedGetCompanies(dispatch);
};

const debouncedGetCompanies = debounce(
  dispatch => dispatch(getCompanies()),
  1000
);

export const getCompanies = (
  forceRefresh = false,
  resetCriteriaLookup = false,
  resetSegmentCount = false,
  dimmerLoading = false
) => (dispatch, getState) => {
  const {
    formData,
    pageSize,
    pageNumber,
    isUniverse
  } = getCompaniesSearchState(getState());
  const isEmpty = isFormDataEmpty(formData);
  const searchFilter = formData.searchCriteria.filter(
    f => f.type === "searchFilter"
  )[0];
  //setting the correct properties for the filters
  const listsFilter = formData.searchCriteria.find(
    filter => filter.type === "listsFilter"
  );
  if (listsFilter) {
    listsFilter.listsSearch.isContact = false;
  }
  const uploadsFilter = formData.searchCriteria.find(
    filter => filter.type === "companyUploadsFilter"
  );
  if (uploadsFilter) {
    uploadsFilter.uploadsSearch.isContact = false;
  }

  const defunctFlag = searchFilter.searchFilter.left || false;
  const params = {
    searchCriteria: extractSearchCriteria(formData),
    pageSize,
    pageNumber,
    showUniverse: isUniverse,
    defunctFlag
  };

  if (!isEmpty || forceRefresh) {
    dispatch(
      apiPost(ActionTypes.GetCompanies, "companies", params, {
        actionCustomData: { dimmerLoading: dimmerLoading }
      })
    );
  } else {
    dispatch({ type: ActionTypes.ResetResults });
  }
  if (resetCriteriaLookup) {
    dispatch(lookupReset("customFieldsLookup"));
    dispatch(lookupRequest("customFieldsLookup"));
  }

  if (resetSegmentCount) {
    dispatch(getUniverseCompaniesCount());
    dispatch(getSegmentCompaniesCount());
  }
};

export const updateForm = (field, data, applyChanges) => dispatch => {
  dispatch({
    type: ActionTypes.UpdateForm,
    field,
    data,
    applyChanges
  });
  if (!data[0].hasOwnProperty("postalFilter")) {
    debouncedGetCompanies(dispatch);
  }
};

export const updatePageNumber = pageNumber => dispatch => {
  dispatch({
    type: ActionTypes.UpdatePageNumber,
    value: {
      pageNumber
    }
  });
  debouncedGetCompanies(dispatch);
};

export const hardResetSearch = () => dispatch => {
  dispatch(
    apiPut(ActionTypes.ResetSearch, "users/self/reset-search", {
      savedSearchType: SavedSearchType.CompanySearch
    })
  );
  dispatch(getCustomerId());
  dispatch(getUniverseCompaniesCount());
  dispatch(getSegmentCompaniesCount());
};

export const resetSearch = () => dispatch => {
  dispatch(
    apiPut(ActionTypes.ResetResults, "users/self/reset-search", {
      savedSearchType: SavedSearchType.CompanySearch
    })
  );
};

export const resetSearchSoft = () => dispatch => {
  dispatch({ type: ActionTypes.ResetResults });
};

export const resetCriteriaForLogout = () => dispatch => {
  dispatch({ type: ActionTypes.ResetSearch });
};

export const clearSelected = (event, id) => dispatch => {
  event && event.stopPropagation();
  dispatch({
    type: ActionTypes.ClearSelected,
    value: id
  });
  debouncedGetCompanies(dispatch);
};

export const addCompanyTag = (field, { selection, viewingUniverse }) => (
  dispatch,
  getState
) => {
  const { formData } = getCompaniesSearchState(getState());
  const isEmpty = isFormDataEmpty(formData);
  return dispatch(
    apiPut(ActionTypes.AddCompanyTag, `companies/company-fields`, {
      searchCriteria: isEmpty ? [] : formData.searchCriteria,
      selection: selection,
      customFieldId: field.name,
      value: field.value,
      showUniverse: viewingUniverse
    })
  );
};

export const clearSearchFilter = () => dispatch => {
  dispatch({ type: ActionTypes.ClearSearchFilter });
  debouncedGetCompanies(dispatch);
};

export const changeSelection = selection => dispatch => {
  dispatch({ type: ActionTypes.ChangeSelection, selection });
};

export const changeFollowing = (
  companies,
  currentlyFollowing
) => async dispatch => {
  const path = `customers/company-watch-list-items`;
  const ids = companies.map(c => c.companyId);
  const actionType = ActionTypes.ChangeFollowing;
  const args = { companyIds: ids, customerId: null };
  const opts = {
    actionCustomData: { companies: companies, following: !currentlyFollowing }
  };

  const action = currentlyFollowing
    ? apiDelete(actionType, path, args, opts)
    : apiPost(actionType, path, args, opts);

  await dispatch(action);
};

export const addSearchFilter = data => dispatch => {
  const { value } = data;
  if (value === "listsFilter") {
    dispatch(lookupReset("companyListsLookup"));
    dispatch(lookupRequest("companyListsLookup"));
  }
  dispatch({
    type: ActionTypes.AddSearchFilter,
    value
  });
};

export const clearSizeFilter = (event, id) => dispatch => {
  event && event.stopPropagation();
  dispatch({ type: ActionTypes.ClearSizeFilter, value: id });
  debouncedGetCompanies(dispatch);
};

export const removeFilter = (event, id) => dispatch => {
  event && event.stopPropagation();
  dispatch({ type: ActionTypes.RemoveFilter, value: id });
  debouncedGetCompanies(dispatch);
};

export const saveList = (modalData, submitParams) => (dispatch, getState) => {
  const { formData } = getCompaniesSearchState(getState());
  const request = {
    ...modalData,
    selection: submitParams.selection,
    searchCriteria: extractSearchCriteria(formData)
  };

  return dispatch(apiPost(ActionTypes.SaveList, "lists/company", request));
};

export const loadSearches = ({ filters, pagination }, params) =>
  apiGet(ActionTypes.LoadSearches, "users/self/saved-searches", {
    ...filters,
    ...pagination,
    ...params
  });

export const saveSearch = (modalData, submitParams) => (dispatch, getState) => {
  const { formData } = getCompaniesSearchState(getState());
  const request = {
    ...modalData,
    ...submitParams,
    searchCriteria: extractSearchCriteria(formData)
  };

  return dispatch(
    apiPost(ActionTypes.SaveSearch, "users/self/saved-searches", request)
  );
};

export const loadSearch = ({ id }) => async dispatch => {
  await dispatch(
    apiGet(ActionTypes.LoadSearchById, `users/self/saved-searches/${id}`)
  );

  debouncedGetCompanies(dispatch);
};

export const deleteSearch = ({ id }) =>
  apiDelete(ActionTypes.DeleteSearchById, `users/self/saved-searches/${id}`);

export const getCustomFields = () =>
  apiGet(
    ActionTypes.GetCustomFields,
    `users/custom-fields`,
    {},
    { preventErrorNotification: true }
  );

export const deleteField = ({ companyId, fieldId, companyOriginalId }) =>
  apiDelete(
    ActionTypes.DeleteCustomField,
    `companies/${companyId}/fields`,
    { id: fieldId, companyOriginalId: companyOriginalId },
    {
      preventErrorNotification: true
    }
  );

export const addToList = (modalData, submitParams) => (dispatch, getState) => {
  const { formData } = getCompaniesSearchState(getState());
  const request = {
    ...modalData,
    selection: submitParams.selection,
    searchCriteria: extractSearchCriteria(formData)
  };

  return dispatch(
    apiPost(ActionTypes.AddToList, "lists/add-to-existing", request)
  );
};

export const updateListCriteria = listId => (dispatch, getState) => {
  const { formData } = getCompaniesSearchState(getState());
  if (isFormDataEmpty(formData)) return;
  const request = {
    listId,
    searchCriteria: extractSearchCriteria(formData)
  };

  return dispatch(
    apiPost(ActionTypes.UpdateListCriteria, "lists/update-criteria", request)
  );
};

export const submitResearchRequest = (
  { requestText },
  { originalId, companyId }
) =>
  apiPost(
    ActionTypes.CreateResearchRequest,
    `companies/${companyId}/research`,
    { requestText: requestText.trim(), originalId }
  );

export const getCustomerId = () =>
  apiGet(ActionTypes.GetCustomerId, `users/user-customer-id`);

export const addSuppressions = submitParams =>
  apiPost(
    ActionTypes.AddSuppressions,
    `customers/${submitParams.customerId}/company-suppressions`,
    {
      selection: submitParams.selection,
      searchCriteria: submitParams.formData.searchCriteria,
      showUniverse: submitParams.viewingUniverse
    }
  );

export const clearCustomFields = ({
  selection,
  formData,
  customerId,
  viewingUniverse
}) =>
  apiDelete(
    ActionTypes.ClearCustomFields,
    `customers/${customerId}/custom-fields`,
    {
      selection,
      searchCriteria: formData.searchCriteria,
      showUniverse: viewingUniverse
    }
  );

export const updateSegmentSelection = selected => async (
  dispatch,
  getState
) => {
  const { claims } = getAuthState(getState());
  const isUniverse = selected === "universe";

  await dispatch({
    type: ActionTypes.UpdateSegmentSelection,
    value: { selected }
  });

  await dispatch(lookupReset("postalAreasLookup"));
  await dispatch(
    lookupRequest("postalAreasLookup", {
      userId: claims.userId,
      viewFullTree: false,
      viewingUniverse: isUniverse
    })
  ).then(async res => {
    if (!res.success) return;

    const { unfilteredSelectedCountries } = getCompaniesSearchState(getState());

    let segmentAllowed = [];
    Object.keys(res.response.flattenedStructure).forEach(e => {
      segmentAllowed.push(e);
      segmentAllowed = segmentAllowed.concat(
        res.response.flattenedStructure[e]
      );
    });
    const newCountriesToShow = unfilteredSelectedCountries[
      isUniverse ? "universe" : "segment"
    ].filter(x => segmentAllowed.includes(x));

    await dispatch({
      type: ActionTypes.UpdateSelectedCountries,
      value: {
        selected: newCountriesToShow,
        viewingUniverse: isUniverse
      }
    });
  });

  await dispatch(getCompanies());
};

export const getUniverseCompaniesCount = () =>
  apiGet(ActionTypes.GetUniverseCompaniesCount, `companies/universe/count`);

export const getSegmentCompaniesCount = () =>
  apiGet(ActionTypes.GetSegmentCompaniesCount, `companies/segment/count`);

export const getSegmentAdditions = data =>
  apiPost(
    ActionTypes.GetSegmentAdditions,
    `customers/segments/count-additions`,
    data
  );

export const getSegmentRemovals = data =>
  apiPost(
    ActionTypes.GetSegmentRemovals,
    `customers/segments/count-removals`,
    data
  );

export const addCompaniesToSegments = data =>
  apiPost(
    ActionTypes.AddCompaniesToSegments,
    `customers/segments/additions`,
    data
  );

export const removeCompaniesFromSegments = (data, submitParams) =>
  apiPost(
    ActionTypes.RemoveCompaniesFromSegments,
    `customers/segments/removals`,
    data
  );

export const getDownloadCount = () => (dispatch, getState) => {
  const { formData, isUniverse } = getCompaniesSearchState(getState());
  const request = {
    searchCriteria: extractSearchCriteria(formData),
    isPreview: isUniverse
  };
  return dispatch(
    apiPost(ActionTypes.DownloadCount, `companies/download-count`, request)
  );
};

export const getDownload = (template, submitParams) => async (
  dispatch,
  getState
) => {
  const { formData } = getCompaniesSearchState(getState());
  const request = {
    searchCriteria: extractSearchCriteria(formData),
    templateId: template.templateId,
    isPreview: submitParams.isPreview
  };

  return await dispatch(
    apiPost(ActionTypes.GetDownload, `companies/download`, request)
  );
};

export const appendViewedCompanyId = id => dispatch =>
  dispatch({ type: ActionTypes.UpdateViewedCompanies, id });

export const removeFromViewedCompanyList = id => dispatch =>
  dispatch({ type: ActionTypes.RemoveFromViewedCompanies, id });

export const resetViewedCompanyList = () => dispatch =>
  dispatch({ type: ActionTypes.ResetViewedCompanies });

export const setExpandedRows = rows => dispatch => {
  dispatch({ type: ActionTypes.SetExpandedRows, rows });
};

export const getCompanyById = id =>
  apiGet(ActionTypes.GetCompanyById, `companies/${id}`);
