export default {
  View: "v",
  Create: "c",
  Edit: "e",
  Delete: "d",
  Migrate: "mi",
  Block: "b",
  Remove: "r",
  Suppress: "s",
  Unsuppress: "us",
  Download: "dl",
  Manage: "mg",
  Impersonate: "im",
  Advanced: "ad"
};
