import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Icon } from "semantic-ui-react";
import { updateCustomField } from "./actions";

const EditField = ({
  as: As,
  customerId,
  fieldId,
  fieldName,
  onUpdate,
  ...props
}) => (
  <ModalBuilder
    withForm
    initialData={{ name: fieldName }}
    submitAction={updateCustomField}
    submitParams={{ customerId, fieldId }}
    onSubmitted={onUpdate}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit
        {...modalProps}
        header={
          <div>
            <Icon name="edit" /> Edit Field
          </div>
        }
        size="tiny"
      >
        <Form {...formProps}>
          <Form.Input field="name" label="Name" required />
        </Form>
      </Modal.Edit>
    )}
  />
);

export default EditField;
