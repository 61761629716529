import { ActionBar, Form, FormBuilder, PageSize } from "@redriver/cinnamon";
import React, { Component } from "react";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import CreateCustomerTag from "./CreateCustomerTag";

class FilterTags extends Component {
  render() {
    const {
      customerId,
      filters,
      onChangeFilters,
      pageSize,
      onPageSizeChange,
      onCreateCustomFieldValue
    } = this.props;

    return (
      <ActionBar>
        <ActionBar.Item fluid>
          <FormBuilder
            initialData={filters}
            onChange={onChangeFilters}
            renderForm={formProps => (
              <Form {...formProps}>
                <Form.Input
                  field="search"
                  placeholder="Search contact custom fields..."
                  width={25}
                />
              </Form>
            )}
          />
        </ActionBar.Item>
        <ActionBar.Item align="right">
          <PermissionCheck target={Targets.ItemTags} action={Actions.Manage}>
            <CreateCustomerTag
              customerId={customerId}
              onSubmitted={onCreateCustomFieldValue}
            />
          </PermissionCheck>
        </ActionBar.Item>
        <ActionBar.Item className="i4b-filter-pagesize">
          <PageSize
            value={pageSize}
            onChange={x => onPageSizeChange({ pageSize: x, pageNumber: 1 })}
            options={[10, 25, 50, 100]}
          />
        </ActionBar.Item>
      </ActionBar>
    );
  }
}

export default FilterTags;
