import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, ErrorMessage, getAuthClaims } from "@redriver/cinnamon";
import { Button, Message } from "semantic-ui-react";
import { requestPasswordChange } from "./actions";
import { getPasswordChangeState } from "../selectors";

class PasswordChange extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    updating: PropTypes.bool,
    slowRequest: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.any,
    userEmail: PropTypes.string.isRequired
  };

  state = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    validated: false,
    showErrors: false
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.success && nextProps.success) {
      this.setState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        showErrors: false
      });
    }
  };

  onFormChange = (field, data) => this.setState({ [field]: data });

  onFormValidated = validated => this.setState({ validated });

  changePassword = e => {
    e.preventDefault();
    if (!this.state.validated) {
      this.setState({ showErrors: true });
      return;
    }
    if (this.props.updating) return;
    const { oldPassword, newPassword } = this.state;
    this.props.onSubmit(this.props.userEmail, oldPassword, newPassword);
  };

  render() {
    const { updating, slowRequest, success, error } = this.props;
    const isBusy = updating && slowRequest;

    return (
      <Form
        value={this.state}
        onChange={this.onFormChange}
        onValidated={this.onFormValidated}
        showErrors={this.state.showErrors}
      >
        <Form.Password
          fluid
          field="oldPassword"
          label="Old Password"
          icon="lock"
          iconPosition="left"
          placeholder="Old password"
          disabled={isBusy}
          autoFocus
          required
        />
        <Form.Password
          fluid
          field="newPassword"
          label="New Password"
          icon="lock"
          iconPosition="left"
          placeholder="New password"
          disabled={isBusy}
          required
          minLength={6}
        />
        <Form.Password
          fluid
          field="confirmPassword"
          label="Confirm Password"
          icon="lock"
          iconPosition="left"
          placeholder="Confirm password"
          disabled={isBusy}
          required
          confirmField="newPassword"
        />
        <Button
          fluid
          primary
          onClick={this.changePassword}
          disabled={isBusy}
          loading={isBusy}
          className="system-button"
        >
          Submit
        </Button>
        <ErrorMessage error={error} />
        {success && (
          <Message
            success
            header="Success"
            content="Password has been updated"
          />
        )}
      </Form>
    );
  }
}

const actions = {
  onSubmit: requestPasswordChange
};

const mapStateToProps = state => {
  const passwordChangeState = getPasswordChangeState(state);
  const { userEmail } = getAuthClaims(state);
  return {
    updating: passwordChangeState.updating,
    slowRequest: passwordChangeState.slowRequest,
    success: passwordChangeState.success,
    error: passwordChangeState.error,
    userEmail
  };
};

export default connect(
  mapStateToProps,
  actions
)(PasswordChange);
