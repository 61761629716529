import { Form, PageLoader } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import { CompanyListsLookup } from "../lookups";
import FilterBase from "./FilterBase";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";

class EmailDdiFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    results: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    clearSelected: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired
  };

  state = {
    visible: true,
    total: null,
    emailOnly: false,
    ddiOnly: false
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  componentDidMount = () => {
    const { id, formData } = this.props;
    this.getSelected(formData, id);
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  componentDidUpdate = prevProps => {
    const { id, formData } = this.props;
    if (prevProps.formData !== formData) {
      this.getSelected(formData, id);
    }
  };

  getSelected = (formData, id) => {
    const { searchCriteria } = formData;
    const listsObj = searchCriteria.find(el => el.id == id);
    if (!listsObj || !listsObj.emailDdiSearch) return;

    const { emailDdiSearch } = listsObj;
    const { emailOnly, ddiOnly } = emailDdiSearch;

    this.setState({
      emailOnly: emailOnly || null,
      ddiOnly: ddiOnly || null
    });
  };

  render() {
    const { visible, emailOnly, ddiOnly, total } = this.state;
    const {
      id,
      loading,
      pageChanged,
      removeFilter,
      clearSelected
    } = this.props;

    return (
      <CompanyListsLookup
        render={({ response }, refresh) => {
          return (
            <FilterBase
              total={total}
              loading={loading && !pageChanged}
              className="i4b-filter-base"
            >
              {response ? (
                <React.Fragment>
                  <FilterBase.Header
                    title="Email & Telephone"
                    collapsible
                    collapsed={!visible}
                    onCollapseToggle={this.toggleVisibility}
                    removable
                    onRemove={event => removeFilter(id, event)}
                    showClearer={emailOnly || ddiOnly}
                    onClear={clearSelected}
                  >
                    {!visible && (
                      <div className="ellipsis-paragraph">
                        {emailOnly && <span>Valid email address only</span>}
                        {ddiOnly && <span>Direct telephone numbers only</span>}
                        {!emailOnly && !ddiOnly && "No active filter"}
                      </div>
                    )}
                  </FilterBase.Header>
                  <FilterBase.Body collapsed={!visible}>
                    <div>
                      <Form.Object field="emailDdiSearch">
                        <Form.Checkbox
                          field="emailOnly"
                          label="Valid email address only"
                          toggle
                        />
                        <Form.Checkbox
                          field="ddiOnly"
                          label="Direct telephone numbers only"
                          toggle
                        />
                      </Form.Object>
                    </div>
                  </FilterBase.Body>
                </React.Fragment>
              ) : (
                <PageLoader
                  active
                  inline="centered"
                  size="small"
                  className="p-y-05"
                />
              )}
            </FilterBase>
          );
        }}
      />
    );
  }
}

export default EmailDdiFilter;
