import React from "react";
import { Loader } from "semantic-ui-react";

const ShowMoreButton = ({ onClick, loading }) => (
  <div className="show-more">
    {loading ? (
      <Loader active inline size="small" />
    ) : (
      <a onClick={onClick}>Show More</a>
    )}
  </div>
);

export default ShowMoreButton;
