import { getAuthClaims, NavMenu } from "@redriver/cinnamon";
import ReadIconActive from "assets/icons/notification-read-active.svg";
import ReadIconInactive from "assets/icons/notification-read-inactive.svg";
import UnreadIconActive from "assets/icons/notification-unread-active.svg";
import UnreadIconInactive from "assets/icons/notification-unread-inactive.svg";
import { AppRoutes } from "constants/routes";
import { SignalRClient } from "modules/signalr";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Image } from "semantic-ui-react";
import { refreshNotifications } from "./actions";
import { getSystemNotificationsState } from "./selectors";

class NotificationIcon extends Component {
  _intervalId = false;

  componentDidMount() {
    this.props.refreshNotifications();
  }

  componentWillUnmount() {
    this._intervalId && clearInterval(this._intervalId);
  }

  determineAlertIconStatus = (alertStatus, pageIsActive) =>
    pageIsActive
      ? alertStatus
        ? UnreadIconActive
        : ReadIconActive
      : alertStatus
      ? UnreadIconInactive
      : ReadIconInactive;

  render() {
    const { alertStatus, pageIsActive } = this.props;

    return (
      <Image
        src={this.determineAlertIconStatus(!!alertStatus, pageIsActive)}
        title={
          alertStatus
            ? "You have unread notifications."
            : "You have no unread notifications."
        }
      />
    );
  }
}

class NotificationNavMenuItem extends Component {
  render() {
    const { alertStatus, refreshNotifications, location, userId } = this.props;
    return (
      <React.Fragment>
        <NavMenu.Footer
          link={AppRoutes.Notifications}
          exact
          className="navitem-notification"
          icon={
            <NotificationIcon
              alertStatus={alertStatus}
              refreshNotifications={refreshNotifications}
              pageIsActive={
                location && location.pathname == AppRoutes.Notifications
              }
            />
          }
        >
          Notifications
        </NavMenu.Footer>
        <SignalRClient
          channel={userId}
          callbacks={[
            {
              method: "sendNotification",
              run: () => this.props.refreshNotifications()
            }
          ]}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    navMenuItem: { alertStatus }
  } = getSystemNotificationsState(state);
  const { userId } = getAuthClaims(state);

  return {
    alertStatus,
    userId
  };
};

const actions = {
  refreshNotifications
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(NotificationNavMenuItem)
);
