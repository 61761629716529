import { Format, getAuthClaims } from "@redriver/cinnamon";
import { Actions, Targets, UserTypes } from "constants/permissions";
import * as fileSaver from "file-saver";
import React from "react";
import { connect } from "react-redux";
import { Button, Grid, Segment } from "semantic-ui-react";
import { downloadFailedFile } from "./actions";

const UploadCard = ({
  id,
  createdByName,
  customerName,
  uploadedUtc,
  numFailed,
  numSuccessful,
  downloadFailedFile
}) => {
  const downloadFailed = () => async () => {
    if (numFailed > 0) {
      downloadFailedFile(id, "failed").then(result => {
        fileSaver.saveAs(result.response, `${customerName} - failed.csv`);
      });
    }
  };

  return (
    <Segment className="left-border-green m-y-1 list-card">
      <Grid style={{ margin: "0" }}>
        <Grid.Row columns={3} style={{ padding: "0" }}>
          <Grid.Column style={{ padding: "0" }} width={7}>
            <h5 className="single-line-text">{customerName}</h5>
          </Grid.Column>
          <Grid.Column width={7}>
            <p className="m-b-0 pull-right">
              Uploaded <Format.Date value={uploadedUtc} />
            </p>
          </Grid.Column>
        </Grid.Row>
        {createdByName && (
          <Grid.Row columns={1} style={{ padding: "0" }}>
            <Grid.Column style={{ padding: "0" }}>
              <p className="m-b-0">Uploaded by {createdByName}</p>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={2} style={{ padding: "0" }}>
          <Grid.Column
            style={{
              padding: "0",
              paddingTop: "0.5rem"
            }}
          >
            <div>{numSuccessful} successful</div>
            <Button
              icon="download"
              content={`${numFailed} failed`}
              className={`upload-list-button ${
                numFailed > 0
                  ? "download-button-active"
                  : "download-button-inactive"
              }`}
              onClick={downloadFailed()}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const mapStateToProps = (state, props) => {
  const { permissions, userType, userId } = getAuthClaims(state);

  return {
    isInternal: userType && userType == UserTypes.Internal,
    currentUserId: userId,
    canManageUploads:
      permissions &&
      permissions.hasOwnProperty(Targets.Upload) &&
      (Array.isArray(permissions[Targets.Upload])
        ? permissions[Targets.Upload].some(p => p == Actions.Manage)
        : permissions[Targets.Upload] == Actions.Manage)
  };
};

const actions = {
  downloadFailedFile
};

export default connect(
  mapStateToProps,
  actions
)(UploadCard);
