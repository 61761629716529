import { Breadcrumb } from "@redriver/cinnamon";
import Home from "assets/icons/home_breadcrumb.svg";
import { AppRoutes } from "constants/routes";
import { DashboardManager } from "features/Dashboard";
import React from "react";
import { Grid, Image } from "semantic-ui-react";
import Menu from "./Menu";

const DashboardPage = () => (
  <React.Fragment>
    <Grid>
      <Grid.Column floated="left" width={5}>
        <Breadcrumb className="i4b-breadcrumb-container">
          <Breadcrumb.Item
            link={AppRoutes.Root}
            icon={<Image src={Home} title="Home" />}
          />
          <Breadcrumb.Item content="Dashboard" />
        </Breadcrumb>
      </Grid.Column>
      <Grid.Column floated="right" width={1}>
        <div className="dashboard-menu">
          <Menu />
        </div>
      </Grid.Column>
    </Grid>
    <DashboardManager />
  </React.Fragment>
);

export default DashboardPage;
