import React from "react";
import { ActionBar, FormBuilder, Form } from "@redriver/cinnamon";

import AddRole from "./AddRole";

const FilterRoles = ({ filters, onChangeFilters, onAddRole }) => (
  <ActionBar>
    <ActionBar.Item fluid>
      <FormBuilder
        initialData={filters}
        onChange={onChangeFilters}
        renderForm={formProps => (
          <Form {...formProps}>
            <Form.Group inline compact>
              <Form.Input
                field="search"
                placeholder="Search roles…"
                width={25}
              />
            </Form.Group>
          </Form>
        )}
      />
    </ActionBar.Item>
    <ActionBar.Item align="right">
      <AddRole onSubmitted={onAddRole} />
    </ActionBar.Item>
  </ActionBar>
);

export default FilterRoles;
