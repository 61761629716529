import React, { Fragment } from "react";
import { Segment, Grid } from "semantic-ui-react";
import { ListBuilder, Table } from "@redriver/cinnamon";

import { Targets, Actions } from "constants/permissions";
import { PermissionCheck } from "components/auth";
import { SearchResultCounter } from "components/lists";
import { PageNumber } from "components/pagination";
import { getContacts } from "./actions";
import CreateUserFromContactModal from "./CreateUserFromContactModal";

const ViewCustomerContacts = ({ customerId }) => (
  <ListBuilder
    withTable
    dataTransform={data => data.results}
    totalTransform={data => data.totalResults}
    loadAction={getContacts}
    loadParams={customerId}
    renderList={(tableProps, { pagination, total }, events) => (
      <Grid className="i4b-list-page user-page" stretched style={{ flex: 1 }}>
        <Grid.Row style={{ paddingTop: 0 }} columns={1}>
          <Grid.Column>
            <div className="i4b-list">
              <h2>Contacts</h2>
              <Segment>
                <Table {...tableProps} dataKey="id" basic="very">
                  <Table.Column field="name" title="Name" width="25%" />
                  <Table.Column
                    field="emailAddress"
                    title="Email"
                    width="25%"
                  />
                  <Table.Column
                    field="jobPosition"
                    title="Job Position"
                    width="25%"
                  />
                  <PermissionCheck
                    target={Targets.User}
                    action={Actions.Create}
                  >
                    <Table.Column
                      name="Actions"
                      render={item => (
                        <CreateUserFromContactModal
                          id={item.id}
                          onSubmitted={events.onRefresh}
                          disabled={item.hasExistingUser}
                        />
                      )}
                      width="12.5%"
                    />
                  </PermissionCheck>
                </Table>
                <div className="i4b-list-footer">
                  <SearchResultCounter
                    pageSize={pagination.pageSize}
                    pageNumber={pagination.pageNumber}
                    totalResults={total}
                  />
                  <PageNumber
                    totalItems={total}
                    onChange={x =>
                      events.onChangePagination({
                        pageSize: pagination.pageSize,
                        pageNumber: x
                      })
                    }
                    pageSize={pagination.pageSize}
                    value={pagination.pageNumber}
                  />
                </div>
              </Segment>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )}
  />
);

export default ViewCustomerContacts;
