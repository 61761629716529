import { apiGet, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "USERS/VIEW_CUSTOMER_CONTACTS";

export const ActionTypes = {
  GetCompanyContacts: `${NAMESPACE}/GET_CONTACTS`,
  CreateUserFromContact: `${NAMESPACE}/CREATE_USER`
};

export const getContacts = ({ pagination }, customerId) =>
  apiGet(
    ActionTypes.GetCompanyContacts,
    `customers/${customerId}/contacts`,
    pagination
  );

export const createUserFromContact = ({ roleIds }, contactId) =>
  apiPost(ActionTypes.CreateUserFromContact, `users/from-contact`, {
    id: contactId,
    roleIds
  });
