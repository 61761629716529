import React from "react";
import { Grid, Image, Segment } from "semantic-ui-react";
import { Format, ListBuilder, Table } from "@redriver/cinnamon";

import { SearchResultCounter } from "components/lists";
import { PageNumber } from "components/pagination";
import { Targets, Actions } from "constants/permissions";
import { PermissionCheck } from "components/auth";

import { getCustomFieldValues } from "./actions";
import ActionsColumn from "./ActionsColumn";
import FilterCustomFieldValues from "./FilterCustomFieldValues";
import Green from "assets/green_square.svg";

const ListCustomFieldValues = customerId => (
  <ListBuilder
    withTable
    totalTransform={data => data.totalResults}
    dataTransform={data => data.results}
    loadAction={getCustomFieldValues}
    loadParams={customerId}
    renderList={(tableProps, { filters, pagination, total }, events) => {
      return (
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={14}
          className="m-b-1"
          stretched
        >
          <div className="i4b-list">
            <h2>Company Custom Field Values</h2>
            <FilterCustomFieldValues
              customerId={customerId}
              filters={filters}
              onChangeFilters={events.onChangeFilters}
              pageSize={pagination.pageSize}
              onPageSizeChange={events.onChangePagination}
              onCreateCustomFieldValue={events.onRefresh}
            />
            <Segment>
              <Table {...tableProps} dataKey="id" basic="very">
                <Table.Column
                  render={item => (
                    <Image
                      src={Green}
                      title="Custom Field Values"
                      style={{ height: "1em", width: "1em" }}
                    />
                  )}
                  width="2.5%"
                />
                <Table.Column title="Value" render={item => item.value} />
                <Table.Column title="Name" render={item => item.name} />
                <Table.Column
                  title="Usage Count"
                  render={item => item.usageCount}
                />
                <Table.Column
                  title="Created"
                  render={item => (
                    <React.Fragment>
                      {item.createdBy}
                      {" - "}
                      <Format.Date value={item.created} format="short" />
                    </React.Fragment>
                  )}
                />
                <Table.Column
                  name="Actions"
                  render={item => (
                    <PermissionCheck
                      target={Targets.ItemTags}
                      action={Actions.Manage}
                    >
                      <ActionsColumn item={item} onChange={events.onRefresh} />
                    </PermissionCheck>
                  )}
                  width="15%"
                />
              </Table>
              <div className="i4b-list-footer">
                <SearchResultCounter
                  pageSize={pagination.pageSize}
                  pageNumber={pagination.pageNumber}
                  totalResults={total}
                />
                <PageNumber
                  totalItems={total}
                  onChange={x =>
                    events.onChangePagination({
                      pageSize: pagination.pageSize,
                      pageNumber: x
                    })
                  }
                  pageSize={pagination.pageSize}
                  value={pagination.pageNumber}
                />
              </div>
            </Segment>
          </div>
        </Grid.Column>
      );
    }}
  />
);

export default ListCustomFieldValues;
