import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { Form, Modal, ModalBuilder, ErrorMessage } from "@redriver/cinnamon";

import { createCustomField } from "./actions";

const CreateCustomField = ({ customerId, onSubmitted }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={createCustomField}
      submitParams={customerId}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <Button primary content="Add Custom Field" onClick={showModal} />
      )}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Add
            {...modalProps}
            header={
              <div>
                <Icon name="plus" /> Add Custom Field
              </div>
            }
            submitLabel="Create Custom Field"
            size="tiny"
          >
            <Form {...formProps}>
              <Form.Input field="name" label="Name" required fluid />
            </Form>
          </Modal.Add>
        );
      }}
    />
  );
};

export default CreateCustomField;
