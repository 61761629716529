import { requestIsBusy } from "@redriver/cinnamon";
import { REQUEST_LOGOUT } from "./actions";

const initialState = {
  loggingOut: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LOGOUT:
      return {
        ...state,
        loggingOut: requestIsBusy(action)
      };

    default:
      return state;
  }
};
