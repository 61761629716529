import { ContactSearchCriteriaDropdown } from "components/search";
import React from "react";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";
import { addSearchFilter } from "./actions";
import { getContactsSearchState } from "./selectors";

class AddFilter extends React.Component {
  onAddFilter = (e, data) => {
    const { addSearchFilter, formData } = this.props;
    const { searchCriteria } = formData;
    addSearchFilter(data);
  };

  shouldComponentUpdate = nextProps => {
    return (
      this.props.formData.searchCriteria.length !=
      nextProps.formData.searchCriteria.length
    );
  };

  render() {
    const { addSearchFilter, formData } = this.props;
    const { searchCriteria } = formData;
    const disabled = searchCriteria.map(el => el.type);
    return (
      <Segment className="m-t-0 add-filter-segment">
        <ContactSearchCriteriaDropdown
          onClick={this.onAddFilter}
          disabled={disabled}
        />
      </Segment>
    );
  }
}

const actions = {
  addSearchFilter
};

const mapStateToProps = state => {
  const { formData } = getContactsSearchState(state);

  return {
    formData
  };
};

export default connect(
  mapStateToProps,
  actions
)(AddFilter);
