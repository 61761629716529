import { apiGet, apiPost, mockError } from "@redriver/cinnamon";

const NAMESPACE = "SEGMENT_REMOVALS/LIST";

export const ActionTypes = {
  UploadFile: `${NAMESPACE}/UPLOAD_FILE`,
  GetUploads: `${NAMESPACE}/UPLOADS`,
  DownloadFailedFile: `${NAMESPACE}/DOWNLOAD_FAILED_FILE`
};

export const uploadFileForProcessing = upload => {
  if (!upload.file || !upload.file.size)
    return mockError(ActionTypes.UploadFile, {
      code: 0,
      message: "Couldn't retrieve uploaded file. Please try again",
      preventNotification: true
    });

  const regex = /(?:\.([^.]+))?$/;
  const ext = regex.exec(upload.file.name)[1];
  if (ext === undefined || !["csv"].includes(ext))
    return mockError(ActionTypes.UploadFile, {
      code: 0,
      message: `${upload.file.name}: Uploaded files must be in the CSV format`
    });

  return apiPost(ActionTypes.UploadFile, "segment-removals", upload.file, {
    contentType: upload.file.type,
    params: { customerId: upload.customerId }
  });
};

export const getUploads = ({ filters, pagination }) => {
  const params = {
    ...filters,
    ...pagination
  };

  return apiGet(ActionTypes.GetUploads, "segment-removals", params);
};

export const downloadFailedFile = id => async dispatch => {
  return await dispatch(
    apiPost(
      ActionTypes.DownloadFailedFile,
      `segment-removals/${id}/failed-file`
    )
  );
};
