import { Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Icon } from "semantic-ui-react";
import { deleteFile } from "./actions";

const DeleteFile = ({
  as: As,
  customerId,
  fileId,
  fileName,
  onDelete,
  ...props
}) => (
  <ModalBuilder
    submitAction={deleteFile}
    submitParams={fileId}
    onSubmitted={onDelete}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={modalProps => (
      <Modal.Delete
        {...modalProps}
        header={
          <div>
            <Icon name="warning sign" /> Delete File
          </div>
        }
      >
        <p>Are you sure you wish to delete {fileName}?</p>
      </Modal.Delete>
    )}
  />
);

export default DeleteFile;
