import {
  createDefaultReduxStore,
  enableSegmentLogger,
  enableSentryLogger,
  setApiOptions,
  setAuthOptions,
  setLoggingOptions,
  setPreferenceDefaults
} from "@redriver/cinnamon";
import { onDeauthenticated, refreshJwt } from "features/System";
import { signalRmiddleware } from "modules/signalr";
import activeActionMiddleware from "./activeActionMiddleware";
import reducers from "./reducers";

export const setupApp = () => {
  configureApi();
  configureAuth();
  configureLogging();
  configurePreferences();
  const store = configureStore();
  return { store };
};

// api / auth modules

const configureApi = () =>
  setApiOptions({
    apiUrl: process.env.API_URL
  });

const configureAuth = () =>
  setAuthOptions({
    tokenKey: `${process.env.COOKIE_PREFIX}-token-key`,
    jwtClaims: {
      emailVerified: {
        type: "boolean",
        key: "ev"
      },
      userId: {
        type: "string",
        key: "sub"
      },
      userName: {
        type: "join",
        keys: ["fn", "ln"],
        separator: " "
      },
      userEmail: {
        type: "string",
        key: "e",
        identity: "email"
      },
      emailExpiryUtc: {
        type: "string",
        key: "ex"
      },
      emailVerifyPending: {
        type: "boolean",
        key: "evp"
      },
      customer: {
        type: "string",
        key: "cu"
      },
      userType: {
        type: "string",
        key: "ut"
      },
      impersonatorId: {
        type: "string",
        key: "i_id"
      },
      impersonatorFirstName: {
        type: "string",
        key: "i_fn"
      },
      permissions: {
        type: "object",
        keys: "^p_(.+)"
      }
    },
    onRefreshJwt: refreshJwt,
    onDeauthenticated
  });

// logging module

const configureLogging = () => {
  setLoggingOptions({
    environment: process.env.LOGGING_ENVIRONMENT,
    release: process.env.LOGGING_RELEASE
  });
  if (process.env.SENTRY_DSN) {
    enableSentryLogger(process.env.SENTRY_DSN);
  }
  if (process.env.SEGMENT_APIKEY) {
    enableSegmentLogger(process.env.SEGMENT_APIKEY);
  }
};

// preferences module

const configurePreferences = () =>
  setPreferenceDefaults({
    timezone: "Europe/London"
  });

// redux store module

const configureStore = () => {
  return createDefaultReduxStore({
    reducers,
    reduxDevTools: process.env.REDUX_DEVTOOLS,
    middleware: [activeActionMiddleware, signalRmiddleware]
  });
};
