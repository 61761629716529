import { AppRoutes } from "constants/routes";
import React from "react";
import { Route } from "react-router-dom";
import DashboardPage from "./DashboardPage";

export default () => (
  <React.Fragment>
    <Route path={AppRoutes.Root} component={DashboardPage} />
  </React.Fragment>
);
