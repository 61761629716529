import React from "react";
import PropTypes from "prop-types";

import { EditCountriesFormWrapper } from "components/lists";
import { updateCountries } from "./actions";

const ViewUserCountries = ({ userId }) => (
  <EditCountriesFormWrapper onSubmit={updateCountries} userId={userId} />
);

ViewUserCountries.propTypes = {
  userId: PropTypes.string.isRequired
};

export default ViewUserCountries;
