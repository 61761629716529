import {
  apiGet,
  Breadcrumb,
  Form,
  Lookup,
  registerLookup
} from "@redriver/cinnamon";
import Home from "assets/icons/home_breadcrumb.svg";
import { AppRoutes, ListRoutes } from "constants/routes";
import React from "react";
import { Image } from "semantic-ui-react";

const NAMESPACE = "LISTS/LOOKUPS";

const ActionTypes = {
  SortByTypeAhead: `${NAMESPACE}/SORTBY_TYPEAHEAD`,
  GetListStatuses: `${NAMESPACE}/GET_LIST_STATUSES`,
  GetDownloadStatuses: `${NAMESPACE}/GET_DOWNLOAD_STATUSES`,
  GetContactLists: `${NAMESPACE}/GET_CONTACT_LISTS`,
  GetCompanyLists: `${NAMESPACE}/GET_COMPANY_LISTS`,
  GetList: `${NAMESPACE}/GET_LIST`,
  ListHasStatus: `${NAMESPACE}/LIST_HAS_STATUS`
};

registerLookup("listSortByOptionsLookup", () =>
  apiGet(ActionTypes.SortByTypeAhead, "lookups/lists/sort-by-options")
);

export const ListSortByDropdown = props => (
  <Form.Dropdown
    placeholder="Sort By..."
    {...props}
    lookup="listSortByOptionsLookup"
  />
);

registerLookup("contactListLookup", ({ search }) => {
  return apiGet(ActionTypes.GetContactLists, "lists/contact", {
    search
  });
});

registerLookup("companyListLookup", ({ search }) => {
  return apiGet(ActionTypes.GetCompanyLists, "lists/company", {
    search
  });
});

registerLookup("basicListLookup", listId => {
  return apiGet(ActionTypes.GetList, `lookups/lists/${listId}`);
});

export const BasicListLookup = ({ listId, ...props }) => (
  <Lookup lookup="basicListLookup" lookupParams={listId} {...props} />
);

export const ListBreadcrumbItem = ({ listId }) => (
  <BasicListLookup
    listId={listId}
    render={({ response }) =>
      response && (
        <Breadcrumb.Item
          className="single-line-text"
          content={response.text}
          link={ListRoutes.List(listId)}
        />
      )
    }
  />
);

registerLookup(
  "listStatusLookup",
  args => apiGet(ActionTypes.GetListStatuses, `lists/statuses`, args),
  { cacheExpiry: 10000 }
);

export const UpdateListBreadcrumb = ({ listId }) => (
  <Breadcrumb className="i4b-breadcrumb-container list-update-breadcrumb">
    <Breadcrumb.Item
      link={AppRoutes.Root}
      icon={<Image src={Home} title="Home" />}
    />
    <Breadcrumb.Item link={AppRoutes.Lists} content="Lists" />
    <ListBreadcrumbItem listId={listId} />
    <Breadcrumb.Item content="Update Criteria" className="single-line-text" />
  </Breadcrumb>
);

registerLookup("contactListLookup", ({ search }) => {
  return apiGet(ActionTypes.GetContactLists, "lists/contact", {
    search
  });
});

registerLookup("companyListLookup", ({ search }) => {
  return apiGet(ActionTypes.GetCompanyLists, "lists/company", {
    search
  });
});

registerLookup(
  "listHasStatusLookup",
  ({ listId, isContactList }) => {
    return apiGet(ActionTypes.ListHasStatus, "lists/has-status", {
      listId,
      isContactList
    });
  },
  { cache: false }
);

export const ListStatusesDropdown = ({
  isContactList,
  value,
  allowAdditions = false,
  required = false,
  listId = null
}) => (
  <Form.Dropdown
    lookup="listStatusLookup"
    lookupParams={{ isContactList, listId }}
    field="status"
    label="Status"
    fluid
    placeholder="Select status..."
    search
    allowAdditions={allowAdditions}
    required={required}
    unknownValueOptions={[{ value: value, text: value }]}
  />
);

registerLookup("downloadStatuses", ({ isContactList }) =>
  apiGet(ActionTypes.GetDownloadStatuses, `lookups/lists/download-reasons`, {
    isContactList
  })
);

export const ListDownloadStatuses = ({ disabled, isContactList }) => {
  return (
    <Form.Dropdown
      lookup="downloadStatuses"
      lookupParams={{ isContactList }}
      field="downloadableReason"
      label={<label className="checkbox-header">Not Downloadable Reason</label>}
      fluid
      placeholder="Select reason..."
      disabled={disabled}
    />
  );
};

registerLookup("contactListLookup", ({ search }) => {
  return apiGet(ActionTypes.GetContactLists, "lists/contact", {
    search
  });
});

registerLookup("companyListLookup", ({ search }) => {
  return apiGet(ActionTypes.GetCompanyLists, "lists/company", {
    search
  });
});
