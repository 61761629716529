import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/CUSTOMER_SEGMENT";

export const ActionTypes = {
  GetSegmentData: `${NAMESPACE}/GET_SEGMENT`,
  UpdateSegmentData: `${NAMESPACE}/UPDATE_SEGMENT`
};

export const getSegment = id =>
  apiGet(
    ActionTypes.GetSegmentData,
    `customers/${id}/segment`,
    {},
    { preventErrorNotification: true }
  );

export const saveSegment = (formData, id) => {
  // get all country-xxx properties and group into countryAccessTypes
  var countriesKeys = Object.keys(formData).filter(k => k.includes("country-"));
  var countriesFormData = {};
  countriesKeys.forEach(k => {
    const key = k.split("-")[1];
    countriesFormData[key] = formData[k];
  });

  const {
    refreshCreditCount,
    refreshDateUtc,
    segmentLimit,
    segmentCredits
  } = formData;

  return apiPut(ActionTypes.UpdateSegmentData, `customers/${id}/segment`, {
    countryAccessTypes: countriesFormData,
    refreshCreditCount,
    refreshDateUtc,
    segmentLimit,
    segmentCredits
  });
};
