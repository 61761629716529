import React from "react";
import { Image } from "semantic-ui-react";
import { PageContent } from "@redriver/cinnamon";

import NotFound from "assets/notfound.svg";

const NotFoundPage = () => (
  <PageContent center>
    <Image src={NotFound} alt="Page Not Found" title="Page Not Found" />
  </PageContent>
);

export default NotFoundPage;
