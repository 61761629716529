import { apiDelete, apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/VIEW_CUSTOMER_TAGS";

export const ActionTypes = {
  CreateTag: `${NAMESPACE}/CREATE_TAGS`,
  ViewTags: `${NAMESPACE}/VIEW_TAGS`,
  EditTag: `${NAMESPACE}/EDIT_TAG`,
  DeleteTag: `${NAMESPACE}/DELETE_TAG`,
  CheckActiveTag: `${NAMESPACE}/CHECK_ACTIVE_TAG`
};

export const getTags = (
  { pagination, filters },
  { customerId }
) => dispatch => {
  const { pageSize, pageNumber } = pagination;
  const { search } = filters;
  return dispatch(
    apiGet(ActionTypes.ViewTags, `customers/${customerId}/tags`, {
      pageSize,
      pageNumber,
      search
    })
  );
};

export const createTag = (formData, { customerId }) =>
  apiPost(ActionTypes.CreateTag, `customers/${customerId}/tags`, {
    name: formData.name
  });

export const editTag = ({ value }, id) =>
  apiPut(ActionTypes.EditTag, `customers/tags/${id}?name=${value}`);

export const deleteTag = id =>
  apiDelete(ActionTypes.DeleteTag, `customers/tags/${id}`);

export const checkActiveTag = id =>
  apiGet(ActionTypes.CheckActiveTag, `customers/tags/${id}/active`);
