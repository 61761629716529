import { FilterBase } from "components/search";
import { orderBy } from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Checkbox, Segment } from "semantic-ui-react";
import { toggleAllBatchIds, toggleBatchId } from "./actions";
import { getListsOverviewState } from "./selectors";

class DisplayedResults extends React.Component {
  state = {
    visible: false
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  render() {
    const { visible } = this.state;
    const { batches, batchIds, toggleBatchId, toggleAllBatchIds } = this.props;

    const originalAndUpdates = batches.filter(b => b.name !== "Removed");

    return (
      <Segment className="m-t-1" style={{ padding: 0, width: "100%" }}>
        <section className={`search-filter`}>
          <FilterBase.Header
            title={"Updates"}
            collapsible
            collapsed={!visible}
            onCollapseToggle={this.toggleVisibility}
            removable={false}
          />
          <FilterBase.Body collapsed={!visible}>
            <div className="list-displayed-results-container">
              <div class-name="list-displayed-results-top">
                Select which results you want to see on the right
              </div>
              <div className="list-displayed-results-content">
                <Checkbox
                  label="Select All"
                  key="select-all"
                  checked={batchIds.length === batches.length}
                  indeterminate={
                    batchIds.length !== batches.length && batchIds.length >= 1
                  }
                  onChange={toggleAllBatchIds}
                />
                <div className="list-displayed-results-batches">
                  {orderBy(originalAndUpdates, ["createdUtc"], ["desc"]).map(
                    b => {
                      return (
                        <Checkbox
                          label={
                            <label className="list-displayed-results-batch">
                              {moment(b.createdUtc).format("DD/MM/YYYY")}{" "}
                              {b.name}
                              {" - "}
                              <strong>{b.totalCount}</strong>{" "}
                              {b.totalCount > 1 ? "Results" : "Result"}{" "}
                            </label>
                          }
                          key={b.id}
                          checked={batchIds.indexOf(b.id) !== -1}
                          onChange={() => toggleBatchId(b.id)}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </FilterBase.Body>
        </section>
      </Segment>
    );
  }
}

const actions = {
  toggleBatchId,
  toggleAllBatchIds
};

const mapStateToProps = state => {
  const { batches, batchIds } = getListsOverviewState(state);

  return {
    batches,
    batchIds
  };
};

export default connect(
  mapStateToProps,
  actions
)(DisplayedResults);
