import React from "react";
import { Cell } from "react-data-grid";
import CsvContext from "./CsvContext";

class CellRenderer extends React.Component {
  render() {
    const { rowData, column } = this.props;
    let ok = true;
    let error = "";
    if (rowData.analysis && column.idx in rowData.analysis) {
      ok = rowData.analysis[column.idx].ok;
      error = rowData.analysis[column.idx].error;
    }
    return (
      <CsvContext.Consumer>
        {context => (
          <Cell
            {...this.props}
            className={
              (this.props.column.ignored ? "ignored-cell " : "") +
              (!ok ? " error-cell" : "")
            }
          />
        )}
      </CsvContext.Consumer>
    );
  }
}

export default CellRenderer;
