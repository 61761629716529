import React from "react";
import { SideMenu } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import { UserRoutes } from "constants/routes";

const ListUsersMenu = () => (
  <SideMenu pointing vertical>
    <SideMenu.Item exact link={UserRoutes.ExternalUsers}>
      <span>External</span>
    </SideMenu.Item>

    <SideMenu.Item link={UserRoutes.InternalUsers} exact>
      <span>Internal</span>
    </SideMenu.Item>
  </SideMenu>
);

export default withRouter(ListUsersMenu);
