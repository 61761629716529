import { apiGet, Form, registerLookup } from "@redriver/cinnamon";
import React from "react";

const NAMESPACE = "ROLES/LOOKUPS";

const ActionTypes = {
  RolesTypeahead: `${NAMESPACE}/ROLES_TYPEAHEAD`
};

registerLookup(
  "rolesLookup",
  () =>
    apiGet(ActionTypes.RolesTypeahead, "roles", {
      pageSize: 100,
      pageNumber: 1
    }),
  {
    transform: response => {
      return response.results.map(s => ({
        value: s.id,
        text: s.name
      }));
    }
  }
);

export const RoleDropdown = props => (
  <Form.Dropdown {...props} lookup="rolesLookup" placeholder="Role" />
);
