import { getAuthClaims } from "@redriver/cinnamon";
import { UserTypes } from "constants/permissions";
import { connect } from "react-redux";

const withCurrentUser = Component => {
  return connect(
    state => {
      const {
        userId,
        userName,
        userEmail,
        userType,
        impersonatorId,
        customer,
        impersonatorFirstName
      } = getAuthClaims(state);

      return {
        currentUser: {
          id: userId,
          name: userName,
          email: userEmail,
          customer: customer,
          type: userType,
          isInternal: userType && userType == UserTypes.Internal,
          impersonator: impersonatorId
            ? {
                id: impersonatorId,
                firstName: impersonatorFirstName
              }
            : undefined
        }
      };
    },
    () => ({})
  )(Component);
};

export default withCurrentUser;
