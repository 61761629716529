import isFormDataEmpty from "./isFormDataEmpty";

const resetSearchCriteria = (
  currentForm,
  prevForm,
  isSystemGenerated,
  resetReduxAction,
  resetAllAction
) => {
  if (isFormDataEmpty(currentForm) && !isFormDataEmpty(prevForm)) {
    if (isSystemGenerated) {
      resetAllAction();
    } else {
      resetReduxAction();
    }
  }
};

export default resetSearchCriteria;
