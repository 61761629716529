import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { Format } from "@redriver/cinnamon";
import ReverifyResetUserConfirmationModal from "./ReverifyResetUserConfirmationModal";

const UserDetailsReadOnly = ({ data, onClick }) => {
  return (
    <React.Fragment>
      <div className="flex">
        <h2 className="m-r-1">User Details</h2>
        <Icon
          className="primary cursor-pointer"
          name="pencil"
          onClick={onClick}
        />
      </div>
      <section className="flex m-t-05">
        <div
          className="m-r-2"
          style={{ textAlign: "right", maxWidth: "100px" }}
        >
          <p>Created</p>
          <p>Training Date</p>
          <p>User ID</p>
          <p>Customer ID</p>
        </div>
        <div>
          <p className="font-weight-bold">
            <Format.DateTime value={data.createdUtc} format="short" />
          </p>
          <p className="font-weight-bold">
            {data.trainingDate ? (
              <React.Fragment>
                <Format.Date value={data.trainingDate} format="DD/MM/YYYY" />
                {` by ${data.trainer || "-"}`}
              </React.Fragment>
            ) : (
              "-"
            )}
          </p>
          <p className="font-weight-bold">{data.originalId}</p>
          <p className="font-weight-bold">{data.customerOriginalId}</p>
        </div>
      </section>
      {!!data.company && (
        <section className="flex m-t-1">
          <ReverifyResetUserConfirmationModal
            id={data.id}
            name={`${data.firstName} ${data.lastName}`}
            company={data.company}
            renderTrigger={showModal => (
              <Button primary onClick={showModal}>
                Force to re-verify and reset password
              </Button>
            )}
          />
        </section>
      )}
    </React.Fragment>
  );
};

export default UserDetailsReadOnly;
