import {
  requestIsBusy,
  requestIsSlow,
  requestIsSuccess,
  requestError
} from "@redriver/cinnamon";
import { RE_VERIFY_EMAIL, EMAIL_VERIFIED } from "./actions";

const initialState = {
  isVerifying: false,
  verifyEmailSlow: null,
  verifyEmailSuccess: null,
  verifyEmailError: null,

  verificationComplete: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RE_VERIFY_EMAIL:
      return {
        ...state,
        isVerifying: requestIsBusy(action),
        verifyEmailSlow: requestIsSlow(action),
        verifyEmailSuccess: requestIsSuccess(action),
        verifyEmailError: requestError(action)
      };

    case EMAIL_VERIFIED:
      return {
        ...state,
        verificationComplete: true
      };

    default:
      return state;
  }
};
