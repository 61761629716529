import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import AddTag from "assets/icons/add_tag.svg";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import { Icon, Image } from "semantic-ui-react";
import {
  CustomFieldNamesDropdown,
  CustomFieldValuesDropdown
} from "../lookups";

const AddCustomFieldModal = ({
  selection,
  isActionList = false,
  onSubmitted,
  submitAction,
  viewingUniverse,
  allowAdditions
}) => {
  return (
    <ModalBuilder
      withForm
      submitAction={submitAction}
      submitParams={{ selection, viewingUniverse }}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <PermissionCheck target={Targets.ItemTags} action={Actions.Edit}>
          {isActionList ? (
            <div className="dropdown-action-container" onClick={showModal}>
              Add company custom field
            </div>
          ) : (
            <Image
              src={AddTag}
              className="row-action-icon"
              onClick={showModal}
            />
          )}
        </PermissionCheck>
      )}
      renderModal={(modalProps, formProps) => (
        <Modal.Add
          {...modalProps}
          header={
            <div>
              <Icon name="plus" /> Add Company Custom Field
            </div>
          }
          submitLabel="Add Field"
          submitDisabled={!formProps.value.name || !formProps.value.value}
          size="tiny"
        >
          <Form {...formProps}>
            <CustomFieldNamesDropdown
              required={true}
              value={formProps.value.name}
              allowAdditions={allowAdditions}
            />
            <CustomFieldValuesDropdown
              customFieldId={formProps.value.name}
              value={formProps.value.value}
              required={true}
              allowAdditions={allowAdditions}
            />
            <Form.Trigger
              event="onSet"
              field="name"
              target="value"
              action="clear"
            />
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default AddCustomFieldModal;
