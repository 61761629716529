import { Form, FormBuilder } from "@redriver/cinnamon";
import { AppRoutes } from "constants/routes";
import { SavedSearchType } from "constants/search";
import { UploadActionType } from "constants/uploads";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { getUploadDetails, submitFileMetadata } from "../actions";

const FileDetailsStep = ({ progressTo, uploadId, history, dataLoading }) => (
  <FormBuilder
    loadAction={getUploadDetails}
    loadParams={uploadId}
    submitAction={submitFileMetadata}
    submitParams={uploadId}
    onSubmitted={() => progressTo(2)}
    renderForm={(formProps, state, events) => (
      <Form {...formProps} style={{ width: "90%" }}>
        <Form.Input field="name" label="Name" fluid required />
        <Form.Group
          style={{ justifyContent: "space-between" }}
          className="action-type-group"
        >
          <Form.RadioGroup
            field="uploadType"
            label="Select Upload Type"
            required
            inline
            options={[
              { value: SavedSearchType.ContactSearch, text: "Contacts" },
              { value: SavedSearchType.CompanySearch, text: "Companies" }
            ]}
            as="button"
            className="action-type-selection"
          />
        </Form.Group>
        <Form.Group
          style={{ justifyContent: "space-between" }}
          className="action-type-group"
        >
          <Form.RadioGroup
            field="actionType"
            label="Select Action Type"
            required
            inline
            options={[
              {
                value: UploadActionType.TagBulkUpdate,
                text: "Add Custom Fields"
              },
              { value: UploadActionType.SearchFilter, text: "Match" }
            ]}
            as="button"
            className="action-type-selection"
          />
        </Form.Group>
        <Form.Group style={{ justifyContent: "space-between" }}>
          <Button
            className="secondary-small-button"
            icon="long arrow alternate left"
            content="CANCEL"
            onClick={() => history.push(AppRoutes.Uploads)}
          />
          <Button
            secondary
            className="secondary-small-button"
            icon="long arrow alternate right"
            content="NEXT"
            disabled={dataLoading}
            loading={dataLoading}
            onClick={events.onSubmit}
          />
        </Form.Group>
      </Form>
    )}
  />
);

FileDetailsStep.propTypes = {
  progressTo: PropTypes.func.isRequired,
  uploadId: PropTypes.string.isRequired
};

export default withRouter(FileDetailsStep);
