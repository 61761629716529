import { PermissionCheck } from "components/auth";
import { DownloadButton, SearchResultsWrapper } from "components/search";
import { Actions, Targets } from "constants/permissions";
import {
  ContactsActionsDropdown,
  ContactsFilters,
  ContactsResultsTable
} from "features/Contacts";
import {
  addContactTag,
  addSuppressions,
  addToList,
  clearSelected,
  clearTags,
  getContacts,
  getCustomerId,
  getDownload,
  getDownloadCount,
  hardResetSearch,
  saveList,
  updatePageNumber,
  updateSearchFilter
} from "features/Contacts/ContactsSearch/actions";
import { getContactsSearchState } from "features/Contacts/ContactsSearch/selectors";
import { UpdateListBreadcrumb } from "features/Lists/lookups";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid } from "semantic-ui-react";

class ContactsSearchPage extends React.Component {
  componentDidMount() {
    const { getCustomerId, clearSelected } = this.props;
    getCustomerId();
    clearSelected();
  }

  render() {
    const {
      contacts,
      pageSize,
      pageNumber,
      updatePageNumber,
      updateSearchFilter,
      selectedItems,
      hardResetSearch,
      pageChanged,
      selection,
      customerId,
      getContacts,
      formData,
      getDownloadCount,
      getDownload,
      match: {
        params: { listId }
      },
      isUniverse,
      isSegmented
    } = this.props;

    return (
      <Grid className="search-page-grid" stackable>
        <Grid.Column className="left-column">
          {listId && <UpdateListBreadcrumb listId={listId} />}
          <ContactsFilters listId={listId} />
        </Grid.Column>
        <Grid.Column className="right-column colour-expanded-table">
          <SearchResultsWrapper
            updatePageNumber={updatePageNumber}
            data={contacts}
            pageNumber={pageNumber}
            pageSize={pageSize}
            actionsDropdown={
              <div className="i4b-search-actions-wrapper">
                <PermissionCheck
                  target={Targets.Contacts}
                  action={Actions.Download}
                >
                  <DownloadButton
                    loadCount={getDownloadCount}
                    download={getDownload}
                    isContact={true}
                    isPreview={isUniverse}
                    disabled={contacts.totalResults == 0}
                  />
                </PermissionCheck>
                <ContactsActionsDropdown
                  selectedItems={selectedItems}
                  selection={selection}
                  formData={formData}
                  addToList={addToList}
                  saveList={saveList}
                  addContactTag={addContactTag}
                  clearTags={clearTags}
                  customerId={customerId}
                  addSuppressions={addSuppressions}
                  onSubmitted={() => getContacts(true, true, true, true)}
                  isUniverse={isUniverse}
                  isSegmented={isSegmented}
                />
              </div>
            }
            isContactsPage
            listId={listId}
            pageChanged={pageChanged}
          >
            <ContactsResultsTable
              listId={listId}
              updateSearchFilter={updateSearchFilter}
              viewingUniverse={isUniverse}
            />
          </SearchResultsWrapper>
        </Grid.Column>
      </Grid>
    );
  }
}

const actions = {
  updatePageNumber,
  updateSearchFilter,
  hardResetSearch,
  getCustomerId,
  getContacts,
  getDownloadCount,
  getDownload,
  clearSelected
};

const mapStateToProps = state => {
  const {
    formData,
    contacts,
    pageSize,
    pageNumber,
    selectedItems,
    pageChanged,
    selection,
    customerId,
    isSegmented,
    isUniverse
  } = getContactsSearchState(state);
  return {
    formData,
    contacts,
    pageSize,
    pageNumber,
    selectedItems,
    pageChanged,
    selection,
    customerId,
    isUniverse,
    isSegmented
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(ContactsSearchPage)
);
