import { Form, getAuthClaims } from "@redriver/cinnamon";
import { SearchFiltersDivider } from "components/search";
import {
  CompanyFollowedFilter,
  CompanyUploadsFilter,
  ContactFollowedFilter,
  ContactListsFilter,
  ContactsInCompanyListsFilter,
  ContactTagsFilter,
  ContactUploadsFilter,
  CountriesFilter,
  CustomFieldsFilter,
  EmailDdiFilter,
  IndustriesFilter,
  JobsFilter,
  LanguagesFilter,
  PostalAreasFilter,
  SizeFilter
} from "components/search/filters";
import {
  guidComparison,
  isFormDataEmpty,
  resetSearchCriteria
} from "features/Utils";
import React from "react";
import { connect } from "react-redux";
import {
  clearJobsFilter,
  clearSelected,
  clearSizeFilter,
  getListFormData,
  getPreviousFormData,
  getSegmentContactsCount,
  getUniverseContactsCount,
  removeFilter,
  resetSearch,
  resetSearchSoft,
  updateForm
} from "./actions";
import AddFilter from "./AddFilter";
import SearchFilter from "./SearchFilter";
import { getContactsSearchState } from "./selectors";

class ContactsFilters extends React.Component {
  componentDidMount() {
    const {
      userId,
      formData,
      getPreviousFormData,
      listId,
      getListFormData,
      getUniverseContactsCount,
      getSegmentContactsCount
    } = this.props;
    // If we're updating an existing list
    if (listId) {
      getListFormData(listId);
    } else {
      if (
        isFormDataEmpty(formData) ||
        !guidComparison(formData.searchCriteriaUserId, userId)
      ) {
        getPreviousFormData(userId);
      }
    }

    getUniverseContactsCount();
    getSegmentContactsCount();
  }

  componentDidUpdate(prevProps) {
    const {
      formData,
      resetSearch,
      resetSearchSoft,
      criteriaIsSystemGenerated
    } = this.props;

    resetSearchCriteria(
      formData,
      prevProps.formData,
      criteriaIsSystemGenerated,
      resetSearchSoft,
      resetSearch
    );
  }

  render() {
    const {
      formData,
      updateForm,
      results,
      loading,
      pageChanged,
      clearSelected,
      removeFilter,
      clearSizeFilter,
      clearJobsFilter,
      listId,
      isUniverse
    } = this.props;

    return (
      <Form value={formData} onChange={updateForm}>
        <Form.Array
          field="searchCriteria"
          arrayKey="id"
          propagateUpdates="always" // todo: remove this workaround, optimise by putting result count into the formData array through the reducer instead. (Talk to NP)
        >
          {({ fields, arrayIndex }) => {
            switch (fields.type) {
              case "searchFilter":
                return (
                  <SearchFilter
                    id={fields.id}
                    value={fields.search}
                    listId={listId}
                  />
                );
              case "countriesFilter":
                return (
                  <CountriesFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    clearSelected={e => clearSelected(e, fields.id)}
                    viewingUniverse={isUniverse}
                  />
                );
              case "stockExchangeIndexesFilter":
                // id:78933
                return <React.Fragment />;
              // return (
              //   <StockExchangeIndexesFilter
              //     id={fields.id}
              //     results={results}
              //     formData={formData}
              //     loading={loading}
              //     pageChanged={pageChanged}
              //     removeFilter={(id, e) => removeFilter(e, id)}
              //     clearSelected={e => clearSelected(e, fields.id)}
              //   />
              // );
              case "postalAreasFilter":
                return (
                  <PostalAreasFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                    viewingUniverse={isUniverse}
                  />
                );
              case "sizeFilter":
                return (
                  <SizeFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSizeFilter={(_, e) => clearSizeFilter(e)}
                  />
                );
              case "industriesFilter":
                return (
                  <IndustriesFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "languagesFilter":
                return (
                  <LanguagesFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "contactTagsFilter":
                return (
                  <ContactTagsFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "customFieldsFilter":
                return (
                  <CustomFieldsFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "listsFilter":
                return (
                  <ContactListsFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "contactUploadsFilter":
                return (
                  <ContactUploadsFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "companyUploadsFilter":
                return (
                  <CompanyUploadsFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "jobsFilter":
                return (
                  <JobsFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearJobsFilter={(_, e) => clearJobsFilter(e)}
                  />
                );
              case "contactFollowedFilter":
                return (
                  <ContactFollowedFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "companyFollowedFilter":
                return (
                  <CompanyFollowedFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "contactsInCompanyListsFilter":
                return (
                  <ContactsInCompanyListsFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "emailDdiFilter":
                return (
                  <EmailDdiFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
            }
          }}
        </Form.Array>
        <SearchFiltersDivider />
        <AddFilter />
      </Form>
    );
  }
}

const actions = {
  updateForm,
  clearSelected,
  removeFilter,
  clearSizeFilter,
  clearJobsFilter,
  getPreviousFormData,
  resetSearch,
  resetSearchSoft,
  getListFormData,
  getUniverseContactsCount,
  getSegmentContactsCount
};

const mapStateToProps = state => {
  const {
    formData,
    contactsLoading,
    contacts,
    pageChanged,
    criteriaIsSystemGenerated,
    isUniverse
  } = getContactsSearchState(state);
  const { userId } = getAuthClaims(state);
  const { criteriaResultCount } = contacts;
  return {
    formData,
    loading: contactsLoading,
    results: criteriaResultCount,
    pageChanged,
    userId,
    criteriaIsSystemGenerated,
    isUniverse
  };
};

export default connect(
  mapStateToProps,
  actions
)(ContactsFilters);
