import {
  connectForm,
  Field,
  formUtils,
  formValidators
} from "@redriver/cinnamon";
import { CompanyRoutes } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Checkbox as SCheckbox } from "semantic-ui-react";
import CustomTreeView from "./CustomTreeView";

/**
 * Field for a tree-view of checkboxes
 */
class CompanyStructureCheckboxTree extends React.Component {
  static propTypes = {
    // -------------------
    // field props
    // -------------------

    /**
     * Label text to display alongside the field
     */
    label: PropTypes.node,
    /**
     * Width of the field in approximate number of characters, or a valid CSS width
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Whether the width of the field should automatically fill all available space
     */
    fluid: PropTypes.bool,
    /**
     * Array of tree nodes objects in format [{ value: "", text: "", children: [] }]
     * @property {any} `value` Unique key for the node (note that only leaf nodes are actual selectable values in the tree)
     * @property {string} `text` Label for the node checkbox
     * @property {Array} `children` Optional child node objects
     * @property {boolean} `defaultExpanded` Whether the node should be expanded when initially mounted
     * @property {string} `className` Optional classes for styling
     * @property {boolean} `disabled` Whether the node checkbox should be disabled
     */
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.object),
        defaultExpanded: PropTypes.bool,
        className: PropTypes.string,
        disabled: PropTypes.bool
      })
    ).isRequired,

    // -------------------
    // validator props
    // -------------------

    /**
     * Whether this field should be mandatory to completing the form
     */
    required: PropTypes.bool,
    /**
     * Override the default error message for required fields
     */
    requiredError: PropTypes.string,

    // -------------------
    // connectForm props
    // -------------------

    /**
     * Name of this field, and the form data key against which the value will be stored
     */
    field: PropTypes.string.isRequired,
    /**
     * Whether any errors on the field should be displayed, if not specified then inherits from the parent form or fields
     */
    showErrors: PropTypes.bool,
    /**
     * Whether to display all errors for this field or just show one error at a time, if not specified then inherits from the parent form or fields
     */
    allErrors: PropTypes.bool,
    /**
     * Time in milliseconds for field error animation transitions or false to disable, if not specified then inherits from the parent form or fields
     */
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    /**
     * Whether to disable the field, if not specified then inherits from the parent form or fields
     */
    disabled: PropTypes.bool,
    /**
     * Whether the field should be read-only, if not specified then inherits from the parent form or fields
     * @ignore
     */
    readOnly: PropTypes.bool,
    /**
     * Additional error messages that should be displayed before validator error messages
     */
    customErrors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function that will be run everytime the field changes to perform additional validation
     * Resulting errors can be passed to customErrors
     */
    customValidator: PropTypes.func,
    /**
     * List of other field names that should be re-validated when this field changes
     */
    notifiedFields: PropTypes.arrayOf(PropTypes.string),
    /**
     * Current value of the field (supplied by the form connection)
     * @ignore
     */
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number)
    ]),
    /**
     * Callback when the field has changed (supplied by the form connection)
     * @ignore
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Array of error messages to display on the field (supplied by the form connection)
     * @ignore
     */
    errors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Props excluding those from the form connection (supplied by the form connection)
     * @ignore
     */
    passThruProps: PropTypes.object,
    /**
     * The current state of the form relative to this field (supplied by the form connection)
     * @ignore
     */
    formState: PropTypes.object
  };

  static defaultProps = {
    value: [],
    onChange: () => {},
    label: ""
  };

  hasChildren = node => node.children && node.children.length > 0;

  getLeafValuesRecursive = (node, includeDisabled = false) => {
    if (!this.hasChildren(node)) {
      return includeDisabled || !node.disabled ? [node.value] : [];
    }
    return node.children.reduce(
      (acc, c) => {
        if (this.hasChildren(c)) {
          return [...acc, ...this.getLeafValuesRecursive(c, includeDisabled)];
        } else {
          return includeDisabled || !c.disabled ? [...acc, c.value] : acc;
        }
      },
      node.disabled ? [] : [node.value]
    );
  };

  getIndeterminateState = node => {
    // an indeterminate node is one where it's children are partially selected
    const { value } = this.props;
    const currentValues = Array.isArray(value) ? value : [];
    if (currentValues.some(c => c == node.value)) {
      return 2;
    } else {
      return 0;
    }
  };

  onChange = (node, checked) => {
    const { value } = this.props;
    const currentValues = Array.isArray(value) ? value : [];

    if (checked) {
      this.props.onChange([...currentValues, node.value]);
    } else {
      const newValues = currentValues.filter(v => v != node.value);
      this.props.onChange(newValues);
    }
  };

  renderNode = (node, state, events, arrow) => {
    const { disabled, companyId } = this.props;
    const indeterminateState = this.getIndeterminateState(node);
    const hasChildren = node.children && node.children.length > 0;
    return (
      <div>
        <div style={{ display: "flex" }}>
          {hasChildren ? arrow : <div style={{ width: "25px" }}>&nbsp;</div>}
          <SCheckbox
            checked={indeterminateState === 2}
            onChange={(e, d) => {
              this.onChange(node, d.checked);
            }}
            label=""
            value={node.value}
            disabled={disabled || node.disabled}
          />
          <Link to={CompanyRoutes.Overview(node.value)}>{node.text}</Link>
        </div>
        <div
          className="node-details"
          style={{
            marginLeft: "25px" //hasChildren ? "25px" : "0px"
          }}
        >
          {node.value === companyId && node.treeLevel === 0 ? (
            <small>Ultimate Parent & Current Company</small>
          ) : node.value === companyId ? (
            <small>Current Company</small>
          ) : node.treeLevel === 0 ? (
            <small>Ultimate Parent</small>
          ) : (
            " "
          )}
          {node.isDefunct && (
            <small className="company-defunct">Company is defunct</small>
          )}
          {node.isOutsideOfSegment && (
            <small className="company-defunct">Outside of segment</small>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      errors,
      showErrors,
      allErrors,
      animateErrors,
      disabled,
      label,
      width,
      fluid,
      required,
      passThruProps,
      nodes,
      companyId
    } = this.props;

    const otherProps = formUtils.omitProps(
      passThruProps,
      Object.keys(CompanyStructureCheckboxTree.propTypes)
    );

    return (
      <Field
        required={required}
        disabled={disabled}
        width={width}
        fluid={fluid}
        label={label}
        errors={formUtils.fieldErrors(errors, showErrors, allErrors)}
        animateErrors={animateErrors}
      >
        <CustomTreeView
          {...otherProps}
          nodes={nodes}
          nodeKey="value"
          renderNode={this.renderNode}
          companyId={companyId}
        />
      </Field>
    );
  }
}

export default connectForm({
  displayName: props =>
    props.label && typeof props.label === "string"
      ? props.label
      : formUtils.prettifyField(props.field),
  validators: [formValidators.requiredField(false)]
})(CompanyStructureCheckboxTree);
