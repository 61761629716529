import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Button, Icon } from "semantic-ui-react";

const SaveSearchModal = ({ submitParams, submitAction, isCompaniesPage }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={submitAction}
      submitParams={submitParams}
      renderTrigger={showModal => (
        <Button
          content="Save"
          primary
          className="green-small-button"
          onClick={showModal}
          type="button"
        />
      )}
      renderModal={(modalProps, formProps) => (
        <Modal.Add
          {...modalProps}
          header={
            <div>
              <Icon name="plus" /> Save{" "}
              {isCompaniesPage ? "Company" : "Contact"} Search
            </div>
          }
          submitLabel="Save"
          size="tiny"
        >
          <Form {...formProps}>
            <Form.Input
              field="name"
              label="Name"
              placeholder="Name"
              required
              fluid
            />
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default SaveSearchModal;
