import { Table } from "@redriver/cinnamon";
import React from "react";
import DeleteCompanySuppressionModal from "./DeleteCompanySuppressionModal";
import DeleteContactSuppressionModal from "./DeleteContactSuppressionModal";

const ActionsColumn = ({ item, customerId, onSubmitted, isContact }) => (
  <Table.Menu
    direction="left"
    icon="ellipsis horizontal"
    className="actions-column"
  >
    <Table.MenuItem>
      {isContact ? (
        <DeleteContactSuppressionModal
          suppression={item}
          customerId={customerId}
          onSubmitted={onSubmitted}
        />
      ) : (
        <DeleteCompanySuppressionModal
          suppression={item}
          customerId={customerId}
          onSubmitted={onSubmitted}
        />
      )}
    </Table.MenuItem>
  </Table.Menu>
);

export default ActionsColumn;
