import { PermissionCheck, UserTypeCheck } from "components/auth";
import { AddToSegmentsModal, RemoveFromSegmentsModal } from "components/search";
import { Actions, Targets, UserTypes } from "constants/permissions";
import AddCustomFieldModal from "features/Companies/CompaniesSearch/AddCustomFieldModal";
import AddTagModal from "features/Contacts/ContactsSearch/AddTagModal";
import React from "react";
import { Button } from "semantic-ui-react";
import {
  addCompanyTag,
  addContactTag,
  blockItems,
  clearFieldsOrTags,
  removeItems,
  suppressItems,
  saveList,
  addToList,
  getSegmentAdditions,
  getSegmentRemovals,
  addCompaniesToSegments,
  removeCompaniesFromSegments
} from "./actions";
import SaveListModal from "components/search/modals/SaveListModal";
import AddToListModal from "components/search/modals/AddToListModal";
import AddRelationsModal from "./ActionsDropdown/AddRelationsModal";
import BlockItemsModal from "./ActionsDropdown/BlockItemsModal";
import ClearFieldsOrTagsModal from "./ActionsDropdown/ClearFieldsOrTagsModal";
import RemoveItemsModal from "./ActionsDropdown/RemoveItemsModal";
import SuppressItemsModal from "./ActionsDropdown/SuppressItemsModal";
import UpdateStatusModal from "./ActionsDropdown/UpdateStatusModal";

class ListsActionsDropdown extends React.Component {
  state = {
    dropdownOpen: false
  };

  componentWillReceiveProps = nextProps => {
    const { dropdownOpen } = this.state;
    const { selectedItems } = nextProps;
    if (!selectedItems && dropdownOpen) {
      this.toggleDropdownOpen();
    }
  };

  toggleDropdownOpen = () =>
    this.setState({ dropdownOpen: !this.state.dropdownOpen });

  onSubmitted = () => {
    const { onSubmitted } = this.props;
    this.toggleDropdownOpen();
    onSubmitted();
  };

  onRemoved = () => {
    const { onRemoved } = this.props;
    this.toggleDropdownOpen();
    onRemoved();
  };

  render() {
    const {
      selectedItems,
      selection,
      className,
      isContactList,
      onRemoved,
      listId,
      allowTagAdditions,
      isSegmented,
      viewingUniverse,
      formData
    } = this.props;
    const { dropdownOpen } = this.state;
    return (
      <div className={className}>
        <span
          className={!selectedItems ? `none-selected` : undefined}
          onClick={selectedItems ? this.toggleDropdownOpen : null}
        >
          Actions
        </span>
        <Button
          className={`actions-dropdown-button ${
            !selectedItems
              ? `none-selected`
              : selectedItems && !dropdownOpen
              ? `some-selected`
              : `close`
          }`}
          onClick={selectedItems ? this.toggleDropdownOpen : null}
        />
        <section
          style={
            dropdownOpen
              ? {
                  visibility: "visible",
                  opacity: "1"
                }
              : {
                  visibility: "hidden",
                  opacity: "0"
                }
          }
          className="actions-dropdown-section"
        >
          <header>
            <h3>Actions</h3>
            <p>
              {selectedItems} selected{" "}
              {selectedItems > 1 ? "results" : "result"}
            </p>
          </header>
          <ul className="list-unstyled">
            <SaveListModal
              selection={selection}
              forceDumbList
              submitAction={(modalData, submitParams) =>
                saveList(modalData, submitParams, isContactList)
              }
              isCompaniesPage={!isContactList}
            />
            <AddToListModal
              submitParams={{ selection }}
              submitAction={(modalData, submitParams) =>
                addToList(modalData, submitParams, isContactList)
              }
              isCompaniesPage={!isContactList}
            />
            <UserTypeCheck restrictTo={UserTypes.External}>
              <li>
                <div>
                  {isContactList ? (
                    <AddTagModal
                      selection={selection}
                      submitAction={addContactTag}
                      onSubmitted={this.onSubmitted}
                      isContactsPage
                      isActionList={true}
                      allowAdditions={allowTagAdditions}
                    />
                  ) : (
                    <AddCustomFieldModal
                      selection={selection}
                      onSubmitted={this.onSubmitted}
                      submitAction={addCompanyTag}
                      isActionList={true}
                      allowAdditions={allowTagAdditions}
                    />
                  )}
                </div>
              </li>
            </UserTypeCheck>
            <ClearFieldsOrTagsModal
              submitAction={clearFieldsOrTags}
              onSubmitted={this.onSubmitted}
              isContactList={isContactList}
              numSelected={selectedItems}
            />
            <li>
              <div>
                <UpdateStatusModal
                  onSubmitted={this.onSubmitted}
                  isContactList={isContactList}
                  numSelected={selectedItems}
                  listId={listId}
                />
              </div>
            </li>
            <UserTypeCheck restrictTo={UserTypes.External}>
              <li>
                <div>
                  <SuppressItemsModal
                    submitAction={suppressItems}
                    onSubmitted={this.onRemoved}
                    isContactList={isContactList}
                    numSelected={selectedItems}
                  />
                </div>
              </li>
            </UserTypeCheck>
            <UserTypeCheck restrictTo={UserTypes.External}>
              <li>
                <div>
                  <BlockItemsModal
                    submitAction={blockItems}
                    onSubmitted={this.onRemoved}
                    isContactList={isContactList}
                    numSelected={selectedItems}
                  />
                </div>
              </li>
            </UserTypeCheck>
            <UserTypeCheck restrictTo={UserTypes.External}>
              <li>
                <div>
                  <RemoveItemsModal
                    submitAction={removeItems}
                    onSubmitted={this.onRemoved}
                    isContactList={isContactList}
                    numSelected={selectedItems}
                  />
                </div>
              </li>
            </UserTypeCheck>
            <PermissionCheck target={Targets.List} action={Actions.Edit}>
              {!isContactList && (
                <li>
                  <div>
                    <AddRelationsModal
                      onSubmitted={this.onRemoved}
                      isContactList={isContactList}
                    />
                  </div>
                </li>
              )}
            </PermissionCheck>
            <UserTypeCheck restrictTo={UserTypes.External}>
              {isSegmented && !isContactList && (
                <PermissionCheck
                  target={Targets.CustomerSegments}
                  action={Actions.Edit}
                >
                  {viewingUniverse && (
                    <li>
                      <div>
                        <AddToSegmentsModal
                          submitAction={addCompaniesToSegments}
                          loadAction={getSegmentAdditions}
                          loadParams={{
                            listId,
                            selection,
                            selectedItems,
                            isUniverse: viewingUniverse,
                            listFilters: formData
                          }}
                          submitParams={{
                            listId,
                            selection,
                            selectedItems,
                            isUniverse: viewingUniverse,
                            listFilters: formData
                          }}
                          onSubmitted={this.onRemoved}
                        />
                      </div>
                    </li>
                  )}
                  {!viewingUniverse && (
                    <PermissionCheck
                      target={Targets.Override}
                      action={Actions.View}
                    >
                      <li>
                        <div>
                          <AddToSegmentsModal
                            submitAction={addCompaniesToSegments}
                            loadAction={getSegmentAdditions}
                            loadParams={{
                              listId,
                              selection,
                              selectedItems,
                              isUniverse: viewingUniverse,
                              listFilters: formData
                            }}
                            submitParams={{
                              listId,
                              selection,
                              selectedItems,
                              isUniverse: viewingUniverse,
                              listFilters: formData
                            }}
                            onSubmitted={this.onRemoved}
                          />
                        </div>
                      </li>
                    </PermissionCheck>
                  )}
                  {/*formData downloadableReason different than 2(outside of segment) because
                  we only want to hide it when seeing ONLY the universe. viewingUniverse on lists is also true when reason
                  == undefined, which also includes outside of universe data*/}
                  {formData.downloadableReason != 2 && (
                    <li>
                      <div>
                        <RemoveFromSegmentsModal
                          loadAction={getSegmentRemovals}
                          submitAction={removeCompaniesFromSegments}
                          loadParams={{
                            listId,
                            selection,
                            selectedItems,
                            isUniverse: viewingUniverse,
                            listFilters: formData
                          }}
                          submitParams={{
                            listId,
                            selection,
                            selectedItems,
                            isUniverse: viewingUniverse,
                            listFilters: formData
                          }}
                          onSubmitted={this.onRemoved}
                        />
                      </div>
                    </li>
                  )}
                </PermissionCheck>
              )}
            </UserTypeCheck>
          </ul>
        </section>
      </div>
    );
  }
}

export default ListsActionsDropdown;
