import { Breadcrumb, PageContent } from "@redriver/cinnamon";
import Home from "assets/icons/home_breadcrumb.svg";
import { AppRoutes } from "constants/routes";
import { AdvancedOptOutReport } from "features/OptOutReport";
import React from "react";
import { Image } from "semantic-ui-react";

const AdvancedOptOutReportPage = () => {
  return (
    <React.Fragment>
      <Breadcrumb className="i4b-breadcrumb-container">
        <Breadcrumb.Item
          link={AppRoutes.Root}
          icon={<Image src={Home} title="Home" />}
        />
        <Breadcrumb.Item content="Opt Out Files" />
      </Breadcrumb>
      <PageContent className="i4b-list-page">
        <AdvancedOptOutReport />
      </PageContent>
    </React.Fragment>
  );
};

export default AdvancedOptOutReportPage;
