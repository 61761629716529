import {
  apiGet,
  apiPut,
  Form,
  Lookup,
  registerLookup
} from "@redriver/cinnamon";
import React from "react";

const NAMESPACE = "SEARCH/LOOKUPS";

const ActionTypes = {
  GetCountries: `${NAMESPACE}/GET_COUNTRIES`,
  GetCountriesDropdown: `${NAMESPACE}/GET_COUNTRIES_DROPDOWN`,
  GetPostalAreas: `${NAMESPACE}/GET_POSTAL_AREAS`,
  GetStockExchangeIndexes: `${NAMESPACE}/GET_SE_INDEXES`,
  GetIndustries: `${NAMESPACE}/GET_INDUSTRIES`,
  GetContactTags: `${NAMESPACE}/GET_CONTACT_TAGS`,
  GetCustomFields: `${NAMESPACE}/GET_CUSTOM_FIELDS`,
  GetLanguages: `${NAMESPACE}/GET_LANGUAGES`,
  GetTechRoles: `${NAMESPACE}/GET_TECH_ROLES`,
  GetLOBRoles: `${NAMESPACE}/GET_LOB_ROLES`,
  GetTopLevelLOBRoles: `${NAMESPACE}/GET_TOP_LEVEL_LOB_ROLES`,
  GetContactLists: `${NAMESPACE}/GET_CONTACT_LISTS`,
  GetCompanyLists: `${NAMESPACE}/GET_COMPANY_LISTS`,
  GetContactUploads: `${NAMESPACE}/GET_CONTACT_UPLOADS`,
  GetCompanyUploads: `${NAMESPACE}/GET_COMPANY_UPLOADS`
};

registerLookup("countriesLookup", ({ userId, viewFullTree, viewingUniverse }) =>
  apiGet(ActionTypes.GetCountries, "lookups/countries", {
    userId,
    viewFullTree,
    viewingUniverse
  })
);

export const CountriesLookup = ({
  userId,
  viewFullTree = false,
  viewingUniverse = false,
  ...props
}) => {
  return (
    <Lookup
      lookup="countriesLookup"
      lookupParams={{ userId, viewFullTree, viewingUniverse }}
      {...props}
    />
  );
};

registerLookup("countriesDropdownLookup", ({ userId }) =>
  apiGet(ActionTypes.GetCountries, "lookups/countries-dropdown", {
    userId
  })
);

export const CountriesDropdown = ({ userId, ...props }) => (
  <Form.Dropdown
    {...props}
    lookup="countriesDropdownLookup"
    lookupParams={userId}
  />
);

registerLookup("postalAreasLookup", ({ viewingUniverse }) =>
  apiPut(ActionTypes.GetCountries, "lookups/postal-areas", {
    viewingUniverse
  })
);

export const PostalAreasLookup = ({ viewingUniverse = false, ...props }) => {
  return (
    <Lookup
      lookup="postalAreasLookup"
      lookupParams={{ viewingUniverse }}
      {...props}
    />
  );
};

registerLookup(
  "industriesLookup",
  () => apiGet(ActionTypes.GetIndustries, "lookups/industries-criteria"),
  {
    transform: data => {
      return data;
    }
  }
);

export const IndustriesLookup = props => (
  <Lookup lookup="industriesLookup" {...props} />
);

registerLookup(
  "contactTagsLookup",
  () => apiGet(ActionTypes.GetContactTags, "contact-tags"),
  {
    transform: data => {
      return data;
    }
  }
);

export const ContactTagsLookup = props => (
  <Lookup lookup="contactTagsLookup" {...props} />
);

registerLookup(
  "customFieldsLookup",
  () => apiGet(ActionTypes.GetCustomFields, "custom-fields"),
  {
    transform: data => {
      return data;
    }
  }
);

export const CustomFieldsLookup = props => (
  <Lookup lookup="customFieldsLookup" {...props} />
);

registerLookup("stockExchangeIndexesLookup", userId =>
  apiGet(
    ActionTypes.GetStockExchangeIndexes,
    "lookups/stock-exchange-indexes",
    {
      userId
    }
  )
);

export const StockExchangeIndexesLookup = ({ userId, ...props }) => (
  <Lookup
    lookup="stockExchangeIndexesLookup"
    lookupParams={userId}
    {...props}
  />
);

registerLookup(
  "languagesLookup",
  () => apiGet(ActionTypes.GetLanguages, "lookups/languages"),
  {
    transform: data => {
      const options = data.map(el => {
        return {
          text: el,
          value: el
        };
      });
      return options;
    }
  }
);

export const LanguagesLookup = props => (
  <Lookup lookup="languagesLookup" {...props} />
);

registerLookup(
  "techRolesLookup",
  () => apiGet(ActionTypes.GetTechRoles, "lookups/tech-roles"),
  {
    transform: data => {
      return data;
    }
  }
);

export const TechRolesLookup = props => (
  <Lookup lookup="techRolesLookup" {...props} />
);

registerLookup(
  "LOBRolesLookup",
  () => apiGet(ActionTypes.GetLOBRoles, "lookups/lob-roles"),
  {
    transform: data => {
      return data;
    }
  }
);

export const LOBRolesLookup = props => (
  <Lookup lookup="LOBRolesLookup" {...props} />
);

export const ContactListsLookup = props => (
  <Lookup lookup="contactListsLookup" {...props} />
);

registerLookup("contactListsLookup", () =>
  apiGet(ActionTypes.GetContactLists, "lists/contact", {
    getAll: true
  })
);

export const CompanyListsLookup = props => (
  <Lookup lookup="companyListsLookup" {...props} />
);

registerLookup("companyListsLookup", () =>
  apiGet(ActionTypes.GetCompanyLists, "lists/company", {
    getAll: true
  })
);

export const ContactUploadsLookup = props => (
  <Lookup lookup="contactUploadsLookup" {...props} />
);

registerLookup("contactUploadsLookup", () =>
  apiGet(ActionTypes.GetContactUploads, "uploads/contact", {
    getAll: true
  })
);

export const CompanyUploadsLookup = props => (
  <Lookup lookup="companyUploadsLookup" {...props} />
);

registerLookup("companyUploadsLookup", () =>
  apiGet(ActionTypes.GetCompanyUploads, "uploads/company", {
    getAll: true
  })
);

const extractLeaves = data =>
  data.children.reduce((acc, cur) => acc.concat(cur.children), []);

const extractTopLevel = data => data.children.map(c => c);

registerLookup(
  "LOBRolesDropdownLookup",
  () => apiGet(ActionTypes.GetLOBRoles, "lookups/lob-roles"),
  {
    transform: extractLeaves
  }
);

registerLookup(
  "topLevelLOBRolesDropdownLookup",
  () => apiGet(ActionTypes.GetTopLevelLOBRoles, "lookups/top-level-lob-roles"),
  {
    transform: extractTopLevel
  }
);

registerLookup(
  "techRolesDropdownLookup",
  () => apiGet(ActionTypes.GetTechRoles, "lookups/tech-roles"),
  {
    transform: extractLeaves
  }
);

registerLookup(
  "topLevelTechRolesDropdownLookup",
  () => apiGet(ActionTypes.GetTechRoles, "lookups/top-level-tech-roles"),
  {
    transform: extractTopLevel
  }
);
