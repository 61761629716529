import { Form, Format, FormBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { updateCustomerAccess } from "./actions";

const CustomerRestrictAccessForm = ({ data, onSubmitted, customerId }) => {
  return (
    <FormBuilder
      submitAction={updateCustomerAccess}
      submitParams={customerId}
      onSubmitted={onSubmitted}
      initialData={data}
      initialReadOnly
      renderError={false}
      renderForm={(formProps, state, events) => (
        <Form {...formProps} className="customer-subscription-form">
          <div className="flex">
            <h2 className="m-r-1">Restrict Access</h2>
            {state.readOnly ? (
              <PermissionCheck target={Targets.Customers} action={Actions.Edit}>
                <Icon
                  className="primary cursor-pointer"
                  name="pencil"
                  onClick={events.onToggleReadOnly}
                />
              </PermissionCheck>
            ) : (
              <React.Fragment>
                <Button
                  className="grey-small-button"
                  content="Cancel"
                  onClick={events.onToggleReadOnly}
                />
                <Button
                  className="primary green-small-button"
                  content="Save"
                  onClick={events.onSubmit}
                  loading={state.submitting}
                />
              </React.Fragment>
            )}
          </div>
          <ul className="list-unstyled m-t-05">
            <li>
              <div className="labelled-formgroup">
                {state.readOnly ? (
                  <React.Fragment>
                    <label>Lock out now</label>
                    <p className="field">
                      {formProps.value.lockOutNow ? "Yes" : "No"}
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Form.Checkbox
                      field="lockOutNow"
                      label={
                        <label
                          style={{
                            marginLeft: "1em"
                          }}
                        >
                          Lock Out Now
                        </label>
                      }
                    />
                    <Form.Trigger
                      field="lockOutNow"
                      action="change"
                      value={({ fields }) => {
                        if (fields.lockOutNow) {
                          return false;
                        } else {
                          return fields.lockOutFrom;
                        }
                      }}
                      event="onChange"
                      target="lockOutFrom"
                    />
                    <Form.Trigger
                      field="lockOutNow"
                      action="change"
                      value={({ fields }) => {
                        if (fields.lockOutNow) {
                          return null;
                        } else {
                          return fields.finalDateUtc;
                        }
                      }}
                      event="onChange"
                      target="finalDateUtc"
                    />
                  </React.Fragment>
                )}
              </div>
            </li>
            <li>
              <div
                className="labelled-formgroup"
                style={{ width: "100%", flexGrow: "0" }}
              >
                {state.readOnly ? (
                  <React.Fragment>
                    <label>Lock out from</label>
                    <p className="field">
                      {formProps.value.lockOutFrom ? (
                        <Format.Date
                          value={formProps.value.finalDateUtc}
                          format="short"
                        />
                      ) : (
                        "-"
                      )}
                    </p>
                  </React.Fragment>
                ) : (
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "70%", flexGrow: "0" }}>
                      <Form.Checkbox
                        field="lockOutFrom"
                        label={
                          <label
                            style={{
                              marginLeft: "1em"
                            }}
                          >
                            Lock Out From
                          </label>
                        }
                      />
                      <Form.Trigger
                        field="lockOutFrom"
                        action="change"
                        value={({ fields }) => {
                          if (fields.lockOutFrom) {
                            return false;
                          } else {
                            return fields.lockOutNow;
                          }
                        }}
                        event="onChange"
                        target="lockOutNow"
                      />
                    </div>
                    <div style={{ marginLeft: "1em", flexGrow: "1" }}>
                      <Form.Date
                        disabled={!formProps.value.lockOutFrom}
                        local
                        requiredError="Date is required"
                        required={formProps.value.lockOutFrom}
                        field="finalDateUtc"
                      />
                    </div>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </Form>
      )}
    />
  );
};

export default CustomerRestrictAccessForm;
