import React from "react";
import { Image } from "semantic-ui-react";
import { Breadcrumb, MenuBar, SideMenu, PageContent } from "@redriver/cinnamon";

import { ListUsers } from "features/Users";
import { AppRoutes } from "constants/routes";
import Home from "assets/icons/home_breadcrumb.svg";

const ListUserPage = ({ userType, requireCustomer }) => {
  return (
    <React.Fragment>
      <Breadcrumb className="i4b-breadcrumb-container">
        <Breadcrumb.Item
          link={AppRoutes.Root}
          icon={<Image src={Home} title="Home" />}
        />
        <Breadcrumb.Item content="Users" />
      </Breadcrumb>
      <ListUsers userType={userType} requireCustomer={requireCustomer} />
    </React.Fragment>
  );
};

export default ListUserPage;
