import React from "react";
import { Image } from "semantic-ui-react";
import { Breadcrumb, PageContent } from "@redriver/cinnamon";

import { ListNotifications } from "features/Notifications";
import { AppRoutes } from "constants/routes";
import Home from "assets/icons/home_breadcrumb.svg";

const ListNotificationsPage = () => {
  return (
    <React.Fragment>
      <Breadcrumb className="i4b-breadcrumb-container">
        <Breadcrumb.Item
          link={AppRoutes.Root}
          icon={<Image src={Home} title="Home" />}
        />
        <Breadcrumb.Item content="Notifications" />
      </Breadcrumb>
      <PageContent className="pagecontent-extended-width i4b-notification-pagecontent">
        <ListNotifications />
      </PageContent>
    </React.Fragment>
  );
};

export default ListNotificationsPage;
