import ArrowRight from "assets/icons/arrow_right.svg";
import React from "react";
import ReactDataGrid from "react-data-grid";
import { Image, Loader } from "semantic-ui-react";
import CsvContext from "./CsvContext";
import RowRenderer from "./RowRenderer";

class CsvGrid extends React.Component {
  constructor() {
    super();
    this.state = {
      currentErrorIdx: null
    };
  }

  onGridRowsUpdated = ({ fromRow, toRow, fromRowData, updated }) => {
    const changedRows = [];

    for (let i = fromRow; i <= toRow; i++) {
      changedRows.push({ rowIdx: i, changes: { ...updated } });
    }

    // hacky method of comparing the original and modified object
    const stateIsClean =
      JSON.stringify({ ...fromRowData, ...updated }) ===
      JSON.stringify(fromRowData);

    if (stateIsClean) return;

    this.context.onRowEdit(changedRows, rows => {
      const existingRows = rows.slice();
      for (let i = fromRow; i <= toRow; i++) {
        existingRows[i] = { ...existingRows[i], ...updated };
      }
      return existingRows;
    });
  };

  onCellSelected = cell => {
    const { rowIdx, idx } = cell;

    const existingErrorIndex = this.context.errors.findIndex(
      e => e.rowIdx == rowIdx && e.idx == idx
    );

    if (existingErrorIndex != -1) {
      this.setState({ currentErrorIdx: existingErrorIndex });
    } else {
      this.setState({ currentErrorIdx: null });
    }

    //clear out selected cell on non-header cell clicked
    this.context.onCellSelected(this.context.errors[existingErrorIndex] || {});
  };

  onNavigateErrorForward = () => {
    if (!this.context.errors.length) return;

    if (this.state.currentErrorIdx == null) {
      const error = this.context.errors[0];
      this.setState({ currentErrorIdx: 0 }, this.selectCell(error, true));
    } else {
      const nextIdx = this.state.currentErrorIdx + 1;
      if (nextIdx <= this.context.errors.length - 1) {
        this.setState(
          { currentErrorIdx: nextIdx },
          this.selectCell(this.context.errors[nextIdx], true)
        );
      } else {
        const error = this.context.errors[0];
        this.setState({ currentErrorIdx: 0 }, this.selectCell(error, true));
      }
    }
  };

  selectCell = (error, showEdit) => {
    /**
     * Using select cell often fails to bring the cell into view,
     *  the below works out where to position the canvas to bring it to the top
     */
    if (error.rowIdx === -1) {
      this.context.onCellSelected(error.data, showEdit);
    } else {
      const top = this.grid.getRowOffsetHeight() * error.rowIdx;
      const gridCanvas = this.grid
        .getDataGridDOMNode()
        .querySelector(".react-grid-Canvas");
      gridCanvas.scrollTop = top;
      this.grid.selectCell(error, showEdit);
    }
  };

  onNavigateErrorBackwards = () => {
    if (!this.context.errors.length) return;

    if (this.state.currentErrorIdx == null) {
      const lastIdx = this.context.errors.length - 1;
      const error = this.context.errors[lastIdx];
      this.setState({ currentErrorIdx: lastIdx }, this.selectCell(error, true));
    } else {
      const nextIdx = this.state.currentErrorIdx - 1;
      if (nextIdx < 0) {
        const lastIdx = this.context.errors.length - 1;
        const error = this.context.errors[lastIdx];
        this.setState(
          { currentErrorIdx: lastIdx },
          this.selectCell(error, true)
        );
      } else {
        this.setState(
          { currentErrorIdx: nextIdx },
          this.selectCell(this.context.errors[nextIdx], true)
        );
      }
    }
  };

  render() {
    return (
      <CsvContext.Consumer>
        {context => (
          <React.Fragment>
            <div className="error-nav">
              <Image
                src={ArrowRight}
                className="error-nav-icon rotate180"
                onClick={() => this.onNavigateErrorBackwards()}
                avatar
              />
              {context.errors.length || "No"} Error
              {context.errors.length === 1 ? " " : "s "}
              <Image
                src={ArrowRight}
                className="error-nav-icon"
                onClick={() => this.onNavigateErrorForward()}
                avatar
              />
              {context.processing && (
                <div>
                  <Loader
                    inline
                    active
                    size="mini"
                    style={{ marginLeft: "0.75rem" }}
                  />{" "}
                  Processing&hellip;
                </div>
              )}
            </div>
            <div style={{ width: "100%", overflow: "auto" }}>
              <ReactDataGrid
                ref={node => (this.grid = node)}
                columns={context.columns}
                resizable
                rowHeight={30}
                rowGetter={i => context.rows[i]}
                rowsCount={context.rows.length}
                onGridRowsUpdated={this.onGridRowsUpdated}
                rowRenderer={RowRenderer}
                enableCellSelect={true}
                onCellSelected={cell => this.onCellSelected(cell)}
              />
            </div>
          </React.Fragment>
        )}
      </CsvContext.Consumer>
    );
  }
}

CsvGrid.contextType = CsvContext;

export default CsvGrid;
