const NAMESPACE = "UI/SLIDE_PANEL";

export const ActionTypes = {
  UpdateSidePanelState: `${NAMESPACE}/UPDATE_SIDE_PANEL_STATE`
};

export const updateSidePanelState = open => ({
  type: ActionTypes.UpdateSidePanelState,
  open
});
