import { Form, PageLoader } from "@redriver/cinnamon";
import { CustomCheckboxTree } from "components/form";
import { AppRoutes } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { CompanyUploadsLookup } from "../lookups";
import FilterBase from "./FilterBase";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";
import FilterClearer from "./FilterClearer";

class CompanyFollowedFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    results: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    clearSelected: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired
  };

  state = {
    visible: true,
    total: null,
    isFollowingCompany: null
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  componentDidMount = () => {
    const { id, formData } = this.props;
    this.getSelected(formData, id);
  };

  componentDidUpdate = prevProps => {
    const { id, formData } = this.props;
    if (prevProps.formData !== formData) {
      this.getSelected(formData, id);
    }
  };

  getSelected = (formData, id) => {
    const { searchCriteria } = formData;
    const followedObj = searchCriteria.find(el => el.id == id);
    if (!followedObj || !followedObj.companyFollowedSearch) return;

    const { companyFollowedSearch } = followedObj;
    const { isFollowingCompany } = companyFollowedSearch;

    this.setState({
      isFollowingCompany
    });
  };

  render() {
    const { visible, total, isFollowingCompany } = this.state;
    const {
      id,
      loading,
      pageChanged,
      removeFilter,
      formData,
      clearSelected
    } = this.props;
    return (
      <FilterBase
        total={total}
        loading={loading && !pageChanged}
        className="followed-filter"
      >
        <FilterBase.Header
          title="Following Company"
          collapsible
          collapsed={!visible}
          onCollapseToggle={this.toggleVisibility}
          removable
          onRemove={event => removeFilter(id, event)}
          showClearer={isFollowingCompany !== null}
          onClear={clearSelected}
        >
          {!visible && (
            <div className="ellipsis-paragraph">
              {isFollowingCompany !== null
                ? isFollowingCompany
                  ? "Only show followed companies"
                  : "Exclude all followed companies"
                : ""}
            </div>
          )}
        </FilterBase.Header>

        <FilterBase.Body collapsed={!visible}>
          <Form.Object field="companyFollowedSearch">
            <Form.RadioGroup
              field="isFollowingCompany"
              options={[
                {
                  value: true,
                  text: "Only show followed companies"
                },
                {
                  value: false,
                  text: "Exclude all followed companies"
                }
              ]}
            />
          </Form.Object>
        </FilterBase.Body>
      </FilterBase>
    );
  }
}

export default withRouter(CompanyFollowedFilter);
