import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";

class EditNotesModal extends React.Component {
  render() {
    const {
      loadAction,
      loadParams,
      loadTransform,
      submitParams,
      submitAction,
      onSubmitted,
      hasNotes = false
    } = this.props;

    return (
      <ModalBuilder
        withForm
        loadAction={loadAction}
        loadParams={loadParams}
        loadTransform={loadTransform}
        submitAction={submitAction}
        submitParams={submitParams}
        renderTrigger={showModal => (
          <div className="list-button-container">
            <Button
              primary
              onClick={showModal}
              content={hasNotes ? "Edit Notes" : "Add Notes"}
              className="green-small-button"
            />
          </div>
        )}
        renderModal={(modalProps, formProps) => (
          <Modal.Edit
            {...modalProps}
            header={hasNotes ? "Edit Notes" : "Add Notes"}
            submitLabel="Save"
          >
            <Form {...formProps}>
              <Form.TextArea field="notes" label="Notes" fluid />
            </Form>
          </Modal.Edit>
        )}
      />
    );
  }
}

export default EditNotesModal;
