import React from "react";
import { Image } from "semantic-ui-react";
import { PageContent } from "@redriver/cinnamon";

import AccessDenied from "assets/accessdenied.svg";

const PermissionErrorPage = () => (
  <PageContent center>
    <Image
      src={AccessDenied}
      alt="You don't have permission to view this page."
      title="Access denied"
    />
  </PageContent>
);

export default PermissionErrorPage;
