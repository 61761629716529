import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes, UploadRoutes } from "constants/routes";
import React from "react";
import { Route, Switch } from "react-router-dom";
import PermissionErrorPage from "../PermissionErrorPage";
import UploadRoute from "./UploadRoute";
import UploadsPage from "./UploadsPage";

export default () => (
  <React.Fragment>
    <PermissionCheck target={Targets.Upload} action={Actions.View}>
      <Switch>
        <Route exact path={AppRoutes.Uploads} component={UploadsPage} />
        <Route path={UploadRoutes.Upload()} component={UploadRoute} />
      </Switch>
    </PermissionCheck>
    <PermissionCheck
      target={Targets.Upload}
      action={Actions.View}
      not
      component={PermissionErrorPage}
    />
  </React.Fragment>
);
