import React from "react";
import { Button, Icon, Image } from "semantic-ui-react";
import { Modal, ModalBuilder, Form } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { CustomerSubTypeDropdown } from "features/Customers/lookups";
import AddUserIcon from "assets/user_add_green.svg";
import { createCustomer } from "./actions";

const CreateCustomer = ({ onSubmitted }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={createCustomer}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <li onClick={showModal}>
          <Image src={AddUserIcon} className="icon-add-user" />
          Add a customer
        </li>
      )}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Add
            {...modalProps}
            header={
              <div>
                <Icon name="plus" /> Add Customer
              </div>
            }
            submitLabel="Add Customer"
            size="tiny"
          >
            <Form {...formProps}>
              <Form.Input
                label="Customer Name"
                field="name"
                placeholder="Customer Name"
                required
                fluid
              />
            </Form>
          </Modal.Add>
        );
      }}
    />
  );
};

export default CreateCustomer;
