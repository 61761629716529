import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { SettingsRoutes } from "constants/routes";
import { TemplateLookupTypeahead } from "features/Settings";
import * as fileSaver from "file-saver";
import moment from "moment";
import { default as React } from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

const generateFileName = (isContact, listName, isPreview) => {
  const fileDate = moment().format("Do_MMMM_YYYY");
  const type = listName
    ? `${listName}_`
    : `Search_${isContact ? "Contact_" : "Company_"}`;
  const preview = isPreview ? `Preview_` : "";
  return `${type}${preview}${fileDate}.csv`;
};

const DownloadButton = ({
  download,
  loadCount,
  isContact,
  isPreview,
  disabled,
  listName
}) => {
  return (
    <ModalBuilder
      withForm
      initialData={{}}
      submitAction={download}
      submitParams={{ isPreview }}
      loadAction={loadCount}
      onSubmitted={data =>
        fileSaver.saveAs(data, generateFileName(isContact, listName, isPreview))
      }
      renderTrigger={showModal => (
        <div onClick={disabled ? null : showModal}>
          <span className={disabled ? `none-selected` : undefined}>
            Download All
          </span>
          <Button
            className={`actions-dropdown-button m-r-1 ${
              disabled ? "cannot-download" : "can-download"
            }`}
          />
        </div>
      )}
      renderModal={(modalProps, formProps, state, events) => {
        if (formProps.value.overLimit) {
          return (
            <Modal.Information
              {...modalProps}
              header="Exceeded download limit"
              onSubmit={events.onCancel}
            >
              Unable to download these results as it would exceed your download
              limit of {formProps.value.limit}
            </Modal.Information>
          );
        }
        return (
          <Modal.Confirmation
            {...modalProps}
            header={
              <div>
                <Icon name="warning sign" /> Download
              </div>
            }
            size="tiny"
          >
            <p>
              You are about to download all{" "}
              <strong>{formProps.value.count}</strong> results.{" "}
              {!isPreview ? "Please select a download template." : ""}
            </p>
            {!isPreview && (
              <React.Fragment>
                <Form {...formProps}>
                  <TemplateLookupTypeahead
                    field="templateId"
                    label="Search & select a download template"
                    placeholder="Type to search existing templates"
                    required
                    fluid
                    lookupParams={{ isContact }}
                  />
                </Form>
                <PermissionCheck
                  target={Targets.ExportTemplates}
                  any
                  action={[Actions.Create, Actions.Edit]}
                >
                  <Link to={SettingsRoutes.Templates}>
                    Customise your template
                  </Link>
                </PermissionCheck>
              </React.Fragment>
            )}
          </Modal.Confirmation>
        );
      }}
    />
  );
};

export default DownloadButton;
