import { PageLoader } from "@redriver/cinnamon";
import { IndustriesIncExcDropdown } from "components/form";
import PropTypes from "prop-types";
import React from "react";
import { IndustriesLookup } from "../lookups";
import FilterBase from "./FilterBase";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";
import FilterClearer from "./FilterClearer";

class IndustriesFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    results: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    clearSelected: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired
  };
  state = {
    visible: true,
    total: null,
    included: [],
    excluded: []
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  componentDidMount = () => {
    const { id, formData } = this.props;
    this.getSelected(formData, id);
  };

  componentDidUpdate = prevProps => {
    const { id, formData } = this.props;
    if (prevProps.formData !== formData) {
      this.getSelected(formData, id);
    }
  };

  getSelected = (formData, id) => {
    const { searchCriteria } = formData;

    const industriesObj = searchCriteria.find(el => el.id == id);
    const { industriesSelection } = industriesObj;
    const { included, excluded } = industriesSelection;

    this.setState({
      included: included.length > 0 ? included.join(", ") : null,
      excluded: excluded.length > 0 ? excluded.join(", ") : null
    });
  };

  render() {
    const { visible, included, excluded, total } = this.state;
    const {
      id,
      loading,
      pageChanged,
      removeFilter,
      clearSelected,
      formData
    } = this.props;

    return (
      <IndustriesLookup
        render={({ response }, refresh) => (
          <FilterBase
            total={total}
            loading={loading && !pageChanged}
            className="i4b-filter-base"
          >
            {response ? (
              <React.Fragment>
                <FilterBase.Header
                  title="Industries"
                  collapsible
                  collapsed={!visible}
                  onCollapseToggle={this.toggleVisibility}
                  removable
                  onRemove={event => removeFilter(id, event)}
                  showClearer={!!included || !!excluded}
                  onClear={clearSelected}
                >
                  {!visible && (
                    <div className="ellipsis-paragraph">
                      {included && <span>Include: {included}</span>}
                      {excluded && <span>Exclude: {excluded}</span>}
                      {!included && !excluded && "No industries selected"}
                    </div>
                  )}
                </FilterBase.Header>
                <FilterBase.Body collapsed={!visible}>
                  <div className="i4b-filter-container handle-own-height">
                    <IndustriesIncExcDropdown
                      field="industriesSelection"
                      nodes={response.children || []}
                      flavor={{
                        included: {
                          [0]: {
                            label: "Industry to Include",
                            placeholder: "Select industry to include"
                          }
                        },
                        excluded: {
                          [0]: {
                            label: "Industry to Exclude",
                            placeholder: "Select industry to exclude"
                          }
                        }
                      }}
                    />
                  </div>
                </FilterBase.Body>
              </React.Fragment>
            ) : (
              <PageLoader
                active
                inline="centered"
                size="small"
                className="p-y-05"
              />
            )}
          </FilterBase>
        )}
      />
    );
  }
}

export default IndustriesFilter;
