import { Table } from "@redriver/cinnamon";
import { CompanyRoutes } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";

class CompaniesResultsTableRowContent extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    state: PropTypes.object,
    events: PropTypes.object,
    viewUniverse: PropTypes.bool,
    listId: PropTypes.string
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.item) {
      return false;
    }

    return this.props.state.expanded !== nextProps.state.expanded;
  }

  render() {
    const { item, state, events, listId, viewUniverse } = this.props;

    return (
      <React.Fragment>
        <Table.Column
          field="name"
          title={listId ? "" : "Select All"}
          width="40%"
          render={item => {
            const nameClass = item.isDefunct ? "color-red" : undefined;

            let name = <span className={nameClass}>{item.name}</span>;

            let defunctPopup = item.isDefunct ? (
              <Popup
                trigger={
                  <Icon name="info circle" style={{ marginLeft: "0.25rem" }} />
                }
                content="Company Is Defunct"
                position="top center"
                className="table-popup status-text"
              />
            ) : (
              undefined
            );

            if (state.expanded && !viewUniverse) {
              return (
                <React.Fragment>
                  <Link to={CompanyRoutes.Overview(item.id)}>{name}</Link>
                  {defunctPopup}
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  {name}
                  {defunctPopup}
                </React.Fragment>
              );
            }
          }}
        />
        <Table.Column field="location" width="10%" />
        <Table.Column field="industry" />
        <Table.Column
          render={() => (
            <Icon name={state.expanded ? "angle up" : "angle down"} />
          )}
          className="tiny-col"
        />
      </React.Fragment>
    );
  }
}

export default CompaniesResultsTableRowContent;
