import { getAuthClaims } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";

class LoginTerms extends Component {
  static propTypes = {
    userName: PropTypes.string,
    customer: PropTypes.string,
    nextPath: PropTypes.string
  };

  render() {
    const { userName, customer, nextPath, history } = this.props;
    let normaliseNextPath = nextPath.replace("//", "/");
    return (
      <React.Fragment>
        <p className="terms-and-conditions">
          Welcome to i&#8209;4business for{" "}
          <strong className="login-terms-green">{customer}</strong>. <br />
          <br />
          The information within this database service is copyright of
          i&#8209;4business EMEA Limited and protected by Database Rights. Only
          authorised users within {customer} may access this information, usage
          is limited to the valid business of {customer} and all access and
          usage is governed by the licence terms within the Service Agreement.{" "}
          <br />
          <br />
          By accessing this service,{" "}
          <strong className="login-terms-red">
            you warrant that you are {userName}
          </strong>{" "}
          and that you are currently authorised by {customer} to access the
          service and that you will comply with the above-mentioned licence
          terms. <br />
          <br />
          By accessing the service, you accept the use of cookies for storage of
          session information (this is in accordance with the Privacy and
          Electronic Communications (EC Directive) Regulations 2003). <br />
          <br />
          <strong className="login-terms-red">
            You must not divulge your login details to anyone.
          </strong>{" "}
          <br />
          If you think that your details may have been disclosed, please contact{" "}
          <a href="mailto:support@i4b.com">Support</a>
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button primary fluid onClick={() => history.push(normaliseNextPath)}>
            Proceed to site
          </Button>
          <Button fluid onClick={() => history.push(SystemRoutes.Logout)}>
            Exit
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { userName, customer } = getAuthClaims(state);
  return {
    userName,
    customer
  };
};

export default connect(
  mapStateToProps,
  null
)(LoginTerms);
