import { ErrorMessage, getAuthClaims } from "@redriver/cinnamon";
import { AppRoutes, SystemRoutes } from "constants/routes";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Button, Message } from "semantic-ui-react";
import { getReverifyState } from "../selectors";
import { resendEmail } from "./actions";

class ReverifyEmail extends Component {
  static propTypes = {
    emailExpiryUtc: PropTypes.string,
    resendEmail: PropTypes.func,
    isLogin: PropTypes.bool
  };

  componentDidMount() {
    const { emailExpiryUtc, resendEmail, emailVerifyPending } = this.props;

    if (!emailExpiryUtc || moment(emailExpiryUtc) < moment()) {
      resendEmail(true);
    }
  }

  render() {
    const {
      resendEmail,
      emailExpiryUtc,
      resendEmailSuccess,
      resendEmailError,
      showMessage,
      resendEmailSlow,
      impersonatorId
    } = this.props;
    if (!!impersonatorId || moment(emailExpiryUtc) > moment()) {
      //redirect to dashboard - they've been verified
      return <Redirect to={AppRoutes.Root} />;
    }

    return (
      <React.Fragment>
        <p>An email has been sent to your address</p>
        <p>Please click the link to re-verify your account</p>
        <div style={{ display: "flex" }}>
          <Button fluid onClick={() => resendEmail()} loading={resendEmailSlow}>
            Re-send Email
          </Button>
          <Button
            fluid
            as={Link}
            to={SystemRoutes.Logout}
            style={{ display: "inline-block" }}
          >
            Sign Out
          </Button>
        </div>
        {resendEmailSuccess && showMessage && (
          <Message
            success
            visible
            header="Email Sent"
            content="The re-verify was sent again successfully"
          />
        )}
        {resendEmailError && showMessage && (
          <ErrorMessage error={resendEmailError} />
        )}
      </React.Fragment>
    );
  }
}

const actions = {
  resendEmail
};

const mapStateToProps = state => {
  const {
    resendEmailSlow,
    resendEmailSuccess,
    resendEmailError,
    showMessage
  } = getReverifyState(state);
  const { emailExpiryUtc, emailVerifyPending, impersonatorId } = getAuthClaims(
    state
  );

  return {
    emailExpiryUtc,
    resendEmailSlow,
    resendEmailSuccess,
    resendEmailError,
    emailVerifyPending,
    impersonatorId,
    showMessage
  };
};

export default connect(
  mapStateToProps,
  actions
)(ReverifyEmail);
