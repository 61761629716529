import {
  requestIsBusy,
  requestIsSlow,
  requestIsSuccess,
  requestError,
  requestResponse
} from "@redriver/cinnamon";
import { ActionTypes } from "./actions";

const initialState = {
  isRemoving: false,
  removingSlow: null,
  removeSuccess: null,
  removeError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.DeleteSessions:
      return {
        ...state,
        isRemoving: requestIsBusy(action),
        removingSlow: requestIsSlow(action),
        removeSuccess: requestIsSuccess(action),
        removeError: requestError(action)
      };

    default:
      return state;
  }
};
