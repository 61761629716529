import { DataList, Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { TemplateType } from "constants/settings/templates";
import React from "react";
import { getTemplate } from "./actions";

const ViewTemplate = ({ id, onClose, columns, isInternal }) => {
  return (
    <PermissionCheck target={Targets.ExportTemplates} action={Actions.View}>
      <ModalBuilder
        withForm
        loadAction={getTemplate}
        loadParams={id}
        loadTransform={data => {
          return {
            ...data,
            templateType: data.isContact
              ? TemplateType.Contact
              : TemplateType.Company
          };
        }}
        onSubmit={onClose}
        renderModal={(modalProps, data, state) => {
          const template = data.value;
          return (
            <Modal.Information
              className="template-details-modal"
              {...modalProps}
              header="Template Details"
              submitLabel="Close"
              size="tiny"
            >
              <DataList>
                <DataList.Item title="Name">{template.name}</DataList.Item>
                <DataList.Item title="Type" width="50%">
                  {template.isContact ? "Contacts" : "Company"}
                </DataList.Item>
                <DataList.Item title="Access" width="50%">
                  {template.isPublic === "false"
                    ? "Just Me"
                    : template.customerId
                    ? "My Company"
                    : "Public"}
                </DataList.Item>
                <DataList.Item title="File Format">
                  {template.fileFormat ? template.fileFormat.name : ""}
                </DataList.Item>
              </DataList>

              <label className="field-header">Columns</label>
              <Form value={template} className="template-form">
                <div className="group-columns">
                  <Form.CheckboxGroup
                    field="columns"
                    options={
                      template.isContact ? columns.contacts : columns.companies
                    }
                    disabled
                  />
                </div>
              </Form>
            </Modal.Information>
          );
        }}
      />
    </PermissionCheck>
  );
};

export default ViewTemplate;
