import PropTypes from "prop-types";
import React from "react";
import { Header, Icon, Loader, Popup, Segment } from "semantic-ui-react";

class Widget extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    header: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    info: PropTypes.string
  };

  renderContent() {
    const { children, loading, header, info } = this.props;
    if (!children && loading) {
      return <Loader active />;
    }

    return (
      <div>
        <Header className="dashboard-widget-header">
          <div>
            {header}
            {info && (
              <Popup
                trigger={
                  <Icon
                    name="info circle"
                    size="small"
                    style={{ paddingLeft: "0.25rem" }}
                  />
                }
                content={info}
                position="top center"
              />
            )}
          </div>
          <Loader active={loading} size="small" />
        </Header>
        {children}
      </div>
    );
  }

  render() {
    const { color, className } = this.props;
    let styles = {
      borderTop: color ? `2px solid ${color}` : "auto"
    };

    const classes = !!className
      ? `dashboard-widget ${className}`
      : "dashboard-widget";

    return (
      <Segment className={classes} style={styles}>
        {this.renderContent()}
      </Segment>
    );
  }
}

export default Widget;
