import React, { Component } from "react";
import PropTypes from "prop-types";
import { Segment, Grid, Icon, Button } from "semantic-ui-react";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";

import { getUserCountries } from "./actions";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { UserCountriesCheckboxTree } from "features/Users";
import { CustomerCountriesCheckboxTree } from "features/Customers";

class EditCountriesFormWrapper extends Component {
  static propTypes = {
    userId: PropTypes.string,
    customerId: PropTypes.string,
    onSubmit: PropTypes.func.isRequired
  };

  render() {
    const { userId, customerId, onSubmit } = this.props;

    return (
      <FormBuilder
        loadAction={() => getUserCountries(userId)}
        loadTransform={data => ({ countries: data })}
        submitAction={onSubmit}
        submitParams={userId || customerId}
        initialReadOnly
        renderError={false}
        renderForm={(formProps, state, events) => {
          return (
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={14}
              className="m-b-1"
              stretched
            >
              <Grid className="user-page" style={{ margin: "0" }}>
                <Grid.Row>
                  <Grid.Column stretched>
                    <Form {...formProps}>
                      <div className="i4b-list">
                        <div className="flex">
                          <h2>Countries</h2>
                          {state.readOnly ? (
                            <PermissionCheck
                              target={Targets.User}
                              action={Actions.Edit}
                            >
                              <Icon
                                className="primary cursor-pointer"
                                name="pencil"
                                onClick={events.onToggleReadOnly}
                                style={{
                                  paddingLeft: "1em",
                                  paddingTop: "0.25em"
                                }}
                              />
                            </PermissionCheck>
                          ) : (
                            <div style={{ paddingLeft: "1em" }}>
                              <Button
                                className="grey-small-button"
                                content="Cancel"
                                onClick={events.onToggleReadOnly}
                              />
                              <Button
                                className="primary green-small-button"
                                content="Save"
                                onClick={() =>
                                  events.onSubmit({ reload: true })
                                }
                                loading={state.submitting}
                              />
                            </div>
                          )}
                        </div>
                        <Segment>
                          {!!userId ? (
                            <UserCountriesCheckboxTree
                              userId={userId}
                              isEditable={!state.readOnly}
                            />
                          ) : (
                            <CustomerCountriesCheckboxTree
                              isEditable={!state.readOnly}
                            />
                          )}
                          <ErrorMessage error={state.error} />
                        </Segment>
                      </div>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          );
        }}
      />
    );
  }
}

export default EditCountriesFormWrapper;
