import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";

import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import UserForm from "../UserForm";
import { createUser } from "./actions";

const CreateUser = ({ onSubmitted, userType, customerId, requireCustomer }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={createUser}
      submitParams={{ userType, customerId }}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <PermissionCheck target={Targets.User} action={Actions.Create}>
          <Button primary content="Add User" onClick={showModal} />
        </PermissionCheck>
      )}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Add
            {...modalProps}
            header={
              <div>
                <Icon name="plus" /> Add User
              </div>
            }
            submitLabel="Create User"
            size="tiny"
          >
            <UserForm formProps={formProps} requireCustomer={requireCustomer} />
          </Modal.Add>
        );
      }}
    />
  );
};

export default CreateUser;
