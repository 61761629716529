import {
  apiDelete,
  deauthenticate,
  getAuthRefreshToken
} from "@redriver/cinnamon";
import { resetCriteriaForLogout as resetCompanySearch } from "features/Companies/CompaniesSearch/actions";
import { resetCriteriaForLogout as resetContactSearch } from "features/Contacts/ContactsSearch/actions";

const NAMESPACE = "SYSTEM/LOGOUT";
export const REQUEST_LOGOUT = `${NAMESPACE}/REQUEST`;

export const requestLogout = () => async (dispatch, getState) => {
  // make the logout api call
  const token = getAuthRefreshToken(getState());
  const logoutAction = apiDelete(
    REQUEST_LOGOUT,
    "auth/logout",
    { token },
    { auth: true }
  );
  const { success } = await dispatch(logoutAction);

  // deauthenticate the user session
  if (!success) return;
  dispatch(deauthenticate());
};

export const onDeauthenticated = () => async dispatch => {
  // clear personal redux
  await dispatch(resetCompanySearch());
  await dispatch(resetContactSearch());
};
