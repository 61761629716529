import React from "react";
import PropTypes from "prop-types";
import { Checkbox as SCheckbox } from "semantic-ui-react";
import {
  connectForm,
  Field,
  FieldError,
  formUtils,
  formValidators,
  TreeView
} from "@redriver/cinnamon";

class CustomCheckboxGroup extends React.Component {
  static propTypes = {
    /**
     * Label text to display alongside the field
     */
    label: PropTypes.node,
    /**
     * Width of the field in approximate number of characters, or a valid CSS width
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Whether the width of the field should automatically fill all available space
     */
    fluid: PropTypes.bool,
    /**
     * Array of checkboxes to display, in the format `{ text: '', value: '', disabled: false }`
     */
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * Whether the checkboxes should be displayed inline rather than stacked vertically
     */
    inline: PropTypes.bool,

    // -------------------
    // validator props
    // -------------------

    /**
     * Whether this field should be mandatory to completing the form
     */
    required: PropTypes.bool,
    /**
     * Override the default error message for required fields
     */
    requiredError: PropTypes.string,

    // -------------------
    // connectForm props
    // -------------------

    /**
     * Name of this field, and the form data key against which the value will be stored
     */
    field: PropTypes.string.isRequired,
    /**
     * Whether any errors on the field should be displayed, if not specified then inherits from the parent form or fields
     */
    showErrors: PropTypes.bool,
    /**
     * Whether to display all errors for this field or just show one error at a time, if not specified then inherits from the parent form or fields
     */
    allErrors: PropTypes.bool,
    /**
     * Time in milliseconds for field error animation transitions or false to disable, if not specified then inherits from the parent form or fields
     */
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    /**
     * Whether to disable the field, if not specified then inherits from the parent form or fields
     */
    disabled: PropTypes.bool,
    /**
     * Whether the field should be read-only, if not specified then inherits from the parent form or fields
     * @ignore
     */
    readOnly: PropTypes.bool,
    /**
     * Additional error messages that should be displayed before validator error messages
     */
    customErrors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function that will be run everytime the field changes to perform additional validation
     * Resulting errors can be passed to customErrors
     */
    customValidator: PropTypes.func,
    /**
     * List of other field names that should be re-validated when this field changes
     */
    notifiedFields: PropTypes.arrayOf(PropTypes.string),
    /**
     * Current value of the field (supplied by the form connection)
     * @ignore
     */
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number)
    ]),
    /**
     * Callback when the field has changed (supplied by the form connection)
     * @ignore
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Array of error messages to display on the field (supplied by the form connection)
     * @ignore
     */
    errors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Props excluding those from the form connection (supplied by the form connection)
     * @ignore
     */
    passThruProps: PropTypes.object,
    /**
     * The current state of the form relative to this field (supplied by the form connection)
     * @ignore
     */
    formState: PropTypes.object
  };

  static defaultProps = {
    value: [],
    onChange: () => {},
    inline: false
  };

  onChange = (e, d) => {
    const currentValues = Array.isArray(this.props.value)
      ? this.props.value
      : [];
    const currentIndex = currentValues.indexOf(d.value);
    if (d.checked && currentIndex < 0) {
      this.props.onChange([...currentValues, d.value]);
    } else if (!d.checked && currentIndex >= 0) {
      this.props.onChange(currentValues.filter(v => v !== d.value));
    }
  };

  render() {
    const {
      value,
      errors,
      showErrors,
      allErrors,
      animateErrors,
      disabled,
      label,
      width,
      fluid,
      required,
      passThruProps,
      options,
      inline
    } = this.props;

    const semanticProps = formUtils.omitProps(
      passThruProps,
      Object.keys(CustomCheckboxGroup.propTypes)
    );

    return (
      <Field
        inline={inline}
        required={required}
        disabled={disabled}
        width={width}
        fluid={fluid}
        label={label}
        errors={formUtils.fieldErrors(errors, showErrors, allErrors)}
        animateErrors={animateErrors}
      >
        {options.map(o => {
          const isChecked = Array.isArray(value) && value.indexOf(o.value) >= 0;
          return (
            <SCheckbox
              {...semanticProps}
              key={o.key || o.value}
              checked={isChecked}
              onChange={this.onChange}
              label={o.text}
              value={o.value}
              disabled={disabled || o.disabled}
            />
          );
        })}
      </Field>
    );
  }
}

export default connectForm({
  displayName: props =>
    props.label && typeof props.label === "string"
      ? props.label
      : formUtils.prettifyField(props.field),
  validators: [formValidators.requiredField(false)]
})(CustomCheckboxGroup);
