import {
  requestIsBusy,
  requestIsSuccess,
  requestResponse
} from "@redriver/cinnamon";
import { difference, find, uniq } from "lodash";
import {
  addSearchFilter,
  clearSearchFilter,
  clearSelected,
  clearSizeFilter,
  removeFilter
} from "modules/reducers";
import { ActionTypes } from "./actions";

const initialState = {
  companiesLoading: false,
  dimmerLoading: false,
  companies: {
    results: [],
    totalResults: 0,
    criteriaResultCount: []
  },
  companiesUniverseCount: null,
  companiesSegmentCount: null,
  criteriaIsSystemGenerated: true,
  criteriaId: null,
  customerId: null,
  formData: {
    searchCriteriaUserId: null,
    searchCriteria: [
      {
        id: 0,
        searchFilter: {
          search: ""
        },
        type: "searchFilter"
      },
      {
        id: 1,
        countries: [
          {
            region: "",
            options: []
          }
        ],
        type: "countriesFilter",
        selectedCountries: []
      }
    ]
  },
  pageNumber: 1,
  pageSize: 200,
  selection: {
    allSelected: false,
    selected: [],
    deselected: []
  },
  selectedItems: null,
  pageChanged: false,
  isSegmented: false,
  expandedRows: [],
  isUniverse: false,
  unfilteredSelectedCountries: {
    universe: [],
    segment: []
  },
  viewedCompanyIds: []
};

const updateListsFilter = formData => {
  const listsFilter = find(formData.searchCriteria, { type: "listsFilter" });
  if (listsFilter) {
    listsFilter.listsSearch.isContact = false;
  }
};

const updateuploadsFilter = formData => {
  const uploadsFilter = find(formData.searchCriteria, {
    type: "companyUploadsFilter"
  });
  if (uploadsFilter) {
    uploadsFilter.uploadsSearch.isContact = false;
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GetCompanies:
      return {
        ...state,
        companiesLoading:
          requestIsBusy(action) &&
          !(action.customData && action.customData.dimmerLoading),
        dimmerLoading:
          requestIsBusy(action) &&
          (action.customData && action.customData.dimmerLoading),
        companies: requestResponse(action) || state.companies,
        pageChanged: requestIsSuccess(action) ? false : state.pageChanged,
        isSegmented: requestIsSuccess(action)
          ? requestResponse(action).isSegmented
          : false
      };
    case ActionTypes.UpdateForm:
      const newFormData = action.applyChanges(state.formData);
      updateListsFilter(newFormData);
      updateuploadsFilter(newFormData);

      return {
        ...state,
        formData: newFormData,
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null,
        pageNumber: 1
      };
    case ActionTypes.GetFormData: {
      const res = requestResponse(action);

      if (res == null) return state;

      const formData = {
        ...state.formData,
        searchCriteriaUserId: res.userId,
        searchCriteria:
          res.criteria.length > 0
            ? [...res.criteria]
            : initialState.formData.searchCriteria
      };

      let selectedCountryCache = [];
      const searchCriteria = formData.searchCriteria.map(s => {
        if (s.type !== "countriesFilter") return s;

        if (s.selectedCountries.length) {
          selectedCountryCache = selectedCountryCache.concat(
            s.selectedCountries
          );
        }

        return {
          ...s,
          selectedCountries: []
        };
      });

      return {
        ...state,
        criteriaIsSystemGenerated: res.isSystemGenerated,
        criteriaId: res.id,
        formData: formData,
        isUniverse: res.isUniverse,
        unfilteredSelectedCountries: {
          universe: selectedCountryCache,
          segment: selectedCountryCache
        }
      };
    }
    case ActionTypes.GetListFormData: {
      const res = requestResponse(action);

      if (res == null) return state;

      return {
        ...state,
        criteriaIsSystemGenerated: false,
        criteriaId: res.criteriaId,
        formData: {
          ...state.formData,
          searchCriteria: [...res.criteria]
        }
      };
    }
    case ActionTypes.UpdatePageNumber:
      const { pageNumber } = action.value;
      return {
        ...state,
        pageNumber: pageNumber,
        pageChanged: true
      };
    case ActionTypes.ResetSearch:
      return {
        ...initialState
      };
    case ActionTypes.ClearSelected:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: clearSelected(state, action.value)
        },
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null
      };
    case ActionTypes.ChangeSelection:
      const { selection } = action;

      return {
        ...state,
        selection: selection,
        selectedItems:
          selection.selected.length > 0
            ? selection.selected.length
            : selection.allSelected
            ? state.companies.totalResults - selection.deselected.length
            : null
      };

    case ActionTypes.ChangeFollowing:
      const response = requestResponse(action);
      const loading = requestIsBusy(action);
      const success = requestIsSuccess(action);

      const newResults = [...state.companies.results];

      if (action.customData && action.customData.companies) {
        for (var i = 0; i < action.customData.companies.length; i++) {
          const companyId = action.customData.companies[i].companyId;
          const company = find(newResults, c => c.companyId === companyId);
          if (company) {
            if (success === false) {
              company.followingUpdating = false;
            } else if (response) {
              company.following = action.customData.following;
              company.followingUpdating = false;
            } else {
              company.followingUpdating = true;
            }
          }
        }
      }

      return {
        ...state,
        companies: {
          ...state.companies,
          results: newResults
        }
      };

    case ActionTypes.ResetResults:
      return {
        ...state,
        companies: { ...initialState.companies },
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null
      };
    case ActionTypes.ClearSearchFilter:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: clearSearchFilter(state)
        },
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null
      };
    case ActionTypes.AddSearchFilter:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: addSearchFilter(state, action.value)
        }
      };
    case ActionTypes.ClearSizeFilter:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: clearSizeFilter(state, action.value)
        },
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null
      };
    case ActionTypes.RemoveFilter:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria: removeFilter(state, action.value)
        },
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null
      };

    case ActionTypes.LoadSearchById:
      var res = requestResponse(action);
      return requestIsBusy(action)
        ? state
        : {
            ...state,
            formData: {
              ...state.formData,
              searchCriteria: res.criteria
            },
            isUniverse: res.isUniverse
          };

    case ActionTypes.GetCustomerId:
      var res = requestResponse(action);
      return requestIsBusy(action)
        ? state
        : {
            ...state,
            customerId: res.customerId
          };

    case ActionTypes.ClearForm:
      return {
        ...state,
        formData: initialState.formData
      };

    case ActionTypes.UpdateSegmentSelection: {
      const { selected } = action.value;
      const { unfilteredSelectedCountries } = state;
      const isUniverse = selected === "universe";
      let selectedCountryCache = [];
      const searchCriteria = state.formData.searchCriteria.map(s => {
        if (s.type !== "countriesFilter") return s;

        if (s.selectedCountries.length) {
          selectedCountryCache = selectedCountryCache.concat(
            s.selectedCountries
          );
        }

        return {
          ...s,
          selectedCountries: []
        };
      });

      let currentCache =
        unfilteredSelectedCountries[isUniverse ? "segment" : "universe"];
      let nextCache =
        unfilteredSelectedCountries[isUniverse ? "universe" : "segment"];

      const cachedRemoved = difference(currentCache, selectedCountryCache);

      //removing countries
      currentCache = selectedCountryCache.filter(
        c => !cachedRemoved.includes(c)
      );
      nextCache = nextCache.filter(c => !cachedRemoved.includes(c));

      //adding countries
      nextCache = uniq(currentCache.concat(nextCache));
      unfilteredSelectedCountries[
        isUniverse ? "segment" : "universe"
      ] = currentCache;
      unfilteredSelectedCountries[
        isUniverse ? "universe" : "segment"
      ] = nextCache;

      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria
        },
        isUniverse: isUniverse,
        unfilteredSelectedCountries: unfilteredSelectedCountries,
        selection: initialState.selection,
        selectedItems: initialState.selectedItems
      };
    }

    case ActionTypes.UpdateSelectedCountries: {
      const { selected, viewingUniverse } = action.value;
      const { unfilteredSelectedCountries } = state;
      const searchCriteria = state.formData.searchCriteria.map(s => {
        if (s.type !== "countriesFilter") return s;

        return {
          ...s,
          selectedCountries: selected
        };
      });
      unfilteredSelectedCountries[
        viewingUniverse ? "universe" : "segment"
      ] = selected;

      return {
        ...state,
        formData: {
          ...state.formData,
          searchCriteria
        },
        unfilteredSelectedCountries
      };
    }

    case ActionTypes.GetUniverseCompaniesCount:
      return {
        ...state,
        companiesUniverseCount: requestResponse(action)
      };

    case ActionTypes.GetSegmentCompaniesCount:
      return {
        ...state,
        companiesSegmentCount: requestResponse(action)
      };

    case ActionTypes.SetExpandedRows:
      const { rows } = action;
      return {
        ...state,
        expandedRows: rows
      };

    case ActionTypes.UpdateViewedCompanies:
      return {
        ...state,
        viewedCompanyIds: [action.id, ...state.viewedCompanyIds]
      };

    case ActionTypes.RemoveFromViewedCompanies:
      return {
        ...state,
        viewedCompanyIds: state.viewedCompanyIds.filter(x => x !== action.id)
      };

    case ActionTypes.ResetViewedCompanies:
      return {
        ...state,
        viewedCompanyIds: initialState.viewedCompanyIds
      };

    default:
      return state;
  }
};
