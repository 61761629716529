import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";

import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { addRole, getAllPermissions } from "./actions";
import RoleForm from "./RoleForm";

const AddRole = ({ onSubmitted }) => (
  <ModalBuilder
    withForm
    submitAction={addRole}
    onSubmitted={onSubmitted}
    loadAction={getAllPermissions}
    renderTrigger={showModal => (
      <PermissionCheck target={Targets.Role} action={Actions.Create}>
        <Button primary content="Add Role" onClick={showModal} />
      </PermissionCheck>
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Add
        {...modalProps}
        header={
          <div>
            <Icon name="plus" /> New Role
          </div>
        }
        submitLabel="Create Role"
      >
        <RoleForm {...formProps} />
      </Modal.Add>
    )}
  />
);

export default AddRole;
