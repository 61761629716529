import { SideMenu, PageLoader } from "@redriver/cinnamon";
import { CompanyRoutes } from "constants/routes";
import React from "react";
import { withRouter } from "react-router-dom";
import { CompanyLookup } from "features/Companies/lookups";

const CompanyMenu = ({ companyId }, isSuppressed) => (
  <CompanyLookup
    companyId={companyId}
    render={({ loading, response }, refresh) => {
      if (!response) return <PageLoader />;
      return (
        <SideMenu pointing vertical>
          <SideMenu.Item link={CompanyRoutes.Overview(companyId)} exact>
            <span>Overview</span>
          </SideMenu.Item>
          <SideMenu.Item
            link={
              response.isSuppressed ? "#" : CompanyRoutes.Contacts(companyId)
            }
          >
            <span>Contacts</span>
          </SideMenu.Item>
          <SideMenu.Item
            link={
              response.isSuppressed
                ? "#"
                : CompanyRoutes.OrgStructure(companyId)
            }
          >
            <span>Org Structure</span>
          </SideMenu.Item>
          <SideMenu.Item
            link={response.isSuppressed ? "#" : CompanyRoutes.Lists(companyId)}
          >
            <span>Lists</span>
          </SideMenu.Item>
        </SideMenu>
      );
    }}
  />
);

export default withRouter(CompanyMenu);
