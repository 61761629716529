import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ErrorMessage, getAuthState } from "@redriver/cinnamon";
import { verifyEmail } from "./actions";
import { getEmailReVerifiedState } from "../selectors";
import { Redirect } from "react-router-dom";
import { AppRoutes, SystemRoutes } from "constants/routes";

class EmailReverified extends React.Component {
  static propTypes = {
    token: PropTypes.string.isRequired
  };

  componentDidMount() {
    const { verifyEmail, token } = this.props;
    verifyEmail(token);
  }

  render() {
    const {
      isVerifying,
      verifyEmailSuccess,
      verifyEmailError,
      verificationComplete,
      loggedIn
    } = this.props;
    if (verificationComplete && loggedIn) {
      return <Redirect to={AppRoutes.Root} />;
    } else if (verificationComplete && !loggedIn) {
      return <Redirect to={SystemRoutes.Login} />;
    }

    return (
      <React.Fragment>
        {isVerifying && (
          <React.Fragment>
            <p>We are verifying your email address</p>
          </React.Fragment>
        )}
        {verifyEmailError && (
          <React.Fragment>
            <p>There was a problem re-verifying your email address</p>
            <ErrorMessage error={verifyEmailError} />
          </React.Fragment>
        )}
        {verifyEmailSuccess && (
          <React.Fragment>
            <p>Your email has been verified successfully</p>
            <p>Please wait to be redirected</p>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const actions = {
  verifyEmail
};

const mapStateToProps = state => {
  const {
    isVerifying,
    verifyEmailSuccess,
    verifyEmailError,
    verificationComplete
  } = getEmailReVerifiedState(state);
  const { loggedIn } = getAuthState(state);

  return {
    isVerifying,
    verifyEmailSuccess,
    verifyEmailError,
    verificationComplete,
    loggedIn
  };
};

export default connect(
  mapStateToProps,
  actions
)(EmailReverified);
