import { apiGet, Form, registerLookup } from "@redriver/cinnamon";
import React from "react";

const NAMESPACE = "SECURE_FILE_AREA/LOOKUPS";

const ActionTypes = {
  CustomersLookup: `${NAMESPACE}/CUSTOMERS_LOOKUP`
};

export const CustomersTypeahead = props => (
  <Form.TypeAhead lookup="customersLookup" {...props} />
);

registerLookup("customersLookup", ({ search }) => {
  return apiGet(ActionTypes.CustomersLookup, "lookups/customers/names", {
    search
  });
});
