import React from "react";
import { Table, Format } from "@redriver/cinnamon";
import FileActions from "./FileActions";

const SecureFilesTable = props => {
  const { isInternalUser, tableProps, onRefresh } = props;
  return (
    <Table {...tableProps} dataKey="id" basic="very">
      {isInternalUser && <Table.Column field="customerName" title="Customer" />}
      <Table.Column field="fileName" title="Name" />
      <Table.Column field="fileSize" title="Size" />
      <Table.Column
        title="Created"
        render={item => <Format.DateTime value={item.createdDateUtc} />}
      />
      <Table.Column field="createdByName" title="Created By" />
      <Table.Column
        render={item => <FileActions file={item} onRefresh={onRefresh} />}
      />
    </Table>
  );
};

export default SecureFilesTable;
