import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { CustomersTypeahead } from "../../SecureFileArea/ListSecureFiles/lookups";
import { uploadFileForProcessing } from "./actions";

class UploadFile extends Component {
  render() {
    return (
      <ModalBuilder
        withForm
        submitAction={uploadFileForProcessing}
        onSubmitted={this.props.onSubmit}
        renderTrigger={showModal => (
          <Button primary onClick={showModal}>
            Upload segment removal
          </Button>
        )}
        renderModal={(
          modalProps,
          formProps,
          { error },
          { onSubmit, onReset }
        ) => {
          return (
            <Modal.Add
              {...modalProps}
              header={
                <div>
                  <Icon name="plus" /> Upload Segment Removal
                </div>
              }
              submitLabel="Upload"
              size="tiny"
            >
              <Form {...formProps}>
                <CustomersTypeahead
                  field="customerId"
                  placeholder="Select customer..."
                  required
                />
                <Form.FileUpload
                  fileExtensionTypes={["csv"]}
                  field="file"
                  fluid
                  required
                  className={`i4b-purple-uploader${
                    !!error ? ` upload-failed` : ""
                  }`}
                  uploadText="Drag your file here to upload, or click to browse files"
                  replaceText={
                    !!error
                      ? `Invalid file, please check the file is a CSV and try again`
                      : `File selected, please submit to begin the upload`
                  }
                />
              </Form>
            </Modal.Add>
          );
        }}
      />
    );
  }
}

export default withRouter(UploadFile);
