import { requestResponse } from "@redriver/cinnamon";
import { ActionTypes } from "./actions";
import moment from "moment";

const initialState = {
  earliestDate: moment().toISOString()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GetUserStatsEarliestDate: {
      const response = requestResponse(action);
      if (response) {
        return {
          ...state,
          earliestDate: response.earliestDate
        };
      }
    }

    default:
      return state;
  }
};
