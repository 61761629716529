import React from "react";

import { ListUsers } from "features/Users";
import { UserTypes } from "constants/permissions";

const ViewCustomerUsers = ({ customerId }) => (
  <ListUsers
    userType={UserTypes.External}
    showUserTypeMenu={false}
    customerId={customerId}
  />
);

export default ViewCustomerUsers;
