import {
  requestIsBusy,
  requestIsSuccess,
  requestError,
  requestResponse
} from "@redriver/cinnamon";
import { ActionTypes } from "./actions";

const initialState = {
  alertStatus: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RefreshNotifications:
      return {
        ...state,
        alertStatus: requestResponse(action)
      };
    default:
      return state;
  }
};
