import React from "react";

const renderTitles = titles => {
  return (
    <React.Fragment>
      <span>Job Titles: {titles.jobTitlesMatch}</span>{" "}
      {titles.jobTitles.map((el, i) =>
        el.title ? (
          <span key={i} className="title">
            {el.title}
          </span>
        ) : null
      )}
    </React.Fragment>
  );
};

const renderList = (title, list) => {
  return (
    <React.Fragment>
      <span>{title}:</span>{" "}
      {list.map((el, i) =>
        el ? (
          <span key={i} className="title">
            {el}
          </span>
        ) : null
      )}
    </React.Fragment>
  );
};

const JobsSummary = ({ titles, lob, tech }) => {
  const hasTitles =
    titles && titles.jobTitles && titles.jobTitles.some(j => j.title);
  const hasLob = lob.length !== 0;
  const hasTech = tech.length !== 0;
  return (
    <React.Fragment>
      {hasTitles && renderTitles(titles)}
      {hasLob && renderList("LOB Roles", lob)}
      {hasTech && renderList("IT Roles", tech)}
    </React.Fragment>
  );
};

export default JobsSummary;
