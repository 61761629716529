import React from "react";
import { Form, FormBuilder, ErrorMessage } from "@redriver/cinnamon";
import { Icon, Image, Button } from "semantic-ui-react";
import { RoleDropdown } from "features/Roles/lookups";
import Roles from "assets/icons/roles.svg";
import Company from "assets/icons/building.svg";
import Job from "assets/icons/job.svg";
import { updateUserContactDetails } from "./actions";

const UserContactsForm = ({ userId, onClick, onSubmitted, data }) => {
  return (
    <section>
      <FormBuilder
        submitAction={updateUserContactDetails}
        submitParams={userId}
        onSubmitted={onSubmitted}
        renderError={false}
        initialData={data}
        renderForm={(formProps, state, events) => (
          <Form {...formProps} className="user-contacts-form">
            <div className="flex">
              <h2 className="m-r-1">Contact Details</h2>
              <Button
                className="grey-small-button"
                content="Cancel"
                onClick={onClick}
              />
              <Button
                className="primary green-small-button"
                content="Save"
                onClick={events.onSubmit}
                loading={state.submitting}
              />
            </div>
            <ul className="list-unstyled m-t-05">
              <li>
                <Icon name="user" className="user-contacts" />
                <div className="flex flex-wrap" style={{ width: "100%" }}>
                  <Form.Group widths="equal">
                    <Form.Input
                      field="firstName"
                      placeholder="Firstname"
                      fluid
                      required
                    />
                    <Form.Input
                      field="lastName"
                      placeholder="Lastname"
                      required
                      fluid
                    />
                  </Form.Group>
                </div>
              </li>
              <li>
                <Image src={Company} />
                {/* <Form.Input
                  field="company"
                  placeholder="Company"
                  fluid
                  disabled
                /> */}
                {data.company || "-"}
              </li>
              <li>
                <Image src={Job} />
                <Form.Input field="jobTitle" placeholder="Job" fluid />
              </li>
              <li>
                <Icon
                  name="call"
                  flipped="horizontally"
                  className="user-contacts"
                />
                <Form.Numeric field="telephone" placeholder="Telephone" fluid />
              </li>
              <li>
                <Icon name="envelope" className="user-contacts" />
                {/* <Form.Input field="email" placeholder="Email" fluid /> */}
                {data.email || "-"}
              </li>
              <li>
                <Image src={Roles} />
                <RoleDropdown
                  field="roles"
                  multiple
                  fluid
                  required
                  unknownValueOptions={({ fields }) => fields.roles}
                />
              </li>
            </ul>
            <ErrorMessage error={state.error} />
          </Form>
        )}
      />
    </section>
  );
};

export default UserContactsForm;
