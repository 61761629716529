import React from "react";
import { Grid, Image, Segment } from "semantic-ui-react";
import { ListBuilder, Table } from "@redriver/cinnamon";

import { SearchResultCounter } from "components/lists";
import { PageNumber } from "components/pagination";
import { Targets, Actions } from "constants/permissions";
import { PermissionCheck } from "components/auth";

import Green from "assets/green_square.svg";
import { getTags } from "./actions";
import ActionsColumn from "./ActionsColumn";
import FilterTags from "./FilterTags";

const ViewCustomerTags = customerId => (
  <ListBuilder
    withTable
    totalTransform={data => data.totalResults}
    dataTransform={data => data.results}
    loadAction={getTags}
    loadParams={customerId}
    renderList={(tableProps, { filters, pagination, total }, events) => {
      return (
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={14}
          className="m-b-1"
          stretched
        >
          <div className="i4b-list">
            <h2>Contact Custom Fields</h2>
            <FilterTags
              customerId={customerId}
              filters={filters}
              onChangeFilters={events.onChangeFilters}
              pageSize={pagination.pageSize}
              onPageSizeChange={events.onChangePagination}
              onCreateCustomFieldValue={events.onRefresh}
            />
            <Segment>
              <Table {...tableProps} dataKey="id" basic="very">
                <Table.Column
                  render={item => (
                    <Image
                      src={Green}
                      title="Custom Field"
                      style={{ height: "1em", width: "1em" }}
                    />
                  )}
                  width="2.5%"
                />
                <Table.Column title="Name" field="value" />
                <Table.Column title="Usage Count" field="usageCount" />
                <Table.Column title="Created" field="createdCalc" />
                <Table.Column
                  name="Actions"
                  render={item => (
                    <PermissionCheck
                      target={Targets.ItemTags}
                      action={Actions.Manage}
                    >
                      <ActionsColumn item={item} onChange={events.onRefresh} />
                    </PermissionCheck>
                  )}
                  width="15%"
                  align="right"
                />
              </Table>
              <div className="i4b-list-footer">
                <SearchResultCounter
                  pageSize={pagination.pageSize}
                  pageNumber={pagination.pageNumber}
                  totalResults={total}
                />
                <PageNumber
                  totalItems={total}
                  onChange={x =>
                    events.onChangePagination({
                      pageSize: pagination.pageSize,
                      pageNumber: x
                    })
                  }
                  pageSize={pagination.pageSize}
                  value={pagination.pageNumber}
                />
              </div>
            </Segment>
          </div>
        </Grid.Column>
      );
    }}
  />
);

export default ViewCustomerTags;
