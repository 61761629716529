import {
  requestIsBusy,
  requestIsSlow,
  requestIsSuccess,
  requestError
} from "@redriver/cinnamon";
import { VALIDATE_TOKEN, REQUEST_SETUP } from "./actions";

const initialSetup = {
  validatingToken: false,
  validationSlow: null,
  tokenCorrect: null,
  settingUp: false,
  slowRequest: null,
  success: null,
  error: null
};

export default (state = initialSetup, action) => {
  switch (action.type) {
    case VALIDATE_TOKEN:
      return {
        ...state,
        validatingToken: requestIsBusy(action),
        validationSlow: requestIsSlow(action),
        tokenCorrect: requestIsSuccess(action)
      };

    case REQUEST_SETUP:
      return {
        ...state,
        settingUp: requestIsBusy(action),
        slowRequest: requestIsSlow(action),
        success: requestIsSuccess(action),
        error: requestError(action)
      };

    default:
      return state;
  }
};
