import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import AddTag from "assets/icons/add_tag.svg";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import { Icon, Image } from "semantic-ui-react";
import { ContactTagsDropdown } from "../lookups";

const AddTagModal = ({
  selection,
  searchCriteria,
  submitAction,
  isActionList = false,
  onSubmitted,
  allowAdditions,
  isUniverse
}) => {
  return (
    <ModalBuilder
      withForm
      submitAction={submitAction}
      submitParams={{ selection, searchCriteria, isUniverse }}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <PermissionCheck target={Targets.ItemTags} action={Actions.Edit}>
          {isActionList ? (
            <div className="dropdown-action-container" onClick={showModal}>
              Add contact custom field
            </div>
          ) : (
            <Image
              src={AddTag}
              className="row-action-icon"
              onClick={showModal}
            />
          )}
        </PermissionCheck>
      )}
      renderModal={(modalProps, formProps, state) => (
        <Modal.Add
          {...modalProps}
          header={
            <div>
              <Icon name="plus" /> Add Contact Custom Field
            </div>
          }
          submitLabel="Add Custom Field"
          submitDisabled={!formProps.value.tag}
          size="tiny"
        >
          <Form {...formProps}>
            {!state.submitting && (
              <ContactTagsDropdown
                value={formProps.value.tag}
                allowAdditions={allowAdditions}
              />
            )}
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default AddTagModal;
