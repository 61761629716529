import React, { Component } from "react";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";

import SlidePanel from "./SlidePanel";

class FilterActionsMenu extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string
  };
  static defaultProps = {
    title: "Filters"
  };

  render() {
    const { show, onClose, children, title } = this.props;

    return (
      <SlidePanel show={show} onClose={onClose} className="i4b-filter-sidebar">
        <h1>{title}</h1>
        <div>{children}</div>
      </SlidePanel>
    );
  }
}

export default FilterActionsMenu;
