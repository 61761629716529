import { Form } from "@redriver/cinnamon";
import React from "react";
import { Grid } from "semantic-ui-react";

const RoleForm = props => {
  return (
    <Form {...props}>
      <Grid>
        <Grid.Column width={8}>
          <Form.Input fluid field="name" label="Name" required />
          <Form.Input fluid field="description" label="Description" />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.CheckboxTree
            field="permissionRefs"
            label="Permissions"
            nodes={props.value.permissions || []}
            required
          />
        </Grid.Column>
      </Grid>
    </Form>
  );
};

export default RoleForm;
