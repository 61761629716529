import { deauthenticate, getAuthClaims } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { deleteOtherSessions } from "./actions";

class ActiveSessions extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  removeOtherSessions = async () => {
    const { userId } = this.props;
    const success = await this.props.deleteOtherSessions(userId);
    if (success) {
      this.props.history.push(SystemRoutes.Terms, this.props.location.state);
    }
  };

  logout = () => {
    this.props.deauthenticate();
    this.props.history.push(SystemRoutes.Login);
  };

  render() {
    return (
      <div>
        <p>
          Your account is already logged in elsewhere. Do you want to log out of
          all the other sessions so you can log in here?
        </p>
        <Button primary onClick={this.removeOtherSessions}>
          Yes - Log me in here
        </Button>
        <Button negative onClick={this.logout} className="m-t-1 grey">
          No - Cancel log in
        </Button>
      </div>
    );
  }
}

const actions = {
  deleteOtherSessions,
  deauthenticate
};

const mapStateToProps = state => {
  const { userId } = getAuthClaims(state);

  return {
    userId
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(ActiveSessions)
);
