import React from "react";
import { connect } from "react-redux";
import { ListBuilder, getAuthClaims } from "@redriver/cinnamon";
import { Grid, Segment } from "semantic-ui-react";
import { PermissionCheck } from "components/auth";
import { UserTypes, Actions, Targets } from "constants/permissions";
import { PageNumber } from "components/pagination";
import { SearchResultCounter } from "components/lists";
import { getSecureFiles } from "./actions";
import SecureFilesTable from "./SecureFilesTable";
import FilterSecureFiles from "./FilterSecureFiles";
import UploadFile from "./UploadFile";
import { getUserStatsState } from "./selectors";

class ListSecureFiles extends React.Component {
  state = {
    viewLoggingRequired: true
  };
  componentDidMount() {}

  render() {
    const { isInternalUser } = this.props;
    return (
      <ListBuilder
        withTable
        debounceFilters={250}
        initialFilters={{ search: "" }}
        totalTransform={data => data.totalResults}
        dataTransform={data => data.results}
        loadAction={getSecureFiles}
        onLoaded={() => this.setState({ viewLoggingRequired: false })}
        loadParams={{ loggingRequired: this.state.viewLoggingRequired }}
        renderList={(tableProps, state, events) => {
          const { filters, pagination, total } = state;
          return (
            <Grid
              className="i4b-list-page user-page secure-files-list"
              style={{ paddingTop: "0.8rem" }}
            >
              <PermissionCheck
                target={Targets.SecureFile}
                action={Actions.Create}
              >
                <Grid.Row className="p-b-0" columns={1}>
                  <Grid.Column stretched>
                    <UploadFile
                      onFileUploaded={events.onRefresh}
                      customerId={filters.customerId}
                    />
                  </Grid.Column>
                </Grid.Row>
              </PermissionCheck>
              <Grid.Row className="p-b-0" columns={1}>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  className="p-b-0"
                  stretched
                >
                  <div className="i4b-list">
                    <h2>Files</h2>
                    <FilterSecureFiles
                      filters={filters}
                      onChangeFilters={events.onChangeFilters}
                      pageSize={pagination.pageSize}
                      onPageSizeChange={events.onChangePagination}
                      isInternalUser={isInternalUser}
                    />
                    <Segment>
                      <SecureFilesTable
                        tableProps={tableProps}
                        onRefresh={events.onRefresh}
                        isInternalUser={isInternalUser}
                      />
                      <div className="i4b-list-footer">
                        <SearchResultCounter
                          pageSize={pagination.pageSize}
                          pageNumber={pagination.pageNumber}
                          totalResults={total}
                        />
                        <PageNumber
                          totalItems={total}
                          onChange={x =>
                            events.onChangePagination({
                              pageSize: pagination.pageSize,
                              pageNumber: x
                            })
                          }
                          pageSize={pagination.pageSize}
                          value={pagination.pageNumber}
                        />
                      </div>
                    </Segment>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        }}
      />
    );
  }
}

const actions = {};

const mapStateToProps = state => {
  const { permissions, userType } = getAuthClaims(state);
  return {
    isInternalUser: userType && userType == UserTypes.Internal
  };
};

export default connect(
  mapStateToProps,
  actions
)(ListSecureFiles);
