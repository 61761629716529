import React from "react";
import CsvContext from "./CsvContext";

const HeaderCell = props => {
  return (
    <CsvContext.Consumer>
      {context => (
        <div
          className="data-grid-header"
          onClick={() => context.onCellSelected(props.column)}
          className={
            (props.column.ok == undefined || props.column.ok
              ? ""
              : "header-error ") +
            (props.column.ignored != undefined && props.column.ignored
              ? "header-ignored"
              : "")
          }
        >
          {props.column.name}
        </div>
      )}
    </CsvContext.Consumer>
  );
};

export default HeaderCell;
