import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import QuestionMark from "assets/icons/question_mark_circle.svg";
import { UserTypeCheck } from "components/auth";
import { UserTypes } from "constants/permissions";
import React from "react";
import { Button, Image, Popup } from "semantic-ui-react";
import { submitResearchRequest } from "./actions";

const RequestResearchModal = ({
  companyId,
  companyOriginalId,
  disabled,
  onSubmitted,
  viewingSearch = true
}) => {
  return (
    <ModalBuilder
      withForm
      submitAction={submitResearchRequest}
      submitParams={{ originalId: companyOriginalId, companyId }}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <UserTypeCheck restrictTo={UserTypes.External}>
          {viewingSearch ? (
            <Popup
              trigger={
                <Image
                  src={QuestionMark}
                  className="row-action-icon"
                  onClick={!disabled ? showModal : undefined}
                  disabled={disabled}
                  style={disabled ? { cursor: "help" } : undefined}
                />
              }
              content={
                !disabled
                  ? "Custom Research Request"
                  : "Research Request pending"
              }
              position="bottom right"
              className="table-popup"
              mouseEnterDelay={75}
            />
          ) : (
            <Button
              className="primary secondary-small-button"
              onClick={showModal}
              disabled={disabled}
              fluid
            >
              New Research Request
            </Button>
          )}
        </UserTypeCheck>
      )}
      renderModal={(modalProps, formProps) => (
        <Modal.Edit
          {...modalProps}
          header="Request Research"
          submitLabel="Send"
          submitDisabled={
            !formProps.value.requestText ||
            !formProps.value.requestText.trim().length
          }
          size="tiny"
        >
          <p>
            Please state within the comment box below, the full description of
            what you would like us to research for this company{" "}
            <em>e.g. Discover CIO at 1Spatial Group Ltd</em>
          </p>
          <Form {...formProps}>
            <Form.TextArea field="requestText" required fluid />
          </Form>
          <p style={{ marginTop: "1em", marginBottom: "0" }}>
            Please select <strong>"Send"</strong> to deliver the above request
            to our research team. You will receive a notification once the
            research has been completed. We will endeavour to action this
            promptly. If your request cannot be actioned, support will be in
            touch.
          </p>
        </Modal.Edit>
      )}
    />
  );
};

export default RequestResearchModal;
