import { apiDelete, apiGet } from "@redriver/cinnamon";
import { debounce } from "lodash";

const NAMESPACE = "SUPPRESSIONS";

export const ActionTypes = {
  GetCompanySuppressions: `${NAMESPACE}/GET_COMPANY_SUPPRESSIONS`,
  GetContactSuppressions: `${NAMESPACE}/GET_COMPANY_SUPPRESSIONS`,
  DeleteCompanySuppression: `${NAMESPACE}/DELETE_COMPANY_SUPPRESSION`,
  DeleteContactSuppression: `${NAMESPACE}/DELETE_CONTACT_SUPPRESSION`
};

export const getSuppressions = (listState, loadParams) => {
  const id = loadParams.customerId;
  const { pagination, filters } = listState;
  const { isContact } = filters;

  const params = {
    pageSize: pagination.pageSize,
    pageNumber: pagination.pageNumber,
    searchText: filters.search
  };

  return isContact
    ? apiGet(
        ActionTypes.GetContactSuppressions,
        `customers/${id}/contact-suppressions`,
        params
      )
    : apiGet(
        ActionTypes.GetCompanySuppressions,
        `customers/${id}/company-suppressions`,
        params
      );
};

export const deleteCompanySuppression = ({ customerId, suppression }) =>
  apiDelete(
    ActionTypes.DeleteCompanySuppression,
    `customers/${customerId}/company-suppressions`,
    { companyIds: [suppression.id] }
  );

export const deleteContactSuppression = ({ customerId, suppression }) =>
  apiDelete(
    ActionTypes.DeleteContactSuppression,
    `customers/${customerId}/contact-suppressions`,
    { contactIds: [suppression.id] }
  );
