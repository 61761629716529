import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";

const JobsCriteria = ({ active, handleClick, criteria }) => {
  if (!criteria || !criteria.jobsSearch) return null;
  const { jobTitles, jobTitlesMatch } = criteria.jobsSearch.jobTitlesFilter;
  const { selectedLobRoles, selectedTechRoles } = criteria.jobsSearch;

  let text = "";
  let hasValidFilter = false;
  if (selectedLobRoles && selectedLobRoles.length !== 0) {
    text += `LOB Roles ${selectedLobRoles.map(el => el).join(", ")} `;
    hasValidFilter = true;
  }
  if (selectedTechRoles && selectedTechRoles.length !== 0) {
    text += `IT Roles ${selectedTechRoles.map(el => el).join(", ")} `;
    hasValidFilter = true;
  }
  if (jobTitles && jobTitles.some(j => j.title)) {
    text += `Job Titles ${jobTitlesMatch} ${jobTitles
      .map(t => t.title)
      .join(", ")}`;
    hasValidFilter = true;
  }

  if (!hasValidFilter) return null;

  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Job Personas"
        text={text}
      />
      <Accordion.Content active={active}>
        <ul className="p-t-0 p-b-05 p-r-0 p-l-1 m-y-0">
          {jobTitles &&
            jobTitles.some(j => j.title) &&
            jobTitles.map(title => <li key={title.id}>{title.title}</li>)}
          {selectedLobRoles &&
            selectedLobRoles.map(item => <li key={item}>{item}</li>)}
          {selectedTechRoles &&
            selectedTechRoles.map(item => <li key={item}>{item}</li>)}
        </ul>
      </Accordion.Content>
    </React.Fragment>
  );
};

export default JobsCriteria;
