import React, { Component } from "react";
import PropTypes from "prop-types";

class FilterClearer extends Component {
  static propTypes = {
    show: PropTypes.bool,
    clearSelected: PropTypes.func
  };

  render() {
    const { clearSelected, show } = this.props;

    return (
      show && (
        <div className="i4b-filter-clear" onClick={e => clearSelected(e)}>
          <a>(Clear)</a>
        </div>
      )
    );
  }
}

export default FilterClearer;
