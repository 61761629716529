export const sizeFilter = {
  type: "sizeFilter",
  companySizeDetails: {
    match: "all",
    revenueFrom: null,
    revenueTo: null,
    revenueCurrency: "GBP",
    numberEmployeesFrom: null,
    numberEmployeesTo: null,
    numberITStaffFrom: null,
    numberITStaffTo: null,
    numberLocationsFrom: null,
    numberLocationsTo: null,
    numberPCsFrom: null,
    numberPCsTo: null,
    numberServersFrom: null,
    numberServersTo: null
  }
};

export const postalAreasFilter = {
  type: "postalAreasFilter",
  selectedAreas: [],
  postalFilter: ""
};

export const industriesFilter = {
  type: "industriesFilter",
  industriesSelection: {
    included: [],
    excluded: [],
    match: "one"
  }
};

export const stockExchangeIndexesFilter = {
  type: "stockExchangeIndexesFilter",
  selectedIndexes: []
};

export const languagesFilter = {
  type: "languagesFilter",
  selectedLanguages: []
};

export const jobTitlesFilter = {
  type: "jobTitlesFilter",
  jobTitlesFilter: {
    jobTitlesMatch: "contains",
    jobTitles: [
      {
        id: "0"
      }
    ]
  }
};

export const jobsFilter = {
  type: "jobsFilter",
  jobsSearch: {
    selectedLobRoles: [],
    selectedTechRoles: [],
    jobTitlesFilter: {
      jobTitlesMatch: "contains",
      jobTitles: [
        {
          id: "0"
        }
      ]
    }
  }
};

export const techRolesFilter = {
  type: "techRolesFilter",
  selectedTechRoles: []
};

export const LOBRolesFilter = {
  type: "LOBRolesFilter",
  selectedLobRoles: []
};

export const contactTagsFilter = {
  type: "contactTagsFilter",
  tagsSelection: {
    match: "all",
    tags: {
      included: [],
      excluded: []
    }
  }
};

export const customFieldsFilter = {
  type: "customFieldsFilter",
  fieldsSelection: {
    match: "all",
    customFields: {
      included: [],
      excluded: []
    }
  }
};

export const listsFilter = {
  type: "listsFilter",
  listsSearch: {
    isContact: null,
    listsSelection: {
      included: [],
      excluded: []
    }
  }
};

export const contactUploadsFilter = {
  type: "contactUploadsFilter",
  uploadsSearch: {
    isContact: true,
    uploadsSelection: {
      included: [],
      excluded: []
    }
  }
};

export const companyUploadsFilter = {
  type: "companyUploadsFilter",
  uploadsSearch: {
    isContact: false,
    uploadsSelection: {
      included: [],
      excluded: []
    }
  }
};

export const companyFollowedFilter = {
  type: "companyFollowedFilter",
  companyFollowedSearch: {
    isFollowingCompany: null
  }
};

export const contactFollowedFilter = {
  type: "contactFollowedFilter",
  contactFollowedSearch: {
    isFollowingContact: null
  }
};

export const contactsInCompanyListsFilter = {
  type: "contactsInCompanyListsFilter",
  contactsInCompanyListsSearch: {
    listsSelection: {
      included: [],
      excluded: []
    }
  }
};

export const emailDdiFilter = {
  type: "emailDdiFilter",
  emailDdiSearch: {
    emailOnly: false,
    ddiOnly: false
  }
};
