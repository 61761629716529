import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { ActionBar, Form, FormBuilder, PageSize } from "@redriver/cinnamon";
import { CustomerRoutes } from "constants/routes";
import { withRouter } from "react-router-dom";
import { FilterActionsMenu } from "components/menus";
import FilterCustomersWindow from "./FilterCustomersWindow";

class FilterCustomers extends Component {
  state = {
    slidePanelActive: false
  };

  toggleFilterSlidePanel = () => {
    this.setState({
      slidePanelActive: !this.state.slidePanelActive
    });
  };

  render() {
    const {
      filters,
      onChangeFilters,
      pageSize,
      onPageSizeChange,
      selection,
      totalSelected,
      history
    } = this.props;
    const { slidePanelActive } = this.state;

    return (
      <ActionBar>
        <ActionBar.Item fluid>
          <FormBuilder
            initialData={filters}
            onChange={onChangeFilters}
            renderForm={formProps => (
              <Form {...formProps}>
                <Form.Group inline compact>
                  <Form.Input
                    field="search"
                    placeholder="Search customers…"
                    width={25}
                  />
                </Form.Group>
                <FilterActionsMenu
                  title="Actions"
                  show={slidePanelActive}
                  onClose={this.toggleFilterSlidePanel}
                >
                  <FilterCustomersWindow
                    formData={formProps.value}
                    selection={selection}
                    totalSelected={totalSelected}
                    filters={filters}
                    onCreatedCustomer={customerId =>
                      history.push(CustomerRoutes.Customer(customerId))
                    }
                  />
                </FilterActionsMenu>
              </Form>
            )}
          />
        </ActionBar.Item>
        <ActionBar.Item>
          <Button primary onClick={this.toggleFilterSlidePanel}>
            Actions
          </Button>
        </ActionBar.Item>
        {pageSize && (
          <ActionBar.Item className="i4b-filter-pagesize">
            <PageSize
              value={pageSize}
              onChange={x => onPageSizeChange({ pageSize: x, pageNumber: 1 })}
              options={[10, 25, 50, 100]}
            />
          </ActionBar.Item>
        )}
      </ActionBar>
    );
  }
}

export default withRouter(FilterCustomers);
