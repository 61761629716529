import { Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Icon } from "semantic-ui-react";
import { switchUser } from "./actions";

const SwitchUser = ({ id, onSubmitted, renderTrigger }) => (
  <ModalBuilder
    submitAction={switchUser}
    submitParams={id}
    renderTrigger={renderTrigger}
    renderModal={modalProps => (
      <Modal.Confirmation
        {...modalProps}
        header={
          <div>
            <Icon name="warning sign" /> View as
          </div>
        }
        size="tiny"
      >
        <p>Are you sure you want to view the system as this user?</p>
      </Modal.Confirmation>
    )}
  />
);

export default SwitchUser;
