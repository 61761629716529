import { Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Icon } from "semantic-ui-react";
import { deleteCustomFieldValue } from "./actions";

const DeleteCustomFieldValue = ({
  id,
  isInUse,
  renderTrigger,
  onSubmitted
}) => (
  <ModalBuilder
    submitAction={deleteCustomFieldValue}
    submitParams={id}
    onSubmitted={onSubmitted}
    renderTrigger={renderTrigger}
    renderModal={(modalProps, data) => {
      return (
        <Modal.Delete
          {...modalProps}
          header={
            <div>
              <Icon name="warning sign" /> Delete Custom Value
            </div>
          }
        >
          <p>Are you sure you want to delete this custom value?</p>
          {isInUse && (
            <div inline="true">
              <strong className="color-red">
                Warning, this custom value has associated companies.
              </strong>
              <p className="color-red">
                Deleting this custom value will remove it from all its companies
                and will no longer appear.
              </p>
            </div>
          )}
        </Modal.Delete>
      );
    }}
  />
);

export default DeleteCustomFieldValue;
