import { ContentBuilder, Format, TriggerBuilder } from "@redriver/cinnamon";
import * as fileSaver from "file-saver";
import * as React from "react";
import { Button, Grid, Segment } from "semantic-ui-react";
import { downloadOptOut, loadToken } from "./actions";

const OptOutDownload = ({ tokenId }) => {
  return (
    <ContentBuilder
      loadAction={loadToken}
      loadParams={tokenId}
      renderContent={(data, state, events) => (
        <Grid style={{ margin: "2rem" }}>
          <Grid.Row columns={3} className="p-t-0">
            <Grid.Column
              mobile={14}
              tablet={14}
              computer={6}
              className="m-b-1"
              stretched
            >
              <Segment className="left-border-green">
                <section>
                  <div className="flex">
                    <h2 className="m-r-1">Opt Out Download</h2>
                  </div>
                  <ul className="list-unstyled m-t-05 overview-text">
                    <li>
                      Expires: <Format.DateTime value={data.expiresUtc} />
                    </li>
                    {data.downloadedUtc && (
                      <li>
                        Last Downloaded:{" "}
                        <Format.DateTime value={data.downloadedUtc} />
                      </li>
                    )}
                    <li>
                      Opted out between:{" "}
                      <Format.DateTime value={data.fromUtc} /> to{" "}
                      <Format.DateTime value={data.toUtc} />
                    </li>
                  </ul>

                  <TriggerBuilder
                    submitAction={downloadOptOut}
                    renderTrigger={(fireTrigger, state, events) => (
                      <Button
                        content="Download"
                        primary
                        className="primary"
                        onClick={fireTrigger}
                        loading={state.submitting}
                        disabled={data.expired}
                      />
                    )}
                    onSubmitted={file => {
                      fileSaver.saveAs(file, "OptOut.csv");
                    }}
                    submitParams={tokenId}
                  />
                </section>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    />
  );
};

export default OptOutDownload;
