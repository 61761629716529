import { getAuthClaims } from "@redriver/cinnamon";
import { PermissionCheck, UserTypeCheck } from "components/auth";
import {
  AddSuppressionsModal,
  AddToListModal,
  AddToSegmentsModal,
  RemoveFromSegmentsModal,
  SaveListModal
} from "components/search";
import { Actions, Targets, UserTypes } from "constants/permissions";
import ClearFieldsOrTagsModal from "features/Lists/ListsOverview/ActionsDropdown/ClearFieldsOrTagsModal";
import React from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import {
  addCompanyTag,
  addToList,
  clearCustomFields,
  getCompanies,
  getSegmentCompaniesCount,
  getSegmentAdditions,
  getSegmentRemovals,
  addCompaniesToSegments,
  removeCompaniesFromSegments,
  saveList
} from "./actions";
import AddCustomFieldModal from "./AddCustomFieldModal";

class CompaniesActionsDropdown extends React.Component {
  state = {
    dropdownOpen: false
  };

  componentWillReceiveProps = nextProps => {
    const { dropdownOpen } = this.state;
    const { selectedItems } = nextProps;
    if (!selectedItems && dropdownOpen) {
      this.toggleDropdownOpen();
    }
  };

  toggleDropdownOpen = () =>
    this.setState({ dropdownOpen: !this.state.dropdownOpen });

  render() {
    const {
      selectedItems,
      selection,
      formData,
      customerId,
      addSuppressions,
      getCompanies,
      isSegmented,
      viewingUniverse,
      allowTagAdditions,
      getSegmentAdditions,
      getSegmentRemovals,
      addCompaniesToSegments,
      removeCompaniesFromSegments
    } = this.props;
    const { dropdownOpen } = this.state;
    return (
      <div>
        <span
          className={
            !selectedItems || selectedItems < 1 ? `none-selected` : undefined
          }
          onClick={
            !!selectedItems && selectedItems > 0
              ? this.toggleDropdownOpen
              : null
          }
        >
          Actions
        </span>
        <Button
          className={`actions-dropdown-button ${
            !selectedItems || selectedItems < 1
              ? `none-selected`
              : selectedItems && !dropdownOpen
              ? `some-selected`
              : `close`
          }`}
          onClick={
            !!selectedItems && selectedItems > 0
              ? this.toggleDropdownOpen
              : null
          }
        />
        <section
          style={
            dropdownOpen
              ? {
                  visibility: "visible",
                  opacity: "1"
                }
              : {
                  visibility: "hidden",
                  opacity: "0"
                }
          }
          className="actions-dropdown-section"
        >
          <header>
            <h3>Actions</h3>
            <p>
              {selectedItems} selected{" "}
              {selectedItems > 1 ? "results" : "result"}
            </p>
          </header>
          <ul className="list-unstyled">
            {!viewingUniverse && (
              <React.Fragment>
                <SaveListModal
                  isCompaniesPage
                  submitAction={saveList}
                  selection={selection}
                />
                <AddToListModal
                  isCompaniesPage
                  submitAction={addToList}
                  submitParams={{
                    selection
                  }}
                />
              </React.Fragment>
            )}

            <UserTypeCheck restrictTo={UserTypes.External}>
              <li>
                <AddCustomFieldModal
                  selection={selection}
                  isActionList
                  onSubmitted={() => getCompanies(false, false, false, true)}
                  submitAction={addCompanyTag}
                  viewingUniverse={viewingUniverse}
                  allowAdditions={allowTagAdditions}
                />
              </li>
            </UserTypeCheck>

            <ClearFieldsOrTagsModal
              submitAction={clearCustomFields}
              onSubmitted={() => getCompanies(false, false, false, true)}
              submitParams={{
                selection,
                selectedItems,
                formData,
                customerId,
                viewingUniverse
              }}
              numSelected={selectedItems}
            />

            <AddSuppressionsModal
              submitAction={addSuppressions}
              submitParams={{
                isContact: false,
                selection,
                selectedItems,
                formData,
                customerId,
                viewingUniverse
              }}
              onSubmitted={() => getCompanies(false, false, false, true)}
            />

            {isSegmented && (
              <PermissionCheck
                target={Targets.CustomerSegments}
                action={Actions.Edit}
              >
                {viewingUniverse && (
                  <li>
                    <AddToSegmentsModal
                      loadAction={getSegmentAdditions}
                      submitAction={addCompaniesToSegments}
                      loadParams={{
                        selection,
                        selectedItems,
                        searchCriteria: formData.searchCriteria,
                        isUniverse: viewingUniverse
                      }}
                      submitParams={{
                        selection,
                        selectedItems,
                        searchCriteria: formData.searchCriteria,
                        isUniverse: viewingUniverse
                      }}
                      onSubmitted={() => getCompanies(false, false, true, true)}
                    />
                  </li>
                )}
                <PermissionCheck
                  target={Targets.Override}
                  action={Actions.View}
                >
                  {!viewingUniverse && (
                    <li>
                      <AddToSegmentsModal
                        loadAction={getSegmentAdditions}
                        submitAction={addCompaniesToSegments}
                        loadParams={{
                          selection,
                          selectedItems,
                          searchCriteria: formData.searchCriteria,
                          isUniverse: viewingUniverse
                        }}
                        submitParams={{
                          selection,
                          selectedItems,
                          searchCriteria: formData.searchCriteria,
                          isUniverse: viewingUniverse
                        }}
                        onSubmitted={() =>
                          getCompanies(false, false, true, true)
                        }
                      />
                    </li>
                  )}
                </PermissionCheck>
                {!viewingUniverse && (
                  <li>
                    <RemoveFromSegmentsModal
                      submitAction={removeCompaniesFromSegments}
                      loadAction={getSegmentRemovals}
                      loadParams={{
                        selection,
                        selectedItems,
                        searchCriteria: formData.searchCriteria,
                        isUniverse: viewingUniverse
                      }}
                      submitParams={{
                        selection,
                        selectedItems,
                        searchCriteria: formData.searchCriteria,
                        isUniverse: viewingUniverse
                      }}
                      onSubmitted={() => getCompanies(false, false, true, true)}
                    />
                  </li>
                )}
              </PermissionCheck>
            )}
          </ul>
        </section>
      </div>
    );
  }
}

const actions = {
  getCompanies,
  getSegmentCompaniesCount,
  getSegmentAdditions,
  getSegmentRemovals,
  addCompaniesToSegments,
  removeCompaniesFromSegments
};

const mapStateToProps = state => {
  const { permissions } = getAuthClaims(state);

  return {
    allowTagAdditions:
      permissions &&
      permissions.hasOwnProperty(Targets.ItemTags) &&
      (Array.isArray(permissions[Targets.ItemTags])
        ? permissions[Targets.ItemTags].some(p => p == Actions.Create)
        : permissions[Targets.ItemTags] == Actions.Create)
  };
};

export default connect(
  mapStateToProps,
  actions
)(CompaniesActionsDropdown);
