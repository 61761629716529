import { uniq } from "lodash";

const mergeSearchCriteria = (oldState, newState) => {
  let searchCriteria = [];

  let ids = uniq([
    ...oldState.map(x => x.id.toString()),
    ...newState.map(x => x.id.toString())
  ]);

  ids.forEach(x => {
    const oldCrit = oldState.find(y => y.id == x);
    const newCrit = newState.find(y => y.id == x);

    if (!!oldCrit && !!newCrit) {
      // overwrite existing old crit with new info
      let updatedCriteria = {
        id: oldCrit.id,
        type: oldCrit.type,
        ...newCrit
      };

      searchCriteria.push(updatedCriteria);
    } else {
      searchCriteria.push(!!oldCrit ? oldCrit : newCrit); // add new/old crit wholesale
    }
  });

  if (!searchCriteria.length) return initialState.formData.searchCriteria;

  return searchCriteria;
};

export default mergeSearchCriteria;
