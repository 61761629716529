import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { getAuthClaims, getAuthRefreshToken } from "@redriver/cinnamon";
import moment from "moment";

/**
 * React Router compatible <Switch/> that also matches on <PrivateRoute/> and <PublicRoute/> components
 */
const VerifiedSwitch = ({
  emailExpiryUtc,
  impersonatorId,
  refreshToken,
  children,
  ...switchProps
}) => {
  const filteredChildren = React.Children.map(children, element => {
    if (element.type && element.type.displayName) {
      const userIsTrusted = impersonatorId || moment(emailExpiryUtc) > moment();
      if (!userIsTrusted) {
        const { verifyRedirect, component, ...routeProps } = element.props;
        if (verifyRedirect) {
          return (
            <Route
              {...routeProps}
              render={props => (
                <Redirect
                  to={{
                    pathname: verifyRedirect,
                    state: { nextPath: props.location }
                  }}
                />
              )}
            />
          );
        }
        return;
      } else if (
        !refreshToken ||
        refreshToken == null ||
        refreshToken == "null"
      ) {
        const { sessionsRedirect, component, ...routeProps } = element.props;
        if (sessionsRedirect) {
          return (
            <Route
              {...routeProps}
              render={props => (
                <Redirect
                  to={{
                    pathname: sessionsRedirect,
                    state: { nextPath: props.location }
                  }}
                />
              )}
            />
          );
        }
      }
    }
    return element;
  });

  return <Switch {...switchProps}>{filteredChildren}</Switch>;
};

const mapStateToProps = state => ({
  emailExpiryUtc: getAuthClaims(state).emailExpiryUtc,
  impersonatorId: getAuthClaims(state).impersonatorId,
  refreshToken: getAuthRefreshToken(state)
});

export default connect(mapStateToProps)(VerifiedSwitch);
