import PropTypes from "prop-types";
import React from "react";
import { Accordion, Icon } from "semantic-ui-react";

class WizardMenu extends React.Component {
  state = {
    active: 1
  };

  onProgression = (nextStep, toggleComplete = true) => {
    const { toggleCompletedState } = this.props;

    const currentStepIndex = nextStep - 2; // -1 from next to current, -1 because arrays start at 0
    if (toggleComplete) toggleCompletedState(currentStepIndex, true);
    this.setState({ active: nextStep });
  };

  static propTypes = {
    steps: PropTypes.arrayOf(PropTypes.object).isRequired,
    id: PropTypes.string.isRequired,
    toggleCompletedState: PropTypes.func,
    processUpload: PropTypes.func,
    processLoading: PropTypes.bool,
    processError: PropTypes.object
  };

  render() {
    const {
      steps,
      id,
      processLoading,
      processUpload,
      processError,
      metaData,
      dataLoading
    } = this.props;
    const { active } = this.state;

    return (
      <Accordion fluid className="i4b-wizard">
        {steps.map(x => (
          <React.Fragment key={x.key}>
            <Accordion.Title
              index={x.key}
              active={x.key === active}
              className={x.completed ? "completed" : undefined}
            >
              <div className={`indicator${x.icon ? " has-icon" : ""}`}>
                {!!x.icon ? <Icon fitted name={x.icon} /> : x.key}
              </div>
              {" " + x.title}
            </Accordion.Title>
            <Accordion.Content active={x.key === active}>
              {x.key === active &&
                x.content({
                  progressTo: this.onProgression,
                  uploadId: id,
                  processUpload,
                  processLoading,
                  processError,
                  metaData,
                  dataLoading
                })}
            </Accordion.Content>
            {x.key !== active &&
              x.summary({
                metaData
              })}
          </React.Fragment>
        ))}
      </Accordion>
    );
  }
}

export default WizardMenu;
