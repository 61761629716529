import React from "react";
import { PageHeader } from "@redriver/cinnamon";
import { PasswordChange } from "features/System";
import { SystemPageContent } from "components/pages";

const PasswordChangePage = () => (
  <SystemPageContent>
    <PageHeader>Change Password</PageHeader>
    <PasswordChange />
  </SystemPageContent>
);

export default PasswordChangePage;
