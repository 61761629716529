import { ListBuilder } from "@redriver/cinnamon";
import { SearchResultCounter } from "components/lists";
import { PageNumber } from "components/pagination";
import {
  getCustomerId,
  setExpandedRows
} from "features/Contacts/ContactsSearch/actions";
import ContactsTable from "features/Contacts/ContactsSearch/ContactsTable";
import { getContactsSearchState } from "features/Contacts/ContactsSearch/selectors";
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeSelection } from "../../../Contacts/ContactsSearch/actions";
import {
  changeFollowing,
  companyContactCriteria,
  deleteTag,
  getContactDetails,
  getContacts,
  storeContactFilters
} from "../actions";
import { getViewCompanyState } from "../selectors";
import FilterContacts from "./FilterContacts";

class CompanyContacts extends React.Component {
  componentDidMount() {
    const {
      companyId,
      storeContactFilters,
      contactFilters,
      changeFollowing,
      location,
      customerId,
      getCustomerId,
      setExpandedRows
    } = this.props;
    if (location.state && location.state.contactId) {
      setExpandedRows([location.state.contactId]);
    }
    getCustomerId();
  }

  render() {
    const {
      companyId,
      storeContactFilters,
      contactFilters,
      changeFollowing,
      location,
      customerId,
      contacts,
      getContacts,
      getContactDetails,
      deleteTag
    } = this.props;

    const search =
      location.state && location.state.search ? location.state.search : "";
    const left =
      location.state && location.state.leftCompany
        ? location.state.leftCompany.toString()
        : "false";
    const filters = {
      textFilter: { filter: search },
      reduxFilter: { ...contactFilters.reduxFilter, left: left }
    };
    return (
      <ListBuilder
        withTable
        totalTransform={data => data.totalResults}
        loadAction={getContacts}
        initialFilters={filters}
        loadParams={{ companyId }}
        initialSelection={{ allSelected: false, selected: [], deselected: [] }}
        renderList={(
          tableProps,
          { filters, pagination, total, selection },
          events
        ) => {
          if (tableProps.data.accessDenied) {
            return (
              <div className="i4b-list colour-expanded-table">
                <h1 className="flex space-between">Contacts</h1>
                <div className="access-denied-banner">
                  <p>
                    This company is NOT within your subscription, therefore your
                    view is limited.
                  </p>
                  <p>
                    For more information please contact{" "}
                    <a className="email" href="mailto:support@i4b.com">
                      support@i4b.com
                    </a>{" "}
                    or <span className="phone">+44 (0) 1252 367 400</span>
                  </p>
                </div>
              </div>
            );
          }

          const searchCriteria = companyContactCriteria(filters, companyId);
          return (
            <div className="i4b-list colour-expanded-table">
              <h1 className="flex space-between">Contacts</h1>
              <FilterContacts
                filters={filters}
                onChangeFilters={filters => {
                  storeContactFilters(filters);
                  events.onChangeFilters(filters);
                }}
                pageSize={pagination.pageSize}
                onPageSizeChange={events.onChangePagination}
                searchCriteria={searchCriteria}
                selection={selection}
                total={total}
                customerId={customerId}
                companyId={companyId}
                onSubmitted={() =>
                  getContacts({ filters, pagination, selection }, { companyId })
                }
                excludeCompanyName
              />

              <ContactsTable
                contacts={this.props.contacts.results || []}
                contactsLoading={tableProps.loading}
                totalResults={total}
                {...pagination}
                selection={{ allSelected: false, selected: [], deselected: [] }}
                deleteTag={deleteTag}
                changeSelection={events.onChangeSelection}
                changeFollowing={changeFollowing}
                getContactDetails={getContactDetails}
                selection={selection}
                className="full-height zindex-clear company-contacts-list"
                hideCompany
                isCompanyContactsPage={true}
                onSubmitted={() =>
                  getContacts({ filters, pagination, selection }, { companyId })
                }
              />
              <div className="i4b-list-footer">
                <SearchResultCounter
                  pageSize={pagination.pageSize}
                  pageNumber={pagination.pageNumber}
                  totalResults={total}
                />
                <PageNumber
                  totalItems={total}
                  onChange={x =>
                    events.onChangePagination({
                      pageSize: pagination.pageSize,
                      pageNumber: x
                    })
                  }
                  pageSize={pagination.pageSize}
                  value={pagination.pageNumber}
                />
              </div>
            </div>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  contactFilters: getViewCompanyState(state).contactFilters,
  customerId: getContactsSearchState(state).customerId,
  contacts: getViewCompanyState(state).contacts
});

const actions = {
  storeContactFilters,
  changeFollowing,
  changeSelection,
  getCustomerId,
  setExpandedRows,
  getContacts,
  getContactDetails,
  deleteTag
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(CompanyContacts)
);
