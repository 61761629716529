import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import { default as React } from "react";
import { submitKnowledgeRequest } from "./actions";

const SendFeedback = ({ renderTrigger, header }) => (
  <ModalBuilder
    withForm
    submitAction={submitKnowledgeRequest}
    renderTrigger={renderTrigger}
    renderModal={(modalProps, formProps, state, events) => (
      <Modal.Edit
        {...modalProps}
        header="Knowledge Base Request"
        submitLabel="Send"
        submitDisabled={
          !formProps.value.requestText ||
          !formProps.value.requestText.trim().length
        }
        size="tiny"
      >
        <p>
          Please state within the comment box below, the full description of
          what you would like to see in the help section
        </p>
        <Form {...formProps}>
          <Form.TextArea field="requestText" required fluid />
        </Form>
        <p style={{ marginTop: "1em", marginBottom: "0" }}>
          Please select <strong>"Send"</strong> to deliver the above request to
          our research team. We will endeavour to action this promptly. If your
          request cannot be actioned, support will be in touch.
        </p>
      </Modal.Edit>
    )}
  />
);

export default SendFeedback;
