import { ActionBar, Form, FormBuilder } from "@redriver/cinnamon";
import React, { Component } from "react";
import { ListSortByDropdown } from "../lookups";

class FilterLists extends Component {
  render() {
    const { filters, onChangeFilters, pageSize, onPageSizeChange } = this.props;

    return (
      <FormBuilder
        initialData={filters}
        onChange={onChangeFilters}
        renderForm={formProps => (
          <Form {...formProps}>
            <h1 className="m-t-05">Lists</h1>
            <ActionBar>
              <ActionBar.Item>
                <Form.Input
                  label="Search Lists"
                  field="search"
                  placeholder="Search lists…"
                />
              </ActionBar.Item>

              <ActionBar.Space width={5} />

              <ActionBar.Item>
                <Form.CheckboxGroup
                  field="types"
                  label={
                    <label className="inline-input-group-label-top m-b-075">
                      List Types
                    </label>
                  }
                  inline
                  options={[
                    { value: "contacts", text: "Contacts" },
                    { value: "companies", text: "Companies" }
                  ]}
                />
              </ActionBar.Item>

              <ActionBar.Space width={5} />

              <ActionBar.Item fluid>
                <Form.RadioGroup
                  field="allLists"
                  label={
                    <label className="inline-input-group-label-top m-b-075">
                      Viewable Lists
                    </label>
                  }
                  inline
                  options={[
                    { value: "false", text: "My Lists" },
                    { value: "true", text: "All lists" }
                  ]}
                />
              </ActionBar.Item>

              <ActionBar.Space width={5} />

              <ActionBar.Item align="right">
                <ListSortByDropdown
                  fluid
                  field="sortBy"
                  label="Sort By"
                  placeholder="Sort By..."
                />
              </ActionBar.Item>
            </ActionBar>
          </Form>
        )}
      />
    );
  }
}

export default FilterLists;
