import { Breadcrumb, Lookup, PageLoader } from "@redriver/cinnamon";
import Home from "assets/icons/home_breadcrumb.svg";
import { AppRoutes } from "constants/routes";
import { ListBreadcrumbItem } from "features/Lists/lookups";
import React from "react";
import { connect } from "react-redux";
import { Grid, Image } from "semantic-ui-react";
import { clearSelection, loadOverview, clearState } from "./actions";
import DisplayedResults from "./DisplayedResults";
import ListFilters from "./ListFilters";
import { ListNotes } from "./ListNotes";
import ListResults from "./ListResults";
import ListSearchCriteria from "./ListSearchCriteria";
import ListSummary from "./ListSummary";
import { getListsOverviewState } from "./selectors";

class ListOverviewContent extends React.Component {
  state = {
    componentReady: false
  };
  componentDidMount() {
    const { loadOverview, listId, clearSelection } = this.props;
    clearSelection();
    loadOverview(listId).then(() => {
      // We must finish regetting the list details as the batches contained within a list
      // may have been changed
      this.setState({ componentReady: true });
    });
  }

  componentWillUnmount() {
    const { clearState } = this.props;
    clearState();
  }

  render() {
    const {
      listId,
      overviewLoading,
      batchIds,
      isContactList,
      reduxListId
    } = this.props;
    const { componentReady } = this.state;
    // Don't mount anything until the loadOverview call has completed for our list
    if (overviewLoading || listId != reduxListId || !componentReady)
      return <PageLoader />;
    return (
      <Lookup
        lookup={"listHasStatusLookup"}
        lookupParams={{ listId, isContactList }}
        render={({ response }, refresh) => {
          return (
            <Grid className="search-page-grid list-overview" stackable>
              <Grid.Column className="left-column">
                <Breadcrumb className="i4b-breadcrumb-container list-overview-breadcrumb">
                  <Breadcrumb.Item
                    link={AppRoutes.Root}
                    icon={<Image src={Home} title="Home" />}
                  />
                  <Breadcrumb.Item link={AppRoutes.Lists} content="Lists" />
                  <ListBreadcrumbItem listId={listId} />
                </Breadcrumb>
                <ListSummary />
                <ListFilters hasStatus={response} />
                <DisplayedResults />
                <ListNotes />
                <ListSearchCriteria />
              </Grid.Column>
              <Grid.Column className="right-column">
                <ListResults
                  listId={listId}
                  batchIds={batchIds}
                  isContactList={isContactList}
                  hasStatus={response}
                />
              </Grid.Column>
            </Grid>
          );
        }}
      />
    );
  }
}
const actions = {
  loadOverview,
  clearSelection,
  clearState
};
const mapStateToProps = state => {
  const {
    overviewLoading,
    batchIds,
    isContactList,
    listId
  } = getListsOverviewState(state);
  return {
    overviewLoading,
    batchIds,
    isContactList,
    reduxListId: listId
  };
};

export default connect(
  mapStateToProps,
  actions
)(ListOverviewContent);
