import { TriggerBuilder } from "@redriver/cinnamon";
import { SavedSearchType } from "constants/search";
import { isFormDataEmpty } from "features/Utils";
import React from "react";
import { Button } from "semantic-ui-react";
import LoadSearchSidePanel from "./LoadSearchSidePanel";
import SaveSearchModal from "./modals/SaveSearchModal";

class SearchHeader extends React.Component {
  state = {
    sidePanelOpen: false
  };

  toggleSidePanelOpen = (newState = true) => {
    this.setState({ sidePanelOpen: newState });
  };

  render() {
    const {
      saveSearchAction,
      loadSavedSearchesAction,
      searchSelectedAction,
      searchDeleteAction,
      isContactsPage,
      listId,
      updateListCriteria,
      formData,
      hardResetSearch,
      isUniverse
    } = this.props;
    const { sidePanelOpen } = this.state;

    return (
      <div className="flex flex-wrap align-center space-between">
        <div className="flex align-center m-b-1">
          <h1>{isContactsPage ? "Contacts Search" : "Companies Search"}</h1>
          {formData && (
            <a
              className="purple-link"
              onClick={hardResetSearch}
              style={{ marginTop: "3px" }}
            >
              (Reset Search)
            </a>
          )}
        </div>
        <div className="m-b-1">
          {listId ? (
            <TriggerBuilder
              submitAction={updateListCriteria}
              onSubmitted={this.onNavBack}
              submitParams={listId}
              renderTrigger={(fireTrigger, state, events) => (
                <Button
                  primary
                  content="Update List"
                  className="green-small-button primary"
                  onClick={fireTrigger}
                  loading={state.submitting}
                  disabled={isFormDataEmpty(formData)}
                />
              )}
            />
          ) : (
            <React.Fragment>
              <SaveSearchModal
                submitAction={saveSearchAction}
                submitParams={{
                  searchType: !isContactsPage
                    ? SavedSearchType.CompanySearch
                    : SavedSearchType.ContactSearch,
                  isUniverse: isUniverse
                }}
                isCompaniesPage={!isContactsPage}
              />
              <Button
                content="Load"
                primary
                className="green-small-button"
                onClick={() => this.toggleSidePanelOpen(!sidePanelOpen)}
                type="button"
              />
              <LoadSearchSidePanel
                loadAction={loadSavedSearchesAction}
                loadParams={{
                  searchType: !isContactsPage
                    ? SavedSearchType.CompanySearch
                    : SavedSearchType.ContactSearch
                }}
                onSelected={searchSelectedAction}
                deleteAction={searchDeleteAction}
                toggleOpenState={() => this.toggleSidePanelOpen(false)}
                open={sidePanelOpen}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default SearchHeader;
