import { Csv } from "components/csv";
import React from "react";
import { withRouter } from "react-router-dom";

const FileDataStep = ({ progressTo, uploadId, history }) => (
  <div>
    <Csv.Tools progressTo={progressTo} uploadId={uploadId} />
  </div>
);

export default withRouter(FileDataStep);
