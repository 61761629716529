import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";

const ContactFollowedCriteria = ({ active, handleClick, criteria }) => {
  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Following Contact Criteria"
        text={
          criteria.contactFollowedSearch.isFollowingContact
            ? "Only show followed contact"
            : "Exclude all followed contact"
        }
      />
      <Accordion.Content active={active}>
        {criteria.contactFollowedSearch.isFollowingContact
          ? "Showing only contacts being followed"
          : "Excluding all contacts being followed"}
      </Accordion.Content>
    </React.Fragment>
  );
};
export default ContactFollowedCriteria;
