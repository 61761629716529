import {
  requestIsBusy,
  requestIsSlow,
  requestIsSuccess,
  requestError
} from "@redriver/cinnamon";
import { REQUEST_FORGOTTEN } from "./actions";

const initialState = {
  requesting: false,
  slowRequest: null,
  success: null,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FORGOTTEN:
      return {
        ...state,
        requesting: requestIsBusy(action),
        slowRequest: requestIsSlow(action),
        success: requestIsSuccess(action),
        error: requestError(action)
      };

    default:
      return state;
  }
};
