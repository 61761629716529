export const AppRoutes = {
  Root: "/",
  Companies: "/companies",
  Contacts: "/contacts",
  Customers: "/customers",
  SegmentRemoval: "/segment-removals",
  Lists: "/lists",
  Uploads: "/uploads",
  Settings: "/settings",
  Users: "/users",
  Roles: "/roles",
  Notifications: "/notifications",
  OptOutDownload: "/opt-out/:tokenId",
  KnowledgeBase: "/knowledge-base",
  OptOutReport: "/opt-out-report",
  AdvancedOptOutReport: "/advanced-opt-out-report",
  UserStats: "/user-stats",
  SecureFileArea: "/secure-files",
  Reports: "/reports"
};

export const SystemRoutes = {
  Login: "/login",
  OAuthLogin: "/oauth/login?",
  Terms: "/welcome",
  Logout: "/logout",
  SetupUser: "/setup-user/:token?",
  VerifyEmail: "/verify-email/:token?",
  ForgottenPassword: "/forgotten-password",
  ResetPassword: "/reset-password/:token?",
  ResetUser: "/reset-user/:verifyToken/:pwToken",
  ChangePassword: "/change-password",
  Reverify: "/reverify-email",
  VerifiedEmail: "/email-reverified/:token",
  ActiveSessions: "/active-sessions",
  AccessDenied: "/access-denied/:reason"
};

export const accessDeniedWithReason = (reason = ":reason") =>
  `/access-denied/${reason}`;

const UserMatch = (id = ":id") => `${AppRoutes.Users}/${id}`;

export const UserRoutes = {
  User: id => `${UserMatch(id)}`,
  Overview: id => `${UserMatch(id)}`,
  Whitelist: id => `${UserMatch(id)}/whitelist`,
  InternalUsers: `${AppRoutes.Users}/internal`,
  ExternalUsers: `${AppRoutes.Users}/external`,
  Countries: id => `${UserMatch(id)}/countries`
};

export const SettingsRoutes = {
  Templates: `${AppRoutes.Settings}/templates`,
  CompanyCustomFields: `${AppRoutes.Settings}/company-custom-fields`,
  CompanyCustomValues: `${AppRoutes.Settings}/company-custom-values`,
  ContactCustomFields: `${AppRoutes.Settings}/contact-custom-fields`
};

const CustomerMatch = (id = ":id") => `${AppRoutes.Customers}/${id}`;

export const CustomerRoutes = {
  Customer: id => `${CustomerMatch(id)}`,
  Overview: id => `${CustomerMatch(id)}`,
  Users: id => `${CustomerMatch(id)}/users`,
  Contacts: id => `${CustomerMatch(id)}/contacts`,
  ContactCustomFields: id => `${CustomerMatch(id)}/contact-custom-fields`,
  Segment: id => `${CustomerMatch(id)}/segment`,
  CustomFields: id => `${CustomerMatch(id)}/company-custom-fields`,
  CustomFieldValues: id => `${CustomerMatch(id)}/custom-field-values`,
  Suppressions: id => `${CustomerMatch(id)}/suppressions`,
  UserStats: id => `${CustomerMatch(id)}/user-stats`
};

const CompanyMatch = (id = ":id") => `${AppRoutes.Companies}/${id}`;

export const CompanyRoutes = {
  Overview: id => `${CompanyMatch(id)}`,
  Contacts: id => `${CompanyMatch(id)}/contacts`,
  OrgStructure: id => `${CompanyMatch(id)}/org-structure`,
  Lists: id => `${CompanyMatch(id)}/lists`
};

const ListMatch = (id = ":id") => `${AppRoutes.Lists}/${id}`;

export const ListRoutes = {
  List: id => `${ListMatch(id)}`,
  UpdateContact: id => `${AppRoutes.Lists}/${id || ":listId"}/update-contact`,
  UpdateCompany: id => `${AppRoutes.Lists}/${id || ":listId"}/update-company`
};

const UploadMatch = (id = ":id") => `${AppRoutes.Uploads}/${id}`;

export const UploadRoutes = {
  Upload: id => `${UploadMatch(id)}`
};
