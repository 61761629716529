import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import FilterClearer from "./FilterClearer";

class FilterBaseHeader extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      .isRequired,
    collapsible: PropTypes.bool,
    collapsed: PropTypes.bool,
    onCollapse: PropTypes.func,
    removable: PropTypes.bool,
    onRemove: PropTypes.func
  };

  static defaultProps = {
    title: "Filter",
    collapsible: false,
    removable: true
  };

  render() {
    const {
      title,
      children,
      collapsible,
      collapsed,
      onCollapseToggle,
      removable,
      onRemove,
      showClearer,
      onClear
    } = this.props;

    const rhMinWidth = showClearer
      ? removable
        ? "7.5rem"
        : "5.5rem"
      : removable
      ? "4.5rem"
      : "2.25rem";

    return (
      <div
        onClick={collapsible ? onCollapseToggle : null}
        className="filter-title"
      >
        <div className="ellipsis-container">
          <div
            style={{ marginRight: "1em", marginBottom: 0, minWidth: "7rem" }}
          >
            {title}
          </div>
          {children}
        </div>
        <div
          style={
            removable || showClearer
              ? {
                  minWidth: rhMinWidth,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }
              : { minWidth: rhMinWidth, alignSelf: "center" }
          }
        >
          {/* Wrap icons with divs to give a larger click target  */}
          {showClearer !== undefined && (
            <FilterClearer show={showClearer} clearSelected={onClear} />
          )}
          {removable && (
            <div
              onClick={onRemove}
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center"
              }}
            >
              <Icon
                name="trash"
                style={{ lineHeight: "inherit", marginLeft: "1rem" }}
              />
            </div>
          )}
          {collapsible && (
            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <Icon
                name={collapsed ? "angle down" : "angle up"}
                style={{ lineHeight: "inherit", marginLeft: "1rem" }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FilterBaseHeader;
