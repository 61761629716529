import { isCompanySizeEmpty } from "features/Utils";
import PropTypes from "prop-types";
import React from "react";
import FilterBase from "./FilterBase";
import SizeFilterForm from "./SizeFilterForm";
import SizeFilterSummary from "./SizeFilterSummary";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";

class SizeFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    formData: PropTypes.object.isRequired,
    results: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    removeFilter: PropTypes.func.isRequired,
    clearSizeFilter: PropTypes.func.isRequired
  };

  state = {
    visible: true,
    total: null
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  isRevenueEmpty = () => {
    const { formData, id } = this.props;
    const { searchCriteria } = formData;
    const revenueArr = searchCriteria.filter(el => {
      if (el.id == id) {
        const { companySizeDetails } = el;
        return companySizeDetails.revenueFrom || companySizeDetails.revenueTo;
      }
    });
    return revenueArr.length == 0;
  };

  render() {
    const {
      id,
      formData,
      loading,
      pageChanged,
      removeFilter,
      clearSizeFilter
    } = this.props;
    const { visible, total } = this.state;
    const { searchCriteria } = formData;

    const sizeObj = searchCriteria.find(el => el.id == id);
    const { companySizeDetails } = sizeObj;
    return (
      <FilterBase
        total={total}
        loading={loading && !pageChanged}
        className="size-filter"
      >
        <FilterBase.Header
          title="Company Size"
          collapsible
          collapsed={!visible}
          onCollapseToggle={this.toggleVisibility}
          removable
          onRemove={event => removeFilter(id, event)}
          showClearer={!isCompanySizeEmpty(companySizeDetails)}
          onClear={event => clearSizeFilter(id, event)}
        >
          {!visible && (
            <div
              className="m-r-1 m-b-0 ellipsis-paragraph"
              style={{ fontSize: "0.75rem", paddingTop: "0.2rem" }}
            >
              {isCompanySizeEmpty(companySizeDetails) ? (
                <span>No criteria selected</span>
              ) : (
                <SizeFilterSummary details={companySizeDetails} />
              )}
            </div>
          )}
        </FilterBase.Header>
        <FilterBase.Body collapsed={!visible}>
          <div className="size-filter-container">
            <div>
              <SizeFilterForm isRevenueEmpty={this.isRevenueEmpty} />
            </div>
          </div>
        </FilterBase.Body>
      </FilterBase>
    );
  }
}

export default SizeFilter;
