import { SideMenu } from "@redriver/cinnamon";
import { PermissionCheck, UserTypeCheck } from "components/auth";
import { Actions, Targets, UserTypes } from "constants/permissions";
import { SettingsRoutes } from "constants/routes";
import React from "react";
import { withRouter } from "react-router-dom";

const SettingsMenu = () => (
  <SideMenu pointing vertical>
    <SideMenu.Item exact link={SettingsRoutes.Templates}>
      <span>Download Templates</span>
    </SideMenu.Item>
    <UserTypeCheck restrictTo={UserTypes.External}>
      <PermissionCheck target={Targets.ItemTags} action={Actions.View}>
        <SideMenu.Item exact link={SettingsRoutes.CompanyCustomFields}>
          <span>Company Custom Fields</span>
        </SideMenu.Item>
        <SideMenu.Item exact link={SettingsRoutes.CompanyCustomValues}>
          <span>Company Custom Values</span>
        </SideMenu.Item>
        <SideMenu.Item exact link={SettingsRoutes.ContactCustomFields}>
          <span>Contact Custom Fields</span>
        </SideMenu.Item>
      </PermissionCheck>
    </UserTypeCheck>
  </SideMenu>
);

export default withRouter(SettingsMenu);
