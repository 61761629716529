import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";

class RenameListModal extends React.Component {
  render() {
    const {
      loadAction,
      loadParams,
      loadTransform,
      submitParams,
      submitAction,
      toggleVisibility,
      onSubmitted
    } = this.props;

    return (
      <ModalBuilder
        withForm
        loadAction={loadAction}
        loadParams={loadParams}
        loadTransform={loadTransform}
        submitAction={submitAction}
        submitParams={submitParams}
        onSubmitted={onSubmitted}
        onCancel={toggleVisibility}
        renderModal={(modalProps, formProps) => (
          <Modal.Edit {...modalProps} header="Change list name">
            <Form {...formProps}>
              <Form.Input field="name" label="New List Name" />
            </Form>
          </Modal.Edit>
        )}
      />
    );
  }
}

export default RenameListModal;
