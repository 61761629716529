import isCompanySizeEmpty from "./isCompanySizeEmpty";
import isJobTitlesEmpty from "./isJobTitlesEmpty";

const isFormDataEmpty = formData => {
  const { searchCriteria } = formData;
  const criteria = searchCriteria.filter(el => {
    switch (el.type) {
      case "searchFilter":
        return el.searchFilter && el.searchFilter.search !== "";
      case "countriesFilter":
        return el.selectedCountries.length !== 0;
      case "sizeFilter":
        const { companySizeDetails } = el;
        return !isCompanySizeEmpty(companySizeDetails);
      case "postalAreasFilter":
        return el.selectedAreas.length !== 0;
      case "stockExchangeIndexesFilter":
        return el.selectedIndexes.length !== 0;
      case "industriesFilter": {
        const { industriesSelection } = el;
        const { included, excluded } = industriesSelection;
        return included.length !== 0 || excluded.length !== 0;
      }
      case "listsFilter": {
        const { included, excluded } = el.listsSearch.listsSelection;
        return included.length !== 0 || excluded.length !== 0;
      }
      case "contactsInCompanyListsFilter": {
        const {
          included,
          excluded
        } = el.contactsInCompanyListsSearch.listsSelection;
        return included.length !== 0 || excluded.length !== 0;
      }
      case "companyUploadsFilter":
      case "contactUploadsFilter": {
        const { included, excluded } = el.uploadsSearch.uploadsSelection;
        return included.length !== 0 || excluded.length !== 0;
      }
      case "companyFollowedFilter": {
        return el.companyFollowedSearch.isFollowingCompany !== null;
      }
      case "contactFollowedFilter": {
        return el.contactFollowedSearch.isFollowingContact !== null;
      }
      case "jobTitlesFilter":
        const { jobTitles } = el.jobTitlesFilter;
        return !isJobTitlesEmpty(jobTitles);
      case "techRolesFilter":
        return el.selectedTechRoles.length !== 0;
      case "LOBRolesFilter":
        return el.selectedLobRoles && el.selectedLobRoles.length !== 0;
      case "contactTagsFilter": {
        const { included, excluded } = el.tagsSelection.tags;
        return included.length !== 0 || excluded.length !== 0;
      }
      case "customFieldsFilter": {
        const { included, excluded } = el.fieldsSelection.customFields;
        return included.length !== 0 || excluded.length !== 0;
      }
      case "languagesFilter":
        return el.selectedLanguages.length !== 0;
      case "jobsFilter":
        const { jobsSearch } = el;

        return (
          (jobsSearch.jobTitlesFilter.jobTitles !== null &&
            !isJobTitlesEmpty(jobsSearch.jobTitlesFilter.jobTitles)) ||
          (jobsSearch.selectedLobRoles !== null &&
            jobsSearch.selectedLobRoles.length !== 0) ||
          (jobsSearch.selectedTechRoles !== null &&
            jobsSearch.selectedTechRoles.length !== 0)
        );
    }
  });
  if (criteria.length > 0) return false;
  else return true;
};

export default isFormDataEmpty;
