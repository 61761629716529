import { ErrorMessage, Form, getAuthClaims } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { getEmailVerificationState } from "../selectors";
import { resendVerification } from "./actions";

class EmailVerificationResender extends React.Component {
  static propTypes = {
    onResend: PropTypes.func.isRequired,
    userEmail: PropTypes.string,
    emailVerified: PropTypes.bool,
    resending: PropTypes.bool,
    slowRequest: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.any
  };

  state = {
    username: "",
    validated: false,
    showErrors: false
  };

  onFormChange = (field, data) => this.setState({ [field]: data });

  onFormValidated = validated => this.setState({ validated });

  requestVerificationEmail = e => {
    e.preventDefault();
    if (!this.state.validated) {
      this.setState({ showErrors: true });
      return;
    }
    if (this.props.resending) return;
    this.props.onResend(this.props.userEmail || this.state.username);
  };

  render() {
    const {
      userEmail,
      emailVerified,
      resending,
      slowRequest,
      success,
      error
    } = this.props;

    if (emailVerified) {
      return <p>Your email address has already been verified</p>;
    }

    if (resending && slowRequest) {
      return <p>Requesting verification email...</p>;
    }

    if (success) {
      return <p>A verification link has been sent, please check your email</p>;
    }

    const isBusy = resending && slowRequest;

    return (
      <Form
        value={this.state}
        onChange={this.onFormChange}
        onValidated={this.onFormValidated}
        showErrors={this.state.showErrors}
      >
        {!userEmail && (
          <Form.Email
            field="username"
            label="Email"
            icon="email"
            iconPosition="left"
            placeholder="Enter email address"
            disabled={isBusy}
            autoFocus
            required
          />
        )}
        <Button
          primary
          onClick={this.requestVerificationEmail}
          disabled={isBusy}
          loading={isBusy}
        >
          Request Verification Email
        </Button>
        <ErrorMessage error={error} />
      </Form>
    );
  }
}

const actions = {
  onResend: resendVerification
};

const mapStateToProps = state => {
  const { userEmail, emailVerified } = getAuthClaims(state);
  const verificationState = getEmailVerificationState(state);
  return {
    userEmail,
    emailVerified,
    resending: verificationState.isResending,
    slowRequest: verificationState.resendingSlow,
    success: verificationState.resendingSuccess,
    error: verificationState.resendingError
  };
};

export default connect(
  mapStateToProps,
  actions
)(EmailVerificationResender);
