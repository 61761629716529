import { getAuthClaims } from "@redriver/cinnamon";
import React from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import {
  SaveListModal,
  AddToListModal,
  AddToSegmentsModal,
  RemoveFromSegmentsModal
} from "components/search";
import { UserTypes, Targets, Actions } from "constants/permissions";
import AddCustomFieldModal from "../CompaniesSearch/AddCustomFieldModal";
import { PermissionCheck, UserTypeCheck } from "components/auth";
import {
  saveList,
  removeCompaniesFromSegments,
  getCompanies,
  addCompaniesToSegments,
  addCompanyTag,
  addToList,
  getSegmentCompaniesCount,
  getSegmentAdditions,
  getSegmentRemovals
} from "../CompaniesSearch/actions";

class CompanyDetailActionDropdown extends React.Component {
  state = {
    dropdownOpen: false
  };

  toggleDropdownOpen = () =>
    this.setState({ dropdownOpen: !this.state.dropdownOpen });

  render() {
    const {
      isInSegment,
      allowTagAdditions,
      customerHasCustomSegment,
      getSegmentAdditions,
      getSegmentRemovals,
      addCompaniesToSegments,
      removeCompaniesFromSegments,
      companyId,
      onUpdated
    } = this.props;
    const { dropdownOpen } = this.state;
    const selection = {
      allSelected: false,
      selected: [companyId],
      deselected: []
    };
    const selectedItems = 1;
    return (
      <div>
        <span className={`none-selected`} onClick={this.toggleDropdownOpen}>
          Actions
        </span>
        <Button
          className={`actions-dropdown-button ${
            !dropdownOpen ? `some-selected` : `close`
          }`}
          onClick={this.toggleDropdownOpen}
        />
        <section
          style={
            dropdownOpen
              ? {
                  visibility: "visible",
                  opacity: "1"
                }
              : {
                  visibility: "hidden",
                  opacity: "0"
                }
          }
          className="actions-dropdown-section"
        >
          <header>
            <h3>Actions</h3>
          </header>
          <ul className="list-unstyled">
            {(isInSegment || !customerHasCustomSegment) && (
              <React.Fragment>
                <SaveListModal
                  isCompaniesPage
                  submitAction={saveList}
                  selection={selection}
                />
                <AddToListModal
                  isCompaniesPage
                  submitAction={addToList}
                  submitParams={{
                    selection
                  }}
                />
              </React.Fragment>
            )}
            <UserTypeCheck restrictTo={UserTypes.External}>
              <li>
                <AddCustomFieldModal
                  selection={selection}
                  onSubmitted={() => onUpdated()}
                  submitAction={addCompanyTag}
                  allowAdditions={allowTagAdditions}
                  isActionList={true}
                />
              </li>

              <PermissionCheck
                target={Targets.CustomerSegments}
                action={Actions.Edit}
              >
                {customerHasCustomSegment && !isInSegment && (
                  <li>
                    <AddToSegmentsModal
                      loadAction={getSegmentAdditions}
                      submitAction={addCompaniesToSegments}
                      loadParams={{
                        selection,
                        selectedItems
                      }}
                      submitParams={{
                        selection,
                        selectedItems
                      }}
                      onSubmitted={() => onUpdated()}
                    />
                  </li>
                )}
                {customerHasCustomSegment && isInSegment && (
                  <li>
                    <RemoveFromSegmentsModal
                      submitAction={removeCompaniesFromSegments}
                      loadAction={getSegmentRemovals}
                      loadParams={{
                        selection,
                        selectedItems
                      }}
                      submitParams={{
                        selection,
                        selectedItems
                      }}
                      onSubmitted={() => onUpdated()}
                    />
                  </li>
                )}
              </PermissionCheck>
            </UserTypeCheck>
          </ul>
        </section>
      </div>
    );
  }
}

const actions = {
  getCompanies,
  getSegmentCompaniesCount,
  getSegmentAdditions,
  getSegmentRemovals,
  addCompaniesToSegments,
  removeCompaniesFromSegments
};

const mapStateToProps = state => {
  const { permissions } = getAuthClaims(state);

  return {
    allowTagAdditions:
      permissions &&
      permissions.hasOwnProperty(Targets.ItemTags) &&
      (Array.isArray(permissions[Targets.ItemTags])
        ? permissions[Targets.ItemTags].some(p => p == Actions.Create)
        : permissions[Targets.ItemTags] == Actions.Create)
  };
};

export default connect(
  mapStateToProps,
  actions
)(CompanyDetailActionDropdown);
