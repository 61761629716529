import React from "react";

export const getCurrencySymbol = currency => {
  switch (currency) {
    case "GBP":
      return "£";
    case "EUR":
      return "€";
    case "USD":
      return "$";
  }
};

const SizeFilterSummary = ({ details }) => {
  return (
    <React.Fragment>
      {(details.revenueFrom || details.revenueTo) && (
        <span>
          Revenue
          {details.revenueFrom &&
            `${" "}from ${getCurrencySymbol(details.revenueCurrency)}${
              details.revenueFrom
            } M`}
          {details.revenueTo &&
            `${" "}to ${getCurrencySymbol(details.revenueCurrency)}${
              details.revenueTo
            } M`}
        </span>
      )}
      {(details.numberEmployeesFrom || details.numberEmployeesTo) && (
        <span>
          Employees
          {details.numberEmployeesFrom &&
            `${" "}from ${details.numberEmployeesFrom}`}
          {details.numberEmployeesTo && `${" "}to ${details.numberEmployeesTo}`}
        </span>
      )}
      {(details.numberITStaffFrom || details.numberITStaffTo) && (
        <span>
          IT Staff
          {details.numberITStaffFrom &&
            `${" "}from ${details.numberITStaffFrom}`}
          {details.numberITStaffTo && `${" "}to ${details.numberITStaffTo}`}
        </span>
      )}
      {(details.numberLocationsFrom || details.numberLocationsTo) && (
        <span>
          Locations
          {details.numberLocationsFrom &&
            `${" "}from ${details.numberLocationsFrom}`}
          {details.numberLocationsTo && `${" "}to ${details.numberLocationsTo}`}
        </span>
      )}
      {(details.numberPCsFrom || details.numberPCsTo) && (
        <span>
          PCs
          {details.numberPCsFrom && `${" "}from ${details.numberPCsFrom}`}
          {details.numberPCsTo && `${" "}to ${details.numberPCsTo}`}
        </span>
      )}
      {(details.numberServersFrom || details.numberServersTo) && (
        <span>
          Servers
          {details.numberServersFrom &&
            `${" "}from ${details.numberServersFrom}`}
          {details.numberServersTo && `${" "}to ${details.numberServersTo}`}
        </span>
      )}
    </React.Fragment>
  );
};

export default SizeFilterSummary;
