import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes, CompanyRoutes } from "constants/routes";
import React from "react";
import { Route, Switch } from "react-router-dom";
import PermissionErrorPage from "../PermissionErrorPage";
import CompaniesSearchPage from "./CompaniesSearchPage";
import CompanyRoute from "./CompanyRoute";

export default () => (
  <React.Fragment>
    <PermissionCheck target={Targets.Company} action={Actions.View}>
      <Switch>
        <Route
          exact
          path={AppRoutes.Companies}
          component={CompaniesSearchPage}
        />
        <Route path={CompanyRoutes.Overview()} component={CompanyRoute} />
      </Switch>
    </PermissionCheck>
    <PermissionCheck
      target={Targets.Company}
      action={Actions.View}
      not
      component={PermissionErrorPage}
    />
  </React.Fragment>
);
