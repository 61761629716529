import { Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Icon } from "semantic-ui-react";
import { loadEditRole, updateRole } from "./actions";
import RoleForm from "./RoleForm";

const EditRole = ({ role, renderTrigger, onSubmitted }) => (
  <ModalBuilder
    withForm
    loadAction={loadEditRole}
    loadParams={role}
    submitAction={updateRole}
    submitParams={(role, role.id)}
    onSubmitted={onSubmitted}
    renderTrigger={renderTrigger}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit
        {...modalProps}
        header={
          <div>
            <Icon name="edit" /> Edit Role
          </div>
        }
      >
        <RoleForm {...formProps} />
      </Modal.Edit>
    )}
  />
);

export default EditRole;
