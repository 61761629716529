import React from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";

const FilterKnowledge = ({ filters, onChangeFilters }) => (
  <FormBuilder
    initialData={filters}
    onChange={onChangeFilters}
    renderForm={(formProps, state, events) => (
      <Form {...formProps}>
        <Form.Input
          field="search"
          placeholder="Search The Knowledge Base..."
          icon="search"
        />
      </Form>
    )}
  />
);

export default FilterKnowledge;
