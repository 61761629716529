import React from "react";
import { Accordion } from "semantic-ui-react";
import SizeFilterSummary, { getCurrencySymbol } from "../SizeFilterSummary";
import ReadOnlyHeader from "./ReadOnlyHeader";

const renderValue = (from, to) => {
  if (!from) return `to ${to}`;

  if (!to) return `from ${from}`;

  return `from ${from} to ${to}`;
};

const SizeItem = ({ from, to, title, children }) => {
  if (!from && !to) return null;

  return (
    <tr>
      <td>{title}</td>
      <td className="font-weight-bold">{children || renderValue(from, to)}</td>
    </tr>
  );
};

const CompanySizeCriteria = ({ active, handleClick, criteria }) => {
  const size = criteria.companySizeDetails;
  if (!size) return null;

  // If no values populated, return early
  if (
    !Object.keys(size).some(
      k => k != "match" && k != "revenueCurrency" && !!size[k]
    )
  )
    return null;

  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Company Size"
        text={<SizeFilterSummary details={size} />}
      />
      <Accordion.Content active={active}>
        <label
          style={{
            marginLeft: "0.2rem"
          }}
        >
          Matches {size.match == "all" ? "all" : "at least one"} of these tags
        </label>
        <table>
          <tbody>
            <SizeItem
              from={size.revenueFrom}
              to={size.revenueTo}
              title="Revenue (in millions)"
            >
              {!!size.revenueFrom &&
                `from ${getCurrencySymbol(size.revenueCurrency)}${
                  size.revenueFrom
                }`}

              {!!size.revenueTo &&
                ` to ${getCurrencySymbol(size.revenueCurrency)}${
                  size.revenueTo
                }`}
            </SizeItem>
            <SizeItem
              from={size.numberEmployeesFrom}
              to={size.numberEmployeesTo}
              title="Number of employees"
            />
            <SizeItem
              from={size.numberITStaffFrom}
              to={size.numberITStaffTo}
              title="Number of IT staff"
            />

            <SizeItem
              from={size.numberLocationsFrom}
              to={size.numberLocationsTo}
              title="Number of locations"
            />

            <SizeItem
              from={size.numberPCsFrom}
              to={size.numberPCsTo}
              title="Number of PCs"
            />

            <SizeItem
              from={size.numberServersFrom}
              to={size.numberServersTo}
              title="Number of servers"
            />
          </tbody>
        </table>
      </Accordion.Content>
    </React.Fragment>
  );
};
export default CompanySizeCriteria;
