import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Pagination, Icon } from "semantic-ui-react";

//Cinnamon PageNumber component with added custom icons

const StyledPagination = styled(Pagination)`
  &&& > .active.item {
    font-weight: bold;
  }
`;

class PageNumber extends React.Component {
  onPageChange = (e, { activePage }) => this.props.onChange(activePage);

  componentWillReceiveProps;
  render() {
    const {
      value,
      onChange,
      pageSize,
      totalItems,
      nextItem,
      prevItem
    } = this.props;
    const totalPages = totalItems > 0 ? Math.ceil(totalItems / pageSize) : 0;

    return (
      <StyledPagination
        activePage={value}
        onPageChange={this.onPageChange}
        totalPages={totalPages}
        size="tiny"
        firstItem={null}
        lastItem={null}
        prevItem={{
          content: (
            <div className="flex align-center">
              <Icon name="long arrow alternate left" />
              <p className="m-b-0 m-l-05">PREV</p>
            </div>
          ),
          icon: true,
          disabled: value <= 1
        }}
        nextItem={{
          content: (
            <div className="flex align-center">
              <p className="m-b-0 m-r-05">NEXT</p>
              <Icon name="long arrow alternate right" />
            </div>
          ),
          icon: true,
          disabled: value >= totalPages
        }}
      />
    );
  }
}

export default PageNumber;
