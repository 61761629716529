import { apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/LIST_CUSTOMERS";

export const ActionTypes = {
  GetCustomers: `${NAMESPACE}/GET_CUSTOMERS`,
  MigrateCustomer: `${NAMESPACE}/MIGRATE_CUSTOMER`,
  BulkMigrateCustomers: `${NAMESPACE}/BULK_MIGRATE_CUSTOMERS`,
  GetMigrationCount: `${NAMESPACE}/GET_MIGRATION_COUNT`
};

const getCustomersQueryParams = ({ filters, pagination, selection }) => {
  let showHardEnd = null;
  let showNoHardEnd = null;
  let showAccessDenied = null;
  let showAccessAllowed = null;
  if (filters.hardEnd) {
    showHardEnd = filters.hardEnd.indexOf("set") > -1;
    showNoHardEnd = filters.hardEnd.indexOf("notSet") > -1;
  }

  if (filters.accessDenied) {
    showAccessDenied = filters.accessDenied.indexOf("accessDenied") > -1;
    showAccessAllowed = filters.accessDenied.indexOf("accessAllowed") > -1;
  }

  const params = {
    ...filters,
    ...pagination,
    showHardEnd,
    showNoHardEnd,
    showAccessDenied,
    showAccessAllowed,
    showNoStartDate: filters.showNoStartDate,
    showNoInitialEnd: filters.showNoInitialEnd,
    showNoLastDay: filters.showNoLastDay
  };

  return params;
};

export const getCustomers = ({ filters, pagination, selection }) => {
  const params = getCustomersQueryParams({ filters, pagination, selection });
  return apiGet(ActionTypes.GetCustomers, "customers", params);
};

export const getMigrationCount = ({ filters, selection }) => {
  const { selected, allSelected } = selection;
  const pagination = {};
  const queryParams = allSelected
    ? getCustomersQueryParams({ filters, pagination, selection })
    : null;

  const params = {
    migrateAll: allSelected,
    customerIds: selected,
    filters: queryParams
  };

  return apiPost(
    ActionTypes.GetMigrationCount,
    `customers/migration-count`,
    params
  );
};

export const migrateCustomer = id =>
  apiPut(ActionTypes.MigrateCustomer, `customers/${id}/migrate`, null);

export const migrateCustomers = ({ filters, selection }) => {
  const { selected, allSelected } = selection;
  const pagination = {};
  const queryParams = allSelected
    ? getCustomersQueryParams({ filters, pagination, selection })
    : null;

  const params = {
    migrateAll: allSelected,
    customerIds: selected,
    filters: queryParams
  };

  return apiPut(
    ActionTypes.BulkMigrateCustomers,
    `customers/bulk-migrations`,
    params
  );
};
