import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { PageHeader, getAuthState } from "@redriver/cinnamon";
import { PasswordForgotten } from "features/System";
import { SystemRoutes } from "constants/routes";
import { SystemPageContent } from "components/pages";

const PasswordForgottenPage = ({ loggedIn }) => {
  if (loggedIn) {
    return <Redirect to={SystemRoutes.ChangePassword} />;
  }

  return (
    <SystemPageContent>
      <PageHeader>Reset My Password</PageHeader>
      <p>Enter the email address associated with your account</p>
      <PasswordForgotten />
    </SystemPageContent>
  );
};

const mapStateToProps = state => ({
  loggedIn: getAuthState(state).loggedIn
});

export default connect(mapStateToProps)(PasswordForgottenPage);
