import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Icon, Image } from "semantic-ui-react";
import { removeSuppression } from "./actions";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/permissions";
import { migrateCustomers, getMigrationCount } from "./actions";
import MigrateIcon from "assets/migrate.svg";

const BulkMigrateCustomersModal = ({
  selection,
  totalSelected,
  filters,
  pagination
}) => {
  return (
    <ModalBuilder
      loadAction={getMigrationCount}
      loadParams={{ filters, pagination, selection }}
      submitAction={migrateCustomers}
      submitParams={{ filters, pagination, selection }}
      renderTrigger={showModal => (
        <li
          onClick={totalSelected || selection.allSelected ? showModal : null}
          className={totalSelected || selection.allSelected ? "" : "disabled"}
        >
          <Image src={MigrateIcon} />
          Migrate customers
        </li>
      )}
      renderModal={(modalProps, data, state, events) => {
        return (
          <Modal.Confirmation
            {...modalProps}
            header={
              <div>
                <Icon name="warning sign" />
                Migrate Customers
              </div>
            }
            size="tiny"
          >
            <p>
              Are you sure you want to migrate <strong>{data.count}</strong>{" "}
              {data.count === 1 ? "customer" : "customers"}?
            </p>
          </Modal.Confirmation>
        );
      }}
    />
  );
};

export default BulkMigrateCustomersModal;
