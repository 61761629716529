import React from "react";
import { Icon } from "semantic-ui-react";
import { Form } from "@redriver/cinnamon";

const SizeFilterForm = ({ isRevenueEmpty }) => {
  return (
    <Form.Object field="companySizeDetails">
      <div className="radio-field">
        <label>
          Match results to ALL of your criteria or to at least one of your
          criteria?
        </label>
        <div>
          <Form.RadioGroup
            field="match"
            inline
            options={[
              {
                value: "all",
                text: "All"
              },
              {
                value: "one",
                text: "At least one"
              }
            ]}
          />
        </div>
      </div>
      <div className="size-filter-field">
        <label>Revenue (in millions)</label>
        <div className="flex align-center flex-wrap">
          <Form.Numeric
            field="revenueFrom"
            decimalPlaces={2}
            inline
            className="m-r-1"
          />
          <label>to</label>
          <Form.Numeric field="revenueTo" decimalPlaces={2} inline />
        </div>
        <div className="m-l-1 m-t-05 currency-container">
          {" "}
          {!isRevenueEmpty() && (
            <Form.RadioGroup
              field="revenueCurrency"
              inline
              options={[
                {
                  value: "GBP",
                  text: <Icon name="pound" />
                },
                {
                  value: "EUR",
                  text: <Icon name="euro sign" />
                },
                {
                  value: "USD",
                  text: <Icon name="dollar sign" />
                }
              ]}
            />
          )}
        </div>
      </div>
      <div className="size-filter-field">
        <label>Number of employees</label>
        <div className="flex align-center flex-wrap">
          <Form.Numeric
            field="numberEmployeesFrom"
            decimalPlaces={0}
            inline
            className="m-r-1"
          />
          <label>to</label>
          <Form.Numeric field="numberEmployeesTo" decimalPlaces={0} inline />
        </div>
      </div>
      <div className="size-filter-field">
        <label>Number of IT staff</label>
        <div className="flex align-center flex-wrap">
          <Form.Numeric
            field="numberITStaffFrom"
            decimalPlaces={0}
            inline
            className="m-r-1"
          />
          <label>to</label>
          <Form.Numeric field="numberITStaffTo" decimalPlaces={0} inline />
        </div>
      </div>
      <div className="size-filter-field">
        <label>Number of locations</label>
        <div className="flex align-center flex-wrap">
          <Form.Numeric
            field="numberLocationsFrom"
            decimalPlaces={0}
            inline
            className="m-r-1"
          />
          <label>to</label>
          <Form.Numeric field="numberLocationsTo" decimalPlaces={0} inline />
        </div>
      </div>
      <div className="size-filter-field">
        <label>Number of PCs</label>
        <div className="flex align-center flex-wrap">
          <Form.Numeric
            field="numberPCsFrom"
            decimalPlaces={0}
            inline
            className="m-r-1"
          />
          <label>to</label>
          <Form.Numeric field="numberPCsTo" decimalPlaces={0} inline />
        </div>
      </div>
      <div className="size-filter-field">
        <label>Number of servers</label>
        <div className="flex align-center flex-wrap">
          <Form.Numeric
            field="numberServersFrom"
            decimalPlaces={0}
            inline
            className="m-r-1"
          />
          <label>to</label>
          <Form.Numeric field="numberServersTo" decimalPlaces={0} inline />
        </div>
      </div>
    </Form.Object>
  );
};

export default SizeFilterForm;
