import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "USERS/USER_COUNTRIES";

export const ActionTypes = {
  ViewCountries: `${NAMESPACE}/GET_COUNTRIES`,
  UpdateCountries: `${NAMESPACE}/UPDATE_COUNTRIES`
};

export const updateCountries = (data, id) =>
  apiPut(ActionTypes.UpdateCountries, `users/${id}/countries`, data, {
    preventErrorNotification: true
  });
