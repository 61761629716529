import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";
import SelectionCollapseText, { renderTags } from "./SelectionCollapseText";
import TreeViewCriteria from "./TreeViewCriteria";

const CustomFieldsCriteria = ({ active, handleClick, criteria }) => {
  const { included, excluded } = criteria.fieldsSelection.customFields;

  if (!included.length && !excluded.length) return null;

  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Company Custom Fields"
        text={
          <SelectionCollapseText
            included={included}
            excluded={excluded}
            renderItem={renderTags}
          />
        }
      />
      <Accordion.Content active={active}>
        <TreeViewCriteria
          criteria={criteria}
          lookup="customFieldsCriteriaLookup"
        />
      </Accordion.Content>
    </React.Fragment>
  );
};

export default CustomFieldsCriteria;
