import {
  connectForm,
  Field,
  FieldError,
  formUtils,
  formValidators,
  TreeView
} from "@redriver/cinnamon";
import PropTypes from "prop-types";
import { default as React } from "react";
import { Checkbox as SCheckbox, Popup } from "semantic-ui-react";

class CustomCheckboxTree extends React.Component {
  static propTypes = {
    //Modified proptype of value to accept an object value
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    showErrors: PropTypes.bool,
    allErrors: PropTypes.bool,
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    disabled: PropTypes.bool,
    label: PropTypes.node,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fluid: PropTypes.bool,
    required: PropTypes.bool,
    passThruProps: PropTypes.object,
    /**
     * The current state of the form relative to this field (supplied by the form connection)
     * @property {Object} `fields` Form field data, either for the entire form or the current level when Object/Array fields are used
     * @property {Object} `parentFields` Form field data for the parent level when Object/Array fields are used
     * @property {Object} `formFields` Form field data for the entire form
     * @property {number} `arrayIndex` Index of the current row when used inside an Array field
     */
    formState: PropTypes.object,
    /**
     * Array of tree nodes objects in format [{ value: "", text: "", children: [] }]
     * @property {any} `value` Unique key for the node (note that only leaf nodes are actual selectable values in the tree)
     * @property {string} `text` Label for the node checkbox
     * @property {Array} `children` Optional child node objects
     * @property {boolean} `defaultExpanded` Whether the node should be expanded when initially mounted
     * @property {string} `className` Optional classes for styling
     * @property {boolean} `disabled` Whether the node checkbox should be disabled
     */
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.object),
        defaultExpanded: PropTypes.bool,
        className: PropTypes.string,
        disabled: PropTypes.bool
      })
    ).isRequired
  };

  static defaultProps = {
    value: [],
    onChange: () => {},
    label: ""
  };

  hasChildren = node => node.children && node.children.length > 0;

  getLeafValuesRecursive = (node, includeDisabled = false) => {
    if (!this.hasChildren(node)) {
      return includeDisabled || !node.disabled ? [node.value] : [];
    }
    return node.children.reduce((acc, c) => {
      if (this.hasChildren(c)) {
        return [...acc, ...this.getLeafValuesRecursive(c, includeDisabled)];
      } else {
        return includeDisabled || !c.disabled ? [...acc, c.value] : acc;
      }
    }, []);
  };

  getCheckboxState = node => {
    const { included, excluded } = this.props.value;
    const leafValues = this.getLeafValuesRecursive(node);

    const includedArray = included || [];
    const excludedArray = excluded || [];

    const isIncluded = this.getValuesStatus(leafValues, includedArray);
    const isExcluded = this.getValuesStatus(leafValues, excludedArray);

    const includedValues = leafValues.filter(v => includedArray.includes(v));

    const excludedValues = leafValues.filter(v => excludedArray.includes(v));

    if (
      (isExcluded && excludedValues.length < leafValues.length) ||
      (isIncluded && includedValues.length < leafValues.length)
    )
      return 1;
    if (isExcluded) return 3;
    if (isIncluded) return 2;
    if (!isIncluded && !isExcluded) return 0;
    return 2; // all
  };

  getValuesStatus = (values, array) => values.some(el => array.includes(el));

  onChange = node => {
    const { value } = this.props;
    const { included, excluded } = value;
    const leafValues = this.getLeafValuesRecursive(node);
    const includedArray = included || [];
    const excludedArray = excluded || [];
    const isIncluded = this.getValuesStatus(leafValues, includedArray);
    const isExcluded = this.getValuesStatus(leafValues, excludedArray);

    if (!isIncluded && !isExcluded) {
      const missingValues = leafValues.filter(v => !includedArray.includes(v));
      this.props.onChange({
        ...value,
        included: [...includedArray, ...missingValues]
      });
    } else if (isIncluded) {
      const missingValues = leafValues.filter(v => !excludedArray.includes(v));
      const newIncludedValues = includedArray.filter(
        el => !leafValues.includes(el)
      );
      const newExcludedValues = [...excludedArray, ...missingValues];
      this.props.onChange({
        included: newIncludedValues,
        excluded: newExcludedValues
      });
    } else if (isExcluded) {
      const newExcludedValues = excludedArray.filter(
        el => !leafValues.includes(el)
      );
      this.props.onChange({
        ...value,
        excluded: newExcludedValues
      });
    }
  };

  renderNode = (node, state, events) => {
    const { disabled } = this.props;
    const checkboxState = this.getCheckboxState(node);
    return checkboxState === 2 ? (
      <Popup
        trigger={
          <SCheckbox
            indeterminate={checkboxState === 1}
            checked={checkboxState === 2}
            className={checkboxState === 3 ? "excluded" : ""}
            onChange={(e, d) => this.onChange(node)}
            label={node.text}
            value={node.value}
            disabled={disabled || node.disabled}
          />
        }
        content={<p>Click to exclude</p>}
      />
    ) : (
      <SCheckbox
        indeterminate={checkboxState === 1}
        checked={checkboxState === 2}
        className={checkboxState === 3 ? "excluded" : ""}
        onChange={(e, d) => this.onChange(node)}
        label={node.text}
        value={node.value}
        disabled={disabled || node.disabled}
      />
    );
  };

  render() {
    const {
      errors,
      showErrors,
      allErrors,
      animateErrors,
      disabled,
      label,
      width,
      fluid,
      required,
      passThruProps,
      nodes
    } = this.props;

    const otherProps = formUtils.omitProps(
      passThruProps,
      Object.keys(CustomCheckboxTree.propTypes)
    );
    const hasErrors = showErrors && !!errors && errors.length > 0;

    return (
      <Field
        required={required}
        error={hasErrors}
        disabled={disabled}
        width={width}
        fluid={fluid}
      >
        {label && (typeof label === "string" ? <label>{label}</label> : label)}
        <TreeView
          {...otherProps}
          nodes={nodes}
          nodeKey="value"
          renderNode={this.renderNode}
        />
        {showErrors && <FieldError errors={errors} showAll={allErrors} />}
      </Field>
    );
  }
}

export default connectForm({
  displayName: props =>
    props.label && typeof props.label === "string"
      ? props.label
      : formUtils.prettifyField(props.field),
  validators: [formValidators.requiredField(false)]
})(CustomCheckboxTree);
