import { ErrorMessage, Form } from "@redriver/cinnamon";
import React from "react";
import { Button, Icon, Popup } from "semantic-ui-react";
import CsvContext from "./CsvContext";
import HeaderTool from "./Tools/HeaderTool";

class CsvTools extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      formData: {},
      formValid: false
    };
  }

  componentDidMount() {
    let headers = [...this.context.columns];
    headers.forEach((item, index) => {
      item.colIdx = index;
    });
    this.setState(
      { formData: { headers: [...this.context.columns] } },
      this.onFormValidated
    );
  }

  getCellError(rowIdx, colIdx) {
    return this.context.errors.find(e => e.rowIdx == rowIdx && e.idx == colIdx);
  }

  onFormChange = (field, data, applyChanges) => {
    this.setState(
      state => ({
        formData: applyChanges(state.formData)
      }),
      this.onFormValidated
    );
  };

  onFormValidated = _ => {
    let isValid = !this.state.formData.headers.every(h => h.headerType === 0);
    if (isValid && this.context.metaData.actionType === 1) {
      isValid = this.state.formData.headers.some(
        h => h.headerType === 5 || h.headerType === 6
      );
    }
    this.setState({ formValid: isValid });
  };

  onError = error => {
    this.setState({ error: error });
  };

  onFormSubmit = () => {};

  render() {
    const { progressTo } = this.props;
    const { error, formValid, formData } = this.state;
    return (
      <CsvContext.Consumer>
        {context => (
          <Form
            value={formData}
            onChange={this.onFormChange}
            onValidated={this.onFormValidated}
          >
            <section>
              <span>Match Column Headers From Upload</span>
              <span className="headers-required"> * </span>
              <span>
                <Popup
                  content="Please note: If you are leaving the country field blank for some (or all) of the companies in your file, then the match will try to match against any available country. You might therefore end up with unexpected matches on the incorrect country."
                  trigger={<Icon name="info circle info-positive" />}
                />
              </span>
              <ErrorMessage header="" error={error} omitCodes />
              <Form.Array field="headers">
                {({ fields, arrayIndex }) => {
                  return (
                    <div style={{ marginTop: "1rem" }}>
                      <HeaderTool
                        headerType={fields.headerType}
                        error={fields.error}
                        columnKey={fields.key}
                        ignored={fields.ignored}
                        uploadId={this.props.uploadId}
                        processing={context.processing}
                        isMatched={fields.headerType !== 0}
                      />
                    </div>
                  );
                }}
              </Form.Array>
            </section>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.5rem"
              }}
            >
              <Button
                className="secondary-small-button"
                icon="long arrow alternate left"
                content="BACK"
                onClick={() => progressTo(1, false)}
              />
              <Button
                secondary
                className="secondary-small-button"
                icon="long arrow alternate right"
                content="Save &amp; Finish"
                disabled={!formValid || context.processing}
                loading={context.processing}
                onClick={() => {
                  context.onHeaderActions(
                    formData,
                    () => progressTo(3),
                    error => this.onError(error)
                  );
                }}
              />
            </div>
          </Form>
        )}
      </CsvContext.Consumer>
    );
  }
}

CsvTools.contextType = CsvContext;

export default CsvTools;
