import { ListBuilder } from "@redriver/cinnamon";
import React from "react";
import { Button, Grid } from "semantic-ui-react";
import { getUploads } from "./actions";
import FilterUploads from "./FilterUploads";
import UploadCard from "./UploadCard";
import UploadFile from "./UploadFile";

import { UserTypes } from "constants/permissions";
import { PermissionCheck, UserTypeCheck } from "components/auth";

const ListUploads = () => (
  <ListBuilder
    dataTransform={data => data.results}
    totalTransform={data => data.totalResults}
    initialFilters={{
      searchTypes: ["contacts", "companies"],
      actionTypes: ["bulkUpdateTags", "searchCriteria"],
      showAllUploads: "true"
    }}
    initialPagination={{
      pageSize: 9,
      pageNumber: 1
    }}
    loadAction={getUploads}
    renderList={(
      data,
      { filters, pagination, total, hasMore, loading, loadingMore },
      events
    ) => (
      <Grid
        className="i4b-list-page user-page"
        style={{ paddingTop: "0.8rem" }}
      >
        <UserTypeCheck restrictTo={UserTypes.External}>
          <Grid.Row className="p-b-0" columns={1}>
            <Grid.Column stretched>
              <UploadFile />
            </Grid.Column>
          </Grid.Row>
        </UserTypeCheck>

        <Grid.Row className="p-b-0" columns={1}>
          <Grid.Column stretched>
            <FilterUploads
              filters={filters}
              onChangeFilters={events.onChangeFilters}
              pageSize={pagination.pageSize}
              onPageSizeChange={events.onChangePagination}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="p-t-0 m-b-1" columns={1}>
          <Grid.Column stretched>
            <Grid>
              <Grid.Row columns={3}>
                {data.map((a, i) => (
                  <Grid.Column key={a.id}>
                    <UploadCard {...a} onRefresh={events.onRefresh} />
                  </Grid.Column>
                ))}
                {!data.length && !loading && (
                  <p
                    style={{
                      padding: "1em 0",
                      textAlign: "center",
                      width: "100%"
                    }}
                  >
                    No uploads found
                  </p>
                )}
              </Grid.Row>
            </Grid>

            {hasMore && (
              <Button
                className="i4b-notification-list-loader"
                loading={loadingMore}
                onClick={events.onLoadMore}
              >
                {loadingMore ? "Loading more uploads..." : "Show More"}
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )}
  />
);

export default ListUploads;
