import { ClaimCheck } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import { HasClaim } from "./helpers";

const PermissionCheck = ({ target, action, any, not, component, children }) => {
  const Component = component;
  return (
    <ClaimCheck
      claim="permissions"
      value={claimData => {
        const claimExists = () => HasClaim(claimData, target, action, any);
        return not ? !claimExists() : claimExists();
      }}
    >
      {Component ? <Component /> : children}
    </ClaimCheck>
  );
};

PermissionCheck.propTypes = {
  target: PropTypes.string.isRequired,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  any: PropTypes.bool,
  not: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
};

PermissionCheck.defaultProps = {
  any: false,
  not: false
};

export default PermissionCheck;
