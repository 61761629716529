import { getAuthClaims, Table } from "@redriver/cinnamon";
import StarEmpty from "assets/icons/star_empty.svg";
import StarSelected from "assets/icons/star_selected.svg";
import { I4bTable } from "components/tables";
import { Actions, Targets, UserTypes } from "constants/permissions";
import React from "react";
import { connect } from "react-redux";
import { Icon, Image, Popup } from "semantic-ui-react";
import {
  appendViewedCompanyId,
  changeFollowing,
  changeSelection,
  getCompanies,
  getCompanyById,
  removeFromViewedCompanyList,
  setExpandedRows
} from "./actions";
import CompaniesResultsTableRowContent from "./CompaniesResultsTableRowContent";
import ExpandedRowContent from "./ExpandedRowContent";
import { getCompaniesSearchState } from "./selectors";

class CompaniesResultsTable extends React.Component {
  state = {
    columnCount: 6
  };

  componentDidMount() {
    this.calculateColumnCount();
  }

  calculateColumnCount = () => {
    const { listId, canEditCustomerWatchLists, isInternal } = this.props;

    let { columnCount } = this.state;

    if (listId) columnCount -= 1; // matches !listId check below

    if (listId || isInternal || !canEditCustomerWatchLists) columnCount -= 1; // matches permissions + user type check + !listId checks below

    this.setState({ columnCount });
  };

  render() {
    const {
      companies,
      companiesLoading,
      dimmerLoading,
      changeSelection,
      changeFollowing,
      selection,
      listId,
      getCompanies,
      viewUniverse,
      isInternal,
      canEditCustomerWatchLists,
      expandedRows,
      setExpandedRows,
      allowTagAdditions,
      viewedCompanyIds,
      getCompanyById,
      appendViewedCompanyId,
      removeFromViewedCompanyList
    } = this.props;

    const { columnCount } = this.state;

    return (
      <div className="table-container">
        <I4bTable
          data={companies ? companies.results : []}
          dataKey="id"
          columns={columnCount}
          selectable={!listId}
          basic="very"
          loading={companiesLoading}
          dimmerLoading={dimmerLoading}
          fixed
          expandRows="onClick"
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          animateExpandedRows={false}
          onRowExpand={id => {
            if (!viewedCompanyIds.includes(id)) {
              getCompanyById(id);
              appendViewedCompanyId(id);
            }
          }}
          onRowCollapse={id => {
            removeFromViewedCompanyList(id);
          }}
          renderExpandedRow={(item, state, events) => (
            <ExpandedRowContent
              item={item}
              collapse={events.onCollapse}
              onSubmitted={() => getCompanies(true, true, false, true)}
              viewUniverse={viewUniverse}
              allowTagAdditions={allowTagAdditions}
            />
          )}
          emptyMessage="No results found"
        >
          {(item, state, events) => {
            return (
              <React.Fragment>
                {!listId && (
                  <React.Fragment>
                    {!isInternal && canEditCustomerWatchLists && (
                      <Table.Column
                        field="follow"
                        title={null}
                        className="follow-column"
                        render={item =>
                          item.followingUpdating ? (
                            <Icon
                              name={item.following ? "star" : "star outline"}
                              loading={true}
                              color={item.following ? "yellow" : "grey"}
                            />
                          ) : (
                            <Popup
                              trigger={
                                <Image
                                  src={
                                    item.following ? StarSelected : StarEmpty
                                  }
                                  onClick={event => {
                                    // Prevent the row click event from expanding row
                                    event.stopPropagation();
                                    changeFollowing([item], item.following);
                                  }}
                                />
                              }
                              content={item.following ? "Followed" : "Follow"}
                              position="top center"
                              className="table-popup"
                            />
                          )
                        }
                      />
                    )}

                    <Table.SelectableColumn
                      value={selection}
                      totalItems={companies ? companies.totalResults : 0}
                      header="all"
                      onChange={changeSelection}
                    />
                  </React.Fragment>
                )}
                <CompaniesResultsTableRowContent
                  item={item}
                  state={state}
                  events={events}
                  listId={listId}
                  viewUniverse={viewUniverse}
                />
              </React.Fragment>
            );
          }}
        </I4bTable>
      </div>
    );
  }
}

const actions = {
  changeSelection,
  changeFollowing,
  getCompanies,
  setExpandedRows,
  getCompanyById,
  appendViewedCompanyId,
  removeFromViewedCompanyList
};

const mapStateToProps = state => {
  const {
    companies,
    companiesLoading,
    dimmerLoading,
    pageNumber,
    pageSize,
    selection,
    expandedRows,
    isUniverse,
    viewedCompanyIds
  } = getCompaniesSearchState(state);
  const { permissions, userType } = getAuthClaims(state);

  return {
    companies,
    companiesLoading,
    dimmerLoading,
    pageNumber,
    pageSize,
    selection,
    canEditCustomerWatchLists:
      permissions && permissions[Targets.CustomerWatchLists] == Actions.Edit,
    isInternal: userType && userType == UserTypes.Internal,
    viewUniverse: isUniverse,
    expandedRows,
    viewedCompanyIds,
    allowTagAdditions:
      permissions &&
      permissions.hasOwnProperty(Targets.ItemTags) &&
      (Array.isArray(permissions[Targets.ItemTags])
        ? permissions[Targets.ItemTags].some(p => p == Actions.Create)
        : permissions[Targets.ItemTags] == Actions.Create)
  };
};

export default connect(
  mapStateToProps,
  actions
)(CompaniesResultsTable);
