import React from "react";
import { withRouter } from "react-router-dom";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";

import { validateTokens, updatePassword } from "./actions";
import { SystemRoutes } from "constants/routes";

const UserReset = ({ verifyToken, pwToken, history }) => (
  <FormBuilder
    loadAction={validateTokens}
    loadParams={{ emailToken: verifyToken, pwToken }}
    submitAction={(data, _) => updatePassword(pwToken, data.newPassword)}
    onSubmitted={() => history.push(SystemRoutes.Login)}
    renderError={e => <ErrorMessage error={e} />}
    renderForm={(formProps, state, events) => (
      <Form {...formProps}>
        <Form.Password
          fluid
          field="newPassword"
          label="New password"
          icon="lock"
          iconPosition="left"
          placeholder="New password"
          autoFocus
          required
          minLength={6}
        />
        <Form.Password
          fluid
          field="confirmPassword"
          label="Confirm password"
          icon="lock"
          iconPosition="left"
          placeholder="Confirm password"
          required
          confirmField="newPassword"
        />
        <Button
          fluid
          primary
          onClick={events.onSubmit}
          className="system-button"
        >
          Submit
        </Button>
      </Form>
    )}
  />
);

export default withRouter(UserReset);
