import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";

const LanguagesCriteria = ({ active, handleClick, criteria }) => {
  const { selectedLanguages } = criteria;

  if (!selectedLanguages.length) return null;

  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Languages"
        text={selectedLanguages.join(", ")}
      />
      <Accordion.Content active={active}>
        <ul className="p-t-0 p-b-05 p-r-0 p-l-1 m-y-0">
          {selectedLanguages.map(lang => (
            <li key={lang}>{lang}</li>
          ))}
        </ul>
      </Accordion.Content>
    </React.Fragment>
  );
};

export default LanguagesCriteria;
