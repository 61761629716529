import React from "react";
import { Format } from "@redriver/cinnamon";

const getPageMin = (pageSize, pageNumber) =>
  pageSize * pageNumber - (pageSize - 1);

const getPageMax = (pageSize, pageNumber, total) => {
  const pageTotal = pageSize * pageNumber;
  return total < pageTotal ? total : pageTotal;
};

const SearchResultCounter = ({
  className = "",
  pageSize,
  pageNumber,
  totalResults
}) =>
  totalResults ? (
    <label className={`search-results` + className}>
      <React.Fragment>
        Showing <Format.Number value={getPageMin(pageSize, pageNumber)} /> -{" "}
        <Format.Number value={getPageMax(pageSize, pageNumber, totalResults)} />{" "}
        of <Format.Number value={totalResults} /> result
        {totalResults == 1 ? "" : "s"}
      </React.Fragment>
    </label>
  ) : (
    ""
  );

export default SearchResultCounter;
