import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Form, ErrorMessage } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import { validateSetupToken, requestUserSetup } from "./actions";
import { getUserSetupState } from "../selectors";

class UserSetup extends React.Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    onValidateToken: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validatingToken: PropTypes.bool,
    validationSlow: PropTypes.bool,
    tokenCorrect: PropTypes.bool,
    settingUp: PropTypes.bool,
    slowRequest: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.any
  };

  state = {
    newPassword: "",
    confirmPassword: "",
    validated: false,
    showErrors: false
  };

  componentWillMount = () => {
    if (this.props.token) {
      this.props.onValidateToken(this.props.token);
    }
  };

  onFormChange = (field, data) => this.setState({ [field]: data });

  onFormValidated = validated => this.setState({ validated });

  resetPassword = e => {
    e.preventDefault();
    if (!this.state.validated) {
      this.setState({ showErrors: true });
      return;
    }
    const { settingUp, token } = this.props;
    if (settingUp) return;
    const { newPassword } = this.state;
    this.props.onSubmit(token, newPassword);
  };

  render() {
    const {
      token,
      validatingToken,
      validationSlow,
      tokenCorrect,
      success
    } = this.props;

    if (!token) {
      return <p>No setup token supplied</p>;
    }

    if (validatingToken) {
      return validationSlow ? <p>Validating setup token...</p> : null;
    }

    if (!tokenCorrect) {
      return <p>The setup token supplied is invalid</p>;
    }

    if (success) {
      return (
        <div>
          <p>Your account has been setup</p>
          <div style={{ marginTop: 20 }}>
            <Button fluid primary as={Link} to={SystemRoutes.Login}>
              Login
            </Button>
          </div>
        </div>
      );
    }

    const { settingUp, slowRequest, error } = this.props;
    const isBusy = settingUp && slowRequest;

    return (
      <Form
        value={this.state}
        onChange={this.onFormChange}
        onValidated={this.onFormValidated}
        showErrors={this.state.showErrors}
      >
        <Form.Password
          fluid
          field="newPassword"
          label="New Password"
          placeholder="New password"
          icon="lock"
          iconPosition="left"
          disabled={isBusy}
          autoFocus
          required
          minLength={6}
        />
        <Form.Password
          fluid
          field="confirmPassword"
          label="Confirm Password"
          placeholder="Confirm password"
          icon="lock"
          iconPosition="left"
          disabled={isBusy}
          required
          confirmField="newPassword"
        />
        <Button
          fluid
          primary
          onClick={this.resetPassword}
          disabled={isBusy}
          loading={isBusy}
        >
          Submit
        </Button>
        <ErrorMessage error={error} />
      </Form>
    );
  }
}

const actions = {
  onValidateToken: validateSetupToken,
  onSubmit: requestUserSetup
};

const mapStateToProps = state => {
  const userSetupState = getUserSetupState(state);
  return {
    validatingToken: userSetupState.validatingToken,
    validationSlow: userSetupState.validationSlow,
    tokenCorrect: userSetupState.tokenCorrect,
    settingUp: userSetupState.settingUp,
    slowRequest: userSetupState.slowRequest,
    success: userSetupState.success,
    error: userSetupState.error
  };
};

export default connect(
  mapStateToProps,
  actions
)(UserSetup);
