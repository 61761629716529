import moment from "moment";
import { ActionTypes as NotificationActions } from "./features/Notifications/NotificationNavMenuItem/actions";
import { REFRESH_JWT as RefreshJwtAction } from "./features/System/Login/actions";
import { REQUEST_LOGOUT as LogoutAction } from "./features/System/Logout/actions";

const activeActionMiddleware = store => next => action => {
  // Consider user still active for any request except refresh notifications

  if (
    action.url &&
    action.requestType &&
    action.requestType != NotificationActions.RefreshNotifications &&
    action.requestType != LogoutAction &&
    action.requestType != RefreshJwtAction
  ) {
    localStorage.setItem("lastAction", moment().format());
  }

  // If a promise (api response), do not handle
  if (typeof action.then == "function") return action;

  return next(action);
};

export default activeActionMiddleware;
