import { apiPut, registerLookup } from "@redriver/cinnamon";

const NAMESPACE = "SEARCH/FILTERS/LOOKUPS";

const ActionTypes = {
  GetCountries: `${NAMESPACE}/GET_COUNTRIES`,
  GetPostalAreas: `${NAMESPACE}/GET_POSTAL_AREAS`,
  GetStockExchangeIndexes: `${NAMESPACE}/GET_SE_INDEXES`,
  GetIndustries: `${NAMESPACE}/GET_INDUSTRIES`,
  GetContactTags: `${NAMESPACE}/GET_CONTACT_TAGS`,
  GetCustomFields: `${NAMESPACE}/GET_CUSTOM_FIELDS`,
  GetLanguages: `${NAMESPACE}/GET_LANGUAGES`,
  GetTechRoles: `${NAMESPACE}/GET_TECH_ROLES`,
  GetLOBRoles: `${NAMESPACE}/GET_LOB_ROLES`,
  GetLists: `${NAMESPACE}/GET_LISTS`,
  GetUploads: `${NAMESPACE}/GET_UPLOADS`
};

registerLookup("countriesCriteriaLookup", criteria =>
  apiPut(ActionTypes.GetCountries, "lookups/countries", criteria)
);

registerLookup("postalAreasCriteriaLookup", criteria =>
  apiPut(ActionTypes.GetPostalAreas, "lookups/postal-areas/criteria", criteria)
);

registerLookup("techRolesCriteriaLookup", criteria =>
  apiPut(ActionTypes.GetTechRoles, "lookups/tech-roles", criteria)
);

registerLookup("industriesCriteriaLookup", criteria =>
  apiPut(ActionTypes.GetIndustries, "lookups/industries", criteria)
);

registerLookup("stockExchangeIndexesCriteriaLookup", criteria =>
  apiPut(
    ActionTypes.GetStockExchangeIndexes,
    "lookups/stock-exchange-indexes",
    criteria
  )
);

registerLookup("lobRolesCriteriaLookup", criteria =>
  apiPut(ActionTypes.GetLOBRoles, "lookups/lob-roles", criteria)
);

registerLookup("contactTagsCriteriaLookup", criteria =>
  apiPut(ActionTypes.GetLOBRoles, "lookups/contact-tags", criteria)
);

registerLookup("customFieldsCriteriaLookup", criteria =>
  apiPut(ActionTypes.GetLOBRoles, "lookups/custom-fields", criteria)
);

registerLookup("listsCriteriaLookup", criteria =>
  apiPut(ActionTypes.GetLists, "lookups/lists/criteria", criteria)
);

registerLookup("contactsInCompanyListsCriteria", criteria =>
  apiPut(
    ActionTypes.GetLists,
    "lookups/lists/contacts-in-company-lists-criteria",
    criteria
  )
);

registerLookup("uploadsCriteriaLookup", criteria =>
  apiPut(ActionTypes.GetUploads, "lookups/uploads/criteria", criteria)
);
