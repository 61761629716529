import React from "react";
import { ActionBar, Form, FormBuilder, PageSize } from "@redriver/cinnamon";

import CreateUser from "./CreateUser";

const FilterUsers = ({
  filters,
  onChangeFilters,
  onCreateUser,
  pageSize,
  onPageSizeChange,
  userType,
  customerId,
  requireCustomer
}) => (
  <ActionBar>
    <ActionBar.Item fluid>
      <FormBuilder
        initialData={filters}
        onChange={onChangeFilters}
        renderForm={formProps => (
          <Form {...formProps}>
            <Form.Group inline compact>
              <Form.Input
                field="search"
                placeholder="Search user's name or email…"
                width={25}
              />
            </Form.Group>
          </Form>
        )}
      />
    </ActionBar.Item>
    <ActionBar.Item align="right">
      <CreateUser
        onSubmitted={onCreateUser}
        userType={userType}
        customerId={customerId}
        requireCustomer={requireCustomer}
      />
    </ActionBar.Item>
    {pageSize && (
      <ActionBar.Item className="i4b-filter-pagesize">
        <PageSize
          value={pageSize}
          onChange={x => onPageSizeChange({ pageSize: x, pageNumber: 1 })}
          options={[10, 25, 50, 100]}
        />
      </ActionBar.Item>
    )}
  </ActionBar>
);

export default FilterUsers;
