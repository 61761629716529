import { AppRoutes } from "constants/routes";
import React from "react";
import { Route, Switch } from "react-router-dom";
import SegmentRemovalPage from "./SegmentRemovalPage";

export default () => (
  <React.Fragment>
    <Switch>
      <Route
        exact
        path={AppRoutes.SegmentRemoval}
        component={SegmentRemovalPage}
      />
    </Switch>
  </React.Fragment>
);
