import { getAuthClaims, Table } from "@redriver/cinnamon";
import StarEmpty from "assets/icons/star_empty.svg";
import StarSelected from "assets/icons/star_selected.svg";
import { I4bTable } from "components/tables";
import { Actions, Targets, UserTypes } from "constants/permissions";
import { getViewCompanyState } from "features/Companies/ViewCompany/selectors";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Icon, Image, Popup } from "semantic-ui-react";
import { setExpandedRows } from "./actions";
import ContactsResultsTableRowContent from "./ContactsResultsTableRowContent";
import ExpandedRowContent from "./ExpandedRowContent";
import { getContactsSearchState } from "./selectors";
class ContactsTable extends React.Component {
  state = {
    columnCount: 7
  };

  componentDidMount() {
    this.calculateColumnCount();
  }

  calculateColumnCount = () => {
    const {
      listId,
      canEditCustomerWatchLists,
      isInternal,
      hideCompany
    } = this.props;

    let { columnCount } = this.state;

    if (listId) columnCount -= 1; // matches !listId check below

    if (listId || isInternal || !canEditCustomerWatchLists) columnCount -= 1; // matches permissions + user type check + !listId checks below

    if (hideCompany) columnCount -= 1; // Match !hideCompany check below

    this.setState({ columnCount });
  };

  render() {
    const {
      contacts,
      totalResults,
      contactsLoading,
      dimmerLoading,
      changeSelection,
      changeFollowing,
      selection,
      updateSearchFilter,
      listId,
      className,
      hideCompany,
      isCompanyContactsPage,
      getContacts,
      getContactDetails,
      deleteTag,
      contactDetails,
      tableKey,
      viewingUniverse,
      isInternal,
      canEditCustomerWatchLists,
      expandedRows,
      setExpandedRows,
      onSubmitted,
      allowTagAdditions
    } = this.props;

    const { columnCount } = this.state;

    return (
      <div className={`table-container ${className}`}>
        <I4bTable
          key={tableKey}
          data={contacts}
          dataKey="id"
          columns={columnCount}
          selectable={!listId && !isCompanyContactsPage}
          basic="very"
          loading={contactsLoading}
          dimmerLoading={dimmerLoading}
          animateExpandedRows={false}
          expandRows={viewingUniverse ? "none" : "onClick"}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          renderExpandedRow={(item, state, events) =>
            !viewingUniverse && (
              <ExpandedRowContent
                details={contactDetails[item.id]}
                contactsLoading={contactsLoading}
                collapse={events.onCollapse}
                updateSearchFilter={updateSearchFilter}
                onSubmitted={onSubmitted}
                getContactDetails={() => getContactDetails(item.id)}
                deleteTag={deleteTag}
                contactId={item.id}
                isCompanyContactsPage={isCompanyContactsPage}
                allowTagAdditions={allowTagAdditions}
                leftCompany={item.leftCompany}
              />
            )
          }
          emptyMessage="No results found"
        >
          {(item, state, events) => {
            return (
              <React.Fragment>
                {!listId && (
                  <React.Fragment>
                    {!isInternal && canEditCustomerWatchLists && (
                      <Table.Column
                        field="follow"
                        title={null}
                        className="follow-column"
                        render={item =>
                          item.followingUpdating ? (
                            <Icon
                              name={item.following ? "star" : "star outline"}
                              loading={true}
                              color={item.following ? "yellow" : "grey"}
                            />
                          ) : (
                            <Popup
                              trigger={
                                <Image
                                  src={
                                    item.following ? StarSelected : StarEmpty
                                  }
                                  onClick={event => {
                                    // Prevent the row click event from expanding row
                                    event.stopPropagation();
                                    this.props.changeFollowing(
                                      [item],
                                      item.following
                                    );
                                  }}
                                />
                              }
                              content={item.following ? "Followed" : "Follow"}
                              position="top center"
                              className="table-popup"
                            />
                          )
                        }
                      />
                    )}
                  </React.Fragment>
                )}
                <Table.SelectableColumn
                  value={selection}
                  totalItems={totalResults}
                  header="all"
                  onChange={changeSelection}
                />
                <ContactsResultsTableRowContent
                  item={item}
                  state={state}
                  events={events}
                  listId={listId}
                  hideCompany={hideCompany}
                  changeSelection={changeSelection}
                  viewingUniverse={viewingUniverse}
                />
              </React.Fragment>
            );
          }}
        </I4bTable>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { permissions, userType } = getAuthClaims(state);
  const { expandedRows } = getContactsSearchState(state);
  const { contactDetails, tableKey } = props.isCompanyContactsPage
    ? getViewCompanyState(state)
    : getContactsSearchState(state);

  return {
    canEditCustomerWatchLists:
      permissions && permissions[Targets.CustomerWatchLists] == Actions.Edit,
    isInternal: userType && userType == UserTypes.Internal,
    contactDetails,
    tableKey,
    expandedRows,
    allowTagAdditions:
      permissions &&
      permissions.hasOwnProperty(Targets.ItemTags) &&
      (Array.isArray(permissions[Targets.ItemTags])
        ? permissions[Targets.ItemTags].some(p => p == Actions.Create)
        : permissions[Targets.ItemTags] == Actions.Create)
  };
};

const actions = {
  setExpandedRows
};

ContactsTable.propTypes = {
  contacts: PropTypes.array,
  totalResults: PropTypes.number,
  contactsLoading: PropTypes.bool,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  selection: PropTypes.object,
  changeSelection: PropTypes.func,
  changeFollowing: PropTypes.func,
  getContactDetails: PropTypes.func
};

export default connect(
  mapStateToProps,
  actions
)(ContactsTable);
