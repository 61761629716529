import React from "react";
import { Grid } from "semantic-ui-react";
import { Format } from "@redriver/cinnamon";

import NotificationCard from "./NotificationCard";

const NotificationsDaySection = ({ date, items, onRefresh }) => (
  <Grid.Row>
    <Grid.Column width={1}>
      <label className="notification-date-day">
        <Format.Date value={date} format="D" />
      </label>
      <label className="notification-date-month-year">
        <Format.Date value={date} format="MMM YY" />
      </label>
    </Grid.Column>
    <Grid.Column style={{ flex: 1 }}>
      {items.map(i => (
        <NotificationCard key={i.id} {...i} onRefresh={onRefresh} />
      ))}
    </Grid.Column>
  </Grid.Row>
);

export default NotificationsDaySection;
