import { apiGet, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "CONTACTS/OPT_OUT";

export const ActionTypes = {
  DownloadOptOut: `${NAMESPACE}/DOWNLOAD_OPT_OUT`,
  LoadToken: `${NAMESPACE}/LOAD_TOKEN`,
  DownloadLeftContact: `${NAMESPACE}/DOWNLOAD_LEFT_CONTACT`,
  LoadLeftContactToken: `${NAMESPACE}/LOAD_LEFT_CONTACT_TOKEN`,
  CanDownloadLeftContact: `${NAMESPACE}/CAN_DOWNLOAD_LEFT_CONTACT`,
  TriggerLeftContactDownload: `${NAMESPACE}/TRIGGER_LEFT_CONTACT_DOWNLOAD`
};

export const downloadOptOut = tokenId =>
  apiPost(ActionTypes.DownloadOptOut, `contact-opt-out/${tokenId}`, null);
export const loadToken = tokenId =>
  apiGet(ActionTypes.LoadToken, `contact-opt-out/${tokenId}`);

export const downloadLeftContact = tokenId => async dispatch => {
  return await dispatch(
    apiPost(ActionTypes.DownloadLeftContact, `left-contact/${tokenId}`, null)
  );
};
export const loadLeftContactToken = tokenId =>
  apiGet(ActionTypes.LoadLeftContactToken, `left-contact/${tokenId}`);
export const canDownloadLeftContact = tokenId =>
  apiGet(
    ActionTypes.CanDownloadLeftContact,
    `left-contact/${tokenId}/can-download`
  );
export const triggerLeftContactDownload = tokenId =>
  apiPost(
    ActionTypes.TriggerLeftContactDownload,
    `left-contact/${tokenId}/trigger-download`
  );
