import React from "react";
import PropTypes from "prop-types";
import { Table as STable, Transition } from "semantic-ui-react";
import classNames from "classnames";

/**
 * Renders an expanded row for a table data item
 */
class ExpandedRow extends React.Component {
  static propType = {
    /**
     * Row item data to be rendered
     */
    item: PropTypes.shape({
      className: PropTypes.string
    }),
    /**
     * Unique key value for row item
     */
    itemKey: PropTypes.any,
    /**
     * Row index for the item
     */
    itemIndex: PropTypes.number,
    /**
     * Custom render function for the expanded row content
     * @param {Object} `item` The current item being rendered
     * @param {Object} `state` An object representing the current state
     * @param {*} `state.itemKey` Unique key value for row item
     * @param {number} `state.itemIndex` Row index for the item
     * @param {Object} `events` An object containing useful callbacks that can be triggered
     * @param {function} `events.onCollapse` Collapses the row
     */
    render: PropTypes.func.isRequired,
    /**
     * Number of columns that this row should span
     */
    columnSpan: PropTypes.number,
    /**
     * Callback when the row is collapsed
     * @param {*} `itemKey` The row item key
     */
    onCollapse: PropTypes.func,
    /**
     * Whether the expanded row is currently visible
     */
    visible: PropTypes.bool,
    /**
     * Time in milliseconds for the expanded row animation transition, or false to disable
     */
    animate: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
  };

  onRowCollapse = () => {
    const { itemKey, onCollapse } = this.props;
    if (onCollapse) onCollapse(itemKey);
  };

  render() {
    const {
      item,
      itemKey,
      itemIndex,
      columnSpan,
      render,
      visible,
      animate
    } = this.props;

    const renderArgs = [
      item,
      { itemKey, itemIndex },
      {
        onCollapse: this.onRowCollapse
      }
    ];

    const content = visible && (
      <STable.Row className={classNames(item.className, "expanded-row")}>
        <STable.Cell colSpan={columnSpan}>{render(...renderArgs)}</STable.Cell>
      </STable.Row>
    );

    return typeof animate === "number" && animate > 0 ? (
      <Transition.Group
        as={React.Fragment}
        animation="fade down"
        duration={animate}
      >
        {content}
      </Transition.Group>
    ) : (
      content
    );
  }
}

export default ExpandedRow;
