import { Form, Format, getAuthClaims, Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { PageNumber } from "components/pagination";
import { DownloadButton } from "components/search";
import { Actions, Targets, UserTypes } from "constants/permissions";
import React from "react";
import { connect } from "react-redux";
import { Checkbox, Dimmer, Icon, Loader } from "semantic-ui-react";
import {
  changeSelectAll,
  clearSelection,
  getCompanyDownload,
  getContactDetails,
  getContactDownload,
  getDownloadCount,
  getResults,
  loadOverview,
  onChangeFollowing,
  onChangeSelection,
  updateHeaderForm,
  updateNameSearch,
  updatePageNumber
} from "./actions";
import ListsActionsDropdown from "./ListsActionsDropdown";
import { getListsOverviewState } from "./selectors";
import TableGroup from "./TableGroup";

class ListResults extends React.Component {
  componentDidMount() {
    this.props.getResults(null, true);
  }

  getResultsDisplayNumber = (totalResults, pageSize, pageNumber) => {
    if (totalResults <= pageSize) {
      return (
        <React.Fragment>
          {"1 - "}
          <Format.Number value={totalResults} />
        </React.Fragment>
      );
    } else {
      const start = pageNumber * pageSize - pageSize + 1;
      const end = Math.min(start + pageSize - 1, totalResults);
      return (
        <React.Fragment>
          <Format.Number value={start} />
          {" - "}
          <Format.Number value={end} />
        </React.Fragment>
      );
    }
  };

  renderDisplayingCount() {
    const { headerForm, totalResults, ungrouped } = this.props;

    // Unless it is a valid show all, don't render the count
    if (headerForm.showGroups || !totalResults || !ungrouped) return null;
    return (
      <label>
        Displaying{" "}
        {this.getResultsDisplayNumber(
          totalResults,
          ungrouped.pageSize,
          ungrouped.pageNumber
        )}
      </label>
    );
  }

  render() {
    const {
      updatePageNumber,
      updateHeaderForm,
      headerForm,
      totalResults,
      isContactList,
      loading,
      dimmerLoading,
      groups,
      ungrouped,
      selection,
      selectedItems,
      onChangeSelection,
      onChangeFollowing,
      changeSelectAll,
      getResults,
      clearSelection,
      listId,
      formData,
      loadOverview,
      getContactDetails,
      contactDetails,
      getDownloadCount,
      getContactDownload,
      getCompanyDownload,
      hasStatus,
      isInternal,
      canEditCustomerWatchLists,
      allowTagAdditions,
      updateNameSearch,
      isSegmented,
      name
    } = this.props;

    const viewingUniverse =
      formData.downloadable === "false" &&
      (formData.downloadableReason === undefined ||
        formData.downloadableReason == 2);
    return (
      <React.Fragment>
        <header className="flex space-between">
          <div style={{ width: "70%" }}>
            <div className=" flex align-center m-b-05">
              <h1>Results</h1>
            </div>
            <div className="flex space-between">
              <h3 className="m-r-1">
                {totalResults > 0 && totalResults !== null && (
                  <React.Fragment>
                    <Format.Number value={totalResults} />
                    {!isContactList ? ` Companies` : ` Contacts`}
                  </React.Fragment>
                )}
              </h3>
            </div>
            <div>{this.renderDisplayingCount()}</div>
            <div className="flex space-between align-center">
              <Checkbox
                label="Select All"
                style={{ margin: "1rem 0rem" }}
                checked={selection.allSelected && !selection.deselected.length}
                indeterminate={
                  selection.allSelected && !!selection.deselected.length
                }
                onClick={e => {
                  e.stopPropagation();
                  changeSelectAll(!selection.allSelected);
                }}
                onChange={e => {
                  // Prevent a double fire of onClick
                }}
              />

              {hasStatus && (
                <Form
                  value={headerForm}
                  onChange={(f, d, applyChanges) =>
                    updateHeaderForm(applyChanges)
                  }
                >
                  <Form.Checkbox
                    field="showGroups"
                    label={
                      <label
                        style={{
                          marginLeft: "1em"
                        }}
                      >
                        <h3>Group Results by Status</h3>
                      </label>
                    }
                    toggle
                  />
                </Form>
              )}
            </div>
          </div>
          <div className="i4b-search-actions-wrapper">
            <PermissionCheck
              target={isContactList ? Targets.Contacts : Targets.Company}
              action={Actions.Download}
            >
              <DownloadButton
                loadCount={getDownloadCount}
                download={
                  isContactList ? getContactDownload : getCompanyDownload
                }
                isContact={isContactList}
                disabled={formData.downloadable === "false"}
                isPreview={false}
                listName={name}
              />
            </PermissionCheck>
            <ListsActionsDropdown
              selectedItems={selectedItems}
              selection={selection}
              isContactList={isContactList}
              onSubmitted={() => {
                clearSelection();
                getResults(null, false, true);
              }}
              onRemoved={() => {
                clearSelection();
                loadOverview(listId);
                getResults(null, false, true);
              }}
              listId={listId}
              allowTagAdditions={allowTagAdditions}
              isSegmented={isSegmented}
              viewingUniverse={viewingUniverse}
              formData={formData}
            />
          </div>
        </header>
        <div className="table-container">
          <Dimmer.Dimmable
            dimmed={dimmerLoading}
            className="i4table-dimmable-container"
          >
            <Dimmer
              active={dimmerLoading}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              className="i4btable-dimmer"
            >
              <Loader size="big" active inline="centered" />
            </Dimmer>
            {headerForm.showGroups ? (
              <Table
                data={groups.filter(g => g.results.length)}
                dataKey="id"
                noHeader
                loading={loading}
                columns={1}
                basic="very"
                expandRows="onClick"
                animateExpandedRows={false}
                className="without-hover-state table-layout-auto"
                renderExpandedRow={(item, state, events) => (
                  <TableGroup
                    item={item}
                    loading={loading}
                    isContactList={isContactList}
                    isGrouped
                    updatePageNumber={pageNumber =>
                      updatePageNumber(pageNumber, 10, item.id)
                    }
                    selection={selection}
                    onChangeSelection={onChangeSelection}
                    onChangeFollowing={onChangeFollowing}
                    onSubmitted={() => getResults(groups, false, true)}
                    updateNameSearch={updateNameSearch}
                    isInternal={isInternal}
                    canEditCustomerWatchLists={canEditCustomerWatchLists}
                    getContactDetails={getContactDetails}
                    details={contactDetails}
                    viewingUniverse={viewingUniverse}
                    allowTagAdditions={allowTagAdditions}
                  />
                )}
                emptyMessage="No results found"
              >
                {(item, state, events) => (
                  <React.Fragment>
                    <Table.Column
                      field="name"
                      width="100%"
                      className="list-table-header"
                      render={item => (
                        <div className="flex space-between">
                          <span
                            className={state.expanded ? "purple-bold" : null}
                          >
                            {item.name} ({item.totalResults})
                          </span>
                          {!(isContactList && viewingUniverse) && (
                            <Icon
                              name={state.expanded ? "angle up" : "angle down"}
                            />
                          )}
                        </div>
                      )}
                    />
                  </React.Fragment>
                )}
              </Table>
            ) : (
              <React.Fragment>
                <TableGroup
                  item={ungrouped}
                  loading={loading}
                  isContactList={isContactList}
                  selection={selection}
                  onChangeSelection={onChangeSelection}
                  onChangeFollowing={onChangeFollowing}
                  onSubmitted={() =>
                    getResults(
                      [{ id: "ungrouped", pageSize: 200, pageNumber: 1 }],
                      false,
                      true
                    )
                  }
                  updateNameSearch={updateNameSearch}
                  isInternal={isInternal}
                  canEditCustomerWatchLists={canEditCustomerWatchLists}
                  getContactDetails={getContactDetails}
                  details={contactDetails}
                  viewingUniverse={viewingUniverse}
                  allowTagAdditions={allowTagAdditions}
                />
              </React.Fragment>
            )}
          </Dimmer.Dimmable>
        </div>

        {!headerForm.showGroups && (
          <div className="table-group-pagination m-t-1 p-b-2">
            <div>
              <PageNumber
                value={ungrouped.pageNumber}
                onChange={pageNumber =>
                  updatePageNumber(pageNumber, 200, ungrouped.id)
                }
                totalItems={Math.min(totalResults || 0, 10000)}
                pageSize={ungrouped.pageSize}
              />
            </div>
            {ungrouped.pageNumber == 50 && (
              <div>
                <small>
                  Paged results limited to 10k, please refine your search
                </small>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const actions = {
  getResults,
  updatePageNumber,
  updateHeaderForm,
  onChangeSelection,
  onChangeFollowing,
  changeSelectAll,
  loadOverview,
  clearSelection,
  getContactDetails,
  getDownloadCount,
  getContactDownload,
  getCompanyDownload,
  updateNameSearch
};

const mapStateToProps = state => {
  const {
    name,
    groups,
    ungrouped,
    loading,
    dimmerLoading,
    totalResults,
    headerForm,
    selection,
    totalWithEmail,
    totalWithoutEmail,
    totalWithPhone,
    totalWithoutPhone,
    selectedItems,
    listId,
    contactDetails,
    formData,
    isSegmented
  } = getListsOverviewState(state);
  const { permissions, userType } = getAuthClaims(state);
  return {
    name,
    groups,
    loading,
    dimmerLoading,
    ungrouped,
    totalResults,
    headerForm,
    selection,
    totalWithEmail,
    totalWithoutEmail,
    totalWithPhone,
    totalWithoutPhone,
    selectedItems,
    listId,
    contactDetails,
    formData,
    isSegmented,
    isInternal: userType && userType == UserTypes.Internal,
    canEditCustomerWatchLists:
      permissions &&
      permissions.hasOwnProperty(Targets.CustomerWatchLists) &&
      (Array.isArray(permissions[Targets.CustomerWatchLists])
        ? permissions[Targets.CustomerWatchLists].some(p => p == Actions.Edit)
        : permissions[Targets.CustomerWatchLists] == Actions.Edit),
    allowTagAdditions:
      permissions &&
      permissions.hasOwnProperty(Targets.ItemTags) &&
      (Array.isArray(permissions[Targets.ItemTags])
        ? permissions[Targets.ItemTags].some(p => p == Actions.Create)
        : permissions[Targets.ItemTags] == Actions.Create)
  };
};

export default connect(
  mapStateToProps,
  actions
)(ListResults);
