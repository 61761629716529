import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";

const DeleteListModal = ({
  submitParams,
  submitAction,
  toggleVisibility,
  onDelete,
  name
}) => {
  return (
    <ModalBuilder
      withForm
      submitAction={submitAction}
      submitParams={submitParams}
      onCancel={toggleVisibility}
      onSubmitted={onDelete}
      renderModal={(modalProps, formProps) => (
        <Modal.Delete {...modalProps} header={<div>Delete list</div>}>
          Are you sure you would like to delete the list <strong>{name}</strong>
          ?
        </Modal.Delete>
      )}
    />
  );
};

export default DeleteListModal;
