import { Format } from "@redriver/cinnamon";
import { PageNumber } from "components/pagination";
import React from "react";
import { Popup } from "semantic-ui-react";

class SearchResultsWrapper extends React.Component {
  state = {
    totalResults: null
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { data, pageChanged } = nextProps;
    if (!pageChanged && state.totalResults !== data.totalResults) {
      return {
        totalResults: data.totalResults
      };
    }
    return null;
  }

  getResultsDisplayNumber = () => {
    const { pageNumber, pageSize, data } = this.props;
    const { totalResults } = data;
    if (totalResults <= pageSize) {
      return (
        <React.Fragment>
          {"1 - "}
          <Format.Number value={totalResults} />
        </React.Fragment>
      );
    } else {
      const start = pageNumber * pageSize - pageSize + 1;
      const end = Math.min(start + pageSize - 1, totalResults);
      return (
        <React.Fragment>
          <Format.Number value={start} />
          {" - "}
          <Format.Number value={end} />
        </React.Fragment>
      );
    }
  };

  render() {
    const { totalResults } = this.state;
    const {
      data,
      pageNumber,
      updatePageNumber,
      pageSize,
      isCompaniesPage,
      isContactsPage,
      actionsDropdown,
      children,
      listId
    } = this.props;
    return (
      <React.Fragment>
        <header className="flex space-between">
          <div style={{ width: "70%" }}>
            <div className=" flex align-center m-b-05">
              <h1>Results</h1>
            </div>
            <div className="flex space-between">
              <h3 className="m-r-1">
                {totalResults > 0 && totalResults !== null && (
                  <React.Fragment>
                    <Format.Number value={totalResults} />
                    {isCompaniesPage ? ` Companies` : ` Contacts`}
                  </React.Fragment>
                )}
              </h3>
              {isContactsPage && data && (
                <React.Fragment>
                  <Popup
                    trigger={
                      <h3 className="m-r-1">
                        {data.totalWithEmail.contacts > 0 && (
                          <React.Fragment>
                            <Format.Number
                              value={data.totalWithEmail.contacts}
                            />{" "}
                            With Email
                          </React.Fragment>
                        )}
                      </h3>
                    }
                    content={
                      <React.Fragment>
                        @
                        <Format.Number
                          value={
                            data.totalWithEmail && data.totalWithEmail.companies
                          }
                        />{" "}
                        Compan
                        {data.totalWithEmail.companies == 1 ? "y" : "ies"}
                      </React.Fragment>
                    }
                    position="top center"
                    className="table-popup"
                  />
                  <Popup
                    trigger={
                      <h3>
                        {data.totalWithoutEmail.contacts > 0 && (
                          <React.Fragment>
                            <Format.Number
                              value={data.totalWithoutEmail.contacts}
                            />{" "}
                            Without Email
                          </React.Fragment>
                        )}
                      </h3>
                    }
                    content={
                      <React.Fragment>
                        @
                        <Format.Number
                          value={
                            data.totalWithoutEmail &&
                            data.totalWithoutEmail.companies
                          }
                        />{" "}
                        Compan
                        {data.totalWithoutEmail.companies == 1 ? "y" : "ies"}
                      </React.Fragment>
                    }
                    position="top center"
                    className="table-popup"
                  />
                </React.Fragment>
              )}
            </div>
            {data && data.totalResults > 0 && (
              <label>Displaying {this.getResultsDisplayNumber()}</label>
            )}
          </div>
          {!listId && actionsDropdown}
        </header>

        {children}

        <div className="table-group-pagination m-t-1 p-b-2">
          <div>
            <PageNumber
              value={pageNumber}
              onChange={pageNumber => updatePageNumber(pageNumber)}
              totalItems={Math.min(totalResults || 0, 10000)}
              pageSize={pageSize}
            />
          </div>
          {pageNumber == 50 && (
            <div>
              <small>
                Paged results limited to 10k, please refine your search
              </small>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default SearchResultsWrapper;
