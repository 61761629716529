import { Form, getAuthClaims, PageLoader } from "@redriver/cinnamon";
import { PostalCheckboxTree } from "components/form";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { PostalAreasLookup } from "../lookups";
import FilterBase from "./FilterBase";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";

class CountriesFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    results: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    clearSelected: PropTypes.func.isRequired
  };
  state = {
    visible: false,
    countriesSelected: "",
    total: null,
    filteredNodes: []
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  componentDidMount = () => {
    const { id, formData } = this.props;
    this.getSelectedCountries(formData, id);
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  componentDidUpdate = prevProps => {
    const { id, formData, loading, results, pageChanged } = this.props;

    if (prevProps.formData !== formData) {
      this.getSelectedCountries(formData, id);
    }
  };

  getSelectedCountries = (formData, id) => {
    const { searchCriteria } = formData;

    const countriesObj = searchCriteria.find(el => el.id == id);
    const { selectedCountries } = countriesObj;

    this.setState({
      countriesSelected:
        !!selectedCountries && selectedCountries.length > 0
          ? selectedCountries.join(", ")
          : null
    });
  };

  locationIsCountry = (countries, location) => {
    return countries.some(c => c == location);
  };

  filter(array) {
    const { id, formData } = this.props;
    const { searchCriteria } = formData;

    const countriesObj = searchCriteria.find(el => el.id == id);
    const { postalFilter } = countriesObj;
    if (postalFilter === undefined || postalFilter === "") return array;
    const getNodes = (result, obj) => {
      if (obj.value.toLowerCase().includes(postalFilter.toLowerCase())) {
        result.push({ ...obj, defaultExpanded: true });
        return result;
      }
      if (Array.isArray(obj.children)) {
        const children = obj.children.reduce(getNodes, []);
        if (children.length)
          result.push({
            ...obj,
            defaultExpanded: true,
            children: children.map(c => ({ ...c, defaultExpanded: true }))
          });
      }
      return result;
    };

    const result = array.reduce(getNodes, []);
    return result;
  }

  render() {
    const { visible, countriesSelected, total } = this.state;
    const {
      id,
      loading,
      pageChanged,
      formData,
      clearSelected,
      userId,
      viewingUniverse
    } = this.props;

    const ClearButton = props => (
      <Button {...props} secondary style={{ marginTop: "1em" }}>
        Clear Filter
      </Button>
    );
    const { searchCriteria } = formData;
    const countriesObj = searchCriteria.find(el => el.id == id);
    const { postalFilter, selectedCountries } = countriesObj;

    return (
      <PostalAreasLookup
        userId={userId}
        viewingUniverse={viewingUniverse}
        render={({ response }, refresh) => {
          let nodes = [];
          let treeKey = 0;
          let countriesDisplay = "No countries selected";
          if (response) {
            nodes = this.filter(response.children || []);
            treeKey = nodes.length;
            const foundCountries = [];
            const partialCountries = [];
            selectedCountries.forEach(location => {
              const search = location.replace(" All", "").replace(" ALL", "");
              if (response.flattenedStructure.hasOwnProperty(search)) {
                if (response.flattenedStructure[search].length == 0) {
                  if (this.locationIsCountry(response.countriesOnly, search)) {
                    foundCountries.push(search);
                  }
                } else if (
                  response.flattenedStructure[search].some(
                    e => !selectedCountries.includes(e)
                  )
                ) {
                  //Do nothing
                } else {
                  if (this.locationIsCountry(response.countriesOnly, search)) {
                    foundCountries.push(search);
                  }
                }
              } else if (response.nonCountryStructure.hasOwnProperty(search)) {
                if (
                  this.locationIsCountry(
                    response.countriesOnly,
                    response.nonCountryStructure[search]
                  )
                ) {
                  partialCountries.push(response.nonCountryStructure[search]);
                }
              }
            });

            if (foundCountries.length > 0 || partialCountries.length > 0) {
              partialCountries.forEach(partialCountry => {
                if (!foundCountries.some(f => f == partialCountry)) {
                  if (
                    partialCountries.filter(p => p == partialCountry).length >=
                    Object.keys(response.nonCountryStructure).filter(
                      k => response.nonCountryStructure[k] == partialCountry
                    ).length
                  ) {
                    foundCountries.push(partialCountry);
                  } else {
                    foundCountries.push(partialCountry + " - Partial");
                  }
                }
              });

              countriesDisplay = [...new Set(foundCountries.sort())].join(", ");
            }
          }
          return (
            <FilterBase total={total} loading={loading && !pageChanged}>
              {response ? (
                <React.Fragment>
                  <FilterBase.Header
                    title="Geography"
                    collapsible
                    collapsed={!visible}
                    onCollapseToggle={this.toggleVisibility}
                    removable={false}
                    showClearer={!!countriesSelected}
                    onClear={clearSelected}
                  >
                    {!visible && (
                      <div className="countries-ellipsis-paragraph">
                        {countriesDisplay}
                      </div>
                    )}
                  </FilterBase.Header>
                  <FilterBase.Body collapsed={!visible}>
                    <Form.Group>
                      <Form.Input
                        style={{ marginTop: "1em" }}
                        field="postalFilter"
                        placeholder="Search..."
                      />
                      <Form.Clearer as={ClearButton} field="postalFilter" />
                    </Form.Group>
                    {postalFilter && (
                      <span>Clear filter to view full options</span>
                    )}
                    <div className="countries-selection-container">
                      <PostalCheckboxTree
                        key={treeKey}
                        className="checkbox-tree"
                        field="selectedCountries"
                        nodes={[
                          {
                            value: `${response.value}`,
                            text: `${response.text}`,
                            defaultExpanded: true,
                            children: nodes
                          }
                        ]}
                      />
                    </div>
                  </FilterBase.Body>
                </React.Fragment>
              ) : (
                <PageLoader
                  active
                  inline="centered"
                  size="small"
                  className="p-y-05"
                />
              )}
            </FilterBase>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => {
  const { userId } = getAuthClaims(state);

  return {
    userId
  };
};

export default connect(
  mapStateToProps,
  null
)(CountriesFilter);
