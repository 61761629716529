export const mapPermissions = permissions => {
  const mapped = [];
  permissions.forEach(item => {
    const mappedItem = {
      value: item.name.replace(" ", "-"),
      text: item.name,
      children: []
    };
    if (item.categories && item.categories.length > 0) {
      mappedItem.children = mappedItem.children.concat(
        mapPermissions(item.categories)
      );
    }
    if (item.permissions && item.permissions.length > 0) {
      mappedItem.children = mappedItem.children.concat(
        item.permissions.map(x => ({
          value: x.uniqueRef,
          text: x.description
        }))
      );
    }
    mapped.push(mappedItem);
  });
  return mapped;
};
