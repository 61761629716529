import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { deleteTemplate } from "./actions";

const DeleteTemplate = ({ template, toggleModal }) => {
  return (
    <PermissionCheck target={Targets.ExportTemplates} action={Actions.Delete}>
      <ModalBuilder
        submitAction={deleteTemplate}
        submitParams={template.id}
        onSubmitted={toggleModal}
        onCancel={toggleModal}
        renderModal={(modalProps, formProps) => {
          return (
            <Modal.Delete
              {...modalProps}
              header={
                <div>
                  <Icon name="delete" />
                  Delete Template
                </div>
              }
              submitLabel="Delete Template"
              size="tiny"
            >
              <p>
                Are you sure you want to delete the template{" "}
                <strong>{template.name}</strong>
              </p>
            </Modal.Delete>
          );
        }}
      />
    </PermissionCheck>
  );
};

export default DeleteTemplate;
