import { apiGet, Form, registerLookup } from "@redriver/cinnamon";
import { default as React } from "react";

const NAMESPACE = "SETTINGS/LOOKUPS";

const ActionTypes = {
  GetTemplatesLookup: `${NAMESPACE}/TEMPLATES`,
  GetFileFormatsLookup: `${NAMESPACE}/FILE_FORMATS`
};

registerLookup(
  "templateLookup",
  ({ isContact }) =>
    apiGet(
      ActionTypes.GetTemplatesLookup,
      `csv-export-templates/lookup?isContact=${isContact}`
    ),
  // do not cache details as it doesn't update when templates added/removed
  { cache: true, cacheLimit: 1 }
);

export const TemplateLookupTypeahead = props => (
  <Form.Dropdown {...props} lookup="templateLookup" search />
);

registerLookup(
  "fileFormatLookup",
  () => apiGet(ActionTypes.GetTemplatesLookup, "lookups/download-file-formats"),
  { cache: false }
);

export const FileFormatLookupDropdown = props => (
  <Form.Dropdown {...props} lookup="fileFormatLookup" />
);
