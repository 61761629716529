import { UserTypeCheck } from "components/auth";
import React from "react";
import { Dropdown } from "semantic-ui-react";

const SearchCriteriaSection = ({ criteria, heading, onClick, disabled }) =>
  (criteria && criteria.length && (
    <React.Fragment>
      <Dropdown.Header content={heading} />
      {criteria.map(el => {
        const item = (
          <Dropdown.Item
            value={el.value}
            onClick={onClick}
            className={disabled.indexOf(el.value) > -1 ? `disabled` : ""}
          >
            {`- ${el.name}`}
          </Dropdown.Item>
        );
        return !!el.userType ? (
          <UserTypeCheck key={el.id} restrictTo={el.userType}>
            {item}
          </UserTypeCheck>
        ) : (
          <React.Fragment key={el.id}>{item}</React.Fragment>
        );
      })}
    </React.Fragment>
  )) ||
  null;

export default SearchCriteriaSection;
