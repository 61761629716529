import React from "react";
import { Grid, Icon, Segment } from "semantic-ui-react";
import { Format, ListBuilder, Table } from "@redriver/cinnamon";

import { SearchResultCounter } from "components/lists";
import { PageNumber } from "components/pagination";
import { Targets, Actions } from "constants/permissions";
import { PermissionCheck } from "components/auth";

import { getCustomFields } from "./actions";
import FieldActions from "./FieldActions";
import FilterCustomFields from "./FilterCustomFields";

const ListCustomFields = ({ customerId }) => (
  <ListBuilder
    withTable
    loadAction={getCustomFields}
    loadParams={customerId}
    dataTransform={data => data.results}
    totalTransform={data => data.totalResults}
    renderList={(tableProps, { filters, pagination, total }, events) => (
      <Grid.Column
        mobile={16}
        tablet={16}
        computer={14}
        className="m-b-1"
        stretched
      >
        <div className="i4b-list">
          <h2>Company Custom Fields</h2>
          <FilterCustomFields
            filters={filters}
            customerId={customerId}
            onChangeFilters={events.onChangeFilters}
            pageSize={pagination.pageSize}
            onPageSizeChange={events.onChangePagination}
            onCreateCustomField={events.onRefresh}
          />
          <Segment>
            <Table {...tableProps} dataKey="id" basic="very">
              <Table.Column
                render={() => (
                  <Icon
                    className="color-green"
                    name="square full"
                    size="small"
                  />
                )}
                width="2.5%"
              />
              <Table.Column field="name" title="Name" />
              <Table.Column field="usageCount" title="Usage Count" />
              <Table.Column
                title="Changed"
                render={field => (
                  <React.Fragment>
                    {field.changedBy}
                    {field.changedDate && field.changedBy && " - "}
                    {field.changedDate && (
                      <Format.Date value={field.changedDate} format="short" />
                    )}
                  </React.Fragment>
                )}
              />
              <Table.Column
                render={field => (
                  <PermissionCheck
                    target={Targets.ItemTags}
                    action={Actions.Manage}
                  >
                    <FieldActions
                      customerId={customerId}
                      field={field}
                      onRefresh={events.onRefresh}
                    />
                  </PermissionCheck>
                )}
                width={"15%"}
              />
            </Table>
            <div className="i4b-list-footer">
              <SearchResultCounter
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                totalResults={total}
              />
              <PageNumber
                totalItems={total}
                onChange={x =>
                  events.onChangePagination({
                    pageSize: pagination.pageSize,
                    pageNumber: x
                  })
                }
                pageSize={pagination.pageSize}
                value={pagination.pageNumber}
              />
            </div>
          </Segment>
        </div>
      </Grid.Column>
    )}
  />
);

export default ListCustomFields;
