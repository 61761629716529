import { Format } from "@redriver/cinnamon";
import React from "react";
import { Loader, Segment } from "semantic-ui-react";

const SearchResultsSegment = ({ total, loading }) => {
  return loading ? (
    <Loader active inline="centered" className="search-results-loader" />
  ) : (
    <Segment className="search-results-segment" loading={loading}>
      <p>
        {total || total === 0 ? (
          <React.Fragment>
            <Format.Number value={total} /> result
            {total !== 1 ? "s" : ""}
          </React.Fragment>
        ) : (
          "-"
        )}
      </p>
    </Segment>
  );
};

export default SearchResultsSegment;
