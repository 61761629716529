import {
  ErrorMessage,
  Form,
  getAuthClaims,
  getAuthOptions,
  getAuthState
} from "@redriver/cinnamon";
import { accessDeniedWithReason, SystemRoutes } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button, Transition } from "semantic-ui-react";
import { EmailVerificationResender } from "../EmailVerification";
import { getLoginState } from "../selectors";
import { requestLogin } from "./actions";

const errorIsIncorrectPassword = error =>
  error && Array.isArray(error.result) && error.result[0].code === 915004;

const errorIsIpWhitelist = error =>
  error && Array.isArray(error.result) && error.result[0].code === 4001;

class Login extends React.Component {
  static propTypes = {
    onLogin: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    emailVerified: PropTypes.bool,
    useEmailVerification: PropTypes.bool,
    loggingIn: PropTypes.bool,
    slowRequest: PropTypes.bool,
    error: PropTypes.object
  };

  state = {
    username: "",
    password: "",
    remember: false,
    validated: false,
    showErrors: false,
    forgotLinkVisible: true
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.loggedIn && nextProps.loggedIn) {
      this.setState({
        username: "",
        password: "",
        remember: false,
        showErrors: false
      });
    }
  };

  componentDidUpdate = prevProps => {
    const { error } = this.props;

    if (!prevProps.error && errorIsIncorrectPassword(error)) {
      this.toggleForgotLinkVisibility();
    }

    if (!prevProps.error && errorIsIpWhitelist(error)) {
      this.props.history.push(accessDeniedWithReason("ip-whitelist"));
    }
  };

  onFormChange = (field, data) => this.setState({ [field]: data });

  onFormValidated = validated => this.setState({ validated });

  login = e => {
    e.preventDefault();
    if (!this.state.validated) {
      this.setState({ showErrors: true });
      return;
    }
    if (this.props.loggingIn) return;
    const { username, password, remember } = this.state;
    this.props.onLogin(username, password, remember);
  };

  toggleForgotLinkVisibility = () =>
    this.setState({ forgotLinkVisible: !this.state.forgotLinkVisible });

  render() {
    const {
      loggedIn,
      emailVerified,
      useEmailVerification,
      loggingIn,
      slowRequest,
      error
    } = this.props;
    const { forgotLinkVisible } = this.state;

    if (loggedIn) {
      if (emailVerified || !useEmailVerification) {
        return <p>You have been successfully logged in</p>;
      }
      return (
        <div>
          <p>Your email address has not been verified</p>
          <EmailVerificationResender />
        </div>
      );
    }

    const disabled = loggingIn && slowRequest;

    const incorrectUserPassword = errorIsIncorrectPassword(error);

    return (
      <Form
        value={this.state}
        onChange={this.onFormChange}
        onValidated={this.onFormValidated}
        showErrors={this.state.showErrors}
      >
        <Form.Email
          fluid
          field="username"
          label="Email"
          icon="mail"
          iconPosition="left"
          placeholder="Enter email address"
          disabled={disabled}
          autoFocus
          required
        />
        <Form.Password
          fluid
          field="password"
          label="Password"
          icon="lock"
          iconPosition="left"
          placeholder="Enter password"
          disabled={disabled}
          required
        />
        <Transition
          animation={"shake"}
          duration={600}
          visible={forgotLinkVisible}
        >
          <p className="system-link-forgotpw">
            {incorrectUserPassword
              ? "If you have forgotten your password please "
              : ""}
            <Link
              to={SystemRoutes.ForgottenPassword}
              style={{ fontWeight: incorrectUserPassword ? "bold" : "normal" }}
            >
              {incorrectUserPassword ? "click here" : "Forgot your Password?"}
            </Link>
          </p>
        </Transition>
        <Button
          primary
          fluid
          onClick={this.login}
          disabled={disabled}
          loading={loggingIn && slowRequest}
          className="system-button"
        >
          Sign in
        </Button>
        <ErrorMessage
          header="Login Error"
          error={
            error &&
            Array.isArray(error.result) &&
            error.result.every(x => x.code !== 4001)
              ? error
              : null
          }
        />
      </Form>
    );
  }
}

const actions = {
  onLogin: requestLogin
};

const mapStateToProps = state => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  const { useEmailVerification } = getAuthOptions();
  const { loggingIn, slowRequest, error } = getLoginState(state);

  return {
    loggedIn,
    emailVerified,
    useEmailVerification,
    loggingIn,
    slowRequest,
    error
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(Login)
);
