import { ListBuilder } from "@redriver/cinnamon";
import { UserTypeCheck } from "components/auth";
import { UserTypes } from "constants/permissions";
import React from "react";
import { Button, Grid } from "semantic-ui-react";
import { getUploads } from "./actions";
import UploadCard from "./UploadCard";
import UploadFile from "./UploadFile";

const ListUploads = () => {
  return (
    <ListBuilder
      dataTransform={data => data.results}
      totalTransform={data => data.totalResults}
      initialPagination={{
        pageSize: 10,
        pageNumber: 1
      }}
      loadAction={getUploads}
      renderList={(
        data,
        { filters, pagination, total, hasMore, loading, loadingMore },
        events
      ) => (
        <Grid
          className="i4b-list-page user-page"
          //style={{ paddingTop: "0.8rem" }}
        >
          <UserTypeCheck restrictTo={UserTypes.Internal}>
            <Grid.Row className="p-b-0 p-t-0">
              <Grid.Column floated="right" width={3}>
                <UploadFile onSubmit={events.onRefresh} />
              </Grid.Column>
            </Grid.Row>
          </UserTypeCheck>

          <Grid.Row className="p-t-0 m-b-1" columns={1}>
            <Grid.Column stretched>
              <Grid>
                <Grid.Row columns={3}>
                  {data.map((a, i) => (
                    <Grid.Column key={a.id}>
                      <UploadCard {...a} />
                    </Grid.Column>
                  ))}
                  {!data.length && !loading && (
                    <p
                      style={{
                        padding: "1em 0",
                        textAlign: "center",
                        width: "100%"
                      }}
                    >
                      No segment removals found
                    </p>
                  )}
                </Grid.Row>
              </Grid>

              {hasMore && (
                <Button
                  className="i4b-notification-list-loader"
                  loading={loadingMore}
                  onClick={events.onLoadMore}
                >
                  {loadingMore ? "Loading more uploads..." : "Show More"}
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    />
  );
};

export default ListUploads;
