import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import EditTag from "./EditTag";
import DeleteTag from "./DeleteTag";

const ActionsColumn = ({ item, onChange }) => {
  return (
    <Table.Menu direction="left" icon="ellipsis horizontal">
      <EditTag
        renderTrigger={showModal => (
          <Table.MenuItem content="Edit" onClick={showModal} />
        )}
        onSubmitted={onChange}
        item={item}
      />
      <DeleteTag
        renderTrigger={showModal => (
          <Table.MenuItem content="Delete" onClick={showModal} />
        )}
        onSubmitted={onChange}
        id={item.id}
      />
    </Table.Menu>
  );
};

export default ActionsColumn;
