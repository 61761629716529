import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAuthState } from "@redriver/cinnamon";
import { requestLogout } from "./actions";

class Logout extends React.Component {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired
  };

  componentWillMount() {
    this.props.onLogout();
  }

  render() {
    const { loggedIn, customText } = this.props;

    const displayText = customText || "You have been successfully logged out";

    return <div>{loggedIn ? <p>Logging out...</p> : <p>{displayText}</p>}</div>;
  }
}

const actions = {
  onLogout: requestLogout
};

const mapStateToProps = state => ({
  loggedIn: getAuthState(state).loggedIn
});

export default connect(
  mapStateToProps,
  actions
)(Logout);
