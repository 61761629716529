import {
  connectForm,
  Field,
  FieldError,
  formUtils,
  formValidators,
  TreeView
} from "@redriver/cinnamon";
import PropTypes from "prop-types";
import { default as React } from "react";
import { Checkbox as SCheckbox } from "semantic-ui-react";

class PostalCheckboxTree extends React.Component {
  static propTypes = {
    //Modified proptype of value to accept an object value
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    showErrors: PropTypes.bool,
    allErrors: PropTypes.bool,
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    disabled: PropTypes.bool,
    label: PropTypes.node,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fluid: PropTypes.bool,
    required: PropTypes.bool,
    passThruProps: PropTypes.object,
    /**
     * The current state of the form relative to this field (supplied by the form connection)
     * @property {Object} `fields` Form field data, either for the entire form or the current level when Object/Array fields are used
     * @property {Object} `parentFields` Form field data for the parent level when Object/Array fields are used
     * @property {Object} `formFields` Form field data for the entire form
     * @property {number} `arrayIndex` Index of the current row when used inside an Array field
     */
    formState: PropTypes.object,
    /**
     * Array of tree nodes objects in format [{ value: "", text: "", children: [] }]
     * @property {any} `value` Unique key for the node (note that only leaf nodes are actual selectable values in the tree)
     * @property {string} `text` Label for the node checkbox
     * @property {Array} `children` Optional child node objects
     * @property {boolean} `defaultExpanded` Whether the node should be expanded when initially mounted
     * @property {string} `className` Optional classes for styling
     * @property {boolean} `disabled` Whether the node checkbox should be disabled
     */
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.object),
        defaultExpanded: PropTypes.bool,
        className: PropTypes.string,
        disabled: PropTypes.bool
      })
    ).isRequired
  };

  static defaultProps = {
    value: [],
    onChange: () => {},
    label: ""
  };

  hasChildren = node => node.children && node.children.length > 0;

  getParentValue = (nodes, node) => {
    if (!this.hasChildren(nodes)) return null;

    if (nodes.children.some(c => c.value == node.value)) {
      return nodes;
    }
    for (let i = 0; i < nodes.children.length; i++) {
      const element = nodes.children[i];
      let parentNode = this.getParentValue(element, node);
      if (parentNode != null) return parentNode;
    }
    return null;
  };

  checkParentState = node => {
    const { value } = this.props;
    const currentValues = Array.isArray(value) ? value : [];
    const leafValues = this.getLeafValuesRecursive(node);
    if (leafValues.some(l => currentValues.includes(l))) {
      return false;
    }
    return true;
  };

  getLeafValuesRecursive = (node, includeDisabled = false) => {
    if (!this.hasChildren(node)) {
      return includeDisabled || !node.disabled ? [node.value] : [];
    }
    const childNodes = node.children.reduce((acc, c) => {
      if (this.hasChildren(c)) {
        return [...acc, ...this.getLeafValuesRecursive(c, includeDisabled)];
      } else {
        return includeDisabled || !c.disabled ? [...acc, c.value] : acc;
      }
    }, []);
    childNodes.push(node.value);
    return childNodes;
  };

  getIndeterminateState = node => {
    const { value } = this.props;
    const currentValues = Array.isArray(value) ? value : [];
    const leafValues = this.getLeafValuesRecursive(node);
    if (
      leafValues.length === 1 &&
      leafValues[0] == node.value &&
      currentValues.some(c => c == node.value)
    )
      return 2;

    const selectedLeafValues = leafValues.filter(
      v => currentValues.includes(v) && v !== node.value
    );
    if (selectedLeafValues.length === 0) return 0; // none
    if (
      selectedLeafValues.length <
      leafValues.filter(v => v !== node.value).length
    )
      return 1; // partial
    return 2; // all
  };

  getValuesStatus = (values, array) => values.some(el => array.includes(el));

  onChange = (node, checked) => {
    const { value, nodes } = this.props;
    const currentValues = Array.isArray(value) ? value : [];
    const leafValues = this.getLeafValuesRecursive(node);
    if (checked) {
      const missingValues = leafValues.filter(v => !currentValues.includes(v));
      this.props.onChange([...currentValues, ...missingValues]);
    } else {
      let newValues = currentValues.filter(v => !leafValues.includes(v));
      let parentNode = node;
      if (node.value !== "null") {
        parentNode = this.getParentValue(nodes[0], node);
      }
      const shouldRemove = this.checkParentState(parentNode);
      if (shouldRemove) {
        newValues = newValues.filter(v => v !== parentNode.value);
      }
      this.props.onChange(newValues);
    }
  };

  renderNode = (node, state, events) => {
    const { disabled } = this.props;
    const indeterminateState = this.getIndeterminateState(node);
    return (
      <SCheckbox
        indeterminate={indeterminateState === 1}
        checked={indeterminateState === 2}
        onChange={(e, d) => this.onChange(node, d.checked)}
        label={node.text}
        value={node.value}
        disabled={disabled || node.disabled}
      />
    );
  };

  render() {
    const {
      errors,
      showErrors,
      allErrors,
      animateErrors,
      disabled,
      label,
      width,
      fluid,
      required,
      passThruProps,
      nodes
    } = this.props;

    const otherProps = formUtils.omitProps(
      passThruProps,
      Object.keys(PostalCheckboxTree.propTypes)
    );
    const hasErrors = showErrors && !!errors && errors.length > 0;

    return (
      <Field
        required={required}
        error={hasErrors}
        disabled={disabled}
        width={width}
        fluid={fluid}
      >
        {label && (typeof label === "string" ? <label>{label}</label> : label)}
        <TreeView
          {...otherProps}
          nodes={nodes}
          nodeKey="value"
          renderNode={this.renderNode}
        />
        {showErrors && <FieldError errors={errors} showAll={allErrors} />}
      </Field>
    );
  }
}

export default connectForm({
  displayName: props =>
    props.label && typeof props.label === "string"
      ? props.label
      : formUtils.prettifyField(props.field),
  validators: [formValidators.requiredField(false)]
})(PostalCheckboxTree);
