import { isFormDataEmpty, isJobTitleEmpty } from "./index";

const extractSearchCriteria = formData => {
  const isEmpty = isFormDataEmpty(formData);
  if (isEmpty) return [];

  const searchCriteria = formData.searchCriteria.map(criteria => {
    switch (criteria.type) {
      case "jobTitlesFilter":
        return {
          ...criteria,
          jobTitlesFilter: {
            ...criteria.jobTitlesFilter,
            jobTitles: criteria.jobTitlesFilter.jobTitles.filter(
              t => !isJobTitleEmpty(t)
            )
          }
        };
      case "industriesFilter":
        return {
          ...criteria,
          industriesSelection: {
            ...criteria.industriesSelection,
            match:
              criteria.industriesSelection && criteria.industriesSelection.match
                ? criteria.industriesSelection.match
                : "one"
          }
        };
      default:
        return criteria;
    }
  });

  return searchCriteria;
};

export default extractSearchCriteria;
