import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { UserTypes } from "constants/permissions";
import { UserTypeCheck } from "components/auth";
import { suppressCompany } from "./actions";
import { Button } from "semantic-ui-react";

const CompanySuppressionConfirmationModal = ({
  disabled,
  companyId,
  onSubmitted
}) => {
  return (
    <ModalBuilder
      submitAction={suppressCompany}
      submitParams={companyId}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <UserTypeCheck restrictTo={UserTypes.External}>
          <Button
            className="primary secondary-small-button"
            onClick={showModal}
            disabled={disabled}
            fluid
          >
            Suppress Company
          </Button>
        </UserTypeCheck>
      )}
      renderModal={modalProps => (
        <Modal.Confirmation
          {...modalProps}
          header="Confirm Company Suppression"
          size="tiny"
        >
          <p>
            Please confirm you would like to suppress this company. In order to
            un-supress this company you will need to contact an admin.
          </p>
        </Modal.Confirmation>
      )}
    />
  );
};

export default CompanySuppressionConfirmationModal;
