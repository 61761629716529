import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Icon, Segment, Grid } from "semantic-ui-react";
import { ListBuilder, Table, Format } from "@redriver/cinnamon";
import { PageNumber } from "components/pagination";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { UserRoutes } from "constants/routes";
import { SearchResultCounter } from "components/lists";
import { getUsers } from "./actions";
import ActionsColumn from "./ActionsColumn";
import FilterUsers from "./FilterUsers";
import ListUsersMenu from "./ListUsersMenu";

const ListUsers = ({
  userType,
  customerId,
  showUserTypeMenu = true,
  requireCustomer
}) => {
  return (
    <ListBuilder
      key={userType}
      withTable
      dataTransform={data => data.results}
      totalTransform={data => data.totalResults}
      loadAction={getUsers}
      initialFilters={{ userType, customerId }}
      renderList={(tableProps, { filters, pagination, total }, events) => (
        <Grid
          className="i4b-list-page user-page"
          style={{
            paddingTop: showUserTypeMenu ? "0.8rem" : undefined,
            flex: !showUserTypeMenu ? "1" : undefined
          }}
        >
          <Grid.Row style={{ paddingTop: !showUserTypeMenu ? "0" : undefined }}>
            {showUserTypeMenu && (
              <Grid.Column mobile={16} tablet={16} computer={2}>
                <ListUsersMenu />
              </Grid.Column>
            )}
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={showUserTypeMenu ? 14 : 16}
              style={{ paddingTop: showUserTypeMenu ? "0.8rem" : undefined }}
            >
              <div className="i4b-list">
                <h1>Users</h1>
                <FilterUsers
                  filters={filters}
                  onChangeFilters={events.onChangeFilters}
                  onCreateUser={events.onRefresh}
                  pageSize={pagination.pageSize}
                  onPageSizeChange={events.onChangePagination}
                  userType={userType}
                  customerId={customerId}
                  requireCustomer={requireCustomer}
                />
                <Segment>
                  <Table {...tableProps} dataKey="id" basic="very">
                    <Table.Column
                      render={item => (
                        <Icon
                          className={
                            item.isDisabled ? "color-red" : "color-green"
                          }
                          name="square full"
                          size="small"
                        />
                      )}
                      width="2.5%"
                    />
                    <Table.Column
                      render={item => (
                        <Link to={UserRoutes.User(item.id)}>
                          {`${item.firstName} ${item.lastName}`}
                        </Link>
                      )}
                      title="Name"
                    />
                    <Table.Column field="email" title="Email" />
                    <Table.Column
                      render={item => `${item.roles.join(", ")}`}
                      title="Role"
                    />
                    {(userType === "External" || customerId) && (
                      <React.Fragment>
                        <Table.Column field="originalId" title="User ID" />
                        <Table.Column
                          field="customerOriginalId"
                          title="Customer ID"
                        />
                      </React.Fragment>
                    )}
                    <Table.Column
                      render={item => (
                        <Format.DateTime
                          value={item.lastLoginUtc}
                          format="DD/MM/YYYY HH:mm"
                        />
                      )}
                      title="Last Login"
                    />
                    <PermissionCheck
                      target={Targets.User}
                      action={Actions.Create}
                    >
                      <Table.Column
                        name="Actions"
                        render={item => (
                          <ActionsColumn
                            item={item}
                            onChange={events.onRefresh}
                            userType={userType}
                          />
                        )}
                        width={"15%"}
                      />
                    </PermissionCheck>
                  </Table>
                  <div className="i4b-list-footer">
                    <SearchResultCounter
                      pageSize={pagination.pageSize}
                      pageNumber={pagination.pageNumber}
                      totalResults={total}
                    />
                    <PageNumber
                      totalItems={total}
                      onChange={x =>
                        events.onChangePagination({
                          pageSize: pagination.pageSize,
                          pageNumber: x
                        })
                      }
                      pageSize={pagination.pageSize}
                      value={pagination.pageNumber}
                    />
                  </div>
                </Segment>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    />
  );
};

ListUsers.propTypes = {
  userType: PropTypes.string.isRequired
};

export default ListUsers;
