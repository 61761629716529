import { Table } from "@redriver/cinnamon";
import { PageNumber } from "components/pagination";
import CompanyExpandedRowContent from "features/Companies/CompaniesSearch/ExpandedRowContent";
import ContactExpandedRowContent from "features/Contacts/ContactsSearch/ExpandedRowContent";
import PropTypes from "prop-types";
import React from "react";
import { deleteContactTag } from "./actions";
import TableGroupItem from "./TableGroupItem";

class TableGroup extends React.Component {
  static propTypes = {
    details: PropTypes.object.isRequired,
    updateNameSearch: PropTypes.func
  };
  render() {
    const {
      loading,
      item,
      updatePageNumber,
      isContactList,
      isGrouped,
      isInternal,
      canEditCustomerWatchLists,
      onChangeSelection,
      onChangeFollowing,
      selection,
      onSubmitted,
      getContactDetails,
      details,
      viewingUniverse,
      allowTagAdditions,
      updateNameSearch
    } = this.props;
    let columns = !isInternal && canEditCustomerWatchLists ? 8 : 7;
    return (
      <div className="colour-expanded-table">
        <Table
          data={item.results}
          dataKey="id"
          columns={isContactList ? columns + 1 : columns}
          basic="very"
          className="table-layout-auto"
          noHeader
          loading={loading}
          expandRows={
            isContactList ? (viewingUniverse ? "none" : "onClick") : "onClick"
          }
          animateExpandedRows={false}
          renderExpandedRow={(item, state, events) => (
            <div>
              {isContactList ? (
                <ContactExpandedRowContent
                  details={details[item.id]}
                  collapse={events.onCollapse}
                  onSubmitted={onSubmitted}
                  getContactDetails={() => getContactDetails(item.id)}
                  contactId={item.id}
                  listViewingUniverse={viewingUniverse}
                  allowTagAdditions={allowTagAdditions}
                  deleteTag={deleteContactTag}
                  updateSearchFilter={updateNameSearch}
                />
              ) : (
                <CompanyExpandedRowContent
                  item={item}
                  collapse={events.onCollapse}
                  onSubmitted={onSubmitted}
                  viewUniverse={viewingUniverse}
                  allowTagAdditions={allowTagAdditions}
                />
              )}
            </div>
          )}
          emptyMessage="No results found"
        >
          {(item, state, events) => (
            <TableGroupItem
              item={item}
              state={state}
              events={events}
              selection={selection}
              isContactList={isContactList}
              isGrouped={isGrouped}
              isInternal={isInternal}
              canEditCustomerWatchLists={canEditCustomerWatchLists}
              onChangeSelection={onChangeSelection}
              onChangeFollowing={onChangeFollowing}
              viewingUniverse={viewingUniverse}
            />
          )}
        </Table>
        {updatePageNumber && item.totalResults > item.pageSize && (
          <div className="table-group-pagination with-border">
            <div>
              {" "}
              <PageNumber
                value={item.pageNumber}
                onChange={pageNumber => updatePageNumber(pageNumber)}
                totalItems={Math.min(item.totalResults || 0, 100)}
                pageSize={item.pageSize}
              />
            </div>
            {item.pageNumber == 10 && (
              <div>
                <small>
                  Paged results limited to 100, please refine your search
                </small>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default TableGroup;
