export function HasClaim(claimData, target, action, any) {
  if (!claimData.hasOwnProperty(target)) return false;
  const claimValue = claimData[target];
  const claimArray = Array.isArray(claimValue) ? claimValue : [claimValue];
  if (Array.isArray(action)) {
    const claimExists = claim => action.includes(claim);
    return any ? claimArray.some(claimExists) : claimArray.every(claimExists);
  } else {
    return claimArray.includes(action);
  }
}
