export const CalculateFilterCount = (
  results,
  pageChanged,
  id,
  currentTotal
) => {
  if (results.length === 0)
    return {
      total: null
    };

  const resultsObj = results.find(el => el.id == id);
  let newTotal;
  if (resultsObj) {
    newTotal = resultsObj.total;
  } else {
    const previousValues = results.filter(f => f.id < id);
    if (previousValues && previousValues.length > 0) {
      newTotal = previousValues.pop().total;
    } else {
      newTotal = results.find(el => el.id == "all").total;
    }
  }
  if (!pageChanged && currentTotal !== newTotal) {
    return {
      total: newTotal
    };
  }
  return null;
};
