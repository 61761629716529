import { NavSidebar } from "@redriver/cinnamon";
import { withCurrentUser } from "components/auth";
import { AppRoutes } from "constants/routes";
import { SignalRClient } from "modules/signalr";
import { disableUser, kickoutUser } from "modules/signalr/actions";
import React, { useEffect, useState } from "react";
import I4BAppRoutes from "./I4BAppRoutes";
import I4BMenu from "./I4BMenu";

const I4BApp = ({ history, currentUser }) => {
  // ensure the app gets safely re-mounted and returned to the root page when user switched
  const [lastUser, setLastUser] = useState(currentUser.id);

  useEffect(() => {
    if (currentUser.id !== lastUser) {
      history.push(AppRoutes.Root);
      setLastUser(currentUser.id);
    }
  }, [currentUser.id]);

  if (currentUser.id !== lastUser) return null;

  return (
    <React.Fragment>
      <NavSidebar
        fitContent
        fixed
        collapsed="auto"
        renderMenu={() => (
          <I4BMenu
            userName={currentUser.name}
            customer={currentUser.customer}
            isInternal={currentUser.isInternal}
            impersonator={currentUser.impersonator}
          />
        )}
      >
        <I4BAppRoutes />
      </NavSidebar>
      <SignalRClient
        channel={currentUser.id}
        callbacks={[
          {
            method: "kickoutUser",
            run: kickoutUser,
            isAction: true
          },
          {
            method: "disableUser",
            run: disableUser,
            isAction: true
          }
        ]}
      />
    </React.Fragment>
  );
};

export default withCurrentUser(I4BApp);
