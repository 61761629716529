import React, { Fragment } from "react";
import { Segment } from "semantic-ui-react";

import SearchFiltersDivider from "../SearchFiltersDivider";
import SearchResultsSegment from "../SearchResultsSegment";
import FilterBaseHeader from "./FilterBaseHeader";
import FilterBaseBody from "./FilterBaseBody";

const FilterBase = ({ children, total, loading, className }) => (
  <Fragment>
    <SearchFiltersDivider />
    <Segment className="m-t-0" style={{ padding: 0 }}>
      <section className={`search-filter ${className}`}>{children}</section>
    </Segment>
    <SearchFiltersDivider />
    <SearchResultsSegment total={total} loading={loading} />
  </Fragment>
);

FilterBase.Header = FilterBaseHeader;
FilterBase.Body = FilterBaseBody;

export default FilterBase;
