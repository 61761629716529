import {
  getAuthClaims,
  getAuthOptions,
  getAuthState,
  PageHeader
} from "@redriver/cinnamon";
import { SystemPageContent } from "components/pages";
import { AppRoutes, SystemRoutes } from "constants/routes";
import { Login } from "features/System";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const LoginPage = ({
  loggedIn,
  emailVerified,
  useEmailVerification,
  location,
  emailExpiryUtc,
  refreshToken,
  impersonatorId
}) => {
  const emailVerifiyExpiresInFuture =
    impersonatorId || moment(emailExpiryUtc) > moment().utc();
  const nextRoute =
    location.state && location.state.nextPath
      ? location.state.nextPath.pathname
      : AppRoutes.Root;

  const loggingInState = {
    nextPath: nextRoute
  };
  if (loggedIn && !refreshToken) {
    return (
      <Redirect
        to={{
          pathname: SystemRoutes.ActiveSessions,
          state: loggingInState
        }}
      />
    );
  } else if (
    loggedIn &&
    (emailVerified || !useEmailVerification) &&
    emailVerifiyExpiresInFuture
  ) {
    return (
      <Redirect
        to={{
          pathname: SystemRoutes.Terms,
          state: loggingInState
        }}
      />
    );
  } else if (
    loggedIn &&
    (emailVerified || !useEmailVerification) &&
    !emailVerifiyExpiresInFuture
  ) {
    return (
      <Redirect
        to={{
          pathname: SystemRoutes.Reverify,
          state: { isLogin: true }
        }}
      />
    );
  }

  return (
    <SystemPageContent showTagline>
      <PageHeader>Login</PageHeader>
      <Login />
    </SystemPageContent>
  );
};

const mapStateToProps = state => {
  const { loggedIn, refreshToken } = getAuthState(state);
  const { emailVerified, emailExpiryUtc, impersonatorId } = getAuthClaims(
    state
  );
  const { useEmailVerification } = getAuthOptions();
  return {
    loggedIn,
    emailVerified,
    useEmailVerification,
    emailExpiryUtc,
    refreshToken,
    impersonatorId
  };
};

export default connect(mapStateToProps)(LoginPage);
