import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "REPORTS";

export const ActionTypes = {
  DownloadRawCriteriaReport: `${NAMESPACE}/DOWNLOAD_RAW_CRITERIA_REPORT`,
  DownloadCriteriaTotalsReport: `${NAMESPACE}/DOWNLOAD_CRITERIA_TOTALS_REPORT`
};

export const downloadRawCriteriaReport = data =>
  apiGet(ActionTypes.DownloadRawCriteriaReport, `reports/criteria-raw`, data);

export const downloadCriteriaTotalsReport = data =>
  apiGet(
    ActionTypes.DownloadCriteriaTotalsReport,
    `reports/criteria-totals`,
    data
  );
