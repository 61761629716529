import { ActionTypes } from "./actions";
import { ContactsSearchActionTypes } from "features/Contacts/ContactsSearch";
import {
  requestResponse,
  requestIsBusy,
  requestIsSuccess
} from "@redriver/cinnamon";
const initialState = {
  contactFilters: {
    textFilter: {
      filter: ""
    },
    reduxFilter: {
      left: "false"
    }
  },
  contacts: {},
  contactDetails: {},
  tableKey: 0
};
import { find } from "lodash";

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.StoreContactFilters:
      return {
        ...state,
        contactFilters: {
          ...state.contactFilters,
          reduxFilter: {
            ...action.filters.reduxFilter
          }
        }
      };
    case ActionTypes.GetContacts:
      return {
        ...state,
        contacts: requestResponse(action) || initialState.contacts,
        contactDetails: {}
      };
    case ActionTypes.ChangeFollowing:
      const loading = requestIsBusy(action);
      const response = requestResponse(action);
      const success = requestIsSuccess(action);
      const newResults = [...state.contacts.results];

      if (action.customData && action.customData.contacts) {
        for (var i = 0; i < action.customData.contacts.length; i++) {
          const contactId = action.customData.contacts[i].originalIntId;
          const contact = find(newResults, c => c.originalIntId === contactId);
          if (contact) {
            if (success === false) {
              contact.followingUpdating = false;
            } else if (response) {
              contact.following = action.customData.following;
              contact.followingUpdating = false;
            } else {
              contact.followingUpdating = true;
            }
          }
        }
      }

      return {
        ...state,
        contacts: {
          ...state.contacts,
          results: newResults
        }
      };
    case ActionTypes.ClearTags: {
      return {
        ...state,
        contactDetails: {},
        tableKey: (state.tableKey += 1)
      };
    }
    case ActionTypes.GetContactDetails:
      const res = requestResponse(action);
      const detailsLoading = requestIsBusy(action);

      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          [action.customData.contactId]: { data: res, loading: detailsLoading }
        }
      };
    default:
      return state;
  }
};
