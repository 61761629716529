import { Format, ListBuilder, Table } from "@redriver/cinnamon";
import Amber from "assets/amber_square.svg";
import Green from "assets/green_square.svg";
import Grey from "assets/grey_square.svg";
import Red from "assets/red_square.svg";
import { SearchResultCounter } from "components/lists";
import { PageNumber } from "components/pagination";
import { CustomerRoutes } from "constants/routes";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Grid, Icon, Image, Popup, Segment } from "semantic-ui-react";
import { getCustomers } from "./actions";
import ActionsColumn from "./ActionsColumn";
import FilterCustomers from "./FilterCustomers";

const getSource = item => {
  const lastDayThreeMonth = moment(item.lastDay).subtract(3, "months");
  //   Grey, between `Client Since` & `Contract start`
  // Green (or green shade 1), Between `Contract start` & `Initial End`
  // Green (or green shade 2), Beyond `Initial End` but doesn't have a `Last Day`
  // Amber, has `Last Day` entered
  // Red, within 3 months of `Last Day` (or non-trial with no contract start / initial end)
  // Grey, `Last Day` passed
  if (item.accessDenied) {
    return Grey;
  } else if (moment(item.lastDay) < moment()) {
    return Grey;
  } else if (lastDayThreeMonth < moment()) {
    return Red;
  } else if (item.lastDay !== null) {
    return Amber;
  } else if (moment() < moment(item.contractStartDate)) {
    return Grey;
  } else if (moment(item.contractStartDate) < moment()) {
    return Green;
  } else if (moment(item.initialEnd) < moment() && item.lastDay === null) {
    return Green;
  } else if (item.accessType == "Trial Access") {
    return Green;
  } else if (item.contractStartDate === null || item.initialEnd === null) {
    return Red;
  }
};

const renderLicenceDate = item => {
  // by default show InitialEnd date
  // if beyond InitialEnd show "Stopped"
  //if HardEnd ticked, show LastDay date with R
  //if not HardEnd ticked and has LastDay, show LastDay
  if (
    !item.hardEnd &&
    !(
      item.lastDay === undefined ||
      item.lastDay === null ||
      item.lastDay === ""
    )
  ) {
    return <Format.Date value={item.lastDay} />;
  } else if (item.hardEnd) {
    return (
      <React.Fragment>
        <Format.Date value={item.lastDay} /> <p>R</p>
      </React.Fragment>
    );
  } else if (moment() > moment(item.initialEnd) && item.lastDay) {
    return "Stopped";
  } else if (item.initialEnd) {
    return <Format.Date value={item.initialEnd} />;
  }
  return <p align="center">-</p>;
};

const renderSubscriptionType = item => {
  return (
    <p>
      {item.accessDenied && (
        <Popup
          trigger={<Icon color="red" name="exclamation circle" />}
          content="Access Denied"
        />
      )}
      {item.accessType || "-"}
    </p>
  );
};

class ListCustomers extends React.Component {
  state = {
    selection: {
      allSelected: false,
      selected: [],
      deselected: []
    },
    totalSelected: 0
  };

  changeSelection = (selection, total) => {
    let totalSelected = 0;
    if (selection.allSelected) {
      totalSelected = total - selection.deselected.length;
    } else {
      totalSelected = selection.selected.length;
    }
    this.setState({
      selection: selection,
      totalSelected: totalSelected
    });
  };

  render() {
    const { selection, totalSelected } = this.state;
    return (
      <ListBuilder
        withTable
        dataTransform={data => data.results}
        totalTransform={data => data.totalResults}
        initialFilters={{ accessDenied: ["accessAllowed"] }}
        loadAction={getCustomers}
        renderList={(tableProps, { filters, pagination, total }, events) => (
          <Grid
            className="i4b-list-page user-page"
            style={{ paddingTop: "0.8rem" }}
          >
            <Grid.Column computer={16} style={{ paddingTop: "0.8rem" }}>
              <div className="i4b-list">
                <h1>Customers</h1>
                <FilterCustomers
                  filters={filters}
                  onChangeFilters={filters => {
                    events.onChangeFilters(filters);
                    this.changeSelection({
                      allSelected: false,
                      deselected: [],
                      selected: []
                    });
                  }}
                  pageSize={pagination.pageSize}
                  onPageSizeChange={events.onChangePagination}
                  selection={selection}
                  totalSelected={totalSelected}
                />
                <Segment>
                  <Table {...tableProps} dataKey="id" basic="very">
                    <Table.SelectableColumn
                      value={selection}
                      totalItems={tableProps.data ? tableProps.data.length : 0}
                      header="all"
                      onChange={selection =>
                        this.changeSelection(selection, total)
                      }
                    />
                    <Table.Column
                      render={item => (
                        <Image
                          src={getSource(item)}
                          title="Company Status"
                          style={{ height: "1em", width: "1em" }}
                        />
                      )}
                      width="2.5%"
                    />
                    <Table.Column
                      title="Name"
                      render={item => (
                        <Link to={CustomerRoutes.Customer(item.id)}>
                          {`${item.fullName}`}
                        </Link>
                      )}
                    />
                    <Table.Column
                      title="Subscription Type"
                      render={item => renderSubscriptionType(item)}
                    />
                    <Table.Column
                      title="Account Manager"
                      render={item => item.accountManager || "-"}
                    />
                    <Table.Column
                      title="Licence Date"
                      render={item => renderLicenceDate(item)}
                    />
                    <Table.Column
                      title="Customer ID"
                      render={item => item.originalId}
                    />
                    <Table.Column
                      name="Actions"
                      render={item => (
                        <ActionsColumn
                          item={item}
                          onChange={events.onRefresh}
                        />
                      )}
                      width={"15%"}
                    />
                  </Table>
                  <div className="i4b-list-footer">
                    <SearchResultCounter
                      pageSize={pagination.pageSize}
                      pageNumber={pagination.pageNumber}
                      totalResults={total}
                    />
                    <PageNumber
                      totalItems={total}
                      onChange={x =>
                        events.onChangePagination({
                          pageSize: pagination.pageSize,
                          pageNumber: x
                        })
                      }
                      pageSize={pagination.pageSize}
                      value={pagination.pageNumber}
                    />
                  </div>
                </Segment>
              </div>
            </Grid.Column>
          </Grid>
        )}
      />
    );
  }
}

export default ListCustomers;
