import { PermissionLink } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { ListRoutes } from "constants/routes";
import React from "react";
import { Message } from "semantic-ui-react";

const ListCreatedNotification = ({ data, state, events }) => (
  <Message
    header="List Created"
    content={
      <PermissionLink
        to={ListRoutes.List(data.customData.listId)}
        action={Actions.View}
        target={Targets.List}
      >
        {data.message}
        <span
          style={{
            float: "right",
            clear: "right",
            marginLeft: 10
          }}
        >
          {state.displayTime}
        </span>
      </PermissionLink>
    }
    onDismiss={events.onDismiss}
    icon="bell"
    className="custom-notification"
  />
);

export default ListCreatedNotification;
