import React from "react";
import { Accordion } from "semantic-ui-react";
import { PostalAreasLookup } from "../../lookups";
import ReadOnlyHeader from "./ReadOnlyHeader";
import TreeViewCriteria from "./TreeViewCriteria";

const locationIsCountry = (countries, location) => {
  return countries.some(c => c == location);
};

const CountriesCriteria = ({ active, handleClick, criteria, userId }) => {
  if (!criteria.selectedCountries.length) return null;

  return (
    <PostalAreasLookup
      userId={userId}
      viewingUniverse={false}
      render={({ response }, refresh) => {
        let countriesDisplay = "";
        if (response) {
          const foundCountries = [];
          const partialCountries = [];
          criteria.selectedCountries.forEach(location => {
            const search = location.replace(" All", "").replace(" ALL", "");
            if (response.flattenedStructure.hasOwnProperty(search)) {
              if (response.flattenedStructure[search].length == 0) {
                if (locationIsCountry(response.countriesOnly, search)) {
                  foundCountries.push(search);
                }
              } else if (
                response.flattenedStructure[search].some(
                  e => !criteria.selectedCountries.includes(e)
                )
              ) {
                //Do nothing
              } else {
                if (locationIsCountry(response.countriesOnly, search)) {
                  foundCountries.push(search);
                }
              }
            } else if (response.nonCountryStructure.hasOwnProperty(search)) {
              if (
                locationIsCountry(
                  response.countriesOnly,
                  response.nonCountryStructure[search]
                )
              ) {
                partialCountries.push(response.nonCountryStructure[search]);
              }
            }
          });
          if (foundCountries.length > 0 || partialCountries.length > 0) {
            partialCountries.forEach(partialCountry => {
              if (!foundCountries.some(f => f == partialCountry)) {
                if (
                  partialCountries.filter(p => p == partialCountry).length >=
                  Object.keys(response.nonCountryStructure).filter(
                    k => response.nonCountryStructure[k] == partialCountry
                  ).length
                ) {
                  foundCountries.push(partialCountry);
                } else {
                  foundCountries.push(partialCountry + " - Partial");
                }
              }
            });

            countriesDisplay = [...new Set(foundCountries.sort())].join(", ");
          }
        }
        return (
          <React.Fragment>
            <ReadOnlyHeader
              active={active}
              handleClick={handleClick}
              criteria={criteria}
              name="Geography"
              text={countriesDisplay}
            />
            <Accordion.Content active={active}>
              <TreeViewCriteria
                criteria={criteria}
                lookup="countriesCriteriaLookup"
              />
            </Accordion.Content>
          </React.Fragment>
        );
      }}
    />
  );
};

export default CountriesCriteria;
