import {
  ListCreatedNotification,
  ErrorMessageNotification
} from "components/notifications";
import React from "react";

export const customRenderEvent = (data, state, events) => {
  switch (data.type) {
    case "ListCreatedNotification":
      return (
        <ListCreatedNotification data={data} state={state} events={events} />
      );
    case "ErrorMessageNotification":
      return (
        <ErrorMessageNotification data={data} state={state} events={events} />
      );

    default:
      return false;
  }
};
