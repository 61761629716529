import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styled, { css } from "styled-components";
import { Loader } from "semantic-ui-react";
import { ErrorMessage } from "@redriver/cinnamon";
import { TileSize, TilePadding } from "./constants";

const breakpointsCss = breakpoints =>
  breakpoints.map((bp, index) => {
    const minWidth = bp;
    const flexBasis = 100 / (index + 2);
    return css`
      &&& > .cell {
        @media (min-width: ${minWidth}px) {
          flex-basis: ${flexBasis}%;
        }
      }
    `;
  });

const TileContainer = styled.div`
  ${props =>
    !!props.breakpoints &&
    props.breakpoints.length > 0 &&
    css`
      ${breakpointsCss(props.breakpoints)}
    `}
`;

/**
 * A responsive grid of tiles
 */
class TileGrid extends React.Component {
  static propTypes = {
    /**
     * Array of tile data
     */
    data: PropTypes.arrayOf(PropTypes.object),
    /**
     * Property name for unique key in data, defaults to "key"
     */
    dataKey: PropTypes.string,
    /**
     * Indicates the tile grid is busy loading data
     */
    loading: PropTypes.bool,
    /**
     * Indicates the tile grid is taking a long time to load (a loading indicator will appear)
     */
    slowLoading: PropTypes.bool,
    /**
     * Error message to display within the tile grid when something has gone wrong
     */
    error: PropTypes.any,
    /**
     * Message to display if the data contains no items
     */
    emptyMessage: PropTypes.string,
    /**
     * Component to use to render each tile
     * Will be passed an item prop with the item data to render
     */
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    /**
     * Optional render function to use to render each tile
     * @param {Object} `item` The item being rendered
     */
    render: PropTypes.func,
    /**
     * The size of the tiles to be rendered
     * Will be ignored if manually specifying breakpoints
     * @typedef TileSize
     * @property {string} `small`
     * @property {string} `medium`
     * @property {string} `large`
     */
    size: PropTypes.oneOf(Object.values(TileSize)),
    /**
     * The padding size surrounding the tiles to be rendered
     * @typedef TilePadding
     * @property {string} `none`
     * @property {string} `medium`
     * @property {string} `large`
     */
    padding: PropTypes.oneOf(Object.values(TilePadding)),
    /**
     * Whether tiles should have additional responsive constraints for wider screen layouts.
     * When set to true, more tiles will be visible on larger screens.
     * (Optimised for use with 'wide' PageContent components).
     * Will be ignored if manually specifying breakpoints
     */
    wide: PropTypes.bool,
    /**
     * Optional, array of screen breakpoints to manually define points at which tiles will wrap onto a new row
     * e.g. [500, 750, 1000] will result in 2 tiles above 500px, 3 tiles above 750px, and 4 tiles above 1000px
     * When specified, the size and wide props will both be ignored
     */
    breakpoints: PropTypes.arrayOf(PropTypes.number),
    /**
     * Additional classes for styling
     */
    className: PropTypes.string
  };

  static defaultProps = {
    dataKey: "key",
    emptyMessage: "No Results",
    size: "medium",
    padding: "medium",
    wide: false
  };

  _keyWarning = false;

  componentDidUpdate = prevProps => {
    if (
      prevProps.data !== this.props.data ||
      prevProps.dataKey !== this.props.dataKey
    ) {
      this._keyWarning = false;
    }
  };

  getItemKey = item => {
    const { dataKey } = this.props;
    const key = item[dataKey];
    if (!key && !this._keyWarning) {
      console.warn("TileGrid dataKey is invalid");
      this._keyWarning = true;
    }
    return key;
  };

  renderError() {
    const { error } = this.props;
    if (!error) return null;
    return (
      <div className="content">
        <ErrorMessage error={error} />
      </div>
    );
  }

  renderTiles() {
    const {
      data,
      loading,
      slowLoading,
      emptyMessage,
      size,
      padding,
      component: Component,
      render
    } = this.props;

    const showLoading =
      loading &&
      (slowLoading || slowLoading === undefined || !data || data.length === 0);

    if (showLoading) {
      return <Loader size="large" active inline className="content" />;
    }

    if (!data) return this.renderError();

    if (data.length === 0) {
      return (
        <React.Fragment>
          <p className="content">{emptyMessage}</p>
          {this.renderError()}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {data.map((item, index) => {
          const itemKey = this.getItemKey(item) || index;
          return (
            <div
              key={itemKey}
              className={classNames("cell", size, {
                "no-padding": padding === TilePadding.None,
                padded: padding === TilePadding.Large
              })}
            >
              {render ? (
                render(item)
              ) : Component ? (
                <Component item={item} />
              ) : null}
            </div>
          );
        })}
        {this.renderError()}
      </React.Fragment>
    );
  }

  render() {
    const { wide, breakpoints, className } = this.props;
    return (
      <TileContainer
        breakpoints={breakpoints}
        className={classNames(
          "cin tile-grid",
          {
            wide,
            "custom-breakpoints": !!breakpoints && breakpoints.length > 0
          },
          className
        )}
      >
        {this.renderTiles()}
      </TileContainer>
    );
  }
}

export default TileGrid;
