import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";

const DeleteUploadModal = ({
  submitParams,
  submitAction,
  toggleVisibility,
  onDelete,
  uploadName
}) => {
  return (
    <ModalBuilder
      withForm
      submitAction={submitAction}
      submitParams={submitParams}
      onCancel={toggleVisibility}
      onSubmitted={onDelete}
      renderModal={(modalProps, formProps) => (
        <Modal.Delete {...modalProps} header={<div>Delete Upload</div>}>
          Are you sure you would like to delete the Upload{" "}
          <strong>{uploadName}</strong>?
          <p>
            {" "}
            Note: uploads will be removed from searches and uploads but remain
            in lists/old saved searches.
          </p>
        </Modal.Delete>
      )}
    />
  );
};

export default DeleteUploadModal;
