import { Form } from "@redriver/cinnamon";
import { ListRoutes } from "constants/routes";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment } from "semantic-ui-react";

class SearchFilterSegment extends Component {
  onNavBack = () => {
    const { history, listId } = this.props;

    history.push(ListRoutes.List(listId));
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  render() {
    const { isCompaniesPage, formData, onClick } = this.props;

    const searchFilter = formData.searchCriteria.filter(
      f => f.type === "searchFilter"
    )[0];
    return (
      <Segment className="search-filter-segment text-filter-segment m-t-0">
        <Form.Object field="searchFilter">
          <div
            className="flex flex-wrap align-center m-b-05"
            style={{ marginTop: "0.25rem" }}
          >
            <div
              style={{
                fontSize: "1rem"
              }}
            >
              {isCompaniesPage ? "Search Company" : "Search Contact"}
            </div>
            <React.Fragment>
              <Form.Checkbox
                className="left-company-toggle"
                field="left"
                label={
                  isCompaniesPage
                    ? "Include Defunct Companies"
                    : "Include Left Contacts"
                }
                disabled={
                  !(
                    searchFilter &&
                    searchFilter.searchFilter &&
                    searchFilter.searchFilter.search
                  )
                }
                toggle
              />
              <Form.Trigger
                field="search"
                event="onClear"
                action="change"
                target="left"
                value={() => false}
              />
            </React.Fragment>
            {searchFilter &&
              searchFilter.searchFilter &&
              searchFilter.searchFilter.search !== "" && (
                <a className="purple-link clear-input" onClick={onClick}>
                  (Clear)
                </a>
              )}
          </div>
          <Form.Input
            field="search"
            onKeyDown={this.handleKeyPress}
            placeholder={
              isCompaniesPage
                ? "Search company name"
                : "Search name, email, company, job title"
            }
            width={25}
          />
        </Form.Object>
      </Segment>
    );
  }
}

export default withRouter(SearchFilterSegment);
