import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, ErrorMessage } from "@redriver/cinnamon";
import { Button, Message } from "semantic-ui-react";
import { requestPasswordForgotten } from "./actions";
import { getPasswordForgottenState } from "../selectors";

class PasswordForgotten extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    requesting: PropTypes.bool,
    slowRequest: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.any
  };

  state = {
    username: "",
    validated: false,
    showErrors: false
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.success && nextProps.success) {
      this.setState({ username: "", showErrors: false });
    }
  };

  onFormChange = (field, data) => this.setState({ [field]: data });

  onFormValidated = validated => this.setState({ validated });

  forgottenPassword = e => {
    e.preventDefault();
    if (!this.state.validated) {
      this.setState({ showErrors: true });
      return;
    }
    if (this.props.requesting) return;
    this.props.onSubmit(this.state.username);
  };

  render() {
    const { requesting, slowRequest, success, error } = this.props;
    const isBusy = requesting && slowRequest;

    return (
      <Form
        value={this.state}
        onChange={this.onFormChange}
        onValidated={this.onFormValidated}
        showErrors={this.state.showErrors}
      >
        <Form.Email
          fluid
          field="username"
          label="Email"
          icon="mail"
          iconPosition="left"
          placeholder="Enter email address"
          disabled={isBusy}
          autoFocus
          required
        />
        <Button
          fluid
          primary
          onClick={this.forgottenPassword}
          disabled={isBusy}
          loading={isBusy}
          className="system-button"
        >
          Submit
        </Button>
        <ErrorMessage
          error={error}
          overrideMessages={{
            913010: "This email address doesn't belong to an active account"
          }}
        />
        {success && (
          <Message
            positive
            header="Success"
            content="A password reset request has been sent"
          />
        )}
      </Form>
    );
  }
}

const actions = {
  onSubmit: requestPasswordForgotten
};

const mapStateToProps = state => {
  const passwordForgottenState = getPasswordForgottenState(state);
  return {
    requesting: passwordForgottenState.requesting,
    slowRequest: passwordForgottenState.slowRequest,
    success: passwordForgottenState.success,
    error: passwordForgottenState.error
  };
};

export default connect(
  mapStateToProps,
  actions
)(PasswordForgotten);
