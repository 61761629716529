import React from "react";

const JobTitlesSummary = ({ data }) => {
  return (
    <React.Fragment>
      <span>{data.jobTitlesMatch}</span>{" "}
      {data.jobTitles.map(
        el =>
          el.title ? (
            <span key={el.id} className="title">
              {el.title}
            </span>
          ) : null
      )}
    </React.Fragment>
  );
};

export default JobTitlesSummary;
