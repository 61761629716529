import { Form } from "@redriver/cinnamon";
import { CustomCheckboxGroup } from "components/form";
import { TemplateType } from "constants/settings/templates";
import { FileFormatLookupDropdown } from "../lookups";
import React from "react";

const types = Object.keys(TemplateType).map(function(k) {
  return { value: TemplateType[k], text: k };
});

const TemplateForm = ({ formProps, columns, editing, isInternal }) => (
  <Form
    {...formProps}
    className={`template-form${
      editing ? ` template-form-editing` : ` template-form-creating`
    }`}
  >
    <Form.Group widths="equal" className="group-details">
      <Form.Input label="Name" field="name" placeholder="Name" required fluid />

      {!editing && (
        <div style={{ width: "100%" }}>
          <label className="field-header required">Template Type</label>

          <Form.RadioGroup
            field="templateType"
            options={types}
            className="template-type i4b-hide-required"
            inline
            required
          />
        </div>
      )}
    </Form.Group>

    <label className="field-header required">Access Type</label>

    <Form.RadioGroup
      field="isPublic"
      className="access-type i4b-hide-required"
      options={[
        { value: "false", text: "Just Me" },
        { value: "true", text: isInternal ? "Public" : "My Company" }
      ]}
      inline
      required
      requiredError="Access Type is required"
    />

    <FileFormatLookupDropdown field="fileFormat" label="File Format" />

    <label className="field-header required">Columns</label>
    <Form.Group className="group-columns">
      {formProps.value.templateType === TemplateType.Company && (
        <CustomCheckboxGroup
          fluid
          options={columns.companies}
          field="companyColumns"
        />
      )}

      {formProps.value.templateType === TemplateType.Contact && (
        <CustomCheckboxGroup
          fluid
          options={columns.contacts}
          field="contactColumns"
        />
      )}
    </Form.Group>
  </Form>
);

export default TemplateForm;
