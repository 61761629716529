import { Form, getAuthClaims } from "@redriver/cinnamon";
import { FilterBase } from "components/search";
import React from "react";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";
import { ListStatusesDropdown } from "../lookups";
import { clearName, clearSearch, clearValue, updateForm } from "./actions";
import LettersFilter from "./LettersFilter";
import { getListsOverviewState } from "./selectors";

class ListFilters extends React.Component {
  state = {
    visible: false
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  onLetterFilterChanged = letter => {
    const { updateForm } = this.props;
    updateForm("firstLetter", letter);
  };

  render() {
    const {
      formData,
      isContactList,
      updateForm,
      clearSearch,
      clearName,
      clearValue,
      listId,
      hasStatus
    } = this.props;

    const { visible } = this.state;

    return (
      <Segment
        className="search-filter-segment list-filters"
        style={{ padding: 0, width: "100%" }}
      >
        <section className={`search-filter`}>
          <FilterBase.Header
            title={
              <React.Fragment>
                <span>Filter List</span>{" "}
                {formData && (
                  <a
                    className="purple-link"
                    onClick={e => {
                      e.stopPropagation();
                      clearSearch();
                    }}
                  >
                    (Clear)
                  </a>
                )}
              </React.Fragment>
            }
            collapsible
            collapsed={!visible}
            onCollapseToggle={this.toggleVisibility}
            removable={false}
          />
          <FilterBase.Body collapsed={!visible}>
            <div className="flex flex-wrap align-center space-between">
              <div className="flex align-center m-b-1" />
            </div>
            <Form value={formData} onChange={updateForm}>
              <Form.Input
                field="search"
                fluid
                placeholder={
                  isContactList
                    ? "Search contact name, email, company, job title"
                    : "Search company name"
                }
              />
              <LettersFilter
                onLetterFilterChanged={this.onLetterFilterChanged}
              />

              {hasStatus && (
                <ListStatusesDropdown
                  isContactList={isContactList}
                  listId={listId}
                />
              )}

              {/* id:70909 Customer wanted this filter removed, but only commented out in case they want it back in - NP 24/07/2019 */}
              {/* <UserTypeCheck restrictTo={UserTypes.External}>
                {isContactList == false ? (
                  <React.Fragment>
                    <Form.Group
                      widths="equal"
                      className="custom-fields-dropdowns"
                    >
                      <CustomFieldNamesDropdown />
                      {formData.name && (
                        <a className="purple-link" onClick={clearName}>
                          (Clear)
                        </a>
                      )}
                      <CustomFieldValuesDropdown
                        customFieldId={formData.name}
                        disabled={
                          formData.name === undefined || formData.name === null
                        }
                        multiple={true}
                        allowAdditions={false}
                      />
                      {!!(formData.value && formData.value.length) && (
                        <a className="purple-link" onClick={clearValue}>
                          (Clear)
                        </a>
                      )}
                    </Form.Group>
                    <Form.Trigger
                      event="onSet"
                      field="name"
                      target="value"
                      action="clear"
                    />
                  </React.Fragment>
                ) : (
                  <ContactTagsDropdown
                    multiple={true}
                    required={false}
                    allowAdditions={false}
                  />
                )}
              </UserTypeCheck> */}
            </Form>
          </FilterBase.Body>
        </section>
      </Segment>
    );
  }
}

const actions = {
  clearSearch,
  updateForm,
  clearName,
  clearValue
};

const mapStateToProps = state => {
  const { userId } = getAuthClaims(state);
  const { formData, isContactList, listId } = getListsOverviewState(state);
  return { userId, formData, isContactList, listId };
};

export default connect(
  mapStateToProps,
  actions
)(ListFilters);
