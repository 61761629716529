import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "LISTS/LIST_LISTS";

export const ActionTypes = {
  GetLists: `${NAMESPACE}/GET_LISTS`,
  GetRecentlyViewed: `${NAMESPACE}/RECENTLY_VIEWED`,
  ClearRecentlyViewed: `${NAMESPACE}/CLEAR_RECENTLY_VIEWED`
};

export const getLists = ({
  filters,
  pagination,
  selection
}) => async dispatch => {
  const excludeCompany = !~(filters.types || []).findIndex(
    i => i == "companies"
  );
  const excludeContact = !~(filters.types || []).findIndex(
    i => i == "contacts"
  );
  const params = {
    ...filters,
    ...pagination,
    excludeCompany,
    excludeContact
  };

  dispatch(getRecentlyViewed(filters));

  return dispatch(apiGet(ActionTypes.GetLists, "lists", params));
};

export const clearRecentlyViewed = () => dispatch => {
  dispatch({ type: ActionTypes.ClearRecentlyViewed });
};

export const getRecentlyViewed = filters => {
  const excludeCompany = !~(filters.types || []).findIndex(
    i => i == "companies"
  );
  const excludeContact = !~(filters.types || []).findIndex(
    i => i == "contacts"
  );
  const params = {
    ...filters,
    take: 3,
    excludeCompany,
    excludeContact
  };

  return apiGet(ActionTypes.GetRecentlyViewed, "lists/recently-viewed", params);
};
