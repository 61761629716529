import { NavMenu } from "@redriver/cinnamon";
import CompaniesActive from "assets/icons/companies-active.svg";
import Companies from "assets/icons/companies.svg";
import ContactsActive from "assets/icons/contacts-active.svg";
import Contacts from "assets/icons/contacts.svg";
import CustomersActive from "assets/icons/customers-active.svg";
import Customers from "assets/icons/customers.svg";
import SecureFileAreaActive from "assets/icons/folder-active.svg";
import SecureFileArea from "assets/icons/folder.svg";
import HomeActive from "assets/icons/home-active.svg";
import Home from "assets/icons/home.svg";
import ListsActive from "assets/icons/lists-active.svg";
import Lists from "assets/icons/lists.svg";
import Logout from "assets/icons/logout.svg";
import NewspaperActive from "assets/icons/newspaper-active.svg";
import Newspaper from "assets/icons/newspaper.svg";
import QuestionActive from "assets/icons/question-active.svg";
import Question from "assets/icons/question.svg";
import RolesActive from "assets/icons/roles-active.svg";
import Roles from "assets/icons/roles.svg";
import Settings from "assets/icons/settings-gears-black.svg";
import SettingsActive from "assets/icons/settings-gears-white.svg";
import Spy from "assets/icons/spy.svg";
import UploadsActive from "assets/icons/uploads-active.svg";
import Uploads from "assets/icons/uploads.svg";
import UsersActive from "assets/icons/users-active.svg";
import Users from "assets/icons/users.svg";
import LogoCompact from "assets/logo/logo-compact.svg";
import LogoText from "assets/logo/logo-textonly.svg";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes, SystemRoutes } from "constants/routes";
import { NotificationNavMenuItem } from "features/Notifications";
import React from "react";
import { Image } from "semantic-ui-react";

const I4BMenu = ({ userName, isInternal, impersonator, customer }) => (
  <NavMenu className="i4b-main-menu">
    <NavMenu.Header
      link={{ pathname: process.env.I4B_MARKETING_URL }}
      size="large"
      target="_blank"
      style={{ display: "inline-block" }}
    >
      <div style={{ display: "inline-block" }}>
        <Image src={LogoCompact} alt="I4B Logo" className="i4b-logo-left" />
      </div>
      <div className="i4b-logo-right-holder">
        <Image src={LogoText} alt="I4B Logo" className="i4b-logo-right" />
      </div>
    </NavMenu.Header>

    <NavMenu.Item link={AppRoutes.Root} exact>
      {({ active }) => (
        <React.Fragment>
          <div className="icon">
            <Image src={active ? HomeActive : Home} aria-hidden="true" />
          </div>
          <div className="content">Dashboard</div>
        </React.Fragment>
      )}
    </NavMenu.Item>

    <PermissionCheck target={Targets.Company} action={Actions.View}>
      <NavMenu.Item link={AppRoutes.Companies} exact>
        {({ active }) => (
          <React.Fragment>
            <div className="icon">
              <Image
                src={active ? CompaniesActive : Companies}
                aria-hidden="true"
              />
            </div>
            <div className="content">Companies</div>
          </React.Fragment>
        )}
      </NavMenu.Item>
    </PermissionCheck>

    <PermissionCheck target={Targets.Contacts} action={Actions.View}>
      <NavMenu.Item link={AppRoutes.Contacts} exact>
        {({ active }) => (
          <React.Fragment>
            <div className="icon">
              <Image
                src={active ? ContactsActive : Contacts}
                aria-hidden="true"
              />
            </div>
            <div className="content">Contacts</div>
          </React.Fragment>
        )}
      </NavMenu.Item>
    </PermissionCheck>

    <PermissionCheck target={Targets.List} action={Actions.View}>
      <NavMenu.Item link={AppRoutes.Lists}>
        {({ active }) => (
          <React.Fragment>
            <div className="icon">
              <Image src={active ? ListsActive : Lists} aria-hidden="true" />
            </div>
            <div className="content">Lists</div>
          </React.Fragment>
        )}
      </NavMenu.Item>
    </PermissionCheck>

    <NavMenu.Item link={AppRoutes.Uploads} exact>
      {({ active }) => (
        <React.Fragment>
          <div className="icon">
            <Image src={active ? UploadsActive : Uploads} aria-hidden="true" />
          </div>
          <div className="content">Uploads</div>
        </React.Fragment>
      )}
    </NavMenu.Item>

    <PermissionCheck target={Targets.SecureFile} action={Actions.View}>
      <NavMenu.Item link={AppRoutes.SecureFileArea} exact>
        {({ active }) => (
          <React.Fragment>
            <div className="icon">
              <Image
                src={active ? SecureFileAreaActive : SecureFileArea}
                aria-hidden="true"
              />
            </div>
            <div className="content">Files</div>
          </React.Fragment>
        )}
      </NavMenu.Item>
    </PermissionCheck>
    <PermissionCheck target={Targets.Settings} action={Actions.View}>
      <NavMenu.Item link={AppRoutes.Settings}>
        {({ active }) => (
          <React.Fragment>
            <div className="icon">
              <Image
                src={active ? SettingsActive : Settings}
                aria-hidden="true"
              />
            </div>
            <div className="content">Settings</div>
          </React.Fragment>
        )}
      </NavMenu.Item>
    </PermissionCheck>

    <NavMenu.Divider />
    {isInternal && (
      <React.Fragment>
        <PermissionCheck target={Targets.Customers} action={Actions.View}>
          <NavMenu.Item link={AppRoutes.Customers}>
            {({ active }) => (
              <React.Fragment>
                <div className="icon">
                  <Image
                    src={active ? CustomersActive : Customers}
                    aria-hidden="true"
                  />
                </div>
                <div className="content">Customers</div>
              </React.Fragment>
            )}
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck target={Targets.User} action={Actions.View}>
          <NavMenu.Item link={AppRoutes.Users}>
            {({ active }) => (
              <React.Fragment>
                <div className="icon">
                  <Image
                    src={active ? UsersActive : Users}
                    aria-hidden="true"
                  />
                </div>
                <div className="content">Users</div>
              </React.Fragment>
            )}
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck target={Targets.Role} action={Actions.View}>
          <NavMenu.Item link={AppRoutes.Roles} exact>
            {({ active }) => (
              <React.Fragment>
                <div className="icon">
                  <Image
                    src={active ? RolesActive : Roles}
                    aria-hidden="true"
                  />
                </div>
                <div className="content">Roles</div>
              </React.Fragment>
            )}
          </NavMenu.Item>
        </PermissionCheck>
        <NavMenu.Item link={AppRoutes.SegmentRemoval} exact>
          {({ active }) => (
            <React.Fragment>
              <div className="icon">
                <Image
                  src={active ? UploadsActive : Uploads}
                  aria-hidden="true"
                />
              </div>
              <div className="content">Segment Removal</div>
            </React.Fragment>
          )}
        </NavMenu.Item>
        <PermissionCheck target={Targets.Reports} action={Actions.View}>
          <NavMenu.Item link={AppRoutes.Reports}>
            {({ active }) => (
              <React.Fragment>
                <div className="icon">
                  <Image
                    src={active ? SecureFileAreaActive : SecureFileArea}
                    aria-hidden="true"
                  />
                </div>
                <div className="content">Reports</div>
              </React.Fragment>
            )}
          </NavMenu.Item>
        </PermissionCheck>
      </React.Fragment>
    )}
    <PermissionCheck target={Targets.ContactOptOuts} action={Actions.View}>
      <NavMenu.Item link={AppRoutes.OptOutReport}>
        {({ active }) => (
          <React.Fragment>
            <div className="icon">
              <Image
                src={active ? NewspaperActive : Newspaper}
                aria-hidden="true"
              />
            </div>
            <div className="content">Opt Out Files</div>
          </React.Fragment>
        )}
      </NavMenu.Item>
    </PermissionCheck>
    <NavMenu.Item link={AppRoutes.KnowledgeBase}>
      {({ active }) => (
        <React.Fragment>
          <div className="icon">
            <Image
              src={active ? QuestionActive : Question}
              aria-hidden="true"
            />
          </div>
          <div className="content">Knowledge Base</div>
        </React.Fragment>
      )}
    </NavMenu.Item>
    {!isInternal && (
      <PermissionCheck target={Targets.CustomerUserStats} action={Actions.View}>
        <NavMenu.Item
          link={AppRoutes.UserStats}
          icon={<Image src={Users} title=" User Stats" color="#4e4e4e" />}
        >
          User Stats
        </NavMenu.Item>
      </PermissionCheck>
    )}

    {impersonator && (
      <NavMenu.Footer
        className="navitem-notification active active-alert"
        icon={<Image src={Spy} />}
      >
        <div style={{ fontSize: "0.75rem" }}>
          {userName}
          <br />
          {customer}
        </div>
      </NavMenu.Footer>
    )}

    <NotificationNavMenuItem />

    <NavMenu.Footer
      link={SystemRoutes.Logout}
      exact
      style={{ marginBottom: "2rem" }}
      icon={<Image src={Logout} title="Sign Out" />}
    >
      Sign Out
    </NavMenu.Footer>
  </NavMenu>
);

export default I4BMenu;
