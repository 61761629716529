import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { CompanyRoutes } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";

class ContactsResultsTableRowContent extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    state: PropTypes.object,
    events: PropTypes.object,
    contacts: PropTypes.object,
    changeSelection: PropTypes.func,
    listId: PropTypes.string
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.item) {
      return false;
    }

    return this.props.state.expanded !== nextProps.state.expanded;
  }

  render() {
    const {
      listId,
      item,
      state,
      events,
      hideCompany,
      viewingUniverse,
      changeSelection
    } = this.props;

    return (
      <React.Fragment>
        {!hideCompany && (
          <Table.Column
            field="company"
            title={listId ? "" : "Select All"}
            render={item => {
              if (state.expanded) {
                return (
                  <PermissionCheck
                    target={Targets.Company}
                    action={Actions.View}
                  >
                    <Link to={CompanyRoutes.Overview(item.companyId)}>
                      <span>{item.companyName}</span>
                    </Link>
                  </PermissionCheck>
                );
              } else {
                return <span>{item.companyName}</span>;
              }
            }}
          />
        )}
        <Table.Column
          title={hideCompany && !listId && "Select All"}
          render={item => {
            return (
              <React.Fragment>
                <span className={item.leftCompany ? "color-red" : undefined}>
                  {item.name}
                </span>
                {item.leftCompany && (
                  <Popup
                    trigger={
                      <Icon
                        name="info circle"
                        style={{ marginLeft: "0.25rem" }}
                      />
                    }
                    content="Has Left Company"
                    position="top center"
                    className="table-popup status-text"
                  />
                )}
              </React.Fragment>
            );
          }}
          field="name"
        />
        <Table.Column field="jobTitle" />
        <Table.Column
          render={() =>
            !viewingUniverse && (
              <Icon name={state.expanded ? "angle up" : "angle down"} />
            )
          }
          className="tiny-col"
        />
      </React.Fragment>
    );
  }
}

export default ContactsResultsTableRowContent;
