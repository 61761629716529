import { Breadcrumb } from "@redriver/cinnamon";
import Home from "assets/icons/home_breadcrumb.svg";
import { AppRoutes, CustomerRoutes } from "constants/routes";
import {
  CustomerBreadcrumbItem,
  CustomerMenu,
  CustomerOverview,
  CustomerPreLoader,
  ListCustomFields,
  ListCustomFieldValues,
  ListSuppressions,
  ListCustomerUserStats,
  ViewCustomerContacts,
  ViewCustomerSegment,
  ViewCustomerTags,
  ViewCustomerUsers
} from "features/Customers";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Grid, Image } from "semantic-ui-react";

const CustomerRoute = ({ match }) => {
  return (
    <CustomerPreLoader customerId={match.params.id}>
      <Breadcrumb className="i4b-breadcrumb-container">
        <Breadcrumb.Item
          link={AppRoutes.Root}
          icon={<Image src={Home} title="Home" />}
        />
        <Breadcrumb.Item link={AppRoutes.Customers} content="Customers" />
        <CustomerBreadcrumbItem customerId={match.params.id} />
      </Breadcrumb>

      <Grid className="customer-page p-t-1">
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={2}>
            <CustomerMenu customerId={match.params.id} />
          </Grid.Column>
          <Switch>
            <Route
              exact
              path={CustomerRoutes.Overview()}
              render={props => (
                <CustomerOverview customerId={match.params.id} />
              )}
            />
            <Route
              exact
              path={CustomerRoutes.CustomFields()}
              render={props => (
                <ListCustomFields customerId={match.params.id} />
              )}
            />
            <Route
              exact
              path={CustomerRoutes.CustomFieldValues()}
              render={props => (
                <ListCustomFieldValues customerId={match.params.id} />
              )}
            />
          </Switch>
          <Switch>
            <Route
              exact
              path={CustomerRoutes.ContactCustomFields()}
              render={props => (
                <ViewCustomerTags customerId={match.params.id} />
              )}
            />
          </Switch>
          <Switch>
            <Route
              exact
              path={CustomerRoutes.Users()}
              render={props => (
                <ViewCustomerUsers customerId={match.params.id} />
              )}
            />
          </Switch>
          <Switch>
            <Route
              exact
              path={CustomerRoutes.Contacts()}
              render={props => (
                <ViewCustomerContacts customerId={match.params.id} />
              )}
            />
          </Switch>
          <Switch>
            <Route
              exact
              path={CustomerRoutes.Segment()}
              render={props => (
                <ViewCustomerSegment customerId={match.params.id} />
              )}
            />
          </Switch>
          <Switch>
            <Route
              exact
              path={CustomerRoutes.Suppressions()}
              render={props => (
                <ListSuppressions customerId={match.params.id} />
              )}
            />
          </Switch>
          <Switch>
            <Route
              exact
              path={CustomerRoutes.UserStats()}
              render={props => (
                <ListCustomerUserStats customerId={match.params.id} />
              )}
            />
          </Switch>
        </Grid.Row>
      </Grid>
    </CustomerPreLoader>
  );
};

export default CustomerRoute;
