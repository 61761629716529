import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck, UserTypeCheck } from "components/auth";
import { Actions, Targets, UserTypes } from "constants/permissions";
import React from "react";
import { Icon } from "semantic-ui-react";

const AddSuppressionsModal = ({ submitParams, submitAction, onSubmitted }) => {
  return (
    <PermissionCheck target={Targets.Items} action={Actions.Suppress}>
      <ModalBuilder
        initialData={submitParams}
        submitAction={submitAction}
        submitParams={submitParams}
        onSubmitted={onSubmitted}
        renderTrigger={showModal => (
          <UserTypeCheck restrictTo={UserTypes.External}>
            <li onClick={showModal}>
              <div className="dropdown-action-container">
                <a>
                  Suppress{" "}
                  {submitParams.isContact
                    ? submitParams.selectedItems === 1
                      ? "contact"
                      : "contacts"
                    : submitParams.selectedItems === 1
                    ? "company"
                    : "companies"}
                </a>
              </div>
            </li>
          </UserTypeCheck>
        )}
        renderModal={(modalProps, formProps) => (
          <Modal.Confirmation
            submitLabel="Add Suppressions"
            cancelLabel="Cancel"
            {...modalProps}
            header={
              <div>
                <Icon name="plus" /> Add Suppressions
              </div>
            }
          >
            <p>
              Are you sure you want to suppress {submitParams.selectedItems}{" "}
              {submitParams.isContact
                ? submitParams.selectedItems === 1
                  ? "contact"
                  : "contacts"
                : submitParams.selectedItems === 1
                ? "company"
                : "companies"}
            </p>
          </Modal.Confirmation>
        )}
      />
    </PermissionCheck>
  );
};

export default AddSuppressionsModal;
