import { apiDelete, apiGet, apiPost, apiPut } from "@redriver/cinnamon";
import { mapPermissions } from "./helpers";

const NAMESPACE = "SETTINGS/LIST_ROLES";

export const ActionTypes = {
  GetRoles: `${NAMESPACE}/GET_ROLES`,
  AddRole: `${NAMESPACE}/ADD_ROLE`,
  UpdateRole: `${NAMESPACE}/UPDATE_ROLE`,
  DeleteRole: `${NAMESPACE}/DELETE_ROLE`,
  GetAllPermissions: `${NAMESPACE}/GET_ALL_PERMISSIONS`,
  GetRolePermissions: `${NAMESPACE}/GET_ROLE_PERMISSIONS`
};

export const getRoles = ({ filters }) => async dispatch => {
  const { success, response, error } = await dispatch(
    apiGet(ActionTypes.GetRoles, "roles", {
      pageSize: 100,
      pageNumber: 1
    })
  );

  if (success && response && filters.search) {
    response.results = response.results.filter(function(el) {
      return el.name.toLowerCase().indexOf(filters.search.toLowerCase()) > -1;
    });
  }

  return { success, response, error };
};

export const addRole = role => apiPost(ActionTypes.AddRole, "roles", role);

export const updateRole = (role, id) =>
  apiPut(ActionTypes.UpdateRole, `roles/${id}`, role);

export const deleteRole = id =>
  apiDelete(ActionTypes.DeleteRole, `roles/${id}`);

export const getAllPermissions = () => async dispatch => {
  let { success, response, error } = await dispatch(
    apiGet(ActionTypes.GetAllPermissions, "roles/permissions")
  );

  if (success && response) {
    response = {
      permissions: mapPermissions(response.categories)
    };
  }

  return { success, response, error };
};

export const loadEditRole = role => async dispatch => {
  const result = await Promise.all([
    dispatch(getAllPermissions()),
    dispatch(
      apiGet(ActionTypes.GetRolePermissions, `roles/${role.id}/permissions`)
    )
  ]);

  const allPermissions = result[0];
  const rolePermissions = result[1];

  if (!allPermissions.success || !allPermissions.response) {
    return allPermissions;
  }
  if (!rolePermissions.success || !rolePermissions.response) {
    return rolePermissions;
  }

  return {
    success: true,
    response: {
      permissions: allPermissions.response.permissions || [],
      name: role.name,
      description: role.description,
      type: role.type,
      permissionRefs: rolePermissions.response || []
    }
  };
};
