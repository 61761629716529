import React from "react";
const ExternalLink = ({ link, className }) => {
  const san = link && link.trim();
  const href = san.startsWith("http") ? san : "//" + san;

  return (
    <a className={className} target="_blank" href={href}>
      {san || "-"}
    </a>
  );
};

export default ExternalLink;
