import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";
import SelectionCollapseText, { renderTags } from "./SelectionCollapseText";
import TreeViewCriteria from "./TreeViewCriteria";

const ContactTagsCriteria = ({ active, handleClick, criteria }) => {
  const {
    match,
    tags: { included, excluded }
  } = criteria.tagsSelection;

  if (!included.length && !excluded.length) return null;

  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Contact Custom Fields"
        text={
          <SelectionCollapseText
            included={included}
            excluded={excluded}
            renderItem={renderTags}
          />
        }
      />
      <Accordion.Content active={active}>
        <label>
          Matches {match == "all" ? "all" : "at least one"} of these contact
          custom fields
        </label>
        <TreeViewCriteria
          criteria={criteria}
          lookup="contactTagsCriteriaLookup"
        />
      </Accordion.Content>
    </React.Fragment>
  );
};

export default ContactTagsCriteria;
