import { Form, FormBuilder } from "@redriver/cinnamon";
import { UploadRoutes } from "constants/routes";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { uploadFileForProcessing } from "./actions";

class UploadFile extends Component {
  onFileUploaded = response => {
    this.props.history.push(UploadRoutes.Upload(response.id));
  };

  render() {
    return (
      <FormBuilder
        submitAction={uploadFileForProcessing}
        onSubmitted={this.onFileUploaded}
        renderError={false}
        renderForm={(formProps, { error }, { onSubmit, onReset }) => {
          if (!!error) {
            onReset();
          }
          return (
            <Form {...formProps}>
              <Form.FileUpload
                fileExtensionTypes={["xlsx", "csv"]}
                field="file"
                fluid
                className={`i4b-purple-uploader${
                  !!error ? ` upload-failed` : ""
                }`}
                uploadText="Drag your file here to upload, or click to browse files"
                replaceIcon="file excel outline"
                replaceText="Please check that your file is a CSV or an Excel XLSX and try again"
              />
              <Form.Trigger field="file" event="onChange" action={onSubmit} />
            </Form>
          );
        }}
      />
    );
  }
}

export default withRouter(UploadFile);
