import { apiPut, authenticate, getAuthState } from "@redriver/cinnamon";
import { refreshJwt } from "features/System";

const NAMESPACE = "SYSTEM/REVERIFY_EMAIL";
export const RE_VERIFY_EMAIL = `${NAMESPACE}/RE_VERIFY_EMAIL`;
export const EMAIL_VERIFIED = `${NAMESPACE}/EMAIL_VERIFIED`;

export const verifyEmail = token => async (dispatch, getState) => {
  const { success } = await dispatch(
    apiPut(RE_VERIFY_EMAIL, `users/reverify-email`, token, {
      preventErrorNotification: true,
      auth: false
    })
  );
  if (success) {
    const { loggedIn, refreshToken } = getAuthState(getState());
    if (loggedIn) {
      try {
        const { success: refreshSuccess, response } = await dispatch(
          refreshJwt(refreshToken)
        );
        if (refreshSuccess) {
          await dispatch(
            authenticate(response.tokens.access, response.tokens.refresh, true)
          );
          dispatch({ type: EMAIL_VERIFIED });
        }
      } catch (error) {
        return;
      }
    } else {
      dispatch({ type: EMAIL_VERIFIED });
    }
  }
};
