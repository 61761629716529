import { Form, PageLoader } from "@redriver/cinnamon";
import { CustomCheckboxTree } from "components/form";
import { AppRoutes } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { ContactUploadsLookup } from "../lookups";
import FilterBase from "./FilterBase";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";
import FilterClearer from "./FilterClearer";

class ContactUploadsFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    results: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    clearSelected: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired
  };

  state = {
    visible: true,
    total: null,
    included: [],
    excluded: []
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  componentDidMount = () => {
    const { id, formData } = this.props;
    this.getSelected(formData, id);
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  componentDidUpdate = prevProps => {
    const { id, formData } = this.props;
    if (prevProps.formData !== formData) {
      this.getSelected(formData, id);
    }
  };

  getSelected = (formData, id) => {
    const { searchCriteria } = formData;
    const uploadsObj = searchCriteria.find(el => el.id == id);
    if (!uploadsObj || !uploadsObj.uploadsSearch) return;

    const { uploadsSearch } = uploadsObj;
    const { uploadsSelection } = uploadsSearch;
    const { included, excluded } = uploadsSelection;

    this.setState({
      included: included.length || null,
      excluded: excluded.length || null
    });
  };

  render() {
    const { visible, included, excluded, total } = this.state;
    const {
      id,
      loading,
      pageChanged,
      removeFilter,
      clearSelected,
      history
    } = this.props;

    return (
      <ContactUploadsLookup
        render={({ response }, refresh) => {
          return (
            <FilterBase
              total={total}
              loading={loading && !pageChanged}
              className="i4b-filter-base"
            >
              {response ? (
                <React.Fragment>
                  <FilterBase.Header
                    title="Contact Uploads"
                    collapsible
                    collapsed={!visible}
                    onCollapseToggle={this.toggleVisibility}
                    removable
                    onRemove={event => removeFilter(id, event)}
                    showClearer={!!included || !!excluded}
                    onClear={clearSelected}
                  >
                    {!visible && (
                      <div className="ellipsis-paragraph">
                        {included && <span>Include: {included}</span>}
                        {excluded && <span>Exclude: {excluded}</span>}
                        {!included && !excluded && "No uploads selected"}
                      </div>
                    )}
                  </FilterBase.Header>
                  <FilterBase.Body collapsed={!visible}>
                    <Button
                      className="primary green-small-button"
                      style={{ marginTop: "1rem" }}
                      onClick={() => history.push(AppRoutes.Uploads)}
                    >
                      Upload a file
                    </Button>
                    <Button
                      className="grey-small-button"
                      style={{ marginTop: "1rem" }}
                      onClick={() => refresh()}
                    >
                      Refresh
                    </Button>
                    <div className="i4b-filter-container">
                      <Form.Object field="uploadsSearch">
                        <CustomCheckboxTree
                          key={id}
                          className="i4b-filter-checktree"
                          field="uploadsSelection"
                          nodes={[
                            {
                              value: "Uploads",
                              text: "Uploads",
                              defaultExpanded: true,
                              children: response || []
                            }
                          ]}
                        />
                      </Form.Object>
                    </div>
                  </FilterBase.Body>
                </React.Fragment>
              ) : (
                <PageLoader
                  active
                  inline="centered"
                  size="small"
                  className="p-y-05"
                />
              )}
            </FilterBase>
          );
        }}
      />
    );
  }
}

export default withRouter(ContactUploadsFilter);
