import React from "react";
import { Button, Icon, Image, Popup } from "semantic-ui-react";
import { Form, FormBuilder, Format } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";

import { updateCustomerDetails } from "./actions";
import Customer from "assets/icons/building.svg";
import Company from "assets/icons/company.svg";
import People from "assets/icons/people.svg";
import Money from "assets/icons/money.svg";
import Calendar from "assets/icons/calendar.svg";
import Megaphone from "assets/icons/megaphone.svg";

const CustomerDetailsForm = ({ data, onSubmitted, customerId }) => {
  return (
    <FormBuilder
      submitAction={updateCustomerDetails}
      submitParams={customerId}
      onSubmitted={onSubmitted}
      initialData={data}
      initialReadOnly
      renderError={false}
      renderForm={(formProps, state, events) => (
        <Form {...formProps} className="customer-details-form">
          <div className="flex">
            <h2 className="m-r-1">General Details</h2>
            {state.readOnly ? (
              <PermissionCheck target={Targets.Customers} action={Actions.Edit}>
                <Icon
                  className="primary cursor-pointer"
                  name="pencil"
                  onClick={events.onToggleReadOnly}
                />
              </PermissionCheck>
            ) : (
              <React.Fragment>
                <Button
                  className="grey-small-button"
                  content="Cancel"
                  onClick={events.onToggleReadOnly}
                />
                <Button
                  className="primary green-small-button"
                  content="Save"
                  onClick={events.onSubmit}
                  loading={state.submitting}
                />
              </React.Fragment>
            )}
          </div>
          <ul className="list-unstyled m-t-05">
            <li>
              <Popup
                trigger={<Icon name="user" />}
                content="Account Manager"
                position="top center"
              />
              <Form.Input
                field="accountManager"
                placeholder="Account Manager"
                fluid
                required
              />
            </li>
            <li>
              <Popup
                trigger={<Image src={Customer} />}
                content="Customer Name"
                position="top center"
              />
              <Form.Group widths="equal">
                <Form.Input
                  field="fullName"
                  placeholder="Full Name"
                  fluid
                  required
                  title="Full Name"
                  className="p-r-1"
                  renderReadOnly={({ value }) => (
                    <Popup
                      trigger={<p>{value}</p>}
                      content={
                        <React.Fragment>
                          AKA {formProps.value.akaName}
                        </React.Fragment>
                      }
                      position="top center"
                    />
                  )}
                />
                {!state.readOnly && (
                  <Form.Input
                    field="akaName"
                    placeholder="AKA Name"
                    fluid
                    title="AKA Name"
                    required
                  />
                )}
              </Form.Group>
            </li>
            <li>
              <Popup
                trigger={<Image src={Calendar} />}
                content="Contract Start Date"
                position="top center"
              />
              {!state.readOnly ? (
                <Form.Group>
                  <Form.Date
                    field="contractStartDate"
                    local
                    required
                    fluid
                    title="Contract Start Date"
                    className="p-r-1"
                  />
                  <Form.Date
                    field="initialEnd"
                    local
                    required
                    title="Initial End"
                    fluid
                  />
                </Form.Group>
              ) : (
                <p>
                  <Format.Date
                    value={formProps.value.contractStartDate}
                    format="short"
                  />
                  {" to "}
                  <Format.Date
                    value={formProps.value.initialEnd}
                    format="short"
                  />
                </p>
              )}
            </li>
            <li>
              <Popup
                trigger={<Image src={People} />}
                content="Number of Users"
                position="top center"
              />
              <Form.Numeric
                field="numUsers"
                placeholder="Number of Users"
                required
                fluid
              />
            </li>
            <li>
              <Popup
                trigger={<Image src={Money} />}
                content="Monthly Revenue"
                position="top center"
              />
              <Form.Numeric
                field="monthlyRevenue"
                placeholder="Monthly Revenue"
                required
                fluid
                decimalPlaces={2}
                formatValue
                subLabel="£"
                subLabelPosition="left"
                renderReadOnly={({ value }, state) =>
                  !!value ? (
                    <Popup
                      trigger={
                        <p>
                          <Format.Number value={value} format="GBP" />
                        </p>
                      }
                      content={
                        <React.Fragment>
                          Annual:{" "}
                          <Format.Number value={value * 12} format={"GBP"} />
                        </React.Fragment>
                      }
                      position="top center"
                    />
                  ) : (
                    "-"
                  )
                }
              />
            </li>
            <li>
              <Popup
                trigger={<Image src={Megaphone} />}
                content="Notice Period"
                position="top center"
              />
              <Form.Input
                field="noticePeriod"
                placeholder="Notice Period"
                required
                fluid
              />
            </li>
            <li>
              <Popup
                trigger={<Image src={Company} />}
                content="Company Name"
                position="top center"
              />
              <p>{formProps.value.companyName || "-"}</p>
            </li>
          </ul>
        </Form>
      )}
    />
  );
};

export default CustomerDetailsForm;
