import React from "react";

const CompanyLocationDetails = ({ data, isSuppressed }) => {
  let address = "";
  if (!isSuppressed) {
    address = data.hq
      ? `${data.hq.line1}
  ${!!data.hq.line2 ? "," + data.hq.line2 : ""}
  ${!!data.hq.line3 ? "," + data.hq.line3 : ""}
  ${!!data.hq.city ? "," + data.hq.city : ""}
  ${!!data.hq.postcode ? "," + data.hq.postcode : ""}
  ${!!data.hq.country ? "," + data.hq.country : ""}`
      : "";
  }
  const url = `https://www.google.com/maps/embed/v1/place?key=${
    process.env.GOOGLE_MAPS_API_KEY
  }&q=${encodeURIComponent(address)}`;

  return (
    <section>
      <div className="flex">
        <h2 className="m-r-1">Location</h2>
      </div>
      <div className="p-t-1">
        {isSuppressed ? (
          <p>Location is suppressed</p>
        ) : address !== "" ? (
          <iframe
            width="600"
            height="300"
            frameBorder="0"
            style={{ border: 0 }}
            src={url}
            allowFullScreen
          />
        ) : (
          <p>Location Unknown</p>
        )}
      </div>
    </section>
  );
};

export default CompanyLocationDetails;
