import { Form } from "@redriver/cinnamon";
import { RoleDropdown } from "features/Roles/lookups";
import React from "react";

const UserForm = ({ formProps, disableEmail, requireCustomer }) => (
  <Form {...formProps}>
    <Form.Group widths="equal">
      <Form.Input
        label="First Name"
        field="firstName"
        placeholder="First Name"
        required
        fluid
      />
      <Form.Input
        label="Last Name"
        field="lastName"
        placeholder="Last Name"
        required
        fluid
      />
    </Form.Group>

    <Form.Email
      label="Email"
      field="email"
      placeholder="Email"
      required
      fluid
      disabled={disableEmail}
    />

    {requireCustomer && (
      <Form.TypeAhead
        field="customerId"
        label="Customer"
        placeholder="Type to search customers"
        required
        fluid
        lookup={"customerNamesDropdownLookup"}
      />
    )}

    <RoleDropdown
      field="roleIds"
      label="Role"
      required
      multiple
      fluid
      unknownValueOptions={({ fields }) => fields.roles}
    />
  </Form>
);

export default UserForm;
