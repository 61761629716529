import { apiPut } from "@redriver/cinnamon";

const NAMESPACE = "USERS/VIEW_CUSTOMER";

export const ActionTypes = {
  UpdateCustomerDetails: `${NAMESPACE}/UPDATE_CUSTOMER_DETAILS`,
  UpdateCustomerSubscription: `${NAMESPACE}/UPDATE_CUSTOMER_SUBSCRIPTION`,
  UpdateCustomerAccess: `${NAMESPACE}/UPDATE_CUSTOMER_ACCESS`
};

export const updateCustomerDetails = (formData, customerId) =>
  apiPut(
    ActionTypes.UpdateCustomerDetails,
    `customers/${customerId}/details`,
    formData
  );

export const updateCustomerSubscription = (formData, customerId) => {
  return apiPut(
    ActionTypes.UpdateCustomerSubscription,
    `customers/${customerId}/subscription`,
    formData
  );
};

export const updateCustomerAccess = (formData, customerId) => {
  const request = {
    lockOutNow: formData.lockOutNow,
    lockOutFrom: formData.lockOutFrom,
    finalDateUtc: formData.finalDateUtc
  };
  return apiPut(
    ActionTypes.UpdateCustomerSubscription,
    `customers/${customerId}/access`,
    request
  );
};
