import React from "react";
import { SidePanel } from "@redriver/cinnamon";

import LoadSearchSidePanelContent from "./LoadSearchSidePanelContent";

const LoadSearchSidePanel = ({
  loadAction,
  loadParams,
  onSelected,
  deleteAction,
  open,
  toggleOpenState
}) => {
  return (
    <SidePanel
      open={open}
      onClose={toggleOpenState}
      closeIcon
      size="53%"
      className="i4b-sidepanel-load-search i4b-side-panel"
    >
      <h1>Load a Saved Search</h1>
      <div className="m-b-1">
        <LoadSearchSidePanelContent
          loadAction={loadAction}
          loadParams={loadParams}
          onSearchSelected={props => {
            onSelected(props);
            toggleOpenState();
          }}
          searchDeleteAction={deleteAction}
        />
      </div>
    </SidePanel>
  );
};

export default LoadSearchSidePanel;
