import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import DeleteUser from "./DeleteUser";
import EditUserStatus from "./EditUserStatus";
import ResendVerificationEmail from "./ResendVerificationEmail";
import SwitchUser from "./SwitchUser";
import ReverifyResetUserConfirmationModal from "../ViewUser/ReverifyResetUserConfirmationModal";

const ActionsColumn = ({ item, onChange, userType }) => (
  <Table.Menu
    direction="left"
    icon="ellipsis horizontal"
    className="actions-column"
  >
    <PermissionCheck target={Targets.User} action={Actions.Edit}>
      <EditUserStatus
        id={item.id}
        userIsDisabled={item.isDisabled}
        onSubmitted={onChange}
        renderTrigger={showModal => (
          <Table.MenuItem onClick={showModal}>
            <p>{item.isDisabled ? "Enable" : "Disable"}</p>
          </Table.MenuItem>
        )}
      />
    </PermissionCheck>
    <PermissionCheck target={Targets.User} action={Actions.Edit}>
      {!item.isEmailVerified && (
        <ResendVerificationEmail
          id={item.id}
          onSubmitted={onChange}
          renderTrigger={showModal => (
            <Table.MenuItem onClick={showModal}>
              <p>Resend Verification Email</p>
            </Table.MenuItem>
          )}
        />
      )}
    </PermissionCheck>
    <PermissionCheck target={Targets.User} action={Actions.Delete}>
      <DeleteUser
        id={item.id}
        onSubmitted={onChange}
        renderTrigger={showModal => (
          <Table.MenuItem onClick={showModal}>
            <p>Delete User</p>
          </Table.MenuItem>
        )}
      />
    </PermissionCheck>

    <PermissionCheck target={Targets.User} action={Actions.Impersonate}>
      {item.isEmailVerified && (
        <SwitchUser
          id={item.id}
          onSubmitted={onChange}
          renderTrigger={showModal => (
            <Table.MenuItem onClick={showModal}>
              <p>View as this user</p>
            </Table.MenuItem>
          )}
        />
      )}
    </PermissionCheck>
    <PermissionCheck target={Targets.User} action={Actions.Edit}>
      {userType == "External" && (
        <ReverifyResetUserConfirmationModal
          id={item.id}
          name={`${item.firstName} ${item.lastName}`}
          company={item.company}
          renderTrigger={showModal => (
            <Table.MenuItem onClick={showModal}>
              <p>Force to re-verify and reset password</p>
            </Table.MenuItem>
          )}
        />
      )}
    </PermissionCheck>
  </Table.Menu>
);

export default ActionsColumn;
