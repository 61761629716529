import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { editTag } from "./actions";

const EditTag = ({ item, renderTrigger, onSubmitted }) => (
  <ModalBuilder
    withForm
    submitAction={editTag}
    submitParams={item.id}
    onSubmitted={onSubmitted}
    renderTrigger={renderTrigger}
    initialData={{ value: item.value }}
    renderModal={(modalProps, formProps) => {
      return (
        <Modal.Edit
          submitLabel="Confirm"
          cancelLabel="Cancel"
          {...modalProps}
          header={
            <div>
              <Icon name="edit" /> Edit Field
            </div>
          }
        >
          <Form {...formProps}>
            <Grid>
              <Grid.Column width={16}>
                <Form.Input fluid field="value" label="Name" required />
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Edit>
      );
    }}
  />
);

export default EditTag;
