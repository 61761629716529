import { Form, FormBuilder } from "@redriver/cinnamon";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { uploadFile } from "./actions";

class UploadFile extends Component {
  render() {
    const { customerId, onFileUploaded } = this.props;
    return (
      <FormBuilder
        submitAction={uploadFile}
        submitParams={customerId}
        onSubmitted={onFileUploaded}
        renderError={false}
        renderForm={(formProps, { error }, { onSubmit, onReset }) => {
          if (!!error) {
            onReset();
          }
          return (
            <Form {...formProps}>
              <Form.FileUpload
                field="file"
                fluid
                className={`i4b-purple-uploader${
                  !!error ? ` upload-failed` : ""
                }`}
                uploadText="Drag your file here to upload, or click to browse files"
                replaceIcon="upload huge icon"
                replaceText="Drag your file here to upload, or click to browse files"
              />
              <Form.Trigger field="file" event="onChange" action={onSubmit} />
            </Form>
          );
        }}
      />
    );
  }
}

export default withRouter(UploadFile);
