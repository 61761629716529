export const renderTags = arr => arr.map(t => t.split("<>")[1]).join(", ");

const SelectionCollapseText = ({ included, excluded, renderItem }) =>
  !excluded.length
    ? renderItem(included)
    : !included.length
    ? `Excluded: ${renderItem(excluded)}`
    : `Included: ${renderItem(included)}. Excluded: ${renderItem(excluded)}`;

SelectionCollapseText.defaultProps = {
  renderItem: i => i.join(", ")
};

export default SelectionCollapseText;
