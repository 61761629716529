import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  lookupRequest,
  lookupReset
} from "@redriver/cinnamon";
import { getListsOverviewState } from "./selectors";

const NAMESPACE = "LISTS_OVERVIEW";

export const ActionTypes = {
  GetResults: `${NAMESPACE}/GET_RESULTS`,
  UpdatePageNumber: `${NAMESPACE}/UPDATE_PAGE_NUMBER`,
  UpdateHeaderForm: `${NAMESPACE}/UPDATE_HEADER_FORM`,
  UpdateSelection: `${NAMESPACE}/UPDATE_SELECTION`,
  UpdateSelectAll: `${NAMESPACE}/UPDATE_SELECT_ALL`,
  UpdateForm: `${NAMESPACE}/UPDATE_FORM`,
  ClearSearch: `${NAMESPACE}/CLEAR_SEARCH`,
  ListOverview: `${NAMESPACE}/LIST_OVERVIEW`,
  LoadOverviewModal: `${NAMESPACE}/LOAD_OVERVIEW_MODAL`,
  ApplyPendingChanges: `${NAMESPACE}/APPLY_PENDING_CHANGES`,
  ClearName: `${NAMESPACE}/CLEAR_NAME`,
  ClearValue: `${NAMESPACE}/CLEAR_VALUE`,
  UpdateLetterFilter: `${NAMESPACE}/UPDATE_LETTER_FILTER`,
  RenameList: `${NAMESPACE}/RENAME_LIST`,
  DeleteList: `${NAMESPACE}/DELETE_LIST`,
  ToggleBatchId: `${NAMESPACE}/TOGGLE_BATCH_ID`,
  ToggleAllBatchIds: `${NAMESPACE}/TOGGLE_ALL_BATCH_IDS`,
  ClearFieldsOrTags: `${NAMESPACE}/CLEAR_FIELDS_OR_TAGS`,
  SaveNotes: `${NAMESPACE}/SAVE_NOTES`,
  SuppressItems: `${NAMESPACE}/SUPPRESS_ITEMS`,
  RemoveItems: `${NAMESPACE}/REMOVE_ITEMS`,
  UpdateStatus: `${NAMESPACE}/UPDATE_STATUS`,
  ClearSelection: `${NAMESPACE}/CLEAR_SELECTION`,
  ClearState: `${NAMESPACE}/CLEAR_STATE`,
  GetContactDetails: `${NAMESPACE}/GET_CONTACT_DETAILS`,
  ToggleDetailsLoading: `${NAMESPACE}/TOGGLE_DETAILS_LOADING`,
  AddRelationsToList: `${NAMESPACE}/ADD_RELATIONS_TO_LIST`,
  GetDownloadCount: `${NAMESPACE}/GET_DOWNLOAD_COUNT`,
  GetContactDownload: `${NAMESPACE}/GET_CONTACT_DOWNLOAD`,
  GetCompanyDownload: `${NAMESPACE}/GET_COMPANY_DOWNLOAD`,
  AddContactTag: `${NAMESPACE}/ADD_CONTACT_TAG`,
  AddCompanyTag: `${NAMESPACE}/ADD_COMPANY_TAG`,
  DeleteContactTag: `${NAMESPACE}/DELETE_CONTACT_TAG`,
  UpdateContactFollowing: `${NAMESPACE}/UPDATE_CONTACT_FOLLOWING`,
  UpdateGroupedContactFollowing: `${NAMESPACE}/UPDATE_GROUPED_CONTACT_FOLLOWING`,
  UpdateCompanyFollowing: `${NAMESPACE}/UPDATE_COMPANY_FOLLOWING`,
  UpdateGroupedCompanyFollowing: `${NAMESPACE}/UPDATE_GROUPED_COMPANY_FOLLOWING`,
  UpdateNameSearch: `${NAMESPACE}/UPDATE_NAME_SEARCH`,
  SaveList: `${NAMESPACE}/SAVE_LIST`,
  AddToList: `${NAMESPACE}/ADD_TO_LIST`,
  GetSegmentAdditions: `${NAMESPACE}/GET_SEGMENT_ADDITIONS`,
  GetSegmentRemovals: `${NAMESPACE}/GET_SEGMENT_REMOVALS`,
  AddCompaniesToSegments: `${NAMESPACE}/ADD_COMPANIES_TO_SEGMENT`,
  RemoveCompaniesFromSegments: `${NAMESPACE}/REMOVE_COMPANIES_FROM_SEGMENT`
};

export const getResults = (
  groups,
  logListViewed = false,
  dimmerLoading
) => async (dispatch, getState) => {
  const { headerForm, listId, batchIds, formData } = getListsOverviewState(
    getState()
  );
  if (!groups) {
    groups = [];
    if (!headerForm.showGroups) {
      groups.push({
        id: "ungrouped",
        pageSize: 200,
        pageNumber: 1
      });
    }
  }
  const params = buildListParams(
    listId,
    batchIds,
    formData,
    groups,
    logListViewed
  );

  dispatch(
    apiPost(ActionTypes.GetResults, "lists/results", params, {
      actionCustomData: { dimmerLoading: dimmerLoading }
    })
  );
};

export const updatePageNumber = (pageNumber, pageSize, group) => async (
  dispatch,
  getState
) => {
  await dispatch({
    type: ActionTypes.UpdatePageNumber,
    value: {
      pageNumber,
      group
    }
  });
  if (group === "ungrouped") {
    dispatch(
      getResults([
        {
          id: "ungrouped",
          pageSize,
          pageNumber
        }
      ])
    );
  } else {
    const { groups } = getListsOverviewState(getState());
    dispatch(getResults(groups));
  }
};

export const updateHeaderForm = applyChanges => dispatch => {
  dispatch({
    type: ActionTypes.UpdateHeaderForm,
    applyChanges
  });

  dispatch(getResults());
};

export const onChangeSelection = contactId => ({
  type: ActionTypes.UpdateSelection,
  contactId
});

export const onChangeFollowing = (
  item,
  currentlyFollowing,
  isContactList,
  isGrouped
) => async dispatch => {
  let path;
  let ids;
  let args;
  let actionType;
  if (!isContactList) {
    path = `customers/company-watch-list-items`;
    ids = item.map(c => c.companyId);
    args = { companyIds: ids, customerId: null };
    actionType = isGrouped
      ? ActionTypes.UpdateGroupedCompanyFollowing
      : ActionTypes.UpdateCompanyFollowing;
  } else {
    path = `customers/contact-watch-list-items`;
    ids = item.map(c => c.originalIntId);
    args = { contactIds: ids, customerId: null };
    actionType = isGrouped
      ? ActionTypes.UpdateGroupedContactFollowing
      : ActionTypes.UpdateContactFollowing;
  }
  const opts = {
    actionCustomData: {
      item: item,
      following: !currentlyFollowing,
      isContactList,
      isGrouped
    }
  };

  const action = currentlyFollowing
    ? apiDelete(actionType, path, args, opts)
    : apiPost(actionType, path, args, opts);

  dispatch(action);
};

export const changeSelectAll = allSelected => ({
  type: ActionTypes.UpdateSelectAll,
  allSelected
});

export const clearSearch = () => dispatch => {
  dispatch({ type: ActionTypes.ClearSearch });
  dispatch(getResults());
};

export const clearName = () => dispatch => {
  dispatch({ type: ActionTypes.ClearName });
  dispatch(getResults());
};
export const clearValue = () => dispatch => {
  dispatch({ type: ActionTypes.ClearValue });
  dispatch(getResults());
};

export const updateForm = (field, data, applyChanges) => (
  dispatch,
  getState
) => {
  const { formData } = getListsOverviewState(getState());
  dispatch({
    type: ActionTypes.UpdateForm,
    field,
    data,
    applyChanges
  });
  dispatch(getResults());
};

export const updateLetterFilter = letter => dispatch => {
  dispatch({
    type: ActionTypes.UpdateLetterFilter,
    letter
  });
  dispatch(getResults());
};

export const updateNameSearch = name => dispatch => {
  dispatch({
    type: ActionTypes.UpdateNameSearch,
    name
  });
  dispatch(getResults());
};

export const loadOverview = id => dispatch => {
  return dispatch(apiGet(ActionTypes.ListOverview, `lists/${id}`));
};

export const loadOverviewModal = id =>
  apiGet(ActionTypes.LoadOverviewModal, `lists/${id}`);

export const applyPendingChanges = id => async dispatch => {
  var result = await dispatch(
    apiPost(ActionTypes.ApplyPendingChanges, `lists/${id}/pending-changes`)
  );
  if (!result.success) return;
  dispatch(loadOverview(id));
};

export const renameList = (formData, submitParams) =>
  apiPut(
    ActionTypes.RenameList,
    `lists/${submitParams.listId}/rename`,
    formData,
    { actionCustomData: formData }
  );

export const deleteList = (data, submitParams) =>
  apiDelete(ActionTypes.DeleteList, `lists/${submitParams.listId}`);

export const toggleBatchId = id => dispatch => {
  dispatch({ type: ActionTypes.ToggleBatchId, id });
  dispatch(getResults());
};

export const toggleAllBatchIds = () => dispatch => {
  dispatch({ type: ActionTypes.ToggleAllBatchIds });
  dispatch(getResults());
};

export const clearFieldsOrTags = () => (dispatch, getState) => {
  const {
    listId,
    criteria,
    batchIds,
    selection,
    formData
  } = getListsOverviewState(getState());
  return dispatch(
    apiPut(ActionTypes.ClearFieldsOrTags, "lists/clear-fields", {
      listId,
      selection,
      criteria,
      listFilters: formData,
      batchIds
    })
  );
};

export const saveNotes = (formData, submitParams) =>
  apiPut(
    ActionTypes.SaveNotes,
    `lists/${submitParams.listId}/notes`,
    formData,
    { actionCustomData: formData }
  );

export const suppressItems = () => (dispatch, getState) => {
  const {
    listId,
    criteria,
    batchIds,
    selection,
    formData
  } = getListsOverviewState(getState());

  return dispatch(
    apiPut(ActionTypes.SuppressItems, `lists/suppress`, {
      listId,
      criteria,
      selection,
      listFilters: formData,
      batchIds
    })
  );
};

export const removeItems = () => (dispatch, getState) => {
  const {
    listId,
    criteria,
    batchIds,
    selection,
    formData
  } = getListsOverviewState(getState());

  return dispatch(
    apiPut(ActionTypes.RemoveItems, `lists/remove`, {
      listId,
      criteria,
      selection,
      listFilters: formData,
      batchIds
    })
  );
};

export const blockItems = () => (dispatch, getState) => {
  const {
    listId,
    criteria,
    batchIds,
    selection,
    formData
  } = getListsOverviewState(getState());

  return dispatch(
    apiPut(ActionTypes.RemoveItems, `lists/block`, {
      listId,
      criteria,
      selection,
      listFilters: formData,
      batchIds
    })
  );
};

export const updateStatus = data => async (dispatch, getState) => {
  const {
    listId,
    criteria,
    batchIds,
    selection,
    formData,
    isContactList
  } = getListsOverviewState(getState());

  const result = await dispatch(
    apiPut(ActionTypes.UpdateStatus, `lists/status`, {
      listId,
      criteria,
      selection,
      status: data.status,
      comment: data.comment,
      listFilters: formData,
      batchIds
    })
  );

  if (!result.success) return;

  dispatch(lookupReset("listStatusLookup"));
  dispatch(lookupRequest("listStatusLookup"));

  dispatch(lookupReset("listHasStatusLookup"));
  dispatch(lookupRequest("listHasStatusLookup", { listId, isContactList }));

  dispatch(getResults());

  return result;
};

export const clearSelection = () => ({ type: ActionTypes.ClearSelection });

export const clearState = () => ({ type: ActionTypes.ClearState });

// please note this is the same as contacts/contactsearch/actions
// had to dupe because we need to use different state
// if you change one please change both (if necessary)
export const getContactDetails = id =>
  apiGet(
    ActionTypes.GetContactDetails,
    `contacts/${id}/details`,
    {},
    { actionCustomData: { contactId: id } }
  );

export const addRelationsToList = data => (dispatch, getState) => {
  const {
    listId,
    criteria,
    batchIds,
    selection,
    formData
  } = getListsOverviewState(getState());

  return dispatch(
    apiPut(ActionTypes.AddRelationsToList, `lists/relations`, {
      listId,
      criteria,
      selection,
      options: data.options,
      listFilters: formData,
      batchIds
    })
  );
};

export const getDownloadCount = () => (dispatch, getState) => {
  const { listId, batchIds, formData } = getListsOverviewState(getState());
  const params = buildListParams(listId, batchIds, formData, [], false);
  return dispatch(
    apiPost(ActionTypes.GetDownloadCount, `lists/download-count`, params)
  );
};

export const getCompanyDownload = template => (dispatch, getState) => {
  const { listId, batchIds, formData } = getListsOverviewState(getState());
  const params = buildListParams(
    listId,
    batchIds,
    formData,
    [],
    false,
    template.templateId
  );
  return dispatch(
    apiPost(ActionTypes.GetCompanyDownload, `lists/companies/download`, params)
  );
};

export const getContactDownload = template => (dispatch, getState) => {
  const { listId, batchIds, formData } = getListsOverviewState(getState());
  const params = buildListParams(
    listId,
    batchIds,
    formData,
    [],
    false,
    template.templateId
  );
  return dispatch(
    apiPost(ActionTypes.GetContactDownload, `lists/contacts/download`, params)
  );
};

const buildListParams = (
  listId,
  batchIds,
  formData,
  groups,
  logListViewed = false,
  templateId = null
) => {
  // Include contacts with email/phone: we do not want to filter if both are unselected
  return {
    listId,
    batchIds,
    groups,
    logListViewed,
    templateId,
    listFilters: {
      ...formData,
      includeContactsWithEmail:
        !formData.includeContactsWithEmail &&
        formData.includeContactsWithoutEmail
          ? false
          : true,
      includeContactsWithPhone:
        !formData.includeContactsWithPhone &&
        formData.includeContactsWithoutPhone
          ? false
          : true,
      includeContactsWithoutEmail:
        !formData.includeContactsWithoutEmail &&
        formData.includeContactsWithEmail
          ? false
          : true,
      includeContactsWithoutPhone:
        !formData.includeContactsWithoutPhone &&
        formData.includeContactsWithPhone
          ? false
          : true
    }
  };
};

export const addContactTag = ({ tag }) => async (dispatch, getState) => {
  const {
    listId,
    criteria,
    batchIds,
    selection,
    formData
  } = getListsOverviewState(getState());
  const response = await dispatch(
    apiPut(ActionTypes.AddContactTag, `lists/contact-tags`, {
      tag: tag,
      searchCriteria: criteria,
      listId,
      batchIds,
      listFilters: formData,
      selection
    })
  );
  const { success } = response;
  if (!success) return;
  await dispatch(lookupReset("contactTagLookup"));
  return dispatch(lookupRequest("contactTagLookup"));
};

export const addCompanyTag = field => (dispatch, getState) => {
  const {
    listId,
    criteria,
    batchIds,
    selection,
    formData
  } = getListsOverviewState(getState());

  return dispatch(
    apiPut(ActionTypes.AddCompanyTag, `lists/company-fields`, {
      searchCriteria: criteria,
      listId,
      batchIds,
      listFilters: formData,
      selection,
      customFieldId: field.name,
      value: field.value
    })
  );
};

export const deleteContactTag = ({ contactId, tagId }) =>
  apiDelete(
    ActionTypes.DeleteContactTag,
    `contacts/${contactId}/tags`,
    { id: tagId },
    { preventErrorNotification: true }
  );

export const saveList = (modalData, submitParams, isContactList) => (
  dispatch,
  getState
) => {
  const { criteria, listId, batchIds, formData } = getListsOverviewState(
    getState()
  );
  const listParams = buildListParams(listId, batchIds, formData);
  const request = {
    ...modalData,
    selection: submitParams.selection,
    searchCriteria: criteria,
    originalListProperties: {
      ...listParams
    },
    forceDumbList: true
  };

  return dispatch(
    apiPost(
      ActionTypes.SaveList,
      `lists/${isContactList ? "contact" : "company"}`,
      request
    )
  );
};

export const addToList = (modalData, submitParams) => (dispatch, getState) => {
  const { criteria, listId, batchIds, formData } = getListsOverviewState(
    getState()
  );
  const listParams = buildListParams(listId, batchIds, formData);
  const request = {
    ...modalData,
    selection: submitParams.selection,
    searchCriteria: criteria,
    originalListProperties: {
      ...listParams
    }
  };

  return dispatch(
    apiPost(ActionTypes.AddToList, "lists/add-to-existing", request)
  );
};

export const getSegmentAdditions = data =>
  apiPost(
    ActionTypes.GetSegmentAdditions,
    `customers/segments/count-additions`,
    data
  );

export const getSegmentRemovals = data =>
  apiPost(
    ActionTypes.GetSegmentRemovals,
    `customers/segments/count-removals`,
    data
  );

export const addCompaniesToSegments = data =>
  apiPost(
    ActionTypes.AddCompaniesToSegments,
    `customers/segments/additions`,
    data
  );

export const removeCompaniesFromSegments = (data, submitParams) =>
  apiPost(
    ActionTypes.RemoveCompaniesFromSegments,
    `customers/segments/removals`,
    data
  );
