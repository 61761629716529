import { PageLoader } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import { CustomerLookup } from "../lookups";
import CustomerDetailsForm from "./CustomerDetailsForm";
import CustomerRestrictAccessForm from "./CustomerRestrictAccessForm";
import CustomerSubscriptionForm from "./CustomerSubscriptionForm";

const CustomerOverview = ({ customerId }) => (
  <CustomerLookup
    customerId={customerId}
    render={({ response }, refresh) => {
      return response ? (
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={14}
          className="m-b-1"
          stretched
        >
          <Grid style={{ margin: "0" }}>
            <Grid.Row columns={2} className="p-t-0">
              <Grid.Column
                mobile={14}
                tablet={14}
                computer={8}
                className="m-b-1"
                stretched
              >
                <Segment className="left-border-green">
                  <CustomerDetailsForm
                    data={response}
                    customerId={customerId}
                    onSubmitted={refresh}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column
                mobile={14}
                tablet={14}
                computer={8}
                className="m-b-1"
                stretched
              >
                <Segment className="left-border-green">
                  <CustomerSubscriptionForm
                    data={response}
                    customerId={customerId}
                    onSubmitted={refresh}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className="p-t-0">
              <Grid.Column
                mobile={14}
                tablet={14}
                computer={8}
                className="m-b-1"
                stretched
              >
                <Segment className="left-border-green">
                  <CustomerRestrictAccessForm
                    data={response}
                    customerId={customerId}
                    onSubmitted={refresh}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      ) : (
        <PageLoader />
      );
    }}
  />
);

CustomerOverview.propTypes = {
  customerId: PropTypes.string.isRequired
};

export default CustomerOverview;
