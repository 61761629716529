import React from "react";
import { Table } from "@redriver/cinnamon";
import ActionsColumn from "./ActionsColumn";
import { PermissionCheck, UserTypeCheck } from "components/auth";
import { Actions, Targets, UserTypes } from "constants/permissions";

const CompanySuppressionsTable = ({ tableProps, customerId, onSubmitted }) => (
  <Table {...tableProps} dataKey="id" basic="very">
    <Table.Column title="Name" field="companyName" />
    <Table.Column title="Country" field="country" />
    <Table.Column title="Primary Industry" field="mainIndustry" />
    <UserTypeCheck restrictTo={UserTypes.Internal}>
      <PermissionCheck target={Targets.Items} action={Actions.Unsuppress}>
        <Table.Column
          name="Actions"
          render={item => (
            <ActionsColumn
              item={item}
              customerId={customerId}
              onSubmitted={onSubmitted}
              isContact={false}
            />
          )}
          width="15%"
        />
      </PermissionCheck>
    </UserTypeCheck>
  </Table>
);

export default CompanySuppressionsTable;
