import {
  ActionBar,
  Format,
  ListBuilder,
  Modal,
  ModalBuilder,
  Table
} from "@redriver/cinnamon";
import { SearchResultCounter } from "components/lists";
import { PageNumber } from "components/pagination";
import { AppRoutes } from "constants/routes";
import {
  canDownloadLeftContact,
  downloadLeftContact
} from "features/Contacts/OptOut/actions";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Grid, Icon, Popup, Segment } from "semantic-ui-react";
import { bulkDownload, getOptOutReports } from "./actions";
import Filters from "./Filters";

const AdvancedOptOutReport = ({ bulkDownload, history }) => {
  return (
    <ListBuilder
      withTable
      initialData={[]}
      initialFilters={{ isLeftContact: "false" }}
      dataTransform={data => data.results}
      totalTransform={data => data.totalResults}
      loadAction={getOptOutReports}
      renderList={(
        tableProps,
        { filters, pagination, total, selection },
        events
      ) => (
        <Grid className="i4b-list-page user-page">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="i4b-list">
                <ActionBar>
                  <ActionBar.Item fluid>
                    <h1>Opt Out/Left Company Files</h1>
                  </ActionBar.Item>
                  <ActionBar.Item align="right">
                    <Button
                      onClick={() => history.push(AppRoutes.OptOutReport)}
                    >
                      Basic
                    </Button>
                    <Button disabled positive>
                      Advanced
                    </Button>
                  </ActionBar.Item>
                </ActionBar>
                <Filters
                  filters={filters}
                  advanced={true}
                  onChangeFilters={events.onChangeFilters}
                  onChangeSelection={events.onChangeSelection}
                  pageSize={pagination.pageSize}
                  onPageSizeChange={events.onChangePagination}
                />
                <ModalBuilder
                  submitAction={bulkDownload}
                  submitParams={{ filters, selection }}
                  renderTrigger={showModal => {
                    const disabled =
                      !selection ||
                      !selection.selected ||
                      (selection.selected.length == 0 &&
                        !selection.allSelected);
                    return (
                      <div
                        className="opt-out-download"
                        onClick={disabled ? null : showModal}
                      >
                        <span
                          className={disabled ? `none-selected` : undefined}
                        >
                          Download
                        </span>
                        <Button
                          className={`actions-dropdown-button m-r-1 ${
                            disabled ? "cannot-download" : "can-download"
                          }`}
                        />
                      </div>
                    );
                  }}
                  renderModal={modalProps => (
                    <Modal.Confirmation
                      {...modalProps}
                      header="Download Bulk Files"
                      size="small"
                    >
                      <p>Are you sure you want to download these files?</p>
                      <p>
                        The file will be generated on the background and it will
                        be available for download on your secure file storage.
                      </p>
                      <p>You will be notified when the file is available.</p>
                    </Modal.Confirmation>
                  )}
                />

                <Segment>
                  <Table {...tableProps} dataKey="id" basic="very">
                    <Table.SelectableColumn
                      field="id"
                      header="all"
                      value={selection}
                      onChange={events.onChangeSelection}
                      totalItems={total}
                    />
                    <Table.Column
                      render={item => (
                        <Icon
                          className={
                            item.downloadedUtc ? "color-green" : "color-red"
                          }
                          name="square full"
                          size="small"
                        />
                      )}
                      width="2.5%"
                    />
                    <Table.Column
                      render={item => <Format.Date value={item.createdUtc} />}
                      title="Created Date"
                    />
                    <Table.Column
                      render={item =>
                        item.downloadedUtc ? (
                          <React.Fragment>
                            <Format.Date value={item.downloadedUtc} />
                            <Popup
                              wide
                              content={
                                <div>
                                  {item.downloads.map(d => (
                                    <div>
                                      {d.name} -{" "}
                                      {moment
                                        .utc(d.downloadedUtc)
                                        .local()
                                        .format("DD/MM/YYYY HH:mm")}
                                    </div>
                                  ))}
                                </div>
                              }
                              trigger={<Icon name="info circle" />}
                            />
                          </React.Fragment>
                        ) : (
                          "Report Requires Download"
                        )
                      }
                      title="Download Date"
                    />
                    <Table.Column
                      render={item =>
                        item.isOptOut ? "Opt Out" : "Left Company"
                      }
                      title="Type"
                    />
                  </Table>
                  <div className="i4b-list-footer">
                    <SearchResultCounter
                      pageSize={pagination.pageSize}
                      pageNumber={pagination.pageNumber}
                      totalResults={total}
                    />
                    <PageNumber
                      totalItems={total}
                      onChange={x =>
                        events.onChangePagination({
                          pageSize: pagination.pageSize,
                          pageNumber: x
                        })
                      }
                      pageSize={pagination.pageSize}
                      value={pagination.pageNumber}
                    />
                  </div>
                </Segment>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    />
  );
};
const actions = {
  downloadLeftContact,
  canDownloadLeftContact,
  bulkDownload
};

export default withRouter(
  connect(
    null,
    actions
  )(AdvancedOptOutReport)
);
