import React from "react";
import PropTypes from "prop-types";
import { Table as STable } from "semantic-ui-react";

/**
 * Renders a row for a table header
 */
class HeaderRow extends React.Component {
  static propType = {
    /**
     * Array of table data
     */
    data: PropTypes.array,
    /**
     * Property name for unique key in data
     */
    dataKey: PropTypes.string
  };

  static childContextTypes = {
    data: PropTypes.array,
    dataKey: PropTypes.string
  };

  getChildContext() {
    const { data, dataKey } = this.props;
    return { data, dataKey };
  }

  render() {
    const { children } = this.props;
    return (
      <STable.Row>
        {typeof children === "function" ? children() : children}
      </STable.Row>
    );
  }
}

export default HeaderRow;
