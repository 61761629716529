import { Csv } from "components/csv";
import { WizardMenu } from "components/menus";
import React from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import {
  applyUpdates,
  getAnalysis,
  getUploadData,
  processUpload,
  reset,
  sendAction,
  sendHeaderActions,
  setSelectedCell,
  updateRows
} from "./actions";
import { getUploadDataState } from "./selectors";
import { FileDataStep, FileDetailsStep, ProcessStep } from "./steps";

class UploadWizard extends React.Component {
  state = {
    steps: [
      {
        key: 1,
        title: "Select Document Type",
        content: props => <FileDetailsStep {...props} />,
        summary: props => {
          return (
            <div style={{ marginLeft: "4.75rem", fontStyle: "italic" }}>
              {props.metaData.name}, Upload type '
              {props.metaData.uploadType === 1 ? "Contacts" : "Companies"}',
              Action type '
              {props.metaData.actionType === 1
                ? "Custom Field Update"
                : "Match"}
              '
            </div>
          );
        },
        completed: false
      },
      {
        key: 2,
        title: "File",
        content: props => <FileDataStep {...props} />,
        summary: props => null,
        completed: false
      },
      {
        key: 3,
        title: "Process",
        icon: "checkmark",
        content: props => <ProcessStep {...props} />,
        summary: props => null,
        completed: false
      }
    ]
  };

  componentDidMount() {
    this.props.getUploadData(this.props.uploadId);
  }

  toggleStepCompletion = (indexToUpdate, forceState) => {
    const { steps } = this.state;

    steps[indexToUpdate].completed =
      forceState !== undefined ? forceState : !steps[indexToUpdate].completed;

    this.setState({ steps });
  };

  onRowsUpdated = (updatedRows, updatesToApply) => {
    const { applyUpdates, updateRows, uploadId } = this.props;
    applyUpdates(updatesToApply);
    updateRows(uploadId, updatedRows);
  };

  onCellAction = action => this.props.sendAction(this.props.uploadId, action);

  onHeaderActions = (actions, callBack, onFailure) => {
    this.props
      .sendHeaderActions(this.props.uploadId, actions)
      .then(analysis => {
        if (analysis.response) {
          const failedAnalysis = analysis.response.headerAnalysis.filter(
            h => h.data && !h.data.ok
          );
          if (failedAnalysis && failedAnalysis.length > 0) {
            onFailure(failedAnalysis[0].data.error);
          } else {
            callBack();
          }
        } else {
          onFailure("Failed to analyse upload");
        }
      });
  };

  render() {
    const {
      uploadId,
      columns,
      rows,
      setSelectedCell,
      selectedCell,
      errors,
      dataLoading,
      analysisLoading,
      processLoading,
      processUpload,
      processError,
      metaData
    } = this.props;
    const { steps } = this.state;

    return (
      <Grid
        columns={2}
        style={{ marginTop: "2em", maxWidth: "1600px", margin: "0 auto" }}
        stackable
      >
        {uploadId && (
          <Csv
            columns={columns}
            rows={rows}
            errors={errors}
            onRowsUpdated={this.onRowsUpdated}
            onCellSelected={setSelectedCell}
            selectedCell={selectedCell}
            onCellAction={this.onCellAction}
            onHeaderActions={this.onHeaderActions}
            processing={analysisLoading}
            metaData={metaData}
            dataLoading={dataLoading}
          >
            <Grid.Column>
              <WizardMenu
                id={uploadId}
                steps={steps}
                toggleCompletedState={this.toggleStepCompletion}
                processUpload={processUpload}
                processLoading={processLoading}
                processError={processError}
                metaData={metaData}
                dataLoading={dataLoading}
              />
            </Grid.Column>
          </Csv>
        )}
      </Grid>
    );
  }
}

const actions = {
  getUploadData,
  applyUpdates,
  updateRows,
  getAnalysis,
  setSelectedCell,
  sendAction,
  sendHeaderActions,
  reset,
  processUpload
};

const mapStateToProps = state => {
  const {
    columns,
    rows,
    headerAnalysis,
    cellAnalysis,
    selectedCell,
    errors,
    dataLoading,
    analysisLoading,
    processLoading,
    processError,
    metaData
  } = getUploadDataState(state);

  return {
    columns,
    rows,
    headerAnalysis,
    cellAnalysis,
    selectedCell,
    errors,
    dataLoading,
    analysisLoading,
    processLoading,
    processError,
    metaData
  };
};

export default connect(
  mapStateToProps,
  actions
)(UploadWizard);
