import { reducer as companies } from "features/Companies";
import { reducer as contacts } from "features/Contacts";
import { reducer as dashboard } from "features/Dashboard";
import { reducer as lists } from "features/Lists";
import { reducer as systemNotifications } from "features/Notifications";
import { reducer as system } from "features/System";
import { reducer as uploads } from "features/Uploads";
import { reducer as customers } from "features/Customers";
export default {
  // add reducers that should be initially loaded by the store here
  system,
  companies,
  contacts,
  lists,
  systemNotifications,
  uploads,
  dashboard,
  customers
};
