import React from "react";
import PropTypes from "prop-types";
import { Table as STable } from "semantic-ui-react";
import classNames from "classnames";

/**
 * Renders a row for a table data item
 */
class TableRow extends React.Component {
  static propType = {
    /**
     * Row item data to be rendered
     */
    item: PropTypes.shape({
      className: PropTypes.string
    }),
    /**
     * Unique key value for row item
     */
    itemKey: PropTypes.any,
    /**
     * Row index for the item
     */
    itemIndex: PropTypes.number,
    /**
     * Array of table data
     */
    data: PropTypes.array,
    /**
     * Property name for unique key in data
     */
    dataKey: PropTypes.string,
    /**
     * Callback when the row is clicked
     * @param {Object} `item` The row item that was clicked on
     * @param {*} `itemKey` The row item key
     */
    onClick: PropTypes.func,
    /**
     * Callback when the row is expanded
     * @param {*} `itemKey` The row item key
     */
    onExpand: PropTypes.func,
    /**
     * Callback when the row is collapsed
     * @param {*} `itemKey` The row item key
     */
    onCollapse: PropTypes.func,
    /**
     * Whether the row is currently in an expanded state
     */
    expanded: PropTypes.bool,
    /**
     * Content that the row should contain, or a render function that will be passed the following parameters:
     * @param {Object} `item` The current item being rendered
     * @param {Object} `state` An object representing the current state
     * @param {*} `state.itemKey` Unique key value for row item
     * @param {number} `state.itemIndex` Row index for the item
     * @param {boolean} `state.expanded` Whether the row is currently expanded
     * @param {Object} `events` An object containing useful callbacks that can be triggered
     * @param {function} `events.onExpand` Expands the row
     * @param {function} `events.onCollapse` Collapses the row
     */
    children: PropTypes.any
  };

  static childContextTypes = {
    item: PropTypes.object,
    data: PropTypes.array,
    dataKey: PropTypes.string,
    onClick: PropTypes.func
  };

  getChildContext() {
    const { item, data, dataKey, onClick } = this.props;
    return { item, data, dataKey, onClick };
  }

  onRowClick = () => {
    const { item, itemKey, onClick } = this.props;
    if (onClick) onClick(item, itemKey);
  };

  onRowExpand = () => {
    const { itemKey, onExpand } = this.props;
    if (onExpand) onExpand(itemKey);
  };

  onRowCollapse = () => {
    const { itemKey, onCollapse } = this.props;
    if (onCollapse) onCollapse(itemKey);
  };

  render() {
    const { item, itemKey, itemIndex, expanded, children } = this.props;

    const renderArgs = [
      item,
      { itemKey, itemIndex, expanded },
      {
        onExpand: this.onRowExpand,
        onCollapse: this.onRowCollapse
      }
    ];

    return (
      <STable.Row
        onClick={this.onRowClick}
        className={classNames(item.className, { expanded })}
      >
        {typeof children === "function" ? children(...renderArgs) : children}
      </STable.Row>
    );
  }
}

export default TableRow;
