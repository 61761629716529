import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { getAuthState, getAuthClaims } from "@redriver/cinnamon";
import { validateEmailToken } from "./actions";
import { AppRoutes, SystemRoutes } from "constants/routes";
import { getEmailVerificationState } from "../selectors";
import EmailVerificationResender from "./EmailVerificationResender";

class EmailVerification extends React.Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    onValidateToken: PropTypes.func.isRequired,
    validatingToken: PropTypes.bool,
    validationSlow: PropTypes.bool,
    tokenCorrect: PropTypes.bool,
    loggedIn: PropTypes.bool,
    emailVerified: PropTypes.bool
  };

  componentWillMount = () => {
    if (this.props.token && !this.props.emailVerified) {
      this.props.onValidateToken(this.props.token);
    }
  };

  render() {
    const {
      token,
      validatingToken,
      validationSlow,
      tokenCorrect,
      loggedIn,
      emailVerified
    } = this.props;

    if (emailVerified) {
      return <p>Your email address has already been verified</p>;
    }

    if (!token) {
      return <p>No email token supplied</p>;
    }

    if (validatingToken) {
      return validationSlow ? <p>Validating email token...</p> : null;
    }

    if (!tokenCorrect) {
      return (
        <div>
          <p>The email token supplied is invalid or has expired</p>
          <EmailVerificationResender />
        </div>
      );
    }

    return (
      <div>
        <p>Your email address has been verified</p>
        <div style={{ marginTop: 20 }}>
          <Button
            primary
            as={Link}
            to={loggedIn ? AppRoutes.Root : SystemRoutes.Login}
          >
            Continue
          </Button>
        </div>
      </div>
    );
  }
}

const actions = {
  onValidateToken: validateEmailToken
};

const mapStateToProps = state => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  const verificationState = getEmailVerificationState(state);
  return {
    loggedIn,
    emailVerified,
    validatingToken: verificationState.validatingToken,
    validationSlow: verificationState.validationSlow,
    tokenCorrect: verificationState.tokenCorrect
  };
};

export default connect(
  mapStateToProps,
  actions
)(EmailVerification);
