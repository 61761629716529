import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";

const TextCriteria = ({ active, handleClick, criteria }) => {
  if (!criteria.searchFilter || !criteria.searchFilter.search) return null;

  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Text"
        text={criteria.searchFilter.search}
      />
      <Accordion.Content active={active}>
        <p>{criteria.searchFilter.search}</p>
      </Accordion.Content>
    </React.Fragment>
  );
};

export default TextCriteria;
