import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Icon } from "semantic-ui-react";
import { removeSuppression } from "./actions";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/permissions";
import { deleteCompanySuppression } from "./actions";

const DeleteCompanySuppressionModal = ({
  suppression,
  customerId,
  onSubmitted
}) => {
  return (
    <ModalBuilder
      initialData={suppression}
      submitAction={deleteCompanySuppression}
      submitParams={{ customerId, suppression }}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => <p onClick={showModal}>Remove</p>}
      renderModal={(modalProps, data, state, events) => {
        return (
          <Modal.Confirmation
            {...modalProps}
            header={
              <div>
                <Icon name="warning sign" /> Remove Company Suppression
              </div>
            }
            size="tiny"
          >
            <p>
              Are you sure you want to remove the suppression for{" "}
              <strong>{suppression.companyName}</strong>?
            </p>
          </Modal.Confirmation>
        );
      }}
    />
  );
};

export default DeleteCompanySuppressionModal;
