import PropTypes from "prop-types";
import React, { Component } from "react";

class FilterBaseBody extends Component {
  static propTypes = {
    collapsed: PropTypes.bool
  };

  render() {
    const { collapsed, children, style } = this.props;

    const containerClasses = collapsed ? "invisible close" : "visible";

    return (
      <div className={"filter-content " + containerClasses} style={style}>
        {children}
      </div>
    );
  }
}

export default FilterBaseBody;
