import React from "react";
import { Link } from "react-router-dom";
import { Image, Segment } from "semantic-ui-react";
import { Format } from "@redriver/cinnamon";

import ArrowRight from "assets/icons/arrow_right.svg";
import { ListRoutes } from "constants/routes";

const getTotal = (total, downloadable, type) => {
  if (!!total || total === 0)
    return (
      <React.Fragment>
        <Format.Number value={downloadable} suffix={type} spaced />
        {` (`}
        <Format.Number value={total} suffix={"Total"} spaced />
        {")"}
      </React.Fragment>
    );

  return `No ${type} Available`;
};

const ListCard = ({
  id,
  isContactList,
  name,
  total,
  downloadableTotal,
  updatesPending,
  canHaveUpdates,
  lastUpdated
}) => (
  <Link key={id} to={ListRoutes.List(id)}>
    <Segment className="left-border-green m-y-1 list-card">
      <div className="flex">
        <div style={{ width: "100%" }}>
          <div className="list-card-header">
            <h5 className="single-line-text">{name}</h5>
            <Image src={ArrowRight} />
          </div>
          <p className="m-b-0">
            {getTotal(
              total,
              downloadableTotal,
              isContactList ? "Contacts Downloadable" : "Companies Downloadable"
            )}
          </p>
          <div className="flex space-between">
            {canHaveUpdates && (
              <p className={!!updatesPending ? "m-b-0 warn-updates" : "m-b-0"}>
                <Format.Number value={updatesPending} /> Updates Pending
              </p>
            )}
            <p className="m-b-0">
              Last updated <Format.Date value={lastUpdated} />
            </p>
          </div>
        </div>
      </div>
    </Segment>
  </Link>
);

export default ListCard;
