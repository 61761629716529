import React from "react";
import { withRouter } from "react-router";
import { LoginTerms } from "features/System";
import { SystemPageContent } from "components/pages";

const LoginTermsPage = ({ location, history }) => {
  return (
    <SystemPageContent reducedPadding>
      <LoginTerms nextPath={location.state.nextPath} history={history} />
    </SystemPageContent>
  );
};

export default withRouter(LoginTermsPage);
