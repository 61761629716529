import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes } from "constants/routes";
import React from "react";
import { Route, Switch } from "react-router-dom";
import AdvancedOptOutReportPage from "./AdvancedOptOutReportPage";
import OptOutReportPage from "./OptOutReportPage";

export default () => (
  <React.Fragment>
    <Switch>
      <Route exact path={AppRoutes.OptOutReport} component={OptOutReportPage} />
      <Route
        exact
        path={`/${AppRoutes.OptOutReport}`}
        component={OptOutReportPage}
      />
      <PermissionCheck
        target={Targets.ContactOptOuts}
        action={Actions.Advanced}
      >
        <Route
          exact
          path={AppRoutes.AdvancedOptOutReport}
          component={AdvancedOptOutReportPage}
        />
        <Route
          exact
          path={`/${AppRoutes.AdvancedOptOutReport}`}
          component={AdvancedOptOutReportPage}
        />
      </PermissionCheck>
    </Switch>
  </React.Fragment>
);
