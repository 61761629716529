import { Form } from "@redriver/cinnamon";
import { FormInputOnBlur } from "components/form";
import React from "react";
import { Icon } from "semantic-ui-react";

class JobTitleFilter extends React.Component {
  render() {
    const { adderDisabled } = this.props;
    return (
      <Form.Object field="jobTitlesFilter">
        <div className="job-titles-filter-container">
          <label>Job Titles</label>
          <div className="match-container">
            <label>Match results to</label>
            <Form.RadioGroup
              field="jobTitlesMatch"
              inline
              options={[
                {
                  value: "contains",
                  text: "Contain"
                },
                {
                  value: "begins",
                  text: "Begin With"
                },
                {
                  value: "equals",
                  text: "Equal"
                }
              ]}
            />
            <label>your criteria.</label>
          </div>
          <div className="job-titles-array">
            <Form.Array
              field="jobTitles"
              arrayKey="id"
              propagateUpdates="always"
            >
              <div>
                <FormInputOnBlur field="title" label="Job Title" inline />
                <Form.ArrayRemover>
                  <Icon name="trash" />
                </Form.ArrayRemover>
              </div>
            </Form.Array>
          </div>
          <Form.ArrayAdder
            field="jobTitles"
            arrayKey="id"
            className="secondary job-titles-adder"
            disabled={adderDisabled}
          >
            Add Another Job Title
          </Form.ArrayAdder>
        </div>
      </Form.Object>
    );
  }
}

export default JobTitleFilter;
