import { apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "UPLOADS/EDIT";

export const ActionTypes = {
  GetUploadDetails: `${NAMESPACE}/GET_UPLOAD_DETAILS`,
  GetUploadData: `${NAMESPACE}/GET_UPLOAD_DATA`,
  EditFileMetadata: `${NAMESPACE}/EDIT_FILE_METADATA`,
  PersistFileMetadata: `${NAMESPACE}/PERSIST_FILE_METADATA`,
  UpdateData: `${NAMESPACE}/UPDATE_DATA`,
  UpdateRows: `${NAMESPACE}/UPDATE_ROWS`,
  GetAnalysis: `${NAMESPACE}/GET_ANALYSIS`,
  SetSelectedCell: `${NAMESPACE}/SET_SELECTED_CELL`,
  SendAction: `${NAMESPACE}/SEND_ACTION`,
  Reset: `${NAMESPACE}/RESET`,
  ProcessUpload: `${NAMESPACE}/PROCESS_UPLOAD`,
  DownloadResultsFile: `${NAMESPACE}/DOWNLOAD_RESULTS_FILE`
};

export const getUploadDetails = id =>
  apiGet(ActionTypes.GetUploadDetails, `uploads/${id}`);

export const getUploadData = id => async (dispatch, getState) => {
  await dispatch(apiGet(ActionTypes.GetUploadData, `uploads/${id}/data`));
  //await dispatch(getAnalysis(id));
};

export const submitFileMetadata = (formData, id) => async dispatch => {
  await dispatch(
    apiPost(ActionTypes.EditFileMetadata, `uploads/${id}`, formData)
  );

  await dispatch({ type: ActionTypes.PersistFileMetadata, metaData: formData });

  return dispatch(getAnalysis(id));
};

export const applyUpdates = applyChanges => dispatch => {
  dispatch({ type: ActionTypes.UpdateData, applyChanges });
};

export const setSelectedCell = cellData => dispatch => {
  dispatch({ type: ActionTypes.SetSelectedCell, cellData });
};

export const updateRows = (id, rows) => async dispatch => {
  await dispatch(apiPut(ActionTypes.UpdateRows, `uploads/${id}/rows`, rows));

  dispatch(getAnalysis(id));
};

export const sendAction = (id, action) => async (dispatch, getState) => {
  await dispatch(
    apiPut(ActionTypes.SendAction, `uploads/${id}/action`, action)
  );
  dispatch(getAnalysis(id));
};

export const sendHeaderActions = (id, actions) => async (
  dispatch,
  getState
) => {
  await dispatch(
    apiPut(ActionTypes.SendAction, `uploads/${id}/header-actions`, actions)
  );
  return dispatch(getAnalysis(id));
};

export const getAnalysis = id => {
  return apiGet(ActionTypes.GetAnalysis, `uploads/${id}/analysis`);
};

export const reset = () => dispatch => {
  dispatch({ type: ActionTypes.Reset });
};

export const processUpload = id => async dispatch => {
  return await dispatch(
    apiPost(
      ActionTypes.ProcessUpload,
      `uploads/${id}/process`,
      {},
      { preventErrorNotification: true }
    )
  );
};

export const downloadResultsFile = (id, type) => async dispatch => {
  return await dispatch(
    apiPost(ActionTypes.DownloadResultsFile, `uploads/${id}/results`, {
      uploadResultType: type
    })
  );
};
