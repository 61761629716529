import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Button, Grid } from "semantic-ui-react";
import { submitResearchRequest } from "./actions";
import Widget from "./Widget";

class CustomResearchWidget extends React.Component {
  renderContent() {
    return (
      <ModalBuilder
        withForm
        submitAction={submitResearchRequest}
        renderTrigger={showModal => (
          <Grid
            columns={1}
            className="widget-content"
            style={{ marginTop: "0" }}
          >
            <Grid.Column>
              <p>
                Looking for more information about a company or need further
                details about a contact? You can submit a request to our
                research team, and they will do their best to find this
                information for you.
              </p>
              <Button
                className="primary secondary-small-button"
                onClick={showModal}
                fluid
              >
                New Research Request
              </Button>
            </Grid.Column>
          </Grid>
        )}
        renderModal={(modalProps, formProps) => (
          <Modal.Edit
            {...modalProps}
            header="Request Research"
            submitLabel="Send"
            submitDisabled={
              !formProps.value.requestText ||
              !formProps.value.requestText.trim().length
            }
            size="tiny"
          >
            <p>
              Please state within the comment box below, the full description of
              what you would like us to research{" "}
              <em>e.g. Discover CIO at 1Spatial Group Ltd</em>
            </p>
            <Form {...formProps}>
              <Form.TextArea field="requestText" required fluid />
            </Form>
            <p style={{ marginTop: "1em", marginBottom: "0" }}>
              Please select <strong>"Send"</strong> to deliver the above request
              to our research team. We will endeavour to action this promptly.
              If your request cannot be actioned, support will be in touch.
            </p>
          </Modal.Edit>
        )}
      />
    );
  }

  render() {
    const { label, colour, loading } = this.props;

    return (
      <Widget loading={loading} header={label} color={colour}>
        {this.renderContent()}
      </Widget>
    );
  }
}

export default CustomResearchWidget;
