import {
  requestError,
  requestIsBusy,
  requestIsSuccess,
  requestResponse
} from "@redriver/cinnamon";
import { cloneDeep, find, orderBy } from "lodash";
import { ActionTypes } from "./actions";

const initialState = {
  loading: false,
  headerForm: {
    showGroups: false
  },
  groups: [
    // An array of objects that look like default
  ],
  ungrouped: {
    id: "ungrouped",
    name: "ungrouped",
    results: [],
    totalResults: 0,
    pageNumber: 1,
    pageSize: 200
  },
  contactDetails: {},
  totalWithEmail: null,
  totalWithoutEmail: null,
  totalWithPhone: null,
  totalWithoutPhone: null,
  totalResults: 0,
  totalPendingUpdates: 0,
  totalPendingAdditions: 0,
  totalPendingRemovals: 0,

  createdByName: null,
  createdUtc: null,
  notes: null,
  selection: {
    allSelected: false,
    selected: [],
    deselected: []
  },
  selectedItems: null,

  isSegmented: false,

  // Set by lookup result
  isContactList: false,
  listId: null,
  batchIds: [],
  formData: {
    firstLetter: "all",
    includeContactsWithEmail: true,
    includeContactsWithoutEmail: true,
    includeContactsWithPhone: true,
    includeContactsWithoutPhone: true,
    downloadable: "true"
  },
  criteria: [],
  overviewLoading: false,

  pendingChanges: {
    loading: null,
    error: null,
    success: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GetResults:
      const response = requestResponse(action);
      const formData = state.formData;
      const updateContactDetailCounts =
        response &&
        formData.includeContactsWithEmail &&
        formData.includeContactsWithPhone &&
        formData.includeContactsWithoutEmail &&
        formData.includeContactsWithoutPhone;
      const onSuccess = response
        ? {
            groups: orderBy(
              response.groups.reduce((acc, val) => {
                const currentIndex = acc.findIndex(v => v.id == val.id);
                let holdOver = {
                  pageNumber: 1,
                  pageSize: 10
                };
                // Remove the old value
                if (currentIndex === -1) {
                  const existingGroup = state.groups.find(g => g.id === val.id);
                  if (existingGroup) {
                    holdOver.pageNumber = existingGroup.pageNumber;
                    holdOver.pageSize = existingGroup.pageSize;
                  }
                } else {
                  holdOver = acc.splice(currentIndex, 1)[0];
                }

                acc.push({
                  ...holdOver,
                  ...val
                });
                return acc;
              }, []),
              ["name"]
            ),
            totalResults: response.totalResults,
            totalUpdatesPending: response.totalUpdatesPending || 0,
            totalWithEmail: updateContactDetailCounts
              ? response.totalWithEmail
              : state.totalWithEmail,
            totalWithoutEmail: updateContactDetailCounts
              ? response.totalWithoutEmail
              : state.totalWithoutEmail,
            totalWithPhone: updateContactDetailCounts
              ? response.totalWithPhone
              : state.totalWithPhone,
            totalWithoutPhone: updateContactDetailCounts
              ? response.totalWithoutPhone
              : state.totalWithoutPhone
          }
        : {};

      return {
        ...state,
        contactDetails: requestResponse(action) ? {} : state.contactDetails,
        loading:
          requestIsBusy(action) &&
          !(action.customData && action.customData.dimmerLoading),
        dimmerLoading:
          requestIsBusy(action) &&
          (action.customData && action.customData.dimmerLoading),
        isSegmented: response && response.isSegmented,
        ungrouped:
          response && response.ungrouped
            ? {
                ...state.ungrouped,
                ...response.ungrouped
              }
            : state.ungrouped,
        ...onSuccess
      };

    case ActionTypes.UpdatePageNumber:
      const { group, pageNumber } = action.value;

      if (group == "ungrouped") {
        return {
          ...state,
          ungrouped: {
            ...state.ungrouped,
            pageNumber
          }
        };
      }

      const newGroups = [...state.groups];

      const groupIndex = newGroups.findIndex(g => g.id == group);

      if (groupIndex != -1) {
        newGroups[groupIndex] = {
          ...newGroups[groupIndex],
          pageNumber
        };
      }

      return {
        ...state,
        groups: newGroups
      };

    case ActionTypes.UpdateHeaderForm: {
      return {
        ...state,
        headerForm: action.applyChanges(state.headerForm)
      };
    }

    case ActionTypes.ListOverview: {
      const response = requestResponse(action);

      return {
        ...state,
        overviewLoading: requestIsBusy(action),
        ...(response || {})
      };
    }

    case ActionTypes.UpdateSelection: {
      const selection = state.selection;
      let newSelection = null;
      if (selection.allSelected) {
        newSelection = {
          allSelected: true,
          selected: [],
          deselected: selection.deselected.includes(action.contactId)
            ? selection.deselected.filter(f => f != action.contactId)
            : [...selection.deselected, action.contactId]
        };

        if (newSelection.deselected.length == state.totalResults) {
          newSelection.allSelected = false;
          newSelection.deselected = [];
        }
      } else {
        newSelection = {
          allSelected: false,
          deselected: [],
          selected: selection.selected.includes(action.contactId)
            ? selection.selected.filter(f => f != action.contactId)
            : [...selection.selected, action.contactId]
        };

        if (newSelection.selected.length == state.totalResults) {
          newSelection.allSelected = true;
          newSelection.selected = [];
        }
      }
      return {
        ...state,
        selection: newSelection,
        selectedItems:
          newSelection.selected.length > 0
            ? newSelection.selected.length
            : newSelection.allSelected
            ? state.totalResults - newSelection.deselected.length
            : null
      };
    }

    case ActionTypes.UpdateSelectAll: {
      return {
        ...state,
        selection: {
          allSelected: action.allSelected,
          deselected: [],
          selected: []
        },
        selectedItems: action.allSelected ? state.totalResults : 0
      };
    }

    case ActionTypes.UpdateCompanyFollowing: {
      if (action.customData && action.customData.item) {
        const response = requestResponse(action);
        const success = requestIsSuccess(action);
        const newResults = cloneDeep(state.ungrouped.results);

        for (let item of action.customData.item) {
          const companyId = item.companyId;
          const company = find(newResults, c => c.companyId === companyId);
          if (company) {
            if (success === false) {
              company.followingUpdating = false;
            } else if (response) {
              company.following = action.customData.following;
              company.followingUpdating = false;
            } else {
              company.followingUpdating = true;
            }
          }

          return {
            ...state,
            ungrouped: {
              ...state.ungrouped,
              results: newResults
            }
          };
        }
      }
    }

    case ActionTypes.UpdateGroupedCompanyFollowing: {
      if (action.customData && action.customData.item) {
        const response = requestResponse(action);
        const success = requestIsSuccess(action);
        const newGroups = cloneDeep(state.groups);

        for (let item of action.customData.item) {
          const companyId = item.companyId;
          const company = find(
            _.flatMap(newGroups, g => g.results),
            c => c.companyId === companyId
          );
          if (company) {
            if (success === false) {
              company.followingUpdating = false;
            } else if (response) {
              company.following = action.customData.following;
              company.followingUpdating = false;
            } else {
              company.followingUpdating = true;
            }
          }

          return {
            ...state,
            groups: newGroups
          };
        }
      }
    }

    case ActionTypes.UpdateContactFollowing: {
      if (action.customData && action.customData.item) {
        const response = requestResponse(action);
        const success = requestIsSuccess(action);
        const newResults = cloneDeep(state.ungrouped.results);

        for (let item of action.customData.item) {
          const contactId = item.id;
          const contact = find(newResults, c => c.id === contactId);
          if (contact) {
            if (success === false) {
              contact.followingUpdating = false;
            } else if (response) {
              contact.following = action.customData.following;
              contact.followingUpdating = false;
            } else {
              contact.followingUpdating = true;
            }
          }

          return {
            ...state,
            ungrouped: {
              ...state.ungrouped,
              results: newResults
            }
          };
        }
      }
    }

    case ActionTypes.UpdateGroupedContactFollowing: {
      if (action.customData && action.customData.item) {
        const response = requestResponse(action);
        const success = requestIsSuccess(action);
        const newGroups = cloneDeep(state.groups);

        for (let item of action.customData.item) {
          const contactId = item.id;
          const contact = find(
            _.flatMap(newGroups, g => g.results),
            c => c.id === contactId
          );
          if (contact) {
            if (success === false) {
              contact.followingUpdating = false;
            } else if (response) {
              contact.following = action.customData.following;
              contact.followingUpdating = false;
            } else {
              contact.followingUpdating = true;
            }
          }

          return {
            ...state,
            groups: newGroups
          };
        }
      }
    }

    case ActionTypes.ApplyPendingChanges: {
      const loading = requestIsBusy(action);
      const error = requestError(action);
      const success = requestIsSuccess(action);

      return {
        ...state,
        pendingChanges: {
          ...state.applyChanges,
          loading: loading,
          error: error,
          success: success
        },
        totalUpdatesPending: success ? 0 : state.totalUpdatesPending
      };
    }

    case ActionTypes.ClearSearch:
      return {
        ...state,
        formData: initialState.formData
      };

    case ActionTypes.UpdateForm:
      return {
        ...state,
        formData: action.applyChanges(state.formData),
        selection: { allSelected: false, selected: [], deselected: [] },
        selectedItems: null,
        pageNumber: 1
      };

    case ActionTypes.UpdateLetterFilter:
      return {
        ...state,
        formData: {
          ...state.formData,
          firstLetter: action.letter
        }
      };

    case ActionTypes.UpdateNameSearch:
      return {
        ...state,
        formData: {
          ...state.formData,
          search: action.name
        }
      };

    case ActionTypes.ClearName:
      return {
        ...state,
        formData: {
          ...state.formData,
          name: "",
          value: []
        }
      };

    case ActionTypes.ClearValue:
      return {
        ...state,
        formData: {
          ...state.formData,
          value: []
        }
      };

    case ActionTypes.ToggleBatchId:
      const batchId = find(state.batchIds, b => b === action.id);
      const newBatchIds = batchId
        ? state.batchIds.filter(b => b !== batchId)
        : [...state.batchIds, action.id];

      return {
        ...state,
        batchIds: newBatchIds
      };

    case ActionTypes.ToggleAllBatchIds:
      const allSelected = state.batches.length === state.batchIds.length;
      const newIds = allSelected ? [] : state.batches.map(b => b.id);

      return {
        ...state,
        batchIds: newIds
      };

    case ActionTypes.SaveNotes:
      const success = requestIsSuccess(action);

      return {
        ...state,
        notes: success ? action.customData.notes : state.notes
      };

    case ActionTypes.ClearSelection:
      return {
        ...state,
        selection: initialState.selection,
        selectedItems: null
      };

    case ActionTypes.ClearState:
      return initialState;

    case ActionTypes.GetContactDetails:
      const res = requestResponse(action);
      const detailsLoading = requestIsBusy(action);

      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          [action.customData.contactId]: { data: res, loading: detailsLoading }
        }
      };

    default:
      return state;
  }
};
