import { ContentBuilder, Format } from "@redriver/cinnamon";
import React from "react";
import { Image, Grid, Icon } from "semantic-ui-react";
import { getRecentlyViewedData } from "./actions";
import Widget from "./Widget";
import Building from "assets/icons/purple_company.svg";
import User from "assets/icons/green_user.svg";
import List from "assets/icons/black_list.svg";
import { CompanyRoutes, ListRoutes } from "constants/routes";
import { withRouter } from "react-router-dom";
import { ActivityType } from "constants/dashboard";

class RecentlyViewedWidget extends React.Component {
  renderActivity = activity => {
    const { history } = this.props;
    return (
      <Grid
        className={`recently-viewed ${
          activity.type === ActivityType.Contact
            ? "contact-update"
            : activity.type === ActivityType.Company
            ? "company-update"
            : "list-update"
        }`}
        onClick={() =>
          activity.type === ActivityType.Contact
            ? history.push(CompanyRoutes.Contacts(activity.entityId), {
                search: activity.contactName,
                leftCompany: activity.contactLeft,
                contactId: activity.contactId
              })
            : activity.type === ActivityType.Company
            ? history.push(CompanyRoutes.Overview(activity.entityId))
            : history.push(ListRoutes.List(activity.entityId))
        }
      >
        <Grid.Row columns={2}>
          <Grid.Column computer={14}>
            <Grid.Row
              columns={2}
              style={{ display: "flex", maxHeight: "5rem" }}
            >
              <Grid.Column
                computer={12}
                style={{ width: "100%", display: "flex" }}
                verticalAlign="middle"
              >
                {activity.type === ActivityType.Contact ? (
                  //NOTE: these icons are different sizes hence different margins
                  <Image src={User} style={{ marginRight: "10px" }} />
                ) : activity.type === ActivityType.Company ? (
                  //NOTE: these icons are different sizes hence different margins
                  <Image
                    src={Building}
                    style={{
                      marginLeft: "-3px",
                      marginRight: "8px"
                    }}
                  />
                ) : (
                  <Image
                    src={List}
                    style={{
                      marginLeft: "-3px",
                      marginRight: "8px"
                    }}
                  />
                )}
                <p className="update-title">{`${
                  activity.type === ActivityType.Contact
                    ? "Contact"
                    : activity.type === ActivityType.Company
                    ? "Company"
                    : "List"
                }`}</p>
              </Grid.Column>
              <Grid.Column computer={4}>
                <Format.DateTime value={activity.createdUtc} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column computer={16}>
                <p style={{ marginTop: "0.3em" }}>{activity.message}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column computer={2} verticalAlign="middle">
            <Icon name="caret right" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderContent() {
    return (
      <ContentBuilder
        loadAction={getRecentlyViewedData}
        renderContent={(data, state, events) =>
          data && data.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              <p style={{ paddingTop: "0.5rem" }}>
                Recently viewed companies, contacts or lists will appear here
              </p>
            </div>
          ) : (
            data.length > 0 && (
              <Grid
                columns={1}
                style={{
                  borderTop: "1px solid #f7f6fa",
                  marginTop: "0rem",
                  paddingTop: "0em"
                }}
              >
                {data.map(r => (
                  <Grid.Column key={r.id}>{this.renderActivity(r)}</Grid.Column>
                ))}
              </Grid>
            )
          )
        }
      />
    );
  }

  render() {
    const { label, colour, loading, className } = this.props;

    return (
      <Widget
        loading={loading}
        header={label}
        color={colour}
        className={className}
      >
        {this.renderContent()}
      </Widget>
    );
  }
}

export default withRouter(RecentlyViewedWidget);
