import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";
import TreeViewCriteria from "./TreeViewCriteria";

const LobRolesCriteria = ({ active, handleClick, criteria }) => {
  const { selectedLobRoles } = criteria;

  if (!selectedLobRoles.length) return null;

  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="LOB Roles"
        text={selectedLobRoles.join(", ")}
      />
      <Accordion.Content active={active}>
        <TreeViewCriteria criteria={criteria} lookup="lobRolesCriteriaLookup" />
      </Accordion.Content>
    </React.Fragment>
  );
};

export default LobRolesCriteria;
