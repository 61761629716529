import { Form, PageLoader } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import { LanguagesLookup } from "../lookups";
import FilterBase from "./FilterBase";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";
import FilterClearer from "./FilterClearer";

class LanguagesFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    results: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    clearSelected: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired
  };
  state = {
    visible: true,
    selectedLanguages: "",
    total: null
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  componentDidMount = () => {
    const { id, formData } = this.props;
    this.getSelected(formData, id);
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  componentDidUpdate = prevProps => {
    const { id, formData } = this.props;
    if (prevProps.formData !== formData) {
      this.getSelected(formData, id);
    }
  };

  getSelected = (formData, id) => {
    const { searchCriteria } = formData;

    const languagesObj = searchCriteria.find(el => el.id == id);
    const { selectedLanguages } = languagesObj;

    this.setState({
      selectedLanguages:
        selectedLanguages.length > 0 ? selectedLanguages.join(", ") : null
    });
  };

  render() {
    const { visible, selectedLanguages, total } = this.state;
    const {
      id,
      loading,
      pageChanged,
      removeFilter,
      clearSelected
    } = this.props;
    return (
      <LanguagesLookup
        render={({ response }, refresh) => (
          <FilterBase
            total={total}
            loading={loading && !pageChanged}
            className="languages-filter"
          >
            {response ? (
              <React.Fragment>
                <FilterBase.Header
                  title="Languages"
                  collapsible
                  collapsed={!visible}
                  onCollapseToggle={this.toggleVisibility}
                  removable
                  onRemove={event => removeFilter(id, event)}
                  showClearer={!!selectedLanguages}
                  onClear={clearSelected}
                >
                  {!visible && (
                    <div className="ellipsis-paragraph">
                      {selectedLanguages || "No languages selected"}
                    </div>
                  )}
                </FilterBase.Header>
                <FilterBase.Body collapsed={!visible}>
                  <div className="languages-container">
                    <Form.CheckboxGroup
                      key={id}
                      className="languages-checkbox-group"
                      field="selectedLanguages"
                      options={response}
                    />
                  </div>
                </FilterBase.Body>
              </React.Fragment>
            ) : (
              <PageLoader
                active
                inline="centered"
                size="small"
                className="p-y-05"
              />
            )}
          </FilterBase>
        )}
      />
    );
  }
}

export default LanguagesFilter;
