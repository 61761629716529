import {
  apiGet,
  Breadcrumb,
  Form,
  Lookup,
  registerLookup
} from "@redriver/cinnamon";
import React from "react";
const NAMESPACE = "UPLOADS/LOOKUPS";

const ActionTypes = {
  ViewUpload: `${NAMESPACE}/VIEW_UPLOAD`,
  ViewHeaders: `${NAMESPACE}/VIEW_HEADERS`
};

registerLookup(
  "headersLookup",
  ({ search, uploadId }) =>
    apiGet(ActionTypes.ViewHeaders, `uploads/${uploadId}/headers`, {
      search,
      pageSize: 10
    }),
  {
    transform: res => {
      return res.map(s => ({
        value: s.id,
        text: s.text
      }));
    },
    cache: false
  }
);

export const HeadersDropdown = props => (
  <Form.Dropdown
    className={props.isMatched ? "matched" : ""}
    placeholder="Headers"
    lookup="headersLookup"
    {...props}
  />
);

registerLookup("uploadLookup", id =>
  apiGet(ActionTypes.ViewUpload, `uploads/${id}`)
);

export const UploadLookup = ({ uploadId, ...props }) => (
  <Lookup lookup="uploadLookup" lookupParams={uploadId} {...props} />
);

export const UploadPreLoader = ({ uploadId, children }) => (
  <UploadLookup
    uploadId={uploadId}
    render={({ loading }) => children}
    resetOnMount
  />
);

export const UploadBreadcrumbItem = ({ uploadId }) => (
  <UploadLookup
    uploadId={uploadId}
    render={({ response }) =>
      response && <Breadcrumb.Item content={response.name} />
    }
  />
);
