import { apiGet, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/OAUTHLOGIN";
export const START_FLOW = `${NAMESPACE}/START_FLOW`;
export const VALIDATE_FLOW = `${NAMESPACE}/VALIDATE_FLOW`;
export const VALIDATE_EMAIL = `${NAMESPACE}/VALIDATE_EMAIL`;

export const startFlow = (
  username,
  password,
  oauthParams
) => async dispatch => {
  const loginAction = apiPost(
    START_FLOW,
    "oauth/start-flow",
    {
      ...oauthParams,
      credential_key: username,
      credential_secret: password
    },
    { auth: false, preventErrorNotification: true }
  );
  return await dispatch(loginAction);
};

export const validateEmail = email => {
  console.log("validate email", email);
  return apiGet(
    VALIDATE_EMAIL,
    `users/check-email-valid`,
    { email },
    {
      auth: false
    }
  );
};

export const validateFlow = oauthParams => {
  //TODO:: Match name with params e.g. client_id not clientId?
  return apiGet(VALIDATE_FLOW, "oauth/validate-flow", oauthParams, {
    auth: false
  });
};
