import React from "react";
import { Accordion, Icon } from "semantic-ui-react";

const ReadOnlyHeader = ({ active, handleClick, name, text, criteria }) => (
  <Accordion.Title active={active} index={criteria.type} onClick={handleClick}>
    <div className="readonly-criteria-header">
      <span className="criteria-name">{name}</span>{" "}
      <div className="single-line-text">{text}</div>
      <Icon
        name={active ? "angle up" : "angle down"}
        style={{ lineHeight: "inherit", marginRight: "1rem" }}
      />
    </div>
  </Accordion.Title>
);

export default ReadOnlyHeader;
