import { apiGet, apiPatch } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/PASSWORD_RESET";
export const VALIDATE_TOKEN = `${NAMESPACE}/VALIDATE`;
export const REQUEST_RESET = `${NAMESPACE}/REQUEST`;

export const validateResetToken = token =>
  apiGet(VALIDATE_TOKEN, `auth/reset-tokens/${token}`, {}, { auth: false });

export const requestPasswordReset = (token, newPassword) =>
  apiPatch(
    REQUEST_RESET,
    `auth/reset-tokens/${token}`,
    { isUsed: true, newPassword },
    { auth: false, preventErrorNotification: true }
  );
