import { apiPost, getAuthState } from "@redriver/cinnamon";

const NAMESPACE = "UI/NOTIFICATIONS";

export const ActionTypes = {
  RefreshNotifications: `${NAMESPACE}/REFRESH_NOTIFICATIONS`
};

export const refreshNotifications = () => (dispatch, getState) => {
  const { refreshToken } = getAuthState(getState());
  return dispatch(
    apiPost(
      ActionTypes.RefreshNotifications,
      `notifications/unread`,
      { refreshToken },
      {
        preventErrorNotification: true
      }
    )
  );
};
