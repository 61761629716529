import { Form } from "@redriver/cinnamon";
import { CompanyStructureCheckboxTree } from "components/form";
import React from "react";
import { connect } from "react-redux";
import { getCompanyStructureState } from "../selectors";
import { updateForm } from "./actions";

const CompanyStructureTreeView = ({
  nodes,
  companyId,
  onExpanded,
  onCollapsed,
  formData,
  updateForm,
  ...otherProps
}) => (
  <Form value={formData} onChange={updateForm}>
    <CompanyStructureCheckboxTree
      key={companyId}
      className="checkbox-tree"
      field="selectedCompanies"
      nodes={nodes}
      companyId={companyId}
      {...otherProps}
    />
  </Form>
);

const actions = {
  updateForm
};

const mapStateToProps = state => {
  const { formData } = getCompanyStructureState(state);
  return { formData };
};

export default connect(
  mapStateToProps,
  actions
)(CompanyStructureTreeView);
