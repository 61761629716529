import { getAuthClaims } from "@redriver/cinnamon";
import { PermissionCheck, UserTypeCheck } from "components/auth";
import {
  AddSuppressionsModal,
  AddToListModal,
  AddToSegmentsModal,
  SaveListModal
} from "components/search";
import { Actions, Targets, UserTypes } from "constants/permissions";
import ClearFieldsOrTagsModal from "features/Lists/ListsOverview/ActionsDropdown/ClearFieldsOrTagsModal";
import React from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import {
  addCompaniesToSegments,
  addContactTag,
  clearTags,
  getSegmentAdditions
} from "./actions";
import AddTagModal from "./AddTagModal";

class ContactsActionsDropdown extends React.Component {
  state = {
    dropdownOpen: false
  };

  componentWillReceiveProps = nextProps => {
    const { dropdownOpen } = this.state;
    const { selectedItems } = nextProps;
    if (!selectedItems && dropdownOpen) {
      this.toggleDropdownOpen();
    }
  };

  toggleDropdownOpen = () =>
    this.setState({ dropdownOpen: !this.state.dropdownOpen });

  render() {
    const {
      selectedItems,
      selection,
      addToList,
      formData,
      forceDumbList,
      customerId,
      addSuppressions,
      onSubmitted,
      allowTagAdditions,
      saveList,
      companyId,
      isUniverse,
      isSegmented
    } = this.props;
    const { dropdownOpen } = this.state;

    return (
      <div>
        <span
          className={!selectedItems ? `none-selected` : undefined}
          onClick={selectedItems ? this.toggleDropdownOpen : null}
        >
          Actions
        </span>
        <Button
          className={`actions-dropdown-button ${
            !selectedItems
              ? `none-selected`
              : selectedItems && !dropdownOpen
              ? `some-selected`
              : `close`
          }`}
          onClick={selectedItems ? this.toggleDropdownOpen : null}
        />
        <section
          style={
            dropdownOpen
              ? {
                  visibility: "visible",
                  opacity: "1"
                }
              : {
                  visibility: "hidden",
                  opacity: "0"
                }
          }
          className="actions-dropdown-section"
        >
          <header>
            <h3>Actions</h3>
            <p>
              {selectedItems} selected{" "}
              {selectedItems > 1 ? "results" : "result"}
            </p>
          </header>
          <ul className="list-unstyled">
            {!isUniverse && (
              <SaveListModal
                isCompaniesPage={false}
                submitAction={saveList}
                companyId={companyId}
                searchCriteria={formData.searchCriteria}
                selection={selection}
                forceDumbList={forceDumbList}
              />
            )}
            <UserTypeCheck restrictTo={UserTypes.External}>
              <li>
                <AddTagModal
                  selection={selection}
                  searchCriteria={formData.searchCriteria}
                  submitAction={addContactTag}
                  isActionList={true}
                  isUniverse={isUniverse}
                  onSubmitted={onSubmitted}
                  allowAdditions={allowTagAdditions}
                />
              </li>
            </UserTypeCheck>

            <ClearFieldsOrTagsModal
              isContactList
              submitAction={clearTags}
              onSubmitted={onSubmitted}
              submitParams={{
                selection,
                selectedItems,
                formData,
                customerId,
                isUniverse
              }}
              numSelected={selectedItems}
              isUniverse={isUniverse}
            />

            {!isUniverse && (
              <AddToListModal
                isCompaniesPage={false}
                submitAction={addToList}
                submitParams={{
                  selection,
                  companyId
                }}
              />
            )}
            {isSegmented && (
              <PermissionCheck
                target={Targets.CustomerSegments}
                action={Actions.Edit}
              >
                {isUniverse && (
                  <li>
                    <AddToSegmentsModal
                      loadAction={getSegmentAdditions}
                      submitAction={addCompaniesToSegments}
                      loadParams={{
                        selection,
                        selectedItems,
                        searchCriteria: formData.searchCriteria,
                        isUniverse,
                        isContact: true
                      }}
                      submitParams={{
                        selection,
                        selectedItems,
                        searchCriteria: formData.searchCriteria,
                        isUniverse,
                        isContact: true
                      }}
                      onSubmitted={onSubmitted}
                    />
                  </li>
                )}
              </PermissionCheck>
            )}
            <AddSuppressionsModal
              submitAction={addSuppressions}
              submitParams={{
                isContact: true,
                selection,
                formData,
                selectedItems,
                customerId,
                isUniverse
              }}
              onSubmitted={onSubmitted}
            />
          </ul>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { permissions } = getAuthClaims(state);

  return {
    allowTagAdditions:
      permissions &&
      permissions.hasOwnProperty(Targets.ItemTags) &&
      (Array.isArray(permissions[Targets.ItemTags])
        ? permissions[Targets.ItemTags].some(p => p == Actions.Create)
        : permissions[Targets.ItemTags] == Actions.Create)
  };
};

export default connect(
  mapStateToProps,
  null
)(ContactsActionsDropdown);
