import { getAuthClaims } from "@redriver/cinnamon";
import { FilterBase } from "components/search";
import { mapCriteriaToComponent } from "components/search/filters/readonly";
import { ListRoutes } from "constants/routes";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Accordion, Button, Segment } from "semantic-ui-react";
import { getListsOverviewState } from "./selectors";

class ListSearchCriteria extends React.Component {
  state = {
    visible: false,
    criteriaVisibility: {}
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { criteriaVisibility: oldCriteria } = this.state;

    const criteriaVisibility = {
      ...oldCriteria,
      [index]: !oldCriteria[index]
    };

    this.setState({ criteriaVisibility });
  };

  updateSearchCriteria = () => {
    const { isContactList, listId, history } = this.props;
    history.push(
      isContactList
        ? ListRoutes.UpdateContact(listId)
        : ListRoutes.UpdateCompany(listId)
    );
  };

  render() {
    const { visible, criteriaVisibility } = this.state;
    const { criteria, userId } = this.props;
    if (!criteria || !criteria.length) return null;
    return (
      <Segment className="m-t-1 p-a-0" style={{ width: "100%" }}>
        <section className="search-filter readonly-criteria">
          <FilterBase.Header
            title="Search Criteria"
            collapsible
            collapsed={!visible}
            onCollapseToggle={this.toggleVisibility}
            removable={false}
          />
          <FilterBase.Body collapsed={!visible}>
            <div className="list-search-criteria-container">
              <Accordion>
                {criteria.map(item => {
                  const Comp = mapCriteriaToComponent(item);
                  return (
                    <Comp
                      key={item.id}
                      userId={userId}
                      active={criteriaVisibility[item.type]}
                      handleClick={this.handleClick}
                      criteria={item}
                    />
                  );
                })}
              </Accordion>
              <div className="list-button-container">
                <Button
                  className="primary green-small-button"
                  content="Update Search Criteria"
                  onClick={this.updateSearchCriteria}
                />
              </div>
            </div>
          </FilterBase.Body>
        </section>
      </Segment>
    );
  }
}

const actions = {};

const mapStateToProps = state => {
  const { isContactList, listId, criteria } = getListsOverviewState(state);
  const { userId } = getAuthClaims(state);
  return { isContactList, listId, criteria, userId };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(ListSearchCriteria)
);
