import { Form, Format, FormBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { updateCustomerSubscription } from "./actions";

const CustomerSubscriptionForm = ({ data, onSubmitted, customerId }) => {
  return (
    <FormBuilder
      submitAction={updateCustomerSubscription}
      submitParams={customerId}
      onSubmitted={onSubmitted}
      initialData={data}
      initialReadOnly
      renderError={false}
      renderForm={(formProps, state, events) => (
        <Form {...formProps} className="customer-subscription-form">
          <div className="flex">
            <h2 className="m-r-1">Subscription Details</h2>
            {state.readOnly ? (
              <PermissionCheck target={Targets.Customers} action={Actions.Edit}>
                <Icon
                  className="primary cursor-pointer"
                  name="pencil"
                  onClick={events.onToggleReadOnly}
                />
              </PermissionCheck>
            ) : (
              <React.Fragment>
                <Button
                  className="grey-small-button"
                  content="Cancel"
                  onClick={events.onToggleReadOnly}
                />
                <Button
                  className="primary green-small-button"
                  content="Save"
                  onClick={events.onSubmit}
                  loading={state.submitting}
                />
              </React.Fragment>
            )}
          </div>
          <ul className="list-unstyled m-t-05">
            <li>
              <div className="labelled-formgroup">
                <label>Created</label>
                <p className="field" style={{ wordBreak: "break-all" }}>
                  <span className="m-r-2">{formProps.value.createdBy}</span>
                  <Format.DateTime
                    value={formProps.value.created}
                    format="short"
                  />
                </p>
              </div>
            </li>
            <li>
              <div className="labelled-formgroup">
                <label>Type</label>
                <Form.Input
                  field="subscriptionType"
                  placeholder="Type"
                  renderReadOnly={({ value }, state) => (
                    <p className="field">{value}</p>
                  )}
                />
              </div>
            </li>
            <li>
              <div className="labelled-formgroup">
                <label>Downloads limit</label>
                <Form.Numeric
                  field="downloadMax"
                  placeholder="Downloads limit"
                  renderReadOnly={({ value }, state) => (
                    <p className="field">{value || "-"}</p>
                  )}
                />
              </div>
            </li>
            <li>
              <div className="labelled-formgroup">
                <label>Usage</label>
                <p className="field">
                  {formProps.value.segmentCurrentNumber || "-"}
                </p>
              </div>
            </li>
            <li>
              <div className="labelled-formgroup">
                <label>Last Change</label>
                <p className="field">
                  {!!formProps.value.lastSegmentChange ? (
                    <Format.Date
                      value={formProps.value.lastSegmentChange}
                      format="short"
                    />
                  ) : (
                    "-"
                  )}
                </p>
              </div>
            </li>
            <li>
              <div className="labelled-formgroup">
                <label>Description</label>
                <Form.Input
                  field="segmentDetails"
                  fluid
                  required
                  placeholder="Description"
                  renderReadOnly={({ value }, state) => (
                    <p className="field">{value || "-"}</p>
                  )}
                />
              </div>
            </li>
            <li>
              <div className="labelled-formgroup">
                <label>Customer ID</label>
                <p className="field" style={{ wordBreak: "break-all" }}>
                  <span className="m-r-2">{formProps.value.originalId}</span>
                </p>
              </div>
            </li>
          </ul>
        </Form>
      )}
    />
  );
};

export default CustomerSubscriptionForm;
