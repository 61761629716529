import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "semantic-ui-react";
import CustomTreeNode from "./CustomTreeNode";

/**
 * Displays data in a nested tree structure
 */
const CustomTreeView = ({
  nodes,
  nodeKey,
  indent,
  fixed,
  collapsedIcon,
  expandedIcon,
  defaultExpanded,
  renderNode,
  animateNodes,
  className,
  companyId
}) => (
  <div className={classNames("cin tree-view", className)}>
    <CustomTreeNode
      node={{ root: true, children: nodes }}
      nodeKey={nodeKey}
      indent={indent}
      fixed={fixed}
      collapsedIcon={collapsedIcon}
      expandedIcon={expandedIcon}
      defaultExpanded={defaultExpanded}
      renderNode={renderNode}
      animate={animateNodes}
      companyId={companyId}
    />
  </div>
);

CustomTreeView.propTypes = {
  /**
   * Array of tree nodes objects in format [{ value: "", text: "", children: [] }]
   * @property {any} `value` Unique key for the node (can use a different property name by overriding the nodeKey prop)
   * @property {string} `text` Text to display for the node (optional if using the custom renderNode prop)
   * @property {Array} `children` Optional child node objects for nesting data in the tree
   * @property {boolean} `defaultExpanded` Whether the node should be expanded when initially mounted
   * @property {string} `className` Optional classes for styling
   */
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Property name for unique key in each node object
   */
  nodeKey: PropTypes.string,
  /**
   * Indent width for each sub-level of nodes
   */
  indent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Whether the tree should be fixed and not expandable/collapsable
   */
  fixed: PropTypes.bool,
  /**
   * Icon to display when a node is collapsed
   */
  collapsedIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Icon to display when a node is expanded
   */
  expandedIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Whether the tree should be fully expanded when initially mounted
   */
  defaultExpanded: PropTypes.bool,
  /**
   * Custom render function for each node in the tree
   * @param {Object} `node` The current node being rendered
   * @param {Object} `state` An object representing the current state
   * @param {boolean} `state.expanded` Whether the node is currently expanded
   * @param {Object} `events` An object containing useful callbacks that can be triggered
   * @param {function} `events.onCollapse` Collapses the node
   * @param {function} `events.onExpand` Expands the node
   */
  renderNode: PropTypes.func,
  /**
   * Time in milliseconds for node animation transitions, or false to disable
   */
  animateNodes: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  /**
   * Additional classes for styling
   */
  className: PropTypes.string
};

CustomTreeView.defaultProps = {
  nodeKey: "value",
  indent: 20,
  fixed: false,
  collapsedIcon: <Icon name="triangle right" size="large" />,
  expandedIcon: <Icon name="triangle down" size="large" />,
  defaultExpanded: false,
  animateNodes: 400
};

export default CustomTreeView;
