import {
  ActionBar,
  Format,
  ListBuilder,
  Modal,
  ModalBuilder,
  Table,
  TriggerBuilder
} from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { SearchResultCounter } from "components/lists";
import { PageNumber } from "components/pagination";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes } from "constants/routes";
import {
  canDownloadLeftContact,
  downloadLeftContact,
  downloadOptOut,
  triggerLeftContactDownload
} from "features/Contacts/OptOut/actions";
import * as fileSaver from "file-saver";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Grid, Icon, Popup, Segment } from "semantic-ui-react";
import { getOptOutReports } from "./actions";
import Filters from "./Filters";

const OptOutReport = ({
  downloadLeftContact,
  canDownloadLeftContact,
  history
}) => {
  const [
    isBackgroundDownloadVisible,
    backgroundDownloadModalVisible
  ] = useState(false);
  const [isDirectDownloadVisible, directDownloadModalVisible] = useState(false);
  const [currentFile, setCurrentFile] = useState({});
  const [pagination, setPagination] = useState({ pageSize: 10, pageNumber: 1 });
  return (
    <ListBuilder
      withTable
      initialData={[]}
      dataTransform={data => data.results}
      totalTransform={data => data.totalResults}
      loadAction={getOptOutReports}
      initialPagination={pagination}
      renderList={(tableProps, { filters, pagination, total }, events) => (
        <Grid className="i4b-list-page user-page">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="i4b-list">
                <ActionBar>
                  <ActionBar.Item fluid>
                    <h1>Opt Out/Left Company Files</h1>
                  </ActionBar.Item>
                  <ActionBar.Item align="right">
                    <PermissionCheck
                      target={Targets.ContactOptOuts}
                      action={Actions.Advanced}
                    >
                      <Button disabled positive>
                        Basic
                      </Button>
                      <Button
                        onClick={() =>
                          history.push(AppRoutes.AdvancedOptOutReport)
                        }
                      >
                        Advanced
                      </Button>
                    </PermissionCheck>
                  </ActionBar.Item>
                </ActionBar>
                <Filters
                  filters={filters}
                  onChangeFilters={events.onChangeFilters}
                  pageSize={pagination.pageSize}
                  onPageSizeChange={events.onChangePagination}
                />

                <Segment>
                  <Table {...tableProps} dataKey="id" basic="very">
                    <Table.Column
                      render={item => (
                        <Icon
                          className={
                            item.downloadedUtc ? "color-green" : "color-red"
                          }
                          name="square full"
                          size="small"
                        />
                      )}
                      width="2.5%"
                    />
                    <Table.Column
                      render={item => <Format.Date value={item.createdUtc} />}
                      title="Created Date"
                    />
                    <Table.Column
                      render={item =>
                        item.downloadedUtc ? (
                          <React.Fragment>
                            <Format.Date value={item.downloadedUtc} />
                            <Popup
                              wide
                              content={
                                <div>
                                  {item.downloads.map(d => (
                                    <div>
                                      {d.name} -{" "}
                                      {moment
                                        .utc(d.downloadedUtc)
                                        .local()
                                        .format("DD/MM/YYYY HH:mm")}
                                    </div>
                                  ))}
                                </div>
                              }
                              trigger={<Icon name="info circle" />}
                            />
                          </React.Fragment>
                        ) : (
                          "Report Requires Download"
                        )
                      }
                      title="Download Date"
                    />
                    <Table.Column
                      render={item =>
                        item.isOptOut ? "Opt Out" : "Left Company"
                      }
                      title="Type"
                    />
                    <Table.Column
                      name="Actions"
                      render={item => (
                        <TriggerBuilder
                          submitAction={
                            !item.isOptOut
                              ? () => {
                                  return canDownloadLeftContact(item.id);
                                }
                              : null
                          }
                          onSubmit={() => {
                            if (item.isOptOut) {
                              setCurrentFile({
                                tokenId: item.id,
                                isOptOut: true,
                                createdUtc: item.createdUtc
                              });
                              directDownloadModalVisible(true);
                            }
                          }}
                          renderTrigger={(fireTrigger, state, events) => (
                            <Button
                              onClick={fireTrigger}
                              style={{ width: "26px", height: "26px" }}
                              className={`actions-dropdown-button m-r-1 can-download`}
                            />
                          )}
                          onSubmitted={result => {
                            if (result == null) {
                              return;
                            } else if (result.canDirectDownload) {
                              setCurrentFile({
                                tokenId: item.id,
                                isOptOut: false,
                                createdUtc: item.createdUtc
                              });
                              directDownloadModalVisible(true);
                            } else {
                              //Show modal and trigger background file creation
                              setCurrentFile({
                                tokenId: item.id,
                                isOptOut: true,
                                createdUtc: item.createdUtc
                              });
                              backgroundDownloadModalVisible(true);
                            }
                          }}
                          submitParams={item.id}
                        />
                      )}
                      width={"15%"}
                    />
                    <ModalBuilder
                      visible={isBackgroundDownloadVisible}
                      submitAction={triggerLeftContactDownload}
                      onCancelled={() => {
                        setCurrentFile({});
                        backgroundDownloadModalVisible(false);
                      }}
                      onSubmitted={() => {
                        setCurrentFile({});
                        backgroundDownloadModalVisible(false);
                      }}
                      submitParams={currentFile.tokenId}
                      renderModal={modalProps => (
                        <Modal.Confirmation
                          {...modalProps}
                          header="Background file creation"
                          size="tiny"
                        >
                          <p>
                            Your file will be created in the background and be
                            added to your secure file area.
                            <br />
                            You will receive a notification when the file has
                            been created.
                          </p>
                        </Modal.Confirmation>
                      )}
                    />
                    <ModalBuilder
                      visible={isDirectDownloadVisible}
                      submitAction={
                        currentFile.isOptOut
                          ? downloadOptOut
                          : downloadLeftContact
                      }
                      onCancelled={() => {
                        setCurrentFile({});
                        directDownloadModalVisible(false);
                      }}
                      onSubmitted={result => {
                        const fileDate = moment(currentFile.createdUtc).format(
                          "Do_MMMM_YYYY"
                        );
                        fileSaver.saveAs(
                          result,
                          currentFile.isOptOut
                            ? `Opt_Outs_${fileDate}.csv`
                            : `Left_Company_${fileDate}.csv`
                        );
                        directDownloadModalVisible(false);
                        events.onRefresh();
                      }}
                      submitParams={currentFile.tokenId}
                      renderModal={modalProps => (
                        <Modal.Confirmation
                          {...modalProps}
                          header="Download File"
                          size="tiny"
                        >
                          <p>
                            Download{" "}
                            {currentFile.isOptOut ? "opt out" : "left contact"}{" "}
                            file
                          </p>
                        </Modal.Confirmation>
                      )}
                    />
                  </Table>
                  <div className="i4b-list-footer">
                    <SearchResultCounter
                      pageSize={pagination.pageSize}
                      pageNumber={pagination.pageNumber}
                      totalResults={total}
                    />
                    <PageNumber
                      totalItems={total}
                      onChange={x => {
                        const newPagination = {
                          pageSize: pagination.pageSize,
                          pageNumber: x
                        };
                        setPagination(newPagination);
                        events.onChangePagination(newPagination);
                      }}
                      pageSize={pagination.pageSize}
                      value={pagination.pageNumber}
                    />
                  </div>
                </Segment>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    />
  );
};
const actions = {
  downloadLeftContact,
  canDownloadLeftContact
};

export default withRouter(
  connect(
    null,
    actions
  )(OptOutReport)
);
