import React from "react";
import { connect } from "react-redux";
import { ListBuilder, FormBuilder, Form, Table } from "@redriver/cinnamon";
import { Grid, Segment } from "semantic-ui-react";
import moment from "moment";
import { PageNumber } from "components/pagination";
import { SearchResultCounter } from "components/lists";
import { getUserStats, GetUserStatsEarliestDate } from "./actions";
import UserStatsTable from "./UserStatsTable";
import FilterCustomerUserStats from "./FilterCustomerUserStats";
import { getUserStatsState } from "./selectors";

class ListCustomerUserStats extends React.Component {
  componentDidMount() {
    const { customerId, isOwnCustomer, GetUserStatsEarliestDate } = this.props;
    GetUserStatsEarliestDate({
      customerId,
      isOwnCustomer
    });
  }

  render() {
    const { customerId, earliestDate, isOwnCustomer } = this.props;
    return (
      <ListBuilder
        withTable
        debounceFilters={250}
        initialFilters={{ search: "", userActiveFilter: "Active" }}
        totalTransform={data => data.totalResults}
        dataTransform={data => data.results}
        loadAction={getUserStats}
        loadParams={{ customerId, isOwnCustomer }}
        renderList={(tableProps, state, events) => {
          const { filters, pagination, total } = state;
          return (
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={14}
              className="m-b-1"
              stretched
            >
              <div className="i4b-list">
                <h2>User Stats</h2>
                <FilterCustomerUserStats
                  filters={filters}
                  customerId={customerId}
                  onChangeFilters={events.onChangeFilters}
                  pageSize={pagination.pageSize}
                  onPageSizeChange={events.onChangePagination}
                  earliestDate={earliestDate}
                  customerId={customerId}
                  isOwnCustomer={isOwnCustomer}
                />
                <Segment>
                  <UserStatsTable
                    tableProps={tableProps}
                    customerId={customerId}
                  />
                  <div className="i4b-list-footer">
                    <SearchResultCounter
                      pageSize={pagination.pageSize}
                      pageNumber={pagination.pageNumber}
                      totalResults={total}
                    />
                    <PageNumber
                      totalItems={total}
                      onChange={x =>
                        events.onChangePagination({
                          pageSize: pagination.pageSize,
                          pageNumber: x
                        })
                      }
                      pageSize={pagination.pageSize}
                      value={pagination.pageNumber}
                    />
                  </div>
                </Segment>
              </div>
            </Grid.Column>
          );
        }}
      />
    );
  }
}

const actions = {
  GetUserStatsEarliestDate
};

const mapStateToProps = state => ({
  earliestDate: getUserStatsState(state).earliestDate
});

export default connect(
  mapStateToProps,
  actions
)(ListCustomerUserStats);
