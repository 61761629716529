import React, { Component } from "react";
import { ActionBar, Form, FormBuilder, PageSize } from "@redriver/cinnamon";

class FilterSuppressions extends Component {
  render() {
    const { filters, onChangeFilters, pageSize, onPageSizeChange } = this.props;
    const types = [
      { value: true, text: "Contacts" },
      { value: false, text: "Companies" }
    ];

    return (
      <ActionBar>
        <ActionBar.Item fluid>
          <FormBuilder
            initialData={filters}
            onChange={onChangeFilters}
            renderForm={formProps => (
              <Form {...formProps}>
                <Form.Input
                  field="search"
                  placeholder={
                    filters.isContact
                      ? "Search contact or company name"
                      : "Search company name"
                  }
                  width={25}
                />
                <Form.RadioGroup
                  field="isContact"
                  options={types}
                  inline
                  style={{ marginLeft: "2em" }}
                />
              </Form>
            )}
          />
        </ActionBar.Item>
        <ActionBar.Item className="i4b-filter-pagesize">
          <PageSize
            value={pageSize}
            onChange={x => onPageSizeChange({ pageSize: x, pageNumber: 1 })}
            options={[10, 25, 50, 100]}
          />
        </ActionBar.Item>
      </ActionBar>
    );
  }
}

export default FilterSuppressions;
