import { ErrorMessage, Form } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import PasswordForgotten from "../PasswordForgotten";
import { getPasswordResetState } from "../selectors";
import { requestPasswordReset, validateResetToken } from "./actions";

class PasswordReset extends React.Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    onValidateToken: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validatingToken: PropTypes.bool,
    validationSlow: PropTypes.bool,
    tokenCorrect: PropTypes.bool,
    updating: PropTypes.bool,
    slowRequest: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.any
  };

  state = {
    newPassword: "",
    confirmPassword: "",
    validated: false,
    showErrors: false
  };

  componentWillMount = () => {
    if (this.props.token) {
      this.props.onValidateToken(this.props.token);
    }
  };

  onFormChange = (field, data) => this.setState({ [field]: data });

  onFormValidated = validated => this.setState({ validated });

  resetPassword = e => {
    e.preventDefault();
    if (!this.state.validated) {
      this.setState({ showErrors: true });
      return;
    }
    const { updating, token } = this.props;
    if (updating) return;
    const { newPassword } = this.state;
    this.props.onSubmit(token, newPassword);
  };

  render() {
    const {
      token,
      validatingToken,
      validationSlow,
      tokenCorrect,
      success
    } = this.props;

    if (!token) {
      return <p>No reset token supplied.</p>;
    }

    if (validatingToken) {
      return validationSlow ? <p>Validating reset token...</p> : null;
    }

    if (!tokenCorrect) {
      return (
        <div>
          <p>The reset token supplied is invalid, please request another.</p>
          <PasswordForgotten />
        </div>
      );
    }

    if (success) {
      return (
        <div>
          <p>Your password has been reset successfully.</p>
          <div style={{ marginTop: 20 }}>
            <Button
              fluid
              primary
              as={Link}
              to={SystemRoutes.Login}
              className="system-button"
            >
              Continue
            </Button>
          </div>
        </div>
      );
    }

    const { updating, slowRequest, error } = this.props;
    const isBusy = updating && slowRequest;

    return (
      <Form
        value={this.state}
        onChange={this.onFormChange}
        onValidated={this.onFormValidated}
        showErrors={this.state.showErrors}
      >
        <Form.Password
          fluid
          field="newPassword"
          label="New password"
          icon="lock"
          iconPosition="left"
          placeholder="New password"
          disabled={isBusy}
          autoFocus
          required
          minLength={6}
        />
        <Form.Password
          fluid
          field="confirmPassword"
          label="Confirm password"
          icon="lock"
          iconPosition="left"
          placeholder="Confirm password"
          disabled={isBusy}
          required
          confirmField="newPassword"
        />
        <Button
          fluid
          primary
          onClick={this.resetPassword}
          disabled={isBusy}
          loading={isBusy}
          className="system-button"
        >
          Submit
        </Button>
        <ErrorMessage error={error} />
      </Form>
    );
  }
}

const actions = {
  onValidateToken: validateResetToken,
  onSubmit: requestPasswordReset
};

const mapStateToProps = state => {
  const passwordResetState = getPasswordResetState(state);
  return {
    validatingToken: passwordResetState.validatingToken,
    validationSlow: passwordResetState.validationSlow,
    tokenCorrect: passwordResetState.tokenCorrect,
    updating: passwordResetState.updating,
    slowRequest: passwordResetState.slowRequest,
    success: passwordResetState.success,
    error: passwordResetState.error
  };
};

export default connect(
  mapStateToProps,
  actions
)(PasswordReset);
