export const TileSize = {
  Small: "small",
  Medium: "medium",
  Large: "large"
};

export const TilePadding = {
  None: "none",
  Medium: "medium",
  Large: "large"
};
