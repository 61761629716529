import {
  companyFollowedFilter,
  companyUploadsFilter,
  contactFollowedFilter,
  contactsInCompanyListsFilter,
  contactTagsFilter,
  contactUploadsFilter,
  customFieldsFilter,
  emailDdiFilter,
  industriesFilter,
  jobsFilter,
  jobTitlesFilter,
  languagesFilter,
  listsFilter,
  LOBRolesFilter,
  postalAreasFilter,
  sizeFilter,
  stockExchangeIndexesFilter,
  techRolesFilter
} from "./filters";

export const addSearchFilter = ({ formData }, value) => {
  const { searchCriteria } = formData;

  const newId = (
    searchCriteria.reduce((acc, criteria) => {
      const id = parseInt(criteria.id);
      return id > acc ? id : acc;
    }, 0) + 1
  ).toString();

  switch (value) {
    case "sizeFilter":
      sizeFilter.id = newId;
      return [...searchCriteria, sizeFilter];
    case "postalAreasFilter":
      postalAreasFilter.id = newId;
      return [...searchCriteria, postalAreasFilter];
    case "stockExchangeIndexesFilter":
      stockExchangeIndexesFilter.id = newId;
      return [...searchCriteria, stockExchangeIndexesFilter];
    case "industriesFilter":
      industriesFilter.id = newId;
      return [...searchCriteria, industriesFilter];
    case "languagesFilter":
      languagesFilter.id = newId;
      return [...searchCriteria, languagesFilter];
    case "jobTitlesFilter":
      jobTitlesFilter.id = newId;
      return [...searchCriteria, jobTitlesFilter];
    case "techRolesFilter":
      techRolesFilter.id = newId;
      return [...searchCriteria, techRolesFilter];
    case "LOBRolesFilter":
      LOBRolesFilter.id = newId;
      return [...searchCriteria, LOBRolesFilter];
    case "contactTagsFilter":
      contactTagsFilter.id = newId;
      return [...searchCriteria, contactTagsFilter];
    case "customFieldsFilter":
      customFieldsFilter.id = newId;
      return [...searchCriteria, customFieldsFilter];
    case "listsFilter":
      listsFilter.id = newId;
      return [...searchCriteria, listsFilter];

    case "contactUploadsFilter":
      contactUploadsFilter.id = newId;
      return [...searchCriteria, contactUploadsFilter];
    case "companyUploadsFilter":
      companyUploadsFilter.id = newId;
      return [...searchCriteria, companyUploadsFilter];
    case "jobsFilter":
      jobsFilter.id = newId;
      return [...searchCriteria, jobsFilter];
    case "contactFollowedFilter":
      contactFollowedFilter.id = newId;
      return [...searchCriteria, contactFollowedFilter];
    case "companyFollowedFilter":
      companyFollowedFilter.id = newId;
      return [...searchCriteria, companyFollowedFilter];
    case "contactsInCompanyListsFilter":
      contactsInCompanyListsFilter.id = newId;
      return [...searchCriteria, contactsInCompanyListsFilter];
    case "emailDdiFilter":
      emailDdiFilter.id = newId;
      return [...searchCriteria, emailDdiFilter];
    default:
      return [...searchCriteria];
  }
};

export const removeFilter = (state, id) => {
  const { formData } = state;
  const { searchCriteria } = formData;
  return searchCriteria.filter(el => el.id !== id);
};

export const clearSearchFilter = state => {
  const { formData } = state;
  const { searchCriteria } = formData;
  const newSearchCriteria = searchCriteria.map(el => {
    return el.type == "searchFilter"
      ? { ...el, searchFilter: { search: "" } }
      : el;
  });
  return newSearchCriteria;
};

export const clearSelected = (state, id) => {
  const { formData } = state;
  const { searchCriteria } = formData;
  const newSearchCriteria = searchCriteria.map(el => {
    if (el.id == id) {
      switch (el.type) {
        case "countriesFilter":
          return { ...el, selectedCountries: [], postalFilter: "" };
        case "postalAreasFilter":
          return { ...el, selectedAreas: [], postalFilter: "" };
        case "stockExchangeIndexesFilter":
          return { ...el, selectedIndexes: [] };
        case "industriesFilter":
          return {
            ...el,
            industriesSelection: {
              included: [],
              excluded: [],
              match: "one"
            }
          };
        case "languagesFilter":
          return { ...el, selectedLanguages: [] };
        case "techRolesFilter":
          return { ...el, selectedTechRoles: [] };
        case "LOBRolesFilter":
          return { ...el, selectedLobRoles: [] };
        case "contactTagsFilter":
          return {
            ...el,
            tagsSelection: {
              match: "all",
              tags: {
                included: [],
                excluded: []
              }
            }
          };
        case "customFieldsFilter":
          return {
            ...el,
            fieldsSelection: {
              match: "all",
              customFields: {
                included: [],
                excluded: []
              }
            }
          };
        case "listsFilter":
          return {
            ...el,
            listsSearch: {
              ...el.listsSearch,
              listsSelection: {
                included: [],
                excluded: []
              }
            }
          };
        case "contactUploadsFilter":
        case "companyUploadsFilter":
          return {
            ...el,
            uploadsSearch: {
              ...el.uploadsSearch,
              uploadsSelection: {
                included: [],
                excluded: []
              }
            }
          };
        case "jobsFilter":
          return {
            ...el,
            jobsSearch: {
              selectedLobRoles: [],
              selectedTechRoles: [],
              jobTitlesFilter: {
                jobTitlesMatch: "contains",
                jobTitles: [
                  {
                    id: "0",
                    title: ""
                  }
                ]
              }
            }
          };
        case "contactFollowedFilter":
          return {
            ...el,
            contactFollowedSearch: {
              ...el.followedSearch,
              isFollowingContact: null
            }
          };
        case "companyFollowedFilter":
          return {
            ...el,
            companyFollowedSearch: {
              ...el.followedSearch,
              isFollowingCompany: null
            }
          };
        case "contactsInCompanyListsFilter":
          return {
            ...el,
            contactsInCompanyListsSearch: {
              ...el.contactsInCompanyListsSearch,
              listsSelection: {
                included: [],
                excluded: []
              }
            }
          };
        case "emailDdiFilter":
          return {
            ...el,
            emailDdiSearch: {
              ...el.emailDdiSearch,
              emailOnly: false,
              ddiOnly: false
            }
          };

        default:
          return el;
      }
    } else return el;
  });
  return newSearchCriteria;
};

export const clearSizeFilter = (state, id) => {
  const { formData } = state;
  const { searchCriteria } = formData;
  const newSearchCriteria = searchCriteria.map(el => {
    if (el.type == "sizeFilter") {
      const { companySizeDetails } = el;
      const newDetails = { ...companySizeDetails };
      Object.keys(newDetails).forEach(prop => {
        switch (prop) {
          case "match":
            return (newDetails[prop] = "all");
          case "revenueCurrency":
            return (newDetails[prop] = "GBP");
          default:
            return (newDetails[prop] = null);
        }
      });
      return { ...el, companySizeDetails: newDetails };
    }
    return el;
  });
  return newSearchCriteria;
};

export const clearJobTitlesFilter = state => {
  const { formData } = state;
  const { searchCriteria } = formData;
  const newSearchCriteria = searchCriteria.map(el => {
    if (el.type == "jobTitlesFilter") {
      return {
        ...el,
        jobTitlesFilter: {
          jobTitlesMatch: "contains",
          jobTitles: [
            {
              id: "0",
              title: ""
            }
          ]
        }
      };
    }
    return el;
  });
  return newSearchCriteria;
};

export const clearJobsFilter = state => {
  const { formData } = state;
  const { searchCriteria } = formData;
  const newSearchCriteria = searchCriteria.map(el => {
    if (el.type == "jobsFilter") {
      return {
        ...el,
        jobsSearch: {
          selectedLobRoles: [],
          selectedTechRoles: [],
          jobTitlesFilter: {
            jobTitlesMatch: "contains",
            jobTitles: [
              {
                id: "0",
                title: ""
              }
            ]
          }
        }
      };
    }
    return el;
  });
  return newSearchCriteria;
};
