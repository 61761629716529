import React from "react";
import { Route, Switch } from "react-router-dom";
import { Image } from "semantic-ui-react";
import { Breadcrumb } from "@redriver/cinnamon";

import Home from "assets/icons/home_breadcrumb.svg";
import { AppRoutes, UploadRoutes } from "constants/routes";
import {
  UploadBreadcrumbItem,
  UploadWizard,
  UploadPreLoader
} from "features/Uploads";

const UploadRoute = ({ match }) => {
  return (
    <UploadPreLoader uploadId={match.params.id}>
      <Breadcrumb className="i4b-breadcrumb-container">
        <Breadcrumb.Item
          link={AppRoutes.Root}
          icon={<Image src={Home} title="Home" />}
        />
        <Breadcrumb.Item link={AppRoutes.Uploads} content="Uploads" />
        <UploadBreadcrumbItem uploadId={match.params.id} />
      </Breadcrumb>
      <Switch>
        <Route
          exact
          path={UploadRoutes.Upload()}
          render={props => {
            return <UploadWizard uploadId={match.params.id} />;
          }}
        />
      </Switch>
    </UploadPreLoader>
  );
};

export default UploadRoute;
