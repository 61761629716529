import { deauthenticate } from "@redriver/cinnamon";

const NAMESPACE = "SIGNAL_R";

export const ActionTypes = {};

export const kickoutUser = () => async dispatch => {
  await dispatch(deauthenticate());
};

export const disableUser = () => async dispatch => {
  await dispatch(deauthenticate());
};
