import { apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "KNOWLEDGE_BASE";

export const ActionTypes = {
  getKnowledgeBaseItems: `${NAMESPACE}/GET_KNOWLEDGE_BASE_ITEMS`,
  submitKnowledgeRequest: `${NAMESPACE}/SUBMIT_KNOWLEDGE_REQUEST`,
  getKnowledgeBaseItemVideo: `${NAMESPACE}/GET_KB_VIDEO`
};

export const getKnowledge = ({ filters }) => {
  return apiGet(ActionTypes.getKnowledgeBaseItems, `knowledge`, {
    search: filters.search
  });
};

export const submitKnowledgeRequest = ({ requestText }) =>
  apiPost(ActionTypes.submitKnowledgeRequest, `knowledge/request`, {
    requestText
  });

export const getKnowledgeVideo = id =>
  apiGet(ActionTypes.getKnowledgeBaseItemVideo, `knowledge/${id}/stream`);
