import {
  addWidget,
  getDashboardState,
  removeWidget,
  Widgets
} from "features/Dashboard";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { getAuthState } from "@redriver/cinnamon";

class Menu extends React.Component {
  state = {};

  static propTypes = {
    filters: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string
    })
  };

  toggleMenu = e => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  onCheck = (key, obj) => {
    if (obj.checked) {
      this.props.addWidget(key);
    } else {
      this.props.removeWidget(key);
    }
  };

  render() {
    const { widgets, isSegmented, userType } = this.props;
    return (
      <Dropdown
        icon="cog"
        floating
        button
        className="icon"
        open={this.state.menuOpen}
        onClick={this.toggleMenu}
      >
        <Dropdown.Menu direction="left" className="widgets-menu">
          {Object.keys(Widgets)
            .filter(
              key =>
                !Widgets[key].alwaysShow &&
                (Widgets[key].userType === undefined ||
                  Widgets[key].userType === userType) &&
                (Widgets[key].forSegmentedCustomers === undefined ||
                  Widgets[key].forSegmentedCustomers === isSegmented)
            )
            .map(key => {
              const found = widgets.find(w => w.id === key);
              return (
                <Dropdown.Item key={key} onClick={e => e.preventDefault()}>
                  <Checkbox
                    label={Widgets[key].label}
                    checked={!!found}
                    onChange={(e, obj) => this.onCheck(key, obj)}
                  />
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const actions = {
  addWidget,
  removeWidget
};

const mapStateToProps = state => {
  const { widgets, isSegmented } = getDashboardState(state);
  const userType = getAuthState(state).claims.userType;
  return {
    widgets,
    isSegmented,
    userType
  };
};

export default connect(
  mapStateToProps,
  actions
)(Menu);
