import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import { Icon } from "semantic-ui-react";
import { resendVerification } from "./actions";

const ResendVerificationEmail = ({ id, onSubmitted, renderTrigger }) => (
  <ModalBuilder
    submitAction={resendVerification}
    submitParams={id}
    renderTrigger={renderTrigger}
    renderModal={modalProps => (
      <Modal.Confirmation
        {...modalProps}
        header={
          <div>
            <Icon name="warning sign" /> Resend Verification Email
          </div>
        }
        size="tiny"
      >
        <p>
          Are you sure you want to resend the verification email for this user?
        </p>
      </Modal.Confirmation>
    )}
  />
);

export default ResendVerificationEmail;
