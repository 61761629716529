import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";

import { createUserFromContact } from "./actions";
import { RoleDropdown } from "features/Roles";

const CreateUserFromContactModal = ({ id, onSubmitted, disabled }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={createUserFromContact}
      submitParams={id}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <Button primary onClick={showModal} disabled={disabled}>
          Create user
        </Button>
      )}
      renderModal={(modalProps, formProps) => (
        <Modal.Add
          {...modalProps}
          header={
            <div>
              <Icon name="plus" /> Create a user for this person
            </div>
          }
          submitLabel="Create User"
          size="tiny"
        >
          <Form {...formProps}>
            <RoleDropdown
              field="roleIds"
              label="Role(s)"
              required
              multiple
              fluid
              unknownValueOptions={({ fields }) => fields.roles}
            />
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default CreateUserFromContactModal;
