import { ActionBar, Form, FormBuilder } from "@redriver/cinnamon";
import { TemplateAccess, TemplateType } from "constants/settings/templates";
import React from "react";
import CreateTemplate from "./CreateTemplate";

const types = Object.keys(TemplateType).map(k => {
  return { value: TemplateType[k], text: k };
});

const viewableTemplates = isInternal =>
  Object.keys(TemplateAccess)
    .filter(k => (isInternal ? k !== "myCompanyTemplates" : true))
    .map(k => ({ value: k, text: TemplateAccess[k] }));

const FilterTemplates = ({
  filters,
  onChangeFilters,
  onCreateTemplate,
  columns,
  isInternal
}) => {
  const defaultCompanyColumns = !!columns
    ? columns.companies.filter(c => c.disabled).map(c => c.value)
    : [];
  const defaultContactColumns = !!columns
    ? columns.contacts.filter(c => c.disabled).map(c => c.value)
    : [];

  const modalInitialData = {
    companyColumns: defaultCompanyColumns,
    contactColumns: defaultContactColumns,
    fileFormat: "Comma"
  };

  return (
    <ActionBar className="filter-templates">
      <ActionBar.Item fluid>
        <FormBuilder
          initialData={filters}
          onChange={onChangeFilters}
          renderForm={formProps => (
            <Form {...formProps}>
              <Form.Group inline compact>
                <Form.Input
                  field="search"
                  label="Search Templates"
                  placeholder="Search templates"
                  width={25}
                />
                <Form.CheckboxGroup
                  field="templateTypes"
                  label="Template Types"
                  options={types}
                  inline
                  className="filter-cb"
                />
                <Form.CheckboxGroup
                  field="viewableTemplates"
                  label="Access"
                  options={viewableTemplates(isInternal)}
                  inline
                  className="filter-cb"
                />
              </Form.Group>
            </Form>
          )}
        />
      </ActionBar.Item>
      <ActionBar.Item align="right">
        <CreateTemplate
          onSubmitted={onCreateTemplate}
          columns={columns}
          isInternal={isInternal}
          initialData={modalInitialData}
        />
      </ActionBar.Item>
    </ActionBar>
  );
};

export default FilterTemplates;
