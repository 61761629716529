import React from "react";
import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import { resetView } from "./actions";
import { getDashboardState } from "./index";
import Widgets from "./Widgets";
import { getAuthState } from "@redriver/cinnamon";

class DashboardManager extends React.Component {
  static propTypes = {};

  componentWillMount() {
    this.props.resetView();
  }

  // maps a given widget stored in the backend,
  // and builds it out based on schema
  buildWidgets() {
    const { widgets, isSegmented, userType } = this.props;
    const widgetsToDisplay = widgets.map(w => w.id);
    return Object.keys(Widgets)
      .filter(
        key =>
          (Widgets[key].alwaysShow || widgetsToDisplay.indexOf(key) != -1) &&
          (Widgets[key].userType === undefined ||
            Widgets[key].userType === userType) &&
          (Widgets[key].forSegmentedCustomers === undefined ||
            Widgets[key].forSegmentedCustomers === isSegmented)
      )
      .map(key => Widgets[key]);
  }

  renderWidgets() {
    if (!this.props.widgets || this.props.loading) {
      return <Loader active />;
    }
    return (
      <div className="dashboard-widgets">
        {this.buildWidgets().map(w => w.content())}
      </div>
    );
  }

  render() {
    return <div>{this.renderWidgets()}</div>;
  }
}

const actions = {
  resetView
};

const mapStateToProps = state => {
  const { widgets, isSegmented, loading, filters } = getDashboardState(state);
  const userType = getAuthState(state).claims.userType;

  return {
    widgets,
    isSegmented,
    loading,
    filters,
    userType
  };
};

export default connect(
  mapStateToProps,
  actions
)(DashboardManager);
