import { apiGet, Form, mockSuccess, registerLookup } from "@redriver/cinnamon";
import { SearchCriteria } from "constants/search";
import React from "react";

const NAMESPACE = "CONTACTS/LOOKUPS";

const ActionTypes = {
  GetSearchCriteria: `${NAMESPACE}/GET_SEARCH_CRITERIA`,
  GetContactTags: `${NAMESPACE}/GET_CONTACT_TAGS`
};

const contactsSearchCriteria = SearchCriteria.filter(el =>
  el.types.includes("contacts")
);

registerLookup(
  "contactsSearchCriteriaLookup",
  () => mockSuccess(ActionTypes.GetSearchCriteria),
  {
    transform: data => {
      return {
        geography: contactsSearchCriteria.filter(
          el => el.category == "geography"
        ),
        company: contactsSearchCriteria.filter(el => el.category == "company"),
        contact: contactsSearchCriteria.filter(el => el.category == "contact"),
        lists: contactsSearchCriteria.filter(el => el.category == "lists"),
        uploads: contactsSearchCriteria.filter(el => el.category == "uploads")
      };
    }
  }
);

registerLookup(
  "contactTagLookup",
  () => apiGet(ActionTypes.GetContactTags, `contact-tags`),
  {
    cache: false,
    transform: data => data.children
  }
);

export const ContactTagsDropdown = ({
  value,
  multiple,
  required = true,
  allowAdditions = true
}) => {
  return (
    <Form.Dropdown
      field="tag"
      label="Contact Custom Field"
      placeholder="Contact Custom Field"
      fluid
      search
      required={required}
      lookup="contactTagLookup"
      multiple={multiple}
      allowAdditions={allowAdditions}
      unknownValueOptions={value ? [{ value: value, text: value }] : undefined}
    />
  );
};
