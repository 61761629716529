import { Form } from "@redriver/cinnamon";
import { isJobTitleEmpty, isJobTitlesEmpty } from "features/Utils";
import PropTypes from "prop-types";
import React from "react";
import FilterBase from "./FilterBase";
import JobsSummary from "./JobsSummary";
import JobTitleFilter from "./JobTitleFilter";
import LobFilter from "./LOBFilter";
import TechRoleFilter from "./TechRoleFilter";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";

class JobsFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    formData: PropTypes.object.isRequired,
    results: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    removeFilter: PropTypes.func.isRequired
  };

  state = {
    visible: true,
    total: null
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  shouldDisableJobTitlesArrayAdder = titles => {
    if (!titles) return true;

    return titles.some(isJobTitleEmpty);
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  render() {
    const {
      id,
      formData,
      loading,
      pageChanged,
      removeFilter,
      clearJobsFilter
    } = this.props;
    const { visible, total } = this.state;
    const { searchCriteria } = formData;
    const jobsSearchObj = searchCriteria.find(el => el.id == id);
    const { jobTitles } = jobsSearchObj.jobsSearch.jobTitlesFilter;
    const { selectedLobRoles, selectedTechRoles } = jobsSearchObj.jobsSearch;
    const adderDisabled = this.shouldDisableJobTitlesArrayAdder(jobTitles);

    return (
      <FilterBase
        total={total}
        loading={loading && !pageChanged}
        className="job-titles-filter"
      >
        <FilterBase.Header
          title="Job Personas"
          collapsible
          collapsed={!visible}
          onCollapseToggle={this.toggleVisibility}
          removable
          onRemove={event => removeFilter(id, event)}
          showClearer={
            (jobTitles != null && !isJobTitlesEmpty(jobTitles)) ||
            (selectedLobRoles != null && selectedLobRoles.length !== 0) ||
            (selectedTechRoles != null && selectedTechRoles.length !== 0)
          }
          onClear={e => clearJobsFilter(id, e)}
        >
          {!visible && (
            <div className="ellipsis-paragraph">
              {(jobTitles == null || isJobTitlesEmpty(jobTitles)) &&
              (selectedLobRoles == null || selectedLobRoles.length === 0) &&
              (selectedTechRoles == null || selectedTechRoles.length === 0) ? (
                <span>No filters selected</span>
              ) : (
                <JobsSummary
                  titles={jobsSearchObj.jobsSearch.jobTitlesFilter}
                  lob={selectedLobRoles}
                  tech={selectedTechRoles}
                />
              )}
            </div>
          )}
        </FilterBase.Header>
        <FilterBase.Body
          collapsed={!visible}
          style={{ maxHeight: visible ? "700px" : "" }}
        >
          <div className="i4b-filter-container handle-own-height">
            <Form.Object field="jobsSearch">
              <LobFilter />
              <TechRoleFilter />
              <JobTitleFilter adderDisabled={adderDisabled} />
            </Form.Object>
          </div>
        </FilterBase.Body>
      </FilterBase>
    );
  }
}

export default JobsFilter;
