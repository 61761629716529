import React from "react";
import { SideMenu } from "@redriver/cinnamon";
import { UserRoutes } from "constants/routes";
import { withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";

const UserMenu = ({ userId, history, match }) => (
  <SideMenu pointing vertical>
    <SideMenu.Item link={UserRoutes.Overview(userId)} exact>
      <span>Overview</span>
    </SideMenu.Item>

    <SideMenu.Item link={UserRoutes.Whitelist(userId)} exact>
      <span>Whitelist</span>
    </SideMenu.Item>

    <SideMenu.Item link={UserRoutes.Countries(userId)} exact>
      <span>Countries</span>
    </SideMenu.Item>
  </SideMenu>
);

export default withRouter(UserMenu);
