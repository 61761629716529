import { apiGet, apiPost, apiPut, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/CREATE_CUSTOMER";

export const ActionTypes = {
  CreateCustomer: `${NAMESPACE}/CREATE_CUSTOMER`
};

export const createCustomer = customer =>
  apiPost(ActionTypes.CreateCustomer, `customers`, customer);
