import {
  apiPatch,
  apiPost,
  refreshJwt,
  getAuthState
} from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/EMAIL_VERIFICATION";
export const VALIDATE_TOKEN = `${NAMESPACE}/VALIDATE`;
export const RESEND_VERIFICATION = `${NAMESPACE}/RESEND`;

export const validateEmailToken = token => async (dispatch, getState) => {
  // make the validation api call
  const validateAction = apiPatch(
    VALIDATE_TOKEN,
    `auth/email-tokens/${token}`,
    { isUsed: true },
    { auth: false }
  );
  const { success } = await dispatch(validateAction);
  if (!success) return;
  // user may be logged in already
  // refresh session jwt to update the email verified state
  const authState = getAuthState(getState());
  if (authState.loggedIn) {
    try {
      await refreshJwt(dispatch);
    } catch (error) {
      return;
    }
  }
};

export const resendVerification = email =>
  apiPost(
    RESEND_VERIFICATION,
    `auth/email-tokens`,
    { email },
    { auth: false, preventErrorNotification: true }
  );
