import React from "react";
import { ModalBuilder, Modal, Table } from "@redriver/cinnamon";
import { Icon } from "semantic-ui-react";
import { editStatus } from "./actions";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/permissions";

const EditUserStatus = ({ id, userIsDisabled, onSubmitted, renderTrigger }) => (
  <ModalBuilder
    submitAction={editStatus}
    submitParams={{ userIsDisabled, id }}
    onSubmitted={onSubmitted}
    renderTrigger={renderTrigger}
    renderModal={modalProps => (
      <Modal.Confirmation
        {...modalProps}
        header={
          <div>
            <Icon name="warning sign" />{" "}
            {userIsDisabled ? "Enable User" : "Disable User"}
          </div>
        }
        size="tiny"
      >
        <p>
          Are you sure you want to {userIsDisabled ? "enable " : "disable "}{" "}
          this user?
        </p>
      </Modal.Confirmation>
    )}
  />
);

export default EditUserStatus;
