import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, getAuthClaims, PageLoader } from "@redriver/cinnamon";
import { StockExchangeIndexesLookup } from "../lookups";
import FilterBase from "./FilterBase";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";
import FilterClearer from "./FilterClearer";

/**
 * @deprecated No longer rendered; SE criteria not calculated any more. See id:78933
 */
class StockExchangeIndexesFilter extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    results: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    clearSelected: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired
  };
  state = {
    visible: true,
    indexesSelected: "",
    total: null
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  componentDidMount = () => {
    const { id, formData } = this.props;
    this.getSelectedIndexes(formData, id);
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  componentDidUpdate = prevProps => {
    const { id, formData, loading, results, pageChanged } = this.props;
    if (prevProps.formData !== formData) {
      this.getSelectedIndexes(formData, id);
    }
  };

  getSelectedIndexes = (formData, id) => {
    const { searchCriteria } = formData;

    const indexesObj = searchCriteria.find(el => el.id == id);
    const { selectedIndexes } = indexesObj;

    this.setState({
      indexesSelected: selectedIndexes.length || null
    });
  };

  render() {
    const {
      userId,
      loading,
      pageChanged,
      id,
      removeFilter,
      clearSelected
    } = this.props;
    const { visible, indexesSelected, total } = this.state;

    return (
      <StockExchangeIndexesLookup
        userId={userId}
        render={({ response }, refresh) => (
          <FilterBase total={total} loading={loading && !pageChanged}>
            {response ? (
              <React.Fragment>
                <FilterBase.Header
                  title="Stock Exchange Indexes"
                  collapsible
                  collapsed={!visible}
                  onCollapseToggle={this.toggleVisibility}
                  onRemove={event => removeFilter(id, event)}
                  showClearer={indexesSelected}
                  onClear={clearSelected}
                >
                  {!visible && (
                    <div
                      className="ellipsis-paragraph"
                      style={{
                        fontSize: "0.75rem",
                        paddingTop: "0.2rem",
                        marginRight: "1em",
                        marginBottom: 0
                      }}
                    >
                      {indexesSelected
                        ? `${indexesSelected} selected indexes`
                        : "No indexes selected"}
                    </div>
                  )}
                </FilterBase.Header>
                <FilterBase.Body collapsed={!visible}>
                  <div className="countries-selection-container">
                    <Form.CheckboxTree
                      key={id}
                      className="checkbox-tree"
                      field="selectedIndexes"
                      nodes={[
                        {
                          value: `${response.value}`,
                          text: `${response.text}`,
                          defaultExpanded: true,
                          children: response.children || []
                        }
                      ]}
                    />
                  </div>
                </FilterBase.Body>
              </React.Fragment>
            ) : (
              <PageLoader
                active
                inline="centered"
                size="small"
                className="p-y-05"
              />
            )}
          </FilterBase>
        )}
      />
    );
  }
}

const mapStateToProps = state => {
  const { userId } = getAuthClaims(state);

  return {
    userId
  };
};

export default connect(
  mapStateToProps,
  null
)(StockExchangeIndexesFilter);
