import React from "react";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateLetterFilter } from "./actions";
import { getListsOverviewState } from "./selectors";

class LettersFilter extends React.Component {
  static propTypes = {
    onLetterFilterChanged: PropTypes.func.isRequired
  };

  render() {
    const { updateLetterFilter, firstLetter } = this.props;
    const letters = [
      "all",
      "0-9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z"
    ];

    return (
      <div className="list-letter-filter-wrapper">
        <div className="list-letter-filter">
          {letters.map((l, i) => (
            <Button
              key={i}
              className={
                firstLetter === l ? "label-selected" : "label-not-selected"
              }
              onClick={() => updateLetterFilter(l)}
            >
              {l}
            </Button>
          ))}
        </div>
      </div>
    );
  }
}

const actions = { updateLetterFilter };
const mapStateToProps = state => {
  const { formData } = getListsOverviewState(state);

  return {
    firstLetter: formData.firstLetter
  };
};

export default connect(
  mapStateToProps,
  actions
)(LettersFilter);
