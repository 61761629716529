import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import TemplateForm from "./TemplateForm";
import { getTemplate, updateTemplate } from "./actions";
import { TemplateType } from "constants/settings/templates";

const EditTemplate = ({
  id,
  onSubmitted,
  onCancel,
  editing,
  columns,
  isInternal
}) => {
  return (
    <PermissionCheck target={Targets.ExportTemplates} action={Actions.Edit}>
      <ModalBuilder
        withForm
        loadAction={getTemplate}
        loadParams={id}
        loadTransform={data => {
          return {
            ...data,
            templateType: data.isContact
              ? TemplateType.Contact
              : TemplateType.Company,
            contactColumns: data.isContact === true ? data.columns : [],
            companyColumns: data.isContact === false ? data.columns : [],
            fileFormat: data.fileFormat.value
          };
        }}
        submitAction={updateTemplate}
        onSubmitted={onSubmitted}
        onCancel={onCancel}
        renderModal={(modalProps, formProps) => {
          return (
            <Modal.Edit
              {...modalProps}
              header={
                <div>
                  <Icon name="edit" />
                  Update Template
                </div>
              }
              submitLabel="Update Template"
              size="tiny"
            >
              <TemplateForm
                formProps={formProps}
                columns={columns}
                editing
                isInternal={isInternal}
              />
            </Modal.Edit>
          );
        }}
      />
    </PermissionCheck>
  );
};

export default EditTemplate;
