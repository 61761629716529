import React from "react";
import { Link } from "react-router-dom";
import { Icon, Image } from "semantic-ui-react";
import { CustomerRoutes } from "constants/routes";
import Roles from "assets/icons/roles.svg";
import Company from "assets/icons/building.svg";
import Job from "assets/icons/job.svg";

const UserContactsReadOnly = ({ data, onClick }) => {
  return (
    <React.Fragment>
      <div className="flex">
        <h2 className="m-r-1">Contact Details</h2>
        <Icon
          className="primary cursor-pointer"
          name="pencil"
          onClick={onClick}
        />
      </div>
      <section>
        <ul className="list-unstyled m-t-05">
          <li>
            <p>
              <Icon name="user" className="user-contacts" />
              {data.firstName} {data.lastName}
            </p>
          </li>
          <li>
            <Image src={Company} />
            {data.customerId ? (
              <Link to={CustomerRoutes.Overview(data.customerId)}>
                <p>{data.company || "-"}</p>
              </Link>
            ) : (
              <p>-</p>
            )}
          </li>
          <li>
            <Image src={Job} />
            <p>{data.jobTitle || "-"}</p>
          </li>
          <li>
            <p>
              <Icon
                name="call"
                flipped="horizontally"
                className="user-contacts"
              />
              {data.telephone || "-"}
            </p>
          </li>
          <li>
            <p>
              <Icon name="envelope" className="user-contacts" />
              {data.email || "-"}
            </p>
          </li>
          <li>
            <Image src={Roles} />
            <p>{data.roleDescription || "-"}</p>
          </li>
        </ul>
      </section>
    </React.Fragment>
  );
};

export default UserContactsReadOnly;
