import React from "react";
import { Route, Switch } from "react-router-dom";
import ReportPage from "./ReportPage";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes } from "constants/routes";
import PermissionErrorPage from "../PermissionErrorPage";

export default () => (
  <React.Fragment>
    <PermissionCheck target={Targets.Reports} action={Actions.View}>
      <Switch>
        <Route path={AppRoutes.Reports} render={() => <ReportPage />} />
      </Switch>
    </PermissionCheck>
    <PermissionCheck
      target={Targets.Reports}
      action={Actions.View}
      not
      component={PermissionErrorPage}
    />
  </React.Fragment>
);
