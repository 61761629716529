import * as fileSaver from "file-saver";
import { connect } from "react-redux";
import {
  downloadRawCriteriaReport,
  downloadCriteriaTotalsReport
} from "./actions";
import React from "react";
import { Form } from "@redriver/cinnamon";
import { Button, Grid, Segment } from "semantic-ui-react";

class ReportDashboard extends React.Component {
  state = {
    rawCriteriaDownloading: false,
    criteriaTotalsDownloading: false,
    formData: { dates: { start: null, end: null } }
  };

  onFormChange = (field, data, applyChanges) => {
    this.setState(state => ({
      formData: applyChanges(state.formData)
    }));
  };

  render() {
    const {
      downloadRawCriteriaReport,
      downloadCriteriaTotalsReport
    } = this.props;
    const {
      rawCriteriaDownloading,
      criteriaTotalsDownloading,
      formData
    } = this.state;
    return (
      <Grid className="i4b-list-page user-page">
        <Grid.Row className="p-t-2 m-b-1" columns={1}>
          <Grid.Column stretched>
            <Grid>
              <Grid.Row columns={2}>
                <Segment className="left-border-green m-y-1 list-card">
                  <Grid style={{ margin: "0" }}>
                    <Grid.Row
                      columns={1}
                      style={{ padding: "0", paddingBottom: "1em" }}
                    >
                      <Form value={formData} onChange={this.onFormChange}>
                        <Form.DateRange
                          instant
                          showYearDropdown
                          field="dates"
                          label="Date Range"
                        />
                      </Form>
                    </Grid.Row>
                    <Grid.Row
                      columns={1}
                      style={{ padding: "0", paddingBottom: "1em" }}
                    >
                      <p>
                        This report shows the raw searches of each customer
                        <br />
                        The download may take a few moments
                      </p>
                    </Grid.Row>
                    <Grid.Row
                      columns={1}
                      style={{ padding: "0", paddingBottom: "1em" }}
                    >
                      <Button
                        positive
                        disabled={!formData.dates.start && !formData.dates.end}
                        loading={rawCriteriaDownloading}
                        onClick={async () => {
                          this.setState({ rawCriteriaDownloading: true });
                          const data = await downloadRawCriteriaReport(
                            formData.dates
                          );

                          fileSaver.saveAs(
                            data.response,
                            "RawCriteriaReport.csv"
                          );
                          this.setState({ rawCriteriaDownloading: false });
                        }}
                        content="Download Raw Criteria Report"
                      />
                    </Grid.Row>
                    <Grid.Row
                      columns={1}
                      style={{ padding: "0", paddingBottom: "1em" }}
                    >
                      <p>
                        This report shows the total counts of each job
                        role/title that has been searched
                        <br />
                        The download may take a few moments
                      </p>
                    </Grid.Row>
                    <Grid.Row columns={1} style={{ padding: "0" }}>
                      <Button
                        positive
                        disabled={!formData.dates.start && !formData.dates.end}
                        loading={criteriaTotalsDownloading}
                        onClick={async () => {
                          this.setState({ criteriaTotalsDownloading: true });
                          const data = await downloadCriteriaTotalsReport(
                            formData.dates
                          );

                          fileSaver.saveAs(
                            data.response,
                            "CriteriaTotalsReport.csv"
                          );
                          this.setState({ criteriaTotalsDownloading: false });
                        }}
                        content="Download Criteria Totals Report"
                      />
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const actions = {
  downloadCriteriaTotalsReport,
  downloadRawCriteriaReport
};

export default connect(
  null,
  actions
)(ReportDashboard);
