import React from "react";
import { Button, Grid, Segment } from "semantic-ui-react";
import { ListBuilder } from "@redriver/cinnamon";

import { getLists } from "../actions";
import FilterCompanyLists from "./FilterCompanyLists";
import ListCard from "features/Lists/ListLists/ListCard";

const CompanyContacts = ({ companyId }) => {
  return (
    <ListBuilder
      loadAction={getLists}
      loadParams={companyId}
      dataTransform={data => data.results}
      totalTransform={data => data.totalResults}
      initialFilters={{
        sortBy: "0",
        allLists: "true"
      }}
      initialPagination={{
        pageSize: 12,
        pageNumber: 1
      }}
      renderList={(
        data,
        { filters, pagination, hasMore, loading, loadingMore },
        events
      ) => (
        <Grid
          className="i4b-list-page user-page"
          style={{ paddingTop: "0", width: "100%" }}
        >
          <Grid.Row style={{ paddingTop: "0" }} columns={1}>
            <Grid.Column className="m-b-1" stretched>
              <Segment className="left-border-green">
                <FilterCompanyLists
                  filters={filters}
                  onChangeFilters={events.onChangeFilters}
                  pageSize={pagination.pageSize}
                  onPageSizeChange={events.onChangePagination}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched columns={1}>
            <Grid.Column className="m-b-1" stretched>
              <Grid>
                <Grid.Row columns={3}>
                  {data.map((a, i) => (
                    <Grid.Column key={a.id}>
                      <ListCard {...a} />
                    </Grid.Column>
                  ))}
                  {!loading && !data.length && (
                    <p style={{ width: "100%", textAlign: "center" }}>
                      This company is not in any lists
                    </p>
                  )}
                </Grid.Row>
              </Grid>

              {hasMore && (
                <Button
                  className="i4b-notification-list-loader"
                  loading={loadingMore}
                  onClick={events.onLoadMore}
                >
                  {loadingMore ? "Loading more lists..." : "Show More"}
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    />
  );
};

export default CompanyContacts;
