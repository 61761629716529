import { Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Icon } from "semantic-ui-react";
import { deleteCustomField } from "./actions";

const DeleteField = ({
  as: As,
  customerId,
  fieldId,
  fieldName,
  fieldUsages,
  onDelete,
  ...props
}) => (
  <ModalBuilder
    submitAction={deleteCustomField}
    submitParams={{ customerId, fieldId }}
    onSubmitted={onDelete}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={modalProps => (
      <Modal.Delete
        {...modalProps}
        header={
          <div>
            <Icon name="warning sign" /> Delete Field
          </div>
        }
      >
        {fieldUsages > 0 && (
          <p>
            <strong>
              Warning: this field is associated with{" "}
              {fieldUsages === 1 ? "company" : "companies"}.
            </strong>
          </p>
        )}
        <p>Are you sure you wish to delete {fieldName}?</p>
      </Modal.Delete>
    )}
  />
);

export default DeleteField;
