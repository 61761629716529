import { Format } from "@redriver/cinnamon";
import Hash from "assets/icons/hash.svg";
import People from "assets/icons/people.svg";
import Revenue from "assets/icons/pounds_bag.svg";
import World from "assets/icons/world.svg";
import Calendar from "assets/icons/calendar.svg";
import { formatRevenue } from "features/Utils";
import React from "react";
import { Icon, Image, Popup } from "semantic-ui-react";
import { FallbackNumber, isEmptyNumber } from "./Shared";

const CompanySizeDetails = ({ data, isSuppressed }) => {
  return (
    <section>
      <div className="flex">
        <h2 className="m-r-1">Size</h2>
      </div>
      <ul className="list-unstyled m-t-05 overview-text">
        <li>
          <Popup
            trigger={<Image src={Revenue} />}
            content="Revenue"
            position="top center"
          />
          {isSuppressed ? (
            "Suppressed"
          ) : isEmptyNumber(data.revenue) ? (
            "-"
          ) : (
            <React.Fragment>
              {" "}
              {data.currencyCode} {formatRevenue(data.revenue)}
            </React.Fragment>
          )}
        </li>

        <li>
          <Popup
            trigger={<Image src={People} />}
            content="Employees"
            position="top center"
          />
          {isSuppressed ? (
            "Suppressed"
          ) : (
            <FallbackNumber value={data.noEmployees} />
          )}
        </li>

        <li className="space-seperate">
          <Popup
            trigger={<Image src={World} />}
            content="Locations"
            position="top center"
          />
          {isSuppressed ? (
            "Suppressed"
          ) : !isEmptyNumber(data.locations) && !!data.totalLocations ? (
            <React.Fragment>
              <span>{data.locations} </span>{" "}
              <span> ({data.totalLocations} Worldwide)</span>
            </React.Fragment>
          ) : !isEmptyNumber(data.locations) ? (
            <span>{data.locations} </span>
          ) : !!data.totalLocations ? (
            <span> ({data.totalLocations} Worldwide)</span>
          ) : (
            "-"
          )}
        </li>

        <li>
          <Popup
            trigger={<Image src={Hash} />}
            content="Reg No"
            position="top center"
          />
          {isSuppressed ? "Suppressed" : data.registrationNumber || "-"}
        </li>

        <li>
          <Popup
            trigger={<Image src={Calendar} />}
            content="Year end"
            position="top center"
          />
          {isSuppressed ? (
            "Suppressed"
          ) : data.yearEnd ? (
            <Format.Date value={data.yearEnd} format="DD/MM" />
          ) : (
            "-"
          )}
        </li>
      </ul>
    </section>
  );
};

export default CompanySizeDetails;
