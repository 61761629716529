import React, { Fragment } from "react";
import { Form } from "@redriver/cinnamon";
import { Grid, Image } from "semantic-ui-react";
import BulkMigrateCustomersModal from "./BulkMigrateCustomersModal";
import CreateCustomer from "../CreateCustomer";
import { CustomerSubTypeDropdown } from "features/Customers/lookups";
import moment from "moment";
import { Actions, Targets } from "constants/permissions";
import { PermissionCheck } from "components/auth";

const RenderClearer = ({ onClick }) => (
  <a className="m-l-1" onClick={onClick}>
    (Clear)
  </a>
);

const isFilterEmpty = formData => {
  return Object.values(formData).every(
    x => x === null || x == undefined || x === "" || x.length == []
  );
};

const FilterCustomersWindow = ({
  formData,
  selection,
  totalSelected,
  filters,
  onCreatedCustomer
}) => {
  return (
    <Fragment>
      <ul className="i4b-actions-list">
        <CreateCustomer onSubmitted={onCreatedCustomer} />

        <PermissionCheck target={Targets.Customers} action={Actions.Migrate}>
          <BulkMigrateCustomersModal
            selection={selection}
            totalSelected={totalSelected}
            filters={filters}
          />
        </PermissionCheck>
      </ul>
      <div className="flex align-center m-b-1">
        <h1 className="m-b-0">Filters</h1>
        {!isFilterEmpty(formData) && <Form.Clearer as={RenderClearer} />}
      </div>
      <Grid className="i4b-filter-grid">
        <Grid.Row columns={1}>
          <Form.Group inline className="i4b-inline-dropdown m-b-1">
            <CustomerSubTypeDropdown
              fluid
              field="subscriptionType"
              label="Subscription Type"
              placeholder="Select subscription type…"
            />
            <Form.Clearer field="subscriptionType" />
          </Form.Group>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Form.CheckboxGroup
              field="hardEnd"
              label="Hard End"
              options={[
                { value: "set", text: "Set" },
                { value: "notSet", text: "Not set" }
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.CheckboxGroup
              field="accessDenied"
              label="Access Denied"
              options={[
                { value: "accessAllowed", text: "Has access" },
                { value: "accessDenied", text: "Access denied" }
              ]}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Form.Group grouped label="Contract Start Date">
              <div className="i4b-inline-datepicker">
                <Form.Date
                  fluid
                  local
                  field="contractStartDateStart"
                  placeholder="Start date from"
                />
                <Form.Date
                  fluid
                  local
                  field="contractStartDateEnd"
                  placeholder="Start date to"
                  minDate={
                    formData.contractStartDateStart
                      ? moment(formData.contractStartDateStart)
                      : null
                  }
                />
              </div>
            </Form.Group>
          </Grid.Column>
          <Grid.Column>
            <Form.Group grouped label="Initial End Date">
              <div className="i4b-inline-datepicker">
                <Form.Date
                  fluid
                  local
                  field="initialEndDateStart"
                  placeholder="End date from"
                />
                <Form.Date
                  fluid
                  local
                  field="initialEndDateEnd"
                  placeholder="End date to"
                  minDate={
                    formData.initialEndDateStart
                      ? moment(formData.initialEndDateStart)
                      : null
                  }
                />
              </div>
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Form.Group grouped label="Last Day Date">
              <div className="i4b-inline-datepicker">
                <Form.Date
                  fluid
                  local
                  field="lastDayDateStart"
                  placeholder="Last date from"
                />
                <Form.Date
                  fluid
                  local
                  field="lastDayDateEnd"
                  placeholder="Last date to"
                  minDate={
                    formData.lastDayDateStart
                      ? moment(formData.lastDayDateStart)
                      : null
                  }
                />
              </div>
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};
export default FilterCustomersWindow;
