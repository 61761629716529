import { apiGet, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "OPT_OUT_REPORT";

export const ActionTypes = {
  GetOptOutReports: `${NAMESPACE}/GET_OPT_OUT_REPORTS`,
  BulkDownloadOptOutReports: `${NAMESPACE}/BULK_DOWNLOAD_OPT_OUT_REPORTS`
};

export const getOptOutReports = ({ filters, pagination, selection }) => {
  const params = { ...filters, ...pagination };
  return apiGet(
    ActionTypes.GetOptOutReports,
    "users/self/opt-out-report",
    params
  );
};

export const bulkDownload = ({ filters, selection }) => {
  const body = { ...filters, selection };
  return apiPost(
    ActionTypes.BulkDownloadOptOutReports,
    "users/self/advanced-opt-out-reports/download",
    body
  );
};
