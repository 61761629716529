import {
  apiGet,
  Breadcrumb,
  Form,
  Lookup,
  mockSuccess,
  registerLookup
} from "@redriver/cinnamon";
import { SearchCriteria } from "constants/search";
import React from "react";

const NAMESPACE = "COMPANIES/LOOKUPS";

const ActionTypes = {
  GetSearchCriteria: `${NAMESPACE}/GET_SEARCH_CRITERIA`,
  GetCustomFieldNames: `${NAMESPACE}/GET_CUSTOM_FIELD_NAMES`,
  GetCustomFieldValues: `${NAMESPACE}/GET_CUSTOM_FIELD_VALUES`,
  ViewCompany: `${NAMESPACE}/VIEW_COMPANY`,
  GetCompanyStructure: `${NAMESPACE}/VIEW_COMPANY`,
  GetUserCustomer: `${NAMESPACE}/GET_CUSTOMER_USER`
};

const companiesSearchCriteria = SearchCriteria.filter(el =>
  el.types.includes("companies")
);

registerLookup(
  "companiesSearchCriteriaLookup",
  () => mockSuccess(ActionTypes.GetSearchCriteria),
  {
    transform: data => {
      return {
        geography: companiesSearchCriteria.filter(
          el => el.category == "geography"
        ),
        company: companiesSearchCriteria.filter(el => el.category == "company"),
        lists: companiesSearchCriteria.filter(el => el.category == "lists"),
        uploads: companiesSearchCriteria.filter(el => el.category == "uploads")
      };
    }
  }
);

registerLookup(
  "customFieldNames",
  () => apiGet(ActionTypes.GetCustomFieldNames, `users/custom-field-names`),
  {
    transform: data => data,
    cache: false
  }
);

export const CustomFieldNamesDropdown = ({
  required,
  value,
  className = "",
  allowAdditions = false
}) => {
  return (
    <Form.Dropdown
      label="Company Custom Field"
      required={required}
      placeholder="Company Custom field"
      fluid
      field="name"
      search
      lookup="customFieldNames"
      className={className}
      allowAdditions={allowAdditions}
      unknownValueOptions={value ? [{ value: value, text: value }] : undefined}
    />
  );
};

registerLookup(
  "customFieldValues",
  ({ customFieldId }) => {
    return apiGet(
      ActionTypes.GetCustomFieldValues,
      `users/custom-field-values`,
      { customFieldId }
    );
  },
  {
    cache: false
  }
);

export const CustomFieldValuesDropdown = ({
  customFieldId,
  value,
  disabled,
  multiple,
  required,
  allowAdditions = true
}) => {
  return (
    <Form.Dropdown
      disabled={disabled || !customFieldId}
      label="Custom Field Value"
      required={required}
      placeholder="Custom field value"
      fluid
      field="value"
      search
      lookup="customFieldValues"
      lookupParams={{ customFieldId }}
      allowAdditions={allowAdditions}
      unknownValueOptions={value ? [{ value: value, text: value }] : undefined}
      multiple={multiple}
    />
  );
};

registerLookup("companyLookup", id =>
  apiGet(ActionTypes.ViewCompany, `companies/${id}`)
);

export const CompanyLookup = ({ companyId, ...props }) => (
  <Lookup lookup="companyLookup" lookupParams={companyId} {...props} />
);

export const CompanyBreadcrumbItem = ({ companyId }) => (
  <CompanyLookup
    companyId={companyId}
    render={({ response }) =>
      response && <Breadcrumb.Item content={response.companyName} />
    }
  />
);

registerLookup("companyStructureLookup", companyId =>
  apiGet(ActionTypes.GetCompanyStructure, `companies/${companyId}/structure`)
);

registerLookup(
  "userCustomerLookup",
  () => apiGet(ActionTypes.GetUserCustomer, `users/user-customer`),
  {
    cache: false
  }
);

export const UserCustomerLookup = ({ ...props }) => (
  <Lookup lookup="userCustomerLookup" {...props} />
);
