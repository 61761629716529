import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";
import TreeViewCriteria from "./TreeViewCriteria";

/**
 * @deprecated No longer rendered; SE criteria not calculated any more. See id:78933
 */
const StockExchangeCriteria = ({ active, handleClick, criteria }) => {
  const { selectedIndexes } = criteria;
  if (!selectedIndexes.length) return null;

  return (
    <React.Fragment>
      {/* <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Stock Exchange Indexes"
        text={`${selectedIndexes.length} selected indexes`}
      />
      <Accordion.Content active={active}>
        <TreeViewCriteria
          criteria={criteria}
          lookup="stockExchangeIndexesCriteriaLookup"
        />
      </Accordion.Content> */}
    </React.Fragment>
  );
};

export default StockExchangeCriteria;
