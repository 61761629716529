import Database from "assets/icons/database.svg";
import Monitor from "assets/icons/desktop-monitor.svg";
import People from "assets/icons/people.svg";
import Platform from "assets/icons/platform.svg";
import React from "react";
import { Image, Popup } from "semantic-ui-react";
import { FallbackNumber } from "./Shared";

const CompanyTechDetails = ({ data, isSuppressed }) => {
  const platforms = [];
  data.ibmPcmMainframe &&
    platforms.push(<span key="ibm">IBM / PCM Mainframe</span>);
  data.unix && platforms.push(<span key="unix">Unix</span>);
  data.windows && platforms.push(<span key="windows">Windows</span>);
  data.other && platforms.push(<span key="other">Other</span>);
  data.systemI && platforms.push(<span key="systemI">System I</span>);
  data.vax && platforms.push(<span key="Vax">VAX</span>);

  return (
    <section>
      <div className="flex">
        <h2 className="m-r-1">Technology</h2>
      </div>
      <ul className="list-unstyled m-t-05 overview-text">
        <li>
          <Popup
            trigger={<Image src={People} alt="IT Staff" />}
            content="IT Staff"
            position="top center"
          />
          {isSuppressed ? (
            "Suppressed"
          ) : (
            <FallbackNumber value={data.itStaff} />
          )}
        </li>

        <li>
          <Popup
            trigger={<Image src={Monitor} alt="PCs" />}
            content="PCs"
            position="top center"
          />
          {isSuppressed ? "Suppressed" : <FallbackNumber value={data.pcs} />}
        </li>

        <li>
          <Popup
            trigger={<Image src={Database} alt="Servers" />}
            content="Servers"
            position="top center"
          />
          {isSuppressed ? (
            "Suppressed"
          ) : (
            <FallbackNumber value={data.servers} />
          )}
        </li>

        <li className="comma-seperate">
          <Popup
            trigger={<Image src={Platform} alt="Platforms" />}
            content="Platforms"
            position="top center"
          />
          <div style={{ display: "flex" }}>
            {isSuppressed
              ? "Suppressed"
              : platforms.length == 0
              ? "-"
              : platforms}
          </div>
        </li>
      </ul>
    </section>
  );
};

export default CompanyTechDetails;
