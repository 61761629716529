import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes, ListRoutes } from "constants/routes";
import React from "react";
import { Route, Switch } from "react-router-dom";
import CompaniesSearchPage from "../companies/CompaniesSearchPage";
import ContactsSearchPage from "../contacts/ContactsSearchPage";
import PermissionErrorPage from "../PermissionErrorPage";
import ListListsPage from "./ListListsPage";
import ListOverviewPage from "./ListOverviewPage";

export default () => (
  <React.Fragment>
    <PermissionCheck target={Targets.List} action={Actions.View}>
      <Switch>
        <Route exact path={AppRoutes.Lists} component={ListListsPage} />

        <Route
          path={ListRoutes.UpdateContact()}
          component={ContactsSearchPage}
        />
        <Route
          path={ListRoutes.UpdateCompany()}
          component={CompaniesSearchPage}
        />

        <Route path={ListRoutes.List()} component={ListOverviewPage} />
      </Switch>
    </PermissionCheck>
    <PermissionCheck
      target={Targets.List}
      action={Actions.View}
      not
      component={PermissionErrorPage}
    />
  </React.Fragment>
);
