import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";

import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import TemplateForm from "./TemplateForm";
import { createTemplate } from "./actions";

const CreateTemplate = ({ onSubmitted, columns, initialData, isInternal }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={createTemplate}
      onSubmitted={onSubmitted}
      initialData={initialData}
      renderTrigger={showModal => (
        <PermissionCheck
          target={Targets.ExportTemplates}
          action={Actions.Create}
        >
          <Button
            primary
            content="Add Template"
            onClick={showModal}
            className="add-template"
          />
        </PermissionCheck>
      )}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Add
            {...modalProps}
            header={
              <div>
                <Icon name="plus" /> Add Template
              </div>
            }
            submitLabel="Create Template"
            size="tiny"
          >
            <TemplateForm
              formProps={formProps}
              columns={columns}
              isInternal={isInternal}
            />
          </Modal.Add>
        );
      }}
    />
  );
};

export default CreateTemplate;
