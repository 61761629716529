import React from "react";
import { ActionBar, Form, FormBuilder, PageSize } from "@redriver/cinnamon";

import CreateCustomField from "./CreateCustomField";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";

const FilterCustomFields = ({
  filters,
  onChangeFilters,
  pageSize,
  onPageSizeChange,
  onCreateCustomField,
  customerId
}) => (
  <ActionBar>
    <ActionBar.Item fluid>
      <FormBuilder
        initialData={filters}
        onChange={onChangeFilters}
        renderForm={formProps => (
          <Form {...formProps}>
            <Form.Input
              field="search"
              placeholder="Search company custom fields..."
            />
          </Form>
        )}
      />
    </ActionBar.Item>
    <ActionBar.Item align="right">
      <PermissionCheck target={Targets.ItemTags} action={Actions.Manage}>
        <CreateCustomField
          customerId={customerId}
          onSubmitted={onCreateCustomField}
        />
      </PermissionCheck>
    </ActionBar.Item>
    <ActionBar.Item className="i4b-filter-pagesize">
      <PageSize
        value={pageSize}
        onChange={x => onPageSizeChange({ pageSize: x, pageNumber: 1 })}
        options={[10, 25, 50, 100]}
      />
    </ActionBar.Item>
  </ActionBar>
);

export default FilterCustomFields;
