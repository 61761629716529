import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "LISTS";

export const ActionTypes = {
  ViewUserCountries: `${NAMESPACE}/COUNTRIES_FORM/GET_USERS_COUNTRIES`,
  ViewCustomerCountries: `${NAMESPACE}/COUNTRIES_FORM/GET_CUSTOMER_COUNTRIES`
};

export const getUserCountries = id =>
  apiGet(
    ActionTypes.ViewUserCountries,
    `users/${id}/countries`,
    {},
    { preventErrorNotification: true }
  );
