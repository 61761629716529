import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Popup } from "semantic-ui-react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import EditUploadNameModal from "./EditUploadNameModal";
import DeleteUploadModal from "./DeleteUploadModal";
import { loadUploadModal, renameUpload, deleteUpload } from "./actions";
import { Actions, Targets } from "constants/permissions";

const UploadsActionDropdown = ({ uploadId, uploadName, onRefresh }) => {
  const [deleteUploadModalVisible, setDeleteUploadModalVisible] = useState(
    false
  );

  const [renameUploadModalVisible, setRenameUploadModalVisible] = useState(
    false
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <React.Fragment>
      <div
        className="list-actions"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <div className="action-button-container">
          <Popup
            className="list-summary-actions-popup"
            basic
            open={dropdownOpen}
            position="bottom right"
            verticalOffset={-5}
            trigger={
              <Button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                style={{ verticalAlign: "middle" }}
                className={`actions-dropdown-button-small ${
                  dropdownOpen ? `close` : `some-selected`
                }`}
              />
            }
            content={
              <div className="list-summary-actions">
                <div
                  onClick={() =>
                    setRenameUploadModalVisible(!renameUploadModalVisible)
                  }
                >
                  Change Upload name
                </div>
                <div
                  onClick={() =>
                    setDeleteUploadModalVisible(!deleteUploadModalVisible)
                  }
                >
                  Delete Upload
                </div>
              </div>
            }
          />
        </div>
      </div>
      {renameUploadModalVisible && (
        <EditUploadNameModal
          uploadName={uploadName}
          submitParams={{ uploadId: uploadId }}
          submitAction={renameUpload}
          onSubmitted={() => {
            onRefresh();
            setRenameUploadModalVisible(false);
          }}
          toggleVisibility={() =>
            setRenameUploadModalVisible(!renameUploadModalVisible)
          }
        />
      )}
      {deleteUploadModalVisible && (
        <DeleteUploadModal
          uploadName={uploadName}
          submitAction={deleteUpload}
          submitParams={{ uploadId: uploadId }}
          onDelete={() => {
            setDeleteUploadModalVisible(false);
            onRefresh();
          }}
          toggleVisibility={() =>
            setDeleteUploadModalVisible(!deleteUploadModalVisible)
          }
          name={name}
        />
      )}
    </React.Fragment>
  );
};

export default UploadsActionDropdown;
