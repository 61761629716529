import { Table } from "@redriver/cinnamon";
import { PermissionCheck, UserTypeCheck } from "components/auth";
import { Actions, Targets, UserTypes } from "constants/permissions";
import React from "react";
import ActionsColumn from "./ActionsColumn";

const ContactSuppressionsTable = ({
  tableProps,
  state,
  events,
  customerId,
  onSubmitted
}) => (
  <Table {...tableProps} dataKey="id" basic="very">
    <Table.Column title="Name" field="fullName" />
    <Table.Column title="Company Name" field="companyName" />
    <Table.Column title="Job Title" field="jobTitle" />
    <UserTypeCheck restrictTo={UserTypes.Internal}>
      <PermissionCheck target={Targets.Items} action={Actions.Unsuppress}>
        <Table.Column
          name="Actions"
          render={item => (
            <ActionsColumn
              item={item}
              customerId={customerId}
              onSubmitted={onSubmitted}
              isContact={true}
            />
          )}
          width="15%"
        />
      </PermissionCheck>
    </UserTypeCheck>
  </Table>
);

export default ContactSuppressionsTable;
