import { ActionBar, ListBuilder, TriggerBuilder } from "@redriver/cinnamon";
import { groupBy, map } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Button, Grid } from "semantic-ui-react";
import { getNotifications } from "./actions";
import FilterNotifications from "./FilterNotifications";
import NotificationsDaySection from "./NotificationsDaySection";
import NotificationSettingsModal from "./NotificationSettingsModal";

class ListNotifications extends Component {
  generateNotificationsList = results => {
    let dayGroups = groupBy(results, item =>
      moment
        .utc(item.createdUtc)
        .local()
        .startOf("day")
        .format()
    );
    dayGroups = map(dayGroups, (data, date) => {
      return {
        date,
        data
      };
    });

    return dayGroups;
  };

  render() {
    return (
      <ListBuilder
        loadAction={getNotifications}
        initialFilters={{
          search: "",
          showActioned: "false"
        }}
        totalTransform={data => data.totalResults}
        dataTransform={data => data.results}
        renderList={(
          data,
          state,
          { onChangeFilters, onLoadMore, onRefresh }
        ) => {
          const items = this.generateNotificationsList(data);
          return (
            <div className="notifications-container">
              <ActionBar>
                <ActionBar.Item fluid>
                  <FilterNotifications
                    filters={state.filters}
                    onChange={onChangeFilters}
                  />
                </ActionBar.Item>
                <ActionBar.Item fluid className="notification-settings">
                  <NotificationSettingsModal />
                </ActionBar.Item>
              </ActionBar>
              <Grid divided="vertically">
                {items &&
                  items.length > 0 &&
                  items.map((el, i) => (
                    <NotificationsDaySection
                      key={`${el.date}-${i}`}
                      date={el.date}
                      items={el.data}
                      onRefresh={onRefresh}
                    />
                  ))}
              </Grid>
              {!state.loading && items.length === 0 && (
                <p style={{ textAlign: "center", marginTop: "3em" }}>
                  No notifications found
                </p>
              )}
              {state.hasMore && (
                <Button
                  className="i4b-notification-list-loader"
                  disabled={!state.hasMore}
                  loading={state.loadingMore}
                  onClick={onLoadMore}
                >
                  {state.loadingMore
                    ? "Loading more notifications..."
                    : "Show More"}
                </Button>
              )}
            </div>
          );
        }}
      />
    );
  }
}

export default ListNotifications;
