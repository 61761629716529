import React from "react";
import { Route, Switch } from "react-router-dom";

import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes } from "constants/routes";
import PermissionErrorPage from "../PermissionErrorPage";
import ListRolesPage from "./ListRolesPage";

export default () => (
  <React.Fragment>
    <PermissionCheck target={Targets.Role} action={Actions.View}>
      <Switch>
        <Route exact path={AppRoutes.Roles} component={ListRolesPage} />
      </Switch>
    </PermissionCheck>
    <PermissionCheck
      target={Targets.Role}
      action={Actions.View}
      not
      component={PermissionErrorPage}
    />
  </React.Fragment>
);
