import PropTypes from "prop-types";
import React from "react";
import CsvContext from "./CsvContext";
import CsvGrid from "./CsvGrid";
import CsvTools from "./CsvTools";

class Csv extends React.Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    onRowsUpdated: PropTypes.func.isRequired,
    onCellSelected: PropTypes.func.isRequired,
    selectedCell: PropTypes.object,
    onCellAction: PropTypes.func.isRequired,
    onHeaderActions: PropTypes.func.isRequired
  };

  onRowEdit = (rows, applyUpdates) => {
    this.props.onRowsUpdated(rows, applyUpdates);
  };

  onCellSelected = cell => {
    this.props.onCellSelected(cell);
  };

  onCellAction = action => {
    this.props.onCellAction(action);
  };

  onHeaderActions = (actions, callBack, onError) => {
    this.props.onHeaderActions(actions, callBack, onError);
  };

  render() {
    const {
      columns,
      rows,
      selectedCell,
      errors,
      processing,
      metaData,
      dataLoading
    } = this.props;

    const contextValue = {
      columns,
      rows,
      onRowEdit: this.onRowEdit,
      onCellSelected: this.onCellSelected,
      selectedCell,
      onCellAction: this.onCellAction,
      errors,
      processing,
      onHeaderActions: this.onHeaderActions,
      metaData,
      dataLoading
    };
    return (
      <CsvContext.Provider value={contextValue}>
        {this.props.children}
      </CsvContext.Provider>
    );
  }
}

Csv.Grid = CsvGrid;
Csv.Tools = CsvTools;

export default Csv;
