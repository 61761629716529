import { requestIsBusy, requestResponse } from "@redriver/cinnamon";
import { ActionTypes } from "./actions";

const initialState = {
  recentlyViewedLoading: false,
  recentlyViewed: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GetRecentlyViewed:
      return {
        ...state,
        recentlyViewed: requestResponse(action) || state.recentlyViewed,
        recentlyViewedLoading: requestIsBusy(action)
      };
    case ActionTypes.ClearRecentlyViewed:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
