import React from "react";
import { connect } from "react-redux";
import { withRouter, matchPath } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { Grid, Image } from "semantic-ui-react";
import { Breadcrumb, PageLoader } from "@redriver/cinnamon";

import { AppRoutes, SettingsRoutes } from "constants/routes";
import { UserTypes } from "constants/permissions";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { UserTypeCheck } from "components/auth";
import SettingsMenu from "features/Settings/SettingsMenu";
import { ListTemplates } from "features/Settings/Templates";
import PermissionErrorPage from "../PermissionErrorPage";
import Home from "assets/icons/home_breadcrumb.svg";
import {
  ListCustomFields,
  ListCustomFieldValues,
  ViewCustomerContacts,
  ViewCustomerTags
} from "features/Customers";
import { getCustomerId } from "features/Contacts/ContactsSearch/actions";
import { getContactsSearchState } from "features/Contacts/ContactsSearch/selectors";

class SettingsRoute extends React.Component {
  componentDidMount() {
    this.props.getCustomerId();
  }

  render() {
    const { customerId, customerIdLoading: loading, location } = this.props;

    const currentPath = location.pathname;

    let breadcrumbText = "Templates";

    switch (currentPath) {
      case SettingsRoutes.CompanyCustomFields: {
        breadcrumbText = "Company Custom Fields";
        break;
      }
      case SettingsRoutes.CompanyCustomValues: {
        breadcrumbText = "Company Custom Values";
        break;
      }
      case SettingsRoutes.ContactCustomFields: {
        breadcrumbText = "Contact Custom Fields";
        break;
      }
      case SettingsRoutes.Templates: {
        breadcrumbText = "Templates";
        break;
      }
    }

    return loading ? (
      <PageLoader />
    ) : (
      <React.Fragment>
        <PermissionCheck target={Targets.Settings} action={Actions.View}>
          <Breadcrumb className="i4b-breadcrumb-container">
            <Breadcrumb.Item
              link={AppRoutes.Root}
              icon={<Image src={Home} title="Home" />}
            />
            <Breadcrumb.Item content={breadcrumbText} />
          </Breadcrumb>
          <Grid stackable className="i4b-list-page templates-page">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={2}>
                <SettingsMenu />
              </Grid.Column>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={14}
                style={{ paddingTop: "0.8em" }}
              >
                <Switch>
                  <Route
                    path={AppRoutes.Settings}
                    exact
                    render={() => <Redirect to={SettingsRoutes.Templates} />}
                  />
                  <Route
                    path={SettingsRoutes.CompanyCustomFields}
                    render={props => (
                      <ListCustomFields customerId={customerId} />
                    )}
                  />
                  <Route
                    path={SettingsRoutes.CompanyCustomValues}
                    render={props => (
                      <ListCustomFieldValues customerId={customerId} />
                    )}
                  />
                  <Route
                    path={SettingsRoutes.ContactCustomFields}
                    render={props => (
                      <ViewCustomerTags customerId={customerId} />
                    )}
                  />
                  <Route
                    path={SettingsRoutes.ExportTemplates}
                    component={ListTemplates}
                  />
                </Switch>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PermissionCheck>
        <PermissionCheck
          target={Targets.Settings}
          action={Actions.View}
          not
          component={PermissionErrorPage}
        />
      </React.Fragment>
    );
  }
}

const actions = {
  getCustomerId
};

const mapStateToProps = state => {
  const { customerId, customerIdLoading } = getContactsSearchState(state);
  return {
    customerId,
    customerIdLoading
  };
};

export default connect(
  mapStateToProps,
  actions
)(SettingsRoute);
