import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import { clearRecentlyViewed } from "./actions";
import ListCard from "./ListCard";
import { getListState } from "./selectors";

class RecentlyViewed extends Component {
  render() {
    const { recentlyViewed } = this.props;
    return (
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          className="m-b-1"
          stretched
        >
          <h2>Recently Viewed</h2>
          <Grid>
            <Grid.Row columns={3}>
              {recentlyViewed &&
                recentlyViewed.map(a => (
                  <Grid.Column key={a.id}>
                    <ListCard {...a} />
                  </Grid.Column>
                ))}
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    );
  }

  componentWillUnmount() {
    this.props.clearRecentlyViewed();
  }
}

const actions = {
  clearRecentlyViewed
};

const mapStateToProps = state => {
  const { recentlyViewed, recentlyViewedLoading } = getListState(state);
  return {
    recentlyViewed,
    recentlyViewedLoading
  };
};

export default connect(
  mapStateToProps,
  actions
)(RecentlyViewed);
