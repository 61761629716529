import { addNotification, Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";

const SaveListModal = ({
  selection,
  submitAction,
  isCompaniesPage,
  addNotification,
  forceDumbList,
  searchCriteria,
  companyId
}) => {
  const isSmart =
    selection.allSelected && !selection.deselected.length && !forceDumbList;
  return (
    <PermissionCheck target={Targets.List} action={Actions.Create}>
      <ModalBuilder
        withForm
        submitAction={submitAction}
        submitParams={{ selection, searchCriteria, companyId }}
        onSubmitted={listId => {
          addNotification({
            type: "ListCreatedNotification",
            message: "Your list has been created! Click here to view.",
            customData: { listId }
          });
        }}
        renderTrigger={showModal => (
          <li onClick={showModal}>
            <div className="dropdown-action-container">
              <a>
                Create new
                {isSmart ? " smart " : " "}
                list
              </a>
            </div>
          </li>
        )}
        renderModal={(modalProps, formProps) => (
          <Modal.Add
            {...modalProps}
            header={
              <div>
                <Icon name="plus" /> Add{" "}
                {isCompaniesPage ? "Company" : "Contact"}
                {isSmart ? " Smart " : " "} List
              </div>
            }
            submitLabel={`Create ${isSmart ? "Smart" : ""} List`}
            size="tiny"
          >
            <Form {...formProps}>
              <Form.Input
                field="name"
                label="Name"
                placeholder="Name"
                required
                fluid
              />
              <Form.TextArea
                field="notes"
                label="Notes"
                placeholder="Notes"
                fluid
              />
            </Form>
          </Modal.Add>
        )}
      />
    </PermissionCheck>
  );
};

export default connect(
  null,
  {
    addNotification
  }
)(SaveListModal);
