import { Form, PageLoader } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import { LOBRolesLookup } from "../lookups";
import FilterBase from "./FilterBase";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";
import FilterClearer from "./FilterClearer";

class LOBRolesFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    results: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    clearSelected: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired
  };
  state = {
    visible: true,
    selectedLobRoles: [],
    total: null
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  componentDidMount = () => {
    const { id, formData } = this.props;
    this.getSelected(formData, id);
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  componentDidUpdate = prevProps => {
    const { id, formData, loading, results, pageChanged } = this.props;
    if (prevProps.formData !== formData) {
      this.getSelected(formData, id);
    }
  };

  getSelected = (formData, id) => {
    const { searchCriteria } = formData;

    const LOBRolesObj = searchCriteria.find(el => el.id == id);
    const { selectedLobRoles } = LOBRolesObj;

    this.setState({
      selectedLobRoles:
        !!selectedLobRoles && selectedLobRoles.length > 0
          ? selectedLobRoles.join(", ")
          : null
    });
  };

  render() {
    const { visible, selectedLobRoles, total } = this.state;
    const {
      id,
      loading,
      pageChanged,
      removeFilter,
      clearSelected
    } = this.props;

    return (
      <LOBRolesLookup
        render={({ response }, refresh) => (
          <FilterBase
            total={total}
            loading={loading && !pageChanged}
            className="lob-roles-filter"
          >
            {response ? (
              <React.Fragment>
                <FilterBase.Header
                  title="LOB Roles"
                  collapsible
                  collapsed={!visible}
                  onCollapseToggle={this.toggleVisibility}
                  removable
                  onRemove={() => removeFilter(id)}
                  showClearer={selectedLobRoles}
                  onClear={clearSelected}
                >
                  {!visible && (
                    <div className="ellipsis-paragraph">
                      {selectedLobRoles || "No roles selected"}
                    </div>
                  )}
                </FilterBase.Header>
                <FilterBase.Body collapsed={!visible}>
                  <div className="lob-roles-container">
                    <Form.CheckboxTree
                      key={id}
                      className="lob-roles-checkbox-tree"
                      field="selectedLobRoles"
                      nodes={[
                        {
                          value: `${response.value}`,
                          text: `${response.text}`,
                          defaultExpanded: true,
                          children: response.children || []
                        }
                      ]}
                    />
                  </div>
                </FilterBase.Body>
              </React.Fragment>
            ) : (
              <PageLoader
                active
                inline="centered"
                size="small"
                className="p-y-05"
              />
            )}
          </FilterBase>
        )}
      />
    );
  }
}

export default LOBRolesFilter;
