import React from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";

const FilterNotifications = ({ onChange, filters }) => (
  <FormBuilder
    initialData={filters}
    onChange={onChange}
    renderForm={formProps => (
      <Form {...formProps}>
        <Form.Group inline compact>
          <Form.Input field="search" placeholder="Type To Search" width={25} />
          <Form.Group inline compact>
            <Form.RadioGroup
              field="showActioned"
              inline
              options={[
                { key: "all", value: "", text: "Show All" },
                { value: "false", text: "Show Open" },
                { value: "true", text: "Show Closed" }
              ]}
            />
          </Form.Group>
        </Form.Group>
      </Form>
    )}
  />
);

export default FilterNotifications;
