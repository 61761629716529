import React from "react";
import { Route, Switch } from "react-router-dom";
import { Grid, Image } from "semantic-ui-react";
import { Breadcrumb } from "@redriver/cinnamon";

import { AppRoutes, UserRoutes } from "constants/routes";
import { UserBreadcrumbItem, UserPreLoader } from "features/Users";
import {
  UserOverview,
  UserWhitelist,
  UserMenu,
  UserCountries
} from "features/Users";

import Home from "assets/icons/home_breadcrumb.svg";

const UserRoute = ({ match }) => (
  <UserPreLoader userId={match.params.id}>
    <Breadcrumb className="i4b-breadcrumb-container">
      <Breadcrumb.Item
        link={AppRoutes.Root}
        icon={<Image src={Home} title="Home" />}
      />
      <Breadcrumb.Item link={AppRoutes.Users} content="Users" />
      <UserBreadcrumbItem userId={match.params.id} />
    </Breadcrumb>

    <Grid className="user-page p-t-1">
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={2}>
          <UserMenu userId={match.params.id} />
        </Grid.Column>
        <Switch>
          <Route
            exact
            path={UserRoutes.Overview()}
            render={props => <UserOverview userId={match.params.id} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={UserRoutes.Whitelist()}
            render={props => <UserWhitelist userId={match.params.id} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={UserRoutes.Countries()}
            render={props => <UserCountries userId={match.params.id} />}
          />
        </Switch>
      </Grid.Row>
    </Grid>
  </UserPreLoader>
);

export default UserRoute;
