import React from "react";
import { Table } from "@redriver/cinnamon";

const UserStatsTable = props => {
  const { customerId, tableProps } = props;
  return (
    <Table {...tableProps} dataKey="userId" basic="very">
      <Table.Column field="userFullName" title="Name" />
      <Table.Column field="loggedIn" title="Logged In" />
      <Table.Column field="contactsViewed" title="Contacts Viewed" />
      <Table.Column field="companiesViewed" title="Companies Viewed" />
      <Table.Column field="filesDownloaded" title="Files Downloaded" />
      <Table.Column field="contactsDownloaded" title="Contacts Downloaded" />
      <Table.Column field="companiesDownloaded" title="Companies Downloaded" />
      <Table.Column field="criteriaSearched" title="Criteria Searched" />
      <Table.Column field="listChanged" title="List Changed" />
    </Table>
  );
};

export default UserStatsTable;
