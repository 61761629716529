import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/LIST_CUSTOMER_USER_STATS";

export const ActionTypes = {
  GetUserStats: `${NAMESPACE}/GET_USER_STATS`,
  GetUserStatsEarliestDate: `${NAMESPACE}/GET_USER_STATS_EARLIEST_DATE`,
  GetUserStatsDownload: `${NAMESPACE}/GET_USER_STATS_DOWNLOAD`
};

export const getUserStats = (
  { filters, pagination },
  { customerId, isOwnCustomer }
) => {
  const f = {
    search: filters.search,
    userActiveFilter: filters.userActiveFilter
  };

  if (filters.dateFilters) {
    f.filterDateFrom = filters.dateFilters.start;
    f.filterDateTo = filters.dateFilters.end;
  }

  return apiGet(
    ActionTypes.GetUserStats,
    isOwnCustomer
      ? `customers/user-stats`
      : `customers/${customerId}/user-stats`,
    { ...f, ...pagination }
  );
};

export const GetUserStatsEarliestDate = ({ customerId, isOwnCustomer }) =>
  apiGet(
    ActionTypes.GetUserStatsEarliestDate,
    isOwnCustomer
      ? `customers/user-stats/earliest-date`
      : `customers/${customerId}/user-stats/earliest-date`
  );

export const GetUserStatsDownload = ({ customerId, isOwnCustomer }, params) => {
  const f = {
    search: params.search,
    userActiveFilter: params.userActiveFilter
  };

  if (params.dateFilters) {
    f.filterDateFrom = params.dateFilters.start;
    f.filterDateTo = params.dateFilters.end;
  }

  return apiGet(
    ActionTypes.GetUserStatsDownload,
    isOwnCustomer
      ? `customers/user-stats/download`
      : `customers/${customerId}/user-stats/download`,
    f
  );
};
