import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import { Icon } from "semantic-ui-react";

const AddToSegmentsModal = ({
  loadAction,
  submitAction,
  loadParams,
  submitParams,
  onSubmitted
}) => {
  return (
    <PermissionCheck target={Targets.CustomerSegments} action={Actions.Edit}>
      <ModalBuilder
        loadAction={loadAction}
        loadParams={loadParams}
        submitAction={submitAction}
        submitParams={submitParams}
        onSubmitted={onSubmitted}
        renderTrigger={showModal => (
          <div onClick={showModal} className="dropdown-action-container">
            <a>Add to Custom Segment</a>
          </div>
        )}
        renderModal={(modalProps, data, state) => (
          <Modal.Confirmation
            {...modalProps}
            header={
              <div>
                <Icon name="plus" /> Add companies to custom segment
              </div>
            }
            submitLabel="Add to Custom Segment"
            size="tiny"
            submitDisabled={data.exceedsLimit || !data.totalUpdates}
          >
            <div style={{ minHeight: "4em" }}>
              {data && data.exceedsLimit === false && data.totalUpdates >= 1 && (
                <p>
                  This will result in the addition of {data.totalUpdates}{" "}
                  {data.totalUpdates === 1 ? "company" : "companies"} to your
                  custom segment. Are you sure you want to proceed?
                </p>
              )}
              {data && data.totalUpdates === 0 && (
                <p>
                  No companies would be added. This is because the companies are
                  already in your custom segment and/or custom segment access is
                  not enabled for the related countries.
                </p>
              )}
              {data && data.exceedsLimit && (
                <p>
                  This would result in the addition of {data.totalUpdates}{" "}
                  {data.totalUpdates === 1 ? "company" : "companies"} to your
                  custom segment. This is not allowed as it would exceed your
                  limit, or because you don't have enough segment credits.
                </p>
              )}
            </div>
          </Modal.Confirmation>
        )}
      />
    </PermissionCheck>
  );
};

export default AddToSegmentsModal;
