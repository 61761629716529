import React from "react";
import { PageHeader, FormBuilder, Form } from "@redriver/cinnamon";
import { SystemPageContent } from "components/pages";
import UserReset from "features/System/UserReset";

const UserResetPage = ({ match, location }) => (
  <SystemPageContent>
    <PageHeader>Re-verify Email Address</PageHeader>
    <UserReset
      verifyToken={match.params.verifyToken}
      pwToken={match.params.pwToken}
    />
  </SystemPageContent>
);

export default UserResetPage;
