import {
  requestError,
  requestIsBusy,
  requestResponse
} from "@redriver/cinnamon";
import { HeaderCell } from "components/csv";
import React from "react";
import { ActionTypes, setSelectedCell } from "./actions";

const initialState = {
  dataLoading: false,
  columns: [],
  rows: [],
  analysisLoading: false,
  errors: [],
  selectedCell: {},
  processLoading: false,
  processError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GetUploadData:
      const newDataState = { ...state, dataLoading: requestIsBusy(action) };
      const dataResponse = requestResponse(action);
      if (dataResponse) {
        const cols = dataResponse.columns.map(c => {
          return {
            ...c,
            headerRenderer: props => <HeaderCell {...props} />,
            onCellSelected: props => setSelectedCell(props),
            resizable: true
          };
        });
        newDataState.columns = cols || [];
        newDataState.rows = dataResponse.rows || [];
      }
      return newDataState;
    case ActionTypes.UpdateData:
      return {
        ...state,
        rows: action.applyChanges(state.rows)
      };
    case ActionTypes.PersistFileMetadata:
      return {
        ...state,
        metaData: action.metaData
      };
    case ActionTypes.SetSelectedCell:
      return {
        ...state,
        selectedCell: action.cellData
      };
    case ActionTypes.GetAnalysis:
      const newAnalysisState = {
        ...state,
        analysisLoading: requestIsBusy(action)
      };
      const analysisResponse = requestResponse(action);
      if (analysisResponse) {
        const errors = [];
        const existingColumns = state.columns.map((c, i) => {
          const header = analysisResponse.headerAnalysis[i];
          if (header && header.data && !header.data.ok) {
            errors.push({
              rowIdx: -1,
              idx: header.colIdx,
              data: { ...header.data, key: c.key, idx: header.colIdx }
            });
          }
          return {
            ...c,
            ...header.data,
            editable: () => !header.data.ignored
          };
        });
        newAnalysisState.columns = existingColumns;

        const existingRows = state.rows.slice();
        const updatedRows = [];

        existingRows.forEach(row => {
          if (row.rowIdx in analysisResponse.cellAnalysis) {
            let analysis = {};
            analysisResponse.cellAnalysis[row.rowIdx].forEach(analysisRow => {
              analysis = {
                ...analysis,
                [analysisRow.colIdx]: { ...analysisRow.data }
              };

              errors.push({
                rowIdx: row.rowIdx,
                idx: analysisRow.colIdx,
                error: analysisRow.data.error
              });
            });
            updatedRows.push({ ...row, analysis });
          } else {
            updatedRows.push({ ...row, analysis: {} });
          }
        });
        newAnalysisState.errors = errors;
        newAnalysisState.rows = updatedRows;
      }

      return newAnalysisState;
    case ActionTypes.Reset:
      return {
        ...initialState
      };
    case ActionTypes.ProcessUpload:
      return {
        ...state,
        processLoading: requestIsBusy(action),
        processError: requestError(action)
      };
    default:
      return state;
  }
};
