import React from "react";
import { ClaimCheck } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import { UserTypes } from "constants/permissions";

const UserTypeCheck = ({ restrictTo, component, children }) => {
  const Component = component;
  return (
    <ClaimCheck claim="userType" value={restrictTo}>
      {Component ? <Component /> : children}
    </ClaimCheck>
  );
};

UserTypeCheck.propTypes = {
  restrictTo: PropTypes.oneOf(Object.values(UserTypes)),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
};

UserTypeCheck.defaultProps = {
  restrictTo: UserTypes.Internal
};

export default UserTypeCheck;
