import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck, UserTypeCheck } from "components/auth";
import { Actions, Targets, UserTypes } from "constants/permissions";
import React from "react";
import { Icon } from "semantic-ui-react";

const DeleteTagModal = ({
  contactOriginalId,
  tagId,
  submitAction,
  onSubmitted
}) => {
  return (
    <ModalBuilder
      submitAction={submitAction}
      submitParams={{ contactId: contactOriginalId, tagId }}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <UserTypeCheck restrictTo={UserTypes.External}>
          <PermissionCheck target={Targets.ItemTags} action={Actions.Edit}>
            {" "}
            <Icon name="delete" onClick={showModal} />
          </PermissionCheck>
        </UserTypeCheck>
      )}
      renderModal={(modalProps, data) => (
        <Modal.Delete
          {...modalProps}
          header={
            <div>
              <Icon name="trash" /> Remove Contact Custom Field
            </div>
          }
          submitLabel="Remove Field"
          size="tiny"
        >
          <p>
            Are you sure you want to remove the custom field from this contact?
          </p>
        </Modal.Delete>
      )}
    />
  );
};

export default DeleteTagModal;
