import React from "react";
import { Route, Switch } from "react-router-dom";

import { AppRoutes } from "constants/routes";
import KnowledgeBasePage from "./KnowledgeBasePage";

export default () => (
  <React.Fragment>
    <Switch>
      <Route
        exact
        path={AppRoutes.KnowledgeBase}
        component={KnowledgeBasePage}
      />
    </Switch>
  </React.Fragment>
);
