import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  authenticate,
  getAuthOptions,
  getAuthState,
  lookupReset
} from "@redriver/cinnamon";

const NAMESPACE = "USERS/LIST_USERS";

export const ActionTypes = {
  GetUsers: `${NAMESPACE}/GET_USERS`,
  CreateUser: `${NAMESPACE}/CREATE_USER`,
  EditStatus: `${NAMESPACE}/EDIT_USER_STATUS`,
  DeleteUser: `${NAMESPACE}/DELETE_USER`,
  ResendVerification: `${NAMESPACE}/RESEND_VERIFICATION`,
  SwitchUser: `${NAMESPACE}/SWITCH_USER`
};

export const getUsers = ({ filters, pagination, selection }) => {
  const params = { ...filters, ...pagination };
  return apiGet(ActionTypes.GetUsers, "users", params);
};

export const createUser = (user, params) =>
  apiPost(ActionTypes.CreateUser, `users`, {
    isInternal: params.userType === "Internal",
    customerId: params.customerId,
    ...user
  });

const enableUser = id =>
  apiPut(ActionTypes.EditStatus, `users/${id}/enable`, {});

const disableUser = id =>
  apiPut(ActionTypes.EditStatus, `users/${id}/disable`, {});

export const editStatus = ({ userIsDisabled, id }) => {
  if (userIsDisabled) {
    return enableUser(id);
  }
  return disableUser(id);
};

export const deleteUser = id =>
  apiDelete(ActionTypes.DeleteUser, `users/${id}`);

export const resendVerification = id =>
  apiPut(ActionTypes.ResendVerification, `users/${id}/resend-verification`);

export const switchUser = userId => async (dispatch, getState) => {
  const { refreshToken } = getAuthState(getState());
  const switchAction = apiPost(
    ActionTypes.SwitchUser,
    `auth/impersonation/${userId}`,
    {
      refreshToken
    }
  );
  const result = await dispatch(switchAction);

  if (result.success) {
    const { tokens } = result.response;
    const { tokenKey } = getAuthOptions();
    const persist = localStorage.getItem(`${tokenKey}-access`) != null;

    dispatch(lookupReset());
    dispatch(authenticate(tokens.access, tokens.refresh, persist));
  }

  return result;
};
