import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import { Icon } from "semantic-ui-react";

const AddToListModal = ({ submitParams, submitAction, isCompaniesPage }) => {
  return (
    <PermissionCheck target={Targets.List} action={Actions.Create}>
      <ModalBuilder
        withForm
        submitAction={submitAction}
        submitParams={submitParams}
        renderTrigger={showModal => (
          <li onClick={showModal}>
            <div className="dropdown-action-container">
              <a>Add to existing list</a>
            </div>
          </li>
        )}
        renderModal={(modalProps, formProps) => (
          <Modal.Add
            {...modalProps}
            header={
              <div>
                <Icon name="plus" /> Add to{" "}
                {isCompaniesPage ? "Company" : "Contact"} List
              </div>
            }
            submitLabel="Add to List"
            size="tiny"
          >
            <Form {...formProps}>
              <Form.TypeAhead
                field="listId"
                label="Search & select an existing list to add to"
                placeholder="Type to search existing lists"
                required
                fluid
                lookup={
                  isCompaniesPage ? "companyListLookup" : "contactListLookup"
                }
              />
            </Form>
          </Modal.Add>
        )}
      />
    </PermissionCheck>
  );
};

export default AddToListModal;
