import { ErrorMessage } from "@redriver/cinnamon";
import { AppRoutes } from "constants/routes";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Loader } from "semantic-ui-react";
import { downloadResultsFile } from "../actions";

class ProcessStep extends Component {
  state = {
    processSuccess: false
  };

  componentDidMount() {
    this.props.processUpload(this.props.uploadId).then(result => {
      this.setState({
        processSuccess: result.success
      });
    });
  }

  render() {
    const { processLoading, processError } = this.props;
    const { processSuccess } = this.state;

    return (
      <div style={{ display: "flex" }}>
        {processLoading && (
          <React.Fragment>
            <Loader
              active={processLoading}
              inline
              style={{ marginRight: "1rem" }}
            />
            <p style={{ alignSelf: "center" }}>
              <strong>Please wait while the upload is submitted.</strong>
            </p>
          </React.Fragment>
        )}
        {!processLoading && processSuccess && (
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex" }}>
              <p style={{ paddingRight: "1rem", paddingBottom: "1rem" }}>
                The upload will be processed in the background.
                <br />
                If your match has not been completed yet, please check in the
                notifications area for an update.
                <br />
                It will then be viewable from the Uploads page.
              </p>
            </div>

            <Button
              content="Return to Uploads"
              icon="arrow right"
              onClick={() => this.props.history.push(AppRoutes.Uploads)}
              style={{ color: "white", backgroundColor: "#9d74b4" }}
              className="pull-right"
            />
          </div>
        )}
        {!processLoading && !processSuccess && (
          <div>
            <strong>There was an issue with the upload</strong>
            <p>
              Please resolve the issue in your upload file and then return to
              the Uploads page to try again
            </p>
            <ErrorMessage
              header="There was a problem"
              error={processError}
              omitCodes
            />
            <Button
              content="Return to Uploads"
              icon="arrow right"
              onClick={() => this.props.history.push(AppRoutes.Uploads)}
              style={{ color: "white", backgroundColor: "#9d74b4" }}
              className="pull-right"
            />
          </div>
        )}
      </div>
    );
  }
}

const actions = {
  downloadResultsFile
};

export default withRouter(
  connect(
    null,
    actions
  )(ProcessStep)
);
