import { apiGet, apiPut, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "USERS/VIEW_USERS";

export const ActionTypes = {
  UpdateUserIpWhitelist: `${NAMESPACE}/UPDATE_USER_WHITELIST`,
  UploadProfilePicture: `${NAMESPACE}/UPLOAD_PROFILE_PICTURE`,
  GetUserContactDetails: `${NAMESPACE}/GET_CONTACT_DETAILS`,
  UpdateUserContactDetails: `${NAMESPACE}/UPDATE_CONTACT_DETAILS`,
  GetUserDetails: `${NAMESPACE}/GET_DETAILS`,
  UpdateUserDetails: `${NAMESPACE}/UPDATE_DETAILS`,
  ForceUserReset: `${NAMESPACE}/RESET_USER`
};

export const updateUserIpWhitelist = (ips, userId) =>
  apiPut(
    ActionTypes.UpdateUserIpWhitelist,
    `users/${userId}/ip-whitelist`,
    ips,
    {
      preventErrorNotification: true
    }
  );

export const getUserContactDetails = userId =>
  apiGet(ActionTypes.GetUserContactDetails, `users/${userId}`);

export const updateUserContactDetails = (formData, userId) =>
  apiPut(
    ActionTypes.UpdateUserContactDetails,
    `users/${userId}/contact-details`,
    formData
  );

export const updateUserDetails = (formData, userId) =>
  apiPut(
    ActionTypes.UpdateUserContactDetails,
    `users/${userId}/details`,
    formData
  );

export const forceUserReset = userId =>
  apiPost(ActionTypes.ForceUserReset, `users/${userId}/reset`);
