import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import { ActionBar, Form, FormBuilder } from "@redriver/cinnamon";
import { UserTypeCheck } from "components/auth";
import { UserTypes } from "constants/permissions";

const FilterUploads = ({
  filters,
  onChangeFilters,
  pageSize,
  onPageSizeChange
}) => (
  <Segment className="left-border-green">
    <FormBuilder
      initialData={filters}
      onChange={onChangeFilters}
      renderForm={formProps => (
        <Form {...formProps}>
          <h1 className="m-t-05">Uploads</h1>
          <ActionBar>
            <ActionBar.Item>
              <Form.Input
                label="Search Uploads"
                field="search"
                placeholder="Search Uploads…"
              />
            </ActionBar.Item>

            <ActionBar.Space width={5} />

            <ActionBar.Item>
              <Form.CheckboxGroup
                field="searchTypes"
                label={
                  <label className="inline-input-group-label-top m-b-075">
                    Upload Type
                  </label>
                }
                inline
                options={[
                  { value: "contacts", text: "Contacts" },
                  { value: "companies", text: "Companies" }
                ]}
              />
            </ActionBar.Item>

            <ActionBar.Space width={5} />

            <ActionBar.Item>
              <Form.CheckboxGroup
                field="actionTypes"
                label={
                  <label className="inline-input-group-label-top m-b-075">
                    Action Type
                  </label>
                }
                inline
                options={[
                  { value: "bulkUpdateTags", text: "Add Custom Fields" },
                  { value: "searchCriteria", text: "Match" }
                ]}
              />
            </ActionBar.Item>

            <ActionBar.Space width={5} />

            <UserTypeCheck restrictTo={UserTypes.External}>
              <ActionBar.Item fluid>
                <Form.RadioGroup
                  field="showAllUploads"
                  label={
                    <label className="inline-input-group-label-top m-b-075">
                      Viewable Uploads
                    </label>
                  }
                  inline
                  options={[
                    { value: "false", text: "My Uploads" },
                    { value: "true", text: "All Uploads" }
                  ]}
                />
              </ActionBar.Item>
            </UserTypeCheck>
          </ActionBar>
        </Form>
      )}
    />
  </Segment>
);

export default FilterUploads;
