const guidParse = guid => {
  if (!guid) return null;
  return guid.toLowerCase().replace(/-/g, "");
};

const guidComparison = (guid1, guid2) => {
  return guidParse(guid1) === guidParse(guid2);
};

export default guidComparison;
