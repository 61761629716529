import { apiPost, authenticate, getAuthClaims } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/LOGIN";
export const REQUEST_LOGIN = `${NAMESPACE}/REQUEST`;
export const REFRESH_JWT = `${NAMESPACE}/REFRESH_JWT`;

export const requestLogin = (
  username,
  password,
  remember
) => async dispatch => {
  // make the login api call
  const loginAction = apiPost(
    REQUEST_LOGIN,
    "auth/login",
    { key: username, secret: password },
    { auth: false, preventErrorNotification: true }
  );
  const { success, response } = await dispatch(loginAction);

  // authenticate the user session
  if (!success) return;
  const { access, refresh } = response.tokens;
  dispatch(authenticate(access, refresh, true));
};

export const refreshJwt = token => (dispatch, getState) => {
  const { userId, impersonatorId } = getAuthClaims(getState());
  const impersonatingUserId = impersonatorId ? userId : null;
  const refreshAction = apiPost(
    REFRESH_JWT,
    "auth/token/refresh",
    { token, impersonatingUserId },
    { auth: false, preventErrorNotification: true }
  );
  return dispatch(refreshAction);
};
