import {
  ErrorMessage,
  Form,
  FormBuilder,
  Modal,
  ModalBuilder
} from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Button, Icon } from "semantic-ui-react";

class IPForm extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    submitAction: PropTypes.func.isRequired,
    onSubmitted: PropTypes.func.isRequired
  };

  state = {
    errors: {},
    formData: {
      ipAddresses: this.props.data.ipAddresses.map(i => {
        return { key: i.id, ip: i.ip, id: i.id };
      })
    },
    ipsChanged: false
  };

  isFormValid = () => {
    const { errors } = this.state;
    return Object.keys(errors).every(key => errors[key].length === 0);
  };

  onChange = formData => {
    this.setState({ formData, ipsChanged: true });
  };

  checkDupeIps = data => {
    let { formData } = this.state;
    return formData.ipAddresses.some(i => i.ip === data);
  };

  updateErrorState = (key, error) => {
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [key]: error
      }
    }));
  };

  onFormSubmitted = () => {
    const { onSubmitted } = this.props;
    this.setState({ ipsChanged: false });
    onSubmitted();
  };

  render() {
    const { submitAction, onSubmitted, data } = this.props;
    const { errors, formData, ipsChanged } = this.state;
    return (
      <FormBuilder
        value={formData}
        submitAction={submitAction}
        submitParams={data.id}
        onSubmitted={this.onFormSubmitted}
        renderError={false}
        onChange={this.onChange}
        renderForm={(formProps, state, events) => (
          <Form {...formProps} className="ip-form">
            <Fragment>
              <p>
                To whitelist an IP address, add it below then click Save
                Changes. To edit an IP address, select it, make your changes and
                then click Save Changes.
              </p>
              <div className="ip-label-container">
                <Form.Array
                  field="ipAddresses"
                  propagateUpdates="always"
                  arrayKey="key"
                >
                  {({ fields }) => {
                    let error = [];
                    let showErrors = false;
                    if (fields.key in errors) {
                      error = errors[fields.key];
                      showErrors = error.length > 0;
                    }
                    return (
                      <div className="ip-label">
                        <Form.Input
                          field="ip"
                          placeholder="Enter IP address"
                          fluid
                          required
                          customErrors={error}
                          showErrors={showErrors}
                          customValidator={(field, value) => {
                            if (!value) {
                              this.updateErrorState(fields.key, [
                                "IP is required"
                              ]);
                              return;
                            }
                            if (
                              !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                                value
                              )
                            ) {
                              this.updateErrorState(fields.key, [
                                "Incorrect format"
                              ]);
                              return;
                            }
                            if (ipsChanged && this.checkDupeIps(value)) {
                              this.updateErrorState(fields.key, [
                                "Duplicate IP"
                              ]);
                              return;
                            }
                            this.updateErrorState(fields.key, []);
                          }}
                          action={
                            <Form.ArrayRemover
                              as={({ onClick }) => (
                                <ModalBuilder
                                  onSubmit={onClick}
                                  renderTrigger={showModal => (
                                    <a onClick={showModal} className="delete">
                                      <Icon name="delete" aria-label="Delete" />
                                    </a>
                                  )}
                                  renderModal={modalProps => (
                                    <Modal.Delete
                                      {...modalProps}
                                      header={<h1>Delete IP Address</h1>}
                                      className="delete-ip-modal"
                                    >
                                      Are you sure you want to delete this IP
                                      address from the whitelist?
                                    </Modal.Delete>
                                  )}
                                />
                              )}
                              onRemoved={() => {
                                this.setState({ ipsChanged: false });
                              }}
                            />
                          }
                        />
                      </div>
                    );
                  }}
                </Form.Array>
                <Form.ArrayAdder
                  field="ipAddresses"
                  className="i4b-ip-add"
                  populate={arrayKey => ({
                    [arrayKey]: Date.now(),
                    ip: ""
                  })}
                >
                  Add IP address
                  <Icon name="plus" style={{ float: "right" }} />
                </Form.ArrayAdder>
              </div>
            </Fragment>
            <Button
              primary
              onClick={events.onSubmit}
              type="button"
              floated="right"
              disabled={!state.dirty || !this.isFormValid()}
              style={{ visibility: state.dirty ? "visible" : "hidden" }}
              loading={state.submitting}
            >
              Save Changes
            </Button>
            <ErrorMessage error={state.error} />
          </Form>
        )}
      />
    );
  }
}

export default IPForm;
