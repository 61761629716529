import React from "react";
import { Grid, Icon, Segment } from "semantic-ui-react";
import { ListBuilder, Table } from "@redriver/cinnamon";

import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { getRoles } from "./actions";
import ActionsColumn from "./ActionsColumn";
import FilterRoles from "./FilterRoles";

const ListRoles = () => (
  <ListBuilder
    withTable
    loadAction={getRoles}
    dataTransform={data => data.results}
    totalTransform={data => data.totalResults}
    renderList={(tableProps, state, events) => (
      <Grid
        className="i4b-list-page user-page"
        style={{ paddingTop: "0.8rem" }}
      >
        <Grid.Column computer={16} style={{ paddingTop: "0.8rem" }}>
          <div className="i4b-list">
            <h1>Roles</h1>
            <FilterRoles
              filters={state.filters}
              onChangeFilters={events.onChangeFilters}
              onAddRole={events.onRefresh}
            />
            <Segment>
              <Table {...tableProps} dataKey="id" basic="very">
                <Table.Column
                  render={() => (
                    <Icon
                      className="color-green"
                      name="square full"
                      size="small"
                    />
                  )}
                  width="2.5%"
                />
                <Table.Column field="name" title="Name" width="30%" />
                <Table.Column field="description" title="Description" />
                <PermissionCheck
                  target={Targets.Role}
                  action={[Actions.Edit, Actions.Delete]}
                  renderFailure={() => null}
                  any
                >
                  <Table.Column
                    width="50px"
                    render={item => (
                      <ActionsColumn item={item} onChange={events.onRefresh} />
                    )}
                  />
                </PermissionCheck>
              </Table>
            </Segment>
          </div>
        </Grid.Column>
      </Grid>
    )}
  />
);

export default ListRoles;
