import React from "react";
import { ActionBar, Form, FormBuilder, PageSize } from "@redriver/cinnamon";
import { CustomersTypeahead } from "./lookups";

const FilterSecureFiles = ({
  filters,
  onChangeFilters,
  pageSize,
  onPageSizeChange,
  isInternalUser
}) => (
  <ActionBar>
    <ActionBar.Item fluid className="secureFilesFilter">
      <FormBuilder
        initialData={filters}
        onChange={onChangeFilters}
        renderForm={formProps => (
          <Form {...formProps}>
            <Form.Group inline>
              <Form.Input
                field="search"
                placeholder="Search files..."
                width={20}
              />
              {isInternalUser && (
                <React.Fragment>
                  <CustomersTypeahead
                    field="customerId"
                    placeholder="Select customer..."
                  />
                  <Form.Clearer field="customerId" />
                </React.Fragment>
              )}
            </Form.Group>
          </Form>
        )}
      />
    </ActionBar.Item>

    <ActionBar.Item
      align="right"
      className="flex align-center i4b-filter-pagesize"
    >
      <PageSize
        value={pageSize}
        onChange={x => onPageSizeChange({ pageSize: x, pageNumber: 1 })}
        options={[10, 25, 50, 100]}
      />
    </ActionBar.Item>
  </ActionBar>
);

export default FilterSecureFiles;
