import { Lookup, PageLoader, TreeView } from "@redriver/cinnamon";
import React from "react";
import { Icon } from "semantic-ui-react";

const renderNode = (node, state, events) => {
  if (node.included == null || node.included == undefined) {
    return node.text;
  }

  return (
    <React.Fragment>
      <Icon name={node.included ? "check" : "close"} />
      {node.text}
    </React.Fragment>
  );
};

const TreeViewCriteria = ({ criteria, lookup, ...props }) => (
  <Lookup
    lookup={lookup}
    lookupParams={criteria}
    render={({ response }, refresh) => (
      <React.Fragment>
        {response ? (
          <TreeView nodes={response.children} renderNode={renderNode} />
        ) : (
          <PageLoader
            active
            inline="centered"
            size="small"
            className="p-y-05"
          />
        )}
      </React.Fragment>
    )}
    {...props}
  />
);

export default TreeViewCriteria;
