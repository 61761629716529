const isCompanySizeEmpty = details => {
  return (
    Object.keys(details).filter(
      prop =>
        prop !== "match" &&
        prop !== "revenueCurrency" &&
        details[prop] !== null &&
        details[prop] !== ""
    ).length == 0
  );
};

export default isCompanySizeEmpty;
