import { PageLoader, ContentBuilder } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import { Grid, Segment, Button } from "semantic-ui-react";
import { CompanyLookup, UserCustomerLookup } from "../lookups";
import CompanyGeneralDetails from "./CompanyGeneralDetails";
import CompanySizeDetails from "./CompanySizeDetails";
import CompanyTechDetails from "./CompanyTechDetails";
import CompanyLocationDetails from "./CompanyLocationDetails";
import RequestResearchModal from "features/Companies/CompaniesSearch/RequestResearchModal";
import CompanySuppressionConfirmationModal from "./CompanySuppressionConfirmationModal";
import { UserTypeCheck } from "components/auth";
import { UserTypes } from "constants/permissions";
const CompanyOverview = ({ companyId }) => (
  <CompanyLookup
    companyId={companyId}
    render={({ response: company }, companyRefresh) => {
      return (
        <UserCustomerLookup
          render={({ response: customer }, refresh) => {
            if (!company || customer === undefined) return <PageLoader />;
            return (
              <Grid style={{ margin: "0" }}>
                <Grid.Row columns={3} className="p-t-0">
                  <Grid.Column
                    mobile={14}
                    tablet={14}
                    computer={8}
                    className="m-b-1"
                    stretched
                  >
                    <Segment className="left-border-green">
                      <CompanyGeneralDetails
                        data={company}
                        companyId={companyId}
                        isSuppressed={company.isSuppressed}
                        isInSegment={company.isInSegment}
                        customerHasCustomSegment={
                          customer && customer.hasCustomSegment
                        }
                        onUpdated={() => {
                          refresh();
                          companyRefresh();
                        }}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column
                    mobile={14}
                    tablet={14}
                    computer={4}
                    className="m-b-1"
                    stretched
                  >
                    <Segment className="left-border-green">
                      <CompanySizeDetails
                        data={company}
                        companyId={companyId}
                        isSuppressed={company.isSuppressed}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column
                    mobile={14}
                    tablet={14}
                    computer={4}
                    className="m-b-1"
                    stretched
                  >
                    <Segment className="left-border-green">
                      <CompanyTechDetails
                        data={company}
                        companyId={companyId}
                        isSuppressed={company.isSuppressed}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3} className="p-t-0">
                  <Grid.Column
                    mobile={14}
                    tablet={14}
                    computer={8}
                    className="m-b-1"
                    stretched
                  >
                    <Segment className="left-border-green">
                      <CompanyLocationDetails
                        data={company}
                        companyId={companyId}
                        isSuppressed={company.isSuppressed}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column
                    mobile={14}
                    tablet={14}
                    computer={4}
                    className="m-b-1"
                    stretched
                  >
                    <UserTypeCheck restrictTo={UserTypes.External}>
                      <Segment className="left-border-green">
                        <section>
                          <div>
                            <h2 className="m-r-1">Research</h2>
                            {company.isSuppressed ? (
                              <p>This company is suppressed</p>
                            ) : (
                              <p>
                                Looking for more information about this company?
                                You can submit a request to our research team,
                                and they will do their best to find this
                                information for you.
                              </p>
                            )}
                            <RequestResearchModal
                              companyId={company.id}
                              companyOriginalId={company.companyId}
                              viewingSearch={false}
                              disabled={company.isSuppressed}
                            />
                          </div>
                        </section>
                      </Segment>
                    </UserTypeCheck>
                  </Grid.Column>
                  <Grid.Column
                    mobile={14}
                    tablet={14}
                    computer={4}
                    className="m-b-1"
                    stretched
                  >
                    <UserTypeCheck restrictTo={UserTypes.External}>
                      <Segment className="left-border-green">
                        <section>
                          <div>
                            <h2 className="m-r-1">Suppression</h2>
                            {company.isSuppressed ? (
                              <p>
                                This company is suppressed, to un-suppress it
                                please contact an admin.
                              </p>
                            ) : (
                              <p>
                                To stop this company appearing in your searches,
                                you can suppress this company below.
                              </p>
                            )}
                            <CompanySuppressionConfirmationModal
                              disabled={company.isSuppressed}
                              companyId={company.companyId}
                              onSubmitted={() => {
                                refresh();
                                companyRefresh();
                              }}
                            />
                          </div>
                        </section>
                      </Segment>
                    </UserTypeCheck>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            );
          }}
        />
      );
    }}
  />
);

CompanyOverview.propTypes = {
  companyId: PropTypes.string.isRequired
};

export default CompanyOverview;
