export const getLoginState = state => state.system.login;
export const getOAuthLoginState = state => state.system.oauthLogin;
export const getLogoutState = state => state.system.logout;
export const getPasswordChangeState = state => state.system.passwordChange;
export const getPasswordForgottenState = state =>
  state.system.passwordForgotten;
export const getPasswordResetState = state => state.system.passwordReset;
export const getEmailVerificationState = state =>
  state.system.emailVerification;
export const getUserSetupState = state => state.system.userSetup;
export const getReverifyState = state => state.system.reverifyEmail;
export const getEmailReVerifiedState = state => state.system.emailReverified;
