import { Table } from "@redriver/cinnamon";
import React from "react";
import DeleteCustomFieldValue from "./DeleteCustomFieldValue";
import EditCustomFieldValue from "./EditCustomFieldValue";

const ActionsColumn = ({ item, onChange }) => {
  return (
    <Table.Menu
      direction="left"
      icon="ellipsis horizontal"
      className="actions-column"
    >
      <EditCustomFieldValue
        renderTrigger={showModal => (
          <Table.MenuItem content="Edit" onClick={showModal} />
        )}
        onSubmitted={onChange}
        item={item}
      />
      <DeleteCustomFieldValue
        renderTrigger={showModal => (
          <Table.MenuItem content="Delete" onClick={showModal} />
        )}
        onSubmitted={onChange}
        isInUse={item.usageCount > 0}
        id={item.id}
      />
    </Table.Menu>
  );
};

export default ActionsColumn;
