import { Form, PageLoader } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import { TechRolesLookup } from "../lookups";
import FilterBase from "./FilterBase";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";
import FilterClearer from "./FilterClearer";

class TechRolesFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    results: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    clearSelected: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired
  };
  state = {
    visible: true,
    selectedTechRoles: [],
    total: null
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  componentDidMount = () => {
    const { id, formData } = this.props;
    this.getSelected(formData, id);
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  componentDidUpdate = prevProps => {
    const { id, formData } = this.props;
    if (prevProps.formData !== formData) {
      this.getSelected(formData, id);
    }
  };

  getSelected = (formData, id) => {
    const { searchCriteria } = formData;

    const techRolesObj = searchCriteria.find(el => el.id == id);
    const { selectedTechRoles } = techRolesObj;

    this.setState({
      selectedTechRoles:
        selectedTechRoles.length > 0 ? selectedTechRoles.join(", ") : null
    });
  };

  render() {
    const { visible, selectedTechRoles, total } = this.state;
    const {
      id,
      loading,
      pageChanged,
      removeFilter,
      clearSelected
    } = this.props;

    return (
      <TechRolesLookup
        render={({ response }, refresh) => (
          <FilterBase
            total={total}
            loading={loading && !pageChanged}
            className="tech-roles-filter"
          >
            {response ? (
              <React.Fragment>
                <FilterBase.Header
                  title="Technology Roles"
                  collapsible
                  collapsed={!visible}
                  onCollapseToggle={this.toggleVisibility}
                  removable
                  onRemove={() => removeFilter(id)}
                  showClearer={selectedTechRoles}
                  onClear={clearSelected}
                >
                  {!visible && (
                    <div className="ellipsis-paragraph">
                      {selectedTechRoles || "No roles selected"}
                    </div>
                  )}
                </FilterBase.Header>
                <FilterBase.Body collapsed={!visible}>
                  <div className="tech-roles-container">
                    <Form.CheckboxTree
                      key={id}
                      className="tech-roles-checkbox-tree"
                      field="selectedTechRoles"
                      nodes={[
                        {
                          value: `${response.value}`,
                          text: `${response.text}`,
                          defaultExpanded: true,
                          children: response.children || []
                        }
                      ]}
                    />
                  </div>
                </FilterBase.Body>
              </React.Fragment>
            ) : (
              <PageLoader
                active
                inline="centered"
                size="small"
                className="p-y-05"
              />
            )}
          </FilterBase>
        )}
      />
    );
  }
}

export default TechRolesFilter;
