import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";
import SelectionCollapseText from "./SelectionCollapseText";
import TreeViewCriteria from "./TreeViewCriteria";

const IndustriesCriteria = ({ active, handleClick, criteria }) => {
  const { included, excluded } = criteria.industriesSelection;

  if (!included.length && !excluded.length) return null;

  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Industries"
        text={<SelectionCollapseText included={included} excluded={excluded} />}
      />
      <Accordion.Content active={active}>
        <TreeViewCriteria
          criteria={criteria}
          lookup="industriesCriteriaLookup"
        />
      </Accordion.Content>
    </React.Fragment>
  );
};

export default IndustriesCriteria;
