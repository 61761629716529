import { apiDelete, apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "USERS/LIST_CUSTOM_FIELDS";

export const ActionTypes = {
  GetCustomFields: `${NAMESPACE}/GET_CUSTOM_FIELDS`,
  CreateCustomField: `${NAMESPACE}/CREATE_CUSTOM_FIELD`,
  UpdateCustomField: `${NAMESPACE}/UPDATE_CUSTOM_FIELD`,
  DeleteCustomField: `${NAMESPACE}/DELETE_CUSTOM_FIELD`
};

export const getCustomFields = ({ filters, pagination }, customerId) =>
  apiGet(ActionTypes.GetCustomFields, `customers/${customerId}/custom-fields`, {
    ...pagination,
    ...filters
  });

export const updateCustomField = (data, { customerId, fieldId }) =>
  apiPut(
    ActionTypes.UpdateCustomField,
    `customers/${customerId}/custom-fields/${fieldId}`,
    data
  );

export const deleteCustomField = ({ customerId, fieldId }) =>
  apiDelete(
    ActionTypes.DeleteCustomField,
    `customers/${customerId}/custom-fields/${fieldId}`
  );

export const createCustomField = (customField, customerId) =>
  apiPost(
    ActionTypes.CreateCustomField,
    `customers/${customerId}/custom-fields`,
    customField
  );
