import { Format, Table } from "@redriver/cinnamon";
import StarEmpty from "assets/icons/star_empty.svg";
import StarSelected from "assets/icons/star_selected.svg";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { CompanyRoutes } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import {
  Checkbox,
  Icon,
  Popup,
  Image,
  Table as STable
} from "semantic-ui-react";
class TableGroupItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    events: PropTypes.object.isRequired
  };

  hasSelectionChangedForItem(item, oldSelection, newSelection) {
    // True if exists in one but not the other, or all items toggled
    const allToggled = oldSelection.allSelected !== newSelection.allSelected;
    if (allToggled) {
      return true;
    }

    const inOld =
      oldSelection.selected.includes(item.id) ||
      oldSelection.deselected.includes(item.id);

    const inNew =
      newSelection.selected.includes(item.id) ||
      newSelection.deselected.includes(item.id);
    return inOld !== inNew;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.state.expanded !== nextProps.state.expanded ||
      this.hasSelectionChangedForItem(
        this.props.item,
        this.props.selection,
        nextProps.selection
      ) ||
      this.props.item.following !== nextProps.item.following ||
      this.props.item.followingUpdating !== nextProps.item.followingUpdating
    );
  }

  render() {
    const {
      item,
      state,
      events,
      selection,
      isContactList,
      isGrouped,
      isInternal,
      canEditCustomerWatchLists,
      onChangeSelection,
      onChangeFollowing,
      viewingUniverse
    } = this.props;
    return (
      <React.Fragment>
        {!isInternal && canEditCustomerWatchLists && (
          <Table.Column
            field="follow"
            title={null}
            className="follow-column"
            render={item =>
              item.followingUpdating ? (
                <Icon
                  name={item.following ? "star" : "star outline"}
                  loading={true}
                  color={item.following ? "yellow" : "grey"}
                />
              ) : (
                <Popup
                  trigger={
                    <Image
                      src={item.following ? StarSelected : StarEmpty}
                      onClick={event => {
                        // Prevent the row click event from expanding row
                        event.stopPropagation();
                        onChangeFollowing(
                          [item],
                          item.following,
                          isContactList,
                          isGrouped
                        );
                      }}
                    />
                  }
                  content={item.following ? "Followed" : "Follow"}
                  position="top center"
                  className="table-popup"
                />
              )
            }
          />
        )}
        <STable.Cell className="tiny-col" onClick={e => e.stopPropagation()}>
          <Checkbox
            checked={
              selection.allSelected
                ? !selection.deselected.includes(item.id)
                : selection.selected.includes(item.id)
            }
            onClick={e => {
              e.stopPropagation();
              onChangeSelection(item.id);
            }}
            onChange={e => {
              // Prevent a double fire of onClick
            }}
          />
        </STable.Cell>
        {isContactList ? (
          <React.Fragment>
            <Table.Column
              field="company"
              render={item => {
                if (state.expanded) {
                  return (
                    <PermissionCheck
                      target={Targets.Company}
                      action={Actions.View}
                    >
                      <Link to={CompanyRoutes.Overview(item.companyId)}>
                        <span>{item.companyName}</span>
                      </Link>
                    </PermissionCheck>
                  );
                } else {
                  return <span>{item.companyName}</span>;
                }
              }}
            />
            <Table.Column
              render={item => {
                return (
                  <React.Fragment>
                    <span
                      className={item.leftCompany ? "color-red" : undefined}
                    >
                      {item.name}
                    </span>
                    {item.leftCompany && (
                      <Popup
                        trigger={
                          <Icon
                            name="info circle"
                            style={{ marginLeft: "0.25rem" }}
                          />
                        }
                        content="Has Left Company"
                        position="top center"
                        className="table-popup status-text"
                      />
                    )}
                  </React.Fragment>
                );
              }}
              field="name"
            />
            <Table.Column field="jobTitle" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Table.Column
              field="name"
              width="40%"
              render={item => {
                const nameClass = item.isDefunct ? "color-red" : undefined;

                let name = <span className={nameClass}>{item.name}</span>;

                let defunctPopup = item.isDefunct ? (
                  <Popup
                    trigger={
                      <Icon
                        name="info circle"
                        style={{ marginLeft: "0.25rem" }}
                      />
                    }
                    content="Company Is Defunct"
                    position="top center"
                    className="table-popup status-text"
                  />
                ) : (
                  undefined
                );

                if (state.expanded) {
                  return (
                    <React.Fragment>
                      <Link to={CompanyRoutes.Overview(item.id)}>{name}</Link>
                      {defunctPopup}
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment>
                      {name}
                      {defunctPopup}
                    </React.Fragment>
                  );
                }
              }}
            />
            <Table.Column field="location" width="10%" />
            <Table.Column field="industry" />
          </React.Fragment>
        )}
        <Table.Column
          render={item => (
            <React.Fragment>
              <span>{item.status}</span>
              {item.statusComment && (
                <Popup
                  trigger={
                    <Icon
                      name="info circle"
                      style={{ marginLeft: "0.25rem" }}
                    />
                  }
                  content={item.statusComment}
                  position="top center"
                  className="table-popup status-text"
                />
              )}
            </React.Fragment>
          )}
        />
        <Table.Column
          render={item => (
            <Popup
              trigger={<span>{item.batch}</span>}
              content={<Format.Date value={item.batchDate} format="short" />}
              position="top center"
              className="table-popup"
            />
          )}
        />
        <Table.Column
          render={() =>
            !(isContactList && viewingUniverse) && (
              <Icon name={state.expanded ? "angle up" : "angle down"} />
            )
          }
          className="tiny-col"
        />
      </React.Fragment>
    );
  }
}

export default TableGroupItem;
