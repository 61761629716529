import { Format, Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck, UserTypeCheck } from "components/auth";
import { Actions, Targets, UserTypes } from "constants/permissions";
import PropTypes from "prop-types";
import React from "react";

const ClearFieldsOrTagsModal = ({
  isContactList,
  submitAction,
  submitParams,
  onSubmitted,
  numSelected
}) => (
  <ModalBuilder
    submitAction={submitAction}
    submitParams={submitParams}
    onSubmitted={onSubmitted}
    renderTrigger={showModal => (
      <UserTypeCheck restrictTo={UserTypes.External}>
        <PermissionCheck target={Targets.ItemTags} action={Actions.Edit}>
          <li onClick={showModal}>
            <div className="dropdown-action-container">
              <a>
                Clear{" "}
                {isContactList
                  ? `contact custom fields`
                  : `company custom fields`}
              </a>
            </div>
          </li>
        </PermissionCheck>
      </UserTypeCheck>
    )}
    renderModal={modalProps => {
      var descriptor = isContactList
        ? numSelected > 1
          ? "contacts"
          : "contact"
        : numSelected > 1
        ? "companies"
        : "company";

      return (
        <Modal.Confirmation
          {...modalProps}
          header={
            isContactList
              ? "Clear Contact Custom Fields"
              : "Clear Company Custom Fields"
          }
        >
          <p>
            {`Are you sure you would like to remove all custom fields from `}
            <Format.Number value={numSelected} suffix={descriptor} spaced />?
          </p>
        </Modal.Confirmation>
      );
    }}
  />
);

ClearFieldsOrTagsModal.propTypes = {
  isContactList: PropTypes.bool,
  submitAction: PropTypes.func.isRequired,
  submitParams: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSubmitted: PropTypes.func,
  numSelected: PropTypes.number
};

ClearFieldsOrTagsModal.defaultProps = {
  numSelected: 0
};

export default ClearFieldsOrTagsModal;
