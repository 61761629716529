import {
  connectForm,
  Field,
  formUtils,
  formValidators
} from "@redriver/cinnamon";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Input as SInput } from "semantic-ui-react";

/**
 * Field for a single line of text
 */
class FormInputOnBlur extends React.Component {
  static propTypes = {
    // -------------------
    // field props
    // -------------------

    /**
     * Label content to display alongside the field
     */
    label: PropTypes.node,
    /**
     * Additional content to appear alongside the field for custom behaviour
     */
    actions: PropTypes.node,
    /**
     * Whether the label text should be displayed to the side of the field rather than above it
     */
    inline: PropTypes.bool,
    /**
     * Width of the field in approximate number of characters, or a valid CSS width
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Whether the width of the field should automatically fill all available space
     */
    fluid: PropTypes.bool,
    /**
     * Optional label text to display within the field (to indicate units)
     */
    subLabel: PropTypes.string,
    /**
     * Optional position to display the sub-label text "left" or "right"
     */
    subLabelPosition: PropTypes.string,
    /**
     * Custom render function to override the displayed content in read-only mode
     * @param {Object} `props` Current field props
     */
    renderReadOnly: PropTypes.func,

    // -------------------
    // validator props
    // -------------------

    /**
     * Whether this field should be mandatory to completing the form
     */
    required: PropTypes.bool,
    /**
     * Override the default error message for required fields
     */
    requiredError: PropTypes.string,
    /**
     * The minimum number of characters allowed by this field
     */
    minLength: PropTypes.number,
    /**
     * Override the default error message for minLength fields
     */
    minLengthError: PropTypes.string,
    /**
     * The maximum number of characters allowed by this field
     */
    maxLength: PropTypes.number,
    /**
     * Override the default error message for maxLength fields
     */
    maxLengthError: PropTypes.string,
    /**
     * Secondary field that should be used as confirmation of the value in this field
     */
    confirmField: PropTypes.string,
    /**
     * Override the default error message for confirmField fields
     */
    confirmFieldError: PropTypes.string,

    // -------------------
    // connectForm props
    // -------------------

    /**
     * Name of this field, and the form data key against which the value will be stored
     */
    field: PropTypes.string.isRequired,
    /**
     * Whether any errors on the field should be displayed, if not specified then inherits from the parent form or fields
     */
    showErrors: PropTypes.bool,
    /**
     * Whether to display all errors for this field or just show one error at a time, if not specified then inherits from the parent form or fields
     */
    allErrors: PropTypes.bool,
    /**
     * Time in milliseconds for field error animation transitions or false to disable, if not specified then inherits from the parent form or fields
     */
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    /**
     * Whether to disable the field, if not specified then inherits from the parent form or fields
     */
    disabled: PropTypes.bool,
    /**
     * Whether the field should be read-only, if not specified then inherits from the parent form or fields
     */
    readOnly: PropTypes.bool,
    /**
     * Additional error messages that should be displayed before validator error messages
     */
    customErrors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function that will be run everytime the field changes to perform additional validation
     * Resulting errors can be passed to customErrors
     */
    customValidator: PropTypes.func,
    /**
     * List of other field names that should be re-validated when this field changes
     */
    notifiedFields: PropTypes.arrayOf(PropTypes.string),
    /**
     * Current value of the field (supplied by the form connection)
     * @ignore
     */
    value: PropTypes.string,
    /**
     * Callback when the field has changed (supplied by the form connection)
     * @ignore
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Array of error messages to display on the field (supplied by the form connection)
     * @ignore
     */
    errors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Props excluding those from the form connection (supplied by the form connection)
     * @ignore
     */
    passThruProps: PropTypes.object,
    /**
     * The current state of the form relative to this field (supplied by the form connection)
     * @ignore
     */
    formState: PropTypes.object
  };

  static defaultProps = {
    value: "",
    onChange: () => {},
    label: "",
    inline: false
  };

  state = {
    currentValue: ""
  };

  debouncedChange = debounce(value => this.props.onChange(value), 750);

  componentDidMount() {
    this.setState({ currentValue: this.props.value });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.value != prevProps.value &&
      this.state.currentValue != this.props.value
    )
      this.setState(() => ({ currentValue: this.props.value }));
  }

  onChange = e => {
    e.persist();
    this.setState(() => ({ currentValue: e.target.value }));
    this.debouncedChange(e.target.value);
  };

  defaultRenderReadOnly = ({ value, subLabel, subLabelPosition }) => {
    if (subLabel) {
      const right =
        subLabelPosition === "right" || subLabelPosition === "right corner";
      return (
        <p>
          {!right && subLabel + " "}
          {value}
          {right && " " + subLabel}
        </p>
      );
    }
    return <p>{value}</p>;
  };

  renderReadOnly = () =>
    (this.props.renderReadOnly || this.defaultRenderReadOnly)(this.props);

  render() {
    const {
      onChange,
      errors,
      showErrors,
      allErrors,
      animateErrors,
      disabled,
      readOnly,
      label,
      actions,
      inline,
      width,
      fluid,
      required,
      subLabel,
      subLabelPosition,
      passThruProps
    } = this.props;

    const { currentValue } = this.state;

    const semanticProps = formUtils.omitProps(
      passThruProps,
      Object.keys(FormInputOnBlur.propTypes)
    );

    return (
      <Field
        inline={inline}
        required={required}
        disabled={disabled}
        renderReadOnly={readOnly && this.renderReadOnly}
        width={width}
        fluid={fluid}
        label={label}
        actions={actions}
        errors={formUtils.fieldErrors(errors, showErrors, allErrors)}
        animateErrors={animateErrors}
      >
        <SInput
          {...semanticProps}
          label={subLabel}
          labelPosition={subLabelPosition}
          value={currentValue !== null ? currentValue : ""}
          onChange={this.onChange}
          disabled={disabled}
        />
      </Field>
    );
  }
}

export default connectForm({
  displayName: props =>
    props.label && typeof props.label === "string"
      ? props.label
      : formUtils.prettifyField(props.field),
  validators: [
    formValidators.requiredField(false),
    formValidators.minLength(),
    formValidators.maxLength(),
    formValidators.confirmField()
  ]
})(FormInputOnBlur);
