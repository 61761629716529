import { ClaimCheck } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { HasClaim } from "./helpers";

const PermissionLink = ({ target, action, children, ...linkProps }) => {
  return (
    <ClaimCheck
      claim="permissions"
      value={claimData => HasClaim(claimData, target, action, false)}
      renderFailure={() => children}
    >
      <Link {...linkProps}>{children}</Link>
    </ClaimCheck>
  );
};

PermissionLink.propTypes = {
  target: PropTypes.string.isRequired,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
};

export default PermissionLink;
