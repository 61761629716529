import { Breadcrumb, PageContent } from "@redriver/cinnamon";
import Home from "assets/icons/home_breadcrumb.svg";
import { AppRoutes } from "constants/routes";
import { ListUploads } from "features/SegmentRemoval";
import React from "react";
import { Image } from "semantic-ui-react";

const SegmentRemovalPage = () => (
  <React.Fragment>
    <Breadcrumb className="i4b-breadcrumb-container">
      <Breadcrumb.Item
        link={AppRoutes.Root}
        icon={<Image src={Home} title="Home" />}
      />
      <Breadcrumb.Item content="Segment Removal" />
    </Breadcrumb>
    <PageContent className="i4b-list-page">
      <ListUploads />
    </PageContent>
  </React.Fragment>
);

export default SegmentRemovalPage;
