import { apiGet, apiPatch, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/USER_RESET";
export const VALIDATE_TOKEN = `${NAMESPACE}/VALIDATE`;
export const REQUEST_RESET = `${NAMESPACE}/REQUEST`;

const ActionTypes = {
  ValidateEmailToken: `${NAMESPACE}/VALIDATE_EMAIL_TOKEN`,
  ValidatePwToken: `${NAMESPACE}/VALIDATE_PW_TOKEN`,
  UpdatePassword: `${NAMESPACE}/UPDATE_PW`
};

const apiOpts = {
  auth: false,
  preventErrorNotification: true
};

export const validateTokens = (
  { emailToken, pwToken },
  _
) => async dispatch => {
  let res = await dispatch(validateEmailToken(emailToken));

  if (res.success) {
    res = await dispatch(validatePasswordResetToken(pwToken));
  }
  return res;
};

export const validateEmailToken = token =>
  apiPut(
    ActionTypes.ValidateEmailToken,
    `users/reverify-email`,
    token,
    apiOpts
  );

export const validatePasswordResetToken = token =>
  apiGet(
    ActionTypes.ValidatePwToken,
    `auth/reset-tokens/${token}`,
    {},
    apiOpts
  );

export const updatePassword = (token, newPassword) =>
  apiPatch(
    ActionTypes.UpdatePassword,
    `auth/reset-tokens/${token}`,
    { isUsed: true, newPassword },
    { auth: false, preventErrorNotification: true }
  );
