import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";

const DeleteLoadedSearchModal = ({ deleteAction, deleteParams, loading }) => (
  <ModalBuilder
    submitAction={deleteAction}
    submitParams={deleteParams}
    header="Delete Saved Search?"
    renderTrigger={showModal => (
      <Button
        content="Delete"
        negative
        className="green-small-button"
        disabled={loading}
        onClick={showModal}
      />
    )}
    renderModal={modalProps => (
      <Modal.Delete {...modalProps}>
        Are you sure you want to delete this saved search?
      </Modal.Delete>
    )}
  />
);

export default DeleteLoadedSearchModal;
