import React from "react";
import { PageHeader } from "@redriver/cinnamon";
import { AccessDenied } from "features/System";
import { SystemPageContent } from "components/pages";

const AccessDeniedPage = ({ match, location }) => {
  return (
    <SystemPageContent>
      <PageHeader>Access Unavailable</PageHeader>
      <AccessDenied reason={match.params.reason} />
    </SystemPageContent>
  );
};

export default AccessDeniedPage;
