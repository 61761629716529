import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";

const EmailDdiCriteria = ({ active, handleClick, criteria }) => {
  const { emailOnly, ddiOnly } = criteria.emailDdiSearch;

  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Email & Telephone"
        text={
          <React.Fragment>
            {emailOnly && <span>Valid email address only</span>}
            {ddiOnly && <span>Direct telephone numbers only</span>}
            {!emailOnly && !ddiOnly && "No active filter"}
          </React.Fragment>
        }
      />
      <Accordion.Content active={active}>
        <p>
          {emailOnly && <span>Valid email address only</span>}
          {ddiOnly && <span>Direct telephone numbers only</span>}
          {!emailOnly && !ddiOnly && "No active filter"}
        </p>
      </Accordion.Content>
    </React.Fragment>
  );
};

export default EmailDdiCriteria;
