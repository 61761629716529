import React from "react";
import { Card, Button } from "semantic-ui-react";
import { Format, TriggerBuilder } from "@redriver/cinnamon";

import { toggleStatus } from "./actions";

class NotificationCard extends React.Component {
  state = {
    closed: null,
    updating: false
  };

  componentDidMount() {
    const { actioned, updating } = this.props;
    this.setState({ closed: actioned, updating: updating });
  }

  fireTrigger = trigger => {
    trigger().then(() => {
      this.props.onRefresh();
    });
  };

  render() {
    const { closed, updating } = this.state;
    const { sentByUser, createdUtc, priority, content } = this.props;

    return (
      <Card className="notifications-card">
        <Card.Content>
          <Card.Header>System Notification</Card.Header>
          <Card.Meta style={{ display: "inline-block", marginLeft: "1em" }}>
            <Format.Time value={createdUtc} format="h:mm a" />
          </Card.Meta>

          <TriggerBuilder
            submitAction={toggleStatus}
            submitParams={{ id: this.props.id }}
            onSubmitted={() => this.setState({ closed: !closed })}
            renderTrigger={(fireTrigger, state, events) => (
              <Button
                className={`secondary-small-button${
                  closed ? " notification-button-closed" : " secondary"
                }`}
                content={closed ? "Closed" : "Mark as closed"}
                style={{
                  float: "right",
                  marginTop: "-.2rem"
                }}
                onClick={() => (closed ? null : this.fireTrigger(fireTrigger))}
                loading={state.submitting || updating}
              />
            )}
          />

          <p>{content}</p>
        </Card.Content>
      </Card>
    );
  }
}

export default NotificationCard;
