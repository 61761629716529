import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import { Icon } from "semantic-ui-react";

const RemoveFromSegmentsModal = ({
  loadAction,
  submitAction,
  loadParams,
  submitParams,
  onSubmitted
}) => {
  return (
    <PermissionCheck target={Targets.CustomerSegments} action={Actions.Edit}>
      <ModalBuilder
        loadAction={loadAction}
        loadParams={loadParams}
        submitAction={submitAction}
        submitParams={submitParams}
        onSubmitted={onSubmitted}
        preventErrorNotification
        renderTrigger={showModal => (
          <div onClick={showModal} className="dropdown-action-container">
            <a>Remove from Custom Segment</a>
          </div>
        )}
        renderModal={(modalProps, data, state) => (
          <Modal.Confirmation
            {...modalProps}
            header={
              <div>
                <Icon name="delete" /> Remove companies from Custom Segment
              </div>
            }
            submitLabel="Remove from Custom Segment"
            submitDisabled={!data.totalUpdates || data.exceedsLimit}
            size="tiny"
            error=""
          >
            <div style={{ minHeight: "4em" }}>
              {state.error && state.error.result.some(x => x.code == 13005) && (
                <p>
                  You cannot remove any more companies from your custom segment
                  as you have run out of refresh credits. Please contact
                  <a href="mailto:support@i4b.com">Support</a> for further
                  information.
                </p>
              )}

              {data && data.exceedsLimit && (
                <p>
                  This would result in the removal of {data.totalUpdates}{" "}
                  {data.totalUpdates === 1 ? "company" : "companies"} from your
                  custom segment. This is not allowed as it would exceed your
                  refresh limit.
                </p>
              )}

              {data && !data.exceedsLimit && data.totalUpdates >= 1 && (
                <p>
                  This will result in the removal of {data.totalUpdates}{" "}
                  {data.totalUpdates === 1 ? "company" : "companies"} from your
                  custom segment. Are you sure you want to proceed?
                </p>
              )}

              {data && data.totalUpdates === 0 && (
                <p>
                  No companies would be removed. This is because the companies
                  are not in your segment and/or segment access is not enabled
                  for the related countries.
                </p>
              )}
            </div>
          </Modal.Confirmation>
        )}
      />
    </PermissionCheck>
  );
};

export default RemoveFromSegmentsModal;
