import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import React from "react";
import MigrateCustomer from "./MigrateCustomer";

const ActionsColumn = ({ item, onChange }) => {
  return (
    <Table.Menu
      direction="left"
      icon="ellipsis horizontal"
      className="actions-column"
    >
      {item.originalId !== null &&
        (!item.migrationInProgress ? (
          <MigrateCustomer
            id={item.id}
            onSubmitted={onChange}
            renderTrigger={showModal => (
              <PermissionCheck
                target={Targets.Customers}
                action={Actions.Migrate}
              >
                <Table.MenuItem
                  onClick={showModal}
                  content="Migrate Customer"
                />
              </PermissionCheck>
            )}
          />
        ) : (
          <Table.MenuItem
            className="disabled"
            content="Migration in progress"
          />
        ))}
    </Table.Menu>
  );
};

export default ActionsColumn;
