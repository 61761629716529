import { apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "COMPANY_STRUCTURE";

export const ActionTypes = {
  UpdateForm: `${NAMESPACE}/UPDATE_FORM`,
  AddCompanyTag: `${NAMESPACE}/ADD_COMPANY_TAG`,
  AddToList: `${NAMESPACE}/ADD_TO_LIST`,
  SaveList: `${NAMESPACE}/SAVE_LIST`,
  ClearForm: `${NAMESPACE}/CLEAR_FORM`
};

export const updateForm = (field, data, applyChanges) => (
  dispatch,
  getState
) => {
  dispatch({
    type: ActionTypes.UpdateForm,
    field,
    data,
    applyChanges
  });
};

export const addCompanyTag = (field, { selection, viewingUniverse }) => (
  dispatch,
  getState
) => {
  return dispatch(
    apiPut(ActionTypes.AddCompanyTag, `companies/company-fields`, {
      searchCriteria: [],
      selection: selection,
      customFieldId: field.name,
      value: field.value,
      showUniverse: false
    })
  );
};

export const clearForm = () => dispatch => {
  dispatch({ type: ActionTypes.ClearForm });
};

export const addToList = (modalData, submitParams) => (dispatch, getState) => {
  const request = {
    ...modalData,
    selection: submitParams.selection,
    searchCriteria: []
  };

  return dispatch(
    apiPost(ActionTypes.AddToList, "lists/add-to-existing", request)
  );
};

export const saveList = (modalData, submitParams) => (dispatch, getState) => {
  const request = {
    ...modalData,
    selection: submitParams.selection,
    searchCriteria: []
  };

  return dispatch(apiPost(ActionTypes.SaveList, "lists/company", request));
};
