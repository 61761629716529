import React from "react";
import PropTypes from "prop-types";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { forceUserReset } from "./actions";
import { Button } from "semantic-ui-react";

const ReverifyResetUserConfirmationModal = ({
  id,
  name,
  company,
  renderTrigger
}) => {
  return (
    <ModalBuilder
      submitAction={forceUserReset}
      submitParams={id}
      renderTrigger={renderTrigger}
      renderModal={modalProps => (
        <Modal.Confirmation
          {...modalProps}
          header="Confirm re-verify and reset"
          size="tiny"
        >
          <p>
            Are you sure you wish to force{" "}
            <strong>
              {name} {!!company && "at"} {company}
            </strong>{" "}
            to re-verify and reset their password?
          </p>
        </Modal.Confirmation>
      )}
    />
  );
};

ReverifyResetUserConfirmationModal.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  company: PropTypes.string,
  renderTrigger: PropTypes.func.isRequired
};

export default ReverifyResetUserConfirmationModal;
