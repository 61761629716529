import {
  requestIsBusy,
  requestIsSlow,
  requestIsSuccess,
  requestError
} from "@redriver/cinnamon";
import { VALIDATE_TOKEN, RESEND_VERIFICATION } from "./actions";

const initialState = {
  // token validation
  validatingToken: false,
  validationSlow: null,
  tokenCorrect: null,
  // email resending
  isResending: false,
  resendingSlow: null,
  resendingSuccess: null,
  resendingError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VALIDATE_TOKEN:
      return {
        ...state,
        validatingToken: requestIsBusy(action),
        validationSlow: requestIsSlow(action),
        tokenCorrect: requestIsSuccess(action)
      };

    case RESEND_VERIFICATION:
      return {
        ...state,
        isResending: requestIsBusy(action),
        resendingSlow: requestIsSlow(action),
        resendingSuccess: requestIsSuccess(action),
        resendingError: requestError(action)
      };

    default:
      return state;
  }
};
