import { Form, getAuthClaims } from "@redriver/cinnamon";
import { SearchFiltersDivider } from "components/search";
import {
  CompanyFollowedFilter,
  CompanyListsFilter,
  CompanyUploadsFilter,
  CountriesFilter,
  CustomFieldsFilter,
  IndustriesFilter,
  PostalAreasFilter,
  SizeFilter
} from "components/search/filters";
import {
  guidComparison,
  isFormDataEmpty,
  resetSearchCriteria
} from "features/Utils";
import React from "react";
import { connect } from "react-redux";
import {
  clearSelected,
  clearSizeFilter,
  getListFormData,
  getPreviousFormData,
  getSegmentCompaniesCount,
  getUniverseCompaniesCount,
  removeFilter,
  resetSearch,
  resetSearchSoft,
  updateForm
} from "./actions";
import AddFilter from "./AddFilter";
import SearchFilter from "./SearchFilter";
import { getCompaniesSearchState } from "./selectors";

class CompaniesFilters extends React.Component {
  state = {};

  isNumber = prop => !isNaN(prop);

  onChange = (field, data, applyChanges) => {
    const { updateForm } = this.props;
    if (data[0].companySizeDetails) {
      const { companySizeDetails } = data[0];
      return Object.keys(companySizeDetails).every(key => {
        if (key !== "match" && key !== "revenueCurrency") {
          this.isNumber(companySizeDetails[key])
            ? updateForm(field, data, applyChanges)
            : null;
        } else {
          updateForm(field, data, applyChanges);
        }
      });
    }
    updateForm(field, data, applyChanges);
  };

  componentDidMount() {
    const {
      userId,
      formData,
      getPreviousFormData,
      getListFormData,
      listId,
      getUniverseCompaniesCount,
      getSegmentCompaniesCount
    } = this.props;

    if (listId) {
      getListFormData(listId);
    } else {
      if (
        isFormDataEmpty(formData) ||
        !guidComparison(formData.searchCriteriaUserId, userId)
      ) {
        getPreviousFormData(userId);
      }
    }
    getUniverseCompaniesCount();
    getSegmentCompaniesCount();
  }

  componentDidUpdate(prevProps) {
    const {
      formData,
      resetSearch,
      resetSearchSoft,
      criteriaIsSystemGenerated
    } = this.props;

    resetSearchCriteria(
      formData,
      prevProps.formData,
      criteriaIsSystemGenerated,
      resetSearchSoft,
      resetSearch
    );
  }

  render() {
    const {
      formData,
      loading,
      pageChanged,
      results,
      clearSelected,
      removeFilter,
      clearSizeFilter,
      listId,
      isUniverse
    } = this.props;

    return (
      <Form value={formData} onChange={this.onChange}>
        <Form.Array
          field="searchCriteria"
          arrayKey="id"
          propagateUpdates="always" // todo: remove this workaround, optimise by putting result count into the formData array through the reducer instead. (Talk to NP)
        >
          {({ fields, arrayIndex }) => {
            switch (fields.type) {
              case "searchFilter":
                return (
                  <SearchFilter id={fields.id} value={fields} listId={listId} />
                );
              case "countriesFilter":
                return (
                  <CountriesFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    clearSelected={e => clearSelected(e, fields.id)}
                    viewingUniverse={isUniverse}
                  />
                );
              case "sizeFilter":
                return (
                  <SizeFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSizeFilter={(id, e) => clearSizeFilter(e, id)}
                  />
                );
              case "postalAreasFilter":
                return (
                  <PostalAreasFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                    viewingUniverse={isUniverse}
                  />
                );
              case "stockExchangeIndexesFilter":
                // id:78933
                return <React.Fragment />;
              // return (
              //   <StockExchangeIndexesFilter
              //     id={fields.id}
              //     results={results}
              //     formData={formData}
              //     loading={loading}
              //     pageChanged={pageChanged}
              //     removeFilter={(id, e) => removeFilter(e, id)}
              //     clearSelected={e => clearSelected(e, fields.id)}
              //   />
              // );
              case "industriesFilter":
                return (
                  <IndustriesFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "customFieldsFilter":
                return (
                  <CustomFieldsFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "listsFilter":
                return (
                  <CompanyListsFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "companyUploadsFilter":
                return (
                  <CompanyUploadsFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
              case "companyFollowedFilter":
                return (
                  <CompanyFollowedFilter
                    id={fields.id}
                    results={results}
                    formData={formData}
                    loading={loading}
                    pageChanged={pageChanged}
                    removeFilter={(id, e) => removeFilter(e, id)}
                    clearSelected={e => clearSelected(e, fields.id)}
                  />
                );
            }
          }}
        </Form.Array>
        <SearchFiltersDivider />
        <AddFilter />
      </Form>
    );
  }
}

const actions = {
  updateForm,
  resetSearch,
  resetSearchSoft,
  clearSelected,
  removeFilter,
  clearSizeFilter,
  getPreviousFormData,
  getListFormData,
  getUniverseCompaniesCount,
  getSegmentCompaniesCount
};

const mapStateToProps = state => {
  const {
    formData,
    companies,
    companiesLoading,
    pageChanged,
    criteriaIsSystemGenerated,
    isUniverse
  } = getCompaniesSearchState(state);
  const { userId } = getAuthClaims(state);
  const { criteriaResultCount } = companies;

  return {
    formData,
    results: criteriaResultCount,
    loading: companiesLoading,
    pageChanged,
    userId,
    criteriaIsSystemGenerated,
    isUniverse
  };
};

export default connect(
  mapStateToProps,
  actions
)(CompaniesFilters);
