import React from "react";
import { Image } from "semantic-ui-react";
import { Breadcrumb } from "@redriver/cinnamon";
import { Grid } from "semantic-ui-react";
import Home from "assets/icons/home_breadcrumb.svg";
import { AppRoutes } from "constants/routes";
import ListCustomerUserStats from "features/Customers/ListCustomerUserStats";

const UserStatsPage = ({ history }) => {
  return (
    <React.Fragment>
      <Breadcrumb className="i4b-breadcrumb-container">
        <Breadcrumb.Item
          link={AppRoutes.Root}
          icon={<Image src={Home} title="Home" />}
        />
        <Breadcrumb.Item content="User Stats" />
      </Breadcrumb>
      <Grid className="i4b-list-page user-page">
        <ListCustomerUserStats isOwnCustomer />
      </Grid>
    </React.Fragment>
  );
};

export default UserStatsPage;
