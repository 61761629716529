import { default as React } from "react";
import { mockSuccess, Form, registerLookup } from "@redriver/cinnamon";
import { NotificationEmailFrequency } from "constants/notifications";

const NAMESPACE = "NOTIFICATIONS/LOOKUPS";

const ActionTypes = {
  GetNotificationFrequenciesLookup: `${NAMESPACE}/EMAIL_FREQUENCIES`
};

registerLookup("notificationFrequencyLookup", () =>
  mockSuccess(ActionTypes.GetNotificationFrequenciesLookup, {
    response: Object.values(NotificationEmailFrequency).map(opt => ({
      value: opt,
      text: opt
    }))
  })
);

export const NotificationFrequencyLookup = props => (
  <Form.Dropdown {...props} lookup="notificationFrequencyLookup" />
);
