import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { OptOutDownload } from "features/Contacts";
import * as React from "react";
import { withRouter } from "react-router-dom";
import PermissionErrorPage from "../PermissionErrorPage";

const OptOutDownloadPage = ({ match }) => (
  <React.Fragment>
    <PermissionCheck target={Targets.ContactOptOuts} action={Actions.View}>
      <OptOutDownload tokenId={match.params.tokenId} />
    </PermissionCheck>
    <PermissionCheck
      target={Targets.ContactOptOuts}
      action={Actions.View}
      not
      component={PermissionErrorPage}
    />
  </React.Fragment>
);

export default withRouter(OptOutDownloadPage);
