import React from "react";
import { ActionBar, Form, FormBuilder, PageSize } from "@redriver/cinnamon";
import { Button, Icon } from "semantic-ui-react";
import * as fileSaver from "file-saver";
import { GetUserStatsDownload } from "./actions";
import { UsersActiveFilterDropdown } from "../lookups";
import moment from "moment";

const FilterCustomerUserStats = ({
  filters,
  onChangeFilters,
  pageSize,
  onPageSizeChange,
  earliestDate,
  customerId,
  isOwnCustomer
}) => (
  <ActionBar>
    <ActionBar.Item fluid className="userStatsFilter">
      <FormBuilder
        initialData={filters}
        onChange={onChangeFilters}
        renderForm={formProps => (
          <Form {...formProps}>
            <Form.Group inline>
              <Form.Input
                field="search"
                placeholder="Search users..."
                width={20}
              />
              <Form.DateRange
                instant
                inclusiveStart
                inclusiveEnd
                field="dateFilters"
                minDate={moment(earliestDate)}
              />
              <Form.Clearer field="dateFilters" />
              <UsersActiveFilterDropdown field="userActiveFilter" width={10} />
            </Form.Group>
          </Form>
        )}
      />
    </ActionBar.Item>

    <ActionBar.Item
      align="right"
      className="flex align-center i4b-filter-pagesize"
    >
      <FormBuilder
        submitAction={() =>
          GetUserStatsDownload({ customerId, isOwnCustomer }, filters)
        }
        onSubmitted={data => fileSaver.saveAs(data, "download.csv")}
        renderForm={(formProps, state, events) => (
          <Form>
            <Button
              content="Download CSV"
              primary
              className="green-small-button"
              onClick={events.onSubmit}
              type="button"
            />
          </Form>
        )}
      />
      <PageSize
        value={pageSize}
        onChange={x => onPageSizeChange({ pageSize: x, pageNumber: 1 })}
        options={[10, 25, 50, 100]}
      />
    </ActionBar.Item>
  </ActionBar>
);

export default FilterCustomerUserStats;
