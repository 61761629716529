import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import {
  loadNotificationSettings,
  updateNotificationSettings
} from "./actions";
import { NotificationFrequencyLookup } from "../lookups";

const NotificationSettingsModal = props => (
  <ModalBuilder
    withForm
    loadAction={loadNotificationSettings}
    renderTrigger={show => (
      <Button
        content="Notification Settings"
        primary
        className="green-small-button"
        onClick={show}
        type="button"
      />
    )}
    submitAction={updateNotificationSettings}
    renderModal={(modalProps, formProps, state, events) => (
      <Modal
        {...modalProps}
        header="Update Notification Settings"
        submitLabel="Update Settings"
        size="small"
      >
        <Form {...formProps}>
          <NotificationFrequencyLookup
            field="notificationEmailFrequency"
            label="Email Frequency"
          />
        </Form>
      </Modal>
    )}
  />
);

export default NotificationSettingsModal;
