import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Transition } from "semantic-ui-react";

class CustomTreeNode extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    node: PropTypes.shape({
      root: PropTypes.bool,
      defaultExpanded: PropTypes.bool,
      text: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.object),
      className: PropTypes.string
    }).isRequired,
    nodeKey: PropTypes.string,
    indent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fixed: PropTypes.bool,
    collapsedIcon: PropTypes.node,
    expandedIcon: PropTypes.node,
    defaultExpanded: PropTypes.bool,
    renderNode: PropTypes.func,
    animate: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
  };

  static defaultProps = {
    visible: true
  };

  state = {
    expanded:
      this.props.fixed ||
      this.props.defaultExpanded ||
      this.props.node.defaultExpanded ||
      this.props.node.root ||
      false,
    // used to ensure previously expanded children are rendered so they persist their expanded state
    previouslyExpanded: false
  };

  _keyWarning = false;

  getNodeKey = node => {
    const { nodeKey } = this.props;
    const key = node[nodeKey];
    if (!key && !this._keyWarning) {
      const { children, ...nodeWithoutChildren } = node;
      console.warn(
        "TreeView nodeKey is invalid for node: " +
          JSON.stringify(nodeWithoutChildren)
      );
      this._keyWarning = true;
    }
    return key;
  };

  collapse = () => this.setState({ expanded: false, previouslyExpanded: true });

  expand = () => this.setState({ expanded: true });

  defaultRenderNode = (node, state, events) => node.text;

  render() {
    const {
      visible,
      node,
      nodeKey,
      indent,
      fixed,
      collapsedIcon,
      expandedIcon,
      defaultExpanded,
      renderNode,
      animate,
      companyId
    } = this.props;

    const { expanded, previouslyExpanded } = this.state;
    const hasChildren = node.children && node.children.length > 0;

    const arrow = !fixed ? (
      <div
        style={{ cursor: "pointer" }}
        onClick={expanded ? this.collapse : this.expand}
      >
        {expanded ? expandedIcon : collapsedIcon}
      </div>
    ) : null;

    const renderArgs = [
      node,
      { expanded },
      { onCollapse: this.collapse, onExpand: this.expand },
      arrow
    ];

    const expanderIndent = fixed ? 0 : 25;

    const isAnimating = typeof animate === "number" && animate > 0;
    const AnimateWrapper = isAnimating ? Transition : React.Fragment;
    const animateProps = isAnimating
      ? {
          visible: visible && expanded,
          animation: "fade down",
          duration: animate
        }
      : {};

    return (
      <section>
        <div
          className={classNames(
            "cin tree-node",
            { visible: !!visible },
            node.className
          )}
        >
          {visible && !node.root && (
            <React.Fragment>
              <div
                className={`company-structure-node ${
                  node.treeLevel == 0
                    ? "ultimate-parent"
                    : node.value === companyId
                    ? "current-company"
                    : `level${node.treeLevel}`
                }`}
                id={node.value === companyId ? "current-company" : null}
              >
                {(renderNode && renderNode(...renderArgs)) ||
                  this.defaultRenderNode(...renderArgs)}
                <p className="country-code">[{node.countryCode}]</p>
              </div>
            </React.Fragment>
          )}
          {(isAnimating || expanded || previouslyExpanded) && hasChildren && (
            <AnimateWrapper {...animateProps}>
              <div style={{ marginLeft: node.root ? expanderIndent : indent }}>
                {node.children.map((child, index) => (
                  <CustomTreeNode
                    key={this.getNodeKey(child) || index}
                    visible={isAnimating || (visible && expanded)}
                    node={child}
                    nodeKey={nodeKey}
                    indent={indent}
                    fixed={fixed}
                    collapsedIcon={collapsedIcon}
                    expandedIcon={expandedIcon}
                    defaultExpanded={defaultExpanded}
                    renderNode={renderNode}
                    animate={animate}
                    companyId={companyId}
                  />
                ))}
              </div>
            </AnimateWrapper>
          )}
        </div>
      </section>
    );
  }
}

export default CustomTreeNode;
