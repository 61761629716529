export default {
  Lookup: "l",
  List: "li",
  User: "u",
  Seeding: "se",
  Role: "r",
  Customers: "cu",
  Contacts: "ct",
  Company: "co",
  //ContactTags: "ct", NOTE: Have combined these into "ItemTags"
  //CompanyCustomFields: "ccf",
  CustomerWatchLists: "cwl",
  CustomerSuppressionLists: "csl",
  CustomerSegments: "seg",
  CustomerUserStats: "us",
  Upload: "up",
  Items: "i", //NOTE: this is used when you have 1 permission eg editing both custom fields & tags
  ItemStatus: "is",
  ItemTags: "it",
  Settings: "sg",
  ExportTemplates: "cet",
  ContactOptOuts: "coo",
  ContactDetailedOptOuts: "cdoo",
  Override: "ov",
  SecureFile: "sf",
  Reports: "re"
};
