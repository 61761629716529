import {
  requestIsBusy,
  requestIsSlow,
  requestIsSuccess,
  requestError
} from "@redriver/cinnamon";
import { RESEND_EMAIL, SHOW_MESSAGE, HIDE_MESSAGE } from "./actions";

const initialState = {
  emailExpiryUtc: "",
  isResending: false,
  resendEmailSlow: null,
  resendEmailSuccess: null,
  resendEmailError: null,
  showMessage: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESEND_EMAIL:
      return {
        ...state,
        isResending: requestIsBusy(action),
        resendEmailSlow: requestIsSlow(action),
        resendEmailSuccess: requestIsSuccess(action),
        resendEmailError: requestError(action)
      };

    case SHOW_MESSAGE:
      return {
        ...state,
        showMessage: true
      };

    case HIDE_MESSAGE:
      return {
        ...state,
        showMessage: false
      };

    default:
      return state;
  }
};
