import { apiGet, apiPost, apiPut } from "@redriver/cinnamon";
import { refreshNotifications } from "../NotificationNavMenuItem/actions";

const NAMESPACE = "NOTIFICATIONS/LIST";

export const ActionTypes = {
  GetUserNotifications: `${NAMESPACE}/GET_USER_NOTIFICATIONS`,
  ToggleStatus: `${NAMESPACE}/TOGGLE_STATUS`,
  SendNotification: `${NAMESPACE}/SEND_NOTIFICATION`,
  ToggleOpenModal: `${NAMESPACE}/TOGGLE_OPEN_MODAL`,
  MarkNotificationAsRead: `${NAMESPACE}/MARK_NOTIFICATION_READ`,
  GetNotificationSettings: `${NAMESPACE}/GET_NOTIFICATION_SETTINGS`,
  UpdateNotificationSettings: `${NAMESPACE}/UPDATE_NOTIFICATION_SETTINGS`
};

export const getNotifications = args => async dispatch => {
  let res;

  if (!args) {
    res = await dispatch(
      apiGet(ActionTypes.GetUserNotifications, `notifications`, {
        pageSize: 10,
        pageNumber: 1
      })
    );
  } else {
    res = await dispatch(
      apiGet(ActionTypes.GetUserNotifications, `notifications`, {
        ...args.pagination,
        ...args.filters
      })
    );
  }

  await dispatch(refreshNotifications());

  return res;
};

export const toggleStatus = ({ id }) => async dispatch => {
  return dispatch(apiPut(ActionTypes.ToggleStatus, `notifications/${id}`));
};

export const loadNotificationSettings = () =>
  apiGet(ActionTypes.GetNotificationSettings, `notifications/settings`);

export const updateNotificationSettings = settings =>
  apiPut(
    ActionTypes.UpdateNotificationSettings,
    `notifications/settings`,
    settings
  );
