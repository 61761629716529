import { ContentBuilder, Format } from "@redriver/cinnamon";
import { isNil } from "lodash";
import React from "react";
import { Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Grid, Loader } from "semantic-ui-react";
import { getSegmentDeta } from "./actions";
import Widget from "./Widget";

class SegmentWidget extends React.Component {
  renderPie = data => {
    const used = data[0].value;
    const remaining = data[1].value;
    const total = used + remaining;

    let labelValue = Math.round(
      !!used && !isNil(remaining) ? (used / total) * 100 : 0
    );

    if (labelValue == 100 && used < total) {
      labelValue = 99;
    }

    return (
      <ResponsiveContainer width="100%" height={160}>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            innerRadius={"60%"}
            outerRadius={"100%"}
            startAngle={90}
            endAngle={-270}
            isAnimationActive={false}
            stroke="none"
            legendType="none"
          >
            <Label
              value={`${labelValue}% used`}
              position="center"
              style={{ fontSize: "1.0rem" }}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  };

  renderStats = data =>
    data.map(x => (
      <li key={x.suffix} style={x.style}>
        <Format.Number
          value={x.value}
          suffix={x.suffix}
          className={x.suffix}
          spaced
        />
      </li>
    ));

  renderContent() {
    return (
      <ContentBuilder
        loadAction={getSegmentDeta}
        loadTransform={data => {
          return {
            pie: [
              {
                name: "Used",
                value: data.usedAmount,
                fill: "#4c8db8"
              },
              {
                name: "Remaining",
                value:
                  data.limit >= data.usedAmount
                    ? data.limit - data.usedAmount
                    : 0,
                fill: "#cccccc"
              }
            ],
            stats: [
              { value: data.remainingAmount, suffix: "credits remaining" },
              {
                value: data.usedAmount,
                suffix: data.usedAmount == 1 ? "company" : "companies",
                style:
                  data.usedAmount > data.limit
                    ? {
                        color: "red",
                        fontWeight: "bold"
                      }
                    : {}
              },
              {
                value: data.limit,
                suffix: "segment limit"
              }
            ],
            dateUtc: data.refreshDateUtc,
            limit: data.limit,
            usedAmount: data.usedAmount
          };
        }}
        renderContent={(
          { pie, stats, dateUtc, limit, usedAmount },
          state,
          events
        ) =>
          !state.loading && stats ? (
            <Grid
              style={{ borderTop: "1px solid #f7f6fa", marginTop: "0.5rem" }}
            >
              <Grid.Row columns={2}>
                <Grid.Column>{this.renderPie(pie)}</Grid.Column>
                <Grid.Column className="widget-segment-details-column">
                  <ul>{this.renderStats(stats)}</ul>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : (
            <Loader />
          )
        }
      />
    );
  }

  render() {
    const { label, colour, loading } = this.props;

    return (
      <Widget
        loading={loading}
        header={label}
        color={colour}
        className="widget-segment"
      >
        {this.renderContent()}
      </Widget>
    );
  }
}

export default SegmentWidget;
