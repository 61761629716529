import Logo from "assets/logo/logo.svg";
import React from "react";
import { Link } from "react-router-dom";
import { Image } from "semantic-ui-react";

const SystemPageContent = ({ showTagline, reducedPadding, children }) => (
  <div className="system-page">
    <div className="system-page-content">
      <section className="system-panel">
        <section className="system-container">
          <section
            className={`system-form${
              reducedPadding ? " system-form-mini" : ""
            }`}
          >
            <Link
              to={{ pathname: process.env.I4B_MARKETING_URL }}
              target="_blank"
            >
              <Image
                src={Logo}
                alt="i-4business logo"
                title="i-4business"
                className="system-logo"
              />
            </Link>
            {showTagline && (
              <small className="system-logo-tagline">
                The Advanced B2B Data platform for Sales & Marketing in EMEA
              </small>
            )}
            {children}
          </section>

          <section className="system-background" />
        </section>
      </section>
    </div>

    <footer className="system-footer">
      <p>
        Problems logging in? <a href="mailto:support@i4b.com">Email support</a>{" "}
        or telephone +44 (0) 1252 367 400
      </p>
    </footer>
  </div>
);

export default SystemPageContent;
