import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";

import { SystemRoutes } from "constants/routes";

const renderMessage = reason => {
  switch (reason) {
    case "ip-whitelist":
      return "You do not have the permissions to access i-4business on this IP address. Please contact the system administrator if this is wrong.";

    default:
      return "This account has been restricted. Please contact the system administrator for more information.";
  }
};

const AccessDenied = ({ reason, history }) => (
  <Fragment>
    <p>{renderMessage(reason)}</p>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button fluid onClick={() => history.push(SystemRoutes.Login)}>
        Cancel &amp; Exit
      </Button>
    </div>
  </Fragment>
);

export default withRouter(AccessDenied);
