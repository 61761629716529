import { Form, PageLoader } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import { PostalAreasLookup } from "../lookups";
import FilterBase from "./FilterBase";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";

class PostalAreasFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    results: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    viewingUniverse: PropTypes.bool.isRequired,
    clearSelected: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired
  };
  state = {
    visible: true,
    areasSelected: "",
    total: null
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  componentDidMount = () => {
    const { id, formData } = this.props;
    this.getSelected(formData, id);
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }

  componentDidUpdate = prevProps => {
    const { id, formData } = this.props;
    if (prevProps.formData !== formData) {
      this.getSelected(formData, id);
    }
  };

  getSelected = (formData, id) => {
    const { searchCriteria } = formData;

    const postalAreasObj = searchCriteria.find(el => el.id == id);
    const { selectedAreas } = postalAreasObj;

    this.setState({
      selectedAreas: selectedAreas.length > 0 ? selectedAreas.join(", ") : null
    });
  };

  filter(array) {
    const { id, formData } = this.props;
    const { searchCriteria } = formData;

    const postalAreasObj = searchCriteria.find(el => el.id == id);
    const { postalFilter } = postalAreasObj;
    if (postalFilter === undefined || postalFilter === "") return array;
    const getNodes = (result, object) => {
      if (object.value.toLowerCase().includes(postalFilter.toLowerCase())) {
        result.push({ ...object });
        return result;
      }
      if (Array.isArray(object.children)) {
        const children = object.children.reduce(getNodes, []);
        if (children.length) result.push({ ...object, children });
      }
      return result;
    };

    return array.reduce(getNodes, []);
  }

  render() {
    const { visible, selectedAreas, total } = this.state;
    const {
      id,
      loading,
      pageChanged,
      formData,
      removeFilter,
      clearSelected,
      viewingUniverse
    } = this.props;
    const { searchCriteria } = formData;

    const postalAreasObj = searchCriteria.find(el => el.id == id);
    const { postalFilter } = postalAreasObj;
    return (
      <PostalAreasLookup
        viewingUniverse={viewingUniverse}
        render={({ response }, refresh) => (
          <FilterBase
            total={total}
            loading={loading && !pageChanged}
            className="postal-areas-filter"
          >
            {response ? (
              <React.Fragment>
                <FilterBase.Header
                  title="Postal Areas"
                  collapsible
                  collapsed={!visible}
                  onCollapseToggle={this.toggleVisibility}
                  removable
                  onRemove={event => removeFilter(id, event)}
                  showClearer={!!selectedAreas}
                  onClear={clearSelected}
                >
                  {!visible && (
                    <div className="ellipsis-paragraph">
                      {selectedAreas || "No areas selected"}
                    </div>
                  )}
                </FilterBase.Header>
                <FilterBase.Body collapsed={!visible}>
                  <Form.Input
                    style={{ marginTop: "1em" }}
                    field="postalFilter"
                    placeholder="Search..."
                  />
                  {postalFilter && <span>Clear filter to view full tree</span>}
                  <div className="countries-selection-container">
                    <Form.CheckboxTree
                      key={id}
                      className="checkbox-tree"
                      field="selectedAreas"
                      nodes={[
                        {
                          value: `${response.value}`,
                          text: `${response.text}`,
                          defaultExpanded: true,
                          children: this.filter(response.children) || []
                        }
                      ]}
                    />
                  </div>
                </FilterBase.Body>
              </React.Fragment>
            ) : (
              <PageLoader
                active
                inline="centered"
                size="small"
                className="p-y-05"
              />
            )}
          </FilterBase>
        )}
      />
    );
  }
}

export default PostalAreasFilter;
