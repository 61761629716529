import React from "react";
import { PageHeader } from "@redriver/cinnamon";
import { EmailVerification } from "features/System";
import { SystemPageContent } from "components/pages";

const EmailVerificationPage = ({ match }) => (
  <SystemPageContent>
    <PageHeader>Verify Email Address</PageHeader>
    <EmailVerification token={match.params.token || ""} />
  </SystemPageContent>
);

export default EmailVerificationPage;
