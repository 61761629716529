import React from "react";
import PropTypes from "prop-types";
import { Content, PageLoader } from "@redriver/cinnamon";
import { Grid, Segment, Button, Icon } from "semantic-ui-react";
import { UserLookup } from "../lookups";
import { updateUserIpWhitelist } from "./actions";
import UserContactsReadOnly from "./UserContactsReadOnly";
import UserDetailsReadOnly from "./UserDetailsReadOnly";
import UserContactsForm from "./UserContactsForm";
import UserDetailsForm from "./UserDetailsForm";

class UserOverview extends React.Component {
  state = {
    contactsEditable: false,
    detailsEditable: false
  };

  toggleContactsEditable = () => {
    this.setState({ contactsEditable: !this.state.contactsEditable });
  };

  toggleDetailsEditable = () => {
    this.setState({ detailsEditable: !this.state.detailsEditable });
  };

  render() {
    const { userId } = this.props;
    const { contactsEditable, detailsEditable } = this.state;

    return (
      <UserLookup
        userId={userId}
        render={({ response }, refresh) => {
          return response ? (
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={14}
              style={{ padding: "0.8rem" }}
            >
              <Grid style={{ margin: "0" }}>
                <Grid.Row columns={2} className="p-t-0">
                  <Grid.Column
                    mobile={14}
                    tablet={14}
                    computer={8}
                    className="m-b-1"
                    stretched
                  >
                    <Segment className="left-border-green">
                      {contactsEditable ? (
                        <UserContactsForm
                          onSubmitted={refresh}
                          userId={userId}
                          onClick={this.toggleContactsEditable}
                          data={response}
                        />
                      ) : (
                        <UserContactsReadOnly
                          data={response}
                          onClick={this.toggleContactsEditable}
                        />
                      )}
                    </Segment>
                  </Grid.Column>
                  <Grid.Column
                    mobile={14}
                    tablet={14}
                    computer={8}
                    className="m-b-1"
                    stretched
                  >
                    <Segment className="left-border-green">
                      {detailsEditable ? (
                        <UserDetailsForm
                          userId={userId}
                          data={response}
                          onClick={this.toggleDetailsEditable}
                          onSubmitted={refresh}
                        />
                      ) : (
                        <UserDetailsReadOnly
                          data={response}
                          onClick={this.toggleDetailsEditable}
                        />
                      )}
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          ) : (
            <PageLoader />
          );
        }}
      />
    );
  }
}

UserOverview.propTypes = {
  userId: PropTypes.string.isRequired
};

export default UserOverview;
