import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { addRelationsToList } from "../actions";

const AddRelationsModal = ({ onSubmitted }) => (
  <ModalBuilder
    withForm
    submitAction={addRelationsToList}
    onSubmitted={onSubmitted}
    renderTrigger={showModal => (
      <div className="dropdown-action-container" onClick={showModal}>
        Add Relations to List
      </div>
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Confirmation
        {...modalProps}
        header={"Add related companies"}
        cancelLabel="Cancel"
        submitLabel="Confirm"
        submitDisabled={
          !formProps.value.options || formProps.value.options.length < 1
        }
      >
        <p>Which related companies would you like to add?</p>
        <Form {...formProps}>
          <Form.CheckboxGroup
            field="options"
            options={[
              { text: "Ultimate Parent", value: "ultimateParent" },
              { text: "Parent", value: "parent" },
              { text: "Siblings", value: "siblings" },
              { text: "Children", value: "children" }
            ]}
            inline={true}
          />
        </Form>
      </Modal.Confirmation>
    )}
  />
);

export default AddRelationsModal;
