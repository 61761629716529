import React from "react";
import { Accordion } from "semantic-ui-react";
import ReadOnlyHeader from "./ReadOnlyHeader";

const CompanyFollowedCriteria = ({ active, handleClick, criteria }) => {
  return (
    <React.Fragment>
      <ReadOnlyHeader
        active={active}
        handleClick={handleClick}
        criteria={criteria}
        name="Following Company Criteria"
        text={
          criteria.companyFollowedSearch.isFollowingCompany
            ? "Only show followed company"
            : "Exclude all followed company"
        }
      />
      <Accordion.Content active={active}>
        {criteria.companyFollowedSearch.isFollowingCompany
          ? "Showing only companies being followed"
          : "Excluding all companies being followed"}
      </Accordion.Content>
    </React.Fragment>
  );
};
export default CompanyFollowedCriteria;
