import { UserTypes } from "constants/permissions";

export default [
  // id: 116650 - Removed
  // {
  //   id: "0",
  //   name: "Postal Areas",
  //   category: "geography",
  //   value: "postalAreasFilter",
  //   types: ["companies", "contacts"]
  // },
  {
    id: "1",
    name: "Company Size",
    category: "company",
    value: "sizeFilter",
    types: ["companies", "contacts"]
  },
  // id:78933
  // {
  //   id: "2",
  //   name: "Stock Exchange Indexes",
  //   category: "company",
  //   value: "stockExchangeIndexesFilter",
  //   types: ["companies", "contacts"]
  // },
  {
    id: "3",
    name: "Industries",
    category: "company",
    value: "industriesFilter",
    types: ["companies", "contacts"]
  },
  {
    id: "7",
    name: "Job Personas",
    category: "contact",
    value: "jobsFilter",
    types: ["contacts"]
  },
  {
    id: "4",
    name: "Languages",
    category: "contact",
    value: "languagesFilter",
    types: ["contacts"]
  },
  {
    id: "8",
    name: "Contact Custom Fields",
    category: "contact",
    value: "contactTagsFilter",
    types: ["contacts"],
    userType: UserTypes.External
  },
  {
    id: "9",
    name: "Company Custom Fields",
    category: "company",
    value: "customFieldsFilter",
    types: ["companies", "contacts"],
    userType: UserTypes.External
  },
  {
    id: "10",
    name: "Lists",
    category: "contact",
    value: "listsFilter",
    types: ["contacts"]
  },
  {
    id: "11",
    name: "Lists",
    category: "company",
    value: "listsFilter",
    types: ["companies"]
  },
  {
    id: "12",
    name: "Uploads",
    category: "contact",
    value: "contactUploadsFilter",
    types: ["contacts"]
  },
  {
    id: "13",
    name: "Uploads",
    category: "company",
    value: "companyUploadsFilter",
    types: ["companies", "contacts"]
  },
  {
    id: "14",
    name: "Following",
    category: "contact",
    value: "contactFollowedFilter",
    types: ["contacts"],
    userType: UserTypes.External
  },
  {
    id: "15",
    name: "Following",
    category: "company",
    value: "companyFollowedFilter",
    types: ["companies", "contacts"],
    userType: UserTypes.External
  },
  {
    id: "16",
    name: "Lists",
    category: "company",
    value: "contactsInCompanyListsFilter",
    types: ["contacts"]
  },
  {
    id: "17",
    name: "Email & Telephone",
    category: "contact",
    value: "emailDdiFilter",
    types: ["contacts"]
  }
];
