import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { createTag } from "./actions";

const CreateCustomerTag = ({ customerId, onSubmitted }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={createTag}
      submitParams={customerId}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <Button primary content="Add Custom Field" onClick={showModal} />
      )}
      renderModal={(modalProps, formProps) => (
        <Modal.Add
          {...modalProps}
          header={
            <div>
              <Icon name="plus" /> Add Custom Field
            </div>
          }
          submitLabel="Add Field"
          submitDisabled={!formProps.value.name}
          size="tiny"
        >
          <Form {...formProps}>
            <Form.Input field="name" label="Name" required fluid />
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default CreateCustomerTag;
