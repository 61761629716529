import { Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Icon } from "semantic-ui-react";
import { migrateCustomer } from "./actions";

const MigrateCustomer = ({ id, renderTrigger, onSubmitted }) => {
  return (
    <ModalBuilder
      submitAction={migrateCustomer}
      submitParams={id}
      onSubmitted={onSubmitted}
      renderTrigger={renderTrigger}
      renderModal={(modalProps, formProps) => (
        <Modal.Confirmation
          submitLabel="Migrate"
          cancelLabel="Cancel"
          {...modalProps}
          header={
            <div>
              <Icon name="sync" /> Migrate Customer
            </div>
          }
        >
          <p>Customer will be migrated into the system. </p>
          <p>
            Any new users created will have registration emails sent to them.
          </p>
        </Modal.Confirmation>
      )}
    />
  );
};

export default MigrateCustomer;
