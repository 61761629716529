import { ListBuilder } from "@redriver/cinnamon";
import React from "react";
import { Button, Grid, Segment } from "semantic-ui-react";
import { getLists } from "./actions";
import FilterLists from "./FilterLists";
import ListCard from "./ListCard";
import RecentlyViewed from "./RecentlyViewed";

const ListLists = () => (
  <ListBuilder
    dataTransform={data => data.results}
    totalTransform={data => data.totalResults}
    initialFilters={{
      sortBy: "0",
      types: ["contacts", "companies"],
      allLists: "true"
    }}
    initialPagination={{
      pageSize: 12,
      pageNumber: 1
    }}
    loadAction={getLists}
    renderList={(
      data,
      { filters, pagination, total, hasMore, loadingMore },
      events
    ) => (
      <Grid
        className="i4b-list-page user-page"
        style={{ paddingTop: "0.8rem" }}
      >
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="m-b-1"
            stretched
          >
            <Segment className="left-border-green">
              <FilterLists
                filters={filters}
                onChangeFilters={events.onChangeFilters}
                pageSize={pagination.pageSize}
                onPageSizeChange={events.onChangePagination}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <RecentlyViewed />
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="m-b-1"
            stretched
          >
            <h2>All Lists</h2>
            <Grid>
              <Grid.Row columns={3}>
                {data.map((a, i) => (
                  <Grid.Column key={a.id}>
                    <ListCard {...a} />
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>

            {hasMore && (
              <Button
                className="i4b-notification-list-loader"
                loading={loadingMore}
                onClick={events.onLoadMore}
              >
                {loadingMore ? "Loading more lists..." : "Show More"}
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )}
  />
);

export default ListLists;
