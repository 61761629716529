import React from "react";
import { Route, Switch } from "react-router-dom";

import { AppRoutes } from "constants/routes";
import ListNotificationsPage from "./ListNotificationsPage";

export default () => (
  <React.Fragment>
    <Switch>
      <Route
        exact
        path={AppRoutes.Notifications}
        component={ListNotificationsPage}
      />
    </Switch>
  </React.Fragment>
);
