import {
  requestError,
  requestIsBusy,
  requestIsSlow,
  requestIsSuccess,
  requestResponse
} from "@redriver/cinnamon";
import { START_FLOW, VALIDATE_FLOW } from "./actions";

const initialState = {
  loggingIn: false,
  slowRequest: null,
  error: null,
  validateResponse: {},
  validateSuccess: false,
  validating: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_FLOW:
      return {
        ...state,
        loggingIn: requestIsBusy(action),
        slowRequest: requestIsSlow(action),
        error: requestError(action)
      };

    case VALIDATE_FLOW:
      return {
        ...state,
        validateSuccess: requestIsSuccess(action),
        validating: requestIsBusy(action),
        validateResponse: requestResponse(action)
      };

    default:
      return state;
  }
};
