import { apiDelete, apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/LIST_CUSTOM_VALUES";

export const ActionTypes = {
  CreateCustomValues: `${NAMESPACE}/CREATE_CUSTOM_VALUES`,
  ViewCustomValues: `${NAMESPACE}/VIEW_CUSTOM_VALUES`,
  EditCustomValue: `${NAMESPACE}/EDIT_TAG`,
  DeleteCustomValue: `${NAMESPACE}/DELETE_TAG`
};

export const getCustomFieldValues = (
  { pagination, filters },
  { customerId }
) => dispatch => {
  const { pageSize, pageNumber } = pagination;
  const { search } = filters;
  return dispatch(
    apiGet(
      ActionTypes.ViewCustomValues,
      `customers/${customerId}/custom-values`,
      {
        pageSize,
        pageNumber,
        search
      }
    )
  );
};

export const createCustomFieldValue = (formData, { customerId }) =>
  apiPost(
    ActionTypes.CreateCustomValues,
    `customers/${customerId}/custom-values`,
    {
      customFieldId: formData.name,
      value: formData.value
    }
  );

export const editCustomFieldValue = (value, id) =>
  apiPut(ActionTypes.EditCustomValue, `customers/custom-values/${id}`, value);

export const deleteCustomFieldValue = id =>
  apiDelete(ActionTypes.DeleteCustomValue, `customers/custom-values/${id}`);
