import { PermissionCheck } from "components/auth";
import { DownloadButton, SearchResultsWrapper } from "components/search";
import { Actions, Targets } from "constants/permissions";
import {
  CompaniesActionsDropdown,
  CompaniesFilters,
  CompaniesResultsTable
} from "features/Companies";
import {
  addSuppressions,
  clearSelected,
  getCompanies,
  getCustomerId,
  getDownload,
  getDownloadCount,
  updatePageNumber
} from "features/Companies/CompaniesSearch/actions";
import { getCompaniesSearchState } from "features/Companies/CompaniesSearch/selectors";
import { UpdateListBreadcrumb } from "features/Lists/lookups";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid } from "semantic-ui-react";

class CompaniesSearchPage extends React.Component {
  componentDidMount() {
    const { getCustomerId, clearSelected } = this.props;
    getCustomerId();
    clearSelected();
  }

  render() {
    const {
      companies,
      pageSize,
      pageNumber,
      updatePageNumber,
      selectedItems,
      pageChanged,
      selection,
      customerId,
      formData,
      getCompanies,
      getDownloadCount,
      getDownload,
      isSegmented,
      viewingUniverse,
      match: {
        params: { listId }
      }
    } = this.props;

    return (
      <Grid className="search-page-grid" stackable>
        <Grid.Column className="left-column">
          {listId && <UpdateListBreadcrumb listId={listId} />}
          <CompaniesFilters listId={listId} />
        </Grid.Column>
        <Grid.Column className="right-column colour-expanded-table">
          <SearchResultsWrapper
            updatePageNumber={updatePageNumber}
            data={companies}
            pageNumber={pageNumber}
            pageSize={pageSize}
            actionsDropdown={
              <div className="i4b-search-actions-wrapper">
                <PermissionCheck
                  target={Targets.Company}
                  action={Actions.Download}
                >
                  <DownloadButton
                    loadCount={getDownloadCount}
                    download={getDownload}
                    isContact={false}
                    isPreview={viewingUniverse}
                    disabled={companies.totalResults == 0}
                  />
                </PermissionCheck>
                <CompaniesActionsDropdown
                  selectedItems={selectedItems}
                  selection={selection}
                  formData={formData}
                  customerId={customerId}
                  addSuppressions={addSuppressions}
                  getCompanies={() => getCompanies(true, false)}
                  isSegmented={isSegmented}
                  viewingUniverse={viewingUniverse}
                />
              </div>
            }
            isCompaniesPage
            listId={listId}
            pageChanged={pageChanged}
          >
            <CompaniesResultsTable listId={listId} />
          </SearchResultsWrapper>
        </Grid.Column>
      </Grid>
    );
  }
}

const actions = {
  updatePageNumber,
  getCustomerId,
  getCompanies,
  getDownloadCount,
  getDownload,
  clearSelected
};

const mapStateToProps = state => {
  const {
    formData,
    companies,
    pageNumber,
    pageSize,
    selectedItems,
    pageChanged,
    selection,
    customerId,
    isSegmented,
    isUniverse
  } = getCompaniesSearchState(state);
  return {
    formData,
    companies,
    pageNumber,
    pageSize,
    selectedItems,
    pageChanged,
    selection,
    customerId,
    isSegmented,
    viewingUniverse: isUniverse
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(CompaniesSearchPage)
);
