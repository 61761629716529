import { Form, Format, lookupRequest, lookupReset } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { FilterBase } from "components/search";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes } from "constants/routes";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Grid, Icon, Label, Popup, Segment } from "semantic-ui-react";
import { ListDownloadStatuses } from "../lookups";
import {
  applyPendingChanges,
  deleteList,
  loadOverviewModal,
  renameList,
  updateForm
} from "./actions";
import DeleteListModal from "./ListSummary/DeleteListModal";
import RenameListModal from "./ListSummary/RenameListModal";
import { getListsOverviewState } from "./selectors";

class ListSummary extends React.Component {
  state = {
    visible: true,
    selectedItems: null,
    summaryActionsVisible: false,
    renameListModalVisible: false,
    deleteListModalVisible: false
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  toggleSummaryActions = () =>
    this.setState({ summaryActionsVisible: !this.state.summaryActionsVisible });

  toggleRenameListModal = () => {
    return this.setState({
      renameListModalVisible: !this.state.renameListModalVisible
    });
  };

  toggleDeleteListModal = () =>
    this.setState({
      deleteListModalVisible: !this.state.deleteListModalVisible
    });

  updateName = () => {
    const { lookupReset, lookupRequest, listId } = this.props;
    lookupReset("basicListLookup", listId);
    lookupRequest("basicListLookup", listId);
    this.toggleRenameListModal();
  };

  render() {
    const {
      visible,
      summaryActionsVisible,
      renameListModalVisible,
      deleteListModalVisible
    } = this.state;
    const {
      name,
      isContactList,
      listId,
      createdByName,
      createdUtc,
      totalResults,
      pendingChanges,
      applyPendingChanges,
      totalPendingAdditions,
      totalPendingRemovals,
      totalPendingUpdates,
      totalWithEmail,
      totalWithoutEmail,
      totalWithPhone,
      totalWithoutPhone,
      formData,
      updateForm,
      history,
      canHaveUpdates,
      downloadableTotal,
      total
    } = this.props;

    const title =
      totalPendingUpdates === 0 ? (
        "List Summary"
      ) : (
        <React.Fragment>
          <span>List Summary</span>
          <Icon name="exclamation circle" className="header-icon-warning" />
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <Segment className="m-t-1" style={{ padding: 0, width: "100%" }}>
          <section className={`search-filter`}>
            <FilterBase.Header
              title={title}
              collapsible
              collapsed={!visible}
              onCollapseToggle={this.toggleVisibility}
              removable={false}
            />
            <FilterBase.Body collapsed={!visible}>
              <div className="list-summary-container">
                <div className="list-button-container">
                  <div className="list-summary-top">
                    <span>
                      Created by <strong>{createdByName}</strong> on&nbsp;
                    </span>
                    <strong>
                      <Format.Date value={createdUtc} format={"medium"} />
                    </strong>
                    <div
                      className="list-actions"
                      onClick={this.toggleSummaryActions}
                    >
                      <span>List Actions</span>
                      <div className="action-button-container">
                        <Popup
                          className="list-summary-actions-popup"
                          basic
                          open={summaryActionsVisible}
                          position="bottom left"
                          horizontalOffset={-20}
                          trigger={
                            <Button
                              onClick={this.toggleActionMenu}
                              className={`actions-dropdown-button-small ${
                                summaryActionsVisible
                                  ? `close`
                                  : `some-selected`
                              }`}
                            />
                          }
                          content={
                            <div className="list-summary-actions">
                              <PermissionCheck
                                target={Targets.List}
                                action={Actions.Edit}
                              >
                                <div onClick={this.toggleRenameListModal}>
                                  Change list name
                                </div>
                              </PermissionCheck>
                              <PermissionCheck
                                target={Targets.List}
                                action={Actions.Delete}
                              >
                                <div onClick={this.toggleDeleteListModal}>
                                  Delete list
                                </div>
                              </PermissionCheck>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Label className="companies">
                      <span>
                        {totalResults}{" "}
                        {isContactList ? " Contacts" : " Companies"}
                      </span>
                    </Label>
                    {canHaveUpdates && (
                      <Label className="pending">
                        {totalPendingUpdates} Updates pending
                        <Popup
                          trigger={<Icon name="info circle" />}
                          content={
                            totalPendingUpdates
                              ? `${totalPendingUpdates} updates have been detected (${totalPendingAdditions} additions and ${totalPendingRemovals} removals)`
                              : "Updates to lists are automatically detected. These can be applied by clicking the button on the right-hand side."
                          }
                        />
                        {totalPendingUpdates > 0 && (
                          <Button
                            className="primary green-small-button"
                            content="Apply Pending Changes"
                            onClick={() => applyPendingChanges(listId)}
                            loading={pendingChanges.loading}
                            disabled={pendingChanges.loading}
                          />
                        )}
                      </Label>
                    )}
                  </div>
                </div>
                {isContactList && (
                  <Form
                    value={formData}
                    onChange={updateForm}
                    style={{ width: "100%" }}
                  >
                    <Grid columns={2}>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <label className="checkbox-header">Email</label>
                          <Form.Checkbox
                            field="includeContactsWithEmail"
                            label={`With (${
                              totalWithEmail ? totalWithEmail.contacts : 0
                            } contacts at ${
                              totalWithEmail ? totalWithEmail.companies : 0
                            } companies)`}
                          />
                          <Form.Checkbox
                            field="includeContactsWithoutEmail"
                            label={`Without (${
                              totalWithoutEmail ? totalWithoutEmail.contacts : 0
                            } contacts at ${
                              totalWithoutEmail
                                ? totalWithoutEmail.companies
                                : 0
                            } companies)`}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <label className="checkbox-header">Phone</label>
                          <Form.Checkbox
                            field="includeContactsWithPhone"
                            label={`With (${
                              totalWithPhone ? totalWithPhone.contacts : 0
                            } contacts at ${
                              totalWithPhone ? totalWithPhone.companies : 0
                            } companies)`}
                          />
                          <Form.Checkbox
                            field="includeContactsWithoutPhone"
                            label={`Without (${
                              totalWithoutPhone ? totalWithoutPhone.contacts : 0
                            } contacts at ${
                              totalWithoutPhone
                                ? totalWithoutPhone.companies
                                : 0
                            } companies)`}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                )}
                <Form
                  value={formData}
                  onChange={updateForm}
                  style={{ width: "100%" }}
                >
                  <Form.Group widths="equal" style={{ alignItems: "center" }}>
                    <Grid columns={2}>
                      <Grid.Row columns={2}>
                        <Grid.Column className="list-filters-radiogroup">
                          <Form.RadioGroup
                            fluid
                            field="downloadable"
                            label={
                              <label className="checkbox-header">
                                Downloadable Status
                              </label>
                            }
                            options={[
                              {
                                text: `Downloadable (${downloadableTotal})`,
                                value: "true"
                              },
                              {
                                text: `Not Downloadable (${total -
                                  downloadableTotal})`,
                                value: "false"
                              }
                            ]}
                            inline
                          />
                        </Grid.Column>

                        <Grid.Column>
                          <ListDownloadStatuses
                            disabled={
                              formData.downloadable === null ||
                              formData.downloadable === undefined ||
                              formData.downloadable !== "false"
                            }
                            isContactList={isContactList}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Group>
                </Form>
              </div>
            </FilterBase.Body>
          </section>
        </Segment>
        {/* We cannot use the renderTrigger because this does not work in Semantic UI Popups */}
        {renameListModalVisible && (
          <RenameListModal
            loadAction={loadOverviewModal}
            loadParams={listId}
            loadTransform={data => {
              return { name: data.name };
            }}
            submitParams={{ listId: listId }}
            submitAction={renameList}
            toggleVisibility={this.toggleRenameListModal}
            onSubmitted={this.updateName}
          />
        )}

        {deleteListModalVisible && (
          <DeleteListModal
            submitAction={deleteList}
            submitParams={{ listId: listId }}
            onDelete={() => history.push(AppRoutes.Lists)}
            toggleVisibility={this.toggleDeleteListModal}
            name={name}
          />
        )}
      </React.Fragment>
    );
  }
}

const actions = {
  applyPendingChanges,
  lookupReset,
  lookupRequest,
  updateForm
};

const mapStateToProps = state => {
  const {
    name,
    isContactList,
    listId,
    createdByName,
    createdUtc,
    totalResults,
    pendingChanges,
    totalPendingAdditions,
    totalPendingRemovals,
    totalPendingUpdates,
    totalWithEmail,
    totalWithoutEmail,
    totalWithPhone,
    totalWithoutPhone,
    formData,
    canHaveUpdates,
    total,
    downloadableTotal
  } = getListsOverviewState(state);
  return {
    name,
    isContactList,
    listId,
    createdByName,
    createdUtc,
    totalResults,
    pendingChanges,
    totalPendingAdditions,
    totalPendingRemovals,
    totalPendingUpdates,
    totalWithEmail,
    totalWithoutEmail,
    totalWithPhone,
    totalWithoutPhone,
    formData,
    canHaveUpdates,
    total,
    downloadableTotal
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(ListSummary)
);
