import { Form } from "@redriver/cinnamon";
import { isJobTitleEmpty, isJobTitlesEmpty } from "features/Utils";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "semantic-ui-react";
import FilterBase from "./FilterBase";
import JobTitlesSummary from "./JobTitlesSummary";
import { CalculateFilterCount } from "./utils/CalculateFilterCount";

class JobTitlesFilter extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    formData: PropTypes.object.isRequired,
    results: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    pageChanged: PropTypes.bool.isRequired,
    removeFilter: PropTypes.func.isRequired
  };

  state = {
    visible: true,
    total: null
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { pageChanged, results, id } = nextProps;
    return CalculateFilterCount(results, pageChanged, id, state.total);
  }
  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  shouldDisableJobTitlesArrayAdder = titles => {
    if (!titles) return true;

    return titles.some(isJobTitleEmpty);
  };

  render() {
    const {
      id,
      formData,
      loading,
      pageChanged,
      removeFilter,
      clearJobTitlesFilter
    } = this.props;
    const { visible, total } = this.state;
    const { searchCriteria } = formData;
    const jobTitlesObj = searchCriteria.find(el => el.id == id);
    const { jobTitles } = jobTitlesObj.jobTitlesFilter;

    return (
      <FilterBase
        total={total}
        loading={loading && !pageChanged}
        className="job-titles-filter"
      >
        <FilterBase.Header
          title="Job Titles"
          collapsible
          collapsed={!visible}
          onCollapseToggle={this.toggleVisibility}
          removable
          onRemove={() => removeFilter(id)}
        >
          {!visible && (
            <div className="ellipsis-paragraph">
              {isJobTitlesEmpty(jobTitles) ? (
                <span>No titles selected</span>
              ) : (
                <JobTitlesSummary data={jobTitlesObj.jobTitlesFilter} />
              )}
            </div>
          )}
          {!isJobTitlesEmpty(jobTitles) && (
            <div
              className="i4b-filter-clear"
              onClick={e => clearJobTitlesFilter(id, e)}
            >
              <a>(Clear)</a>
            </div>
          )}
        </FilterBase.Header>
        <FilterBase.Body collapsed={!visible}>
          <Form.Object field="jobTitlesFilter">
            <div className="job-titles-filter-container">
              <div className="match-container">
                <label>Match results that</label>
                <Form.RadioGroup
                  field="jobTitlesMatch"
                  inline
                  options={[
                    {
                      value: "contains",
                      text: "Contain"
                    },
                    {
                      value: "begins",
                      text: "Begin With"
                    },
                    {
                      value: "equals",
                      text: "Equal"
                    }
                  ]}
                />
                <label>your criteria.</label>
              </div>
              <div className="job-titles-array">
                <Form.Array
                  field="jobTitles"
                  arrayKey="id"
                  propagateUpdates="always"
                >
                  <div>
                    <Form.Input field="title" label="Job Title" inline />
                    <Form.ArrayRemover>
                      <Icon name="trash" />
                    </Form.ArrayRemover>
                  </div>
                </Form.Array>
                <Form.ArrayAdder
                  field="jobTitles"
                  arrayKey="id"
                  className="secondary"
                  disabled={this.shouldDisableJobTitlesArrayAdder(jobTitles)}
                >
                  Add Another Job Title
                </Form.ArrayAdder>
              </div>
            </div>
          </Form.Object>
        </FilterBase.Body>
      </FilterBase>
    );
  }
}

export default JobTitlesFilter;
