// bundle related reducers
import { combineReducers } from "redux";
import { reducer as activeSessions } from "./ActiveSessions";
import { reducer as emailReverified } from "./EmailReverified";
import { reducer as emailVerification } from "./EmailVerification";
import { reducer as login } from "./Login";
import { reducer as logout } from "./Logout";
import { reducer as oauthLogin } from "./OAuthLogin";
import { reducer as passwordChange } from "./PasswordChange";
import { reducer as passwordForgotten } from "./PasswordForgotten";
import { reducer as passwordReset } from "./PasswordReset";
import { reducer as reverifyEmail } from "./ReverifyEmail";
import { reducer as userSetup } from "./UserSetup";

export default combineReducers({
  login,
  logout,
  passwordChange,
  passwordForgotten,
  passwordReset,
  emailVerification,
  userSetup,
  reverifyEmail,
  emailReverified,
  activeSessions,
  oauthLogin
});
