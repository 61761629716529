import React from "react";
import { Button } from "semantic-ui-react";
import { Form, FormBuilder, Format, ErrorMessage } from "@redriver/cinnamon";
import { updateUserDetails } from "./actions";

const UserDetailsForm = ({ data, onSubmitted, userId, onClick }) => {
  return (
    <FormBuilder
      submitAction={updateUserDetails}
      submitParams={userId}
      onSubmitted={onSubmitted}
      initialData={data}
      renderError={false}
      renderForm={(formProps, state, events) => (
        <Form {...formProps} className="user-details-form">
          <div className="flex">
            <h2 className="m-r-1">User Details</h2>
            <Button
              className="grey-small-button"
              content="Cancel"
              onClick={onClick}
            />
            <Button
              className="primary green-small-button"
              content="Save"
              onClick={events.onSubmit}
              loading={state.submitting}
            />
          </div>
          <section className="flex m-t-05">
            <div
              className="m-r-2"
              style={{ textAlign: "right", maxWidth: "100px" }}
            >
              <p>Created</p>
              <p>Training Date</p>
              <p>User ID</p>
              <p>Customer ID</p>
            </div>
            <div>
              <p className="font-weight-bold">
                <Format.DateTime value={data.createdUtc} format="short" />
              </p>
              <Form.Group widths="equal">
                <Form.Date
                  local
                  field="trainingDate"
                  placeholder="Select a Date"
                  fluid
                />
                <Form.Input field="trainer" placeholder="Trainer" fluid />
              </Form.Group>
              <p className="font-weight-bold">{data.originalId}</p>
              <p className="font-weight-bold">{data.customerOriginalId}</p>
            </div>
          </section>
          <ErrorMessage error={state.error} />
        </Form>
      )}
    />
  );
};

export default UserDetailsForm;
