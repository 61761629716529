import { ErrorMessage, Form, PageHeader } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import qs from "qs";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Message } from "semantic-ui-react";
import { getOAuthLoginState } from "../selectors";
import { startFlow, validateEmail, validateFlow } from "./actions";

class OAuthLogin extends React.Component {
  static propTypes = {
    emailVerified: PropTypes.bool,
    useEmailVerification: PropTypes.bool,
    loggingIn: PropTypes.bool,
    slowRequest: PropTypes.bool,
    error: PropTypes.object
  };

  state = {
    username: "",
    password: "",
    remember: false,
    validated: false,
    showErrors: false,
    forgotLinkVisible: true,
    validationFailed: false,
    validationError: null,
    manualError: null,
    showReverifyMessage: false
  };

  componentDidMount() {
    const oauthParams = qs.parse(this.props.location.search.substr(1));
    this.props.validateFlow(oauthParams);
  }

  componentDidUpdate = prevProps => {
    const { error, validateResponse } = this.props;

    if (
      validateResponse &&
      prevProps.validateResponse != validateResponse &&
      !validateResponse.isValid
    ) {
      this.setState({
        validationFailed: true,
        validationError: validateResponse ? validateResponse.error : ""
      });
    }
  };

  onFormChange = (field, data) => this.setState({ [field]: data });

  onFormValidated = validated => this.setState({ validated });

  startFlow = e => {
    e.preventDefault();
    if (!this.state.validated) {
      this.setState({ showErrors: true });
      return;
    }
    const oauthParams = qs.parse(this.props.location.search.substr(1));
    const { username, password } = this.state;
    this.props.validateEmail(username).then(result => {
      if (result.response && result.response.emailExists) {
        this.setState({ manualError: null });
        this.props
          .startFlow(username, password, oauthParams)
          .then(flowResult => {
            if (flowResult.error && flowResult.error.result[0].code === 2040) {
              this.setState({ showReverifyMessage: true });
              return;
            }
            window.location = flowResult.response.redirectUri;
          });
      } else {
        if (!result.response.emailExists) {
          this.setState({
            manualError:
              "User with given username does not exist or is deleted (4003)"
          });
        }
      }
    });
  };

  render() {
    const {
      loggingIn,
      slowRequest,
      error,
      validateResponse,
      validating
    } = this.props;
    const {
      validationFailed,
      validationError,
      manualError,
      showReverifyMessage
    } = this.state;

    if (validating) {
      return <p>Validating request</p>;
    }

    if (validationFailed) {
      return (
        <p>
          Login request failed to pass validation with error:
          <br />
          {validationError}
        </p>
      );
    }

    const disabled = loggingIn && slowRequest;

    return (
      <React.Fragment>
        <PageHeader>
          Login
          {validateResponse &&
            validateResponse.appName &&
            ` with ${validateResponse.appName}`}
        </PageHeader>

        <Form
          value={this.state}
          onChange={this.onFormChange}
          onValidated={this.onFormValidated}
          showErrors={this.state.showErrors}
        >
          <Form.Email
            fluid
            field="username"
            label="Email"
            icon="mail"
            iconPosition="left"
            placeholder="Enter email address"
            disabled={disabled}
            autoFocus
            required
          />
          <Form.Password
            fluid
            field="password"
            label="Password"
            icon="lock"
            iconPosition="left"
            placeholder="Enter password"
            disabled={disabled}
            required
          />
          <Button
            primary
            fluid
            onClick={this.startFlow}
            disabled={disabled}
            loading={loggingIn && slowRequest}
            className="system-button"
          >
            Sign in
          </Button>
          <ErrorMessage
            header="Login Error"
            error={manualError !== null && !error ? manualError : null}
          />
          {showReverifyMessage && (
            <Message negative>
              <Message.Header>Login Error</Message.Header>
              <p>
                Your email address needs to be verified, log in to the main web
                application to request a verification email{" "}
                <a href={process.env.BASE_URL} target="_blank">
                  Click here
                </a>
              </p>
            </Message>
          )}
          {!showReverifyMessage && (
            <ErrorMessage
              header="Login Error"
              error={
                error &&
                Array.isArray(error.result) &&
                error.result.every(x => x.code !== 4001)
                  ? error
                  : null
              }
            />
          )}
        </Form>
      </React.Fragment>
    );
  }
}

const actions = {
  startFlow,
  validateFlow,
  validateEmail
};

const mapStateToProps = state => {
  const {
    loggingIn,
    slowRequest,
    error,
    validateResponse,
    validateSuccess,
    validating
  } = getOAuthLoginState(state);

  return {
    loggingIn,
    slowRequest,
    error,
    validateResponse,
    validateSuccess,
    validating
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(OAuthLogin)
);
