import { ActionBar, Form, FormBuilder, PageSize } from "@redriver/cinnamon";
import React from "react";
import { addSuppressions } from "../../../Contacts/ContactsSearch/actions";
import ContactsActionsDropdown from "../../../Contacts/ContactsSearch/ContactsActionsDropdown";
import {
  addContactTag,
  addToList,
  clearTags,
  saveListCompanyContacts
} from "../actions";

const FilterContacts = ({
  filters,
  onChangeFilters,
  pageSize,
  onPageSizeChange,
  searchCriteria,
  selection,
  total,
  onSubmitted,
  customerId,
  companyId,
  excludeCompanyName
}) => {
  return (
    <ActionBar className="company-contact-filters">
      <ActionBar.Item>
        <FormBuilder
          initialData={filters.textFilter}
          onChange={textFilter =>
            onChangeFilters({
              ...filters,
              textFilter,
              excludeCompanyName
            })
          }
          renderForm={formProps => (
            <Form {...formProps}>
              <Form.Group inline compact>
                <Form.Input
                  field="filter"
                  placeholder="Search contacts…"
                  width={25}
                  style={{ height: "2.714em" }}
                />
              </Form.Group>
            </Form>
          )}
        />
      </ActionBar.Item>
      <ActionBar.Item className="persistent-filters" fluid>
        <FormBuilder
          initialData={filters.reduxFilter}
          onChange={reduxFilter =>
            onChangeFilters({
              ...filters,
              reduxFilter
            })
          }
          renderForm={formProps => (
            <Form {...formProps}>
              <Form.Group inline compact>
                <Form.Dropdown
                  placeholder="LOB Roles…"
                  fluid
                  field="lobRoles"
                  search
                  lookup="topLevelLOBRolesDropdownLookup"
                  multiple
                />

                <Form.Dropdown
                  placeholder="Technology Roles…"
                  fluid
                  field="techRoles"
                  search
                  lookup="topLevelTechRolesDropdownLookup"
                  multiple
                />

                <Form.RadioGroup
                  field="left"
                  inline
                  options={[
                    { value: "false", text: "Active" },
                    { value: "true", text: "Left Company" }
                  ]}
                />
              </Form.Group>
            </Form>
          )}
        />
      </ActionBar.Item>
      <ActionBar.Item fluid>
        <div className="i4b-search-actions-wrapper">
          <ContactsActionsDropdown
            selectedItems={
              selection.allSelected
                ? total - selection.deselected.length
                : selection.selected.length
            }
            selection={selection}
            formData={{ searchCriteria }}
            addToList={addToList}
            addContactTag={addContactTag}
            clearTags={clearTags}
            saveList={saveListCompanyContacts}
            customerId={customerId}
            addSuppressions={addSuppressions}
            onSubmitted={onSubmitted}
            companyId={companyId}
            forceDumbList={true}
            isUniverse={false}
          />
        </div>
      </ActionBar.Item>
      {pageSize && (
        <ActionBar.Item className="i4b-filter-pagesize">
          <PageSize
            value={pageSize}
            onChange={x => onPageSizeChange({ pageSize: x, pageNumber: 1 })}
            options={[10, 25, 50, 100]}
          />
        </ActionBar.Item>
      )}
    </ActionBar>
  );
};

export default FilterContacts;
