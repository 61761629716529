import { ActionBar, ListBuilder } from "@redriver/cinnamon";
import ChatImage from "assets/chat.svg";
import EmailImage from "assets/email.svg";
import { UserTypeCheck } from "components/auth";
import { Tile, TileGrid } from "components/tiles";
import { UserTypes } from "constants/permissions";
import React from "react";
import { Button, Icon, Image, Popup } from "semantic-ui-react";
import { getKnowledge } from "./actions";
import FilterKnowledge from "./FilterKnowledge";
import PreviewKnowledgeItem from "./PreviewKnowledgeItem";
import SendFeedback from "./SendFeedback";

const KnowledgeBase = () => (
  <ListBuilder
    initialData={[]}
    loadAction={getKnowledge}
    renderList={(data, state, events) => (
      <React.Fragment>
        <ActionBar className="knowledge-action-bar">
          <ActionBar.Item className="knowledge-search">
            <h1>Knowledge Base</h1>
            <FilterKnowledge
              filters={state.filters}
              onChangeFilters={events.onChangeFilters}
            />
          </ActionBar.Item>
          <ActionBar.Item className="knowledge-contact" />
          <ActionBar.Item className="knowledge-contact">
            <Image src={EmailImage} />
            <a href="mailto:support@i4b.com">Email us at support@i4b.com</a>
            <Icon name="chevron right" />
          </ActionBar.Item>
        </ActionBar>
        {data && data.length ? (
          <TileGrid
            dataKey="id"
            className="knowledge-tile-grid"
            data={data}
            padding="large"
            breakpoints={[750, 1100, 1500]}
            render={item => (
              <PreviewKnowledgeItem
                itemId={item.id}
                title={item.name}
                link={item.link}
                description={item.description}
                download={item.type === "PDF"}
                renderTrigger={showModal => (
                  <Tile className="knowledge-tile" onClick={showModal}>
                    <section>
                      <Popup
                        content={item.name}
                        trigger={
                          <h2 style={{ marginBottom: "0.5rem" }}>
                            {item.name}
                          </h2>
                        }
                      />
                      <div
                        style={{ marginBottom: "0.5rem", fontSize: "0.8rem" }}
                      >
                        {item.description}
                      </div>
                      <small>{item.type}</small>
                      <Icon name="chevron right" />
                    </section>
                  </Tile>
                )}
              />
            )}
          />
        ) : (
          <section className="no-knowledge-results">
            <UserTypeCheck restrictTo={UserTypes.Internal}>
              <h2>No results</h2>
            </UserTypeCheck>
            <UserTypeCheck restrictTo={UserTypes.External}>
              <h2>No results. Not found what you are looking for?</h2>
              <h2>Let us know below:</h2>
              <SendFeedback
                renderTrigger={showModal => (
                  <Button primary content="Send Feedback" onClick={showModal} />
                )}
              />
            </UserTypeCheck>
          </section>
        )}
      </React.Fragment>
    )}
  />
);

export default KnowledgeBase;
