import {
  AuthenticatedSwitch,
  NavigationLogger,
  PrivateRoute,
  PublicRoute,
  NotificationStack
} from "@redriver/cinnamon";
import { SystemRoutes, AppRoutes } from "constants/routes";
import React from "react";
import { Route } from "react-router-dom";
import "styles/index.scss";
import I4BApp from "./I4BApp";
import AccessDeniedPage from "./system/AccessDeniedPage";
import ActiveSessionsPage from "./system/ActiveSessionsPage";
import EmailReVerifiedPage from "./system/EmailReVerifiedPage";
import EmailVerificationPage from "./system/EmailVerificationPage";
import LoginPage from "./system/LoginPage";
import LoginTermsPage from "./system/LoginTermsPage";
import LogoutPage from "./system/LogoutPage";
import OAuthLoginPage from "./system/OAuthLoginPage";
import PasswordChangePage from "./system/PasswordChangePage";
import PasswordForgottenPage from "./system/PasswordForgottenPage";
import PasswordResetPage from "./system/PasswordResetPage";
import ReverificationPage from "./system/ReverificationPage";
import UserSetupPage from "./system/UserSetupPage";
import UserResetPage from "./system/UserResetPage";
import { customRenderEvent } from "./I4BNotifications";

/**
 * Top-level app routes only
 * Define sub-path routes inside top-level page components
 */
const Shell = () => (
  <React.Fragment>
    <NavigationLogger />
    <NotificationStack renderEvent={customRenderEvent} />
    <AuthenticatedSwitch>
      {/* system routes */}
      <Route exact path={SystemRoutes.Login} component={LoginPage} />
      <Route exact path={SystemRoutes.OAuthLogin} component={OAuthLoginPage} />
      <Route exact path={SystemRoutes.Terms} component={LoginTermsPage} />
      <Route exact path={SystemRoutes.Logout} component={LogoutPage} />
      <Route exact path={SystemRoutes.SetupUser} component={UserSetupPage} />
      <Route
        exact
        path={SystemRoutes.VerifyEmail}
        component={EmailVerificationPage}
      />
      <Route
        exact
        path={SystemRoutes.VerifiedEmail}
        component={EmailReVerifiedPage}
      />
      <PublicRoute
        exact
        path={SystemRoutes.ResetUser}
        component={UserResetPage}
      />
      <PublicRoute
        exact
        path={SystemRoutes.ForgottenPassword}
        component={PasswordForgottenPage}
      />
      <PublicRoute
        exact
        path={SystemRoutes.AccessDenied}
        component={AccessDeniedPage}
      />
      <PublicRoute
        exact
        path={SystemRoutes.ResetPassword}
        component={PasswordResetPage}
      />
      <PrivateRoute
        exact
        path={SystemRoutes.ChangePassword}
        component={PasswordChangePage}
        redirect={SystemRoutes.Login}
      />

      {/* app routes */}
      <PrivateRoute
        path={SystemRoutes.Reverify}
        component={ReverificationPage}
      />
      <PrivateRoute
        path={SystemRoutes.ActiveSessions}
        component={ActiveSessionsPage}
      />
      <PrivateRoute component={I4BApp} redirect={SystemRoutes.Login} />
    </AuthenticatedSwitch>
  </React.Fragment>
);

export default Shell;
