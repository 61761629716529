import { apiDelete, apiGet, apiPost, mockError } from "@redriver/cinnamon";

const NAMESPACE = "SECURE_FILE_AREA";

export const ActionTypes = {
  GetSecureFiles: `${NAMESPACE}/GET_SECURE_FILES`,
  UploadSecureFile: `${NAMESPACE}/UPLOAD_SECURE_FILE`,
  DownloadSecureFile: `${NAMESPACE}/DOWNLOAD_SECURE_FILE`,
  DeleteSecureFile: `${NAMESPACE}/DELETE_SECURE_FILE`
};

export const getSecureFiles = (
  { filters, pagination },
  { loggingRequired }
) => {
  const f = {
    search: filters.search,
    customerId: filters.customerId,
    loggingRequired: loggingRequired
  };

  return apiGet(ActionTypes.GetSecureFiles, `secure-file-storage`, {
    ...f,
    ...pagination
  });
};

export const uploadFile = (upload, customerId) => {
  if (!upload.file || !upload.file.size)
    return mockError(ActionTypes.UploadSecureFile, {
      code: 0,
      message: "Couldn't retrieve uploaded file. Please try again",
      preventNotification: true
    });

  if (upload.file.size >= 1000 * 1000 * 100) {
    return mockError(ActionTypes.UploadSecureFile, {
      code: 0,
      message: "File size limit of 100Mb",
      preventNotification: false
    });
  }

  return apiPost(
    ActionTypes.UploadSecureFile,
    `secure-file-storage/upload`,
    upload.file,
    {
      contentType: upload.file.type,
      params: {
        fileName: upload.file.name,
        customerId: customerId
      }
    }
  );
};

export const downloadFile = fileId =>
  apiGet(ActionTypes.DownloadSecureFile, `secure-file-storage/download`, {
    fileId
  });

export const deleteFile = fileId =>
  apiDelete(ActionTypes.DeleteSecureFile, `secure-file-storage`, {
    fileId
  });
