import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes } from "constants/routes";
import React from "react";
import { Route, Switch } from "react-router-dom";
import PermissionErrorPage from "../PermissionErrorPage";
import UserStatsPage from "./UserStatsPage";

export default () => (
  <React.Fragment>
    <PermissionCheck target={Targets.CustomerUserStats} action={Actions.View}>
      <Switch>
        <Route exact path={AppRoutes.UserStats} component={UserStatsPage} />
      </Switch>
    </PermissionCheck>
    <PermissionCheck
      target={Targets.CustomerUserStats}
      action={Actions.View}
      not
      component={PermissionErrorPage}
    />
  </React.Fragment>
);
