import React from "react";
import { PageHeader } from "@redriver/cinnamon";
import { EmailReverified } from "features/System";
import { SystemPageContent } from "components/pages";

const EmailReVerifiedPage = ({ match, location }) => {
  return (
    <SystemPageContent>
      <PageHeader>Verifying Email Address</PageHeader>
      <EmailReverified token={match.params.token || ""} />
    </SystemPageContent>
  );
};

export default EmailReVerifiedPage;
