import { apiGet, apiPut, apiPost, apiDelete } from "@redriver/cinnamon";
import { TemplateType, TemplateAccess } from "constants/settings/templates";

const NAMESPACE = "TEMPLATES";

export const ActionTypes = {
  GetTemplates: `${NAMESPACE}/GET_TEMPLATES`,
  GetTemplate: `${NAMESPACE}/GET_TEMPLATE`,
  GetColumns: `${NAMESPACE}/GET_COLUMNS`,
  UpdateTemplate: `${NAMESPACE}/UPDATE_TEMPLATE`,
  CreateTemplate: `${NAMESPACE}/CREATE_TEMPLATE`,
  DeleteTemplate: `${NAMESPACE}/DELETE_TEMPLATE`
};

export const getTemplates = (state, loadParams) => {
  // if no types or access level specified, include all
  const params = {
    ...state.filters,
    ...state.pagination,
    templateTypes:
      state.filters.templateTypes && state.filters.templateTypes.length
        ? state.filters.templateTypes
        : Object.values(TemplateType),
    viewableTemplates:
      state.filters.viewableTemplates && state.filters.viewableTemplates.length
        ? state.filters.viewableTemplates
        : Object.keys(TemplateAccess)
  };

  return apiGet(ActionTypes.GetTemplates, `csv-export-templates`, params);
};

export const getTemplate = id =>
  apiGet(ActionTypes.GetTemplate, `csv-export-templates/${id}`);

export const updateTemplate = data => {
  const isContact =
    data.templateType === TemplateType.Contact
      ? true
      : data.templateType === TemplateType.Company
      ? false
      : null;
  const params = {
    name: data.name,
    isPublic: data.isPublic,
    fileFormat: data.fileFormat,
    columns:
      isContact === true
        ? data.contactColumns
        : isContact === false
        ? data.companyColumns
        : null
  };

  return apiPut(
    ActionTypes.UpdateTemplate,
    `csv-export-templates/${data.id}`,
    params
  );
};

export const createTemplate = data => {
  const isContact =
    data.templateType === TemplateType.Contact
      ? true
      : data.templateType === TemplateType.Company
      ? false
      : null;
  const params = {
    isContact: isContact,
    name: data.name,
    isPublic: data.isPublic,
    fileFormat: data.fileFormat,
    columns:
      isContact === true
        ? data.contactColumns
        : isContact === false
        ? data.companyColumns
        : null
  };
  return apiPost(ActionTypes.CreateTemplate, `csv-export-templates`, params);
};

export const deleteTemplate = id =>
  apiDelete(ActionTypes.DeleteTemplate, `csv-export-templates/${id}`);

export const getTemplateColumns = () => async dispatch => {
  var { success, response } = await dispatch(
    apiGet(ActionTypes.GetColumns, `lookups/csv-export-columns`)
  );
  if (!success) return;
  return response;
};
