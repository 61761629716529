import { Form } from "@redriver/cinnamon";
import { HeadersDropdown } from "features/Uploads/lookups";
import React from "react";

class HeaderTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        value: props.headerType,
        ignored: props.ignored
      },
      idx: props.idx,
      error: props.error,
      columnKey: props.columnKey
    };
  }

  componentDidUpdate(previousProps) {
    if (previousProps.idx != this.props.idx) {
      this.setState({
        formData: {
          value: this.props.headerType,
          ignored: this.props.ignored
        }
      });
    }
  }

  render() {
    const { columnKey, uploadId, isMatched, headerType } = this.props;
    const currentColumnName =
      !!columnKey &&
      columnKey.charAt(0).toUpperCase() + columnKey.slice(1).replace(/_/g, " ");
    return (
      <div>
        <Form.Group>
          <div className="i4b-datagrid-available-headers">
            <HeadersDropdown
              field="headerType"
              label={currentColumnName}
              fluid
              required
              lookupParams={{ uploadId }}
              isMatched={isMatched}
              inline
            />
          </div>
        </Form.Group>
      </div>
    );
  }
}

export default HeaderTool;
