import React from "react";
import { Image } from "semantic-ui-react";
import { Breadcrumb } from "@redriver/cinnamon";
import Home from "assets/icons/home_breadcrumb.svg";
import { AppRoutes } from "constants/routes";
import { ListSecureFiles } from "features/SecureFileArea/ListSecureFiles";

const SecureFileAreaPage = ({ history }) => {
  return (
    <React.Fragment>
      <Breadcrumb className="i4b-breadcrumb-container">
        <Breadcrumb.Item
          link={AppRoutes.Root}
          icon={<Image src={Home} title="Home" />}
        />
        <Breadcrumb.Item content="Secure File Area" />
      </Breadcrumb>
      <ListSecureFiles />
    </React.Fragment>
  );
};

export default SecureFileAreaPage;
