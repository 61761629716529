import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { getAuthClaims, getAuthRefreshToken } from "@redriver/cinnamon";
import moment from "moment";

/**
 * React Router compatible <Route/> that only matches when a user is currently authenticated
 */
const VerifiedRoute = ({
  component: Component,
  verifyRedirect,
  emailExpiryUtc,
  refreshToken,
  sessionsRedirect,
  impersonatorId,
  ...rest
}) => {
  const userIsTrusted = impersonatorId || moment(emailExpiryUtc) > moment();

  if (!userIsTrusted && !verifyRedirect) return null;
  if ((!refreshToken || refreshToken == "null") && !sessionsRedirect)
    return null;

  return (
    <Route
      {...rest}
      render={props =>
        userIsTrusted ? (
          !refreshToken || refreshToken == "null" ? (
            <Redirect
              to={{
                pathname: sessionsRedirect,
                state: { nextPath: props.location }
              }}
            />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: verifyRedirect,
              state: { nextPath: props.location }
            }}
          />
        )
      }
    />
  );
};

VerifiedRoute.propTypes = {
  /**
   * Component to be rendered by route
   */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  /**
   * Optional path to automatically redirect to if not verified, if not specified then the route will not match
   */
  verifyRedirect: PropTypes.string,
  /**
   * Optional path to automatically redirect to if user has other active sessions
   */
  sessionsRedirect: PropTypes.string
};

const mapStateToProps = state => ({
  emailExpiryUtc: getAuthClaims(state).emailExpiryUtc,
  impersonatorId: getAuthClaims(state).impersonatorId,
  refreshToken: getAuthRefreshToken(state)
});

export default connect(mapStateToProps)(VerifiedRoute);
