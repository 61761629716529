import { UserTypeCheck } from "components/auth";
import {
  CalculateFilterCount,
  SearchFiltersDivider,
  SearchFilterSegment,
  SearchHeader,
  SearchResultsSegment,
  SearchSegmentToggle
} from "components/search";
import { UserTypes } from "constants/permissions";
import React from "react";
import { connect } from "react-redux";
import {
  clearSearchFilter,
  deleteSearch,
  hardResetSearch,
  loadSearch,
  loadSearches,
  saveSearch,
  updateForm,
  updateListCriteria,
  updateSegmentSelection
} from "./actions";
import { getContactsSearchState } from "./selectors";

class SearchFilter extends React.Component {
  state = {
    total: null
  };

  componentDidUpdate = prevProps => {
    const { id, loading, results, pageChanged } = this.props;

    if (prevProps.loading && !loading) {
      this.setState(
        CalculateFilterCount(results, pageChanged, id, this.state.total)
      );
    }
  };

  hardReset = () => {
    this.setState(CalculateFilterCount([], true, "0", 0));
    this.props.hardResetSearch();
  };

  render() {
    const { total } = this.state;
    const {
      loading,
      clearSearchFilter,
      hardResetSearch,
      pageChanged,
      value,
      loadSearch,
      deleteSearch,
      listId,
      formData,
      contactsSegmentCount,
      contactsUniverseCount,
      updateSegmentSelection,
      isUniverse
    } = this.props;

    return (
      <React.Fragment>
        <SearchHeader
          isContactsPage
          formData={formData}
          loadSavedSearchesAction={loadSearches}
          saveSearchAction={saveSearch}
          searchSelectedAction={loadSearch}
          searchDeleteAction={deleteSearch}
          value={value}
          listId={listId}
          updateListCriteria={updateListCriteria}
          hardResetSearch={this.hardReset}
          isUniverse={isUniverse}
        />
        <UserTypeCheck restrictTo={UserTypes.External}>
          <SearchSegmentToggle
            segmentSelected={!isUniverse}
            totalUniverseResults={contactsUniverseCount}
            totalSegmentResults={contactsSegmentCount}
            updateSegmentSelection={updateSegmentSelection}
            disableUniverse={!!listId}
          />
          {!listId && <SearchFiltersDivider />}
        </UserTypeCheck>
        <SearchFilterSegment formData={formData} onClick={clearSearchFilter} />
        <SearchFiltersDivider />
        <SearchResultsSegment
          total={total || null}
          loading={loading && !pageChanged}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    formData,
    contacts,
    contactsLoading,
    pageChanged,
    contactsUniverseCount,
    contactsSegmentCount,
    isUniverse
  } = getContactsSearchState(state);
  const { criteriaResultCount } = contacts;
  return {
    formData,
    results: criteriaResultCount,
    loading: contactsLoading,
    pageChanged,
    contactsUniverseCount,
    contactsSegmentCount,
    isUniverse
  };
};

const actions = {
  updateForm,
  clearSearchFilter,
  hardResetSearch,
  loadSearch,
  deleteSearch,
  updateSegmentSelection
};

export default connect(
  mapStateToProps,
  actions
)(SearchFilter);
