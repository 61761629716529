import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  getAuthState,
  lookupRequest,
  lookupReset
} from "@redriver/cinnamon";
import { SavedSearchType } from "constants/search";
import { extractSearchCriteria, isFormDataEmpty } from "features/Utils";
import { debounce } from "lodash";
import { getContactsSearchState } from "./selectors";

const NAMESPACE = "CONTACTS";

export const ActionTypes = {
  GetFormData: `${NAMESPACE}/GET_FORM_DATA`,
  GetListFormData: `${NAMESPACE}/GET_LIST_FORM_DATA`,
  GetContacts: `${NAMESPACE}/GET_CONTACTS`,
  UpdateForm: `${NAMESPACE}/UPDATE_FORM`,
  UpdatePageNumber: `${NAMESPACE}/UPDATE_PAGE_NUMBER`,
  UpdateSearchFilter: `${NAMESPACE}/UPDATE_SEARCH_FILTER`,
  ResetSearch: `${NAMESPACE}/RESET_SEARCH`,
  AddContactTag: `${NAMESPACE}/ADD_CONTACT_TAG`,
  ResetResults: `${NAMESPACE}/RESET_RESULTS`,
  ClearSearchFilter: `${NAMESPACE}/CLEAR_SEARCH_FILTER`,
  ClearSelected: `${NAMESPACE}/CLEAR_SELECTED`,
  ChangeSelection: `${NAMESPACE}/CHANGE_SELECTION`,
  ChangeFollowing: `${NAMESPACE}/CHANGE_FOLLOWING`,
  AddSearchFilter: `${NAMESPACE}/ADD_SEARCH_FILTER`,
  RemoveFilter: `${NAMESPACE}/REMOVE_FILTER`,
  ClearSizeFilter: `${NAMESPACE}/CLEAR_SIZE_FILTER`,
  ClearJobTitlesFilter: `${NAMESPACE}/CLEAR_JOB_TITLES_FILTER`,
  ClearJobsFilter: `${NAMESPACE}/CLEAR_JOBS_FILTER`,
  SaveList: `${NAMESPACE}/SAVE_LIST`,
  DeleteTag: `${NAMESPACE}/DELETE_TAG`,
  LoadSearches: `${NAMESPACE}/LOAD_SAVED_SEARCHES`,
  LoadSearchById: `${NAMESPACE}/LOAD_SAVED_SEARCH`,
  DeleteSearchById: `${NAMESPACE}/DELETE_SAVED_SEARCH`,
  SaveSearch: `${NAMESPACE}/SAVE_SEARCH`,
  AddToList: `${NAMESPACE}/ADD_TO_LIST`,
  UpdateListCriteria: `${NAMESPACE}/UPDATE_LIST_CRITERIA`,
  CreateResearchRequest: `${NAMESPACE}/CREATE_RESEARCH_REQUEST`,
  AddSuppressions: `${NAMESPACE}/ADD_CONTACT_SUPPRESSIONS`,
  GetCustomerId: `${NAMESPACE}/GET_CUSTOMER_ID`,
  GetContactDetails: `${NAMESPACE}/GET_CONTACT_DETAILS`,
  ClearTags: `${NAMESPACE}/CLEAR_TAGS`,
  DownloadCount: `${NAMESPACE}/DOWNLOAD_COUNT`,
  GetDownload: `${NAMESPACE}/GET_DOWNLOAD`,
  GetUniverseContactsCount: `${NAMESPACE}/GET_CONTACTS_UNIVERSE_COUNT`,
  GetSegmentContactsCount: `${NAMESPACE}/GET_CONTACTS_SEGMENT_COUNT`,
  UpdateSegmentSelection: `${NAMESPACE}/UPDATE_SEGMENT_SELECTION`,
  SetExpandedRows: `${NAMESPACE}/SET_EXPANDED_ROWS`,
  UpdateSelectedCountries: `${NAMESPACE}/UPDATE_SELECTED_COUNTRIES`,
  GetSegmentAdditions: `${NAMESPACE}/GET_SEGMENT_ADDITIONS`,
  AddCompaniesToSegments: `${NAMESPACE}/ADD_COMPANIES_TO_SEGMENTS`
};

const debouncedGetContacts = debounce(dispatch => dispatch(getContacts()), 500);

export const getPreviousFormData = id => async (dispatch, getState) => {
  await dispatch(
    apiGet(ActionTypes.GetFormData, `users/${id}/previous-criteria`, {
      criteriaType: SavedSearchType.ContactSearch
    })
  );

  debouncedGetContacts(dispatch);
};

export const getListFormData = listId => async (dispatch, getState) => {
  await dispatch(
    apiGet(ActionTypes.GetListFormData, `lists/${listId}/criteria`)
  );

  debouncedGetContacts(dispatch);
};

export const getContacts = (
  forceRefresh = false,
  resetCriteriaLookup = false,
  dimmerLoading = false,
  resetSegmentCount = false
) => (dispatch, getState) => {
  const { formData, pageSize, pageNumber, isUniverse } = getContactsSearchState(
    getState()
  );
  const isEmpty = isFormDataEmpty(formData);
  const searchFilter = formData.searchCriteria.filter(
    f => f.type === "searchFilter"
  )[0];
  //setting the correct properties for the filters
  const listsFilter = formData.searchCriteria.find(
    filter => filter.type === "listsFilter"
  );
  if (listsFilter) {
    listsFilter.listsSearch.isContact = true;
  }
  const uploadsFilter = formData.searchCriteria.find(
    filter => filter.type === "companyUploadsFilter"
  );
  if (uploadsFilter) {
    uploadsFilter.uploadsSearch.isContact = false;
  }

  const leftCompanyFlag = searchFilter.searchFilter.left || false;
  const params = {
    searchCriteria: extractSearchCriteria(formData),
    pageSize,
    pageNumber,
    showUniverse: isUniverse,
    leftCompanyFlag
  };

  if (!isEmpty || forceRefresh) {
    dispatch(
      apiPost(ActionTypes.GetContacts, "contacts", params, {
        actionCustomData: { dimmerLoading: dimmerLoading }
      })
    );
  } else {
    dispatch({ type: ActionTypes.ResetResults });
  }

  if (resetCriteriaLookup) {
    dispatch(lookupReset("contactTagsLookup"));
    dispatch(lookupRequest("contactTagsLookup"));
  }

  if (resetSegmentCount) {
    dispatch(getUniverseContactsCount());
    dispatch(getSegmentContactsCount());
  }
};

export const updateForm = (field, data, applyChanges) => (
  dispatch,
  getState
) => {
  dispatch({
    type: ActionTypes.UpdateForm,
    applyChanges
  });
  if (!data[0].hasOwnProperty("postalFilter")) {
    debouncedGetContacts(dispatch);
  }
};

export const updatePageNumber = pageNumber => dispatch => {
  dispatch({
    type: ActionTypes.UpdatePageNumber,
    value: {
      pageNumber
    }
  });
  debouncedGetContacts(dispatch);
};

export const updateSearchFilter = searchText => dispatch => {
  dispatch({
    type: ActionTypes.UpdateSearchFilter,
    value: {
      searchText
    }
  });
  debouncedGetContacts(dispatch);
};

export const hardResetSearch = () => dispatch => {
  dispatch(
    apiPut(ActionTypes.ResetSearch, "users/self/reset-search", {
      savedSearchType: SavedSearchType.ContactSearch
    })
  );
  dispatch(getCustomerId());
  dispatch(getUniverseContactsCount());
  dispatch(getSegmentContactsCount());
};

export const resetSearch = () => dispatch => {
  dispatch(
    apiPut(ActionTypes.ResetResults, "users/self/reset-search", {
      savedSearchType: SavedSearchType.ContactSearch
    })
  );
};

export const loadSearches = ({ filters, pagination }, params) =>
  apiGet(ActionTypes.LoadSearches, "users/self/saved-searches", {
    ...filters,
    ...pagination,
    ...params
  });

export const saveSearch = (modalData, submitParams) => (dispatch, getState) => {
  const { formData } = getContactsSearchState(getState());
  const request = {
    ...modalData,
    ...submitParams,
    searchCriteria: extractSearchCriteria(formData)
  };

  return dispatch(
    apiPost(ActionTypes.SaveSearch, "users/self/saved-searches", request)
  );
};

export const loadSearch = ({ id }) => async dispatch => {
  await dispatch(
    apiGet(ActionTypes.LoadSearchById, `users/self/saved-searches/${id}`)
  );

  debouncedGetContacts(dispatch);
};

export const deleteSearch = ({ id }) =>
  apiDelete(ActionTypes.DeleteSearchById, `users/self/saved-searches/${id}`);

export const resetSearchSoft = () => dispatch => {
  dispatch({ type: ActionTypes.ResetResults });
};

export const resetCriteriaForLogout = () => dispatch => {
  dispatch({ type: ActionTypes.ResetSearch });
};

export const addContactTag = (
  { tag },
  { searchCriteria, selection, isUniverse }
) => async (dispatch, getState) => {
  const response = await dispatch(
    apiPut(ActionTypes.AddContactTag, `contacts/contact-tags`, {
      tag: tag,
      searchCriteria: searchCriteria,
      selection,
      showUniverse: isUniverse
    })
  );
  const { success } = response;
  if (!success) return;
  await dispatch(lookupReset("contactTagLookup"));
  return dispatch(lookupRequest("contactTagLookup"));
};

export const clearSelected = (event, id) => dispatch => {
  event && event.stopPropagation();
  dispatch({ type: ActionTypes.ClearSelected, value: id });
  debouncedGetContacts(dispatch);
};

export const clearSearchFilter = () => dispatch => {
  dispatch({ type: ActionTypes.ClearSearchFilter });
  debouncedGetContacts(dispatch);
};

export const changeSelection = selection => dispatch =>
  dispatch({ type: ActionTypes.ChangeSelection, value: { selection } });

export const changeFollowing = (contacts, currentlyFollowing) => {
  const path = `customers/contact-watch-list-items`;
  const ids = contacts.map(c => c.originalIntId);
  const actionType = ActionTypes.ChangeFollowing;
  const args = { contactIds: ids, customerId: null };
  const opts = {
    actionCustomData: { contacts, following: !currentlyFollowing }
  };

  const action = currentlyFollowing
    ? apiDelete(actionType, path, args, opts)
    : apiPost(actionType, path, args, opts);

  return action;
};

export const addSearchFilter = data => dispatch => {
  const { value } = data;
  if (value === "listsFilter") {
    dispatch(lookupReset("contactListsLookup"));
    dispatch(lookupRequest("contactListsLookup"));
  }
  dispatch({
    type: ActionTypes.AddSearchFilter,
    value
  });
};

export const removeFilter = (event, id) => dispatch => {
  event && event.stopPropagation();
  dispatch({ type: ActionTypes.RemoveFilter, value: id });
  debouncedGetContacts(dispatch);
};

export const clearSizeFilter = event => dispatch => {
  event && event.stopPropagation();
  dispatch({ type: ActionTypes.ClearSizeFilter });
  debouncedGetContacts(dispatch);
};

export const clearJobTitlesFilter = () => dispatch => {
  dispatch({ type: ActionTypes.ClearJobTitlesFilter });
  debouncedGetContacts(dispatch);
};

export const clearJobsFilter = event => dispatch => {
  event && event.stopPropagation();
  dispatch({ type: ActionTypes.ClearJobsFilter });
  debouncedGetContacts(dispatch);
};

// If modifying check features/Companies/ViewCompany/actions
export const saveListBase = (
  searchCriteria,
  modalData,
  selection,
  forceDumbList,
  leftCompanyFlag
) => {
  const request = {
    ...modalData,
    selection,
    searchCriteria,
    forceDumbList,
    leftCompanyFlag
  };
  return apiPost(ActionTypes.SaveList, "lists/contact", request);
};

export const saveList = (modalData, submitParams) => (dispatch, getState) => {
  const { formData } = getContactsSearchState(getState());
  const searchFilter = formData.searchCriteria.filter(
    f => f.type === "searchFilter"
  )[0];
  return dispatch(
    saveListBase(
      extractSearchCriteria(formData),
      modalData,
      submitParams.selection,
      false,
      searchFilter.searchFilter.left
    )
  );
};

// If modifying check features/Companies/ViewCompany/actions
export const addToListBase = (searchCriteria, modalData, selection) => {
  const request = {
    ...modalData,
    selection,
    searchCriteria
  };

  return apiPost(ActionTypes.AddToList, "lists/add-to-existing", request);
};

export const addToList = (modalData, submitParams) => (dispatch, getState) => {
  const { formData } = getContactsSearchState(getState());

  return dispatch(
    addToListBase(
      extractSearchCriteria(formData),
      modalData,
      submitParams.selection
    )
  );
};

export const updateListCriteria = listId => (dispatch, getState) => {
  const { formData } = getContactsSearchState(getState());
  if (isFormDataEmpty(formData)) return;
  const searchFilter = formData.searchCriteria.filter(
    f => f.type === "searchFilter"
  )[0];

  const leftCompanyFlag = searchFilter.searchFilter.left || false;
  const request = {
    listId,
    searchCriteria: extractSearchCriteria(formData),
    leftCompanyFlag
  };

  return dispatch(
    apiPost(ActionTypes.UpdateListCriteria, "lists/update-criteria", request)
  );
};

export const deleteTag = ({ contactId, tagId }) =>
  apiDelete(
    ActionTypes.DeleteTag,
    `contacts/${contactId}/tags`,
    { id: tagId },
    { preventErrorNotification: true }
  );

export const submitResearchRequest = (
  { requestText },
  { originalId, contactId }
) =>
  apiPost(ActionTypes.CreateResearchRequest, `contacts/${contactId}/research`, {
    requestText: requestText.trim(),
    originalId
  });

export const getCustomerId = () =>
  apiGet(ActionTypes.GetCustomerId, `users/user-customer-id`);

export const addSuppressions = submitParams =>
  apiPost(
    ActionTypes.AddSuppressions,
    `customers/${submitParams.customerId}/contact-suppressions`,
    {
      selection: submitParams.selection,
      searchCriteria: submitParams.formData.searchCriteria,
      showUniverse: submitParams.isUniverse
    }
  );

// please note this is the same as lists/listsoverview/actions
// had to dupe because we need to use different state
// if you change one please change both (if necessary)
export const getContactDetails = id =>
  apiGet(
    ActionTypes.GetContactDetails,
    `contacts/${id}/details`,
    {},
    { actionCustomData: { contactId: id } }
  );

export const clearTags = ({ selection, formData, customerId, isUniverse }) =>
  apiDelete(ActionTypes.ClearTags, `customers/${customerId}/contact-tags`, {
    selection,
    searchCriteria: formData.searchCriteria,
    showUniverse: isUniverse
  });

export const getDownloadCount = () => (dispatch, getState) => {
  const { formData, isUniverse } = getContactsSearchState(getState());
  const request = {
    searchCriteria: extractSearchCriteria(formData),
    isPreview: isUniverse
  };
  return dispatch(
    apiPost(ActionTypes.DownloadCount, `contacts/download-count`, request)
  );
};

export const getDownload = (template, submitParams) => (dispatch, getState) => {
  const { formData } = getContactsSearchState(getState());
  const request = {
    searchCriteria: extractSearchCriteria(formData),
    templateId: template.templateId,
    isPreview: submitParams.isPreview
  };
  return dispatch(
    apiPost(ActionTypes.GetDownload, `contacts/download`, request)
  );
};

export const getUniverseContactsCount = () =>
  apiGet(ActionTypes.GetUniverseContactsCount, `contacts/universe/count`);

export const getSegmentContactsCount = () =>
  apiGet(ActionTypes.GetSegmentContactsCount, `contacts/segment/count`);

export const updateSegmentSelection = selected => async (
  dispatch,
  getState
) => {
  const { claims } = getAuthState(getState());
  const isUniverse = selected === "universe";

  await dispatch({
    type: ActionTypes.UpdateSegmentSelection,
    value: { selected }
  });

  await dispatch(lookupReset("postalAreasLookup"));
  await dispatch(
    lookupRequest("postalAreasLookup", {
      userId: claims.userId,
      viewFullTree: false,
      viewingUniverse: isUniverse
    })
  ).then(async res => {
    if (!res.success) return;

    const { unfilteredSelectedCountries } = getContactsSearchState(getState());
    let segmentAllowed = [];
    Object.keys(res.response.flattenedStructure).forEach(e => {
      segmentAllowed.push(e);
      segmentAllowed = segmentAllowed.concat(
        res.response.flattenedStructure[e]
      );
    });
    const newCountriesToShow = unfilteredSelectedCountries[
      isUniverse ? "universe" : "segment"
    ].filter(x => segmentAllowed.includes(x));

    await dispatch({
      type: ActionTypes.UpdateSelectedCountries,
      value: { selected: newCountriesToShow, viewingUniverse: isUniverse }
    });
  });

  await dispatch(getContacts());
};

export const setExpandedRows = rows => dispatch => {
  dispatch({ type: ActionTypes.SetExpandedRows, rows });
};

export const getSegmentAdditions = data =>
  apiPost(
    ActionTypes.GetSegmentAdditions,
    `customers/segments/count-additions`,
    data
  );

export const addCompaniesToSegments = data =>
  apiPost(
    ActionTypes.AddCompaniesToSegments,
    `customers/segments/additions`,
    data
  );
