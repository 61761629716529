import React from "react";
import { PageHeader } from "@redriver/cinnamon";
import { ReverifyEmail } from "features/System";
import { SystemPageContent } from "components/pages";

const ReverificationPage = ({ match, location }) => {
  return (
    <SystemPageContent>
      <PageHeader>Re-verify Email Address</PageHeader>
      <ReverifyEmail isLogin={location.state && location.state.isLogin} />
    </SystemPageContent>
  );
};

export default ReverificationPage;
