import React from "react";
import { uniq } from "lodash";
import { Link } from "react-router-dom";
import { Icon, Image, Popup } from "semantic-ui-react";
import { MenuBar } from "@redriver/cinnamon";

import Building from "assets/icons/building.svg";
import Factory from "assets/icons/factory.svg";
import Family from "assets/icons/family.svg";
import Website from "assets/icons/website.svg";
import People from "assets/icons/people.svg";
import Newspaper from "assets/icons/newspaper.svg";
import Switchboard from "assets/icons/headset.svg";
import Hash from "assets/icons/hash.svg";
import { ExternalLink } from "components/buttons";
import { CompanyRoutes } from "constants/routes";
import { GoogleMapsLink } from "constants/search";
import { isPhoneValid } from "features/Utils";
import CompanyDetailActionDropdown from "./CompanyDetailActionDropdown";

const CompanyGeneralDetails = ({
  data,
  companyId,
  isSuppressed,
  isInSegment,
  onUpdated,
  customerHasCustomSegment
}) => {
  const industries = uniq([data.mainIndustry, ...data.industries]).filter(
    f => f && !!f.trim()
  );

  const formattedAliases =
    data.aliases && !!data.aliases.length
      ? data.aliases.map(a => a.trim()).join("; ")
      : null;

  const companyNameStyling = data.isDefunct ? "color-red" : undefined;

  const isDefunctPopup = (
    <Popup
      trigger={<Icon name="info circle" style={{ marginLeft: "0.25rem" }} />}
      content="Company Is Defunct"
      position="top center"
      className="table-popup status-text"
    />
  );

  return (
    <section>
      <div className="flex">
        <h2 className="m-r-1">General</h2>
        <MenuBar.Space />
        <div className="i4b-search-actions-wrapper">
          <CompanyDetailActionDropdown
            companyId={companyId}
            isInSegment={isInSegment}
            onUpdated={onUpdated}
            customerHasCustomSegment={customerHasCustomSegment}
          />
        </div>
      </div>
      <ul className="list-unstyled m-t-05 overview-text">
        {data.isDefunct && (
          <li className="defunct-banner">
            <h4>Company status: Defunct - This company no longer exists</h4>
          </li>
        )}
        <li>
          <Popup
            trigger={<Image src={Building} alt="Full Name" />}
            content="Full Name"
            position="top center"
          />
          <span className={companyNameStyling}>{data.companyName || "-"}</span>
          {data.isDefunct && isDefunctPopup}
        </li>

        <li>
          <Popup
            trigger={<Image src={Family} alt="Parent Company" />}
            content="Parent Company"
            position="top center"
          />
          {isSuppressed ? (
            "Suppressed"
          ) : (
            <Link to={CompanyRoutes.Overview(data.parentCompanyId)}>
              {data.parentCompany || "-"}
            </Link>
          )}
        </li>

        <li>
          <Popup
            trigger={<Image src={Website} alt="Website" />}
            content="Website"
            position="top center"
          />
          {isSuppressed ? (
            "Suppressed"
          ) : data.companyWebsite ? (
            <ExternalLink link={data.companyWebsite} />
          ) : (
            "-"
          )}
        </li>

        <li className="comma-seperate">
          <Popup
            trigger={<Image src={Factory} alt="Industries" />}
            content="Industries"
            position="top center"
          />
          <div>
            {industries.length
              ? industries.map(i => <span key={i}>{i}</span>)
              : "-"}
          </div>
        </li>

        <li>
          <Popup
            trigger={
              <Icon name="map marker alternate" alt="Head Office Address" />
            }
            content="Head Office Address"
            position="top center"
          />
          {isSuppressed ? (
            "Suppressed"
          ) : data.address ? (
            <a
              target="_blank"
              href={GoogleMapsLink(data.address)}
              className="purple-bold"
            >
              {data.address}
            </a>
          ) : (
            "-"
          )}
        </li>

        {isPhoneValid(data.telPublic) && (
          <li>
            <Popup
              trigger={<Image src={Switchboard} alt="HQ Switchboard" />}
              content="HQ Switchboard"
              className="flowing"
              position="top center"
            />
            {data.telPublic}
          </li>
        )}

        <li>
          <Popup
            trigger={<Image src={People} alt="Number of Active Contacts" />}
            content="Active Contacts at this Company"
            className="flowing"
            position="top center"
          />
          {data.numberActiveContacts && (
            <React.Fragment>{data.numberActiveContacts}</React.Fragment>
          )}
        </li>

        <li>
          <Popup
            trigger={
              <Image
                src={Newspaper}
                alt="Description"
                style={{ marginBottom: "auto", marginTop: "1em" }}
              />
            }
            content="Company Description"
            className="flowing"
            position="top center"
          />
          {data.description && (
            <div style={{ marginTop: "1em" }}>{data.description}</div>
          )}
        </li>
        {customerHasCustomSegment && (
          <li>
            <Popup
              trigger={<Image src={Hash} alt="Segmentation" />}
              content="Segmentation"
              className="flowing"
              position="top center"
            />
            {isInSegment && <div>Company is in segment</div>}
            {!isInSegment && <div>Company is not in segment</div>}
          </li>
        )}
      </ul>
    </section>
  );
};

export default CompanyGeneralDetails;
