import { HubConnectionBuilder } from "@aspnet/signalr";
import { getAuthState } from "@redriver/cinnamon";
import React from "react";
import { connect } from "react-redux";

const loggingEnabled = false; // whether or not to have debug console output
const connectionUpdateInterval = 2000; // how frequently to attempt to connect / process unsubscriptions
const listenMethodList = [
  //method names sent by the backend
  "sendNotification",
  "kickoutUser",
  "disableUser"
];

let currentStore = null; // redux store
let hubConnection = null; // signalr hub connection
let connected = false; // connected to the hub?
const currentChannelCallbacks = {}; // list of callbacks registered for a given channel
let subscriptionsToRemove = [];

// runs frequently to connect if disconnected but authenticated,
// as well as unsubscribe from notification channels
const checkConnection = () => {
  if (
    currentStore != null &&
    getAuthState(currentStore.getState()).accessToken &&
    hubConnection == null
  ) {
    connectToHub(getAuthState(currentStore.getState()).accessToken);
  } else if (connected) {
    subscriptionsToRemove.forEach(r => {
      loggingEnabled &&
        console.log("[cinnamon-signalr] Processing unsubscription from " + r);
      hubConnection.invoke("unsubscribe", r);
    });
    subscriptionsToRemove = [];
  }
};
setInterval(checkConnection, connectionUpdateInterval);

// subscribes to a notification channel
const addSubscription = channel => {
  if (!connected) return;
  const removeIdx = subscriptionsToRemove.indexOf(channel);
  if (removeIdx != -1) {
    loggingEnabled &&
      console.log(
        "[cinnamon-signalr] Cancelling removal of subscription " + channel
      );
    subscriptionsToRemove.splice(removeIdx, 1);
    return;
  }
  loggingEnabled && console.log("[cinnamon-signalr] Subscribing to " + channel);
  hubConnection.invoke("subscribe", channel);
};

// enqueues unsubscription from a notification channel
// note: not immediate, in case soon after we need to resubscribe
// (e.g. if something is unmounted then mounted again)
const removeSubscription = channel => {
  if (!connected) return;
  loggingEnabled &&
    console.log(
      "[cinnamon-signalr] Queueing subscription removal from " + channel
    );
  subscriptionsToRemove.push(channel);
};

// redux middlewhere which captures the store and detects
// authentication events. note we do this here without requiring
// the app to pass something custom into the onAuthenticated /
// onDeauthenticated functions during setupAuth
export const signalRmiddleware = store => next => action => {
  currentStore = store;
  //console.log(action);
  if (
    action.accessToken &&
    action.type == "AUTH/AUTHENTICATE" &&
    hubConnection == null
  ) {
    connectToHub(action.accessToken);
  } else if (action.type == "AUTH/DEAUTHENTICATE") {
    disconnectFromHub();
  }
  return next(action);
};

// gets a unique ID to refer to callbacks (for easy removal layer)
let nextCallbackId = 0;
const getNextCallbackId = () => nextCallbackId++;

// registers a callback for a given channel. returns an ID which
// should be passed to removeChannelCallback later
const addChannelCallback = (channel, action) => {
  if (!(channel in currentChannelCallbacks)) {
    currentChannelCallbacks[channel] = {};
    addSubscription(channel);
  }
  const id = getNextCallbackId();
  currentChannelCallbacks[channel][id] = action;
  loggingEnabled &&
    console.log(
      "[cinnamon-signalr] Added callback " + id + " for channel " + channel
    );
  return id;
};

// removes a previously-registered callback given its channel and
// callback ID (returned from addChannelCallback)
const removeChannelCallback = (channel, id) => {
  loggingEnabled &&
    console.log(
      "[cinnamon-signalr] Removing callback " + id + " for channel " + channel
    );
  delete currentChannelCallbacks[channel][id];
  if (Object.keys(currentChannelCallbacks[channel]).length == 0) {
    delete currentChannelCallbacks[channel];
    removeSubscription(channel);
  }
};

// begins connecting to the hub. specifies a default access token,
// but the auth state is read from the store where appropriate
const connectToHub = accessToken => {
  loggingEnabled && console.log("[cinnamon-signalr] Connecting");
  if (connected) return;
  hubConnection = new HubConnectionBuilder()
    .configureLogging(6) //Log level 6 implies no logs, 0 is full logs
    .withUrl(process.env.API_URL + "users-hub", {
      accessTokenFactory: () =>
        getAuthState(currentStore.getState()).accessToken || accessToken
    })
    .build();

  hubConnection.onclose(err => {
    loggingEnabled &&
      console.log("[cinnamon-signalr] Connection closed with error", err);
    disconnectFromHub(); // mark as disconnected so we reconnect if needed
  });

  hubConnection
    .start()
    .then(onConnected)
    .catch(err => {
      loggingEnabled &&
        console.log(
          "[cinnamon-signalr] Error establishing notification connection",
          err
        );
      disconnectFromHub();
    });
};

// immediately disconnects from the hub, if connected
const disconnectFromHub = () => {
  loggingEnabled && console.log("[cinnamon-signalr] Disconnecting");
  if (hubConnection == null) return;
  hubConnection.stop();
  connected = false;
  hubConnection = null;
};

// handles pending subscriptions upon initial connection to the hub
const onConnected = () => {
  loggingEnabled && console.log("[cinnamon-signalr] Connected");
  //create event handlers
  for (const methodName of listenMethodList) {
    hubConnection.on(methodName, (channel, data) =>
      handleEvent(channel, data, methodName)
    );
  }
  connected = true;
  for (const channel in currentChannelCallbacks) {
    addSubscription(channel);
  }
};

// main client-side callback
// for a channel, from the server
const handleEvent = (channel, data, method) => {
  if (!(channel in currentChannelCallbacks)) {
    loggingEnabled &&
      console.log(
        "[cinnamon-signalr] Received unexpected notification for channel " +
          channel +
          " (method: " +
          method +
          "): " +
          JSON.stringify(data) +
          " (ignoring)"
      );
    return;
  }

  // get the list of callbacks for this channel
  const channelCallbacks = currentChannelCallbacks[channel];

  // call each in turn
  for (const callbackId in channelCallbacks) {
    const callback = channelCallbacks[callbackId];
    loggingEnabled &&
      console.log(
        "[cinnamon-signalr] Invoking callback " +
          callbackId +
          " for channel " +
          channel +
          " (method: " +
          method +
          ")"
      );
    try {
      callback(method, data);
    } catch (ex) {
      console.log(
        "[cinnamon-signalr] Error invoking notification callback " +
          callbackId +
          " for channel " +
          channel +
          " (method: " +
          method +
          ")",
        ex
      );
    }
  }
};

// component which registers a callback for a channel and
// uses it to invoke a method (set via setupCallbacks prop) or action
// (via action prop).
const mapDispatchToProps = dispatch => ({ dispatch });
export const SignalRClient = connect(
  null,
  mapDispatchToProps
)(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.registration = null;
    }

    render() {
      return null;
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.channel != prevProps.channel) {
        this.unregister(prevProps.channel);
        this.register();
      }
    }

    componentDidMount() {
      this.register();
    }

    componentWillUnmount() {
      this.unregister(this.props.channel);
    }

    register() {
      const regId = addChannelCallback(this.props.channel, this.handleCallback);
      this.registration = regId;
    }

    unregister(channel) {
      removeChannelCallback(channel, this.registration);
    }

    handleCallback = (method, data) => {
      if (this.props.callbacks) {
        const callback = this.props.callbacks.find(c => c.method == method);
        if (callback) {
          if (callback.isAction) {
            this.props.dispatch(callback.run(data));
          } else {
            callback.run(data);
          }
        }
      }
    };
  }
);
