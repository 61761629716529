import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

/**
 * Renders a single tile
 */
class Tile extends React.Component {
  static propTypes = {
    /**
     * Time in milliseconds for tile animation transitions, or false to disable
     */
    animate: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    /**
     * Whether content within the tile should be centered
     */
    center: PropTypes.bool,
    /**
     * Whether a tile should appear selectable
     */
    selectable: PropTypes.bool,
    /**
     * Optional, turns the tile into a link to the specified path
     * Can be a path string or a React Router Link object:
     * @property {string} `pathname` The path to link to
     * @property {string} `search` Query parameters to append
     * @property {string} `hash` Hash string to append
     * @property {*} `state` State to persist in the location
     */
    link: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        pathname: PropTypes.string
      })
    ]),
    /**
     * Called when the tile is clicked on
     */
    onClick: PropTypes.func,
    /**
     * Additional classes for styling
     */
    className: PropTypes.string
  };

  static defaultProps = {
    animate: 400,
    center: false,
    selectable: false
  };

  state = { mouseOver: false };

  onMouseEnter = e => {
    this.setState({ mouseOver: true });
    if (this.props.onMouseEnter) this.props.onMouseEnter(e);
  };

  onMouseLeave = e => {
    this.setState({ mouseOver: false });
    if (this.props.onMouseLeave) this.props.onMouseLeave(e);
  };

  render() {
    const {
      animate,
      link,
      center,
      selectable,
      className,
      children,
      ...otherProps
    } = this.props;
    const hover = this.state.mouseOver;

    const output =
      typeof children === "function" ? children({ hover }) : children;

    const tileProps = {
      ...otherProps,
      onMouseEnter: this.onMouseEnter,
      onMouseLeave: this.onMouseLeave,
      className: classNames("cin tile", { center, selectable }, className),
      children: output
    };

    const tileContent = link ? (
      <Link {...tileProps} to={link} />
    ) : (
      <div {...tileProps} />
    );

    if (typeof animate === "number" && animate > 0) {
      return <Fade duration={animate}>{tileContent}</Fade>;
    }

    return tileContent;
  }
}

export default Tile;
