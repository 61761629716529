import { Format, getAuthClaims } from "@redriver/cinnamon";
import { Actions, Targets, UserTypes } from "constants/permissions";
import * as fileSaver from "file-saver";
import React from "react";
import { connect } from "react-redux";
import { Button, Grid, Popup, Segment } from "semantic-ui-react";
import { downloadResultsFile } from "../UploadWizard/actions";
import UploadsActionDropdown from "./UploadsActionDropdown";

const UploadCard = ({
  name,
  rowCount,
  searchType,
  uploadType,
  uploadedUtc,
  numFailed,
  numSuccessful,
  numExcluded,
  id,
  downloadResultsFile,
  onRefresh,
  userId,
  isInternal,
  currentUserId,
  canManageUploads,
  createdByName
}) => {
  const downloadSuccessfulFile = () => async () => {
    if (numSuccessful > 0) {
      downloadResultsFile(id, "successful").then(result => {
        fileSaver.saveAs(result.response, `${name} - successful.csv`);
      });
    }
  };

  const downloadExcludedFile = () => async () => {
    if (numExcluded > 0) {
      downloadResultsFile(id, "excluded").then(result => {
        fileSaver.saveAs(result.response, `${name} - excluded.csv`);
      });
    }
  };

  const downloadFailedFile = () => async () => {
    if (numFailed > 0) {
      downloadResultsFile(id, "failed").then(result => {
        fileSaver.saveAs(result.response, `${name} - failed.csv`);
      });
    }
  };

  const shouldShowActions =
    isInternal || currentUserId == userId.replace(/-/g, "") || canManageUploads;

  return (
    <Segment className="left-border-green m-y-1 list-card">
      <Grid style={{ margin: "0" }}>
        <Grid.Row columns={3} style={{ padding: "0" }}>
          <Grid.Column style={{ padding: "0" }} width={7}>
            <Popup
              content={name}
              trigger={<h5 className="single-line-text">{name}</h5>}
            />
          </Grid.Column>
          <Grid.Column width={7}>
            <p className="m-b-0 pull-right">
              Uploaded <Format.Date value={uploadedUtc} />
            </p>
          </Grid.Column>
          <Grid.Column width={2} style={{ padding: "0" }}>
            <p className="pull-right">
              {shouldShowActions && (
                <UploadsActionDropdown
                  uploadId={id}
                  uploadName={name}
                  onRefresh={onRefresh}
                />
              )}
            </p>
          </Grid.Column>
        </Grid.Row>
        {createdByName && (
          <Grid.Row columns={1} style={{ padding: "0" }}>
            <Grid.Column style={{ padding: "0" }}>
              <p className="m-b-0">Uploaded by {createdByName}</p>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={2} style={{ padding: "0" }}>
          <Grid.Column style={{ padding: "0", paddingTop: "1rem" }}>
            <p className="m-b-0">{rowCount} Rows</p>
            <div className="space-between">
              <p className="m-b-0">{searchType}</p>
              <p className="m-b-0">{uploadType}</p>
            </div>
          </Grid.Column>
          <Grid.Column
            style={{
              padding: "0",
              paddingTop: "0.5rem"
            }}
          >
            <Button
              icon="download"
              content={`${numSuccessful} successful`}
              className={`upload-list-button ${
                numSuccessful > 0
                  ? "download-button-active"
                  : "download-button-inactive"
              }`}
              onClick={downloadSuccessfulFile()}
            />
            <Button
              icon="download"
              content={`${numExcluded} excluded`}
              className={`upload-list-button ${
                numExcluded > 0
                  ? "download-button-active"
                  : "download-button-inactive"
              }`}
              onClick={downloadExcludedFile()}
            />
            <Button
              icon="download"
              content={`${numFailed} failed`}
              className={`upload-list-button ${
                numFailed > 0
                  ? "download-button-active"
                  : "download-button-inactive"
              }`}
              onClick={downloadFailedFile()}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const mapStateToProps = (state, props) => {
  const { permissions, userType, userId } = getAuthClaims(state);

  return {
    isInternal: userType && userType == UserTypes.Internal,
    currentUserId: userId,
    canManageUploads:
      permissions &&
      permissions.hasOwnProperty(Targets.Upload) &&
      (Array.isArray(permissions[Targets.Upload])
        ? permissions[Targets.Upload].some(p => p == Actions.Manage)
        : permissions[Targets.Upload] == Actions.Manage)
  };
};

const actions = {
  downloadResultsFile
};

export default connect(
  mapStateToProps,
  actions
)(UploadCard);
