import { apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/REVERIFY_EMAIL";

export const RESEND_EMAIL = `${NAMESPACE}/RESEND_EMAIL`;
export const SHOW_MESSAGE = `${NAMESPACE}/SHOW_MESSAGE`;
export const HIDE_MESSAGE = `${NAMESPACE}/HIDE_MESSAGE`;

export const resendEmail = (isLogin = false) => async dispatch => {
  if (isLogin) {
    await dispatch({ type: HIDE_MESSAGE });
  } else {
    await dispatch({ type: SHOW_MESSAGE });
  }
  dispatch(apiPut(RESEND_EMAIL, `users/send-verify-email`, isLogin));
};
