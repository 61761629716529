import { combineReducers } from "redux";
import { reducer as companiesSearch } from "./CompaniesSearch/index.js";
import { reducer as viewCompany } from "./ViewCompany";
import { reducer as companyStructure } from "./ViewCompany/CompanyStructure";

export default combineReducers({
  companiesSearch,
  viewCompany,
  companyStructure
});
